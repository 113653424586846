import React, {useEffect, useState} from "react"
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputMask from 'react-input-mask';
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import {ReactComponent as AutoImport} from '../../../src/assets/images/format-icon/svg/auto-import.svg'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import colors from "../../assets/themes/Colors";
import {postExtDataReset} from "../../actions/extdata";
import ModalListExtDataComponent from "../modals/extdata/ModalListExtDataComponent";
import {Search} from "@material-ui/icons";
import {convertersAlphaToNumber, devLog, isDefined} from "../../utils/utils";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import {HyperFormula} from "hyperformula";
import ModalListExtDataMultipleComponent from "../modals/extdata/ModalListExtDataMultipleComponent";
import { RenderItemMultipleSelectComponent, RenderItemSingleSelectComponent, restylizeNumber } from "../custom/FormatTable";
import { DurationInput, RestyledNumericInput } from "../custom/CustomComponents";
import { Tooltip } from "@material-ui/core";
import { ModalTablemultiTableViewComponent } from "../modals/tablemulti/ModalTablemultiTableViewComponent";
import { confirmAlert } from "react-confirm-alert";
import { getToken } from "../../utils/Common";
import { trackPromise } from "react-promise-tracker";
import Axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {isNull} from "lodash";
import { createEmptyRow, getAllDefaultRows, getLabelWithSpecialChar, handleCleanEmptyRows, isRowLimitReached, isVisibleInCSV, populateRowWithDefaultValues, purgeDefaultEmptyRow } from "../../utils/Fields";
import { toast } from "react-toastify";
import PlugTooltip from "../custom/PlugTooltip";
import { isMobile } from "react-device-detect";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    table: {
        userSelect: 'none',
        "&::-youbkit-user-select": {
            color: 'red',
            backgroundColor: 'yellow',
        },
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
        "@media (max-width: 640px)": {
            margin: 0
        }
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    scrollBar: {
        overflowX: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '10px',
            background: '#E3E3E3',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&::-webkit-scrollbar-button:single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */
    },
    addCellButton: {
        backgroundColor: colors.greyLighter,
    },
    rowDelCellButton: {
        backgroundColor: colors.greyLighter,
        display: 'flex',
        alignItems: 'center',
    },
    rowNum: {
        color: colors.grey,
        //fontWeight: 'bold',
    },
    tableHeader: {
        padding: 15,
    },
    tableMultAddButton: {
        // width : 20,
        // height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        fontSize: 28,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableMultExportButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.main,
        color: colors.main,
        fontSize: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headerSeparator: {
        borderColor: colors.greyLight,
        borderStyle: 'solid',
        borderWidth: 0,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        padding: 10,
    },
    buttonBar:{
        marginLeft: 'auto',
        backgroundColor: colors.greyLighter,
        display: 'flex',
        justifyContent:'space-between',
        height: 30,
        alignItems: 'center'
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        //border: '1px solid greenyellow',
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    paginationSpacer: {
        flex: 'none',
    },
    tableMultPageText: {
        color: colors.main,
        fontSize: 15,
        display: 'flex',
        gap: '8px'
    },
    tableMultButton: {
        fontSize: 13,
        alignSelf: 'center',
        margin: '0 6px',
        "@media (max-width: 640px)": {
            fontSize: 8,
        }
    },
    tableRowField: {
        fontSize: 10,
        width: 30,
        margin: '0 auto',
        color: colors.main,
        "@media (max-width: 640px)": {
            fontSize: 8,
        }
    },
    tableMultPageChoice: {
        color: colors.white,
        backgroundColor: colors.unselectedItem,
        height: '100%',
        fontSize: 15,
        fontWeight: 'bold',
        padding: '0 5px',
        borderRadius: '6px',
        "@media (max-width: 640px)": {
            padding: '0 3px',
            fontWeight: '100 !important',
            fontSize: 11
        }
    },
    //Fonts
    fontChange: {
        fontSize: 12,
        paddingLeft: '5px!important',
    },
    fontChangeLabel: {
        fontSize: 12,
        color: colors.main,
        fontWeight: 'bold',
        marginLeft: '10px',
        "@media (max-width: 640px)": {
            marginLeft: 2
        }
    },
    fontChangeWidth: {
        fontSize: 12,
        minWidth : 120
    },
    fontFirst: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    textFont: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        paddingTop: 6,
    },
    textLength: {
        fontSize: 8,
        position: 'absolute',
        top: -6,
        right: 1,
    },
    fontFieldNumber: {
        fontSize: 8,
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        padding: '0px 2px',
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    //Render Item Single Select Component Styles
    renderSingleSelMainContainer: {
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    renderSingleSelContainer: {
        minHeight: 25,
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
    },
    renderSingleFormulaContainer: {
        minHeight: 25,
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
    },
    renderSingleSubTotalContainer: {
        padding : 0,
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
    },
    renderInputContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'nowrap',
    },
    renderSingleSelSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    renderSingleSelPreselNum: {
        fontSize: 10,
        color: colors.greyLight,
        border: `1px solid ${colors.greyLight}`,
        borderRadius: 50,
        padding: '1px 2px',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    durationInputContainer: {
        display: 'flex',
        width: '100%',
        background: colors.greenLightest,
        marginBottom: 3,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottom: '1px solid black',
        position: 'relative'
    },
    renderFirstInputContainer: {
        display: 'flex',
        width: '40px'
    },
    renderSecondInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 'auto'
    },
    renderCentralInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 17
    },
    renderThirdInputContainer: {
        width: '70%'
    },
    tableLabelContainer: {
        marginRight: 'auto',
        order: 1,
        width : '30%',
        "@media (max-width: 640px)": {
            minWidth: '30%',
            maxWidth: '50%'
        }
    },btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedItem,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedItem,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 2,
    },
    selectField: {
        minHeight: 24
    },
    fieldContainer: {
        display : 'flex',
        alignItems : 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 3,
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width : 'calc(60% + 32px)',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
    },
    fieldValueContainer: {
        display: 'flex',
        marginLeft: 'auto',
        order: 2,
        width : '60%'
    },
    fieldValues: {
        color: colors.main,
        padding: 0,
        paddingLeft: 5,
        cursor: 'pointer',
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
    },
    fieldLabels: {
        fontWeight: 'bold',
        wordBreak: 'break-word',
        color: colors.main,
        textAlign: 'left',
        padding: '10px 15px',
    },
    formatInputFieldComposed: {
        marginLeft: '29%',
        borderRadius: "4px",
        paddingRight: '20px',
        // width: "100%",
        padding: "5px 0",
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    formatInputFieldLockedComposed: {
        backgroundColor: colors.white,
        marginLeft: '29%',
        paddingRight: '20px',
        borderRadius: "4px",
        // width: "100%",
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    upliftedContainer: {
        // marginTop: '-30px',
        overflowY : 'visible'
    },
    searchField: {
        border: "solid #E3E3E3 1px",
		borderRadius: 4,
		paddingLeft: 10,
		marginTop: 0,
		marginBottom: 0,
    },
    mainCtn: {
        marginTop: -40,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
		width: isMobile ? '98vw' : '100%',
        alignItems: isMobile ? 'center' : 'stretch',
        gap: 8,
		overflowY: 'visible',
		position: 'relative',
	},
    tableRoot: {
        // width: "99%", //it was 100%
        overflowX: "auto",
    },
    //Top buttons
	topBtnCtn: {
        zIndex: 200,
		right: 0,
		gap: 3,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	topBtn: {
		width: 50,
		height: 30,
		borderRadius: 6,
		boxShadow: '0px 1px 2px 1px #ddd',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		backgroundColor: colors.main,
		color: 'white',
		'&:hover': {
			boxShadow: '0px 0px 10px 1px #aaa',
			fontWeight: 'bold',
		},
	},
	topBtnImg: {
		color: 'white',
		width: 20,
	},
	//New design
	totalValues: {
		color: colors.bloodOrange,
		padding: 0,
		paddingLeft: 5,
		wordBreak: 'break-word',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'left',
		fontSize: 12,
	},
	summaryCtn: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		gap: 5
	},
	summary: {
		paddingRight: 4
	},
	rowCtn: {
		justifyContent: 'flex-end',
		display: 'flex',
		flexDirection: 'row',
        width: '70%',
        alignSelf: 'flex-end',
        minHeight: 30,
	},
    searchBtn: {
        fontSize: 15,
        minWidth: 70,
        cursor: 'pointer',
    },
    pageRowCtn: {
        height: 30,
        verticalAlign: 'middle',
        backgroundColor: colors.greyLighter,
        borderBottom: 'none'
    },
    pageCell: {
        textAlign: 'center',
    },
    arrowRight: {
        textAlign: 'left',
        height: 28,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
    },
    arrowLeft: {
        textAlign: 'right',
        height: 30,
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
    },
    hidden: {
        color: colors.greyLighter
    },
    tripleCell: {
        '&.MuiTableCell-root': {
            borderBottom: 'none !important'
        },
        height: 30
    },
    rowBtn: {
        justifyContent: 'center',
        borderBottom: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.hoverBgnd,
        },
    },
	disabledBtn: {
		cursor: 'not-allowed',
		backgroundColor: colors.iconGrey,
		'&:hover': {
			boxShadow: '0px 0px 10px 1px #aaa',
			fontWeight: 'bold',
			borderColor: colors.iconGrey,
		},
	},
	leftCtn: {
		display: 'flex',
		flexDirection: 'column',
        flex: 1,
	},
	inputCtn: {
		paddingRight: 24
	},
    colorEnable: {
        color: colors.main
    },
    colorDisable: {
        color: colors.grey
    },
    notAllowedIcon: {
        position: 'absolute',
        right: 15,
        bottom: 5,
        fontSize: 20,
        textShadow: '0px 2px 1px white',
        fontWeight: 'bolder',
        cursor: 'not-allowed !important',
    },
    colorUnselectedItem:{
        color: colors.unselectedItem
    },
    //table preview
    tooltipCtn: {
		maxWidth: 'none',
		backgroundColor: colors.greyLighter,
		boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
		borderRadius: 8,
	},
	noTooltip: {
		backgroundColor: colors.transparent,
	},
	previewCtn: {
		backgroundColor: colors.transparent,
		margin: 10,
		maxWidth: '95vw',
	},
    warnToast: {
        backgroundColor: colors.unselected,
    },
}));

const FormatTableMultiplenComponent = (props) => {
    console.log('Tablemultin got props', props)
    const classes = useStyles();
    const { t } = useTranslation(['common']);
    let tmpDisabled = false;
    if (typeof props.disabled !== "undefined") {
        tmpDisabled = props.disabled;
    }
    else if (typeof props.field.disabled !== "undefined") {
        tmpDisabled = props.field.disabled;
    }

    const setup = props.field.options?.setup
	const defaultRows = setup?.defaultRows || []
	const rowLimit = setup?.rowLimit === 0 ? Infinity : setup?.rowLimit || Infinity

    const colonnes = props.field.options?.lines?.length > 0 ? props.field?.options?.lines : []
    const [rows , setRows] = useState(props.field.value?.list?.length > 0 ? props.field?.value?.list : []);
    const [disabled , setDisabled] = useState(tmpDisabled);
    const [open, setOpen] = useState(false);
    const [openRow, setOpenRow] = useState(null);
    const [openDownload, setOpenDownload] = useState(false);
    const [datasToDownload, setDatasToDownload] = useState([]);
    const authorizeDBSearch = isDefined(props?.model?.authoriseExternalDbInPf) ? props?.model?.authoriseExternalDbInPf : false
    const owner = typeof props.owner === 'undefined' ? null: props.owner
    const [openModal, setOpenModal] = useState(false)
    const ref = props.field.options.ref
    const field = props.field.options.field
    const column = props.field.options.column
    const columns = props.field.options.columns
    const options = props.field.options
    const dest = props.field.options.dest
    let searchValue = isDefined(props.field.options.search) ? props.field.options.search : ''
    if (isDefined(props.field?.value?.value) && props.field?.value?.value !== '') { searchValue = props.field.value.value }
    const [value, setValue] = useState(searchValue);
    const ownerId = props.ownerId
    const optionFormule = { licenseKey: 'gpl-v3' }
    const [recap, setRecap] = useState(false)
    const [openTable, setOpenTable] = useState(false)
    const [criteria, setCriteria] = useState("= xx");
	const [tableLength, setTableLength] = useState(recap ? rows.length - 1 : rows.length)
    const [newRow, setNewRow] = useState(null)
	const [pages, setPages] = useState(0)
	const [excessLimit, setExcessLimit] = useState(0)
	const isAddRowPossible = !disabled && tableLength < rowLimit

    //function deleteEmpty pour supprimer une rows[n] vide dans un useEffect ([])
    function deleteEmptyRows (rows) {
        let newRows = []
        if(rows.length > 0) {
            rows.forEach((row, index) => {
                let value = ''
                Object.values(row).forEach((valeur, id) => {
                    if(valeur !== '' && valeur !== undefined) value = valeur
                })
                if(value !== '') newRows.push(row)
            })
        }
        return newRows
    }

    useEffect(() => {
		let hasRecap = false
        props.field.options.lines.forEach((col) => { if (col.recap === true) hasRecap = true })
        setRecap(hasRecap)
        if (rows.length > 0) {
            setTableLength(rows.length > 0 && rows[rows.length - 1].hasOwnProperty('recap')? rows.length - 1 : rows.length)
            let newRows = deleteEmptyRows(rows)
            setRows(newRows)
        } else if ((props.mode.includes('create') || props.mode.includes('complete')) && defaultRows?.length > 0) {
            if (setup?.defaultComplete === 'open') {
                let _rows = purgeDefaultEmptyRow(getAllDefaultRows(colonnes, setup?.defaultRows))
                // devLog('initial rows', _rows)
                setRows(hasRecap ? [..._rows, createRecapData()] : _rows)
            }
        }
    }, [])

    const createNewRowWithDefaultValues = () => {
        let row = createEmptyRow(colonnes)
        return populateRowWithDefaultValues(colonnes, setup?.defaultRows, tableLength, row)
    }

    const handleAddTableMulti = async (reason) => {
        if (isDefined(reason) && reason === "once" && !disabled) {
			let newRows = purgeDefaultEmptyRow(getAllDefaultRows(colonnes, setup?.defaultRows))
			setRows(recap ? [...newRows, createRecapData()] : newRows)
		} else {
			let _newRow = createNewRowWithDefaultValues()
			if (rows.length !== 0) {
				let rowLimit = isDefined(setup?.rowLimit) ? setup?.rowLimit : 0
				let isEmpty = true
				let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
				_rows.filter(Boolean)
				if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
					if (_rows.length > 0) {
						let lastOne = _rows[_rows.length - 1];
						Object.values(lastOne).forEach(k => {
							if (isDefined(k)) {
								let control = typeof k !== "string" ? k.toString() : k
								if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
							}
						})
						if (!isEmpty) {
							if (recap) setRows([..._rows, _newRow, createRecapData()]);
							else setRows([..._rows, _newRow]);//ouvre automatiquement la ligne créée
							setNewRow(_newRow)
						}
						else {
							setOpen(true)
							setOpenRow(_rows.length - 1)
						}
					}
					else {
						if (recap) setRows([_newRow, createRecapData()])
						else setRows([_newRow]);
						setNewRow(_newRow)
					}
				} else {
					toast.info(t('tablemulti.row-limit-warn'),
					{
						autoClose: 3000,
						hideProgressBar: true,
						bodyClassName: classes.warnToast,
						className: classes.warnToast,
						toastClassName: classes.warnToast,
					})
				}
			} else {
				if (recap) setRows([...rows, _newRow, createRecapData()])
				else setRows([...rows, _newRow]);
				setNewRow(_newRow)
			}
		}
        if(!openTable) setOpenTable(true)
    }

    const handleClickAddRow = (reason) => {
		let _reason = reason
		if ((!isDefined(reason) || typeof reason === "object") && rows.length === 0) { _reason = setup?.defaultComplete }
		handleAddTableMulti(_reason)
		if(!openTable) setOpenTable(true)
    }

	function handleImportAuto(e) {
		e.preventDefault();
		e.stopPropagation();
		setPages(1);
	}

	useEffect(async () => {
        async function fetchDatas() {
            let valueTmp = value;
            if (valueTmp === "") {
                valueTmp = 'ùµùµùµ?';
            }
            let payload = {
                "column": options.column,
                "query": typeof options.query !== "undefined" ? options.query : 'LIKE',
                "columns": options?.columns?.split(';') || [],
                "userhash": owner,
                "value": valueTmp,
                "file": options.ref,
                "ownerId": ownerId,
                "page": pages
            }

            if (payload.value !== "") {
                await trackPromise(
                    Axios.post(ApplicationConfig.apiUrl + 'extdata/v3s/retrieves/datas.json', payload,
                        {
                            crossdomain: true,
                            'headers': {
                                'X-AUTH-TOKEN': getToken(),
                            }
                        }).then(async (response) => {
                        if (response.data.result && response.data.data.length > 0) {
                            await handleSelectData((response.data.data));
                        }

                        if (pages < response.data.end) {
                            setPages(pages + 1);
                        } else {
                            setPages(0);
                        }
                    }).catch((error) => {
                        setPages(0);
                    })
                )
            }
        }
		if (pages > 0 && typeof value !== 'undefined' && value !== "") {
			await fetchDatas();
		}
	}, [pages])

    const handleDuplicateRow = async (row) => {
		let _newRowDuplicated = { ...row }
		if (rows.length > 0) {
			let isEmpty = true
			let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
			_rows = _rows.filter(Boolean);
			if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
				if (_rows.length > 0) {
					let lastOne = _rows[_rows.length - 1];
					Object.values(lastOne).forEach((k) => {
						let control = typeof k !== "string" ? k.toString() : k;
						if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
					})
					if (!isEmpty) {
						if (recap) setRows([..._rows, _newRowDuplicated, createRecapData()]);
						else setRows([..._rows, _newRowDuplicated]);
					}
					else {
						setOpenRow(_rows.length - 1)
					}
				}
				else {
					if (recap) setRows([_newRowDuplicated, createRecapData()])
					else setRows([_newRowDuplicated]);
				}
			} else {
				if (!toast.isActive()) {
					toast.info(t('tablemulti.row-limit-warn'),
						{
							autoClose: 3000,
							hideProgressBar: true,
							bodyClassName: classes.warnToast,
							className: classes.warnToast,
							toastClassName: classes.warnToast,
						})
				}
			}
		} else {
			if (recap) { setRows([...rows, _newRowDuplicated, createRecapData()]) }
			else { setRows([...rows, _newRowDuplicated]) }
		}
	}

	const handleExportTableMulti = () => {
        let dataRows = [];
        if (colonnes.length > 0){
            let line1 = [];
            line1.push(t('export.export-date'))
            line1.push(t('export.export-key'))
            line1.push(t('export.export-number'))
            line1.push(t('export.export-field-number'))
            line1.push(t('export.export-field'))
            colonnes.forEach(col => {
                if (isVisibleInCSV(col, props.mode)) line1.push(getLabelWithSpecialChar(col.label))
            });

            dataRows.push(line1);
            rows.forEach((row, index) => {
                let dataRow = [];
                // date et heure de l'export
                // ajouter Clé du plugform
                // Numero de plugnote
                // Numero du champ
                // Libellé du champ
                let now = new Date()
                dataRow.push(now.toLocaleString())
                dataRow.push(props.plugKey)
                dataRow.push(props.plugNb)
                dataRow.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
                dataRow.push(props.title)

                colonnes.forEach(col => {
                    if (isVisibleInCSV(col, props.mode)) {
                        let someText = typeof row[col.id] === 'string'
                            ? row[col.id].replace(/\r?\n?/g, '').trim()
                            : row[col.id]
                        if (someText === undefined) {
                            someText = "";
                        }
                        dataRow.push(someText);
                }})
                dataRows.push(dataRow);
            });
        }

        if (dataRows.length > 0) {
            setDatasToDownload(dataRows);
            setOpenDownload(true);
        }
    }

    const handleAddRecap = () => {
        setRows([...rows, createRecapData()]);
        if (!open) setOpen(true);
    }

    useEffect(() => {
        if(openDownload){
            setDatasToDownload([]);
            setOpenDownload(false);
        }
    }, [openDownload]);

    const handleRemoveSpecificRow = (value) => {
        let items
		if (Array.isArray(value)) {
			items = rows.filter((_, index) => !value.includes(index + 1))
		} else {
			items = [...rows];
			items = items.filter((_, index) => index !== value);
		}
		if (recap) {
            let row = items.find(row => row?.recap === true)
            if (row && items.length > 1) {
                let rindex = items.indexOf(row)
                colonnes.forEach( (col, index) => {
                    if (col?.recap){
                        row["recap"] = true;
                        row[col.id] = getAllValueFromRowsToSpecificIndex(items, col.id)
                    }
                })
                items[rindex] = row;
                setRows([...items]);
            }
			else setRows([])
        }
		else setRows(items);
	};

    function createData() {
        let data = {};
        colonnes.forEach((col, index) => {
            data[col.id] = '';
        })
        return data;
    }

    function createRecapData() {
        let data = {};
        colonnes.forEach((col, index) => {
            if(col?.recap){
                data["recap"] = true;
                data[col.id] = getAllValueFromRowsToSpecificIndex(rows, col.id)
            }
        })
        return data;
    }

    function createRecapDataForce(rows) {
		let data = {};
		colonnes.forEach((col, index) => {
			if (col?.recap === true) {
				data["recap"] = true;
				data[col.id] = getAllValueFromRowsToSpecificIndex(rows, col.id)
			}
		})
		return data;
	}

    function refreshRecapData() {
		let _rows = [...rows]
		let row = _rows.find(row => row?.recap === true)
		if (row) {
			let rindex = _rows.indexOf(row)
			colonnes.forEach((col, index) => {
				if (col?.recap) {
					row["recap"] = true;
					row[col.id] = getAllValueFromRowsToSpecificIndex(rows, col.id)
				}
			})
			rows[rindex] = row;
			setRows([...rows]);
		}
	}

    const getAllValueFromRowsToSpecificIndex = (rows, id) => {
        let total = 0;
        rows.forEach((row, index) => {
            if (row[id] && row?.recap !== true){
                total += Number(row[id]);
            }
        });
        return total;
    }

    function handleChangeTableValue () {
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleEditValue = (value) => {
        setValue(value);
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleCalculateValueAuto = async (row, formula) => {
        let answer = null;
        let tmp = [];

        colonnes.forEach((elm, index) => {
            let tmpValue = row[elm.id];
            if(Object.prototype.toString.call(tmpValue) === '[object Array]' || Object.prototype.toString.call(tmpValue) === '[object Object]') {
                tmpValue = "";
            }
            if(elm.format === 'numeric' && row[elm.id] !== undefined && typeof row[elm.id] !== 'number'){
                tmpValue = row[elm.id].replace(', ', '.');
            }
            tmp.push([tmpValue]);
        });

        if (formula !== null) {
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };
            let sheetAData = data;
            let newFormula =  formula;
            const hfInstance = HyperFormula.buildEmpty(optionFormule);
            let formulasData = [[
                newFormula
            ]];
            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);
            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);

            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );

            try {
                const mySum = hfInstance.getCellValue(cellAddress);
                if(!mySum.hasOwnProperty('type')){
                    answer =  mySum;
                }
                else {
                    answer =  "";
                }
            }
            catch (e){
                answer =  "";
            }
            hfInstance.destroy();
        }
        return answer;
    }

    const handleChangeChildrenValue = async (value, col, rindex) => {
		let row = rows[rindex];
		if (!props.disabled && rindex >= 0) {
			let obj = colonnes.filter(o => o.format === 'formula').filter(Boolean)
			switch (col.format) {
                case 'presel': {
                    let multiselect = col.mask.multiselect;
                    let editable = col.mask.editable;
                    if (multiselect === false && editable === false) {
                        row[col.id] = value;
                        if (obj) {
                            for (const list of obj) {
                                let tmpValue = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null) {
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    else if (multiselect === false && editable === true) {
                        row[col.id] = value;
                        if (obj) {
                            for (const list of obj) {
                                let tmpValue = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null) {
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    else if (multiselect === true && editable === false) {
                        row[col.id] = value;
                        if (obj) {
                            for (const list of obj) {
                                let tmpValue = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null) {
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    else if (multiselect === true && editable === true) {
                        row[col.id] = value;
                        if (obj) {
                            for (const list of obj) {
                                let tmpValue = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null) {
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    break;
                }
				default:
					row[col.id] = value;
					if(obj){
						for (const list of obj) {
							let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
							if (tmpValue !== null){
								row[list.id] = tmpValue
							}
						}
					}
					setRows(previous => {
						let _rows = [...previous]
						_rows[rindex] = row;
						return _rows
					})
					break
			}
			refreshRecapData()
		}
	}

    const handleChangeChildrenRowValue =  async (value, col, row, rindex) => {
        if(!props.disabled) {
            switch (col.format) {
                case 'extdata':
                    rows[rindex] = value;
                    setRows([...rows]);

                    // if (typeof row.recap === "undefined") {
                    //     await handleRemoveRecap();
                    // }
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        handleChangeTableValue();
        setTableLength(rows.length > 0 && rows[rows.length - 1].hasOwnProperty('recap')? rows.length - 1 : rows.length)
        // console.log('S32 rows', rows);
    }, [rows]);

    useEffect(() => {
        let tmpDisabled = false;
        if(typeof props.disabled !== "undefined"){
            tmpDisabled = props.disabled;
        }
        else if(typeof props.field.disabled !== "undefined"){
            tmpDisabled = props.field.disabled;
        }
        setDisabled(tmpDisabled);
    }, [props]);

    useEffect(() => {
		switch (props.field.options.query) {
			case 'CONTAINS':
				setCriteria("..xx..")
				break;
			case 'START':
				setCriteria("xx..")
				break;
			case 'MULTIPLE':
				setCriteria("..xx ; xx..")
				break;
			default: setCriteria("= xx")
				break;
		}
	}, [props.field.options.query])

    useEffect (() => {
        setValue(!!props.field?.value?.value ? props.field?.value?.value : "");
    }, [props.field])

    const handleOpenSearchModel = () => {
        if (rows.length > 0) {
			setRows(handleCleanEmptyRows(rows))
		}
        props.attemptGetDataFromAnotherBaseByClientReset();
        setOpenModal(true);
    }

    const handleViewTable = () => {
        setOpenTable(true)
    }

    const handleClickPreview = () => {
        if (!disabled && rows.length === 0) { handleClickAddRow() }
        handleViewTable()
    }

    const handleSelectData = async (objects) => {
        let datas = [];
        let datasFields = null;
        if (objects.length > 0) {
            let resolve = new Promise((resolve, reject) => {
                objects.map(async (object, indexObject) => {
                    let jsonObjectToArray = Object.values(object)
                    let destinations = [];
                    let fieldDestinations = [];
                    let champs = dest.split('-');

                    champs.forEach((elm, index) => {
                        try {
                            let field = elm.split(':');

                            if (field.length > 0) {

                                let fieldDest = field[0];

                                if(fieldDest.startsWith('F')){
                                    fieldDest = fieldDest.replace('F', '');
                                    let dest = fieldDest
                                    let col = field[1];

                                    let lineDest = null;
                                    let colDest = null;

                                    if(typeof field[2] !== "undefined" && typeof field[3] !== "undefined"){
                                        lineDest = field[2];
                                        colDest = field[3];
                                    }

                                    let copyValue = col?.split(';') || col;
                                    let value = ""

                                    if(typeof props.fields[(dest - 1)] !== "undefined"){
                                        if(props.fields[(dest - 1)].format === "glocalis"){
                                            value = {
                                                latitude : "",
                                                longitude : "",
                                                address : ""
                                            }
                                        }
                                        else if(props.fields[(dest - 1)].format === "tablemultin" || props.fields[(dest - 1)].format === "smailvalidmulti" || props.fields[(dest - 1)].format === "document"){
                                            value = {
                                                value : "",
                                                list : [],
                                            }
                                        }
                                        else if(props.fields[(dest - 1)].format === "intdatamaj"){
                                            value = {
                                                value : "",
                                                updatedDate : "",
                                            }
                                        }
                                        else if(props.fields[(dest - 1)].format === "tablemulti"){
                                            value = [];
                                        }

                                        copyValue.map((item, index) => {
                                            let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                            let data = jsonObjectToArray[alphaNumIndexDest];
                                            if(typeof data !== "undefined"){
                                                let temp = data;
                                                if(temp !== "" && typeof temp !== "undefined"){
                                                    if(temp === null){
                                                        temp = "";
                                                    }

                                                    if(temp != ""){

                                                        switch (props.fields[(dest - 1)].format) {

                                                            case 'free-single-select':
                                                            case 'single-select':
                                                            case 'multi-select':
                                                            case 'free-multi-select':
                                                                try {
                                                                    value = [temp];
                                                                }catch (e) {
                                                                    value = [];
                                                                }
                                                                break;
                                                            case 'glocalis' :
                                                                value.address = value.address == "" ? value.address.concat(temp) : value.address.concat(' ' + temp) ;
                                                                break;
                                                            case 'tablemulti':
                                                                if(!isNull(lineDest) && !isNull(colDest)){
                                                                    let field = props.fields.find((field, index) => Number(field.id) === Number(dest));
                                                                    if(field){
                                                                        let tmpValue = field?.value ? field.value : null;
                                                                        if(!isNull(tmpValue)){
                                                                            if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                tmpValue[(targert - 1)][options[(targettable - 1)].id] = temp;
                                                                                value = tmpValue;
                                                                            }else{
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let newLine = {};
                                                                                for (const option of options) {
                                                                                    newLine[option.id] = "";
                                                                                }
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                newLine[options[(targettable - 1)].id] = temp;
                                                                                let newValue = [];
                                                                                newValue[(targert - 1)] = newLine
                                                                                value = newValue;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                break;
                                                            case 'tablemultin' :
                                                            case 'smailvalidmulti' :
                                                                if(isNull(lineDest) && isNull(colDest)){
                                                                    value.value = temp;
                                                                }else if(!isNull(lineDest) && !isNull(colDest)){
                                                                    let field = props.fields.find((field, index) => Number(field.id) === Number(dest));
                                                                    if(field){
                                                                        let tmpValue = field?.value?.list ? field.value.list : null;
                                                                        if(!isNull(tmpValue)){
                                                                            if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                tmpValue[(targert - 1)][options[(targettable - 1)].id] = temp;
                                                                                value.list = tmpValue;
                                                                            }else{
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let newLine = {};
                                                                                for (const option of options) {
                                                                                    newLine[option.id] = "";
                                                                                }
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                newLine[options[(targettable - 1)].id] = temp;
                                                                                let newValue = [];
                                                                                newValue[(targert - 1)] = newLine
                                                                                value.list = newValue;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                break;
                                                            case 'localis' :
                                                                value = value == "" ? value.concat(temp) : value.concat(' ' + temp) ;
                                                                break;
                                                            case 'intdatamaj' :
                                                                value.value = temp;
                                                                break;
                                                            default:
                                                                if (item == item.toUpperCase()) {
                                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + '\n'+ temp);
                                                                }else{
                                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                                }
                                                                break;

                                                        }
                                                    }
                                                }
                                            }
                                        });


                                        if(props.fields[(dest - 1)].format === "glocalis" ){
                                            value = JSON.stringify(value);
                                        }

                                        fieldDestinations.push({
                                            "dest" : dest,
                                            "value" : value
                                        })
                                    }
                                }
                                else{
                                    let dest = colonnes[(field[0] - 1)].id;
                                    let col = field[1];
                                    let copyValue = col?.split(';') || col;
                                    let value = ""

                                    copyValue.forEach((item, index) => {
                                        let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                        let data = jsonObjectToArray[alphaNumIndexDest];
                                        if (typeof data !== "undefined") {
                                            let temp = data;
                                            if (temp !== "" && typeof temp !== "undefined") {
                                                if (temp === null) {
                                                    temp = "";
                                                }

                                                if (temp !== "") {
                                                    if (item === item.toUpperCase()) {
                                                        value = value === "" ? value.concat(temp) : value.concat(' ~ \n' + temp);
                                                    }
                                                    else {
                                                        value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                    }
                                                }
                                            }
                                        }
                                    });
                                    destinations.push({
                                        "dest": dest,
                                        "value": value
                                    })
                                }
                            }
                        }catch (e) {

                        }


                    })

                    if (destinations.length > 0) {
                        let obj = colonnes.filter(o => o.format === 'formula').filter(Boolean);
                        let obj2 = colonnes.filter(o => o.format === 'numeric-ref').filter(Boolean);
                        let obj3 = colonnes.filter(o => o.format === 's33data').filter(Boolean);

                        let data = createData();
                        destinations.map(async item => {
                            let col = colonnes.find(o => ((o.id === Number(item.dest)) && (o.format === 'numeric')));

                            if (typeof col === "undefined") {
                                data[item.dest] = item.value;
                            } else {
                                data[item.dest] = item.value.replace(',', '.');
                            }

                            if(obj2){
                                for (const list2 of obj2) {
                                    let tmpValue = data[list2.id];
                                    if (tmpValue == "") {
                                        let target = list2?.configuration?.target;
                                        if (typeof target !== "undefined") {
                                            let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                                            if (field) {
                                                data[list2.id] = field.value;
                                            }
                                        }
                                    }
                                }
                            }

                            if(obj3){
                                for (const list3 of obj3) {
                                    let value = data[list3.id];

                                    try {
                                        if (value !== "") {
                                            let target = list3?.configuration?.target;
                                            let targettable = list3?.configuration?.targettable;
                                            if (typeof target !== "undefined") {
                                                let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                                                if (field) {
                                                    let tmpValue = field?.value?.list ? field.value.list : field.value;
                                                    if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                        let options = field.options?.lines ? field.options.lines : field.options;
                                                        data[list3.id] = tmpValue[(value - 1)][options[(targettable - 1)].id];
                                                    }
                                                }
                                            }
                                        } else {
                                            data[list3.id] = value;
                                        }
                                    } catch (e) {
                                        data[list3.id] = value
                                    }
                                }
                            }

                            if (obj) {
                                for (const list of obj) {
                                    let tmpValue = await handleCalculateValueAuto(data, list?.configuration?.formula);
                                    if (tmpValue !== null) {
                                        data[list.id] = tmpValue
                                    }
                                }
                            }

                        })
                        datas.push(data);
                    }

                    if (fieldDestinations.length > 0) {
                        datasFields = fieldDestinations;
                    }
                    resolve(true);
                });

            })
            if ((datas.length > 0 || (!isNull(datasFields) && datasFields.length > 0)) && await resolve) {
                let rowLimit = setup?.rowLimit
                if (typeof rowLimit !== "undefined" && rowLimit > 0 && datas.length > rowLimit - tableLength) {
                    setExcessLimit(previous => {
                        let excess = datas.length - (rowLimit - tableLength)
                        if (excess !== 100 && previous > 0) {
                            toast.dismiss('row-limit-toast')
                            toast.info(
                                t('tablemulti.row-limit-warn') + ': ' + (previous + excess) + t('tablemulti.rows-ignored'),
                                {
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    bodyClassName: classes.warnToast,
                                    className: classes.warnToast,
                                    toastClassName: classes.warnToast,
                                })
                            return 0
                        }
                        else { return previous + excess }
                    })
                    datas = datas.slice(0, rowLimit - tableLength)
                }
                if (datas.length > 0) {
                    let newRows = [...rows];

                    if (newRows.length > 0) {
                        if (newRows[(newRows.length - 1)].hasOwnProperty('recap')) {
                            newRows = newRows.filter((row, index) => index !== (newRows.length - 1));
                        }
                    }

                    let forek = new Promise((resolve, reject) => {
                        datas.forEach((item, index, array) => {
                            newRows.push(item);
                            if (index === array.length -1) resolve();

                        });
                    });

                    forek.then(() => {
                        if (recap) setRows([...newRows, createRecapDataForce(newRows)]);
                        else setRows(newRows);
                    });
                }

                if(!isNull(datasFields) && datasFields.length > 0){
                    props.handleChangeExtData(datasFields);
                }
            }
        }
    }

    const handleDeleteAll = () => {
        if(!disabled) {
            confirmAlert({
                title: t('actions.delete'),
                message: t('tablemulti.confirm-delete'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () => {
                            setRows([])
                        }
                    },
                    {
                        label: t('global.no'),
                    },
                ]
            });
        }
    }

	useEffect(() => { // Sert à limiter l'affichage du toast warning à 1
		if (excessLimit > 0 && !toast.isActive('row-limit-toast')) {
			toast.warn(props.field.title + ': ' + t('tablemulti.row-limit-warn'), {
				hideProgressBar: true,
				bodyClassName: classes.warnToast,
				className: classes.warnToast,
				toastClassName: classes.warnToast,
				toastId: 'row-limit-toast',
				isLoading: true,
				autoClose: 3000,
			})
            setTimeout(() => setExcessLimit(0), 20000)
		}
	}, [excessLimit])

    const titleFormat = `[ ${field} ] ( ${ref} ) [ ${columns} ] [ ${column} ]`

	function isLimitReached() {
		return isRowLimitReached(setup?.rowLimit, rows)
	}

    return (
        <div className={classes.mainCtn}>
            <div className={classes.rowCtn}>
                {
                    authorizeDBSearch &&
                    <div className={classes.leftCtn}>
                        <div className={classes.inputCtn}>
                            <TextField
                                disabled={disabled}
                                onChange={(event) => handleEditValue(event.target.value)}
                                id="standard-full-width"
                                type="text"
                                multiline={false}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <React.Fragment>
                                            {
                                                authorizeDBSearch ?
                                                    <div className={`${classes.searchBtn} ${disabled ? classes.colorDisable : classes.colorUnselectedItem}`} > {criteria}
                                                        <span id="icon-listForm" className={`material-icons md-25 ${disabled ? classes.colorDisable : classes.colorEnable}`} onClick={() => { if (!disabled) handleOpenSearchModel() }}>
                                                            search
                                                        </span>
                                                    </div>
                                                    :
                                                    <PlugTooltip placement="top" title={t('global.not-allowed')}>
                                                        <div className={`${classes.searchBtn} ${classes.colorDisable}`}> {criteria}
                                                            <span id="icon-listForm" className={`material-icons md-25 ${classes.colorDisable}`}>
                                                                search
                                                            </span>
                                                            <span id="icon-listForm" className={`material-icons red ${classes.notAllowedIcon}`}>
                                                                close
                                                            </span>
                                                        </div>
                                                    </PlugTooltip>
                                            }
                                        </React.Fragment>),
                                }}
                                placeholder={t('ext-table.search-table')}
                                helperText=""
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.searchField}
                                onKeyDown={(e) => { if (e.key === 'Enter' && !props.disabled && authorizeDBSearch) handleOpenSearchModel() }}
                                value={value}
                            />
                        </div>
                    </div>
                }
                <div className={classes.topBtnCtn}>
                    {
                        !disabled && typeof props.field.options.importauto !== "undefined" && props.field.options.importauto === true && value !== undefined && value !== '' && authorizeDBSearch &&
                        <PlugTooltip title="Auto-import" placement="top">
                            <div className={classes.topBtn} onClick={handleImportAuto}>
                                <AutoImport className={classes.topBtnImg} />
                            </div>
                        </PlugTooltip>
                    }

					{
						!disabled && rowLimit > tableLength &&
                        <PlugTooltip title={isLimitReached() ? t('tablemulti.row-limit-warn') : t('tablemulti.add-row')} placement="top">
                            <div className={`${classes.topBtn} ${isLimitReached() ? classes.disabledBtn : undefined} `} onClick={handleClickAddRow}>
                                <span id="icon-add-playlist" className={` material-icons`} style={isLimitReached() ? { cursor: 'not-allowed' } : undefined}>
                                    add_circle_outline
                                </span>
                            </div>
                        </PlugTooltip>
					}

                    {
                        rows.length > 0 &&
                        <PlugTooltip title={t('tablemulti.table-view').concat(` ${tableLength} `).concat(t('tablemulti.rows'))} placement="top">
                            <div className={classes.topBtn} onClick={handleViewTable}>
                                <span id="icon-add-playlist" className="material-icons">
                                    visibility
                                </span>
                                <span style={{ fontSize: 10, fontWeight: 'bolder', }}>
                                    {`(${tableLength})`}
                                </span>
                            </div>
                        </PlugTooltip>
                    }
                </div>
            </div>
            <div>
                <ModalTablemultiTableViewComponent
                    attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                    authorizeDBSearch={authorizeDBSearch}
                    criteria={criteria}
                    colonnes={colonnes}
                    datasToDownload={datasToDownload}
                    disabled={disabled}
                    field={props.field}
                    fields={props.fields}
                    handleAddRecap={handleAddRecap}
                    handleAddTableMulti={handleAddTableMulti}
                    handleChangeChildrenRowValue={handleChangeChildrenRowValue}
                    handleChangeChildrenValue={handleChangeChildrenValue}
                    handleClickPreview={handleClickPreview}
                    handleClose={() => setOpenTable(false)}
                    handleDeleteAll={handleDeleteAll}
                    handleDuplicateRow={handleDuplicateRow}
                    handleDeleteRow={handleRemoveSpecificRow}
                    handleEditValue={handleEditValue}
                    handleExportTableMulti={handleExportTableMulti}
                    handleImportAuto={handleImportAuto}
                    handleOpenSearchModel={handleOpenSearchModel}
                    hasDb={true}
                    isAddRowPossible={isAddRowPossible}
                    isFromPwCode={props.isFromPwCode}
                    isPreview={!openTable}
                    mode={props.mode}
                    model={props.model}
                    newRow={newRow}
                    open={openTable}
                    openDownload={openDownload}
                    openRow={openRow}
                    owner={owner}
                    ownerId={ownerId}
                    recap={recap}
                    rows={rows}
                    search={value}
                    setNewRow={setNewRow}
                    setOpenRow={setOpenRow}
                    title={props.title}
                />
            </div>
            {
                openModal &&
                <ModalListExtDataMultipleComponent titlePerso={props.title} titleFormat={titleFormat} format="extdata" open={openModal} close={(event) => setOpenModal(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} handleSelectData={handleSelectData} external={props.external} />
            }
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        // "plugKey": state.model.model.clef
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTableMultiplenComponent);
