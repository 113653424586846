import IconButton from "@material-ui/core/IconButton";
import {postLogoutRequest} from "../../actions/user";

import React from "react";
//import styles from "./styles.module.less"
import {makeStyles, alpha} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {CloseRounded, MenuRounded} from "@material-ui/icons";
import Images from "../../assets/themes/Images";
import {useLocation} from "react-router";

import { NavLink } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black",
  },
  menuButtonAccount: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: "#58C3C1",
  },
  menuButtonHelp: {
    marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(2),
    color: "#DADADA",
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: "10%",
  },
  li: {
    borderLeft: "1px solid #DADADA",
  },
  nav: {
    display: "inline-block",
    padding: "0px 10px",
  },
  navLast: {
    display: "inline-block",
    paddingLeft: "10px",
    paddingRight: "20px",
  },
}));

const excludes = [];

const MenuComponent = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const [menuSmall, setMenuSmall] = React.useState(false);

  const menuVisibility = () => {
    return setMenuSmall((menuHidden) => !menuHidden);
  };

  if (excludes.includes(location.pathname)) {
    return null;
  } else {
    return (
      <header className={`${classes.appBar} menuContainer`}>
        <div className="menuLogoIcon">
          <img
            className="logo"
            src={Images.logoWithoutDefault}
            alt="logo"
          />
          {/* icon menu for small screens  */}
          <div className="menuIconSm">
            <IconButton
              edge="start"
              className=""
              color="inherit"
              aria-label="menu"
              id="menu_button"
              onClick={menuVisibility}
            >
              {menuSmall ? <CloseRounded /> : <MenuRounded />  }
            </IconButton>
          </div>
        </div>

        <Toolbar className="nav_links">
          <div className="menuGroupLinks1">
            <NavLink
                to={{pathname:"https://www.plugnotes.com"}} target="_blank"
                className={classes.nav}
                activeClassName="black heavy"
            >
              VISIT PLUGNOTES.COM(CURRENT)
            </NavLink>
            <NavLink
                to={{pathname:"https://www.plugnotes.com"}} target="_blank"
                className={classes.nav}
                activeClassName="black heavy"
            >
              CONTACT
            </NavLink>
          </div>
        </Toolbar>
      </header>
    );
  }
};

// redux providing state takeover
const mapStateToProps = (state) => {
  return {
    username:
      !!state.user && !!state.user.user ? state.user.user.username : null,
    type: !!state.user && !!state.user.user ? state.user.user.type : null,
    listToAccept:
      !!state.user && !!state.user.user ? state.user.listToAccept : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  attemptLogout: (username) => dispatch(postLogoutRequest(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);
