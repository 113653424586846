import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {getToken} from '../utils/Common';

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  )
}
export default PublicRoute
