import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel, getPlugnote,} from "../../utils/Common";
import "@contentful/forma-36-react-components/dist/styles.css";
import ApplicationConfig from "../../config/ApplicationConfig";
import * as moment from "moment";
import {confirmAlert} from "react-confirm-alert";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft, faArrowAltCircleRight, faEdit,} from "@fortawesome/free-regular-svg-icons";
import * as XLSX from "xlsx";

import ModalAddAnnexeCommentComponent from "../../components/modals/ModalAddAnnexeCommentComponent";
import colors from "../../assets/themes/Colors";

// const zeroPad = (num, places) => String(num).padStart(places, "0");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popup: {
    position: "relative",
    display: "flex",
    width: "85vw",
    height: '90vh',
    padding: "15px 25px",
    backgroundColor: "white",
    borderRadius: "10px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "@media (max-width: 1024px)": {
      minWidth: "481px",
    },
    "@media (max-width: 639px)": {
      minWidth: 0,
      padding: '10px 5px',
      width: "100vw"
    }
  },
  previewPopup: {
    height: '100vh',
    width: '100vw',
    backgroundColor: colors.blackAlpha,
    zIndex: 9999
  },
  popup_content: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: 'column',
    gap: 25,
    "@media (max-width: 1024px)": {
      flexDirection: "column",
      gap: 0,
    },
  },
  arrow_smallScreen: {
    display: "none",
    cursor: 'pointer',
    "@media (max-width: 1024px)": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
    },
  },
  arrow_icon: {
    fontSize: "38px",
    color: colors.plugnotesLight,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  arrowPrev: {
    "@media (max-width: 1024px)": {
      order: 1,
      display: "none",
    },
  },
  arrowNext: {
    "@media (max-width: 1024px)": {
      order: 2,
      display: "none",
    },
  },
  elVisibility: {
    fontSize: "40px",
    color: "transparent",
    visibility: "hidden",
  },
  content_change: {
    /* border: '1px solid green', */
    flex: "1 1 100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: 'center',
    "@media (max-width: 1024px)": {
      order: 3,
      width: "100%",
    },
  },
  file_content: {
    border: '1px solid red',
  },
  modalHeader: {
    // padding: "15px 0",
    display: "flex",
    minWidth: '45vw',
    // height: 60,
    alignItems: "center",
    "@media (max-width: 1024px)": {
      flexDirection: "row",
      alignItems: "center",
    },
    "@media (min-width: 1025px)": {
      marginRight: '25px'
    },
    '@media (max-width: 640px)': {
      flexDirection: "column",
    }
  },
  modalIcon: {
    width: '50px',
    padding: '3px 8px',
    margin: 5,
    borderRadius: 15,
    backgroundColor: colors.whiteAlpha,
    textAlign: 'center'
  },
  modalHeaderIcon: {
    transform: 'scale(0.5)',
    marginRight: '5px',
    color: colors.plugnotesLight,
    '@media (max-width: 640px)': {
      display: 'none'
    },
  },
  modalLrgIcon: {
    cursor: 'pointer',
    marginLeft: 5,
    fontSize: 35
  },
  modalHeaderName: {
    // height: "24px",
    fontSize: "20px",
    fontWeight: "bold",
    overflow: "auto",
    display: 'flex',
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "@media (max-width: 1024px)": {
      fontSize: "16px",
      // height: "24px",
    },
    "@media (max-width: 640px)": {
      flexDirection: 'column'
    }
  },
  modalHeaderTitle: {
    display: "flex",
    flexDirection: 'row',
    gap: 15,
  },
  modalClose: {
    position: 'absolute',
    top: 11,
    right: 11,
    gap: 10,
    height: 'fit-content',
    "@media (max-width: 1024px)": {
      justifyContent: 'center',
      },
  },
  modal_button: {
    minWidth: "150px",
    padding: "4px 20px",
    borderRadius: "3px",
    cursor: "pointer",
    color: colors.white,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: colors.main
  },
  modalSubheader: {
    padding: 5,
    display: 'flex',
    justifyContent: "space-between",
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  button_download: {
    color: "white",
    backgroundColor: colors.mainButton,
  },
  button_delete: {
    color: colors.plugnotesGris,
    backgroundColor: "transparent",
    border: "2px solid",
    fontWeight: "600",
  },
  button_update: {
    color: "white",
    backgroundColor: colors.plugnotesBlueApp,
  },
  rightArrow: {
    color: colors.plugnotesLight,
    fontSize: '45px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top:'40vh',
    right: '25px',
    gap: 10,
    height: 'fit-content',
    "@media (max-width: 1024px)": {
      display: 'none',
      justifyContent: 'center',
      },
  },
  leftArrow: {
    color: colors.plugnotesLight,
    fontSize: '45px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top:'40vh',
    left: '25px',
    gap: 10,
    height: 'fit-content',
    "@media (max-width: 1024px)": {
      display: 'none',
      justifyContent: 'center',
      },
  },
  modalHeaderSize: {
    color: colors.plugnotesGris,
    marginRight: 10,
  },
  close_buttonSmall: {
    display: 'none',
    color: colors.frost,
    "@media (max-width: 1024px)": {
      fontSize: '38px',
      // fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center'
    },
  },
  close_button: {
    color: colors.frost,
    fontSize: '45px',
    "@media (max-width: 1024px)": {
      display: 'none',
    },
  },
  modalAttPrev: {
    backgroundColor: colors.frost,
    verticalAlign: 'middle',
    minHeight: '350px',
    maxHeight: '70vh',
    minWidth:'350px',
    maxWidth: '80vw',
    position: 'relative',
    "@media (min-width: 1025px)": {
      minWidth:'60vw',
      maxWidth: '80vw',
    },
    "@media (min-height: 700px)": {
      minHeight: '60vh',
      maxHeight: '70vh',
    },
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
  },
  modalFooter: {
    position: 'absolute',
    bottom: 0,
    width: '50px',
    padding: '3px 8px',
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row-reverse',
    marginBottom: '20px',
    gap: 20,
    alignItems: 'center',
    backgroundColor: colors.whiteAlpha,
    "@media (max-width: 1024px)": {
      display :'none'
    }
  },
  scaledImg: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  fieldTitle: {
    color: "black",
    fontWeight: "600",
  },
  commentField: {
    minHeight: 3,
    width: '100%',
    margin: "0 0 5px 0",
    borderBottom: `2px solid ${colors.frost}`,
  },
  commentText: {
    display: "flex",
    flexDirection: "row",
  },
  commentEdit: {
    display: "flex",
    alignItems: "flex-start",
    gap: 10,
  },
  commentEditIcon: {
    color: "#88949C",
    cursor: "pointer",
  },
  commentDesc: {
    fontSize: "14px",
    overflow: "auto",
    minHeight: "50px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "@media (max-width: 1024px)": {
      minHeight: "30px",
    },
  },
  historyField: {
    display: "flex",
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 1024px)': {
      flexDirection: 'column'
    }
  },
  historyText: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'flex-start',
    marginRight: 15
  },
  historyDesc: {
    fontSize: "14px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  buttonsGroup: {
    padding: "15px 0",
    display: "flex",
    justifyContent: "space-between",
    gap: 5,
  },
  buttonsRight: {
    display: "flex",
    color: colors.plugnotesGris,
    gap: 5,
  },
  edit_comment: {
    border: '1px solid red',
    width: '90px',
  },
  marginLeft: {
    marginLeft: "10px",
  },
  pointer: {
    cursor: "pointer",
  },
  mainColor: {
    color: colors.plugnotesLight,
    cursor: "pointer",
    marginLeft: 'auto',
    display: 'inline-block',
    "@media (min-width: 1025px)": {
      display: 'none'
    },
    "@media (max-width: 640px)": {
      marginTop: '-25px',
      textAlignLast: 'right'
    }
  },
  modalPgNb: {
    fontSize: 15,
    color: colors.selectedMain,
    fontWeight: 'bold'
  }
}));

const ModalTestAnnexeGallery = (props) => {
  const [fileId, setFileId] = useState(props.fileId); //receive the attachment index from the list on plugforms as a number
  const [focus, setFocus] = useState('')
  let contentId = props.annexes[fileId]; //all the fields from a specific attachment

  // console.log('meu contentId tem isso aqui ó: ',contentId)

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [t, i18n] = useTranslation("common");
  const [openInfos, setOpenInfos] = useState(contentId.comment? contentId.comment !== "" : false)
  // const [fullScreen, setFullScreen] = useState(false)
  const plugnote = getPlugnote();
  const environment = getCurrentEnvironnement();
  let fileName = contentId.name.length > 21 ? contentId.name.slice(0,20) + '...' + contentId.type : contentId.name + '.' + contentId.type
  const handleCloseModal = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
        props.close()
    }
  };
  const fileIsImg = contentId.type == "jpg" ||
      contentId.type == "JPG"||
      contentId.type == "jpeg"||
      contentId.type == "png"||
      contentId.type == "PNG"||
      contentId.type == "gif"

  const nextFile = () => {
    if (fileId + 1 >= props.annexes.length) {
      return fileId;
    } else {
      setFileId(fileId + 1);
    }
  };

  const previousFile = () => {
    if (fileId <= 0) {
      return fileId;
    } else {
      setFileId(fileId - 1);
    }
  };

  const handleExportCsv = () => {
    let binary = [];
    if (rows.length > 0) {
      rows.map((row, index) => {
        let finalObject = {};
        initColumns.map((colum, index) => {
          if (colum.key !== "actions") {
            finalObject[colum.realName] = row[colum.key];
          }
        });
        binary.push(finalObject);
      });
    }

    if (binary.length) {
      let wscols = [
        { width: 10 },
        { width: 100 },
        { width: 10 },
        { width: 100 },
        { width: 10 },
        { width: 10 },
      ];

      const binaryWS = XLSX.utils.json_to_sheet(binary);
      binaryWS["!cols"] = wscols;

      // Create a new Workbook
      let wb = XLSX.utils.book_new();

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");
      if (getModel().isModelShared) {
        XLSX.writeFile(
          wb,
          "[" +
            getModel().clef +
            "]" +
            getModel().label +
            "_PN_" +
            getPlugnote().userindex +
            "_" +
            ".xlsx"
        );
      } else {
        XLSX.writeFile(
          wb,
          "[" +
            getCurrentEnvironnement().envIndex +
            "-" +
            getModel().clef +
            "]" +
            getModel().label +
            "_PN_" +
            getPlugnote().userindex +
            "_" +
            ".xlsx"
        );
      }

      // export your excel
    }
  };

  const allOldActions = (object) => {
    return (
      <div className="display_flex float_right grey">
        <PopupState variant="popover" popupId={object.row.annexe.hash}>
          {(popupState) => (
            <div>
              <span
                aria-describedby={object.row.annexe.hash}
                className="material-icons md-medium"
                {...bindTrigger(popupState)}
              >
                more_horiz
              </span>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box p={2}>
                  <div className="borderTeste">
                    {object.row.annexe.type !== "note" && (
                      <Tooltip
                        title={i18n.t("common:actions.download")}
                        aria-label="add"
                        placement="top-start"
                      >
                        <span
                          id="icon-file_copy"
                          className="p3 cursor"
                          onClick={(event) =>
                            props.downloadEmployeeData(event, object.row.annexe)
                          }
                        >
                          <span
                            id="icon-archive"
                            className="material-icons md-25"
                          >
                            get_app
                          </span>
                        </span>
                      </Tooltip>
                    )}

                    {!props.locked &&
                      props.isAuthorisedToEdit(object.row.annexe.type) && (
                        <Tooltip
                          title={i18n.t("common:actions.update")}
                          aria-label="add"
                          placement="top-start"
                        >
                          <span
                            id="icon-file_copy"
                            className="p3 cursor"
                            onClick={(event) =>
                              props.handleUpdateAnnexe(event, object.row.annexe)
                            }
                          >
                            <span
                              id="icon-archive"
                              className="material-icons md-25"
                            >
                              publish
                            </span>
                          </span>
                        </Tooltip>
                      )}

                    {!props.locked && (
                      <Tooltip
                        title={i18n.t("common:actions.comment")}
                        aria-label="add"
                        placement="top-start"
                      >
                        <span
                          id="icon-file_copy"
                          className="p3 cursor"
                          onClick={(event) =>
                            props.handleModalAnnexeOpen(
                              event,
                              object.row.annexe
                            )
                          }
                        >
                          <span
                            id="icon-archive"
                            className="material-icons md-25"
                          >
                            comment
                          </span>
                        </span>
                      </Tooltip>
                    )}

                    {object.row.annexe.type === "note" && !props.locked && (
                      <Tooltip
                        title={i18n.t("common:actions.notes")}
                        aria-label="add"
                        placement="top-start"
                      >
                        <span
                          id="icon-file_copy"
                          className="p3 cursor"
                          onClick={(event) =>
                            props.handleNoteOpen(event, object.row.annexe)
                          }
                        >
                          <span
                            id="icon-archive"
                            className="material-icons md-25"
                          >
                            create
                          </span>
                        </span>
                      </Tooltip>
                    )}
                    {!props.disabledDelete && (
                      <Tooltip
                        title={i18n.t("common:actions.delete")}
                        aria-label="add"
                        placement="top-start"
                      >
                        <span
                          id="icon-file_copy"
                          className="p3 cursor"
                          onClick={(event) =>
                            props.handleDeleteAnnexe(event, object.row.annexe)
                          }
                        >
                          <span
                            id="icon-archive"
                            className="material-icons md-25"
                          >
                            delete
                          </span>
                        </span>
                      </Tooltip>
                    )}
                    {!props.locked && object.row.annexe.countVersion > 0 && (
                      <Tooltip
                        title={i18n.t("common:actions.versionning")}
                        aria-label="add"
                        placement="top-start"
                      >
                        <span
                          id="icon-file_copy"
                          className="p3 cursor"
                          onClick={(event) =>
                            props.handleModalListAnnexesVersionOpen(
                              event,
                              object.row.annexe.hash
                            )
                          }
                        >
                          <span
                            id="icon-archive"
                            className="material-icons md-25"
                          >
                            arrow_circle_down
                          </span>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </Box>
              </Popover>
            </div>
          )}
        </PopupState>
      </div>
    );
  };

  const _handleViewComment = (value) => {
    confirmAlert({
      message: value,
      buttons: [
        {
          label: i18n.t("common:global.ok"),
        },
      ],
    });
  };

  const commentAction = (object) => {
    let column = object.column.key;
    let value = object.row[column];

    return (
      <div
        onClick={(event) => _handleViewComment(value)}
        className={classes.pointer}
      >
        {typeof value !== "undefined" && value.length > 0 && (
          <span>{value}</span>
        )}
      </div>
    );
  };

  const formatAction = (object) => {
    return <div>{renderAnnexePreview(object)}</div>;
  };

  const getUrlPreviewAnnexe = (annexe) => {
    return (
      ApplicationConfig.downloadUrl +
      environment.userhash +
      "/" +
      plugnote.hash +
      "/" +
      encodeURIComponent(annexe.name) +
      "." +
      annexe.type
    );
  };

  const renderAnnexePreview = (object) => {
    let annexeToPreview = object.row.annexe;
    let column = object.column.key;
    let value = object.row[column];

    switch (annexeToPreview.type.toLowerCase()) {
      case "jpg":
      case "JPG":
      case "jpeg":
      case "png":
      case "PNG":
      case "gif":
        return (
          <span
            onClick={(event) =>{
              props.handlePreviewAnnexe(event, annexeToPreview.hash)}
            }
            className={classes.pointer}
          >
            {value}
          </span>
        );

      case "mp4":
      case "mkv":
      case "avi":
      case "mov":
        return <span>{value}</span>;

      case "pdf":
        return (
          <span
            onClick={(event) =>
              props.handlePreviewPdfAnnexe(
                event,
                getUrlPreviewAnnexe(annexeToPreview)
              )
            }
            className={classes.pointer}
          >
            {value}
          </span>
        );

      case "zip":
      case "rar":
        return <span>{value}</span>;

      case "mp3":
      case "aac":
        return <span>{value}</span>;

      case "html":
        return <span>{value}</span>;

      case "txt":
      case "sheet":
      case "doc":
      case "docx":
        return <span>{value}</span>;

      case "xls":
      case "xlsx":
      case "csv":
        return <span>{value}</span>;

      case "note":
        return <span>{value}</span>;

      case "pages":
        return <span>{value}</span>;

      case "pptx":
      case "ppt":
        return <span>{value}</span>;
      default:
        return <span>{value}</span>;
    }
  };

  const initialRows = [];
  let initColumns = [];

  // initColumns.push({ key: 'id-plug', name:  'N°Plugnote', resizable: true, sortable : true});
  initColumns.push({
    key: "id-an",
    name: "N°",
    realName: "N°",
    resizable: true,
    sortable: true,
    width: "5%",
  });
  initColumns.push({
    key: "name",
    name: "File name",
    realName: "File name",
    resizable: true,
    sortable: true,
    width: "40%",
    formatter: commentAction,
  });
  initColumns.push({
    key: "type",
    name: "",
    realName: "Format",
    resizable: false,
    sortable: true,
    width: "5%",
    formatter: formatAction,
  });
  initColumns.push({
    key: "comment",
    name: "Comment",
    realName: "Comment",
    resizable: true,
    sortable: true,
    width: "30%",
    formatter: commentAction,
  });
  initColumns.push({
    key: "size",
    name: "Kb",
    realName: "Kb",
    resizable: true,
    sortable: true,
    width: "5%",
  });
  initColumns.push({
    key: "group",
    name: "",
    realName: "Group",
    resizable: false,
    sortable: true,
    width: "1%",
  });
  // initColumns.push({ key: 'version', name:  'Import', resizable: true, sortable : true});

  initColumns.push({
    key: "actions",
    name: "",
    resizable: false,
    sortable: false,
    formatter: allOldActions,
    width: "5%",
  });

  if (props.annexes.length > 0) {
    props.annexes.map((annexe, index) => {
      let finalObject = {};

      finalObject["annexe"] = annexe;
      //finalObject["id-plug"] = plugnote.id;
      finalObject["id-an"] = annexe.id;
      finalObject["hash"] = annexe.hash;
      finalObject["name"] = annexe.name;
      finalObject["type"] = annexe.type;
      finalObject["group"] = annexe.groupid !== 0 ? annexe.groupid : "";
      finalObject["comment"] = annexe.comment;
      finalObject["size"] = (annexe.size / 1000).toFixed(2);
      finalObject["version"] = annexe.countVersion;

      initialRows.push(finalObject);
    });
  }

  const [rows, setRows] = useState(initialRows);
  const [sortDirection, setSortDirection] = useState("NONE");
  const [sortColumn, setSortColumn] = useState("id");
  const [rowCount, setRowCount] = useState(rows.length);

  const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
  };

  useEffect(() => {
    setRows(initialRows);
    setRowCount(initialRows.length);
  }, [props.annexes]);

  let current = new Date();

  const handlePreviewClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (contentId.type) {
      case "jpg":
      case "JPG":
      case "jpeg":
      case "png":
      case "PNG":
      case "gif":
        window.open(contentId.path,"_blank")
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if(contentId.comment !== null && contentId.comment !== undefined){
      if(contentId.comment.length > 0) setOpenInfos(true)
    }
    else setOpenInfos(false)
  }, [contentId.comment])

  return (
    <Modal
      keepMounted={false}
      open={props.open}
      onClose={handleCloseModal}
      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
      className={`${classes.modal}`}
    >
      <div className={`${classes.popup}`}>
        <div className={classes.modalClose}>
            <div
              className={`${classes.close_button} material-icons`}
              onClick={handleCloseModal}
            >
                highlight_off
            </div>
        </div>
        <div className={classes.rightArrow}>
          <Tooltip
            className={`${classes.arrowNext}`}
            title={`file ${fileId + 1} of  ${props.annexes.length}`}
            placement="top"
          >
            <div
                onMouseEnter={() => setFocus('right')}
                onMouseLeave={() => setFocus('')}
              className={
                fileId + 1 >= props.annexes.length
                  ? `${classes.elVisibility}`
                  : `${classes.arrow_icon}`
              }
              onClick={nextFile}
              style={focus==='right'? {color: colors.plugnotesGris, borderRadius: 50, backgroundColor: '#fff'} : null}
            >
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </div>
          </Tooltip>
        </div>
        <div className={classes.leftArrow}>
          <Tooltip
            className={`${classes.arrowPrev}`}
            title={`file ${fileId + 1} of  ${props.annexes.length}`}
            placement="top"
          >
            <div
                onMouseEnter={() => setFocus('left')}
                onMouseLeave={() => setFocus('')}
              className={
                fileId <= 0
                  ? `${classes.elVisibility}`
                  : `${classes.arrow_icon}`
              }
              onClick={previousFile}
              style={focus==='left'? {color: colors.plugnotesGris, borderRadius: 50, backgroundColor: '#fff'} : null}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            </div>
          </Tooltip>
        </div>
        <div className={`${classes.popup_content}`}>
          <div className={`${classes.arrow_smallScreen}`} >
            <Tooltip title={`file ${fileId}`} placement="top">
              <div
                onMouseEnter={() => setFocus('left')}
                onMouseLeave={() => setFocus('')}
                className={
                  fileId <= 0
                    ? `${classes.elVisibility}`
                    : `${classes.arrow_icon}`
                }
                onClick={previousFile}
              >
                <FontAwesomeIcon icon={faArrowAltCircleLeft} />
              </div>
            </Tooltip>

            <div
                    className={`${classes.close_buttonSmall} material-icons`}
                    onClick={handleCloseModal}
                  >
                      highlight_off
                  </div>

            <Tooltip
              title={`file ${fileId + 1} of  ${props.annexes.length}`}
              placement="top"
            >
              <div
                onMouseEnter={() => setFocus('right')}
                onMouseLeave={() => setFocus('')}
                className={
                  fileId + 1 >= props.annexes.length
                    ? `${classes.elVisibility}`
                    : `${classes.arrow_icon}`
                }
                onClick={nextFile}
              >
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </div>
            </Tooltip>
          </div>
          <div className={classes.content_change}>
            <div className={classes.modalHeader}>
              {openInfos? null :
              <div className={classes.modalHeaderIcon}>
                {props.renderAnnexeIconPreview(contentId, true)}
              </div>}
              {openInfos?
                <div className={`${classes.historyField}`}>
                  <span>{contentId.name}.{contentId.type}
                  <span className={classes.modalHeaderSize}>
                    {`   `}({(contentId.size / 1000).toFixed(0)} kb)
                  </span></span>
                  <div className={classes.historyText}>

                    <div className={`${classes.fieldTitle}`}>
                      {/* {t("multiple.history")} */}
                    </div>
                    <div className={classes.historyDesc}>
                      {/* Version {fileId.countVersion} : {current.getDate()}/
                      {current.getMonth() + 1}/{current.getFullYear()} */}
                      {/* Version {contentId.countVersion} {/* / {contentId.lastversion} / {contentId.editorName} : */}
                      {moment(contentId.creationdate).format('YY/MM/DD H:m')}
                    </div>
                  </div>
                </div>
                :
                <div className={classes.modalHeaderTitle}>
                  <div className={`${classes.modalHeaderName} titleScrollHor`}>
                    {fileName}{`   `}
                    <span className={classes.modalHeaderSize}>
                    ({(contentId.size / 1000).toFixed(0)} kb)
                  </span>
                  </div>
                </div>
              }
            </div>

              <div className={classes.mainColor}>
              {/* {
              !props.locked &&
                    props.isAuthorisedToEdit &&
                    (
                      <Tooltip
                        title={i18n.t("common:actions.update")}
                        aria-label="add"
                        placement="top-start"
                      >
                        <div
                          onClick={(event) =>
                            props.handleUpdateAnnexe(event, contentId.annexe)
                          }
                            id="icon-archive"
                            className={`${classes.mainColor} material-icons md-25`}
                          >
                            publish
                        </div>
                      </Tooltip>
                    )} */}
              {!props.locked &&
              (<Tooltip title="Delete file" placement="top" >
                <span
                  id="icon-delete"
                  className={`material-icons ${classes.modalLrgIcon}`}
                  onClick={(event) => props.handleDeleteAnnexe(event, contentId, previousFile)}
                >
                  delete
                </span>
              </Tooltip>)}
              {(props.mode !== "external-shared-create" && props.mode !== "external-create" && props.mode !== 'external-shared-create' && props.mode !== 'modal-shared-create' && props.mode !== 'plugcode-complete-gallery' && props.mode !== 'plugcode-complete' ) ?
              <Tooltip title="Download file" placement="top" >
                <span
                  id="icon-download"
                  className={`material-icons ${classes.modalLrgIcon}`}
                  onClick={(event) => props.downloadEmployeeData(event, contentId)}
                >
                  download
                </span>
              </Tooltip>
              :
              null}
              {!props.locked && (
              <Tooltip
                title={`${t("actions.comment-edit")}`}
                placement="top-start"
              >
                <span
                  className={`material-icons ${classes.modalLrgIcon}`}
                  onClick={(event) => props.handleModalAnnexeOpen(event, contentId)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </span>
              </Tooltip>
              )}

              {!openInfos &&
              <Tooltip title={openInfos? "Hide details" : "Show details"} placement="top" >
                <span
                  id="icon-infos"
                  className={`material-icons ${classes.modalLrgIcon}`}
                  onClick={() => setOpenInfos(!openInfos)}
                >
                  {openInfos? 'visibility_off' : 'info'}
                </span>
              </Tooltip>}
              </div>
            <div className={`${classes.commentField}`}>
              <div className={classes.modalSubheader}>
                {!props.locked &&
                <div className={classes.modal_button}
                  onClick={(event) => props.handleDeleteAnnexe(event, contentId, previousFile)}>
                  {t('global.delete-uppercase')}
                </div>}
                {!props.locked && !openInfos &&
                <div className={classes.modal_button}
                  onClick={(event) => {setOpenInfos(true); props.handleModalAnnexeOpen(event, contentId)}}
                >
                  {t('actions.comment-uppercase')}
                </div>}
                {props.locked &&
                <div className={classes.modal_button}
                  onClick={(event) => setOpenInfos(!openInfos)}
                >
                  {openInfos? t('actions.hide-details-uppercase') : t('actions.see-details-uppercase')}
                </div>}
                <div className={classes.modal_button}
                  onClick={(event) => props.downloadEmployeeData(event, contentId)}>
                  {t('actions.download-uppercase')}
                </div>
              </div>
            </div>
            {openInfos?
              <div className={`${classes.commentField}`}>
                {contentId.comment &&
                <div>
                  <div className={classes.commentText}>
                    <div className={classes.commentEdit}>
                      {!props.locked && (
                        <Tooltip
                          title={`${t("actions.comment-edit")}`}
                          placement="top-start"
                        >
                          <div
                            className={`${classes.commentEditIcon}`}
                            onClick={(event) =>
                              props.handleModalAnnexeOpen(event, contentId)
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </div>
                        </Tooltip>
                      )}
                      <span className={`${classes.fieldTitle}`}>
                        {t("actions.comment")}:
                      </span>
                      <span className={classes.commentDesc}>{contentId.comment}</span>
                    </div>
                    <Tooltip title={openInfos? "Hide comment" : "Show details"} placement="top" >
                    <span
                      id="icon-infos"
                      style={{cursor: 'pointer', marginLeft: 'auto', color: colors.main}}
                      className={`material-icons`}
                      onClick={() => setOpenInfos(!openInfos)}
                    >
                      {openInfos? 'visibility_off' : 'info'}
                    </span>
                  </Tooltip>
                  </div>
                </div>}
              </div>
            : null}

              <div className={classes.modalAttPrev}>
                {fileIsImg &&
                  <Tooltip title="See full image" placement="top" >
                    <span
                      id="icon-zoom"
                      style={{cursor: 'pointer', marginLeft: 'auto', fontSize: 35, color: colors.main, position: 'absolute', right: 0}}
                      className={`material-icons ${classes.modalIcon}`}
                      onClick={(e) => handlePreviewClick(e)}
                    >
                      zoom_out_map
                    </span>
                  </Tooltip>}
                <div className={classes.scaledImg}>{props.renderAnnexeImagePreview(contentId)}</div>
              </div>


            <div className={classes.buttonsGroup}>
              <div className={classes.buttonsRight}>
                {/* <button
              className={`${classes.modal_button} ${classes.button_update}`}
              onClick={props.handleExitCreatePlugnote}
            >
              {t("common:multiple.update")}
            </button> */}

              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <span className={classes.modalPgNb}>{fileId + 1}/{props.annexes.length}</span>
          </div>
        </div>
        {contentId !== null && (
          <ModalAddAnnexeCommentComponent
            modal={props.modal}
            className={classes.edit_comment}
            open={props.isOpenModalAnnexe}
            close={props.handleModalAnnexeClose}
            annexe={contentId}
            selectedAnnexe={props.selectedAnnexe}
            mode={props.mode}
            handleSaveFictifComment={props.handleSaveFictifComment}
          />
        )}
        {/* {fullScreen ?
          <Modal
            fullScreen={true}
            open={fullScreen}
            onClose={setFullScreen(false)}
            fullWidth={true}
            className={classes.previewPopup}
        >
          <div className={classes.previewPopup} style={{backgroundColor: colors.blackAlpha}}>
            <div className={classes.scaledImg}>{props.renderAnnexeImagePreview(contentId)}</div>
          </div>
        </Modal>
        : null} */}
      </div>
    </Modal>
  );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
  return {
    list: props.annexes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalTestAnnexeGallery);
