import React, {useEffect, useState} from "react"
import { makeStyles } from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import colors from "../../assets/themes/Colors";
import {postExtDataReset} from "../../actions/extdata";
import {convertersAlphaToNumber, currentDate, devLog, isDefined } from "../../utils/utils";
import ModalListExtDataMultipleComponent from "../modals/extdata/ModalListExtDataMultipleComponent";
import {createEmptyRow, getAllDefaultRows, getLabelWithSpecialChar, isRowLimitReached, isVisibleInCSV, populateRowWithDefaultValues, purgeDefaultEmptyRow } from "../../utils/Fields";
import {confirmAlert} from "react-confirm-alert";
import { ModalTablemultiTableViewComponent } from "../modals/tablemulti/ModalTablemultiTableViewComponent";
import PlugTooltip from "../custom/PlugTooltip";
import { toast } from "react-toastify";
import { postSendMailV3Request } from "../../actions/utils";
import { isMobile } from "react-device-detect";


const useStyles = makeStyles((theme) => ({
    mainCtn: {
		width: isMobile ? '98vw' : '100%',
        alignItems: isMobile ? 'center' : 'stretch',
		marginTop: -50,
		overflowY: 'visible',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
        paddingBottom: 24,
    },
    mailButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
    inputArea: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    table: {
        //maxWidth : 'fit-content',
        // width: "99%",
        userSelect: 'none',
        "&::-youbkit-user-select": {
            color: 'red',
            backgroundColor: 'yellow',
        },

    },
    tableRoot: {
        width: "99%", //it was 100%
        // marginTop: theme.spacing(3),
        overflowX: "auto",
        //marginRight: 'auto',

    },
    title: {
        flexGrow: 1,
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    scrollBar: {
        marginRight: 3,
        overflowX: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '10px',
            background: '#E3E3E3',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&::-webkit-scrollbar-button:single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */

    },
    addCellButton: {
        backgroundColor: colors.greyLighter,
        borderBottom: 'none'
    },
    rowDelCellButton: {
        backgroundColor: colors.greyLighter,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rowNum: {
        color: colors.grey,
    },
    tableHeader: {
        padding: 15,
    },
    tablemultiButton: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        // fontSize: 22,
    },
    tablemultiAddButton: {
        // width : 20,
        // height : 20,
        display : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        fontSize: 28,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tablemultiExportButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.main,
        fontSize: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headerSeparator: {
        borderColor: colors.greyLight,
        borderStyle: 'solid',
        borderWidth: 0,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        padding: 10,
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 20,
        //border: '1px solid greenyellow',
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    paginationSpacer: {
        flex: 'none',
    },
    width:{
        width : '99%'
    },
    paginationStyle: {
        border: '1px solid blue',
        position: "sticky",
        left: '-10px',
        background: colors.white,
        borderWidth: 0,
    },
    tablemultiPageText: {
        color: colors.main,
        fontSize: 15,
        display: 'flex',
        gap: '8px'
    },
    tablemultiPageChoice: {
        color: colors.white,
        backgroundColor: colors.unselectedItem,
        height: 24,
        fontSize: 15,
        alignSelf: 'center',
        // fontWeight: 'bold',
        padding: '0 5px',
        borderRadius: '6px'
    },
    paginationStyleRow: {
        //border: '3px solid blue',
    },
    paginationStyleFooter: {
        //border: '3px solid blue',
    },

    //Render Item Single Select Component Styles
    renderSingleSelMainContainer: {
        justifyContent: 'space-between',
    },
    renderSingleSelContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
        minHeight: 30,
    },
    renderInputContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'no-wrap',
    },
    renderSingleSelSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    renderSingleSelPreselNum: {
        fontSize: 10,
        color: colors.greyLight,
        border: `1px solid ${colors.greyLight}`,
        borderRadius: 50,
        padding: '1px 2px',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    fontChange: {
        fontSize: 12,
        paddingLeft: '5px!important',
    },
    fontChangeLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.main
    },
    fontChangeWidth: {
        fontSize: 12,
        minWidth : 120
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formatInputFieldComposed: {
        marginLeft: '28%',
        borderRadius: "4px",
        paddingRight: '27px',
        // width: "100%",
        padding: "5px 0",
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    formatInputFieldLockedComposed: {
        backgroundColor: colors.white,
        marginLeft: '28%',
        paddingRight: '7px',
        borderRadius: "30px",
        // width: "100%",
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    upliftedContainer: {
        marginTop: '-30px',
        overflowY : 'visible'
    },
    searchField: {
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        padding: '5px 10px 0px',
        marginTop: 5,
        minHeight: 36
    },
    colorDisable: {
        color: colors.grey
    },
    notAllowedIcon: {
        position: 'absolute',
        right: 15,
        bottom: 5,
        fontSize: 20,
        textShadow: '0px 2px 1px white',
        fontWeight: 'bolder',
        cursor: 'not-allowed !important',
    },
    searchBtn: {
        fontSize: 15,
        minWidth: 65,
    },
    textFont: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
    },
    textLength: {
        fontSize: 8,
        position: 'absolute',
        top: -6,
        right: 0,
    },
    fontFieldNumber: {
        fontSize: 8,
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        padding: '0px 2px',
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 2,
    },
    selectField: {
        minHeight: 24
    },
    fieldContainer: {
        display : 'flex',
        alignItems : 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 3,
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width : 'calc(60% + 32px)',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
    },
    fieldValueContainer: {
        display: 'flex',
        marginLeft: 'auto',
        order: 2,
        width : '60%'
    },
    fieldValues: {
        color: colors.main,
        padding: 0,
        paddingLeft: 5,
        cursor: 'pointer',
        wordBreak: 'break-word',
        marginRight: 'auto',
        textAlign: 'left',
    },
    totalValues: {
        color: colors.bloodOrange,
        padding: 0,
        paddingLeft: 5,
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 12,
    },
    fieldLabels: {
        fontWeight: 'bold',
        wordBreak: 'break-word',
        color: colors.main,
        textAlign: 'left',
        padding: '10px 15px',
    },
    pageRowCtn: {
        height: 30,
        verticalAlign: 'middle',
        backgroundColor: colors.greyLighter,
        borderBottom: `1px solid ${colors.greyLight}`
    },
    pageCell: {
        textAlign: 'center',
        borderBottom: 'none',
    },
    arrowRight: {
        textAlign: 'left',
        height: 28,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    arrowLeft: {
        textAlign: 'right',
        height: 30,
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    hidden: {
        color: colors.greyLighter
    },
    tripleCell: {
        '&.MuiTableCell-root': {
            borderBottom: 'none !important'
        },
        height: 30,
        display: 'flex',
        flexDirection: 'row',
    },
    rowCtn: {
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        zIndex: 200,
        display: 'flex',
        minHeight: 30,
        width: '70%',
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center',
        paddingTop: isMobile ? 12 : 4,
    },
    topBtnCtn: {
        right: 0,
        gap: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    topBtn: {
        width: 60,
        height: 30,
        padding: 5,
        borderRadius: 5,
        boxShadow: '0px 1px 2px 1px #ddd',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: colors.mainButton,
        color: 'white',
		'&:hover': {
			boxShadow: '0px 0px 10px 1px #aaa',
			fontWeight: 'bold',
			borderColor: colors.mainButton,
		},
	},
    disabledBtn: {
        cursor: 'not-allowed',
        backgroundColor: colors.iconGrey,
        '&:hover': {
            boxShadow: '0px 0px 10px 1px #aaa',
            fontWeight: 'bold',
            borderColor: colors.iconGrey,
        },
    },
    tableRowField: {
        fontSize: 10,
        width: 30,
        margin: '0 auto',
        color: colors.main,
        "@media (max-width: 640px)": {
            fontSize: 8,
        }
    },
    valuesCell: {
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    openRow: {
        backgroundColor: colors.greyLighter,
    },
    warnToast: {
        backgroundColor: colors.unselected,
    },
}));

const FormatSMailValidMultiComponent = (props) => {
    // devLog('SMailValid got props', props)
    let tmpDisabled = false;
    if (typeof props.disabled !== "undefined"){
        tmpDisabled = props.disabled;
    }
    else if (typeof props.field.disabled !== "undefined"){
        tmpDisabled = props.field.disabled;
    }

    const classes = useStyles()
    const { t } = useTranslation(['common'])

    const plugNb = props?.plugNb
    const isAuthorizedSearch = props?.model?.authoriseExternalDbInPf !== false && props?.model?.authoriseExternalDbInPf !== 0
    const setup = props.field.options?.setup
	const defaultRows = setup?.defaultRows || []
    const rowLimit = setup?.rowLimit === 0 ? Infinity : setup?.rowLimit || Infinity
    let tmpColonnes = props.field.options?.lines?.length > 0 ? props.field?.options?.lines.filter((col) => col.visible !== false) : [];

    const [openTable, setOpenTable] = useState(false)
    const [newRow, setNewRow] = useState(null)
    const [disabled , setDisabled] = useState(tmpDisabled);
    const [open, setOpen] = useState(false)
    const [rows , setRows] = useState(props.field.value?.list?.length > 0 ? props.field?.value?.list : []);
    const [openDownload, setOpenDownload] = useState(false);
    const [datasToDownload, setDatasToDownload] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [value, setValue] = useState(!!props.field?.value?.value ? props.field?.value?.value : '');
    const [criteria, setCriteria] = useState("= xx")
    const [excessLimit, setExcessLimit] = useState(0)

	const isAddRowPossible = !disabled && rows.length < rowLimit
    const colonnes = tmpColonnes
    const owner = typeof props.owner === 'undefined' ? null: props.owner
    const ref = props.field.options.ref
    const field = props.field.options.field
    const column = props.field.options.column
    const columns = props.field.options.columns
    const dest = props.field.options.dest
    const ownerId = props.ownerId
    const hasDb = (ref !== '' && column !== '' && columns !== '') || false


    //function deleteEmpty pour supprimer une rows[n] vide dans un useEffect ([])
    function deleteEmptyRows (rows) {
        let newRows = []
        if (rows.length > 0) {
            rows.forEach((row, index) => {
                let value = ''
                Object.values(row).forEach((valeur, id) => {
                    if (valeur !== '' && valeur !== undefined) value = valeur
                })
                if (value !== '') newRows.push(row)
            })
        }
        return newRows
    }
    useEffect(() => {
        if (rows.length > 0) {
            let newRows = deleteEmptyRows(rows)
            setRows(newRows)
        } else if ((props.mode.includes('create') || props.mode.includes('complete')) && defaultRows?.length > 0) {
            if (setup?.defaultComplete === 'open') {
                let _rows = purgeDefaultEmptyRow(getAllDefaultRows(colonnes, setup?.defaultRows))
                // devLog('initial rows', _rows)
                setRows(_rows)
            }
        }
    }, [])

    function createNewRowWithDefaultValues() {
        let row = createEmptyRow(colonnes)
        return populateRowWithDefaultValues(colonnes, defaultRows, rows.length, row)
    }

    const handleAddTableMulti = async (reason) => {
        if (isDefined(reason) && reason === "once" && !disabled) {
            let newRows = purgeDefaultEmptyRow(getAllDefaultRows(colonnes, setup?.defaultRows))
            setRows(newRows)
        } else {
            let rowLimit = isDefined(setup?.rowLimit) ? setup?.rowLimit : 0
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'))

            if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                let _newRow = createNewRowWithDefaultValues()
                if (rows.length !== 0) {
                    let isEmpty = true
                    _rows.filter(Boolean);
                    let lastOne = _rows[_rows.length - 1];

                    Object.values(lastOne).forEach((k) => {
                        let control = typeof k !== "string" ? k.toString() : k;
                        if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                    })
                    if (!isEmpty) {
                        setRows([..._rows, _newRow])
                        if (!open) { setOpen(true); }
                    }
                    else {
                        setOpen(true)
                        setNewRow(lastOne)
                    }
                } else {
                    setRows([_newRow])
                    setNewRow(_newRow)
                }
            } else {
                toast.info(t('tablemulti.row-limit-warn'),
                    {
                        autoClose: 3000,
                        hideProgressBar: true,
                        bodyClassName: classes.warnToast,
                        className: classes.warnToast,
                        toastClassName: classes.warnToast,
                    })
            }
        }
        if (!openTable) setOpenTable(true)
    }

    const handleClickAddRow = (reason) => {
        let _reason = reason
		if ((!isDefined(reason) || typeof reason === "object") && rows.length === 0) { _reason = setup?.defaultComplete }
		handleAddTableMulti(_reason)
        if(!openTable) setOpenTable(true)
    }


    const handleDuplicateRow = async (row) => {
        // let _newRowDuplicated = row
        let _newRowDuplicated = { ...row }
        if (rows.length > 0) {
            let isEmpty = true
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
            _rows = _rows.filter(Boolean);
            if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                if (_rows.length > 0) {
                    let lastOne = _rows[_rows.length - 1];
                    Object.values(lastOne).forEach((k) => {
                        let control = typeof k !== "string" ? k.toString() : k;
                        if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                    })
                    if (!isEmpty) {
                        setRows([..._rows, _newRowDuplicated]);
                    }
                    else {
                        //setOpenRow(_rows.length - 1)
                    }
                }
                else {
                    setRows([_newRowDuplicated]);
                }
            } else {
                if (!toast.isActive()) {
                    toast.info(t('tablemulti.row-limit-warn'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                }
            }
        } else {
            setRows([...rows, _newRowDuplicated])
        }
    }

    const handleExportTableMulti = () => {
        let dataRows = [];
        if (colonnes.length > 0){
            let line1 = [];
            line1.push(t('export.export-date'))
            line1.push(t('export.export-key'))
            line1.push(t('export.export-number'))
            line1.push(t('export.export-field-number'))
            line1.push(t('export.export-field'))
            colonnes.forEach(col => {
                if (isVisibleInCSV(col, props.mode)) line1.push(getLabelWithSpecialChar(col.label))
            });

            dataRows.push(line1);
            rows.forEach((row, index) => {
                let dataRow = [];
                // date et heure de l'export
                // ajouter Clé du plugform
                // Numero de plugnote
                // Numero du champ
                // Libellé du champ
                let now = new Date()
                dataRow.push(now.toLocaleString())
                dataRow.push(props.plugKey)
                dataRow.push(props.plugNb)
                dataRow.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
                dataRow.push(props.title)

                colonnes.forEach(col => {
                    if (isVisibleInCSV(col, props.mode)) {
                        let someText = typeof row[col.id] === 'string'
                            ? row[col.id].replace(/\r?\n?/g, '').trim()
                            : row[col.id]
                        if (someText === undefined) {
                            someText = "";
                        }
                        dataRow.push(someText);
                }})
                dataRows.push(dataRow);
            });
        }

        if (dataRows.length > 0) {
            setDatasToDownload(dataRows);
            setOpenDownload(true);
        }
    }

    useEffect(() => {
        if (openDownload){
            setDatasToDownload([]);
            setOpenDownload(false);
        }
    }, [openDownload]);

    const handleRemoveSpecificRow = (value) => {
        let items
        if (Array.isArray(value)) {
            items = rows.filter((_, index) => !value.includes(index + 1))
        } else {
            items = rows.filter((_, index) => index !== value);
        }
        setRows(items);
    }

    const handleChangeTableValue =  () => {
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleEditValue = (value) => {
        setValue(value);
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleChangeChildrenValue =  async (value, col, rindex) => {
        let row = rows[rindex]
        if (!props.disabled && rindex >= 0) {
            switch (col.format) {
                case 'presel':
                    let multiselect = col.mask.multiselect;
                    let editable = col.mask.editable;
                    if (multiselect === false && editable === false) {
                        row[col.id] = value;
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === false && editable === true) {
                        row[col.id] = value;
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === true && editable === false) {
                        row[col.id] = value;
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === true && editable === true) {
                        row[col.id] = value;
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    break;
                case 'radio':
                    if (col.id === 1002) {
                        let colDate = colonnes.filter((colonne) => colonne.id === 1003);
                        if (colDate.length > 0) {
                            if (props.profil !== null) {
                                row[colDate[0].id] = props.profil.lastname + ' ' + props.profil.firstname + ' - ' + currentDate()
                            }
                            else row[colDate[0].id] = currentDate();
                        }
                        row[col.id] = value;
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    else {
                        row[col.id] = value;
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    break;
                default:
                    row[col.id] = value;
                    rows[rindex] = row;
                    setRows([...rows]);
                    break;
            }
        }
    }

    const handleChangeChildrenRowValue =  async (value, col, row, rindex) => {
        if (!props.disabled) {
            switch (col.format) {
                case 'extdata':
                    rows[rindex] = value;
                    setRows([...rows]);
                    break;
                default:
                    break;
            }
        }
    }

    const handleViewTable = () => {
        setOpenTable(true)
    }

    function handleCloseTable () {
        setOpenTable(false)
        setNewRow(null)
    }

    useEffect(() => {
        handleChangeTableValue()
        // setRecap(rows.length > 0 && rows[rows.length - 1].hasOwnProperty('recap'))
    }, [rows]);

    useEffect(() => {
        let tmpDisabled = false;
        if (typeof props.disabled !== "undefined"){
            tmpDisabled = props.disabled;
        }
        else if (typeof props.field.disabled !== "undefined"){
            tmpDisabled = props.field.disabled;
        }
        setDisabled(tmpDisabled);
    }, [props]);

    useEffect(()=>{
        switch (props.field.options.query) {
            case 'CONTAINS':
                setCriteria("..xx..")
                break;
            case 'START':
                setCriteria("xx..")
                break;
            case 'MULTIPLE':
                setCriteria("xxx")
                break;
            default: setCriteria("= xx")
                break;
        }
    }, [props.field.options.query])

    useEffect (() => {
        setValue(!!props.field?.value?.value ? props.field?.value?.value : "");
    }, [props.field])

    const handleOpenSearchModel = () => {
        console.log('searching DB...', )
        props.attemptGetDataFromAnotherBaseByClientReset();
        setOpenModal(true)
    }

    const handleSelectData = (objects) => {
        let datas = [];
        if (objects.length > 0) {
            objects.forEach((object, indexObject) => {
                let jsonObjectToArray = Object.values(object)
                let destinations = [];
                let champs = dest.split('-');
                champs.forEach(elm => {
                    let field = elm.split(':');
                    if (field.length > 0 && field.length <= 3) {
                        let dest = colonnes[(field[0] - 1)].id;
                        let col = field[1];
                        let copyValue = col.split(';');
                        let value = ""
                        copyValue.forEach((item, index) => {
                            let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                            let data = jsonObjectToArray[alphaNumIndexDest];
                            if (typeof data !== "undefined"){
                                let temp = data;
                                if (temp !== "" && typeof temp !== "undefined") {
                                    if (temp === null){
                                        temp = "";
                                    }
                                    if (temp !== "") {
                                        if (item === item.toUpperCase()) {
                                            value = value === "" ? value.concat(temp) : value.concat(' ~ ' + '\n' + temp);
                                        } else {
                                            value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                        }
                                    }
                                }
                            }
                        })
                        destinations.push({
                            "dest" : dest,
                            "value" : value
                        })
                    }
                })
                if (destinations.length > 0){
                    let data = createEmptyRow(colonnes)
                    destinations.forEach(item => {
                        data[item.dest] = item.value
                    })
                    datas.push(data);
                }
            });
        }
        let rowLimit = setup?.rowLimit
        console.log('importing data', datas, '(limite :', rowLimit,')')
        if (typeof rowLimit !== "undefined" && rowLimit > 0 && datas.length > rowLimit - rows.length) {
            setExcessLimit(previous => {
                let excess = datas.length - (rowLimit - rows.length)
                if (excess !== 100 && previous > 0) {
                    toast.dismiss('row-limit-toast')
                    toast.info(
                        t('tablemulti.row-limit-warn') + ': ' + (previous + excess) + t('tablemulti.rows-ignored'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                    return 0
                }
                else { return previous + excess }
            })
            datas = datas.slice(0, rowLimit - rows.length)
        }
        if (datas.length > 0) {
            const newRows = [...rows]
            datas.forEach((item) => {
                newRows.push(item);
            })
            setRows(newRows);
        }
    }

    useEffect(() => { // sert à limiter l'affichage du toast Limit à 1
		if (excessLimit > 0 && !toast.isActive('row-limit-toast')) {
			toast.warn(t('tablemulti.row-limit-warn'), {
				hideProgressBar: true,
				bodyClassName: classes.warnToast,
				className: classes.warnToast,
				toastClassName: classes.warnToast,
				toastId: 'row-limit-toast',
				isLoading: true,
				autoClose: 3000,
			})
            setTimeout(() => setExcessLimit(0), 20000)
		}
	}, [excessLimit])

    const handleClickPreview = (index, id) => {
        handleViewTable()
    }

    const handleDeleteAll = () => {
        if(!disabled) {
            confirmAlert({
                title: t('actions.delete'),
                message: t('tablemulti.confirm-delete'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () => {
                            setRows([])
                        }
                    },
                    {
                        label: t('global.no'),
                    },
                ]
            });
        }
    }

    function isLimitReached() {
		return isRowLimitReached(props.field.options?.setup?.rowLimit, rows)
    }
    const titleFormat = `[ ${field} ] ( ${ref} ) [ ${columns} ] [ ${column} ]`

    return (
        colonnes.length > 0 &&
        <div className={classes.mainCtn}>
            <div className={classes.rowCtn}>
                {
                    hasDb &&
                    <TextField
                        disabled={disabled}
                        onChange={(event) => handleEditValue(event.target.value)}
                        id="standard-full-width"
                        type="text"
                        multiline={false}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                isAuthorizedSearch && !disabled &&
                                <div className={classes.searchBtn} > {criteria}
                                    <span id="icon-listForm" className={`material-icons md-25`} style={{ color: colors.main }} onClick={() => handleOpenSearchModel()}>
                                        search
                                    </span>
                                </div>
                            ),
                        }}
                        helperText=""
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.searchField}
                        value={value}
                    />
                }
                <div className={classes.topBtnCtn}>
                    {
                        !disabled && props.field.options?.setup?.rowLimit !== 1 &&
                        <PlugTooltip title={isLimitReached() ? t('tablemulti.row-limit-warn') : t('tablemulti.add-row')} placement="top">
                            <div className={`${classes.topBtn} ${isLimitReached() ? classes.disabledBtn : undefined} `} colSpan={3} onClick={handleClickAddRow}>
                                <span id="icon-add-playlist" className={` material-icons`} style={isLimitReached() ? { cursor: 'not-allowed' } : undefined}>
                                    add_circle_outline
                                </span>
                            </div>
                        </PlugTooltip>
                    }

                    {
                        rows.length > 0 &&
                        <PlugTooltip title={t('tablemulti.table-view').concat(` ${rows.length} `).concat(t('tablemulti.rows'))} placement="top">
                            <div className={classes.topBtn} onClick={handleViewTable}>
                                <span id="icon-add-playlist" className="material-icons">
                                    visibility
                                </span>
                                <span style={{ fontSize: 10, fontWeight: 'bolder' }}>
                                    {`(${rows.length})`}
                                </span>
                            </div>
                        </PlugTooltip>
                    }
                </div>
            </div>
            <ModalTablemultiTableViewComponent
                {...props}
                authorizeDBSearch={isAuthorizedSearch}
                attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                attemptSendMailSimple={props.attemptSendMailSimple}
                colonnes={colonnes}
                controlCreate={props.controlCreate}
                controlUpdate={props.controlUpdate}
                datasToDownload={datasToDownload}
                disabled={disabled}
                field={props.field}
                fields={props.fields}
                handleAddRecap={() => null}
                handleAddTableMulti={handleClickAddRow}
                handleChangeChildrenRowValue={handleChangeChildrenRowValue}
                handleChangeChildrenValue={handleChangeChildrenValue}
                handleClickPreview={handleClickPreview}
                handleClose={handleCloseTable}
                handleDeleteAll={handleDeleteAll}
                handleDeleteRow={handleRemoveSpecificRow}
                handleDuplicateRow={handleDuplicateRow}
                handleEditValue={handleEditValue}
                handleExportTableMulti={handleExportTableMulti}
                handleOpenSearchModel={handleOpenSearchModel}
                handleSafeExitPlugnote={props.handleSafeExitPlugnote}
                hasDb={hasDb}
                isAddRowPossible={isAddRowPossible}
                isPreview={!openTable}
                isFromPwCode={props.isFromPwCode}
                mode={props.mode}
                newRow={newRow}
                open={openTable}
                openDownload={openDownload}
                openModal={openModal}
                owner={owner}
                ownerId={ownerId}
                plugnoteNb={isDefined(props.plugnote) ? props.plugnote.userindex : props.plugNb}
                renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                rows={rows}
                search={value}
                setNewRow={setNewRow}
                share={props.field?.options?.share === undefined ? false : props.field.options?.share}
                title={props.title}
            />
            {
                openModal &&
                <ModalListExtDataMultipleComponent
                    titlePerso={props.title}
                    titleFormat={titleFormat}
                    format="extdata"
                    open={openModal}
                    close={(event) => setOpenModal(false)}
                    options={props.field.options} owner={owner} ownerId={ownerId}
                    value={value} fields={props.fields}
                    handleSelectData={handleSelectData} external={props.external} />
            }
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        main : state?.user || null,
        user : state?.user?.user || null,
        user2 : state?.user || null,
        profil : state?.user?.profil || null,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
    attemptSendMailSimple: (payload, userhash) => dispatch(postSendMailV3Request(payload, userhash)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormatSMailValidMultiComponent);
