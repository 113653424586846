import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { camelCase } from "lodash";
import Images from "../../assets/themes/Images";
import { makeStyles } from "@material-ui/core/styles";
import { Content, Header } from "rsuite";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Dropzone from "react-dropzone";
import Select from "react-select";
import colors from "../../assets/themes/Colors";
import FormatMemoComponent from "../../components/formats/FormatMemoComponent";
import FormatDateComponent from "../../components/formats/FormatDateComponent";
import FormatLocalisationComponent from "../../components/formats/FormatLocalisationComponent";
import FormatGeoLocalisationComponent from "../../components/formats/FormatGeoLocalisationComponent";
import FormatPlugLinkComponent from "../../components/formats/FormatPlugLinkComponent";
import FormatMultipleSelectComponent from "../formats/FormatMultipleSelectComponent";
import FormatSingleSelectComponent from "../formats/FormatSingleSelectComponent";
import ModalTestAnnexeGallery from "../../components/modals/ModalTestAnnexeGallery";
import FormatPhoneComponent from "../formats/FormatPhoneComponent";
import FormatAgendaComponent from "../formats/FormatAgendaComponent";
import FormatNowComponent from "../formats/FormatNowComponent";
import FormatBirthdayComponent from "../formats/FormatBirthdayComponent";
import FormatTimeComponent from "../formats/FormatTimeComponent";
import FormatBooleanComponent from "../formats/FormatBooleanComponent";
import FormatPasswordComponent from "../formats/FormatPasswordComponent";
import FormatMailComponent from "../formats/FormatMailComponent";
import FormatUrlComponent from "../formats/FormatUrlComponent";
import FormatPlugcodeComponent from "../formats/FormatPlugcodeComponent";
import FormatSingleSelectTableComponent from "../formats/FormatSingleSelectTableComponent";
import FormatMultipleSelectTableComponent from "../formats/FormatMultipleSelectTableComponent";
import FormatKeywordComponent from "../formats/FormatKeywordComponent";
import FormatFormulaComponent from "../formats/FormatFormulaComponent";
import FormatPlugformComponent from "../formats/FormatPlugformComponent";
import ModalSavePlugcodeComponent from "../modals/ModalSavePlugcodeComponent";
import ModalConfirmComponent from "../modals/ModalConfirmComponent";
import { getAllTranslationsByModelOfflineRequest, } from "../../actions/translate";
import { connect } from "react-redux";
import FormatSignComponent from "../formats/FormatSignComponent";
import FormatSMailComponent from "../formats/FormatSMailComponent";
import FormatExtDataComponent from "../formats/FormatExtDataComponent";
import FormatExtDatanComponent from "../formats/FormatExtDatanComponent";
import FormatTableMultipleComponent from "../formats/FormatTableMultipleComponent";
import { _getCodePFCode, _getCodePnPpCodeTemplate } from "../../utils/Fields";
import { MdNoteAdd } from "react-icons/all";
import { Tooltip } from "@material-ui/core";
import FormatTableMultiplenComponent from "../formats/FormatTableMultiplenComponent";
import FormatSMailValidMultiComponent from "../formats/FormatSMailValidMultiComponent";
import FormatPlugcodeInternalComponent from "../formats/FormatPlugcodeInternalComponent";
import FormatIndiceComponent from "../formats/FormatIndiceComponent";
import { getToken } from "../../utils/Common";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../api";
import ModalViewFicheComponent from "../modals/ModalViewFicheComponent";
import FormatDocumentComponent from "../formats/FormatDocumentComponent";
import FormatIntDataMajComponent from "../formats/FormatIntDataMajComponent";
import { devLog, isDefined } from "../../utils/utils";
import ApplicationConfig from "../../config/ApplicationConfig";
import PlugTooltip from "../custom/PlugTooltip";
import DialogErrorSavePlugnoteComponent from "../modals/shared-components/DialogErrorSavePlugnoteComponent";
import { hasMissingMandatoryValue } from "../../utils/Plugnotes";

const specialFields = ["plugform", "textarea", "list", "single-select", "free-single-select", "multi-select", "free-multi-select", "single-table", "free-single-table", "multi-table", "free-multi-table", "qrcode", "qrcodeint", "localis", "glocalis", "tablemulti", "tablemultin", "smailvalidmulti", "extdata", "extdatan","document","intdatamaj"];
const fullwidthFields = ["tablemulti", "tablemultin", "smailvalidmulti", "document"]

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	contentModel: {
		//flexGrow: 1,
		padding: theme.spacing(2),
		overflow: "scroll",
		//maxWidth: "73%",
		"@media (max-width: 1280px)": {
			maxWidth: "80%",
		},
		"@media (max-width: 1024px)": {
			maxWidth: "100%",
			padding: 0,
		},
	},
	fieldImageSvg: {
		width: 20,
		height: 20,
		opacity: 0.7,
		marginTop: 3,
		color: colors.unselected
	},
	fieldIconRed: {
		width: 20,
		height: 20,
		opacity: 0.7,
		marginTop: 3,
		color: colors.red
	},
	fieldImage: {
		height: 10,
		width: 10,
	},
	fieldIconMandatory: {
		width: 20,
		height: 20,
		backgroundColor: "#57bbb8",
		borderRadius: 50,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: "1px solid red",
	},
	fieldIcon: {
		width: 20,
		height: 20,
		backgroundColor: "#57bbb8",
		borderRadius: 50,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	pointer: {
		cursor: "pointer",
	},

	/* ---------------------- new styles ---------------------------- */
	/* ------ format styles ------- */
	formatContainer: {
		display: "flex",
		justifyContent: 'space-between',
		backgroundColor: "white",
		width: "100%",
		marginBottom: 20,
		flex: 1,
		gap: 10,
		'@media (max-width: 640px)': {
			margin: '0px 5px 5px 5px',
			flexDirection: "column",
		},
	},
	formatColumnCtn: {
		flexDirection: 'column',
	},
	formatContent: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		flex: 1,
		gap: 5,
	},
	logoField: {
		width: '100%',
		maxHeight: 100,
		display: 'flex',
		zIndex: 1001,
		flexDirection: 'column',
		alignItems: 'center',
	},
	modeInfoCtn: {
		alignSelf: 'flex-end',
		fontSize: 10,
		color: colors.frost,
	},
	formatHeader: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		flex: 0.3,
		width: '100%',
		'@media (max-width: 640px)': {
			flexDirection: 'column',
			position: 'relative',
		},
	},
	numTitleFormat: {
		display: "flex",
		alignItems: "center",
		gap: 5,
		flex: 1,
		whiteSpace: "nowrap",
		textShadow: "none",
		justifyContent: 'space-between',
		'@media (max-width: 640px)': {
			width: '100%'
		},
		zIndex: 100,
		paddingRight: 5,
	},
	fakeFullwidthTitleFormat: {
		width: 'calc(31% - 30px)',
		alignSelf: 'flex-start',
	},
	numHeaderFormat: {
		whiteSpace: "nowrap",
		color: colors.darkgrey,
		fontWeight: "thin",
		textShadow: "none",
		fontSize: 10,
		height: "auto",
	},
	separatorHeaderFormat: {
		backgroundColor: colors.greyLight,
		width: 1,
		height: 16,
	},
	titleHeaderFormat: {
		whiteSpace: "break-spaces",
		color: colors.unselected,
		fontWeight: "bold",
		textShadow: "none",
		fontSize: 14,
		paddingRight: '5px',
		cursor: "default"
	},
	formatInputField: {
		//border: "solid #E3E3E3 1px",
		borderRadius: "4px",
		marginLeft: "auto",
		width: "100%",
		padding: "3px 10px",
		// padding: "5px 15px",
	},
	formatInputBooleanField: {
		// border: "solid #E3E3E3 1px",
		borderRadius: "4px",
		// marginLeft: "auto",
		width: "92.5%",
		// padding: "5px 15px",
	},
	formatInputFieldInline: {
		border: "solid #E3E3E3 1px",
		borderRadius: "4px",
		// width: "70%",
		flex: 0.7,
		padding: "3px 10px",
		'@media (max-width: 640px)': {
			width: 'calc(92% - 15px)',
			marginLeft: 15,
			marginBottom: 8,
		},
	},
	formatInlineNoBorder: {
		marginLeft: -10,
		flex: 0.7,
		'@media (max-width: 640px)': {
			width: 'calc(98% - 15px)',
			marginLeft: 15,
			marginBottom: 8,
		},
	},
	formatInputFieldInlineLocked: {
		backgroundColor: colors.greyLighter,
		border: "solid #E3E3E3 1px",
		borderRadius: "4px",
		flex: 0.7,
		padding: "3px 10px",
		'@media (max-width: 640px)': {
			width: 'calc(92% - 15px)',
			marginLeft: 15,
			marginBottom: 8,
		},
	},
	formatInlineNoBorderLocked: {
		backgroundColor: colors.greyLighter,
		// width: "74%",
		marginLeft: -10,
		flex: 0.7,
		'@media (max-width: 640px)': {
			width: 'calc(98% - 15px)',
			marginLeft: 15,
			marginBottom: 8,
		},
	},
	formatInputFieldComposed: {
		//border: "solid red 1px",
		borderRadius: "4px",
		marginLeft: "auto",
		width: "100%",
		padding: "5px 0",
		// overflowX: 'hidden', //temp fix for f32 size issue
		'@media (max-width: 640px)': {
			marginLeft: '-35px'
		},
	},
	formatInputFieldLocked: {
		backgroundColor: colors.greyLighter,
		border: "solid #E3E3E3 1px",
		borderRadius: "4px",
		width: "100%",
		padding: "3px 10px",
	},
	formatInputFieldLockedComposed: {
		// backgroundColor: colors.greyLighter,
		//border: "solid #E3E3E3 1px",
		borderRadius: "4px",
		width: "100%",
		// overflowX: 'hidden', //temp fix for f32 size issue
	},
	formatText: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	f01Container: {
		display: "flex",
		justifyContent: "space-between",
	},
	visibilityIcon: {
		/* border: '1px red solid', */
		display: 'inline-block',
		color: "white",
		width: "31px",
		backgroundColor: "#58c3c1",
		padding: "3px 10px",
		borderRadius: "3px",
		cursor: "pointer",
	},
	confirmContainer: {
		border: "1px solid #88949C",
		backgroundColor: "white",
		borderRadius: 15,
		padding: "10px 10px",
		height: "50vh"
	},
	confirmTitle: {
		color: colors.mainDark,
		fontSize: "30px",
	},
	confirmText: {
		paddingTop: "20px",
		color: "#88949C",
	},
	confirmButtons: {
		display: "flex",
		justifyContent: "end",
		paddingBottom: "20px"
	},
	confirmButtonStyle: {
		width: "100px",
		padding: "4px 20px",
		borderRadius: "3px",
		cursor: "pointer",
		"@media (max-width: 1024px)": {
			/* width: "100%", */
		},
	},
	confirmAffirmative: {
		color: "white",
		backgroundColor: "#58c3c1",
		border: "none",
	},
	confirmNegative: {
		color: "#E3E3E3",
		backgroundColor: "transparent",
		border: "2px solid #E3E3E3",
		fontWeight: "600",
	},

	/* ------ other styles ------- */
	mainColor: {
		color: colors.plugnotesLight,
		cursor: "default"
	},
	lockedColor: {
		color: "#e73536",
		'@media (max-width: 640px)': {
			position: 'absolute',
			right: 0,
		},
	},
	fieldBorder: {
		border: "2px solid #E3E3E3",
		borderRadius: "5px",
		// width: "100%",
		"@media (max-width: 1024px)": {
			border: "none",
		},
	},
	titleBorder: {
		border: "2px solid #E3E3E3",
		borderRadius: "5px",
		padding: 15,
		"@media (max-width: 1024px)": {
			border: "none",
		},

	},
	formContainer: {
		maxWidth: "65vw",
		height: "calc(100vh - 65px)",
		position: "relative",
		top: "65px",
		padding: 15,
		display: "flex",
		flexWrap: "wrap",
		gap: 15,
		//overflow: "auto",
		overflowX: 'auto',
		scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
		scrollbarColor: '#B9E5E4 #E3E3E3', //first color to scrollbutton and the second to the bar
		"&::-webkit-scrollbar": {
			display: "block",
			overflowX: 'auto',
			background: '#E3E3E3',
			width: '10px',
		},
		"&::-webkit-scrollbar-thumb": {
			background: '#9B9B9B',
		},
		/* border: "1px solid blue", */
		margin: "0 auto",
		//scrollbarWidth: "none",
		// "&::-webkit-scrollbar": {
		//   display: "none",
		// },
		"@media (max-width: 1024px)": {
			maxWidth: "65vw",
			top: "45px",
		},
		"@media (max-width: 700px)": {
			maxWidth: "100vw",
		},
	},
	formModalContainer: {
		maxWidth: "65vw",
		height: "calc(100vh - 50px)",
		position: "relative",
		top: "5px",
		padding: 15,
		display: "flex",
		flexWrap: "wrap",
		// gap: 15,
		overflow: "inherit",
		alignContent: 'flex-start',
		margin: "0 auto",
		"@media (max-width: 1024px)": {
			maxWidth: "65vw",
			top: "15px",
		},
		"@media (max-width: 700px)": {
			maxWidth: "100vw",
			padding: 0
		},
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	formModalContainerV2: {
		maxWidth: "65vw",
		height: "100%",
		position: "relative",
		top: "5px",
		padding: 15,
		display: "flex",
		flexWrap: "wrap",
		// gap: 15,
		overflow: "inherit",
		alignContent: 'flex-start',
		margin: "0 auto",
		"@media (max-width: 1024px)": {
			maxWidth: "65vw",
			top: "15px",
		},
		"@media (max-width: 700px)": {
			maxWidth: "100vw",
			padding: 0
		},
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	header: {
		width: "100%",
		flex: "0 0 100%",
		position: 'sticky',
		top: -115,
		"@media (max-width: 1024px)": {
			borderBottom: '1px solid #E3E3E3',
			boxShadow: '0px 2px 2px -3px rgb(0, 0, 0, 0.14)',
		},
	},
	headerInfos: {
		fontSize: '12px !important'
	},
	header_content: {
		fontSize: '13px !important',
		display: "flex",
		flexGrow: 1,
		flexDirection: "column",
		justifyContent: "space-between",
		gap: 5,
		"@media (max-width: 1300px)": {
			flexDirection: "column",
		},
	},
	sous_header_content: {
		fontSize: '13px !important',
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		gap: 5,
		"@media (max-width: 640px)": {
			flexDirection: "column-reverse",
		},
	},
	sous_title_content: {
		display: 'flex',
		flexDirection: "row",
		gap: "25px",
	},
	sous_header_content_title: {
		flexGrow: 1,
		overflow: "hidden",
		display: "flex",
		alignItems: 'center',
		gap: 3,
		width: "100%",
		"@media (max-width: 1024px)": {
			width: "100%",
		},
	},
	sous_header_content_title_input: {
		color: colors.unselected,
		width: '100%',
		"@media (max-width: 1024px)": {
			textAlign: "center",
		},
	},
	sous_header_content_buttons: {
		display: "flex",
		marginLeft: 'auto',
		gap: 5,
		maxHeight: 40,
		"@media (max-width: 1024px)": {
			// width: "100%", ancien
			width: "300px",
			justifyContent: "end",
		},
		"@media (max-width: 640px)": {
			width: '100%'
		}
	},
	sous_header_content_buttons_one: {

		display: "flex",
		marginTop : '5px',
		marginLeft: 'auto',
		gap: 5,
		maxHeight: 40,
		"@media (max-width: 1024px)": {
			width: "300px",
			justifyContent: "end",
		},
	},
	sous_header_button: {
		width: "150px",
		padding: "4px 20px",
		borderRadius: "3px",
		cursor: "pointer",
		// "@media (max-width: 1024px)": {
		//   width: "100%",
		// },
	},
	sous_header_button_one: {
		padding: "4px 20px",
		borderRadius: "3px",
		cursor: "pointer",
		"@media (max-width: 1024px)": {
			width: "100%",
		},
	},
	button_attach: {
		color: "white",
		backgroundColor: "#58c3c1",
		border: "none",
		width: 40,
		textAlignLast: 'center',
		'@media (min-width: 1301px)': {
			display: 'none'
		}
	},
	button_save: {
		color: "white",
		backgroundColor: "#58c3c1",
		border: "none",
	},
	button_cancel: {
		color: "#16868c",
		backgroundColor: "transparent",
		border: "2px solid #16868c",
		fontWeight: "600",
	},
	fieldTitle: {
		color: colors.selectedMain,
		margin: "0 0 15px 30px",
	},
	formTitle: {
		color: colors.selectedMain,
		margin: "0 0 15px 30px",
		"@media (max-width: 639px)": {
			zIndex: 1000,
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			margin: 0,
			textAlignLast: 'center',
			marginTop: -10
		},
	},
	commentField: {
		"@media (max-width: 1024px)": {
			margin: "15px 0 0",
		},
		top: 90,
		"@media (min-width: 1024px)": {
			marginTop: 15,
		},
	},
	commentContent: {
		padding: 15,
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		gap: 5,
		"@media (max-width: 1024px)": {
			flexDirection: "row",
			alignItems: "center",
			padding: 10,
			// border: `2px solid ${colors.frost}`,
		},
		"@media (max-width: 639px)": {
			border: 'none',
			// borderTop: `2px solid ${colors.frost}`,
		}
	},
	commentText: {
		display: "flex",
		flexDirection: "row-reverse",
		width: '100%',
		"@media (max-width: 1024px)": {
			alignItems: "center",
		},
	},
	commentDesc: {
		fontSize: "14px",
		"@media (max-width: 1024px)": {
			textAlign: "center",
		},
		// color: `${colors.selectedMain} !important`
	},
	contentFields: {
		overflow: 'hidden',
		height: 'fit-content',
		flexGrow: 1,
		display: "flex",
		gap: 15,
		justifyContent: "space-between",
		"@media (max-width: 1300px)": {
			flexDirection: "column",
			display: "inherit"
		},
		paddingBottom: 20,
	},
	contentFieldsColumn: {
		overflow: 'hidden',
		height: 'fit-content',
		flexGrow: 1,
		gap: 15,
		justifyContent: "space-between",
		flexDirection: "column",
		display: "inherit",
	},
	contentFieldModel: {
		padding: theme.spacing(2),
		overflowX: 'hidden', //temp fix for f32 size issue
		flex: "1 0",
		"@media (max-width: 1300px)": {
			minHeight: "auto",
		},
		"@media (max-width: 1280px)": {
			maxWidth: "100%",
			minHeight: "auto",
			//maxHeight: 'calc(100vh - 120px)',
			overflow: "overlay"
		},
		"@media (max-width: 1024px)": {
			maxWidth: "100%",
			padding: '0px 8px',
			marginBottom: 20,
			borderBottom: '1px solid #E3E3E3',
			borderRadius: 0,
		},
		"@media (max-width: 639px)": {
			padding: '0px 5px',
			maxHeight: 'max-content'
		},
	},
	attField: {
		padding: '8px',
		flex: "0 1 30%",
		// marginBottom: '25px',
		"@media (max-width: 1024px)": {
			marginTop: -25
		},
		"@media (min-width: 1301px)": {
			//position: 'fixed',
			right: '17.5vw',
			width: 'calc(17.2% - 42px)'
		},
		// },
		"@media (max-width: 1300px)": {
			flex: 'inherit',
			maxWidth: "100%",
			minHeight: "auto",
			padding: '10px 15px',
		},
	},
	attFieldEmpty: {
		'@media (max-width: 640px)': {
			display: 'none'
		},
		border: "2px solid",
		borderColor: colors.greyLight,
		borderRadius: "5px",
		height: 'fit-content',
		padding: 16,
	},
	attFieldColumn: {
		padding: '16px',
		marginBottom: '25px',
		flex: "0 1 30%",
		"@media (max-width: 1300px)": {
			flex: 'inherit',
			maxWidth: "100%",
			minHeight: "auto",
			padding: '10px 15px',
		},
	},
	attachmentTitle: {
		color: colors.selectedMain,
		fontWeight: "600",
	},
	attachmentEmpty: {
		color: "grey",
		fontStyle: "italic",
		fontSize: 15
	},
	attachmentBox: {
		margin: "0 0 25px 0",
		border: "dashed 2px #51C3C1",
		borderRadius: "5px",
		backgroundColor: "#c5e4e3",
		height: 90,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: 'center',
		alignItems: "center",
		cursor: 'pointer',
		position: 'relative',
	},
	attItemsContainer: {
		display: "flex",
		flexDirection: "column",
		gap: 5,
		overflow: "hidden",
	},
	attItem: {
		display: "flex",
		justifyContent: "space-between",
		// alignItems: "center",
		margin: "0 0 5px 0",
	},
	attAnnexName: {
		overflowX: "clip",
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none",
		},
		height: "30px",
		maxWidth: 150,
		flexGrow: 1,
		fontSize: "14px",
		cursor: "pointer",
	},
	attIconDlInfo: {
		display: "flex",
		justifyContent: "space-between",
		// alignItems: "center",
		// color: colors.main,
		width: 29,
	},
	memoCount: {
		width: '50px',
		fontSize: '10px',
		textAlign: 'center',
		color: colors.unselected,
		lineHeight: '10px',
	},
	attImgType: {
		flex: "0 0 10%",
		width: "30px",
		height: "30px",
		cursor: "pointer",
	},
	attIconType: {
		// flex: "0 0 10%",
		marginRight: '5px',
		minWidth: "50px",
		width: "30px",
		minHeight: "50px",
		// cursor: "pointer",
		'@media (max-width: 1300px)': {
			width: '10%',
			overflow: 'hidden',
			height: '100%',
			maxHeight: 50
		},
		'& .svg-inline--fa.fa-w-12': {
			height: 35,
		},
	},
	dlIcon: {
		fontSize: '40px',
		// color: colors.selectedItem
	},
	endOfContent: {
		textAlign: "center",
		// marginBottom: 50,
		marginTop: 50
	},
	//Lang select
	langCtn: {
		minWidth: 70,
		height: 25,
		zIndex: 2000,
		maxHeight: 40,
		// right: -1,
		// bottom: 19,
		position: 'relative',
		'& .css-1pahdxg-control': {
			boxShadow: 'none !important',
			borderColor: `${colors.main} !important`,
			borderWidth: 2
		},
		'& .css-1kcwhfq-singleValue:before': {
			display: 'none'
		},
		'& .css-5h2isx-placeholder:before': {
			display: 'none'
		},
		'& .css-yk16xz-control': {
			borderColor: colors.frost,
			borderWidth: '2px',
			'&:hover': {
				borderColor: colors.main
			},
			justifyContent: 'flex-end',
		},
		'& .css-g1d714-ValueContainer': {
			padding: '0px !important',
			overflow: 'visible',
			width: 40,
			left: 30,
			'& .css-1wa3eu0-placeholder': {
				left: '-30px !important',
				fontSize: 12
			},
		},
		'&.css-1iava1u-Input': {
			visibility: 'hidden !important',
		},
		'& .css-1uccc91-singleValue': {
			color: colors.unselected
		},
		'& .css-1kcwhfq-singleValue': {
			maxWidth: 100,
			// maxWidth: 'calc(100% - 4px)'
			top: 14,
			right: '-150%'
		},
		'& .css-1hb7zxy-IndicatorsContainer': {
			backgroundColor: colors.transparent,
			// display: 'none !important',
			flexDirection: 'row-reverse',
		},
		'& .css-tlfecz-indicatorContainer': {
			// display: 'none !important'
			padding: 5,
		},
		'& .css-1gtu0rj-indicatorContainer': {
			padding: 5,
		},
		'&.css-1okebmr-indicatorSeparator': {
			// display: 'none !important'
		},
		'& .css-1okebmr-indicatorSeparator': {
			width: 0
		},
	},
	infobulle: {
		backgroundColor: colors.grey,
		color: colors.white,
		border: '1px solid',
		padding: '6px 10px',
		margin: 5,
		fontSize: '18px',
		borderRadius: 5,
		whiteSpace: 'pre-line',
	},
	noTooltip: {
		backgroundColor: colors.transparent,
	},
	formatTitleCtn: {
		display: 'inline-flex',
		gap: 5,
		alignItems: 'flex-start',
	},
}));

const AttachmentPanel = (props) => {
	const { t } = useTranslation("common")
	const classes = useStyles()
	if (!props.isAuthorizedAttach && !props.isAuthorizedNote) {
		return null
	}
	return (
		<Content
			id="attZone"
			className={`${classes.attFieldColumn} ${classes.fieldBorder} scrollbar-hidden`}
		>
			{
				props.disabled === false && props.isAuthorizedNote &&
				<div
					className={`${classes.fieldTitle} ${classes.attachmentTitle} cursor icon-style`}
					onClick={(event) => props.handleCreateNote(event)}
				>
					{t("common:plugnote.add-attachment-note")}
					<div>
						<MdNoteAdd style={{ fontSize: 20, color: "black" }} />
					</div>
				</div>
			}
			<div className={`${classes.fieldTitle} ${classes.attachmentTitle}`}>
				{t("multiple.add-attachements")}
			</div>
			<Dropzone
				disabled={!props.isAuthorizedAttach || props.disabled}
				onDrop={(acceptedFiles) => props.onChangeHandlerDrop(acceptedFiles)}>
				{({ getRootProps, getInputProps }) => (
					<section className="">
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							{
								props.disabled === false && props.isAuthorizedAttach &&
								<div className={classes.attachmentBox}>
									<span className={classes.mainColor} style={{ cursor: "pointer" }}>
										{t("multiple.attachement-drag-and-drop")}
									</span>
								</div>
							}
							<div className={`${classes.fieldTitle} ${classes.attachmentTitle}`}>
								{t("multiple.current-attachements")}
							</div>

							{
								props.plugnoteAnnexes.length > 0 &&
								props.plugnoteAnnexes.map((annexe, index) => {
									return (
										<div
											className={classes.attItemsContainer}
											key={index + 1}
											onClick={(event) => {
												event.preventDefault();
												event.stopPropagation();
											}}
										>
											<PlugTooltip
												title={
													<div>
														<p className="bold">{annexe.name}</p>
														<p className="italic">{annexe.comment}</p>
														{
															annexe?.Webdoc?.content?.length > 0 &&
															<p className="italic">{annexe.Webdoc.content?.replaceAll('<p>', '').replaceAll('</p>', '\n')}</p>
														}
													</div>
												}
												placement="left">
												<div className={classes.attItem}>
													<div
														className={classes.attIconType}
														onClick={(event) => {
															props._handleOpenModalListAnnexes();
															props.setFileId(index);
														}}
													>
														{props.renderAnnexeIconPreview(annexe)}
													</div>


													<div
														className={classes.attAnnexName}
														onClick={(event) => {
															// devLog('att', annexe)
															props._handleOpenModalListAnnexes();
															props.setFileId(index);
														}}
													>
														{annexe.comment !== "" ? annexe.comment : annexe.name}
													</div>
													<div className={classes.attIconDlInfo}>
														{annexe.type === "note" && (
															<span
																id="icon-file_copy"
																className="material-icons md-25"
																onClick={(event) => props.handleNoteOpen(event, annexe)}
															>
																create
															</span>
														)}
														{annexe.type !== "note" && (
															<span
																id="icon-file_copy"
																className={`material-icons md-25 ${classes.dlIcon}`}
																onClick={(event) => props.downloadEmployeeData(event, annexe)}
															>
																{" "}
																get_app
															</span>
														)}
														{annexe.countVersion > 0 && (
															<span
																id="icon-file_copy"
																className={classes.pointer}
																onClick={(event) => props.handleModalListAnnexesVersionOpen(event, annexe.hash)}
															>
																({annexe.countVersion})
															</span>
														)}

														<div className="">
															{annexe.groupid !== 0 ? (
																<span>{annexe.groupid}</span>
															) : (
																<span />
															)}
														</div>
														{props.disabled === false &&
															<span
																id="icon-file_"
																className="material-icons"
																onClick={(event) => props.handleDeleteAnnexe(event, annexe)}
															>
																delete
															</span>}
													</div>

												</div>
											</PlugTooltip>
										</div>
									);
								})}
						</div>
						<div className={classes.endOfContent}>
							* * *
						</div>
					</section>
				)}
			</Dropzone>
		</Content>
	)
}

function Plugforms(props) {
	const executeScroll = () => {
		// attZone.current.scrollIntoView();
		let attZone = document.getElementById("attZone")
		attZone.scrollIntoView()
	}
	const { t } = useTranslation(["common", "models"], { useSuspense: false });
	const classes = useStyles();

	const [view, setView] = useState(false);
	const [textStyle, setTextStyle] = useState(false);
	const [lngOptions, setOptions] = useState([]);
	const [originalLng, setOriginalLng] = useState(null);
	const [loading, setLoading] = useState(true);
	const [lang, setLang] = useState("");
	const [nameModel, setNameModel] = useState(props.nameModel);
	const [formLogo, ] = useState(typeof props.logo !== "undefined" ? props.logo : Images['logoPng'])
	const [fileId, setFileId] = useState("");
	const [fields, setFields] = useState(props.fields);
	const [infobulles, setInfobulles] = useState(null)
	const plugOwner = props.owner === undefined ? '' : ' - ' + props.owner
	const [pnComment, setPnComment] = useState('')
	const [pfComment, setPfComment] = useState('')
	const [openComment, setOpenComment] = useState([true, true]) //pfComment, pnComment
	const [pfCode, setPfCode] = useState('')
	const [pnCode, setPnCode] = useState('')
	const showEmpty = props.showEmpty !== undefined ? props.showEmpty : false
	const [missingValues, setMissingValues] = useState(false)

	const isAuthorizedAttach = props?.model?.authAnnexeInPublicMode !== 0
	const isAuthorizedNote = props?.model?.authAnnexeInPublicMode !== 0

	const handleFormatInput = (event, inputTitle, inputText) => {
		event.preventDefault();
		event.stopPropagation();
	}

	const plugCode = props.plugnote !== null ? _getCodePnPpCodeTemplate(props.plugnote) : props.model !== null ? _getCodePFCode(props.model) : props.searchPlugcode
	const pnKey = props.plugnote !== null ? "[" + props.plugNb + "]" : ""
	const pfKey = props.model !== null ? "[" + props.model.clef + "]" : ""

	const handleChangeLanguage = (value) => {
		let language = { ...value }
		// Parsing du fichier de traduction
		Object.keys(language).forEach((entry, id) => {
			if (entry.includes('formatoptions')) language[entry] = JSON.parse(language[entry])
		})
		if (value) setLang(language); else setLang("")
	}

	function compileInfobulles() {
		if (infobulles !== null && infobulles !== undefined && props.fields.length > 0) {
			let _fields = [...props.fields]
			fields.forEach((field, fid) => {
				if (infobulles.hasOwnProperty('field' + field.id)) _fields[fid]['infobulle'] = infobulles['field' + field.id]
			})
			// console.log('Fields avec infobulles', _fields);
			setFields(_fields)
		}else{
			setFields(props.fields);
		}
	}

	useEffect(() => {
		const fetchFieldTooltips = () => {
			let hash = props.model.hash
			trackPromise(
				api.get(`models/infosbulles/infb/unique.json?mh=${hash}`),
				{
					crossdomain: true,
					'headers': {
						'X-AUTH-TOKEN': getToken(),
					}
				})
				.then((response) => {
					if (response.data.result) {
						setInfobulles(response.data.data)
					}
				})
				.catch(e => console.log(e))
		}
		if (infobulles === null && props.model.hash !== undefined) {
			fetchFieldTooltips()
		}
	}, [props.model])

	useEffect(() => {
		if (originalLng === null && props.fields.length > 0) backupOriginalLng()
	}, [fields])

	useEffect(() => {
		compileInfobulles()
	}, [infobulles])

	const handleOpenDataOrigin = (datahash) => {
		setView(true)
	}

	function backupOriginalLng() {
		let original = {
			description: "Original labels",
			label: props.model.label
		}
		fields.forEach((field, findex) => {
			let options = field.options?.lines !== undefined ? field.options.lines : field.options;
			if(typeof options !== "undefined"){
				if (options.presel !== undefined) options = options.presel
				let originalOptions = [];
				if (options.length > 0) {
					options.forEach((subfield, index) => originalOptions.push({
						id: subfield.id !== undefined ? subfield.id : index,
						label: subfield.label !== undefined ? subfield.label : subfield
					}))
				}
				original['field' + (findex + 1)] = field.title;
				original['field' + (findex + 1) + 'formatoptions'] = originalOptions;
			}
		})
		//console.log("Backed up originals :", original);
		setOriginalLng(original);
	}

	function restoreOriginalFieldLng() {
		// console.log('Fields to restore : ', fields);
		if (fields.length > 0) {
			fields
				.map((field, index) => {
					if(originalLng !== null){
						//console.log('processing field ', field);
						field['titleTranslate'] = originalLng['field' + (index + 1)]
						if (field.options?.lines !== undefined) {
							field.options.lines.map((subfield, subindex) => {
								let label = originalLng['field' + (index + 1) + 'formatoptions'].find((opt) => opt.id === subfield.id)['label']
								subfield.label = label

								return subfield;
							})
						} else if (field.format?.includes('select') && field.options?.presel !== undefined) {
							let originalPreselNb = field.originalPresel?.length // concaténation des éventuels ajouts
							field.options.presel = field.originalPresel.concat(field.options.presel.slice(originalPreselNb))
						} else if (field.options?.length > 0) {
							field.options.map((subfield, subindex) => {
								let label = originalLng['field' + (index + 1) + 'formatoptions'].find((opt) => opt.id === subfield.id)['label']
								subfield.label = label
								return subfield;
							})
						}
					}
					return field;
				})
		}
		props.setFields([...fields])
	}

	function translateFields() {
		//console.log('Fields to translate : ', fields);
		fields.map((field, index) => {
			let options = field.options?.lines !== undefined ? field.options.lines : field.options?.length > 0 ? field.options : []
			if (lang !== null && lang !== "" && lang["field" + field.id] !== "") {
				field.titleTranslate = lang["field" + field.id];
			}
			else {
				field.titleTranslate = field.title;
			}
			if (lang["field" + field.id + "formatoptions"] !== undefined) {
				let tradOptions = lang["field" + field.id + "formatoptions"];
				if (options.length > 0) {
					options.forEach((row, subindex) => {
						let trad = tradOptions.find(el => el.id === row.id)['label']
						if (trad !== '' && trad !== undefined) {
							row.label = trad
						} else {
							let tradOptions = originalLng["field" + field.id + "formatoptions"];
							let trad = (tradOptions?.lines !== undefined) ? tradOptions.lines.find(el => el.id === row.id)['label'] : tradOptions.find(el => el.id === row.id)['label']
							if (trad !== '' && trad !== undefined) {
								row.label = trad
							}

						}
					})
					if (field.options?.lines !== undefined) field.options.lines = options;
					else if (field.options?.length > 0) field.options = options;
				}
			}
			else if (originalLng["field" + field.id + "formatoptions"] !== undefined) {
				let tradOptions = originalLng["field" + field.id + "formatoptions"];
				if (options.length > 0) {
					options.forEach((row, subindex) => {
						let trad = (tradOptions?.lines !== undefined) ? tradOptions.lines.find(el => el.id === row.id)['label'] : tradOptions.find(el => el.id === row.id)['label']
						if (trad !== '' && trad !== undefined) {
							row.label = trad
						}
					})
					if (field.options?.lines !== undefined) field.options.lines = options;
					else if (field.options?.length > 0) field.options = options;
				}
			}
			return field;
		})
		props.setFields([...fields]);
	}

	useEffect(() => {
		if (typeof props.setFields !== "undefined") {
			if (lang !== null && lang !== "") {
				translateFields()
			} else {
				restoreOriginalFieldLng()
			}
		}

		if (lang !== null && lang !== "") {
			setNameModel(lang['title'] + plugOwner);
		}
		else {
			setNameModel(props.model.label + plugOwner);
		}
		if (props.codes !== undefined) setNameModel(props.model.label)
		//Champ de titre
		let fCode = props.model !== null ? _getCodePFCode(props.model) : ''
		setPfCode(fCode)
		let nCode = props.plugnote !== null ? _getCodePnPpCodeTemplate(props.plugnote) : ''
		setPnCode(nCode)

		//Champ de commentaire - PN & PF
		let pnComment = props.plugnote === null ? "" : typeof props.plugnote !== "undefined" ? props.plugnote?.pncomment ? props.plugnote.pncomment : "" : ""
		pnComment === '' ? setPnComment("") : setPnComment(pnComment)
		let comment = !isDefined(props.model.pfcomment) ? "" : props.model.pfcomment
		comment === '' ? setPfComment("") : setPfComment(comment)
	}, [lang])

	useEffect(() => {
		if (lngOptions.length !== props.list?.length) {
			let langs = [];
			props.list.forEach((i, index) => {
				langs.push({
					...i,
					value: i.id,
					title: i.label,
					label: (
						<div className={classes.translationSelect}>
							{/* eslint-disable-next-line no-undef*/}
							<img alt="" src={require('../../assets/images/flags/' + i.flag + '.svg').default} height="30px" width="30px" />
							{i.name}
						</div>
					),
					indice: index, translation: i.translationId
				});
			});
			//console.log('u-e props.list - langs', langs);
			setOptions(langs);
		}
	}, [props.list]);

	useEffect(() => {
		compileInfobulles()
	}, [props.fields])

	useEffect(() => {
		if (lngOptions.length === 0) {
			let active = true
			if (!loading) {
				return undefined;
			}
			(async () => {
				// const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
				// await sleep(1e3); // For demo purposes.
				// const countries = await response.json();
				props.attemptGetAllTranslationsOffline(props.model.hash);
			})();

			return () => {
				active = false;
			};
		}
		else {
			setLoading(false);
		}
	}, [loading]);

	const handleClickSendPlugnote = () => {
		if (handleCheckMissingMandatory() === true) {
			props.handleSaveCreatePlugnote()
		}
	}

	const handleCheckMissingMandatory = () => {
		// devLog('handleCheckMissingMandatory', )
		let missing = hasMissingMandatoryValue(fields, props.mode)
		// devLog('missing', missing)
		if (missing !== false) {
				setMissingValues(missing)
		} else return true
}

	// console.log("Plugforms.js - props :", props)
	let isFromPwCode = isDefined(props.plugnote?.pncodeprivileges)

	switch (props.mode) {
		case "external-view":
		case "modal-external-view":
		case "external-view-edit":
			return (
				<div className={`${classes.root}`} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
					<div className={`${classes.formModalContainer}`}>
						<Header className={`sticky  ${classes.header}`}>
							<div className={classes.logoField}>
								<img
									alt="form-logo"
									className={classes.fieldImage}
									src={formLogo}
									style={{ height: '100%', aspectRatio: 'auto', width: 'auto', maxHeight: 100}}
								/>
							</div>
							<div className={classes.titleBorder}>
								<div className={classes.sous_header_content}>
									{/* Lignes de titre supplémentaires */}
									<div className={classes.header_content}>
										<div className={classes.sous_header_content_title}>
											<span id="standard-full-width">
												{nameModel}
											</span>
											{props.codes !== undefined &&
												<span className={classes.headerInfos} style={{ paddingLeft: 5 }}>{`(${props.codes})`}</span>
											}
											{openComment.includes(false) ?
												<Tooltip title={"Show comments"} placement="top" >
													<span
														id="icon-infos"
														style={{ cursor: 'pointer', alignSelf: 'center' }}
														className={`material-icons md-25`}
														onClick={() => setOpenComment([true, true])}
													>
														visibility
													</span>
												</Tooltip> : null}
										</div>
										{/* <div className={classes.sous_title_content}>
                        <div className={classes.headerInfos}>
                          {pfKey} {pfCode !== ""? ' - ' + pfCode : ""}
                        </div>
                        <div className={classes.headerInfos}>
                          {pnKey} {pnCode !== ""? ' - ' + pnCode : ""}
                        </div>
                      </div> */}
									</div>
									<div className={classes.sous_header_content_buttons}>
										{
											props.model.isTranslated && !props.viewInputSearchCode && lngOptions.length > 0 &&
											<div className={classes.langCtn}>
												<Select
													// fullWidth
													id="demo-simple-select"
													isClearable
													labelId="demo-simple-select-label"
													placeholder={t('common:models.list-translation')}
													onChange={(value) => handleChangeLanguage(value)}
													onFocus={() => { if (props.list?.length < 1) setLoading(true) }}
													options={lngOptions}
													value={lang}
												/>
											</div>
										}
										<button
											className={`${classes.sous_header_button} ${classes.button_cancel}`}
											onClick={props.handleExitViewPlugnote}>
											{t("common:global.close")}
										</button>
										{props.plugnoteAnnexes.length > 0 ?
											<span
												className={`${classes.sous_header_button} ${classes.button_attach} material-icons`}
												onClick={executeScroll}
											>
												attach_file
											</span>
											: null}

										{
											props.mode === "external-view-edit" &&
											<button
												className={`${classes.sous_header_button} ${classes.button_save}`}
												onClick={handleClickSendPlugnote}
											>
												{t("common:multiple.send")}{" "}
											</button>
										}
									</div>
								</div>
							</div>
							{(pfComment?.length > 0) && props.plugnote === null && openComment[0] ? (
									<div className={`${classes.commentField} ${classes.fieldBorder}`} style={(openComment[1] && pnComment !== '') ? { marginBottom: 10 } : null}>
										<div className={classes.commentContent}>
											<div className={classes.commentText}>
											<TextField className={classes.commentDesc}
												value={pfComment}
												multiline
												style={{ width: '100%' }}
												InputProps={{ disableUnderline: true, }}
												disabled={true}
											/>
											</div>
											<Tooltip title={"Hide comment"} placement="top" >
											<span
												id="icon-infos"
												style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
												className={` material-icons md-25`}
												onClick={() => setOpenComment([false, openComment[1]])}
											>
												visibility_off
											</span>
											</Tooltip>
										</div>
									</div>)
								:
								null}
							{!props.viewInputSearchCode && !!props.model && (pnComment?.length > 0) && openComment[1] ? (
								<div className={`${classes.commentField} ${classes.fieldBorder}`}>
									<div className={classes.commentContent}>
										<div className={classes.commentText}>
											<Tooltip title={"Hide comment"} placement="top" >
												<span
													id="icon-infos"
													style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
													className={`material-icons md-25`}
													onClick={() => setOpenComment([openComment[0], false])}
												>
													visibility_off
												</span></Tooltip>
											<TextField className={classes.commentDesc}
												value={pnComment}
												multiline
												style={{ width: '100%' }}
												InputProps={{ disableUnderline: true, }}
												disabled={true}
											/>
										</div>
									</div>
								</div>
							) : null}

							{props?.model?.authoriseShowPlugLinkData === true && props.plugnote !== null && typeof props.plugnote.dataHashOrigin !== "undefined" && props.plugnote.dataHashOrigin !== "" && props.plugnote.dataHashOrigin !== null &&
								<div className={classes.sous_header_content_buttons_one}>
									{
										props.model.isTranslated && !props.viewInputSearchCode && lngOptions.length > 0 &&
										<div className={classes.langCtn}>
											<Select
												// fullWidth
												id="demo-simple-select"
												isClearable
												labelId="demo-simple-select-label"
												placeholder={t('common:models.list-translation')}
												onChange={(value) => handleChangeLanguage(value)}
												onFocus={() => { if (props.list?.length < 1) setLoading(true) }}
												options={lngOptions}
												value={lang}
											/>
										</div>
									}
									<button
										className={`${classes.sous_header_button_one} ${classes.button_save}`}
										onClick={() => handleOpenDataOrigin(props.plugnote.dataHashOrigin)}
									>
										<span>{t("common:global.open-origin")}</span>
									</button>
								</div>
							}
						</Header>

						<div className={props.mode.includes('modal') ? classes.contentFieldsColumn : classes.contentFields}>
							<Content
								className={`${classes.contentFieldModel} ${classes.fieldBorder} scrollbar-hidden`}>
								<div className={`${classes.formTitle} ${classes.attachmentTitle}`}>
									{/* {t("multiple.fields-labels-lowercase")}
                    					{pnKey} */}
									{pnCode !== "" ? pnCode : ""}
								</div>

								{typeof props.fields !== "undefined" &&
									props.fields.length > 0 &&
									props.fields.map((field, index) => {
										if (field.visible || field.visible === 1) {
											let tmpValue = typeof field.value !== "undefined" && field.value !== null ? field.value.toString() : "";
											let length = field.format === "numeric" && tmpValue === "NaN" ? 0 : tmpValue.length;
											if ((typeof tmpValue === "string" && Object(tmpValue) && tmpValue !== null && (field.format === "plugform")) || (typeof tmpValue === "object" && Object(tmpValue) && tmpValue !== null && (field.format === "qrcodeint"))) {
												let tmp = field.value;
												if (tmp.hasOwnProperty("value") || tmp.hasOwnProperty("code")) { length = tmp.value.length; } else { length = 0; }
											}
											//   console.log('field', index, ': ', field);
											if (showEmpty || length > 0)
												return (
													<div className="flexbox-container" key={index + 1}>
														<div className={`${classes.formatContainer} ${fullwidthFields.includes(field.format) && classes.formatColumnCtn}`}>
															<div className={`${classes.formatHeader} ${!props.mode.includes('modal') && classes.formatLabelCtn} ${fullwidthFields.includes(field.format) && classes.fakeFullwidthTitleFormat} `}>
																<Tooltip
																	title={field.hasOwnProperty('infobulle') ? infobulles['field' + field.id] : ""}
																	classes={{ tooltip: field.hasOwnProperty('infobulle') ? classes.infobulle : classes.noTooltip }} placement="bottom"
																>
																	<div className={classes.numTitleFormat}>
																		<span className={classes.formatTitleCtn}>
																			{
																				field.visuelFormat.toLowerCase() === "now" ?
																					<div className={field.mandatory ? classes.fieldIconMandatory : classes.fieldIcon}>
																						<img
																							className={classes.fieldImage}
																							src={Images[camelCase(field.visuelFormat.toLowerCase()) + "Icon"]}
																							alt={t("fields.format-choice-" + field.visuelFormat.toLowerCase())}
																						/>
																					</div>
																					:
																					<img
																						className={classes.fieldImageSvg}
																						src={field.mandatory ? Images[camelCase(field.visuelFormat.toLowerCase()) + "IconSvgRed"] : Images[camelCase(field.visuelFormat.toLowerCase()) + "IconSvg"]}
																						alt={t("fields.format-choice-" + field.visuelFormat.toLowerCase())}
																					/>
																			}
																			<div className={classes.separatorHeaderFormat} />
																			<div className={classes.titleHeaderFormat}>
																				{field.titleTranslate}
																			</div>
																		</span>
																		{
																			field.hasOwnProperty('infobulle') &&
																			<span
																				id="icon-file_"
																				className="material-icons"
																				style={{ color: colors.mainButton, fontSize: 25 }}
																			>
																				info
																			</span>
																		}
																	</div>
																</Tooltip>
															</div>

															{
																!specialFields.includes(field.format) && field.format === "sign" &&
																<div className={classes.formatInputFieldInlineLocked}>
																	<FormatSignComponent
																		handleEditValue={props.handleEditValue}
																		field={field}
																		index={index}
																		disabled={props.disabled || field.disabled}
																		mode={props.mode}
																		model={props?.model || null}
																		plugnote={props?.plugnote || null}
																		annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																		fields={fields}
																		onChangeHandlerDrop={props.onChangeHandlerDrop}
																		logo={props?.logo}
																	/>
																</div>
															}

															{(field.format === "boolean" ||
																	field.format === "qrcode" ||
																	field.format === "qrcodeint" ||
																	field.format === "pluglink" ||
																	field.format === "plugform") &&
																<div className={(props.disabled === false || field.disabled === false) ? classes.formatInlineNoBorder : classes.formatInlineNoBorderLocked}>

																	{!specialFields.includes(field.format) && field.format === "boolean" && (
																		<FormatBooleanComponent
																			handleChangeBoolean={props.handleChangeBoolean}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	)}

																	{specialFields.includes(field.format) && field.format === "plugform" && (
																		<FormatPlugformComponent
																			handleChangePlugform={props.handleChangePlugform}
																			mode={props.mode}
																			model={props.model}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																		/>
																	)}

																	{!specialFields.includes(field.format) && field.format === "pluglink" && (
																		<FormatPlugLinkComponent
																			enabled={true}
																			field={field}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled}
																			dataHash={props?.plugnote?.hash}
																			dataUserindex={props?.plugnote?.userindex}
																			owner={props.hashOwnerModel}
																			ownerHash={props.model.hash}
																		/>
																	)}


																	{specialFields.includes(field.format) && field.format === "qrcode" && (
																		<FormatPlugcodeComponent
																			handleChangePlugcode={props.handleChangePlugcode}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	)}

																	{specialFields.includes(field.format) && field.format === "qrcodeint" && (
																		<FormatPlugcodeInternalComponent
																			handleChangePlugcode={props.handleChangePlugcodeInternal}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																		/>
																	)}

																</div>
															}
															{(field.format === "phone" ||
																	field.format === "mail" ||
																	field.format === "smail" ||
																	field.format === "url" ||
																	field.format === "localis" ||
																	field.format === "glocalis" ||
																	field.format === "date" ||
																	field.format === "agenda" ||
																	field.format === "now" ||
																	field.format === "birthday" ||
																	field.format === "time" ||
																	field.format === "formula" ||
																	field.format === "extdata" ||
																	field.format === "extdatan" ||
																	field.format === "list" ||
																	field.format === "numeric" ||
																	field.format === "password" ||
																	field.format === "textarea" ||
																	field.format === "intdatamaj" ||
																	field.format === "indice" ||
																	(field.format.includes("table") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
																	(field.format.includes("table") && field.format.includes("multi") && !field.format.includes("tablemulti")) ||
																	(field.format.includes("select") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
																	(field.format.includes("select") && field.format.includes("multi") && !field.format.includes("tablemulti"))) &&

																<div className={(props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>
																	{
																		specialFields.includes(field.format) && field.format === "glocalis" &&
																		<FormatGeoLocalisationComponent
																			handleChangeLocalisation={props.handleChangeLocalisation}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{
																		field.format === "intdatamaj" && specialFields.includes(field.format) && field.format === "intdatamaj" &&
																		<FormatIntDataMajComponent
																			ownerId={props.model?.owner}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			mode={props.mode}
																			plugNb={props.plugnote?.userindex}
																			plugKey={props.model?.clef}
																			handleChangeIntDataMaj={props.handleChangeIntDataMaj}
																			dataHash={props?.plugnote?.hash}
																			title={field.titleTranslate}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "formula" &&
																		<FormatFormulaComponent
																			field={field}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled}
																			model={props.model}
																			fields={fields}
																			dataHasChanged={props.dataHasChanged}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "phone" &&
																		<FormatPhoneComponent
																			field={field}
																			index={index}
																			handleEditPhoneValue={props.handleEditPhoneValue}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "mail" &&
																		<FormatMailComponent
																			field={field}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "smail" &&
																		<FormatSMailComponent
																			field={field}
																			fields={fields}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled}
																			model={props?.model || null}
																			ownerHash={props?.model?.hash || null}
																			plugnote={props?.plugnote || null}
																			annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																			renderAnnexeIconPreview={props.renderAnnexeIconPreview}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "url" &&
																		<FormatUrlComponent
																			field={field}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled ||
																				field.options.setup?.editable === false ||
																				(field.options.setup?.editablePf === false && !isFromPwCode) ||
																				(field.options.setup?.editablePw === false && isFromPwCode)}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format === "localis" &&
																		<FormatLocalisationComponent
																			handleChangeLocalisation={props.handleChangeLocalisation}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "date" &&
																		<div className={classes.fieldRight}>
																			<FormatDateComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	}
																	{!specialFields.includes(field.format) && field.format === "agenda" &&
																		<div className={classes.fieldRight}>
																			<FormatAgendaComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	}

																	{!specialFields.includes(field.format) && field.format === "now" && (
																		<div className={classes.fieldRight}>
																			<FormatNowComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	)}

																	{!specialFields.includes(field.format) && field.format === "birthday" &&
																		<div className={classes.fieldRight}>
																			<FormatBirthdayComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	}

																	{!specialFields.includes(field.format) && field.format === "time" &&
																		<div className={classes.fieldRight}>
																			<FormatTimeComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	}

																	{!specialFields.includes(field.format) && field.format === "numeric" &&
																		<TextField
																			onChange={(event) => {if (event.target.value.match(/[-+]?[0-9]*[.,]?[0-9]+/) || event.target.value === "") props.handleEditValue(event.target.value, field, index)}}
																			id="standard-full-width"
																			style={{ margin: 0 }}
																			type="number"
																			autoComplete='off'
																			onWheel={(event) => event.target.blur()}
																			placeholder={t(
																				"fields.format-choice-placeholder-" +
																				field.visuelFormat.toLowerCase()
																			)}
																			onFocus={(e) => e.target.placeholder = ''}
																			onBlur={(e) => e.target.placeholder = t(
																				"fields.format-choice-placeholder-" +
																				field.visuelFormat.toLowerCase()
																			)}
																			helperText=""
																			fullWidth
																			margin="normal"
																			InputLabelProps={{shrink: true }}
																			inputProps={{ step: "0.1" }}
																			className={classes.fieldRight}
																			value={field.value}
																			InputProps={{ disableUnderline: true }}
																			disabled={props.disabled || field.disabled}
																		/>
																	}
																	{!specialFields.includes(field.format) && field.format === "password" &&
																		<FormatPasswordComponent
																			handleChangePassword={props.handleChangePassword}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}
																	{specialFields.includes(field.format) && field.format === "extdata" &&
																		<FormatExtDataComponent
																			ownerId={props.model.owner}
																			model={props.model}
																			fields={fields}
																			handleChangeExtData={props.handleChangeExtData}
																			handleEditValue={props.handleEditValue}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																			title={field.titleTranslate}
																		/>
																	}
																	{specialFields.includes(field.format) && field.format === "extdatan" &&
																		<FormatExtDatanComponent
																			model={props.model}
																			ownerId={props.model.owner}
																			fields={fields}
																			handleChangeExtDatan={props.handleChangeExtDatan}
																			handleEditValue={props.handleEditValue}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																			title={field.titleTranslate}
																		/>}

																	{specialFields.includes(field.format) && field.format.includes("select") && field.format.includes("multi") &&
																		!field.format.includes("tablemulti") &&
																		<FormatMultipleSelectComponent
																			handleChangeRadio={props.handleChangeRadio}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			expand={false}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format.includes("select") && field.format.includes("single") &&
																		<FormatSingleSelectComponent
																			handleChangeRadio={props.handleChangeRadio}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			expand={false}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format.includes("table") && field.format.includes("multi") &&
																		!field.format.includes("tablemulti") &&
																		<FormatMultipleSelectTableComponent
																			handleChangeRadio={props.handleChangeRadioTable}
																			handleChangeInputTable={props.handleChangeInputTable}
																			handleUpdateInputTable={props.handleUpdateInputTable}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			expand={false}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format.includes("table") && field.format.includes("single") &&
																		<FormatSingleSelectTableComponent
																			handleChangeRadio={props.handleChangeRadioTable}
																			handleChangeInputTable={props.handleChangeInputTable}
																			handleUpdateInputTable={props.handleUpdateInputTable}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			expand={false}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format === "list" &&
																		<FormatKeywordComponent
																			handleChangeKeyword={props.handleChangeKeyword}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format === "textarea" &&
																		<FormatMemoComponent
																			handleEditValue={props.handleEditValue}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled ||
																				field.options.setup?.editable === false ||
																				(field.options.setup?.editablePf === false && !isFromPwCode) ||
																				(field.options.setup?.editablePw === false && isFromPwCode)}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "indice" &&
																		<FormatIndiceComponent
																			handleEditValue={props.handleEditValue}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}
																</div>
															}

															{field.format === "text" &&
																<div className={(props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>
																	<div className={classes.f01Container} >
																		<TextField
																			onChange={event => props(event.target.value, field, index)}
																			id="standard-full-width"
																			style={{ margin: 0, width: '100%' }}
																			autoComplete='off'
																			placeholder={t(
																				"fields.format-choice-placeholder-" +
																				field.visuelFormat.toLowerCase()
																			)}
																			onBlur={(e) => e.target.placeholder = t(
																				"fields.format-choice-placeholder-" +
																				field.visuelFormat.toLowerCase()
																			)}
																			onFocus={(e) => e.target.placeholder = ''}
																			helperText=""
																			fullWidth
																			margin="normal"
																			InputLabelProps={{ shrink: true }}
																			inputProps={{ maxLength: 40 }}
																			value={field.value}
																			InputProps={{ disableUnderline: true }}
																			disabled={props.disabled || field.disabled ||
																				field.options.setup?.editable === false ||
																				(field.options.setup?.editablePf === false && !isFromPwCode) ||
																				(field.options.setup?.editablePw === false && isFromPwCode)}
																		/>
																		{(field.disabled === false) ? null : field.value?.length > 29 ? <span className={classes.memoCount}>{field.value.length + ' / 40'}</span> : null}
																	</div>
																</div>
															}
														</div>

														{
															field.format === "tablemulti" && specialFields.includes(field.format) &&
															<FormatTableMultipleComponent
																ownerId={props.model?.owner}
																handleChangeTableValue={props.handleChangeTableValue}
																field={field}
																index={index}
																isFromPwCode={isFromPwCode}
																disabled={props.disabled || field.disabled}
																mode={props.mode}
																plugNb={props.plugnote?.userindex}
																plugKey={props.model?.clef}
																title={field.titleTranslate}
															/>
														}
														{
															field.format === "tablemultin" && specialFields.includes(field.format) &&
															<FormatTableMultiplenComponent
																disabled={props.disabled || field.disabled}
																field={field}
																fields={fields}
																handleChangeTableValue={props.handleChangeTableRowsValue}
																index={index}
																isFromPwCode={isFromPwCode}
																owner={props.hashOwnerModel}
																ownerId={props.model.owner}
																mode={props.mode}
																model={props.model}
																plugNb={props.plugnote?.userindex}
																plugKey={props.model?.clef}
																title={field.titleTranslate}
																handleChangeExtData={props.handleChangeExtData}
															/>
														}

														{specialFields.includes(field.format) && field.format === "document" &&
															<FormatDocumentComponent
																ownerId={props.model.owner}
																handleChangeTableValue={props.handleChangeTableRowsValue}
																handleEditValue={props.handleEditValue}
																field={field}
																fields={fields}
																index={index}
															isFromPwCode={isFromPwCode}
																owner={props.hashOwnerModel}
																title={field.titleTranslate}
																mode={props.mode}
																disabled={props.disabled || field.disabled}
																model={props?.model || null}
																ownerHash={props?.model?.hash || null}
																plugKey={props.model?.clef}
																plugNb={props.plugnote?.userindex}
																plugnote={props?.plugnote || null}
																annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																renderAnnexeIconPreview={props.renderAnnexeIconPreview}
															/>
														}

														{
															(field.format === "formula" || field.format === "smailvalidmulti") &&
																<div
																	className={
																		props.disabled === false || field.disabled === false
																			? classes.formatInputFieldComposed
																			: classes.formatInputFieldLockedComposed
																	}
																>
																	{specialFields.includes(field.format) && field.format === "smailvalidmulti" &&
																		<FormatSMailValidMultiComponent
																			mode={props.mode}
																			ownerId={props.model.owner}
																			handleChangeTableValue={props.handleChangeTableRowsValue}
																			handleEditValue={props.handleEditValue}
																			field={field}
																			fields={fields}
																			index={index}
																		isFromPwCode={isFromPwCode}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																			title={field.titleTranslate}
																			model={props?.model || null}
																			plugnote={props?.plugnote || null}
																			annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																			renderAnnexeIconPreview={props.renderAnnexeIconPreview}
																			control={props.mode !== 'modal-plugnote-create' ? props.plugnote === null ? props.handleCreatePlugnote : props.handleSavePlugnote : null}
																		/>
																	}
																</div>
														}
													</div>
												);
										}
										else {
											return null;
										}
									})}
								{
									props.plugnoteAnnexes.length === 0 &&
									<div className={classes.endOfContent}>
										* * *
									</div>}
							</Content>

							{
								props.plugnoteAnnexes.length > 0 &&
								<AttachmentPanel {...props} disabled={props.mode !== 'external-view-edit'} isAuthorizedAttach={isAuthorizedAttach} isAuthorizedNote={isAuthorizedNote} setFileId={setFileId} />
								/* <Content
									id="attZone"
									className={`${props.mode.includes('modal') ? classes.attFieldColumn : classes.attField} ${classes.fieldBorder} scrollbar-hidden`}
								>
									{props.disabled === false ? (
										<div className={`${classes.fieldTitle} ${classes.attachmentTitle}`}>
											{t("multiple.add-attachements")}
										</div>) : null}

									<Dropzone onDrop={(acceptedFiles) => props.onChangeHandlerDrop(acceptedFiles)}>
										{({ getRootProps, getInputProps }) =>
											<section className="">
												<div {...getRootProps()}>
													{props.disabled === false ? (
														<input {...getInputProps()} />
													) : null}

													{props.disabled === false ? (
														<div className={classes.attachmentBox}>
															<span className={classes.mainColor} style={{ cursor: "pointer" }}>
																{t("multiple.attachement-drag-and-drop")}
															</span>
														</div>

													) : null}

													<div className={`${classes.fieldTitle} ${classes.attachmentTitle}`}>
														{t("multiple.current-attachements")}{" "}
													</div>

													{props.plugnoteAnnexes.map((annexe, index) => {
														return (
															<div
																className={classes.attItemsContainer}
																key={index + 1}
																onClick={(event) => {
																	event.preventDefault();
																	event.stopPropagation();
																}}
															>
																<div className={classes.attItem}>
																	<div
																		className={classes.attIconType}
																		onClick={(event) => {
																			event.preventDefault();
																			event.stopPropagation();
																			props._handleOpenModalListAnnexes();
																			setFileId(index);
																		}}
																	>
																		{props.renderAnnexeIconPreview(annexe)}
																	</div>

																	<div
																		className={classes.attAnnexName}
																		onClick={(event) => {
																			props._handleOpenModalListAnnexes();
																			setFileId(index);
																		}}
																	>
																		{annexe.comment !== "" ? annexe.comment : annexe.name}
																	</div>

																	<div className={classes.attIconDlInfo}>
																		{/* { !locked && isAuthorisedToEdit(annexe.type)  &&
                                  <span id="icon-file_copy" className="material-icons md-25" onClick={(event) => handleUpdateAnnexe(event, annexe)}>
                                    publish
                                  </span>}
																		{annexe.type !== "note" && (
																			<span
																				id="icon-file_copy"
																				className={`material-icons md-25 ${classes.dlIcon}`}
																				onClick={(event) =>
																					props.downloadEmployeeData(event, annexe)
																				}
																			>
																				{" "}
																				get_app
																			</span>
																		)}

																		{annexe.type === "note" && (
																			<span
																				id="icon-file_copy"
																				className={`material-icons md-25 ${classes.dlIcon}`}
																				onClick={(event) =>
																					props.handleNoteOpen(event, annexe)
																				}
																			>
																				visibility_on
																			</span>
																		)}

																		{annexe.countVersion > 0 && (
																			<span
																				id="icon-file_copy"
																				className={classes.pointer}
																				onClick={(event) =>
																					props.handleModalListAnnexesVersionOpen(
																						event,
																						annexe.hash
																					)
																				}
																			>
																				({annexe.countVersion})
																			</span>
																		)}

																		<div className="">
																			{annexe.groupid !== 0 ? (
																				<span>{annexe.groupid}</span>
																			) : (
																				<span />
																			)}
																		</div>
																		{props.disabled === false &&
																			<span
																				id="icon-file_"
																				className="material-icons"
																				onClick={(event) => props.handleDeleteAnnexe(event, annexe)}
																			>
																				delete
																			</span>
																		}
																	</div>
																</div>
															</div>
														);
													})
													}
													<div className={classes.endOfContent}>
														* * *
													</div>
												</div>
											</section>
										}
									</Dropzone>
								</Content> */

							}
							{
								props.plugnoteAnnexes.length > 0 && props.isOpenModalListAnnexes &&
								<ModalTestAnnexeGallery
									locked={props.disabled}
									mode={props.mode}
									disabledDelete={props.disabled}
									open={props.isOpenModalListAnnexes}
									close={props._handleCloseModalListAnnexes}
									annexes={props.plugnoteAnnexes}
									isAuthorisedToEdit={props.isAuthorisedToEdit}
									downloadEmployeeData={props.downloadEmployeeData}
									handleUpdateAnnexe={props.handleUpdateAnnexe}
									handleModalAnnexeOpen={props.handleModalAnnexeOpen}
									handleNoteOpen={props.handleNoteOpen}
									handleDeleteAnnexe={props.handleDeleteAnnexe}
									handleModalListAnnexesVersionOpen={
										props.handleModalListAnnexesVersionOpen
									}
									handlePreviewAnnexe={props.handlePreviewAnnexe}
									handlePreviewPdfAnnexe={props.handlePreviewPdfAnnexe}
									fileId={fileId}
									renderAnnexeIconPreview={props.renderAnnexeIconPreview}
									renderAnnexeImagePreview={props.renderAnnexeImagePreview}
									isOpenModalAnnexe={props.isOpenModalAnnexe}
									selectedAnnexe={props.selectedAnnexe}
									handleModalAnnexeClose={props.handleModalAnnexeClose}
								/>
							}
						</div>
					</div>
					{
						missingValues &&
						<DialogErrorSavePlugnoteComponent
                handleClickOk={() => setMissingValues(false)}
                missingValues={missingValues}
                openModal={missingValues !== false}
            />
					}
					{
						props.openSave && !props.viewInputSearchCode && props.mode === "external-view-edit" &&
						<ModalConfirmComponent
							external={true}
							open={props.openSave}
							close={props.handleCloseSave}
							handleChangeEmail={props.handleChangeEmail}
							handleChangeSendCopy={props.handleChangeSendCopy}
							handleSaveCreatePlugnoteConfirm={props.handleSaveCreatePlugnoteConfirm}
							copy={props.copy}
							emailValid={props.emailValid}
							email={props.email}
						/>
					}
					{
						view &&
						<ModalViewFicheComponent
							mode={props.mode}
							open={view}
							dataHash={props.plugnote.dataHashOrigin}
							close={() => setView(false)}
						/>
					}
				</div>
			);

		case "modal-external-create":
		case "external-create":
			return (
				<div className={`${classes.root}`} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
					<div className={`${classes.formModalContainerV2}`}>
						<Header className={`sticky  ${classes.header}`}>
							<div className={classes.logoField}>
								<img
									alt="form-logo"
									className={classes.fieldImage}
									src={formLogo}
									style={{ height: '100%', aspectRatio: 'auto', width: 'auto', maxHeight: 100}}
								/>
							</div>
							{
								ApplicationConfig.devMode &&
								<div className={classes.modeInfoCtn} >{props.mode}</div>
							}
							<div className={classes.titleBorder}>
								<div className={classes.sous_header_content}>
									{/* Lignes de titre supplémentaires */}
									<div className={classes.header_content}>
										<div className={classes.sous_header_content_title}>
											<Input
												id="standard-full-width"
												readOnly={true}
												label="Label"
												multiline
												placeholder={t("common:models.form-title")}
												helpertext="Full width!"
												fullWidth={true}
												margin="none"
												disableUnderline={true}
												inputProps={{
													className: classes.sous_header_content_title_input,
												}}
												className={classes.sous_header_content_title_input}
												value={nameModel}
											/>
											{openComment.includes(false) ?
												<PlugTooltip title={"Show comments"} placement="top" >
													<span
														id="icon-infos"
														style={{ cursor: 'pointer', alignSelf: 'center' }}
														className={` material-icons md-25`}
														onClick={() => setOpenComment([true, true])}
													>
														visibility
													</span>
												</PlugTooltip> : null}
										</div>
									</div>
									<div className={classes.sous_header_content_buttons}>
										{
											props.model.isTranslated && !props.viewInputSearchCode && lngOptions.length > 0 &&
											<div className={classes.langCtn}>
												<Select
													// fullWidth
													id="demo-simple-select"
													isClearable
													labelId="demo-simple-select-label"
													placeholder={t('common:models.list-translation')}
													onChange={(value) => handleChangeLanguage(value)}
													onFocus={() => { if (props.list?.length < 1) setLoading(true) }}
													options={lngOptions}
													value={lang}
												/>
											</div>
										}
										<button
											className={`${classes.sous_header_button} ${classes.button_cancel}`}
											onClick={props.handleExitCreatePlugnote}
										>
											{t("common:global.close")}
										</button>
										<span
											className={`${classes.sous_header_button} ${classes.button_attach} material-icons`}
											onClick={executeScroll}
										>
											attach_file
										</span>
										<button
											className={`${classes.sous_header_button} ${classes.button_save}`}
											onClick={handleClickSendPlugnote}
										>
											{t("common:multiple.send")}{" "}
										</button>
									</div>
								</div>
							</div>
							{(pfComment?.length > 0) && props.plugnote === null && openComment[0] ? (
								<div className={`${classes.commentField} ${classes.fieldBorder}`} style={openComment[1] ? { marginBottom: 10 } : null}>
									<div className={classes.commentContent}>
										<div className={classes.commentText}>
											<TextField className={classes.commentDesc}
												value={pfComment}
												multiline
												style={{ width: '100%' }}
												InputProps={{ disableUnderline: true, }}
												disabled={true}
											/>
										</div>
										<Tooltip title={"Hide comment"} placement="top" >
											<span
												id="icon-infos"
												style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
												className={` material-icons md-25`}
												onClick={() => setOpenComment([false, openComment[1]])}
											>
												visibility_off
											</span>
										</Tooltip>
									</div>
								</div>
							) : null}
							{!props.viewInputSearchCode && !!props.model && (pnComment?.length > 0) && openComment[1] ? (
								<div className={`${classes.commentField} ${classes.fieldBorder}`}>
									<div className={classes.commentContent}>
										<div className={classes.commentText}>
											<Tooltip title={"Hide comment"} placement="top">
												<span
													id="icon-infos"
													style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
													className={` material-icons md-25`}
													onClick={() => setOpenComment([openComment[0], false])}
												>
													visibility_off
												</span>
											</Tooltip>
											<TextField className={classes.commentDesc}
												value={pnComment}
												multiline
												style={{ width: '100%' }}
												InputProps={{ disableUnderline: true, }}
												disabled={true}
											/>
										</div>
									</div>
								</div>
							) : null}
						</Header>

						<div className={props.mode.includes('modal') ? classes.contentFieldsColumn : classes.contentFields}>
							{!props.viewInputSearchCode &&
								<Content
									className={`${classes.contentFieldModel} ${classes.fieldBorder} scrollbar-hidden`}
								>
									<div className={classes.formTitle}>
										{/* {t("multiple.form-title")}
                      {pfKey} */}
										{pfCode !== "" ? pfCode : ""}
									</div>

									{
										typeof props.fields !== "undefined" &&
										props.fields.length > 0 &&
										props.fields.map((field, index) => {
											if (field.encodable) {
												return (
													<div className="flexbox-container" key={index + 1}>
														<div className={`${classes.formatContainer} ${fullwidthFields.includes(field.format) && classes.formatColumnCtn}`}>
															<div className={`${classes.formatHeader} ${fullwidthFields.includes(field.format) && classes.fakeFullwidthTitleFormat}`}>
																<Tooltip
																	title={field.hasOwnProperty('infobulle') ? infobulles['field' + field.id] : ""}
																	classes={{ tooltip: field.hasOwnProperty('infobulle') ? classes.infobulle : classes.noTooltip }} placement="bottom"
																>
																	<div className={classes.numTitleFormat}>
																		<span className={classes.formatTitleCtn}>
																			{field.visuelFormat.toLowerCase() === "now" ? (
																				<div
																					className={
																						field.mandatory
																							? classes.fieldIconMandatory
																							: classes.fieldIcon
																					}
																				>
																					<img
																						className={classes.fieldImage}
																						src={
																							Images[
																							camelCase(
																								field.visuelFormat.toLowerCase()
																							) + "Icon"
																							]
																						}
																						alt={t(
																							"fields.format-choice-" +
																							field.visuelFormat.toLowerCase()
																						)}
																					/>
																				</div>
																			) : (
																				<img
																					className={field.mandatory ? classes.fieldIconRed : classes.fieldImageSvg}
																					src={
																						field.mandatory
																							? Images[
																							camelCase(
																								field.visuelFormat.toLowerCase()
																							) + "IconSvgRed"
																							]
																							: Images[
																							camelCase(
																								field.visuelFormat.toLowerCase()
																							) + "IconSvg"
																							]
																					}
																					alt={t(
																						"fields.format-choice-" +
																						field.visuelFormat.toLowerCase()
																					)}
																				/>
																			)}
																			<div className={classes.separatorHeaderFormat} />
																			<div className={classes.titleHeaderFormat}>
																				{field.titleTranslate}
																			</div>
																		</span>
																		{
																			field.hasOwnProperty('infobulle') &&
																			<span
																				id="icon-file_"
																				className="material-icons"
																				style={{ color: colors.mainButton, fontSize: 25 }}
																			>
																				info
																			</span>
																		}
																	</div>
																</Tooltip>
															</div>

															{!specialFields.includes(field.format) && field.format === "sign" &&
																<div className={classes.formatInputFieldInlineLocked}>
																	<FormatSignComponent
																		handleEditValue={props.handleEditValue}
																		field={field}
																		index={index}
																		disabled={props.disabled || field.disabled}
																		mode={props.mode}
																		model={props?.model || null}
																		plugnote={props?.plugnote || null}
																		annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																		fields={fields}
																		onChangeHandlerDrop={props.onChangeHandlerDrop}
																		logo={props?.logo}
																	/>
																</div>
															}

															{(
																field.format === "pluglink" ||
																field.format === "qrcode" ||
																field.format === "qrcodeint" ||
																field.format === "plugform" ||
																field.format === "boolean") &&
																<div className={(props.disabled === false || field.disabled === false) ? classes.formatInlineNoBorder : classes.formatInlineNoBorderLocked}>

																	{!specialFields.includes(field.format) && field.format === "pluglink" && (
																		<FormatPlugLinkComponent
																			enabled={false}
																			field={field}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled}
																			dataHash={props?.plugnote?.hash}
																			owner={props.hashOwnerModel}
																			ownerHash={props.model.hash}
																		/>
																	)}
																	{specialFields.includes(field.format) && field.format === "qrcode" &&
																		<FormatPlugcodeComponent
																			handleChangePlugcode={props.handleChangePlugcode}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format === "qrcodeint" &&
																		<FormatPlugcodeInternalComponent
																			handleChangePlugcode={props.handleChangePlugcodeInternal}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "boolean" && (
																		<FormatBooleanComponent
																			handleChangeBoolean={
																				props.handleChangeBoolean
																			}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	)}

																	{specialFields.includes(field.format) && field.format === "plugform" && (
																		<FormatPlugformComponent
																			model={props.model}
																			mode={props.mode}
																			handleChangePlugform={
																				props.handleChangePlugform
																			}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																		/>
																	)}
																</div>
															}

															{(field.format === "phone" ||
																field.format === "mail" ||
																field.format === "smail" ||
																field.format === "url" ||
																field.format === "localis" ||
																field.format === "glocalis" ||
																field.format === "date" ||
																field.format === "agenda" ||
																field.format === "now" ||
																field.format === "birthday" ||
																field.format === "time" ||
																field.format === "formula" ||
																field.format === "extdata" ||
																field.format === "extdatan" ||
																field.format === "list" ||
																field.format === "numeric" ||
																field.format === "password" ||
																field.format === "textarea" ||
																field.format === "intdatamaj" ||
																field.format === "indice" ||
																(field.format.includes("table") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
																(field.format.includes("table") && field.format.includes("multi") && !field.format.includes("tablemulti")) ||
																(field.format.includes("select") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
																(field.format.includes("select") && field.format.includes("multi") && !field.format.includes("tablemulti"))) &&
																<div className={(props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>

																	{!specialFields.includes(field.format) && field.format === "formula" &&
																		<FormatFormulaComponent
																			field={field}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled}
																			model={props.model}
																			fields={fields}
																			dataHasChanged={props.dataHasChanged}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format === "textarea" && (
																		<FormatMemoComponent
																			handleEditValue={props.handleEditValue}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled ||
																				field.options.setup?.editable === false ||
																				(field.options.setup?.editablePf === false && !isFromPwCode) ||
																				(field.options.setup?.editablePw === false && isFromPwCode)}
																		/>
																	)}

																	{!specialFields.includes(field.format) && field.format === "indice" && (
																		<FormatIndiceComponent
																			handleEditValue={props.handleEditValue}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	)}

																	{specialFields.includes(field.format) && field.format.includes("select") && field.format.includes("multi") &&
																		!field.format.includes("tablemulti") &&
																		<FormatMultipleSelectComponent
																			handleChangeRadio={props.handleChangeRadio
																			}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			expand={false}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format.includes("select") && field.format.includes("single") &&
																		<FormatSingleSelectComponent
																			handleChangeRadio={props.handleChangeRadio
																			}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			expand={false}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format.includes("table") && field.format.includes("multi") &&
																		!field.format.includes("tablemulti") &&
																		<FormatMultipleSelectTableComponent
																			handleChangeRadio={props.handleChangeRadioTable
																			}
																			handleChangeInputTable={props.handleChangeInputTable
																			}
																			handleUpdateInputTable={props.handleUpdateInputTable
																			}
																			handleDeleteInputTable={props.handleDeleteInputTable
																			}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			expand={false}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format.includes("table") && field.format.includes("single") &&
																		<FormatSingleSelectTableComponent
																			handleChangeRadio={props.handleChangeRadioTable
																			}
																			handleChangeInputTable={props.handleChangeInputTable
																			}
																			handleUpdateInputTable={props.handleUpdateInputTable
																			}
																			handleDeleteInputTable={props.handleDeleteInputTable
																			}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			expand={false}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format === "list" &&
																		<FormatKeywordComponent
																			handleChangeKeyword={props.handleChangeKeyword
																			}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "phone" &&
																		<FormatPhoneComponent
																			field={field}
																			index={index}
																			handleEditPhoneValue={props.handleEditPhoneValue
																			}
																			disabled={props.disabled || field.disabled}
																		/>
																	}


																	{!specialFields.includes(field.format) && field.format === "mail" &&
																		<FormatMailComponent
																			field={field}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "smail" &&
																		<FormatSMailComponent
																			field={field}
																			fields={fields}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled}
																			model={props?.model || null}
																			ownerHash={props?.model?.hash || null}
																			plugnote={props?.plugnote || null}
																			annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																			renderAnnexeIconPreview={props.renderAnnexeIconPreview}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "url" &&
																		<FormatUrlComponent
																			field={field}
																			index={index}
																			handleEditValue={props.handleEditValue}
																			disabled={props.disabled || field.disabled ||
																				field.options.setup?.editable === false ||
																				(field.options.setup?.editablePf === false && !isFromPwCode) ||
																				(field.options.setup?.editablePw === false && isFromPwCode)}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format === "localis" &&
																		<FormatLocalisationComponent
																			handleChangeLocalisation={props.handleChangeLocalisation}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format === "glocalis" &&
																		<FormatGeoLocalisationComponent
																			handleChangeLocalisation={props.handleChangeLocalisation
																			}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}

																	{!specialFields.includes(field.format) && field.format === "date" &&
																		<div className={classes.fieldRight}>
																			<FormatDateComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	}
																	{!specialFields.includes(field.format) && field.format === "agenda" && (
																		<div className={classes.fieldRight}>
																			<FormatAgendaComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	)}

																	{!specialFields.includes(field.format) && field.format === "now" && (
																		<div className={classes.fieldRight}>
																			<FormatNowComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	)}

																	{!specialFields.includes(field.format) && field.format === "birthday" && (
																		<div className={classes.fieldRight}>
																			<FormatBirthdayComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	)}

																	{!specialFields.includes(field.format) && field.format === "time" && (
																		<div className={classes.fieldRight}>
																			<FormatTimeComponent
																				field={field}
																				handleEditDateTime={props.handleEditDateTime}
																				index={index}
																				disabled={props.disabled || field.disabled}
																			/>
																		</div>
																	)}

																	{!specialFields.includes(field.format) && field.format === "numeric" && (
																		<TextField
																			onChange={(event) => {
																				if (event.target.value.match(/[-+]?[0-9]*[.,]?[0-9]+/) || event.target.value === "") props.handleEditValue(
																					event.target.value,
																					field,
																					index
																				)
																			}
																			}
																			id="standard-full-width"
																			style={{ margin: 0 }}
																			type="number"
																			autoComplete='off'
																			onWheel={(event) => event.target.blur()}
																			placeholder={t(
																				"fields.format-choice-placeholder-" +
																				field.visuelFormat.toLowerCase()
																			)}
																			onFocus={(e) => e.target.placeholder = ''}
																			onBlur={(e) => e.target.placeholder = t(
																				"fields.format-choice-placeholder-" +
																				field.visuelFormat.toLowerCase()
																			)}
																			helperText=""
																			fullWidth
																			margin="normal"
																			InputLabelProps={{ shrink: true }}
																			inputProps={{ step: "0.1" }}
																			className={classes.fieldRight}
																			value={field.value}
																			InputProps={{ disableUnderline: true }}
																			disabled={props.disabled || field.disabled}
																		/>
																	)}
																	{!specialFields.includes(field.format) && field.format === "password" &&
																		<FormatPasswordComponent
																			handleChangePassword={props.handleChangePassword}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																		/>
																	}
																	{specialFields.includes(field.format) && field.format === "extdata" && (
																		<FormatExtDataComponent
																			ownerId={props.model.owner}
																			fields={fields}
																			model={props.model}
																			handleChangeExtData={props.handleChangeExtData}
																			handleEditValue={props.handleEditValue}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																			title={field.titleTranslate}
																		/>
																	)}

																	{
																		field.format === "intdatamaj" && specialFields.includes(field.format) && field.format === "intdatamaj" &&
																		<FormatIntDataMajComponent
																			ownerId={props.model?.owner}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			mode={props.mode}
																			plugNb={props.plugnote?.userindex}
																			plugKey={props.model?.clef}
																			handleChangeIntDataMaj={props.handleChangeIntDataMaj}
																			dataHash={props?.plugnote?.hash}
																			title={field.titleTranslate}
																		/>
																	}

																	{specialFields.includes(field.format) && field.format === "extdatan" && (
																		<FormatExtDatanComponent
																			ownerId={props.model.owner}
																			fields={fields}
																			model={props.model}
																			handleChangeExtDatan={props.handleChangeExtDatan}
																			handleEditValue={props.handleEditValue}
																			field={field}
																			index={index}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																			title={field.titleTranslate}
																		/>)}
																</div>
															}

															{
																field.format === "text" && (
																	<div className={(props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>
																		<div className={classes.f01Container} >
																			<TextField
																				onChange={event => props.handleEditValue(event.target.value, field, index)}
																				autoComplete='off'
																				multiline={textStyle}
																				id="standard-full-width"
																				style={{ margin: 0, width: '100%' }}
																				placeholder={t(
																					"fields.format-choice-placeholder-" +
																					field.visuelFormat.toLowerCase()
																				)}
																				onBlur={(e) => e.target.placeholder = t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
																				onFocus={(e) => e.target.placeholder = ''}
																				helperText=""
																				fullWidth
																				margin="normal"
																				InputLabelProps={{ shrink: true }}
																				inputProps={{ maxLength: 40 }}
																				value={field.value}
																				InputProps={{ disableUnderline: true }}
																				disabled={props.disabled || field.disabled ||
																					field.options.setup?.editable === false ||
																					(field.options.setup?.editablePf === false && !isFromPwCode) ||
																					(field.options.setup?.editablePw === false && isFromPwCode)}
																			/>
																			{(field.disabled === true) ? null : field.value.length > 35 ? <span className={classes.memoCount}>{field.value.length + ' / 40'}</span> : null}
																		</div>
																	</div>
																)
															}

															{
																field.format === "tablemulti" && specialFields.includes(field.format) && field.format === "tablemulti" &&
																<FormatTableMultipleComponent
																	disabled={props.disabled || field.disabled}
																	field={field}
																	handleChangeTableValue={props.handleChangeTableValue}
																	index={index}
																	isFromPwCode={isFromPwCode}
																	mode={props.mode}
																	plugKey={props.model?.clef}
																	plugNb={props.plugnote?.userindex}
																	ownerId={props.model?.owner}
																	title={field.titleTranslate}
																/>
															}
															{
																field.format === "tablemultin" && specialFields.includes(field.format) && (field.format === "tablemultin") &&
																<FormatTableMultiplenComponent
																	disabled={props.disabled || field.disabled}
																	field={field}
																	fields={fields}
																	handleChangeTableValue={props.handleChangeTableRowsValue}
																	index={index}
																	isFromPwCode={isFromPwCode}
																	mode={props.mode}
																	model={props.model}
																	plugKey={props.model?.clef}
																	plugNb={props.plugnote?.userindex}
																	owner={props.hashOwnerModel}
																	ownerId={props.model.owner}
																	title={field.titleTranslate}
																	handleChangeExtData={props.handleChangeExtData}
																/>
															}
															{
																field.format === "smailvalidmulti" &&
																<div
																	className={
																		props.disabled === false || field.disabled === false
																			? classes.formatInputFieldComposed
																			: classes.formatInputFieldLockedComposed
																	}
																>
																	{
																		specialFields.includes(field.format) && field.format === "smailvalidmulti" &&
																		<FormatSMailValidMultiComponent
																			mode={props.mode}
																			ownerId={props.model.owner}
																			handleChangeTableValue={props.handleChangeTableRowsValue}
																			handleEditValue={props.handleEditValue}
																			field={field}
																			fields={fields}
																			index={index}
																			isFromPwCode={isFromPwCode}
																			disabled={props.disabled || field.disabled}
																			owner={props.hashOwnerModel}
																			title={field.titleTranslate}
																			model={props?.model || null}
																			plugnote={props?.plugnote || null}
																			annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																			renderAnnexeIconPreview={props.renderAnnexeIconPreview}
																			control={props.mode !== 'modal-plugnote-create' ? props.plugnote === null ? props.handleCreatePlugnote : props.handleSavePlugnote : null}
																		/>
																	}
																</div>
															}
														</div>
													</div>
												);
											} else {
												return null;
											}
										})
									}
								</Content>
							}

							{
								!props.viewInputSearchCode &&
								<AttachmentPanel {...props} disabled={false} isAuthorizedAttach={isAuthorizedAttach} isAuthorizedNote={isAuthorizedNote} setFileId={setFileId} />

								/* <Content
									id="attZone"
									className={`${props.mode.includes('modal') ? classes.attFieldColumn : classes.attField} ${classes.fieldBorder} scrollbar-hidden`}
								>
									<div
										className={`${classes.fieldTitle} ${classes.attachmentTitle} cursor icon-style`}
										onClick={(event) => props.handleCreateNote(event)}
									>
										{t("common:plugnote.add-attachment-note")}
										<div>
											<MdNoteAdd style={{ fontSize: 20, color: "black" }} />
										</div>
									</div>{" "}
									<div className={`${classes.fieldTitle} ${classes.attachmentTitle}`}>
										{t("multiple.add-attachements")}
									</div>
									<Dropzone
										onDrop={(acceptedFiles) => props.onChangeHandlerDrop(acceptedFiles)}>
										{({ getRootProps, getInputProps }) => (
											<section className="">
												<div {...getRootProps()}>
													<input {...getInputProps()} />

													<div className={classes.attachmentBox}>
														<span className={classes.mainColor} style={{ cursor: "pointer" }}>
															{t("multiple.attachement-drag-and-drop")}
														</span>
													</div>

													<div className={`${classes.fieldTitle} ${classes.attachmentTitle}`}>
														{t("multiple.current-attachements")}{" "}
													</div>

													{props.plugnoteAnnexes.length > 0 ?
														props.plugnoteAnnexes.map((annexe, index) => {
															return (
																<div
																	className={classes.attItemsContainer}
																	key={index + 1}
																	onClick={(event) => {
																		event.preventDefault();
																		event.stopPropagation();
																	}}
																>
																	<div className={classes.attItem}>
																		<div
																			className={classes.attIconType}
																			onClick={(event) => {
																				props._handleOpenModalListAnnexes();
																				setFileId(index);
																			}}
																		>
																			{props.renderAnnexeIconPreview(annexe, true)}
																		</div>

																		<div
																			className={classes.attAnnexName}
																			onClick={(event) => {
																				props._handleOpenModalListAnnexes();
																				setFileId(index);
																			}}
																		>
																			{annexe.comment !== "" ? annexe.comment : annexe.name}
																		</div>

																		<div className={classes.attIconDlInfo}>
																			{annexe.type === "note" && (
																				<span
																					id="icon-file_copy"
																					className={`material-icons md-25 ${classes.dlIcon}`}
																					onClick={(event) =>
																						props.handleNoteOpen(event, annexe)
																					}
																				>
																					create
																				</span>
																			)}

																			{/* {annexe.countVersion > 0 && (
																					<span
																						id="icon-file_copy"
																						className={classes.pointer}
																						onClick={(event) =>
																							props.handleModalListAnnexesVersionOpen(
																								event,
																								annexe.hash
																							)
																						}
																					>
																						({annexe.countVersion})
																					</span>
																				)}

																			<div className="">
																				{annexe.groupid !== 0 ? (
																					<span>{annexe.groupid}</span>
																				) : (
																					<span />
																				)}
																			</div>
																			{
																				props.disabled === false &&
																				<span
																					id="icon-file_"
																					className="material-icons"
																					onClick={(event) => props.handleDeleteAnnexe(event, annexe)}
																				>
																					delete
																				</span>
																			}
																		</div>
																	</div>
																</div>
															);
														}) :
														<div className={classes.attachmentEmpty}>
															{t("multiple.no-attachements")}{" "}
														</div>
													}

													{
														props.plugnoteAnnexes.length > 0 &&
														<div className={classes.endOfContent}>
															* * *
														</div>
													}
												</div>
											</section>
										)}
									</Dropzone>
								</Content> */
							}
							{props.plugnoteAnnexes.length > 0 && props.isOpenModalListAnnexes &&
								<ModalTestAnnexeGallery
									mode={props.mode}
									locked={props.disabled}
									disabledDelete={false}
									open={props.isOpenModalListAnnexes}
									close={props._handleCloseModalListAnnexes}
									annexes={props.plugnoteAnnexes}
									isAuthorisedToEdit={props.isAuthorisedToEdit}
									downloadEmployeeData={props.downloadEmployeeData}
									handleUpdateAnnexe={props.handleUpdateAnnexe}
									handleModalAnnexeOpen={props.handleModalAnnexeOpen}
									handleNoteOpen={props.handleNoteOpen}
									handleDeleteAnnexe={props.handleDeleteAnnexe}
									handleModalListAnnexesVersionOpen={props.handleModalListAnnexesVersionOpen}
									/* handlePreviewAnnexe={props.handlePreviewAnnexe} */
									handlePreviewPdfAnnexe={props.handlePreviewPdfAnnexe}
									fileId={fileId}
									renderAnnexeIconPreview={props.renderAnnexeIconPreview}
									renderAnnexeImagePreview={props.renderAnnexeImagePreview}
									isOpenModalAnnexe={props.isOpenModalAnnexe}
									selectedAnnexe={props.selectedAnnexe}
									handleModalAnnexeClose={props.handleModalAnnexeClose}
									handleSaveFictifComment={props.handleSaveFictifComment}
								/>
							}
						</div>
					</div>
					{
						missingValues &&
						<DialogErrorSavePlugnoteComponent
                handleClickOk={() => setMissingValues(false)}
                missingValues={missingValues}
                openModal={missingValues !== false}
            />
					}
					{props.openSave && !props.viewInputSearchCode &&
						<ModalConfirmComponent
							open={props.openSave}
							close={props.handleCloseSave}
							handleChangeEmail={props.handleChangeEmail}
							handleChangeSendCopy={props.handleChangeSendCopy}
							handleSaveCreatePlugnoteConfirm={props.handleSaveCreatePlugnoteConfirm}
							copy={props.copy}
							emailValid={props.emailValid}
							email={props.email}
						/>
					}
				</div>
			);

		case "external-shared-create":
			return (
				<div className={`${classes.root}`} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
					<div className={`${classes.formModalContainer}`}>
						<Header className={`sticky  ${classes.header}`}>
							<div className={classes.logoField}>
								<img
									alt="form-logo"
									className={classes.fieldImage}
									src={formLogo}
									style={{ height: '100%', aspectRatio: 'auto', width: 'auto', maxHeight: 100}}
								/>
							</div>

							<div className={classes.titleBorder}>
								<div className={classes.sous_header_content}>
									{/* Lignes de titre supplémentaires */}
									<div className={classes.header_content}>
										<div className={classes.sous_header_content_title}>
											<Input
												id="standard-full-width"
												readOnly={true}
												label="Label"
												multiline
												placeholder={t("common:models.form-title")}
												helpertext="Full width!"
												fullWidth={true}
												margin="none"
												disableUnderline={true}
												inputProps={{ className: classes.sous_header_content_title_input }}
												className={classes.sous_header_content_title_input}
												value={nameModel}
											/>
											{openComment.includes(false) ?
												<Tooltip title={"Show comments"} placement="top" >
													<span
														id="icon-infos"
														style={{ cursor: 'pointer', alignSelf: 'center' }}
														className={` material-icons md-25`}
														onClick={() => setOpenComment([true, true])}
													>
														visibility
													</span>
												</Tooltip>
												: null}
										</div>
										{pnCode !== '' &&
											<div className={classes.sous_title_content}>
												<div className={classes.headerInfos}>
													{pfKey} {pfCode !== "" ? ' - ' + pfCode : ""}
												</div>
												{props.mode === "update" &&
													<div className={classes.headerInfos}>
														{pnKey} {pnCode !== "" ? ' - ' + pnCode : ""}
													</div>}
											</div>
										}
									</div>
									<div className={classes.sous_header_content_buttons}>
										{
											props.model.isTranslated && !props.viewInputSearchCode && lngOptions.length > 0 &&
											<div className={classes.langCtn}>
												<Select
													// fullWidth
													id="demo-simple-select"
													isClearable
													labelId="demo-simple-select-label"
													placeholder={t('common:models.list-translation')}
													onChange={(value) => handleChangeLanguage(value)}
													onFocus={() => { if (props.list?.length < 1) setLoading(true) }}
													options={lngOptions}
													value={lang}
												/>
											</div>
										}
										<button
											className={`${classes.sous_header_button} ${classes.button_cancel}`}
											onClick={props.handleExitCreatePlugnote}
										>
											{t("common:global.close")}
										</button>
										<span
											className={`${classes.sous_header_button} ${classes.button_attach} material-icons`}
											onClick={executeScroll}
										>
											attach_file
										</span>
										<button
											className={`${classes.sous_header_button} ${classes.button_save}`}
											onClick={handleClickSendPlugnote}
										>
											{t("common:multiple.send")}{" "}
										</button>
									</div>
								</div>
							</div>

							{/*props.plugnote === null &&*/}
							{pfComment?.length > 0 && openComment[0] ?
								<div className={`${classes.commentField} ${classes.fieldBorder}`} style={openComment[1] ? { marginBottom: 10 } : null}>
									<div className={classes.commentContent}>
										<div className={classes.commentText}>
											<TextField
												className={classes.commentDesc}
												value={pfComment}
												multiline
												style={{ width: '100%' }}
												InputProps={{ disableUnderline: true, }}
												disabled={true}
											/>
										</div>
										<Tooltip title={"Hide comment"} placement="top" >
											<span
												id="icon-infos"
												style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
												className={`material-icons md-25`}
												onClick={() => setOpenComment([false, openComment[1]])}
											>
												visibility_off
											</span>
										</Tooltip>
									</div>
								</div>
								: null}
							{!props.viewInputSearchCode && !!props.model && (pnComment?.length > 0) && openComment[1] ?
								<div className={`${classes.commentField} ${classes.fieldBorder}`}>
									<div className={classes.commentContent}>
										<div className={classes.commentText}>
											<Tooltip title={"Hide comment"} placement="top" >
												<span
													id="icon-infos"
													style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
													className={` material-icons md-25`}
													onClick={() => setOpenComment([openComment[0], false])}
												>
													visibility_off
												</span>
											</Tooltip>
											<TextField className={classes.commentDesc}
												value={pnComment}
												multiline
												style={{ width: '100%' }}
												InputProps={{ disableUnderline: true, }}
												disabled={true}
											/>
										</div>
									</div>
								</div>
								: null}
						</Header>

						<div className={classes.contentFieldsColumn}>
							{!props.viewInputSearchCode &&
								<Content className={`${classes.contentFieldModel} ${classes.fieldBorder} scrollbar-hidden`}>
									{/* <div className={classes.fieldTitle}>
									{t("multiple.fields-labels-lowercase")}
								</div> */}

									{typeof fields !== "undefined" && fields.length > 0 &&
										fields.map((field, index) => {
											if (field.encodable) {
												return (
													<div className="flexbox-container" key={index + 1}>
														<div className={`${classes.formatContainer}`}>
															<div className={classes.formatContent}>
																<div className={`${classes.formatHeader} ${fullwidthFields.includes(field.format) && classes.fakeFullwidthTitleFormat}`}>
																	<Tooltip
																		title={field.hasOwnProperty('infobulle') ? infobulles['field' + field.id] : ""}
																		classes={{ tooltip: field.hasOwnProperty('infobulle') ? classes.infobulle : classes.noTooltip }} placement="bottom"
																	>
																		<div className={classes.numTitleFormat} style={{flex: 0.3}}>
																	<span className={classes.formatTitleCtn}>
																		{field.visuelFormat.toLowerCase() === "now" ? (
																			<div
																				className={
																					field.mandatory
																						? classes.fieldIconMandatory
																						: classes.fieldIcon
																				}
																			>
																				<img
																					className={classes.fieldImage}
																					src={
																						Images[
																						camelCase(
																							field.visuelFormat.toLowerCase()
																						) + "Icon"
																							]
																					}
																					alt={t(
																						"fields.format-choice-" +
																						field.visuelFormat.toLowerCase()
																					)}
																				/>
																			</div>
																		) : (
																			<img
																				className={field.mandatory ? classes.fieldIconRed : classes.fieldImageSvg}
																				src={
																					field.mandatory
																						? Images[
																						camelCase(
																							field.visuelFormat.toLowerCase()
																						) + "IconSvgRed"
																							]
																						: Images[
																						camelCase(
																							field.visuelFormat.toLowerCase()
																						) + "IconSvg"
																							]
																				}
																				alt={t(
																					"fields.format-choice-" +
																					field.visuelFormat.toLowerCase()
																				)}
																			/>
																		)}
																		<div className={classes.separatorHeaderFormat} />
																		<div className={classes.titleHeaderFormat}>
																			{field.titleTranslate}
																		</div>
																	</span>
																			{
																				field.hasOwnProperty('infobulle') &&
																				<span
																					id="icon-file_"
																					className="material-icons"
																					style={{ color: colors.mainButton, fontSize: 25 }}
																				>
																			info
																		</span>
																			}
																		</div>
																	</Tooltip>

																	{!specialFields.includes(field.format) && field.format === "sign" &&
																		<div className={classes.formatInputFieldInlineLocked}>
																			<FormatSignComponent
																				handleEditValue={props.handleEditValue}
																				field={field}
																				index={index}
																				disabled={props.disabled || field.disabled}
																				mode={props.mode}
																				model={props?.model || null}
																				plugnote={props?.plugnote || null}
																				annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																				fields={fields}
																				onChangeHandlerDrop={props.onChangeHandlerDrop}
																				logo={props?.logo}
																			/>
																		</div>
																	}

																	{(
																			field.format === "pluglink" ||
																			field.format === "qrcode" ||
																			field.format === "qrcodeint" ||
																			field.format === "plugform" ||
																			field.format === "boolean") &&
																		<div className={(props.disabled === false || field.disabled === false) ? classes.formatInlineNoBorder : classes.formatInlineNoBorderLocked}>

																			{!specialFields.includes(field.format) && field.format === "pluglink" && (
																				<FormatPlugLinkComponent
																					enabled={false}
																					field={field}
																					index={index}
																					handleEditValue={props.handleEditValue}
																					disabled={props.disabled || field.disabled}
																					dataHash={props?.plugnote?.hash}
																					owner={props.hashOwnerModel}
																					ownerHash={props.model.hash}
																				/>
																			)}
																			{specialFields.includes(field.format) && field.format === "qrcode" &&
																				<FormatPlugcodeComponent
																					handleChangePlugcode={props.handleChangePlugcode}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																				/>
																			}

																			{specialFields.includes(field.format) && field.format === "qrcodeint" &&
																				<FormatPlugcodeInternalComponent
																					handleChangePlugcode={props.handleChangePlugcodeInternal}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																					owner={props.hashOwnerModel}
																				/>
																			}

																			{!specialFields.includes(field.format) && field.format === "boolean" && (
																				<FormatBooleanComponent
																					handleChangeBoolean={
																						props.handleChangeBoolean
																					}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																				/>
																			)}

																			{specialFields.includes(field.format) && field.format === "plugform" && (
																				<FormatPlugformComponent
																					model={props.model}
																					mode={props.mode}
																					handleChangePlugform={
																						props.handleChangePlugform
																					}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																					owner={props.hashOwnerModel}
																				/>
																			)}
																		</div>
																	}

																	{(field.format === "phone" ||
																			field.format === "mail" ||
																			field.format === "smail" ||
																			field.format === "url" ||
																			field.format === "localis" ||
																			field.format === "glocalis" ||
																			field.format === "date" ||
																			field.format === "agenda" ||
																			field.format === "now" ||
																			field.format === "birthday" ||
																			field.format === "time" ||
																			field.format === "formula" ||
																			field.format === "extdata" ||
																			field.format === "extdatan" ||
																			field.format === "list" ||
																			field.format === "intdatamaj" ||
																			field.format === "numeric" ||
																			field.format === "password" ||
																			field.format === "textarea" ||
																			field.format === "indice" ||
																			(field.format.includes("table") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
																			(field.format.includes("table") && field.format.includes("multi") && !field.format.includes("tablemulti")) ||
																			(field.format.includes("select") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
																			(field.format.includes("select") && field.format.includes("multi") && !field.format.includes("tablemulti"))) &&
																		<div className={(props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>

																			{!specialFields.includes(field.format) && field.format === "formula" &&
																				<FormatFormulaComponent
																					field={field}
																					index={index}
																					handleEditValue={props.handleEditValue}
																					disabled={props.disabled || field.disabled}
																					model={props.model}
																					fields={fields}
																					dataHasChanged={props.dataHasChanged}
																				/>
																			}

																			{specialFields.includes(field.format) && field.format === "textarea" && (
																				<FormatMemoComponent
																					handleEditValue={props.handleEditValue}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled ||
																				field.options.setup?.editable === false ||
																				(field.options.setup?.editablePf === false && !isFromPwCode) ||
																				(field.options.setup?.editablePw === false && isFromPwCode)}
																				/>
																			)}

																			{!specialFields.includes(field.format) && field.format === "indice" && (
																				<FormatIndiceComponent
																					handleEditValue={props.handleEditValue}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																				/>
																			)}

																			{
																				specialFields.includes(field.format) && field.format.includes("select") && field.format.includes("multi") && !field.format.includes("tablemulti") &&
																				<FormatMultipleSelectComponent
																					handleChangeRadio={props.handleChangeRadio}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																					expand={false}
																				/>
																			}

																			{specialFields.includes(field.format) && field.format.includes("select") && field.format.includes("single") &&
																				<FormatSingleSelectComponent
																					handleChangeRadio={props.handleChangeRadio}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																					expand={false}
																				/>
																			}

																			{specialFields.includes(field.format) && field.format.includes("table") && field.format.includes("multi") &&
																				!field.format.includes("tablemulti") &&
																				<FormatMultipleSelectTableComponent
																					handleChangeRadio={props.handleChangeRadioTable}
																					handleChangeInputTable={props.handleChangeInputTable}
																					handleUpdateInputTable={props.handleUpdateInputTable}
																					handleDeleteInputTable={props.handleDeleteInputTable}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																					expand={false}
																				/>
																			}

																			{specialFields.includes(field.format) && field.format.includes("table") && field.format.includes("single") &&
																				<FormatSingleSelectTableComponent
																					handleChangeRadio={props.handleChangeRadioTable
																					}
																					handleChangeInputTable={props.handleChangeInputTable
																					}
																					handleUpdateInputTable={props.handleUpdateInputTable
																					}
																					handleDeleteInputTable={props.handleDeleteInputTable
																					}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																					expand={false}
																				/>
																			}

																			{specialFields.includes(field.format) && field.format === "list" &&
																				<FormatKeywordComponent
																					handleChangeKeyword={props.handleChangeKeyword
																					}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																				/>
																			}

																			{!specialFields.includes(field.format) && field.format === "phone" &&
																				<FormatPhoneComponent
																					field={field}
																					index={index}
																					handleEditPhoneValue={props.handleEditPhoneValue
																					}
																					disabled={props.disabled || field.disabled}
																				/>
																			}


																			{!specialFields.includes(field.format) && field.format === "mail" &&
																				<FormatMailComponent
																					field={field}
																					index={index}
																					handleEditValue={props.handleEditValue}
																					disabled={props.disabled || field.disabled}
																				/>
																			}

																			{!specialFields.includes(field.format) && field.format === "smail" &&
																				<FormatSMailComponent
																					field={field}
																					fields={fields}
																					index={index}
																					handleEditValue={props.handleEditValue}
																					disabled={props.disabled || field.disabled}
																					model={props?.model || null}
																					ownerHash={props?.model?.hash || null}
																					plugnote={props?.plugnote || null}
																					annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																					renderAnnexeIconPreview={props.renderAnnexeIconPreview}
																				/>
																			}

																			{!specialFields.includes(field.format) && field.format === "url" &&
																				<FormatUrlComponent
																					field={field}
																					index={index}
																					handleEditValue={props.handleEditValue}
																					disabled={props.disabled || field.disabled ||
																				field.options.setup?.editable === false ||
																				(field.options.setup?.editablePf === false && !isFromPwCode) ||
																				(field.options.setup?.editablePw === false && isFromPwCode)}
																				/>
																			}

																			{specialFields.includes(field.format) && field.format === "localis" &&
																				<FormatLocalisationComponent
																					handleChangeLocalisation={props.handleChangeLocalisation}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																				/>
																			}

																			{specialFields.includes(field.format) && field.format === "glocalis" &&
																				<FormatGeoLocalisationComponent
																					handleChangeLocalisation={props.handleChangeLocalisation}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																				/>
																			}

																			{!specialFields.includes(field.format) && field.format === "date" &&
																				<div className={classes.fieldRight}>
																					<FormatDateComponent
																						field={field}
																						handleEditDateTime={props.handleEditDateTime}
																						index={index}
																						disabled={props.disabled || field.disabled}
																					/>
																				</div>
																			}
																			{!specialFields.includes(field.format) && field.format === "agenda" && (
																				<div className={classes.fieldRight}>
																					<FormatAgendaComponent
																						field={field}
																						handleEditDateTime={props.handleEditDateTime}
																						index={index}
																						disabled={props.disabled || field.disabled}
																					/>
																				</div>
																			)}

																			{!specialFields.includes(field.format) && field.format === "now" && (
																				<div className={classes.fieldRight}>
																					<FormatNowComponent
																						field={field}
																						handleEditDateTime={props.handleEditDateTime}
																						index={index}
																						disabled={props.disabled || field.disabled}
																					/>
																				</div>
																			)}

																			{!specialFields.includes(field.format) && field.format === "birthday" && (
																				<div className={classes.fieldRight}>
																					<FormatBirthdayComponent
																						field={field}
																						handleEditDateTime={props.handleEditDateTime}
																						index={index}
																						disabled={props.disabled || field.disabled}
																					/>
																				</div>
																			)}

																			{!specialFields.includes(field.format) && field.format === "time" && (
																				<div className={classes.fieldRight}>
																					<FormatTimeComponent
																						field={field}
																						handleEditDateTime={props.handleEditDateTime}
																						index={index}
																						disabled={props.disabled || field.disabled}
																					/>
																				</div>
																			)}

																			{
																				!specialFields.includes(field.format) && field.format === "numeric" && (
																				<TextField
																					onChange={(event) => {if (event.target.value.match(/[-+]?[0-9]*[.,]?[0-9]+/) || event.target.value === "") props.handleEditValue(
																						event.target.value,
																						field,
																						index)}}
																					id="standard-full-width"
																					style={{ margin: 0 }}
																					type="number"
																					autoComplete='off'
																					onWheel={(event) => event.target.blur()}
																					placeholder={t(
																						"fields.format-choice-placeholder-" +
																						field.visuelFormat.toLowerCase()
																					)}
																					onFocus={(e) => e.target.placeholder = ''}
																					onBlur={(e) => e.target.placeholder = t(
																						"fields.format-choice-placeholder-" +
																						field.visuelFormat.toLowerCase()
																					)}
																					helperText=""
																					fullWidth
																					margin="normal"
																					InputLabelProps={{ shrink: true }}
																					inputProps={{ step: "0.1" }}
																					className={classes.fieldRight}
																					value={field.value}
																					InputProps={{ disableUnderline: true }}
																					disabled={props.disabled || field.disabled}
																				/>
																			)}
																			{!specialFields.includes(field.format) && field.format === "password" &&
																				<FormatPasswordComponent
																					handleChangePassword={props.handleChangePassword}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																				/>
																			}

																			{
																				field.format === "intdatamaj" && specialFields.includes(field.format) && field.format === "intdatamaj" &&
																				<FormatIntDataMajComponent
																					ownerId={props.model?.owner}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																					mode={props.mode}
																					plugNb={props.plugnote?.userindex}
																					plugKey={props.model?.clef}
																					handleChangeIntDataMaj={props.handleChangeIntDataMaj}
																					dataHash={props?.plugnote?.hash}
																					title={field.titleTranslate}
																				/>
																			}

																			{specialFields.includes(field.format) && field.format === "extdata" && (
																				<FormatExtDataComponent
																					ownerId={props.model.owner}
																					fields={fields}
																					model={props.model}
																					handleChangeExtData={props.handleChangeExtData}
																					handleEditValue={props.handleEditValue}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																					owner={props.hashOwnerModel}
																					title={field.titleTranslate}
																				/>
																			)}
																			{specialFields.includes(field.format) && field.format === "extdatan" && (
																				<FormatExtDatanComponent
																					ownerId={props.model.owner}
																					fields={fields}
																					model={props.model}
																					handleChangeExtDatan={props.handleChangeExtDatan}
																					handleEditValue={props.handleEditValue}
																					field={field}
																					index={index}
																					disabled={props.disabled || field.disabled}
																					owner={props.hashOwnerModel}
																					title={field.titleTranslate}
																				/>)}
																		</div>
																	}

																	{field.format === "text" && (
																		<div className={(props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>
																			<div className={classes.f01Container} >
																				<TextField
																					onChange={event => props.handleEditValue(event.target.value, field, index)}
																					autoComplete='off'
																					multiline={textStyle}
																					id="standard-full-width"
																					style={{ margin: 0, width: '100%' }}
																					placeholder={t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
																					onBlur={(e) => e.target.placeholder = t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
																					onFocus={(e) => e.target.placeholder = ''}
																					helperText=""
																					fullWidth
																					margin="normal"
																					InputLabelProps={{ shrink: true }}
																					inputProps={{ maxLength: 40 }}
																					value={field.value}
																					InputProps={{ disableUnderline: true }}
																					disabled={props.disabled || field.disabled ||
																						field.options.setup?.editable === false ||
																						(field.options.setup?.editablePf === false && !isFromPwCode) ||
																						(field.options.setup?.editablePw === false && isFromPwCode)}
																				/>
																				{(field.disabled === true) ? null : field.value.length > 35 ? <span className={classes.memoCount}>{field.value.length + ' / 40'}</span> : null}
																			</div>
																		</div>
																	)
																	}

																	{/* {props.disabled === false || field.disabled === false ? null : (
                                        <span className={`${classes.lockedColor} material-icons md-15`}>
                                          lock
                                        </span>
                                    )} */}

																</div>

																{
																	field.format === "tablemulti" && specialFields.includes(field.format) && field.format === "tablemulti" &&
																	<FormatTableMultipleComponent
																		disabled={props.disabled || field.disabled}
																		field={field}
																		handleChangeTableValue={props.handleChangeTableValue}
																		index={index}
																		isFromPwCode={isFromPwCode}
																		mode={props.mode}
																		plugKey={props.model?.clef}
																		plugNb={props.plugnote?.userindex}
																		ownerId={props.model?.owner}
																		title={field.titleTranslate}
																	/>
																}
																{
																	field.format === "tablemultin" && specialFields.includes(field.format) && (field.format === "tablemultin") &&
																	<FormatTableMultiplenComponent
																		disabled={props.disabled || field.disabled}
																		field={field}
																		fields={fields}
																		handleChangeTableValue={props.handleChangeTableRowsValue}
																		index={index}
																		isFromPwCode={isFromPwCode}
																		mode={props.mode}
																		model={props.model}
																		plugKey={props.model?.clef}
																		plugNb={props.plugnote?.userindex}
																		owner={props.hashOwnerModel}
																		ownerId={props.model.owner}
																		title={field.titleTranslate}
																		handleChangeExtData={props.handleChangeExtData}
																	/>
																}
																{
																	field.format === "smailvalidmulti" &&
																	<div
																		className={
																			props.disabled === false || field.disabled === false
																				? classes.formatInputFieldComposed
																				: classes.formatInputFieldLockedComposed
																		}
																	>
																		{
																			specialFields.includes(field.format) && field.format === "smailvalidmulti" &&
																			<FormatSMailValidMultiComponent
																				mode={props.mode}
																				ownerId={props.model.owner}
																				handleChangeTableValue={props.handleChangeTableRowsValue}
																				handleEditValue={props.handleEditValue}
																				field={field}
																				fields={fields}
																				index={index}
																				isFromPwCode={isFromPwCode}
																				disabled={props.disabled || field.disabled}
																				owner={props.hashOwnerModel}
																				title={field.titleTranslate}
																				model={props?.model || null}
																				plugnote={props?.plugnote || null}
																				annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
																				renderAnnexeIconPreview={props.renderAnnexeIconPreview}
																				control={props.mode !== 'modal-plugnote-create' ? props.plugnote === null ? props.handleCreatePlugnote : props.handleSavePlugnote : null}
																			/>
																		}
																	</div>
																}
															</div>
														</div>
													</div>
												);
											}
											else {
												return null;
											}
										})}
								</Content>
							}

							{
								!props.viewInputSearchCode &&
								<AttachmentPanel {...props} disabled={false} isAuthorizedAttach={isAuthorizedAttach} isAuthorizedNote={isAuthorizedNote} setFileId={setFileId} />
							}
							{
								props.plugnoteAnnexes.length > 0 && props.isOpenModalListAnnexes &&
								<ModalTestAnnexeGallery
									mode={props.mode}
									locked={props.disabled}
									disabledDelete={false}
									open={props.isOpenModalListAnnexes}
									close={props._handleCloseModalListAnnexes}
									annexes={props.plugnoteAnnexes}
									isAuthorisedToEdit={props.isAuthorisedToEdit}
									downloadEmployeeData={props.downloadEmployeeData}
									handleUpdateAnnexe={props.handleUpdateAnnexe}
									handleModalAnnexeOpen={props.handleModalAnnexeOpen}
									handleNoteOpen={props.handleNoteOpen}
									handleDeleteAnnexe={props.handleDeleteAnnexe}
									handleModalListAnnexesVersionOpen={props.handleModalListAnnexesVersionOpen}
									/* handlePreviewAnnexe={props.handlePreviewAnnexe} */
									handlePreviewPdfAnnexe={props.handlePreviewPdfAnnexe}
									fileId={fileId}
									renderAnnexeIconPreview={props.renderAnnexeIconPreview}
									renderAnnexeImagePreview={props.renderAnnexeImagePreview}
									isOpenModalAnnexe={props.isOpenModalAnnexe}
									selectedAnnexe={props.selectedAnnexe}
									handleModalAnnexeClose={props.handleModalAnnexeClose}
									handleSaveFictifComment={props.handleSaveFictifComment}
								/>
							}

							{/* to open the modal for send*/}
							{props.openClassify && !props.viewInputSearchCode &&
								<ModalSavePlugcodeComponent
									open={props.openClassify}
									close={props.handleCloseClassify}
									history={props.history}
								/>
							}
							{
								missingValues &&
								<DialogErrorSavePlugnoteComponent
										handleClickOk={() => setMissingValues(false)}
										missingValues={missingValues}
										openModal={missingValues !== false}
								/>
							}
							{
								props.openSave && !props.viewInputSearchCode &&
								<ModalConfirmComponent
									open={props.openSave}
									close={props.handleCloseSave}
									handleChangeEmail={props.handleChangeEmail}
									handleChangeSendCopy={props.handleChangeSendCopy}
									handleSaveCreatePlugnoteConfirm={props.handleSaveCreatePlugnoteConfirm}
									copy={props.copy}
									emailValid={props.emailValid}
									email={props.email}
								/>
							}
						</div>
					</div>
				</div>
			);
		default:
			break;
	}
}

// redux providing state takeover
const mapStateToProps = (state) => {
	return {
		user: state.user,
		token: state.user.token,
		list: !!state.translate ? state.translate.list : [],
		type: !!state.user && !!state.user.user ? state.user.user.type : null,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		attemptGetAllTranslationsOffline: (modelhash) => dispatch(getAllTranslationsByModelOfflineRequest(modelhash)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Plugforms)
