import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles, alpha} from "@material-ui/core/styles";
import {connect} from "react-redux";
import colors from "../../assets/themes/Colors";
import { Tooltip } from "@material-ui/core";
import {KeyboardDateTimePicker} from "@material-ui/pickers";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    timeDelButton: {
        height:'22px',
        // width: '18px',
        // border: '2px solid',
        borderRadius: "50px",
        color: colors.main,
        alignItems: 'center',
        fontSize: 16
    },
    endButton: {    
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
    timeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    }
}));


const FormatAgendaComponent = (props) => {
    const classes = useStyles();

    const [selectedDate, handleDateChange] = useState(props.field.value !== null && props.field.value.length > 0 ? props.field.value : null);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    const openingDate = () => {
        let nowTime = new Date()
        console.log ("time", nowTime)
        nowTime.setHours(nowTime.getHours() + 1);
        console.log ("time", nowTime)
        return nowTime;
    }

    return (
    <div className={classes.timeContainer}>
        <KeyboardDateTimePicker
            InputAdornmentProps={{ position: 'start' }}
            disabled={disabled}
            variant="inline"
            ampm={false}
            fullWidth={true}
            placeholder="YYYY-MM-DD HH:MM"
            id={'date-picker-inline-'+props.field.id}
            className={`${classes.datePickerIcon}`}
            //value={selectedDate}
            // onOpen={()=> handleDateChange(openingDate)} //broken :-/
            format="yyyy-MM-dd HH:mm"
            value={selectedDate}
            // initialFocusedDate={}
            // onOpen={() => openingDate()}
            onChange={(date, value) => {
                handleDateChange(date);
                props.handleEditDateTime(date, value, props.field, props.index);
            }}
            InputProps={{ disableUnderline: true }}
        />
        {!disabled && (selectedDate === "" || selectedDate !== null) ?
            <Tooltip title={"Delete"} placement="top" className={classes.endButton} >
                <span className={`${classes.timeDelButton} material-icons`} onClick={() => {handleDateChange(null); props.handleEditDateTime(null, props.field.value, props.field, props.index)}}>highlight_off</span>
            </Tooltip> : null}
    </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatAgendaComponent);





