import React from "react";
import {Switch} from 'react-router';
import PublicRoute from "../src/router/PublicRoute";
import Home from "./containers/Home";
//const Home = React.lazy(() => import('../src/containers/Home'), 500);

export default function Navigations() {
    //http://localhost:3000?fh=d2949f5d-76fa-4c1b-8b45-26d1617e0730&pfc=pnwZeLlLbvKvE0116740001637060209
    //http://localhost:3000?dh=daeQoUI0U0hL60197873001630483328&pnc=pnhgGGN3lMlWA0744836001635329925
    return (
        <Switch>
            <PublicRoute exact  path="" component={Home}/>
        </Switch>
    );
}
