import React from "react";
import PropTypes from "prop-types"
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import PDFRow from "./PDFRow";
import PDFHeaderRow from "./PDFHeaderRow";
import i18n from "i18next";
import { isDefined } from "../../../utils/utils";

const styles = StyleSheet.create({
    tableContainer: {
        // flexDirection: "column",
        // flexWrap: "nowrap",
        //width: '100%',
        height: 'auto',
    },
    noData: {
        fontFamily: 'Poppins',
        fontSize: 10,
        fontWeight: 'light',
        fontStyle: 'italic',
        padding: 4,
    },
});

const PDFTablemulti = (props) => {
    // console.log('PDFTablemulti got props', props);
    let noDataMsg = i18n.t('common:global.no-data')
    let labelLengths = props.header.map(col => col.value.length);
    let align = isDefined(props.alignment) ? props.alignment : 'left';
    let tableWidth = 20;
    if (align !== 'full') {
        props.header.forEach(col => {
            if (col.showOnPdf !== 0 || !isDefined(col.showOnPdf)) {
                let colWidth = col.colWidth * 10;
                if (isNaN(colWidth)) { colWidth = props.widths * 514 }
                tableWidth += colWidth;
            }
        });
        if (tableWidth > 514) { tableWidth = 514; }
    } else { tableWidth = 514; }

    const widthStyle = { maxWidth: tableWidth, width: tableWidth, minWidth: 120 }
    if (align === 'right') { widthStyle.marginLeft = 'auto'; } else if (align === 'center') { widthStyle.marginLeft = 'auto';  widthStyle.marginRight = 'auto'; }
    return (
        <View style={[styles.tableContainer, widthStyle]}>
            {
                props.header.length > 0 &&
                <PDFHeaderRow
                    borderColor={props.borderColor}
                    items={props.header}
                    key="pdf-table-header"
                    mode={props.mode}
                    style={props.headerStyle}
                    widths={labelLengths}
                />
            }
            {
                props.rows?.length > 0 ?
                    props.rows
                        .map((row, rindex) => {
                            return (
                                <PDFRow
                                    borderColor={props.borderColor}
                                    header={props.header}
                                    row={row}
                                    key={"pdf-table-row-" + rindex}
                                    mode={props.mode}
                                    rowNb={rindex}
                                    widths={labelLengths}
                                />
                            );
                        })
                    :
                    <PDFRow
                        borderColor={props.borderColor}
                        isEmpty={true}
                        header={props.header}
                        id={"pdf-empty-table"}
                        row={[noDataMsg]}
                        rowNb={0}
                        textStyle={styles.noData}
                        widths={labelLengths}
                    />
            }
        </View>
    );
}


PDFTablemulti.propTypes = {
    borderColor: PropTypes.string,
    header: PropTypes.array,
    rows: PropTypes.array.isRequired,
    widths: PropTypes.array,
}

export default PDFTablemulti;