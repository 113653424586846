import {Types} from '../actions/gallery';

// create initial state for reducers
const INIT_STATE = {
    list : [],
};

// reducer function to transform state
export default function gallery(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.GET_PLUGCODE_GALLERY_ERROR : return {...state};
        case Types.GET_PLUGCODE_GALLERY_FAILURE : return {...state};
        case Types.GET_PLUGCODE_GALLERY_REQUEST :{
            return {
                list : []
            }
        }
        case Types.GET_PLUGCODE_GALLERY_SUCCESS:{
            return {
                list : action.payload.data
            }
        }

        default: return state;
    }
}