import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {getDecryptPasswordRequest, reduxGenerateDataPasswordReset} from "../../actions/data";
import {getCurrentEnvironnement, getPlugnote} from "../../utils/Common";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        border: '1px red solid',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    }
}));



const FormatPasswordComponent = (props) => {

    const classes = useStyles();

    const [password, setPassword] = useState(props.field.value);
    const [decryptPassword, setDecryptPassword] = useState("");
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [isFromPlugCode, setIsFromPlugCode] = React.useState(typeof props.isFromPlugCode === 'undefined' ? false: props.isFromPlugCode);

    const environment = getCurrentEnvironnement();
    const plugnote = isFromPlugCode ? props.plugnote : getPlugnote() ;
    const visualPW = '•••••••••••••••••••'

    const handleViewCryptedPassword = (event) => {
        if(!edit && !!password && !!plugnote){
            props.attemptGetPasswordField(plugnote.hash, props.field.id, environment.userhash)
        }
        setView(true);
        setEdit(true);
    }

    const handleHidePassword = (event) => {
        setView(false);
        //props.attemptResetDataPassword();
        setEdit(false);
    }

    const { t, i18n } = useTranslation(['common', 'login'], { useSuspense: false });


    useEffect(() => {
        setDecryptPassword(password);
    }, [view === true]);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);


    return (
        <div>
            {
            view ?
                <Input
                    autoComplete='new-password'
                    id={props.field.id + '-' + props.field.format + '-visible'}
                    placeholder="Password"
                    disabled={disabled}
                    fullWidth={true}
                    style={{ margin: 0 }}
                    type="text"
                    margin="none"
                    disableUnderline={true}
                    className="text-line"
                    endAdornment={
                        <InputAdornment position="end">
                            <div onClick={(event) => handleHidePassword(event)}>
                                <span className="material-icons">
                                    visibility_off
                                </span>
                            </div>
                        </InputAdornment>}
                    onChange={(event) => {
                        setEdit(true);
                        setPassword(event.target.value);
                        setDecryptPassword(event.target.value);
                        props.handleChangePassword(event.target.value, props.field, props.index)}
                    }
                    value={view?
                        props.fetchingPassword ? t('global.loading') : decryptPassword.length > 0 ? decryptPassword : password
                        :
                        password?.length > 10 ? visualPW : password
                    }
                />
                :

                <Input
                    disableUnderline={true}
                    autoComplete='new-password'
                    id={props.field.id + '-' + props.field.format + '-invisible'}
                    placeholder="password"
                    fullWidth={true}
                    style={{ margin: 0 }}
                    margin="none"
                    disabled={disabled}
                    type="password"
                    className="text-line"
                    endAdornment={ password.length > 0 && !disabled &&
                        <InputAdornment position="end">
                            <div onClick={(event) => handleViewCryptedPassword(event)}>
                                <span className="material-icons">
                                    visibility
                                </span>
                            </div>
                        </InputAdornment>}
                    onFocus={(e) => e.target.placeholder = ''}
                    onBlur={(e) => e.target.placeholder = 'password'}
                    onChange={(event) => {
                        setEdit(true);
                        setPassword(event.target.value);
                        setDecryptPassword(event.target.value);
                        props.handleChangePassword(event.target.value, props.field, props.index)}
                    }
                    value={disabled? password.length > 21? "····················" : password : password.length > 0 ? password : ""}
                />
            }

        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        plugnote : state.plugcode.plugnote,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptResetDataPassword: () => dispatch(reduxGenerateDataPasswordReset()),
    attemptGetPasswordField: (datahash, fieldId, userhash) => dispatch(getDecryptPasswordRequest(datahash, fieldId, userhash)),

});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPasswordComponent);





