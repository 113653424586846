import {fork} from 'redux-saga/effects';
import {history} from '../hooks/history'
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


function forwardTo(location) {
    history.push(location);
}

function forwardToProps(location, valueProp) {

    history.push({
        pathname: location,
        state: { email: valueProp }
    });
}

function* watchFetchUser() {
    // create watcher of fetchData function
}

const UserSagas = [
    fork(watchFetchUser),
];

export default UserSagas;
