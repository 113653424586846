import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import colors from "../../assets/themes/Colors";
import { isDefined } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        width : '100%',
    },
    memoContainer:{
        width: '100%',
        display: 'flex',
        minHeight: '42px',
        //justifyContent: 'space-between',
        //alignItems: 'flex-start',
        gap: 5,
        color: colors.selectedMain,
        flexDirection : 'column'
    },
    memoText:{
        flexGrow: 1,
    },
    memoDisabledText:{
        // flexGrow: 1,
        color: colors.unselected
    },
    memoCount:{
        width: '100%',
        fontSize : '10px',
        textAlign: 'center',
        color: colors.unselected
        //lineHeight: '10px',
    },
    memoCountSpan:{
        fontSize : '10px',
        float : 'right'
        //lineHeight: '10px',
    },
}));

const FormatMemoComponent = (props) => {
    const { t } = useTranslation(['common', 'models']);
    const classes = useStyles();
    let initialValue = isDefined(props.field.options?.setup?.defaultValue) ? props.field.options?.setup?.defaultValue : ''
    if (isDefined(props.field.value) && props.field.value !== '') { initialValue = props.field.value }
    const [value, setValue] = useState(initialValue);

    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled)
        props.handleEditValue(initialValue, props.field, props.index)
    },[ props.disabled]);

    const handleEditValue = (value, field, index) => {
        setValue(value);
        props.handleEditValue(value, field, index)
    }

    useEffect(() => {
        setValue(props.field.value);
    },[props.field.value, props.field.visuelValue])

    return (
        <div className={classes.memoContainer} key={"memo-"+props.index}>
            <div>
                <TextField
                    disabled={disabled}
                    onChange={(event) => {
                        handleEditValue(event.target.value, props.field, props.index);
                    }}
                    id="standard-full-width"
                    //label={field.title}
                    style={{ margin: 0}}
                    multiline={true}
                    placeholder={t('fields.format-choice-placeholder-'+props.field.visuelFormat.toLowerCase())}
                    onBlur={(e) => e.target.placeholder = t('fields.format-choice-placeholder-'+props.field.visuelFormat.toLowerCase())}
                    onFocus={(e) => e.target.placeholder = ''}
                    helperText=""
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{classes: {root : {maxLength: 1500, color: colors.selectedMain}, disabled: classes.memoDisabledText}} }
                    InputProps={{disableUnderline: true}}
                    className={classes.memoText}
                    value={value}
                />
            </div>
            <div className={classes.memoCount}>
                {!disabled&& <span className={classes.memoCountSpan}>{value.length +  ' / ' + '1500'}</span>}
            </div>
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatMemoComponent);





