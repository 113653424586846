import * as moment from "moment";
import { devLog, isDefined } from "./utils";

export const fieldsWithColumns = ['document', 'smailvalidmulti', 'tablemulti', 'tablemultin' ]
export const  getContentValueForField = (field) => {
    let fields
    if(field.value !== "" ){
        switch (field.format) {
            case 'extdata':
            case 'extdatan':
            case 'text':
            case 'agenda':
            case 'textarea':
            case 'date':
            case 'birthday':
            case 'phone':
            case 'sms':
            case 'time':
            case 'smail':
            case 'mail':
            case 'formula':
            case 'sign':
            case 'url':
            case 'qrcode':
            case 'now':
            case 'localis':
                return field.value;
            case 'password':
                return "******"
            case 'list':
            case 'single-select':
            case 'free-single-select':
            case 'multi-select':
            case 'free-multi-select':
                return field.value.join(' , ');
            case 'single-table':
            case 'free-single-table':
            case 'multi-table':
            case 'free-multi-table':
                return field.value.map(item => item.text + ' : '+ item.value).join(' , ');
            // case 'tablemulti':
            //     fields = getMultTableFieldsLabels(field)
            //     if(Array.isArray(field.value)){
            //         return '\n' + field.value.map(item => Object.values(item) + '\n').join('');
            //     }
            //     else {
            //         let tmp = JSON.parse(field.value);
            //         return '\n' + tmp.map(item => Object.values(item) + '\n').join('');
            //     }
            // case 'tablemultin':
            //     fields = getMultTableFieldsLabels(field)
            //     if(Array.isArray(field.value.list)){
            //         return '\n' + field.value.value + '\n' + field.value.list.map(item => Object.values(item) + '\n').join('');
            //     }
            //     else{
            //         let tmpArray = JSON.parse(field.value.list);
            //         return '\n' + field.value.value + '\n' + tmpArray.map(item => Object.values(item) + '\n').join('');
            //     }
            case 'tablemulti':
            case 'tablemultin':
            case 'smailvalidmulti':
                fields = getTableMultiFieldsLabels(field)
                let values = ''
                let _value = Object.prototype.toString.call(field.value) === '[object Array]'? field.value : field.value.list
                _value.map((item, row) => {
                    if(Object.values(item).length > 0) {
                        Object.values(item).map((val, id) =>
                        {
                            if(id === 0) values += '\n' + ` ${row + 1}) `
                            if(val !== '') {
                                // console.log('val', val, typeof val);
                                let _val
                                if(typeof val === "object") _val = val.join(', ');
                                else _val = val
                                let nb = id + 1
                                if(_val !== '') values += `<sup>(${nb})</sup> ` + _val + ' | '
                            }
                            else return ''
                        })
                    }})
                return '\n' + fields + values;
            case 'boolean':
                return field.value == "1" ? "Y" : "N";
            case 'numeric':
                if(!isNaN(field.value)){
                    return field.value;
                }else return "";
            case 'plugform':
                return field?.value?.value
            case 'glocalis':
                try {
                    let tmp = JSON.parse(field?.value);
                    return '\n' + "Adr: "+ tmp.address + '\n' + "Lat: "+ tmp.latitude + '\n' + "Long: "+ tmp.longitude;
                }catch (e) {
                    return "";
                }
            default : return  "";
        }
    }else return "";
};

export const strReplaceContentField = (content, fields,model, plugnote, user) => {
    let fieldsCopy = [...fields]
    let copy = content;
    copy = copy.replaceAll('***', '{{')
    copy = copy.replaceAll('**', '}}')

    if(fieldsCopy.length > 0 && fieldsCopy[0].hasOwnProperty('hash')){
        fieldsCopy = fieldsCopy.filter((value, index )=> index !== 0).filter(Boolean);
    }

    fieldsCopy.map((field, index) => {
        // let searchRegex = "{{field"+field.id.toString()+"}}"
        // let searchRegexLabelled = "{{field" + field.id.toString() + '__' + field.title.substr(0, 18) + '}}'
        // let regex = new RegExp( "{{field"+field.id.toString()+"}}",'g');
        // if(copy.search(searchRegex)){
        //     copy = copy.replace(regex, getContentValueForField(field));
        // }

        // try {
        //     let regexLabelled = new RegExp(searchRegexLabelled,'g');
        //     if(copy.search(regexLabelled)){
        //         copy = copy.replace(searchRegexLabelled, getContentValueForField(field));
        //     }
        // }catch (e) {
        //     copy = copy.replace(searchRegexLabelled, getContentValueForField(field));
        // }

        let fieldInsert = "{{" + field.title.substr(0, 18) + '__field' +  field.id.toString() + '}}'
        let fieldId = "{{field" + field.id.toString()+"}}" // legacy version
        if(copy.includes(fieldId)) {
            copy = copy.replaceAll(fieldId, getContentValueForField(field, model))
        }
        if(copy.includes(fieldInsert)) {
            copy = copy.replaceAll(fieldInsert, getContentValueForField(field, model))
        }

    });


    //! champs modifiés dans le setup après la composition du mail
    // remplacé selon le label ou selon le numéro du champs
    //Si les deux valeurs changent, impossible de garantir la correction
    if (copy.includes("__field")) {
        let inserts = []
        let copyArray = copy.split('{{')
        copyArray.forEach(entry => {
            let insert  = entry.split('}}')[0]
            let id = insert.split('__field')[1]
            let label = insert.split('__field')[0]
            if(id !== undefined) inserts.push({id: id, insert: insert, label: label})
        })
        // console.log('inserts', inserts)
        inserts.forEach((value) => {
            let field = fieldsCopy.find(insert => insert.title.includes(value.label))
            // console.log('original', field)
            if (field !== undefined) {
                copy = copy.replaceAll('{{' + value.insert + '}}', getContentValueForField(field))
            }
            else {
                let index = parseInt(value.id)
                // console.log('wrong number', fields[index]);
                copy = copy.replaceAll('{{' + value.insert + '}}', getContentValueForField(fieldsCopy.find(field => field.id === index)))
            }})
    }

    //  {{PF Key}} {{PF}} {{User}} {{Date Modif}} {{PN}} {{PN-}} {{User Gsm}}

    let searchRegexPFKey = "{{PF Key}}";
    let regexPFKey = /{{PF Key}}/g;
    if(copy.search(searchRegexPFKey)){
        copy = copy.replace(regexPFKey, model?.clef || "");
    }

    let searchRegexPF = "{{PF}}"
    let regexPF = /{{PF}}/g
    if(copy.search(searchRegexPF)){
        copy = copy.replace(regexPF, model?.label || "");
    }

    let searchRegexUser = "{{User}}"
    let regexUser = /{{User}}/g
    if(copy.search(searchRegexUser)){
        copy = copy.replace(regexUser, user?.pseudo || "");
    }

    let searchRegexDateModif = "{{Date Modif}}"
    let regexDateModif = /{{Date Modif}}/g
    if(copy.search(searchRegexDateModif)){
        if(plugnote !== null){
            let date = typeof plugnote.moddate !== "undefined" ?  moment(plugnote.moddate).format('YY-MM-DD') : ""
            copy = copy.replace(regexDateModif, date );
        }else copy = copy.replace(regexDateModif, "" );
    }

    let searchRegexPn = "{{PN}}"
    let regexPn = /{{PN}}/g
    if(copy.search(searchRegexPn)){
        if(plugnote !== null){
            copy = copy.replace(regexPn, "1-" + plugnote?.userindex || "");
        }else copy = copy.replace(regexPn, "" );
    }


    let searchRegexPn_ = "{{PN-}}"
    let regexPn_ = /{{PN-}}/g
    if(copy.search(searchRegexPn_)){
        copy = copy.replace(regexPn_, _getCodePnPpCodeTemplate(plugnote));
    }

    let sarchRegexUserGsm = "{{User Gsm}}"
    let regexUserGsm = /{{User Gsm}}/g
    if(copy.search(sarchRegexUserGsm)){
        copy = copy.replace(regexUserGsm, user?.mobile || "");
    }

    let searchRegexPw = "{{PW}}"
    let regexPw = /{{PW}}/g
    if(copy.search(searchRegexPw)){
        copy = copy.replaceAll(regexPw, "" );
    }

    let searchRegexPwUrl = "{{PW Url}}"
    let regexPwUrl = /{{PW Url}}/g
    if(copy.search(searchRegexPwUrl)){
        copy = copy.replaceAll(regexPwUrl, "" );
    }


    return copy;
}

export const _getCodePnPpCodeTemplate = (plug) => {
    if(plug === undefined || plug === null) return ""
    const {publicpncode} = plug

    let codePn= 'PN';

    switch (publicpncode) {
        case 0:
            codePn = 'PP';
            break;
        case 1:
            codePn = 'PN';
            break;
        case 2:
            codePn = 'PE';
            break;
        case 3:
            codePn = 'PS';
            break;
        default:
            break;
    }

    let pncode = plug.codepncode ? `${codePn}-${moment(plug.creationdate).format('YYMMDD')}-${plug.codepncode}` : null

    if (pncode === null) {
        pncode = "";
    }

    return pncode
};

export const _getCodePFCode = (plugform) => {
    return plugform !== null && plugform.codepfcode != null ? 'PF-' + moment(plugform.creationdate).format('YYMMDD') + '-' + plugform.codepfcode : '';
};

const getTableMultiFieldsLabels = (fields) => {
    let modelFields = fields.options.lines === undefined ? fields.options : fields.options.lines
    if(modelFields !== null && modelFields !== undefined){
        let fieldStr = ''
        modelFields.map((value, index) => {
            fieldStr += (`<span><sup>(${index + 1})</sup> ${value.label} - </span>`)
        })
        return '&nbsp;&nbsp;&nbsp;&nbsp;' + fieldStr
    }
    else return null
}

export const isPrintable = (field, mode) => {
    let print = true
    if ((field.encodable === false || field.editable === false) && mode.includes('external-create')) { print = false }
    if (field.visible === false && mode.includes('external-view')) { print = false }
    if (field.showOnPdf === false) { print = false }
    return print
}

export const isVisibleInCSV = (field, mode) => {
    let visible = true
    if ((field.encodable === false || field.editable === false) && mode.includes('create')) { visible = false }
    if (field.visible === false && mode.includes('view')) { visible = false }
    return visible
}

export const getLabelWithSpecialChar = (label) => {
    let str = label
    if (label.includes('~')) {
        let array = label.split('~').map(entry => entry?.trim())
        str = array.join(' ')
    }
    if (str.includes('#')) {
        let array = label.split('#').map(entry => entry?.trim())
        str = array.join(' ')
    }
    return str
}

export function createEmptyRow(columns) {
    // devLog('createEmptyRow columns', columns)
    let data = {}
    columns.forEach(col => {
        data[col.id] = ''
    })
    return data
}

export const isEditableField = (field, mode) => {
    if (isDefined(mode)) {
        return !(field?.encodable === false)
    } else { return true }
}

export const isEditableColumnMode = (field, col, mode) => {
    if (isDefined(mode)) {
        return !(mode.includes('create') && col?.encodable === false)
    } else { return true }
}

export const isEditableColumn = (col) => {
    // devLog('isEditableColumn', col)
    if (isDefined(col)) {
        return !(
            col.id === 1000 || col.id === 1003 ||
            col.format === 'formula' ||
            col.format === 'file-source' ||
            col.format === 'file-name-text' ||
            col.format === 'file-comment-text' ||
            col.format === 'file-type-text' ||
            col.format === 'file-size-num' ||
            col.format === 'file-create-date' ||
            col.format === 'file-refid' ||
            col.format === 'file-uri' ||
            // col.format === 'intdatamaj' ||
            col.format === 's33data'
        )
    } else { return false }
}

export const populateRowWithDefaultValues = (columns, defaultRows, index, row) => {
    // devLog('populateRowWithDefaultValues columns, row', columns, row)
    if (defaultRows?.length > 0) {
        let defaultRow
        if (defaultRows.length > 1) {
            if (index + 1 < defaultRows.length) {
                defaultRow = defaultRows[index]
            } else {
                defaultRow = defaultRows.slice(-1)[0]
            }
        } else { defaultRow = defaultRows[0] }
        Object.keys(defaultRow).forEach(key => {
            let col = columns.find(entry => entry.id.toString() === key)
            if (key === 'locked') {
                if (defaultRow.locked) { row['locked'] = true }
            } else if (isDefined(col) && isEditableColumn(col)) {
                row[key] = defaultRow[key].value
            } else if (!isDefined(row[key])) {
                row[key] = ''
            }
        })
        return row
    } else return row
}

export const isEditableDefaultValue = (field, id, rindex, mode, isPw) => {
    let editable = true
    
    if (field.options.setup?.hasOwnProperty('defaultRows')) {
        let defaultRow = field.options.setup?.defaultRows[rindex]
        if (isDefined(defaultRow) && typeof defaultRow[id] === "object") {
            if (defaultRow[id].options.editable === false) {
                return false
            } else if (mode.includes('create')) {
                if ((isDefined(isPw) && isPw && !defaultRow[id].options.editablePw) || (!defaultRow[id].options.editablePf && isPw !== true)) {
                    return false
                }
            }
            // else if (mode.includes('create')) {
                // if ((isDefined(isPw) && isPw && !defaultRow[id].options.editablePw) || (!defaultRow[id].options.editablePf && !isDefined(isPw))) { return false }
            // }
        }
    }
    return editable
}

export const isEmptyField = (value) => {
    if (!isDefined(value)) {
        return true;
    }
    if (Array.isArray(value) || typeof value === 'string') {
        // console.log('Array.isArray(value) || typeof value === string', value.length === 0);
        return value.length === 0;
    } else if (typeof value === 'number') {
        return false;
    } else {
        let valArray = Object.values(value);
        // console.log('Object', valArray.some(row => !isEmptyField(row)));
        return !valArray.some(row => !isEmptyField(row));
    }
}
export const purgeDefaultEmptyRow = data => {
    // devLog('purging ', data)
    let _data = [...data]
    if (data.length > 0) {
        let lastRow = data[data.length - 1]
        // devLog('lastRow isEmpty ?', isEmptyField(lastRow))
        if (isEmptyField(lastRow)) {
            _data.pop()
        }
    }
    return _data
}
export const handleCleanEmptyRows = (rows) => {
    if (isDefined(rows) && rows.length > 0) {
        let cleaned = [...rows]
        let recap = cleaned.find(entry => entry?.recap === true)
        if (recap) { cleaned.pop() }
        let lastRow = cleaned[cleaned.length - 1]
        if (!Object.values(lastRow).some(entry => entry !== '')) {
            cleaned.pop()
        }
        return recap ? [...cleaned, recap] : cleaned
    } else return []
}

export const getAllDefaultRows = (columns, defaultRows) => {
    return defaultRows.map((_, index) => {
        return populateRowWithDefaultValues(columns, defaultRows, index, createEmptyRow(columns))
    })
}

export const getFieldDefaultValue = (format, options, value) => {
    if (isDefined(options.setup?.defaultValue)) {
        let _default = options.setup.defaultValue
        switch (format) {
            case 'text':
            case 'textarea':
            case 'url':
                return _default
            case 'qrcode':
                return _default + ';'
            // case '':
            // case '':
                // break;
            default:
                return value
        }
    } else {
        return value
    }
}
export const isRowLimitReached = (rowLimit, rows) => {
    if (!isDefined(rowLimit) || rowLimit === 0) {
        return false
    }
    if (rows.length > 0) {
        let lastRow = rows[rows.length - 1]
        let tableLength = lastRow.hasOwnProperty('recap') ? rows.length - 1 : rows.length
        return tableLength === rowLimit
    } else { return false }
}
export const isHiddenColumn = (col, mode) => {
    return (mode.includes('create') && col.encodable === false) || (mode.includes('consult') && col.visible === false)
}