import {Types} from '../actions/annexe';
import {getAnnexes, getModalAnnexes, setAnnexes, setModalAnnexes} from "../utils/Common";

// create initial state for reducers
const INIT_STATE = {
    fetching : false,
    fetchingModal : false,
    progress : 0,
    progressModal : 0,
    list : [],
    listModal : [],
    listVersion : [],
    version : null
};

// reducer function to transform state
export default function annexe(state = INIT_STATE, action) {
    switch(action.type) {
        case "RESET_ALL_DATA": return INIT_STATE;
        case Types.POST_ANNEXES_REQUEST: {
            return {
                fetching : true,
                progress: 0,
                list : [],
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_PROGRESS: {
            return {
                fetching : true,
                progress: action.progress,
                list : [],
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_FAILURE: {
            return {
                fetching : false,
                progress: 0,
                list : [],
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_ERROR: {
            return {
                fetching : false,
                progress: 0,
                list : [],
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_SUCCESS: {
            return {
                // list: [JSON.parse(action.payload.data)],
                list: [action.payload.data],
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.POST_ANNEXES_MODAL_REQUEST: {
            return {
                ...state,
                fetchingModal : true,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_MODAL_PROGRESS: {
            return {
                ...state,
                fetchingModal : true,
                progressModal: action.progress,
                listModal : [],
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_MODAL_FAILURE: {
            return {
                fetchingModal : false,
                progressModal: 0,
                listModal : [],
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_MODAL_ERROR: {
            return {
                fetchingModal : false,
                progressModal: 0,
                listModal : [],
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_MODAL_SUCCESS: {
            return {
                // list: [JSON.parse(action.payload.data)],
                listModal: [action.payload.data],
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.POST_ANNEXES_SEND_MAIL_SUCCESS: {
            return {
                // list: [JSON.parse(action.payload.data)],
                list: [action.payload.data],
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_SEND_MAIL_MODAL_SUCCESS: {
            return {
                // list: [JSON.parse(action.payload.data)],
                listModal: [action.payload.data],
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.POST_ANNEXES_VERSION_SUCCESS: {
            return {
                list: [action.payload.data],
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_VERSION_MODAL_SUCCESS: {
            return {
                listModal: [action.payload.data],
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.POST_ANNEXES_VERSION_SHARED_SUCCESS: {
            return {
                list: [action.payload.data],
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_VERSION_SHARED_MODAL_SUCCESS: {
            return {
                listModal: [action.payload.data],
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.POST_ANNEXES_SHARED_SUCCESS: {
            return {
                // list: [JSON.parse(action.payload.data)],
                list: [action.payload.data],
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ANNEXES_SHARED_MODAL_SUCCESS: {
            return {
                // list: [JSON.parse(action.payload.data)],
                listModal: [action.payload.data],
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.POST_ADD_NOTE_SUCCESS: {
            return {
                list: [action.payload.data],
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.POST_ADD_NOTE_MODAL_SUCCESS: {
            return {
                listModal: [action.payload.data],
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.PUT_EDIT_NOTE_SUCCESS: {
            let noteUpdated = action.payload.data;

            const list = getAnnexes().map((item) => {
                if(typeof item.Webdoc !== "undefined"){
                    if (item.Webdoc.id === noteUpdated.id) {
                        let annexe = item;
                        annexe.name = noteUpdated.annexeName;
                        annexe.Webdoc.content = noteUpdated.content;
                        return {
                            ...annexe,
                        }
                    } else {
                        return item
                    }
                }else{
                    return item
                }

            });

            setAnnexes(list);
            return {
                list: state.list,
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.PUT_EDIT_NOTE_MODAL_SUCCESS: {
            let noteUpdated = action.payload.data;

            const list = getModalAnnexes().map((item) => {
                if(typeof item.Webdoc !== "undefined"){
                    if (item.Webdoc.id === noteUpdated.id) {
                        let annexe = item;
                        annexe.name = noteUpdated.annexeName;
                        annexe.Webdoc.content = noteUpdated.content;
                        return {
                            ...annexe,
                        }
                    } else {
                        return item
                    }
                }else{
                    return item
                }

            });

            setModalAnnexes(list);
            return {
                listModal: state.listModal,
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.DEL_DELETE_ANNEXE_SUCCESS: {
            let annexes = getAnnexes();
            if(annexes !== null){
                let tmp = annexes.filter((item, index) => {
                    if(item.hash !== action.payload.data){
                        return item;
                    }
                });
                setAnnexes(tmp);
            }
            return {
                list: [],
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.DEL_DELETE_ANNEXE_MODAL_SUCCESS: {
            let annexes = getModalAnnexes();
            if(annexes !== null){
                let tmp = annexes.filter((item, index) => {
                    if(item.hash !== action.payload.data){
                        return item;
                    }
                });
                setModalAnnexes(tmp);
            }
            return {
                listModal: [],
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.DEL_DELETE_ANNEXE_VERSION_SUCCESS: {
            let annexes = getAnnexes();
            if(annexes !== null){
                annexes.map((item, index) => {
                    if(item.hash === action.payload.data.hash){
                        let findIndex = annexes.findIndex(element => element.hash === action.payload.data.hash);
                        annexes[findIndex].lastversion = action.payload.data.lastversion;
                        annexes[findIndex].creationdate = action.payload.data.creationdate;
                        annexes[findIndex].countVersion = action.payload.data.countVersion;
                    }
                });
                setAnnexes(annexes);
            }

            let listVersion = [];

            if(state.listVersion.length > 0){
                listVersion  = state.listVersion.filter((item, index) => {
                    if(item.lastversion !== action.version.version){
                        return item;
                    }
                });
            }

            return {
                list: [],
                fetching : false,
                progress: 0,
                listVersion : listVersion,
                version : null
            }
        }
        case Types.DEL_DELETE_ANNEXE_VERSION_MODAL_SUCCESS: {
            let annexes = getModalAnnexes();
            if(annexes !== null){
                annexes.map((item, index) => {
                    if(item.hash === action.payload.data.hash){
                        let findIndex = annexes.findIndex(element => element.hash === action.payload.data.hash);
                        annexes[findIndex].lastversion = action.payload.data.lastversion;
                        annexes[findIndex].creationdate = action.payload.data.creationdate;
                        annexes[findIndex].countVersion = action.payload.data.countVersion;
                    }
                });
                setModalAnnexes(annexes);
            }

            let listVersion = [];

            if(state.listVersion.length > 0){
                listVersion  = state.listVersion.filter((item, index) => {
                    if(item.lastversion !== action.version.version){
                        return item;
                    }
                });
            }

            return {
                listModal: [],
                fetchingModal : false,
                progressModal: 0,
                listVersion : listVersion,
                version : null
            }
        }

        case Types.PUT_ANNEXE_COMMENT_SUCCESS: {
            let annexes = getAnnexes();
            if(annexes !== null){
                annexes.map((item, index) => {
                    if(item.hash === action.payload.data){
                        item.comment = action.payload1.data1
                    }
                });
                setAnnexes(annexes);
            }
            return {
                list: [],
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.PUT_ANNEXE_COMMENT_MODAL_SUCCESS: {
            let annexes = getModalAnnexes();
            if(annexes !== null){
                annexes.map((item, index) => {
                    if(item.hash === action.payload.data){
                        item.comment = action.payload1.data1
                    }
                });
                setModalAnnexes(annexes);
            }
            return {
                listModal: [],
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.GET_ANNEXE_VERSION_SUCCESS:{
            return {
                list: state.list,
                fetching : false,
                progress: 0,
                listVersion : state.listVersion,
                version : action.payload.data
            }
        }
        case Types.GET_ANNEXE_VERSION_MODAL_SUCCESS:{
            return {
                listModal: state.listModal,
                fetchingModal : false,
                progressModal: 0,
                listVersion : state.listVersion,
                version : action.payload.data
            }
        }

        case Types.GET_ANNEXES_VERSION_SUCCESS:{
            return {
                list: state.list,
                fetching : false,
                progress: 0,
                listVersion : action.payload.data,
                version : null
            }
        }
        case Types.GET_ANNEXES_VERSION_MODAL_SUCCESS:{
            return {
                lilistModalst: state.listModal,
                fetchingModal : false,
                progressModal: 0,
                listVersion : action.payload.data,
                version : null
            }
        }

        case Types.GET_ANNEXES_VERSION_FAILURE:{
            return {
                list: state.list,
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.GET_ANNEXES_VERSION_MODAL_FAILURE:{
            return {
                listModal: state.list,
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.GET_ANNEXES_VERSION_ERROR:{
            return {
                list: state.list,
                fetching : false,
                progress: 0,
                listVersion : [],
                version : null
            }
        }
        case Types.GET_ANNEXES_VERSION_MODAL_ERROR:{
            return {
                listModal: state.listModal,
                fetchingModal : false,
                progressModal: 0,
                listVersion : [],
                version : null
            }
        }

        case Types.REDUX_GENERATE_ANNEXE_RESET: return INIT_STATE;


        default: return state;
    }
}