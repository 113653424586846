import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import colors from "../../../assets/themes/Colors";
import { Translation, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CircularProgress, Collapse, Fade, FormControlLabel, Input, InputAdornment, MenuItem, Popover, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import InputMask from 'react-input-mask';
import { Search } from "@material-ui/icons";
import { CSVDownload } from "react-csv";
import useWindowDimensions, { convertersAlphaToNumber, devLog, getElementCoords, isDefined, isLandscape } from "../../../utils/utils";
import { HyperFormula } from "hyperformula";
import { ColorCheckbox, DurationInput, RestyledNumericInput } from "../../custom/CustomComponents";
import ModalListExtDataComponent from "../extdata/ModalListExtDataComponent";
import { RenderItemMultipleSelectComponent, RenderItemSingleSelectComponent } from "../../custom/FormatTable";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import PDFTablemulti from "../../custom/pdftable/PDFTablemulti";
import { confirmAlert } from "react-confirm-alert";
import PlugTooltip from "../../custom/PlugTooltip";
import { ReactComponent as AutoImport } from '../../../../src/assets/images/format-icon/svg/auto-import.svg'
import { isEditableDefaultValue, isHiddenColumn, isRowLimitReached } from "../../../utils/Fields";
import { LockedIcon } from "../../custom/CustomIcons";
import { isMobile } from "react-device-detect";
import PersistentBottomDrawer from '../../custom/mobile/PersistentBottomDrawer'
import i18n from "../../../translations/i18n";
import { RiFileCopyLine } from "react-icons/ri";
import { MdDelete, MdDeleteSweep } from "react-icons/md";
import TablemultiHorizontalViewComponent from "./TablemultiHorizontalViewComponent";

function getModalStyle() {
    const top = 5;
    return {
        // top: `${top}%`,
        margin: 'auto',
        overflow: 'auto',
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90vw',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        height: '99vh',
        scrollbarWidth: 'none!important',
        "&::-webkit-scrollbar": {
            display: 'none'
        },
        overflow: 'hidden',
        '@media (max-width: 1024px)': {
            width: '95vw',
            minHeight: '60vh'
        },
        '@media (max-width: 640px)': {
            padding: '8px 0px 30px',
        }
    },
    popupLandscape: {
        paddingTop: 72,
    },
    closeBtn: {
        alignSelf: 'flex-end',
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        marginLeft: 15,
        marginBottom: 15,
        cursor: 'pointer',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalHeader: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        position: 'sticky',
        top: isMobile ? 40 : -17,
        zIndex: 9999,
        width: '100%',
        margin: 5,
        '@media (max-width: 640px)': {
            flexDirection: 'column-reverse'
        }
    },
    modalHeaderLandscape: {
        position: 'fixed',
        width: '90vw',
        top: 14,
    },
    titleDetails: {
        fontSize: 16,
        color: colors.darkSilver
    },
    modalBody: {
        margin: isMobile ? '40px 15px 30px' : '15px 15px 30px',
        maxWidth: '96vw',
        overflow: 'hidden',
    },
    modalBodyLandscape: {
        maxHeight: 'calc(85vh - 56px)',
        margin: 0,
    },
    headerTitle: {
        textAlign: 'center',
        '@media (max-width: 1299px)': {
            fontSize: '12px !important'
        },
        marginLeft: -50,
    },
    exportIconCtn: {
        width: 100,
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    searchInputCtn: {
        flex: 1,
        marginRight: 15,
        marginBottom: 15,
        '@media (max-width: 640px)': {
            order: 1,
            marginBottom: -40,
        },
    },
    input: {
        color: 'white',
        fontFamily: 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '8px',
        maxWidth: 350,
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '17px',
        MozBorderRadius: '8px',
        WebkitBorderRadius: '8px',
        '& .MuiInputBase-input': {
            width: 250,
            maxWidth: '20vw',
        }
    },
    tableCtn: {
        marginRight: -20,
        width: 'auto',
        maxHeight: '80vh',
        // height: isMobile ? '80vh' : 'auto',
        overflowY: 'auto',
        paddingRight: 2,
        '& .MuiTableCell-root': {
            padding: '2px',
        },
        position: 'relative',
    },
    horizontalTable: {
        '& .MuiTable-root .MuiTableCell-root': {
            minWidth: isMobile ? 72 : 30,
            border: '1px solid ' + colors.unselectedItem,
        },
    },
    table: {
        '& .MuiTable-root .MuiTableCell-root': {
            // width: 'auto !important',
            minWidth: isMobile ? 72 : 30,
            border: '1px solid ' + colors.unselected,
        },
        overflowX: isMobile ? 'auto' : 'hidden',
    },
    tablePreviewCtn: {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        maxHeight: '80vh',
        maxWidth: '92vw',
        overflowY: 'auto',
        marginLeft: isMobile ? 0 : -5,
        marginRight: -5,
        marginBottom: -4,
        '& .MuiTableCell-root': {
            borderRight: `1px solid ${colors.unselected}`,
            borderBottom: `1px solid ${colors.unselected}`,
            padding: '3px !important'
        },
        '& .MuiTable-root': {
            width: '99%',
            borderLeft: `1px solid ${colors.unselected}`,
            borderTop: `1px solid ${colors.unselected}`,
        },
    },
    stickyHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: colors.palePlugGreen,
        zIndex: 20,
    },
    stickyColumn: {
        position: 'sticky',
        left: 0,
        zIndex: 10,
        backgroundColor: 'white',
        minWidth: '25px !important',
        width: '25px !important',
        paddingRight: '2px !important',
    },
    stickyEditor: {
        position: 'sticky',
        left: 0,
        zIndex: 10,
        maxWidth: '80vw',
    },
    tableHeader: {
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: colors.palePlugGreen
        },
    },
    tableBodyCtn: {
        maxHeight: '55vh',
        overflow: 'auto',
        backgroundColor: 'white',
    },
    scrollBar: {
        "&::-webkit-scrollbar": {
            display: "block",
            position: 'absolute',
            overflowX: 'auto',
            background: colors.greyLight,
            width: 10,
            right: 0,
            borderRadius: 8,
        },
        "&::-webkit-scrollbar-thumb": {
            background: colors.grey,
            borderRadius: 8,
        },
    },
    columnHeader: {
        fontSize: 11,
        '@media (max-width: 640px)': {
            fontSize: 11,
        }
    },
    thinColumn: {
        '& .MuiTable-root .MuiTableCell-root': {
            width: '30px !important',
        },
        width: '30px !important',
    },
    rowNbEdit: {
        fontSize: 11,
        border: '1px solid',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0px 0px 10px 1px',
            fontWeight: 'bold',
            backgroundColor: colors.mainButton,
            borderColor: colors.mainButton,
            color: 'white',
        },
    },
    rowNb: {
        fontSize: 10,
        border: '1px solid',
        borderRadius: '50%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: 20,
        width: 20,
        '&:hover': {
            boxShadow: '0px 0px 10px 1px',
            fontWeight: 'bold',
            backgroundColor: colors.mainButton,
            borderColor: colors.mainButton,
            color: 'white',
        },
    },
    rowNbColumnHeader: {
        position: 'sticky',
        left: 0,
        zIndex: 30,
        borderRight: '1px solid ' + colors.unselected,
        backgroundColor: colors.palePlugGreen,
        minWidth: '25px !important',
        width: '25px !important',
        paddingRight: '0px !important',
    },
    rowNbPreview: {
        fontSize: 11,
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    actionColumn: {
        minWidth: '28px !important',
        width: '28px !important',
        paddingRight: '0px !important',
    },
    verticalDotsCtn: {
        width: 28, maxWidth: '28px !important', display: 'flex',
        // marginRight: -40,
    },
    // Popover
    popoverCtn: {
        padding: '8px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        alignItems: 'flex-start',
    },
    popoverItemCtn: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        cursor: 'pointer',
    },
    //selects
    selectsCtn: {
        minHeight: 24,
        gap: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        maxWidth: 150
    },
    selectItem: {
        color: colors.unselected,
        borderColor: colors.selectedItem,
        padding: '0px 5px',
        margin: '1px 0px',
        border: '1px solid',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
        minHeight: 25,
        minWidth: 30,
    },
    //editor
    editFieldsCtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: isMobile ? '80vw' : '45%',
        minHeight: 60,
        minWidth: isMobile ? 200 : 400,
        maxWidth: '80vw',
        position: 'relative',
        padding: 2,
    },
    editIcon: {
        position: 'absolute',
        top: 10,
        left: -20,
        fontSize: 20,
        color: colors.mainButton,
        cursor: 'pointer'
    },
    editRow: {
        '& .MuiTableCell-root': {
            padding: '0px !important',
            verticalAlign: 'baseline',
            borderColor: colors.transparent + ' !important',
        },
        zIndex: 0,
        backgroundColor: colors.hoverBgnd
    },
    singleEditorCtn: {
        minWidth: 'max-content',
        display: 'flex',
        flexDirection: 'row',
        padding: 8,
        // backgroundColor: colors.hoverBgnd,
    },
    editorCtn: {
        zIndex: 50,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        padding: 2,
    },
    cellPreviewCtn: {
        display: 'flex',
        order: 2,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    voidCell: {
        height: 24,
        width: '100%',
    },
    blankCell: {
        height: 24,
    },
    absoluteIcon: {
        position: 'absolute',
        bottom: 8,
        right: -48,
        '@media (max-width: 640px)': {
            display: 'none'
        }
    },
    validateIcon: {
        fontSize: 20,
        backgroundColor: colors.mainButton,
        borderRadius: 5,
        padding: '3px 8px',
        color: 'white',
        cursor: 'pointer'
    },
    //fields
    singleTitleCtn: {
        order: 1,
        maxWidth: '20%',
        marginRight: 10
    },
    singleValueCtn: {
        display: 'flex',
        order: 2,
        width: '100%'
    },
    singleWiderValueCtn: {
        order: 2,
        width: '100%',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
        alignSelf: 'center',
        display: 'inline-flex',
        alignItems: 'center',
        gap: 4,
    },
    fieldValueContainer: {
        display: 'flex',
        marginLeft: 'auto',
        order: 2,
        width: '60%'
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width: 'calc(60% + 32px)',
        alignSelf: 'flex-end',
    },
    fontFieldLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.main
    },
    fontFieldNumber: {
        fontSize: 12,
        display: 'inline-flex',
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        verticalAlign: 'bottom',
        width: 20,
        height: 20,
    },
    stylizedValue: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3,
    },
    fontTablemulti: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    totalCtn: {
        display: 'flex',
        height: 20,
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 7,
    },
    totalValues: {
        color: colors.red,
        fontSize: 11,
        fontWeight: 'bold',
        padding: '0 5px',
        textAlign: 'left',
        wordBreak: 'break-word',
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 10,
        paddingRight: '10px !important',
    },
    selectField: {
        maxHeight: 20,
    },
    tablemultiInputCtn: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        gap: 3,
        flexWrap: 'nowrap',
        minHeight: 25
    },
    tablemultiRadioCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        marginLeft: 3,
    },
    tablemultiInputBtn: {
        height: '20px',
        width: '20px',
        color: colors.main,
        alignItems: 'center',
        fontSize: "20px",
        margin: '0px 5px',
    },
    tablemultiBtn: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        // fontSize: 22,
    },
    searchBtn: {
        fontSize: 15,
        minWidth: 70,
        cursor: 'pointer',
    },
    colorDisable: {
        color: colors.grey
    },
    notAllowedIcon: {
        position: 'absolute',
        right: 15,
        bottom: 5,
        fontSize: 20,
        textShadow: '0px 2px 1px white',
        fontWeight: 'bolder',
    },
    urlButton: {
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    noDataCell: {
        textAlign: 'center',
        borderLeft: '1px solid #e0e0e0',
    },
    noDataTxt: {
        fontStyle: 'italic',
        fontSize: 12,
    },
    deleteBtnCtn: {
        color: colors.main,
        top: 'calc(50% - 12px)'
    },
    headerDeleteBtnCtn: {
        marginRight: -6,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    textLength: {
        fontSize: 8,
        alignSelf: 'flex-start',
    },
    autoImportBtn: {
        width: 50,
        height: 32,
        borderRadius: 6,
        marginTop: 2,
        // boxShadow: '0px 1px 2px 1px #ddd',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: colors.main,
        '&:hover': {
            fontWeight: 'bold',
        },
    },
    autoImportBtnImg: {
        color: colors.main,
        width: 32,
    },
    icon: { fontSize: 32, color: colors.mainButton },
    disabledIcon: {
        color: colors.iconGrey,
        cursor: "not-allowed",
        fontSize: 32,
    },
    disabledCellIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
}))

function getCellColumn(cellId, columns) {
    // console.log('getCell', cellId, columns);
    if (cellId) {
        let column = columns.find(obj => {
            return obj.id.toString() === cellId.toString()
        })
        return column;
    }
}

// Finds special chars in column label
const getColumnLabel = (col, _defaultLabelSize, orientation) => {
    const headerStyle = {
        fontSize: isDefined(col.headerFontSize) && col.headerFontSize > -1 ? col.headerFontSize : _defaultLabelSize,
        maxWidth: col.colWidth > 30 ? col.colWidth : 'auto',
        whiteSpace: "break-spaces",
        textAlign: col.labelAlignment || 'center',
        // margin: '0px auto',
        // wordBreak: orientation === 'horizontal' ? 'normal' : 'break-word',
        overflowWrap: 'break-word',
    }
    const headerIcon = {
        fontSize: headerStyle.fontSize,
        fontWeight: 'bold',
        cursor: 'pointer',
    }
    const headerCtn = {
        padding: '0 4px',
    }

    switch (col.labelAlignment) {
        case 'left':
            headerStyle.marginRight = 'auto'
            break
        case 'right':
            headerStyle.marginLeft = 'auto'
            break
        case 'center':
        default:
            headerStyle.marginLeft = 'auto'
            headerStyle.marginRight = 'auto'
            break
    }
    let template;
    let hasTooltip = false;
    if (col.label.includes('~')) {
        const parts = col.label.split("~");
        template = parts.map((part, i) => {
            let section;
            if (part.includes('#')) {
                hasTooltip = true;
                section = part.split('#')[0] + '...';
            } else section = part;
            if (i !== parts.length - 1) {
                return (
                    <span key={'label-section-' + section} style={headerStyle} >
                        {
                            col.mandatory && i === 0 &&
                            <PlugTooltip title={i18n.t('common:helper.mandatory-column')}>
                                <span style={headerIcon} >* </span>
                            </PlugTooltip>
                        }
                        {section}<br />
                    </span>)
            }
            return (
                <span key={'label-section-' + section} style={headerStyle}>
                    {
                        col.mandatory && i === 0 &&
                        <PlugTooltip title={i18n.t('common:helper.mandatory-column')}>
                            <span style={headerIcon} >* </span>
                        </PlugTooltip>
                    }
                    {section}
                </span>
            )
        })
    } else if (col.label.includes('#')) {
        const index = col.label.indexOf("#");
        hasTooltip = true;
        template = (
            [<span key={'label-slice-' + col.label} style={headerStyle} >
                {
                    col.mandatory &&
                    <PlugTooltip title={i18n.t('common:helper.mandatory-column')}>
                        <span style={headerIcon} >* </span>
                    </PlugTooltip>
                }
                {col.label.slice(0, index)}
                <span style={headerStyle}>...</span>
            </span>]
        );
    } else {
        template = (
            [<div
                key={'label-section-' + col.label}
                style={headerCtn}
            >
                {
                    col.mandatory &&
                    <PlugTooltip title={i18n.t('common:helper.mandatory-column')}>
                        <span style={headerIcon} >* </span>
                    </PlugTooltip>
                }
                <span key={'label-' + col.label} style={headerStyle}>{col.label}</span>
            </div>]
        );
    }
    let label = col.label.replaceAll('#', ' ');
    return (hasTooltip ?
        <PlugTooltip title={label.replaceAll('~', '\n')} placement="top">
            <div style={headerStyle}>
                {template.map(part => part)}
            </div>
        </PlugTooltip>
        :
        <div style={headerStyle}>
            {template.map(part => part)}
        </div>
    )
}

const handleSpecialCharsInLabel = (label, classes) => {
    let template;
    let hasTooltip = false;
    if (label.includes('~')) {
        const parts = label.split("~");
        template = parts.map((part, i) => {
            let section;
            if (part.includes('#')) {
                hasTooltip = true;
                section = part.split('#')[0] + '...';
            } else section = part;
            if (i !== parts.length - 1) {
                return (<span key={'label-section-' + section} className={classes.fontFieldLabel}>{section} </span>);
            }
            return (<span key={'label-section-' + section} className={classes.fontFieldLabel}>{section}</span>);
        });
    } else if (label.includes('#')) {
        const index = label.indexOf("#");
        hasTooltip = true;
        template = (
            [<span key={'label-slice-' + label} className={classes.fontFieldLabel}>
                {label.slice(0, index)}
                <span >...</span>
            </span>]
        );
    } else {
        template = (
            [<div
                key={'label-section-' + label}
                className={classes.fontFieldLabel}
            >
                {label}
            </div>]
        );
    }
    let _label = label.replaceAll('#', '');
    return (hasTooltip ?
        <PlugTooltip title={_label.replaceAll('~', ' ')} placement="top">
            <span className={classes.fontFieldLabel}>
                {template.map(part => part)}
            </span>
        </PlugTooltip>
        :
        <>
            {template.map(part => part)}
        </>
    );
}

const ToastMessage = ({ title, message, folder }) => (
    <div>
        {title !== '' &&
            <Translation ns="common">
                {(t, { i18n }) => <h5>{t(title)}</h5>}
            </Translation>
        }
        <Translation ns="common">
            {(t, { i18n }) => <p>{t(message, { folder: folder })}</p>}
        </Translation>
    </div>
)

const immutableFormats = ['formula'];

const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

const RenderCellValue = (props) => {
    const classes = useStyles()
    const columns = props.columns
    const value = props.value
    const cellId = props.id
    const column = getCellColumn(cellId, columns)

    const textStyle = {
        fontSize: column.fontSize > -1 ? column.fontSize : props.defaultFontsize,
    }

    switch (column.format) {
        case 'file-uri':
            if (typeof value !== 'undefined' && value !== '') {
                let fileSource = props.columns.find((column, index) => column.format === 'file-source');
                if (fileSource) {
                    if (typeof props.row !== "undefined") {
                        switch (props.row[fileSource.id]) {
                            case 'PLUGNOTE':
                                if (value !== "") {
                                    return (
                                        <div className={classes.cellPreviewCtn}>
                                            <span onClick={() => openInNewTab(value.toString())} data-skip="" className={classes.urlButton} rel="noreferrer noopener"><span className="material-icons md-small white">link</span></span>
                                        </div>
                                    )
                                } else return null
                            case 'URL':
                                if (value !== "") {
                                    return (
                                        <div className={classes.cellPreviewCtn}>
                                            <span onClick={() => openInNewTab(value.toString())} data-skip="" className={classes.urlButton} rel="noreferrer noopener"><span className="material-icons md-small white">link</span></span>
                                        </div>
                                    )
                                } else return null
                            case 'FTP':
                                if (value !== "") {
                                    return (
                                        <div className={classes.cellPreviewCtn}>

                                        </div>
                                    )
                                } else return null
                        }
                    } else return null;
                }
                break;
            } else return null
        case 'radio':
            return (
                column.mask === 'Checkbox'
                    ? (
                        <div
                            style={{ justifyContent: 'center', flex: 1 }}>
                            <ColorCheckbox
                                checked={value === 'Y'}
                                color={value === '' ? colors.greyLight : colors.main}
                                size={30}
                            />
                        </div>
                    ) : <span style={textStyle}>{value}</span>
            )
        case 'date':
        case 'duration':
        case 'extdata':
        case 'formula':
        case 'mail':
        case 'numeric':
        case 'select':
        case 'tel':
        case 'text':
        case 'textarea':
        case 's33data':
        case 'time':
        case 'file-source':
        case 'file-refid':
        case 'file-name-text':
        case 'file-comment-text':
        case 'file-type-text':
        case 'file-size-num':
        case 'file-create-date':
            return <span className={classes.columnHeader} style={textStyle}>{value}</span>
        case 'presel':
            if (column.mask.multiselect === true) return (
                <div className={classes.selectsCtn}>
                    {value.length > 0 &&
                        value
                            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                            .map((value, index) => (
                                <div className={classes.selectItem} key={index}>
                                    <span key={`select-item-${index}`}>{value}</span>
                                </div>))}
                </div>
            );
            else return (
                <div className={classes.selectsCtn}>
                    <div className={classes.selectItem}>
                        <span key={`select-item`}>{value}</span>
                    </div>
                </div>
            )
        case 'numeric-ref': {
            let tmpValue = value;

            if (typeof tmpValue !== 'undefined' && tmpValue == '') {
                let target = column?.configuration?.target;
                if (typeof target !== "undefined") {
                    let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                    if (field) {
                        tmpValue = field.value;
                    }
                }
            }

            return <span className={classes.columnHeader} style={textStyle}>{tmpValue}</span>
        }
        case 'url':
            if (isDefined(value && value.length > 0)) {
                return (
                    <div className={classes.cellPreviewCtn}>
                        <span style={textStyle}>{value}</span>
                        <span onClick={() => openInNewTab(value.toString())} data-skip="" className={classes.urlButton} rel="noreferrer noopener">
                            <span className="material-icons md-small white">link</span>
                        </span>
                    </div>
                )
            } else return null
        default:
            return (<span className={classes.columnHeader}>{`<!> ERROR <!>`}</span>)
    }
}

const restylizeNumber = (number) => {
    if ((typeof number === "number" || typeof number === 'string') && number !== '') {
        let nombre = 0
        let coma = false
        if (typeof number === "number") nombre = number;
        else if (number.charAt(number.length - 1) === ',') {
            nombre = parseFloat(number.slice(0, -1))
            coma = true
        }
        else nombre = parseFloat(number.replace(',', '.'))
        let restyled = ''
        let ent = 0
        ent = Math.floor(nombre);
        let stInt = ''
        let decim = number.toString().split('.')[1]
        if (!number.toString().includes('.')) decim = number.toString().split(',')[1]
        if (nombre > 999) {
            ent += ''
            let rank = 1
            for (let index = ent.length - 1; index >= 0; index--) {
                const nb = ent.charAt(index);
                if (rank % 3 === 0 && index >= 1 && rank !== 0) stInt = '.' + nb + stInt;
                else stInt = nb + stInt.toString()
                rank++;
            }
        }
        else stInt = ent.toString()
        if (decim !== undefined) restyled = stInt + ',' + decim;
        else restyled = stInt
        return restyled.concat(coma ? ',' : '')
    }
    return ''
}

export const RenderEditCellComponent = (props) => {
    const classes = useStyles();
    let template;
    const { t } = useTranslation(['common']);

    let tmpValue = "";
    if (typeof props.value !== "undefined" && (props.col.format !== 'numeric' || props.col.format !== 'numeric-ref') && props.value.length > 0) {
        tmpValue = props.value;
    }
    else if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric') {
        tmpValue = restylizeNumber(props.value);
    } else if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric-ref') {
        //console.log(props.value);
        if (props.value == "") {
            let target = props?.col?.configuration?.target;
            //console.log('target' , target);
            if (typeof target !== "undefined") {
                let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                if (field) {
                    //console.log('field',field);
                    tmpValue = field.value;
                }
            }
        } else {
            tmpValue = props.value;
        }
    }

    //Props const
    const field = props.field
    const fields = props.fields
    const model = props.model
    const plugnote = props.plugnote
    const user = props.user

    const isEditable = isEditableDefaultValue(field, props.col.id, props.rindex, props.mode, props.isFromPwCode)
    const [value, setValue] = useState(tmpValue)

    const disabled = props.disabled === true
    const owner = typeof props.owner === 'undefined' ? null : props.owner
    const ref = props?.col?.configuration?.ref || ""
    const query = props?.col?.configuration?.query || ""
    const dbcolumn = props?.col?.configuration?.column || ""
    const dbcolumns = props?.col?.configuration?.columns || ""
    const column = props.col
    const options = props?.col?.configuration || { ref: "", query: "LIKE", columns: "", column: "", dest: "" }
    const dest = props?.col?.configuration?.dest || ""
    const authorizeDBSearch = props?.model?.authoriseExternalDbInPf === undefined ? false : props?.model?.authoriseExternalDbInPf
    const formula = props?.col?.configuration?.formula || null
    const optionFormule = { licenseKey: 'gpl-v3' }
    const ownerId = props.ownerId
    const [open, setOpen] = useState(false);
    const [openSelect, setOpenSelect] = useState(false)
    const [loading, setLoading] = useState(false);
    const [duration, setDuration] = useState(value !== '' && value !== undefined && value !== null && props.col.format === 'duration' ? props?.col?.regex === '99:99' ? [value.split(':')[0] || '', value.split(':')[1]] || '' : [value.split(':')[0] || '', value.split(':')[1] || '', value.split(':')[2] || ''] : ['', '', ''])
    const [accept, setAccept] = useState(false)
    const columns = props.columns

    let titleCtn,
        fieldCtn,
        widerCtn,
        editorCtn
    // styles différents selon que l'on édite une cellule seule ou toute une ligne
    switch (props.edit) {
        case "row":
            titleCtn = classes.fieldTitleContainer
            fieldCtn = classes.fieldValueContainer
            widerCtn = classes.widerFieldValueContainer
            editorCtn = classes.editorCtn
            break;
        case "cell":
            titleCtn = classes.singleTitleCtn
            fieldCtn = classes.singleValueCtn
            widerCtn = classes.singleWiderValueCtn
            editorCtn = classes.singleEditorCtn
            break;
        case "preview":
            fieldCtn = classes.sendMailPreviewCtn
            break
        default:
            break
    }

    const handleOpenSearchModel = () => {
        if (authorizeDBSearch && !disabled) props.attemptGetDataFromAnotherBaseByClientReset();
        setOpen(true);
    }

    const handleChangeTableValue = (value) => {
        if (!props.disabled) {
            setValue(value)
            if ((column.format === 'time' && !value?.includes('_')) || column.format !== 'time') props.handleChangeChildrenValue(value)
        }
    }

    const handleGetValueFromTable = (col, fields, value) => {
        if (value !== "") {
            let target = col?.configuration?.target;
            let targettable = col?.configuration?.targettable;
            //console.log('target' , target);
            if (typeof target !== "undefined") {
                let field = fields.find(field => Number(field.id) === Number(target));
                if (field) {
                    let tmpValue = field?.value?.list ? field.value.list : field.value;
                    if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                        let options = field.options?.lines ? field.options.lines : field.options;
                        setValue(tmpValue[(value - 1)][options[(targettable - 1)].id]);
                        handleChangeTableValue(tmpValue[(value - 1)][options[(targettable - 1)].id]);
                    }

                }
            }
        }
    }

    useEffect(() => {
        if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric-ref') {
            if (props.value === "") {
                let target = props?.col?.configuration?.target;
                //console.log('target' , target);
                if (typeof target !== "undefined") {
                    let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                    if (field) {
                        tmpValue = field.value;
                    }
                }
            } else {
                setValue(props.value);
            }
        }
        if (column.format === 'formula') {
            if (isDefined(props.value)) { setValue(props.value) }
        }
    }, [props.value])

    useEffect(() => {
        if (props.col.format === 'duration') {
            if (value !== '' && value === undefined) {
                let one = value.split(':')[0],
                    two = value.split(':')[1],
                    three = value.split(':')[2]
                setDuration([one, two, three])
            }
        } else if (props.col.format === 'numeric-ref') {
            if (props.value === "" && value !== "") {
                handleChangeTableValue(value);
            }
        }
    }, [value])

    const handleSelectData = (object) => {
        let jsonObjectToArray = Object.values(object)
        let destinations = [];
        let champs = dest.split('-');

        champs.forEach(elm => {
            let field = elm.split(':');

            if (field.length > 0 && field.length <= 3) {
                let dest = field[0];
                let col = field[1];
                let copyValue = col?.split(';') || col;
                let value = "";
                let row = Object.values(props.row);
                let keys = Object.keys(props.row);

                if (typeof row[(dest - 1)] !== "undefined") {
                    copyValue.map((item, index) => {
                        let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                        let data = jsonObjectToArray[alphaNumIndexDest];
                        if (typeof data !== "undefined") {
                            let temp = data;
                            if (temp !== "" && typeof temp !== "undefined") {

                                if (temp === null) {
                                    temp = "";
                                }

                                if (temp !== "") {

                                    if (item === item.toUpperCase()) {
                                        value = value === "" ? value.concat(temp) : value.concat(' ~ ' + '\n' + temp);
                                    } else {
                                        value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                    }
                                }
                            }
                        }
                    });
                    destinations.push({
                        "dest": keys[(dest - 1)],
                        "value": value
                    })
                }
            }
        });

        if (destinations.length > 0) {
            let value = { ...props.row }
            destinations.forEach(destination => {
                value[destination.dest] = destination.value
            })
            props.handleChangeChildrenRowValue(value, props.col, props.row, props.rindex)
        }
        setOpen(false)
    }

    const handleClickCalculateValue = async () => {
        let value = await handleCalculateValue(column.configuration.formula)
        handleChangeTableValue(value)
    }

    const handleCalculateValue = async (formula) => {
        // console.log('Recalculate value', formula)
        let row = props.row
        let answer = null
        let tmp = []

        columns.map((elm, index) => {
            let tmpValue = row[elm.id]
            if(Object.prototype.toString.call(tmpValue) === '[object Array]' || Object.prototype.toString.call(tmpValue) === '[object Object]') {
                tmpValue = ""
            }
            if(elm.format === 'numeric' && row[elm.id] !== undefined && typeof row[elm.id] !== 'number'){
                tmpValue = row[elm.id].replace(', ', '.')
            }
            tmp.push([tmpValue])
        });

        if (formula !== null) {
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            }
            let sheetAData = data
            let newFormula =  formula
            const hfInstance = HyperFormula.buildEmpty(optionFormule)

            let formulasData = [[
                newFormula
            ]]

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName)
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData)

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName)
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData)
            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            )


            try {
                const mySum = hfInstance.getCellValue(cellAddress)

                if(!mySum.hasOwnProperty('type')){
                    answer =  mySum
                }else{
                    answer =  ""
                }
            }catch (e){
                answer =  ""
            }

            hfInstance.destroy()
        }
        return answer
    }

    const monthLength = (month, bissextile) => {
        if (month === 2) {
            if (bissextile === true || bissextile === undefined) return 29;
            else return 28
        }
        if (month % 2 === 0 && month < 7) return 30
        if (month > 8 && month % 2 !== 0) return 30
        return 31
    }
    const isBissextile = (year) => {
        if (year % 4 === 0 && year % 100 !== 0) return true
        if (year % 400 === 0) return true
        return false
    }

    let presentTime = new Date()
    let nowVal = ''

    const handleOnBlur = (e) => {
        if (e.key === 'Enter') {
            if (column.format === 'extdata') handleOpenSearchModel();
            //console.log('blur...');
            if (props.edit === 'cell') {
                props.handleEndEdit();
            }
        }
    }

    switch (column.format) {
        case 'file-source': {
            let sourcePresels = ['PLUGNOTE', 'FTP', 'URL'];
            template = (
                <div className={widerCtn}>
                    <Select
                        classes={{
                            icon: classes.selectIcon,
                            // nativeInput: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                            root: `${classes.fontTablemultiWidth} ${classes.selectField}`,
                            select: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                        }}
                        //disabled={props.disabled}
                        disabled={true}
                        disableUnderline
                        displayEmpty
                        fullWidth={true}
                        id="demo-simple-select"
                        labelId="demo-simple-select-label"
                        //onChange={(event => handleChangeChildrenRowWithPreValueValue(event.target.value, props.row))}
                        //onClick={() => setOpenSelect(!openSelect)}
                        //open={openSelect}
                        renderValue={(value) => value !== '' && (<span className={classes.selectedItem}>{value}</span>)}
                        value={value}
                    >
                        {sourcePresels.map((row, index) => (
                            <MenuItem key={index} value={row} dense>{row}</MenuItem>
                        ))}
                    </Select>
                </div>
            );
            break;
        }
        case 'file-name-text':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: '512', width: '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        disabled={props.col.id === 1000 ? true : props.disabled}
                        InputProps={{
                            classes: {
                                input: classes.textFont,
                                root: props.col.id === 1000 ? classes.renderBlankContainer : classes.renderSingleSelContainer
                            },
                            endAdornment: value !== null && value !== undefined && (value.length > parseInt(props.col.mask) - 10 && props.col.id !== 1000 ?
                                <React.Fragment>
                                    <span className={classes.textLength}>{value.length}/512</span>
                                </React.Fragment>
                                : null
                            )
                        }}
                    />
                </div>
            )
            break;
        case 'file-comment-text':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: '512', width: '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        disabled={props.col.id === 1000 ? true : props.disabled}
                        InputProps={{
                            classes: {
                                input: classes.textFont,
                                root: props.col.id === 1000 ? classes.renderBlankContainer : classes.renderSingleSelContainer
                            },
                            endAdornment: value !== null && value !== undefined && (value.length > parseInt(props.col.mask) - 10 && props.col.id !== 1000 ?
                                <React.Fragment>
                                    <span className={classes.textLength}>{value.length}/512</span>
                                </React.Fragment>
                                : null
                            )
                        }}
                    />
                </div>
            )
            break;
        case 'file-type-text':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: '4', width: '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        disabled={props.col.id === 1000 ? true : props.disabled}
                        InputProps={{
                            classes: {
                                input: classes.textFont,
                                root: props.col.id === 1000 ? classes.renderBlankContainer : classes.renderSingleSelContainer
                            },
                            endAdornment: value !== null && value !== undefined && (value.length > parseInt(props.col.mask) - 10 && props.col.id !== 1000 ?
                                <React.Fragment>
                                    <span className={classes.textLength}>{value.length}/4</span>
                                </React.Fragment>
                                : null
                            )
                        }}
                    />
                </div>
            )
            break;
        case 'file-size-num':
            //fixed pasting values
            // https://github.com/s-yadav/react-number-format/issues/349
            template = (
                <div className={`${classes.fieldValueContainer} ${classes.renderSingleSelContainer}`}>
                    <NumberFormat
                        {...props}
                        // allowedDecimalSeparators={[',', '.']}
                        className={classes.stylizedValue}
                        customInput={RestyledNumericInput}
                        decimalScale={3}
                        decimalSeparator={","}
                        format={null}
                        onCopy={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(value)
                        }}

                        onValueChange={(v, infos) => {
                            // formattedValue: '$23,234,235.56', //value after applying formatting
                            // value: '23234235.56', //non formatted value as numeric string 23234235.56, if you are setting this value to state make sure to pass isNumericString prop to true
                            // floatValue: 23234235.56 //floating point representation. For big numbers it can have exponential syntax
                            //setNumericValue(v);
                            setValue(v.floatValue);
                            if (infos.event !== null) {
                                handleChangeTableValue(v.floatValue);
                            } else if (infos.event === null && v.value !== value.toString()) {
                                handleChangeTableValue(v.floatValue);
                            }
                        }}
                        thousandSeparator={false}
                        value={value}
                    />
                </div>
            );
            break;
        case 'file-create-date': {
            let dateCreateMask = ''
            template = (
                <div className={fieldCtn}>
                    <TextField
                        inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                        placeholder={dateCreateMask === '' ? '' : props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        disabled={props.col.id === 1003}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        onChange={(event) => { handleChangeTableValue(event.target.value) }}
                        value={value}
                    />
                </div>
            );
            break;
        }
        case 'file-refid':
            template = (
                <div className={fieldCtn}>
                    <TextField
                        inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        disabled={true}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        //onChange={(event) => {handleChangeTableValue(event.target.value)}}
                        value={value}
                    />
                </div>
            );
            break;
        case 'file-uri': {
            let fileSource = props.columns.find(column => column.format === 'file-source');
            if (fileSource) {
                switch (props.row[fileSource.id]) {
                    case 'PLUGNOTE':
                        if (value !== "") {
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <a href={value.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                                </div>
                            )
                        }
                        break;
                    case 'URL':
                        if (value !== "") {
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <a href={value.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                                </div>
                            )
                        }
                        break;
                    case 'FTP':
                        if (value !== "") {
                            template = (
                                <div className={classes.fieldValueContainer}></div>
                            )
                        }
                        break;
                    default:
                        break;
                }
            }
            break;
        }
        case 'intdata': {
            const titleIntFormat = `( ${ref} )  ( ${query} ) [ ${dbcolumn} ] [ ${dbcolumns} ] [ ${dest} ]`
            template = (
                <div className={fieldCtn}>
                    <Input
                        autoFocus={props.edit === "cell"}
                        className={`${classes.fontTablemulti} ${classes.tablemultiInputCtn}`}
                        classes={{ root: classes.tablemultiInputCtn }}
                        disabled={props.disabled || !isEditable}
                        fullWidth={true}
                        inputProps={{ maxLength: props.col.mask, width: '100%' }}
                        multiline={true}
                        name={props.col.format + '-' + props.cindex}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        onKeyDown={(e) => { if (e.key === 'Enter') handleOpenSearchModel() }}
                        placeholder={t('common:global.search')}
                        endAdornment={
                            !disabled && authorizeDBSearch &&
                            <PlugTooltip title={t('global.not-plugcodes')} placement="top">
                                <InputAdornment position="end"
                                    // onClick={(event) => handleOpenSearchModel()}
                                    style={{ cursor: 'not-allowed', right: 0, position: 'absolute', color: colors.iconGrey }}>
                                    <Search />
                                </InputAdornment>
                            </PlugTooltip>
                        }
                        type="text"
                        value={value}
                    />
                </div>
            )
            break;
        }
        case 'date': {
            let dateMask = ''
            let day = presentTime.getDay() < 10 ? '0' + presentTime.getDay() : presentTime.getDay()
            let month = presentTime.getMonth() + 1 < 10 ? '0' + (presentTime.getMonth() + 1) : presentTime.getMonth() + 1
            let year = presentTime.getFullYear().toString()

            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                dateMask = props.col.regex
            }
            let dateIsInvalid = false
            switch (dateMask) {
                case '99/99':
                    if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0, 2)) > monthLength(parseInt(value.substr(3, 2))) || parseInt(value.substr(3, 2)) >= 13
                    nowVal = day + '/' + month
                    break;
                case '99/99/99':
                    if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0, 2)) > monthLength(parseInt(value.substr(3, 2))) || parseInt(value.substr(3, 2)) >= 13
                    nowVal = day + '/' + month + '/' + year.slice(2, 4)
                    break;
                case '99/9999':
                    if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0, 2)) >= 13
                    nowVal = month + '/' + year
                    break;
                case '99/99/9999':
                    if (!value.includes('_')) dateIsInvalid = parseInt(value.substr(0, 2)) > monthLength(parseInt(value.substr(3, 2)), isBissextile(parseInt(value.substr(6, 4)))) || parseInt(value.substr(3, 2)) >= 13
                    nowVal = day + '/' + month + '/' + year
                    break;
                case '99':
                    nowVal = day + '/' + month + '/' + year
                    dateMask = ''
                    break;
                default:
                    break;
            }
            template = (
                <div className={fieldCtn}>
                    {
                        dateMask !== '' && props.id !== '1003' ?
                            <InputMask
                                disabled={disabled || !isEditable}
                                maskChar="_"
                                mask={dateMask}
                                value={value}
                                onChange={(event) => {
                                    setAccept(false);
                                    handleChangeTableValue(event.target.value)
                                }}
                                onKeyDown={handleOnBlur}
                            >
                                {(inputProps) =>
                                    <TextField
                                        {...inputProps}
                                        autoFocus={props.edit === "cell"}
                                        inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                                        placeholder={dateMask === '' ? '' : props.col.mask}
                                        name={props.col.format + '-' + props.cindex}
                                        type="text"
                                        fullWidth={true}
                                        InputProps={{
                                            classes: {
                                                input: classes.fontTablemulti,
                                                root: classes.tablemultiInputCtn,
                                            },
                                            endAdornment:
                                                <React.Fragment>
                                                    {value !== undefined && value.length > 0 && dateIsInvalid && !accept && !disabled &&
                                                        <PlugTooltip title={t('tablemulti.validate')} placement="right">
                                                            <span className={classes.fontError} onClick={() => setAccept(true)}>
                                                                {t('tablemulti.invalid-value')}
                                                                <span id="icon-add-playlist" className={`${classes.tablemultiBtn} material-icons`}>
                                                                    check
                                                                </span>
                                                            </span>
                                                        </PlugTooltip>
                                                    }
                                                    {!disabled &&
                                                        <PlugTooltip title={(value !== "" && value !== null && value !== undefined) ? t('actions.delete') : t('tablemulti.current-date')} placement="top" className={classes.endButton} >
                                                            {value !== "" && value !== null && value !== undefined ?
                                                                <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue("") }}>highlight_off</span>
                                                                :
                                                                <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue(nowVal) }}>check_circle_outline</span>}
                                                        </PlugTooltip>}
                                                </React.Fragment>,
                                        }}
                                    />
                                }
                            </InputMask>
                            :
                            <TextField
                                autoFocus={props.edit === "cell"}
                                inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                                placeholder={dateMask === '' ? '' : props.col.mask}
                                name={props.col.format + '-' + props.cindex}
                                type="text"
                                disabled={props.id === '1003'}
                                fullWidth={true}
                                InputProps={{
                                    classes: {
                                        input: classes.fontTablemulti,
                                        root: props.id === '1003' ? classes.renderSingleFormulaContainer : classes.tablemultiInputCtn,
                                    },
                                    endAdornment:
                                        !disabled && props.id !== '1003' && isEditable &&
                                        <PlugTooltip title={value !== "" && value !== null && value !== undefined ? t('actions.delete') : t('tablemulti.current-date')} placement="top" className={classes.endButton} >
                                            {value !== "" && value !== null && value !== undefined ?
                                                // delete
                                                <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue("") }}>highlight_off</span>
                                                :
                                                // autocomplete
                                                <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue(nowVal) }}>check_circle_outline</span>}
                                        </PlugTooltip>,
                                }}
                                onChange={(event) => { handleChangeTableValue(event.target.value) }}
                                onKeyDown={handleOnBlur}
                                value={value}
                            />
                    }
                </div>
            )
            break;
        }
        case 'duration': {
            let durMask = '99:99:99'
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                durMask = props.col.regex
            }
            template = (
                <div className={fieldCtn}>
                    <DurationInput
                        {...props}
                        accept={accept}
                        duration={duration || !isEditable}
                        handleChangeTableValue={handleChangeTableValue}
                        mask={durMask}
                        setAccept={setAccept}
                        setDuration={setDuration}
                        value={value}
                    />
                </div>
            );
            break;
        }
        case 'extdata': {
            const titleFormat = `( ${ref} )  ( ${query} ) [ ${dbcolumn} ] [ ${dbcolumns} ] [ ${dest} ]`
            template = (
                <div className={fieldCtn}>
                    <Input
                        autoFocus={props.edit === "cell"}
                        className={`${classes.fontTablemulti} ${classes.tablemultiInputCtn}`}
                        classes={{ root: classes.tablemultiInputCtn }}
                        disabled={disabled || !isEditable}
                        fullWidth={true}
                        inputProps={{ maxLength: props.col.mask, width: '100%' }}
                        multiline={true}
                        name={props.col.format + '-' + props.cindex}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        onKeyDown={handleOnBlur}
                        placeholder={t('common:global.search')}
                        startAdornment={
                            !disabled && authorizeDBSearch &&
                            <InputAdornment position="end" onClick={(event) => handleOpenSearchModel()} style={{ cursor: 'pointer', right: 0, position: 'absolute', }}>
                                <Search />
                            </InputAdornment>}
                        type="text"
                        value={value}
                    />
                    {
                        open &&
                        <ModalListExtDataComponent
                            titlePerso={props.title} titleFormat={titleFormat} format="extdata" open={open}
                            close={(event) => setOpen(false)} options={options} owner={owner} ownerId={ownerId} value={value}
                            fields={props.fields}
                            handleSelectData={handleSelectData}
                            external={props.external}

                        />
                    }
                </div>
            )
            break;
        }
        case 'formula':
            template = (
                <div className={fieldCtn}>
                    <Input
                        classes={{ root: classes.renderSingleFormulaContainer }}
                        fullWidth={true}
                        placeholder={props?.col?.configuration?.formula || 'formula'}
                        multiline={true}
                        className={classes.fontTablemulti}
                        inputProps={{ maxLength: props.col.mask, width: '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        disabled={true}
                        startAdornment={loading &&
                            <Fade
                                in={loading}
                                style={{
                                    transitionDelay: loading ? '800ms' : '0ms',
                                }}
                                unmountOnExit
                            >
                                <CircularProgress size={10} />
                            </Fade>
                        }
                        endAdornment={
                            !disabled &&
                            <InputAdornment position="end" onClick={(event) => handleClickCalculateValue()} style={{ cursor: 'pointer' }}>
                                <span className="material-icons md-medium green">calculate</span>
                            </InputAdornment>
                        }
                    />
                </div>
            )
            break;
        case 'mail':
            template = (
                <div className={fieldCtn}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        fullWidth={true}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        onKeyDown={handleOnBlur}
                        disabled={disabled || !isEditable}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn,
                            },
                        }}
                    />
                </div>
            );
            break;
        case 'numeric': {
            let separator = false
            let hasDecimal = false
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                switch (props.col.regex) {
                    case "99,999999":
                        hasDecimal = true
                        break;
                    case "9.999":
                        separator = true;
                        // errorMsg = 'Integer only'
                        break;
                    case "9.999,99999":
                        separator = true;
                        hasDecimal = true;
                        break;
                    default:
                        break;
                }
            }

            //fixed pasting values
            // https://github.com/s-yadav/react-number-format/issues/349
            template = (
                <div className={`${fieldCtn} ${classes.tablemultiInputCtn}`}>
                    <NumberFormat
                        {...props}
                        // allowedDecimalSeparators={[',', '.']}
                        className={classes.stylizedValue}
                        customInput={RestyledNumericInput}
                        decimalScale={hasDecimal ? 99 : 0}
                        decimalSeparator={"."}
                        disabled={disabled || !isEditable}
                        format={null}
                        onCopy={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(value)
                        }}
                        onKeyDown={handleOnBlur}
                        onValueChange={(v, infos) => {
                            // formattedValue: '$23,234,235.56', //value after applying formatting
                            // value: '23234235.56', //non formatted value as numeric string 23234235.56, if you are setting this value to state make sure to pass isNumericString prop to true
                            // floatValue: 23234235.56 //floating point representation. For big numbers it can have exponential syntax
                            setValue(v.floatValue);
                            if (infos.event !== null) {
                                handleChangeTableValue(v.floatValue);
                            } else if (infos.event === null && v.value !== value.toString()) {
                                handleChangeTableValue(v.floatValue);
                            }
                        }}
                        thousandSeparator={separator ? ' ' : false}
                        value={value}
                    />
                </div>
            );
            break;
        }
        case 'numeric-ref':
            template = (
                <div className={fieldCtn}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        disabled={disabled || !isEditable}
                        inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        onChange={(event) => {
                            setValue(event.target.value);
                            handleChangeTableValue(event.target.value);
                        }}
                        onKeyDown={handleOnBlur}
                        type="number"
                        value={value}
                    />
                </div>
            );
            break;
        case 's33data':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        onChange={(event) => {
                            setValue(event.target.value);
                            handleChangeTableValue(event.target.value);
                        }}
                        onKeyDown={handleOnBlur}
                        type="number"
                        value={value}
                    />
                    <span id="icon-create" className="material-icons md-small green" onClick={() => handleGetValueFromTable(props.col, props.fields, value)}>check</span>
                    {
                        value !== null && value !== undefined && (value.length > 0 && props.col.id !== 1000) &&
                        <span id="icon-create" className="material-icons md-small red" onClick={() => handleChangeTableValue("")}>delete</span>
                    }
                </div>
            )
            break;
        case 'presel': {
            let multiselect = props?.col?.mask?.multiselect || false
            let editable = props?.col?.mask?.editable || false

            if (multiselect === false && editable === false) {
                template = (
                    <div className={widerCtn}>
                        <RenderItemSingleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={props.col.presel} disabled={disabled || !isEditable} value={value} />
                    </div>
                );
            }
            else if (multiselect === false && editable === true) {
                let presel = props.col.presel
                if (!presel.includes(value)) {
                    presel.push(value)
                }
                template = (
                    <div className={widerCtn}>
                        <RenderItemSingleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={presel} disabled={disabled || !isEditable} value={value} />
                    </div>
                );
            }
            else if (multiselect === true && editable === false) {
                template = (
                    <div className={widerCtn}>
                        <RenderItemMultipleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={props.col.presel} disabled={disabled || !isEditable} value={value} />
                    </div>
                );
            }
            else if (multiselect === true && editable === true) {
                let tmpValue = Array.isArray(value) ? value : [];
                let presel = props.col.presel;
                tmpValue.forEach((item, index) => {
                    if (!presel.includes(item)) {
                        presel.push(item);
                    }
                })
                template = (
                    <div className={widerCtn}>
                        <RenderItemMultipleSelectComponent {...props} multiselect={multiselect} editable={editable} presel={presel} disabled={disabled || !isEditable} value={value} />
                    </div>
                );
            }
            break;
        }
        case 'radio': {
            let masks = props.col.mask.split('/')
            template = (
                <div className={fieldCtn}>
                    {
                        props.col.mask === "Checkbox"
                            ? (
                                <div
                                    onClick={() => handleChangeTableValue(value === 'Y' ? 'N' : 'Y')}
                                    style={{ justifyContent: 'center', flex: 1 }}
                                >
                                    <ColorCheckbox
                                        checked={value === 'Y'}
                                        color={value === '' ? colors.greyLight : colors.main}
                                        size={30}
                                    />
                                </div>
                            )
                            : (
                                <div style={{ float: 'left' }} className={classes.tablemultiInputCtn}>
                                    <RadioGroup className={classes.tablemultiRadioCtn} aria-label="gender" name="gender1" value={value} onChange={event => handleChangeTableValue(event.target.value)}>
                                        {
                                            masks.map((mask, index) => {
                                                return (
                                                    <FormControlLabel size='small'
                                                        value={mask}
                                                        control={
                                                            <Radio
                                                                classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                                                                disabled={disabled || !isEditable}
                                                                size='small'
                                                            />
                                                        }
                                                        label={<span className={classes.radioLabel}>{mask}</span>}
                                                        key={index}
                                                    />
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </div>
                            )
                    }
                </div>
            );
            break;
        }
        case 'select': {
            let presels = props.col.presel;
            template = (
                <div className={widerCtn}>
                    <Select
                        classes={{
                            icon: classes.selectIcon,
                            // nativeInput: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                            root: `${classes.fontTablemultiWidth} ${classes.selectField}`,
                            select: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                        }}
                        disabled={props.disabled}
                        disableUnderline
                        displayEmpty
                        fullWidth={true}
                        id="demo-simple-select"
                        labelId="demo-simple-select-label"
                        onChange={(event => handleChangeTableValue(event.target.value))}
                        onClick={() => setOpenSelect(!openSelect)}
                        open={openSelect}
                        renderValue={(value) => value !== '' && (<span className={classes.selectedItem}>{value}</span>)}
                        value={value}
                    >
                        {presels.map((row, index) => (
                            <MenuItem key={index} value={row} dense>{row}</MenuItem>
                        ))}
                    </Select>
                </div>
            );
            break;
        }
        case 'tel': {
            let telMasks = '+99999999999999'
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                telMasks = props.col.regex
            }
            template = (
                <div className={fieldCtn}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        disabled={disabled || !isEditable}
                        inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        onKeyDown={handleOnBlur}
                        type="tel"
                        value={value}
                    />
                </div>
            );
            break;
        }
        case 'text':
            template = (
                <div className={fieldCtn}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        disabled={disabled || !isEditable || props.id === '1000'}
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: props.col.mask, width: '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        onKeyDown={handleOnBlur}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: props.id === '1000' ? classes.renderSingleFormulaContainer : classes.tablemultiInputCtn
                            },
                            endAdornment: value !== null && value !== undefined && value.length > parseInt(props.col.mask) - 10 ?
                                <React.Fragment>
                                    <span className={classes.textLength}>{value.length}/{props.col.mask}</span>
                                </React.Fragment>
                                : null
                        }}
                    />
                </div>
            )
            break;
        case 'time': {
            let timeMask = '99:99:99'
            let hour = presentTime.getHours() > 9 ? presentTime.getHours() : '0' + presentTime.getHours()
            let minutes = presentTime.getMinutes() > 9 ? presentTime.getMinutes() : '0' + presentTime.getMinutes()
            let seconds = presentTime.getSeconds() > 9 ? presentTime.getSeconds() : '0' + presentTime.getSeconds()

            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                timeMask = props.col.regex
            }
            let timeIsInvalid = false
            if (value !== null && value !== undefined) switch (timeMask) {
                case '99:99:99':
                    if (!value.includes('_')) timeIsInvalid = parseInt(value.substr(0, 2)) >= 24 || parseInt(value.substr(3, 2)) >= 60 || parseInt(value.substr(6, 2)) >= 60
                    nowVal = hour + ':' + minutes + ':' + seconds
                    break;
                case '99:99':
                    if (!value.includes('_')) {
                        if (props.col.mask.includes('H')) { timeIsInvalid = parseInt(value.substr(0, 2)) >= 24 || parseInt(value.substr(3, 2)) >= 60 }
                        else timeIsInvalid = parseInt(value.substr(0, 2)) >= 60 || parseInt(value.substr(3, 2)) >= 60
                    }
                    if (props.col.mask.includes('H')) nowVal = hour + ':' + minutes;
                    else nowVal = minutes + ':' + seconds
                    break;
                case '99 H 99':
                    if (!value.includes('_')) timeIsInvalid = parseInt(value.substr(0, 2)) >= 24 || parseInt(value.substr(5, 2)) >= 60
                    nowVal = hour + ' H ' + minutes
                    break;
                case '99':
                    if (!value.includes('_')) timeIsInvalid = false
                    timeMask = ''
                    nowVal = hour + ':' + minutes + ':' + seconds
                    break;
                default:
                    break;
            }
            template = (
                <div className={fieldCtn}>
                    {timeMask !== '' ?
                        <InputMask
                            disabled={disabled || !isEditable}
                            maskChar="_"
                            mask={timeMask}
                            value={value}
                            onChange={(event) => {
                                setAccept(false);
                                handleChangeTableValue(event.target.value);
                            }}
                            onKeyDown={handleOnBlur}
                        >
                            {(inputProps) =>
                                <TextField
                                    {...inputProps}
                                    autoFocus={props.edit === "cell"}
                                    inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                                    placeholder={timeMask === '' ? '' : props.col.mask}
                                    name={props.col.format + '-' + props.cindex}
                                    type="text"
                                    fullWidth={true}
                                    InputProps={{
                                        classes: {
                                            input: classes.fontTablemulti,
                                            root: classes.tablemultiInputCtn
                                        },
                                        endAdornment:
                                            <React.Fragment>
                                                {value.length > 0 && timeIsInvalid && !accept && !disabled ?
                                                    <PlugTooltip title={t('tablemulti.validate')} placement="right">
                                                        <span className={classes.fontError} onClick={() => setAccept(true)}>
                                                            {t('tablemulti.invalid-value')}
                                                            <span id="icon-add-playlist" className={`${classes.tablemultiBtn} material-icons`}>
                                                                check
                                                            </span>
                                                        </span>
                                                    </PlugTooltip>
                                                    : null}
                                                {!disabled &&
                                                    <PlugTooltip title={(value !== "" && value !== null && value !== undefined) ? t('actions.delete') : t('tablemulti.current-time')} placement="top" className={classes.endButton} >
                                                        {value !== "" && value !== null && value !== undefined ?
                                                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue("") }}>highlight_off</span>
                                                            :
                                                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue(nowVal) }}>check_circle_outline</span>}
                                                    </PlugTooltip>}
                                            </React.Fragment>
                                    }}
                                />
                            }
                        </InputMask>
                        :
                        <TextField
                            autoFocus={props.edit === "cell"}
                            disabled={disabled || !isEditable}
                            inputProps={{ style: { textAlign: 'left', paddingLeft: 5 } }}
                            placeholder={timeMask === '' ? '' : props.col.mask}
                            name={props.col.format + '-' + props.cindex}
                            type="text"
                            fullWidth={true}
                            InputProps={{
                                classes: {
                                    input: classes.fontTablemulti,
                                    root: classes.tablemultiInputCtn
                                },
                                endAdornment: !disabled &&
                                    <PlugTooltip title={(value !== "" && value !== null && value !== undefined) ? t('actions.delete') : t('tablemulti.current-time')} placement="top" className={classes.endButton} >
                                        {value !== "" && value !== null && value !== undefined ?
                                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue("") }}>highlight_off</span>
                                            :
                                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue(nowVal) }}>check_circle_outline</span>}
                                    </PlugTooltip>
                            }}
                            onChange={(event) => { handleChangeTableValue(event.target.value); }}
                            onKeyDown={handleOnBlur}
                            value={value}
                        />
                    }
                </div>
            );
            break;
        }
        case 'url':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        disabled={disabled || !isEditable}
                        inputProps={{ style: { textAlign: 'center', paddingLeft: 5 } }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.fontTablemulti,
                                root: classes.tablemultiInputCtn
                            },
                        }}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        type="url"
                        value={value}
                    />
                    {
                        typeof value !== 'undefined' && value !== '' &&
                        <span onClick={() => openInNewTab(value.toString())}
                            data-skip="" className={classes.urlButton}
                            rel="noreferrer noopener"
                            style={{ marginLeft: 10 }}
                        >
                            <span className="material-icons md-small white">link</span>
                        </span>
                    }
                </div>
            );
            break;
        default:
            return (
                <div className={classes.editorCtn}>
                    <span className={classes.columnHeader}>{`<!> ERROR <!>`}</span>
                </div>
            )
    }
    return (
        <div className={editorCtn}>
            {
                props.edit === "row" &&
                <div className={titleCtn}>
                    <span className={classes.fontFieldNumber}> {columns.indexOf(column) + 1} </span>
                    <span className={classes.fontFieldLabel}> {handleSpecialCharsInLabel(column.label, classes)} </span>
                </div>
            }
            {template}
        </div>
    )
}

const RenderEditRowComponent = (props) => {
    // devLog('RenderEditRow got props', props)
    const classes = useStyles()
    const row = props.row
    const { t } = useTranslation('common')

    return (
        <div className={classes.editFieldsCtn}>
            {
                Object.keys(row)
                    .sort((a, b) => {
                        if (a === '1000') { return 1 }
                        else if (b === '1000') { return -1 }
                        else return a - b
                    })
                    .map((id, cindex) => {
                        let value = row[id]
                        const column = getCellColumn(id, props.columns)
                        if (!isDefined(column) || (props.mode.includes('external-create') && column.encodable === false) || (props.mode.includes('external-view') && column.visible === false)) { return null; }
                        return (
                            <RenderEditCellComponent
                                annexes={props.annexes}
                                attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                attemptSendMailSimple={props.attemptSendMailSimple}
                                col={column}
                                columns={props.columns}
                                control={props.control}
                                controlCreate={props.controlCreate}
                                controlUpdate={props.controlUpdate}
                                disabled={props.disabled}
                                edit="row"
                                field={props.field}
                                fields={props.fields}
                                handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, column, props.rindex)}
                                handleEndEdit={props.handleEndEdit}
                                id={id}
                                isFromPwCode={props.isFromPwCode}
                                key={'edit-cell-' + id}
                                mode={props.mode}
                                model={props.model}
                                owner={props.owner}
                                ownerId={props.ownerId}
                                plugnote={props.plugnote}
                                renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                rindex={props.rindex}
                                row={props.row}
                                share={props.share}
                                title={props.title}
                                user={props.user}
                                value={value}
                            />)
                    })}
            <PlugTooltip title={t('global.validate')} placement="right-start">
                <span className={`material-icons ${classes.validateIcon} ${classes.absoluteIcon}`} onClick={props.handleEndEdit}>check</span>
            </PlugTooltip>

        </div>
    )
}

const RenderMenuPopup = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('common')
    const [anchor, setAnchor] = useState(null)
    const row = props.row
    const rowId = props.rowId
    const rows = props.rows
    const rowToDelete = props.rowToDelete

    const handleCancelDelete = () => {
        props.handleClose()
        props.setRowToDelete(null)
    }

    const handleOpen = (e) => {
        setAnchor(e.currentTarget)
        props.handleOpen()
    }

    const handleDeleteRow = () => {
        props.handleClose()
        props.handleDeleteSingleRow()
    }

    const handleToggleToDelete = () => {
        props.handleClose()
        props.handleToggleToDelete(rowId)
    }

    const handleDuplicate = (e) => {
        props.handleClose()
        if (!props.isLimitReached()) { props.handleClickDuplicate(row)
        } else { props.openLastRow() }
    }

    return (
        <span className={classes.verticalDotsCtn} >
            <span className="material-icons md-medium"
                onClick={handleOpen}
                style={{ maxWidth: 28, width: 28 }}
            >
                more_vertic
            </span>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                anchorEl={anchor}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                marginThreshold={0}
                open={props.open}
                onClose={props.handleClose}
            >
                {
                    // DELETE ROW CONFIRMATION
                    rowToDelete === rowId ?
                        <div className={classes.deleteConfirmationCtn} >
                            <span >{t('tablemulti.delete-row')}?</span>
                            <div className={classes.confirmBtnCtn}>
                                <span className={classes.confirmBtn} onClick={handleCancelDelete}>
                                    <span className={`${classes.confirmIcon} material-icons`} style={{ color: colors.plugformBlue }}>cancel</span>
                                </span>
                                <span className={classes.confirmBtn} onClick={handleDeleteRow}>
                                    <span className={`${classes.confirmIcon} material-icons`} style={{ color: colors.bloodOrange }}>delete</span>
                                </span>
                            </div>
                        </div>
                        :
                        <div className={classes.popoverCtn}>
                            {
                                !row.locked &&
                                <PlugTooltip title={t('tablemulti.delete-row')} placement="top-end">
                                    <div className={classes.popoverItemCtn} onClick={(e) => props.handleClickDelete(e, rowId)}>
                                    <MdDelete
                                        style={{ width: '2rem', height: '2rem', fill: colors.main, margin: '0 4px 0 -4px' }}
                                        className={classes.duplicateBtn}
                                    />
                                        <span style={{ color: 'black' }}>{t('tablemulti.delete-row')}</span>
                                    </div>
                                </PlugTooltip>
                            }
                            {
                                // DELETE ROWS MENU BTN
                                !row.locked && rows.length > 1 &&
                                <div className={classes.popoverItemCtn}
                                    onClick={handleToggleToDelete}
                                >
                                    <MdDeleteSweep
                                        style={{ width: '2rem', height: '2rem', fill: colors.main }}
                                        className={classes.duplicateBtn}
                                    />
                                    <span style={{ color: 'black' }}>{t('tablemulti.delete-multiple')}</span>
                                </div>
                            }
                            {
                                // DUPLICATE ROW MENU BTN
                                props.isDefaultRowsQtyReached() &&
                                <div
                                    className={classes.popoverItemCtn}
                                    onClick={handleDuplicate}
                                >
                                    <RiFileCopyLine
                                        style={{ width: '2rem', height: '2rem', fill: colors.main }}
                                        className={classes.duplicateBtn}
                                    />
                                    <span style={{ color: 'black' }}>{t('tablemulti.duplicate-row')}</span>
                                </div>
                            }
                        </div>
                }
            </Popover>
        </span>
    )
}
export const ModalTablemultiTableViewComponent = (props) => {
    // devLog('ModalTM got props', props)
    const classes = useStyles()
    const [modalStyle] = useState(getModalStyle)
    const { t } = useTranslation('common')

    //Props
    const value = props.search
    const authorizeDBSearch = props.authorizeDBSearch
    const criteria = props.criteria
    const setup = props.field.options.setup

    //Refs
    const searchInput = useRef()
    const editedRow = useRef()

    //variables & constantes
    let timer
    const [search, setSearch] = useState('')
    const [openSearch, setOpenSearch] = useState(false)
    const [rows, setRows] = useState(props.rows || [])
    const [keyList, setKeyList] = useState([])
    const [completed, setCompleted] = useState(false)
    const [openRow, setOpenRow] = useState(props.openRow !== null ? { row: rows[props.openRow] } : null)
    const [rowsToDelete, setRowsToDelete] = useState([])
    const [rowToDelete, setRowToDelete] = useState(null)

    const [openPop, setOpenPop] = useState(false)

    const showRowNumbers = setup?.layout?.showRowNb !== false && (
        (props.mode !== 'pdf' && !props.mode.includes('view') && !props.mode.includes('create')) ||
        (props.mode === 'pdf' && setup?.layout?.showRowNb?.showOnPdf !== false) ||
        (props.mode.includes('create') && setup?.layout?.showRowNb?.showOnPf !== false) ||
        (props.mode.includes('view') && setup?.layout?.showRowNb?.showOnPn !== false))
    const showRowNumbersInModal = showRowNumbers || !props.mode.includes('view')
    const { height, width } = useWindowDimensions()
    const isLandscape = isMobile && height < width
    const [tableWidth] = useState(calculateTableWidth)

    useEffect(() => {
        setRows(props.rows)
        let keys = []
        props.colonnes.forEach(col => {
            if (isDefined(col.id) && !keys.includes(col.id.toString())) keys.push(col.id.toString())
        })
        setKeyList(keys) 
    }, [props.rows])

    useEffect(() => {
        if (rows.length !== props.field.value.length && search === "") setRows(props.rows)
    }, [search])

    useEffect(() => {
        if (props.openRow !== null && !isMobile) {
            setOpenRow({ ...props.openRow })
            setTimeout(() => {
                let scrollId = props.openRow > 0 ? (props.openRow - 1) : props.openRow
                document.getElementById("row-index-" + scrollId)?.scrollIntoView()
            }, 400)
        }
    }, [props.openRow])

    useEffect(() => {
        if (isDefined(props.newRow)) { setOpenRow({ id: undefined, row: props.newRow, rindex: rows.indexOf(props.newRow) }) }
    }, [props.newRow])

    function calculateTableWidth() {
        if (props.colonnes.some(col => isNaN(col?.colWidth))) {
            return '100%'
        }
        let width = 0
        props.colonnes.forEach(col => {
            if (!isHiddenColumn(col, props.mode)) {
                width += (col.colWidth > 40 ? col.colWidth : 40)
            }
        })
        return Math.ceil(width)
    }

    function handleCloseModal() {
        props.handleClose()
    }

    function completeRows() {
        let _rows = [...props.rows]
        if (_rows.length > 0 && !completed) {
            _rows.forEach((row) => {
                if (row.recap !== true) keyList.forEach((key) => {
                    if (!Object.prototype.hasOwnProperty.call(row, key) && isDefined(key)) row[key] = ''
                })
            })
            setRows(_rows)
            setCompleted(true)
        }
    }

    function handleEditCellValue(row, id) {
        let format = getCellColumn(id, props.colonnes)?.format
        // devLog('handleEditCellValue ||| row', row, props.mode, props.isFromPwCode, format)

        let isEditable = isDefined(id) ? isEditableDefaultValue(props.field, id, rows.indexOf(row), props.mode, props.isFromPwCode) : true
        if ((immutableFormats.includes(format) || id === '1000' || !isEditable) && !props.disabled) {
            toast.error(
                <ToastMessage
                    message={isEditable === false ? 'tablemulti.default-not-editable' : 'tablemulti.edit-impossible'}
                />, { autoClose: 3000 })
        } else if (!props.disabled) setOpenRow({ id: id, row: row, rindex: rows.indexOf(row) })
    }

    function handleEndEdit() {
        setOpenRow(null)
    }

    function handleAddRow(e) {
        e.preventDefault();
        e.stopPropagation();
        props.setNewRow(null)
        setOpenRow(null)
        props.handleAddTableMulti(e)
        if (props.newRow === null) openLastRow()
    }

    function openLastRow() {
        let _rows = rows.filter(row => row.recap !== true)
        // let finalRow = _rows[_rows.length - 1]
        // setOpenRow({row: finalRow})
        // if(editedRow.current !== null && editedRow.current !== undefined && openRow !== null) setTimeout(() => {
        //     editedRow.current.scrollIntoView({ block: 'center' })
        //     setTimeout(() => editedRow.current.scrollIntoView({ block: 'center' }), 200)
        // }, 200)
        let lastRow = document.getElementById("row-index-" + _rows.length - 1)
        lastRow?.scrollIntoView()
    }

    function handleClickRowNb(row) {
        if (!props.open) { props.handleClickPreview(rows.indexOf(row)) }
        if (openRow !== null && openRow?.row === row) { handleEndEdit() }
        else handleEditCellValue(row)
    }

    function handleCellClick(e, row, key) {
        e.preventDefault()
        e.stopPropagation()
        props.setNewRow(null)
        if (isMobile) { handleClickRowNb(row) }
        else handleEditCellValue(row, key)
    }

    const isDefaultRowsQtyReached = () => {
        const defaultRows = props.field?.options?.setup?.defaultRows || [];
        let isRowsLengthBigger = rows.length >= defaultRows.length;
        return isRowsLengthBigger
    }

    const handleClickDuplicate = row => {
        handleDuplicateRow(row)
    }

    const handleDuplicateRow = row => {
        let _row = { ...row }
        if (row.hasOwnProperty('locked')) {
            _row.locked = false
        }
        props.handleDuplicateRow(_row)
    }

    const handleDeleteSingleRow = () => {
        setOpenSearch(false)
        props.handleDeleteRow(rowToDelete - 1)
        setRowToDelete(null)
    }

    function handleClickDelete(e, id) {
        if (!isDefined(timer)) (timer = setTimeout(() => { handleDeleteRow(id) }, 500))
        if (e.detail === 2) {
            clearTimeout(timer)
            handleToggleToDelete(id)
        }
    }

    function handleDeleteSelected() {
        confirmAlert({
            title: t('tablemulti.delete-rows'),
            message: t('tablemulti.confirm-delete'),
            buttons: [
                {
                    label: t('common:global.delete'),
                    onClick: () => {
                        setOpenSearch(false)
                        props.handleDeleteRow(rowsToDelete)
                        setRowsToDelete([])
                    },
                },
                {
                    label: t('common:global.cancel')
                },
            ]
        })
    }

    function handleCancelDelete() {
        setRowsToDelete([])
    }

    function handleToggleToDelete(id) {
        if (rowsToDelete.length > 0) {
            if (rowsToDelete.includes(id)) {
                if (rowsToDelete.length === 1) setRowsToDelete([]);
                else setRowsToDelete(rowsToDelete.filter(entry => entry !== id))
            } else setRowsToDelete([...rowsToDelete, id]);
        } else setRowsToDelete([id]);
    }

    function handleDeleteRow(id) {
        confirmAlert({
            title: t('tablemulti.delete-row'),
            message: t('tablemulti.confirm-delete'),
            buttons: [
                {
                    label: t('common:global.delete'),
                    onClick: () => {
                        setOpenSearch(false)
                        props.handleDeleteRow(id - 1)
                    },
                },
                {
                    label: t('common:global.cancel')
                },
            ]
        })
    }

    completeRows()

    let clickTimer;
    const handleClick = (e, rindex, key) => {
        if (!props.disabled && rows.length === 0) {
            props.handleAddTableMulti(e)
        }
        if (!isDefined(clickTimer)) {
            clickTimer = setTimeout(() => {
                props.handleClickPreview();
            }, 500);
        } else {
            if (isDefined(key)) props.handleClickPreview(rindex, key);
            else props.handleClickPreview(rindex);
            clearTimeout(clickTimer);
        }
    }

    const isLimitReached = () => {
        return isRowLimitReached(props.field.options?.setup?.rowLimit, rows)
    }

    const isEditableDirectly = (col) => {
        // devLog('Directly ? col', col)
        return (
            !props.disabled && (
                // col.id === 1001 ||
                col.mask === 'Checkbox'
            ))
    }

    let _defaultFontsize = isDefined(props.field.options?.setup?.layout?.defaultFontSize) ? props.field.options?.setup?.layout?.defaultFontSize : 11
    let _defaultLabelSize = isDefined(props.field.options?.setup?.layout?.defaultLabelSize) ? props.field.options?.setup?.layout?.defaultLabelSize : 11
    
    const TablemultiHorizontalView = (
        <TablemultiHorizontalViewComponent
            annexes={props.annexes}
            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
            attemptSendMailSimple={props.attemptSendMailSimple}
            columns={props.colonnes}
            control={props.control}
            controlCreate={props.controlCreate}
            controlUpdate={props.controlUpdate}
            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
            dataHash={props?.dataHash}
            _defaultFontsize={_defaultFontsize}
            _defaultLabelSize={_defaultLabelSize}
            disabled={props.disabled}
            editedCell={openRow}
            field={props.field}
            fields={props.fields}
            getCellColumn={getCellColumn}
            getColumnLabel={getColumnLabel}
            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
            handleChangeChildrenValue={props.handleChangeChildrenValue}
            handleClickCell={handleCellClick}
            handleClickPreview={handleClick}
            handleDeleteRow={props.handleDeleteRow}
            handleDuplicateRow={handleDuplicateRow}
            handleOpenModalView={props.handleClickPreview}
            isFromPwCode={props.isFromPwCode}
            handleValidate={handleEndEdit}
            isModelPreview={props.isModelPreview}
            keyList={keyList}
            mode={props.mode}
            model={props.model}
            openModal={props.open}
            owner={props.owner}
            ownerId={props.ownerId}
            plugnote={props.plugnote}
            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
            renderCellValue={prop => <RenderCellValue {...prop} />}
            renderEditCellComponent={prop => <RenderEditCellComponent {...prop} />}
            rows={rows}
            share={props.share}
            title={props.title}
            user={props.user}
            showRowNumbers={showRowNumbers}
            widths={props.widths}
        />
    )

    if (props.isPreview === true) {
        return (
            <div className={`${classes.tablePreviewCtn}`}>
                <Table className={classes.table} >
                    <TableHead className={classes.tableHeader} sx={{ display: "table-header-group" }} aria-label="simple table" onClick={handleClick}>
                        <TableRow className={classes.stickyHeader} >
                            {
                                showRowNumbers &&
                                <TableCell align="center" className={`${classes.stickyColumn} ${classes.rowNbColumnHeader}`}>
                                    <div className={classes.columnHeader}>N°</div>
                                </TableCell>
                            }
                            {
                                props.colonnes.map((entry, id) => {
                                    if ((props.mode.includes('external-create') && entry.encodable === false) || (props.mode.includes('external-view') && entry.visible === false)) {
                                        {/* devLog('col not rendered: ', entry) */ }
                                        return null
                                    }
                                    else {
                                        let lastRow = rows[rows.length - 1]
                                        let hasTotal = false
                                        let totals = []
                                        let total = ""
                                        if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
                                            totals = Object.keys(lastRow)
                                            if (totals.includes(entry.id.toString())) {
                                                hasTotal = true
                                                if (lastRow[entry.id] !== null && lastRow[entry.id] !== undefined && lastRow[entry.id] !== "") {
                                                    if (lastRow[entry.id].toString().includes(':')) total = lastRow[entry.id];
                                                    else total = Math.round(lastRow[entry.id] * 1000) / 1000
                                                }
                                            }
                                        }
                                        let colWidth = isDefined(entry.colWidth) && entry.colWidth > 40 ? entry.colWidth : 'auto'
                                        if (!isNaN(colWidth)) {
                                            colWidth = 'calc((100% - ' + (!props.editMode && !props.disabled ? '40px' : '10px') + ') * ' + colWidth + ' / ' + tableWidth + ')'
                                        }
                                        const headerStyle = {
                                            fontSize: isDefined(entry.headerFontSize) ? entry.headerFontSize : _defaultLabelSize,
                                            maxWidth: colWidth,
                                            whiteSpace: "break-spaces",
                                        }

                                        const totalStyle = {
                                            fontSize: isDefined(entry.fontSize) ? entry.fontSize : 11,
                                            verticalAlign: 'top',
                                        }
                                        if (hasTotal) {
                                            switch (entry.rowAlignment) {
                                                case 'left':
                                                    totalStyle.marginRight = 'auto'
                                                    break
                                                case 'right':
                                                    totalStyle.marginLeft = 'auto'
                                                    break
                                                case 'center':
                                                default:
                                                    totalStyle.marginLeft = 'auto'
                                                    totalStyle.marginRight = 'auto'
                                                    break
                                            }
                                        }

                                        return (
                                            <TableCell
                                                align={entry.labelAlignment || 'center'}
                                                key={"table-header-" + id} style={headerStyle} >
                                                {getColumnLabel(entry, _defaultLabelSize, setup?.tableOrientation)}
                                                {hasTotal &&
                                                    <div className={classes.totalCtn}>
                                                        <NumberFormat value={total} thousandSeparator="." decimalSeparator="," displayType="text"
                                                            renderText={
                                                                (value, _) => <span className={classes.totalValues} style={totalStyle}>
                                                                    {value}
                                                                </span>}
                                                        />
                                                    </div>}
                                            </TableCell>)
                                    }
                                }
                                )}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBodyCtn}>
                        {
                            rows.length > 0 &&
                            rows.map((row, rindex) => {
                                if (row.recap !== true) {
                                    return (
                                        <TableRow key={'table-row-' + props.field.label + '-' + rindex}>
                                            {
                                                showRowNumbers &&
                                                <TableCell
                                                    align="center" key={"row-index-" + rindex}
                                                    style={{ width: 25, borderLeft: `1px solid ${colors.unselected}`, backgroundColor: 'white' }}
                                                    className={`${classes.rowNbCell} ${classes.stickyColumn} `}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <span className={classes.rowNb}>{rindex + 1}</span>
                                                </TableCell>
                                            }
                                            {
                                                Object.keys(row)
                                                    .sort((a, b) => {
                                                        if (a === '1000' || a === 1000) return 1; else if (b === '1000' || b === 1000) return -1; else return a - b
                                                    })
                                                    .map((key, cindex) => {
                                                        let col = props.colonnes.find(entry => entry.id.toString() === key.toString())
                                                        if (!isDefined(col) || (props.mode === 'external-create' && col.encodable === false) || (props.mode === 'external-view' && col.visible === false)) {
                                                            {/* devLog('col not rendered: ', col) */ }
                                                            return null
                                                        }
                                                        

                                                        const cellStyle = {
                                                            fontSize: isDefined(col.fontSize) && col.fontSize > -1 ? col.fontSize : _defaultFontsize,
                                                            /* width: col.colWidth > 0 ? col.colWidth : 'auto', */
                                                            whiteSpace: "break-spaces",
                                                        }

                                                        if (isEditableDirectly(col)) {
                                                            return (
                                                                <TableCell
                                                                    align={col.rowAlignment || 'center'}
                                                                    key={"cell-" + key + '-' + cindex}
                                                                    style={{ ...cellStyle, position: 'relative' }}
                                                                >
                                                                    <RenderEditCellComponent
                                                                        createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                        annexes={props.annexes}
                                                                        attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                        attemptSendMailSimple={props.attemptSendMailSimple}
                                                                        col={col}
                                                                        columns={props.colonnes}
                                                                        control={props.control}
                                                                        controlCreate={props.controlCreate}
                                                                        controlUpdate={props.controlUpdate}
                                                                        disabled={props.disabled}
                                                                        edit="cell"
                                                                        field={props.field}
                                                                        fields={props.fields}
                                                                        handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                        handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, col, rindex)}
                                                                        handleValidate={handleEndEdit}
                                                                        id={key}
                                                                        isPreview
                                                                        key={'edit-cell-' + rindex + '-' + key}
                                                                        mode={props.mode}
                                                                        model={props.model}
                                                                        open={true}
                                                                        owner={props.owner}
                                                                        ownerId={props.ownerId}
                                                                        plugnote={props.plugnote}
                                                                        renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                        rindex={rindex}
                                                                        cindex={cindex}
                                                                        row={row}
                                                                        share={props.share}
                                                                        title={props.title}
                                                                        user={props.user}
                                                                        value={row[key]}
                                                                        dataHash={props?.dataHash}
                                                                    />
                                                                </TableCell>
                                                            )
                                                        } else if (row[key] === '' && keyList.includes(key)) {
                                                            return (
                                                                <TableCell
                                                                    align="center"
                                                                    key={props.field.label + "-cell-" + key + '-' + cindex}
                                                                    style={cindex === keyList.length - 1 ? { ...cellStyle, position: 'relative' } : cellStyle}
                                                                    onClick={(e) => { handleClick(e, rindex, key) }}
                                                                >
                                                                    <div className={classes.voidCell}>
                                                                        <span className={classes.voidCell} />
                                                                    </div>
                                                                </TableCell>
                                                            )
                                                        } else return (keyList.includes(key) &&
                                                            <TableCell
                                                                align={col.rowAlignment || 'center'}
                                                                key={"cell-" + key + '-' + cindex}
                                                                style={{ ...cellStyle, position: 'relative' }}
                                                                onClick={(e) => handleClick(e, rindex, key)}
                                                            >
                                                                <RenderCellValue
                                                                    columns={props.colonnes}
                                                                    defaultFontsize={_defaultFontsize}
                                                                    id={key}
                                                                    value={row[key]}
                                                                    row={row}
                                                                    fields={props.fields}
                                                                />
                                                            </TableCell>
                                                        )
                                                    })
                                            }
                                            {
                                                row.recap === true && keyList.map((key, cindex) => {
                                                    let col = props.colonnes.find(entry => entry.id.toString() === key.toString());
                                                    if ((props.mode === 'external-create' && col.encodable === false) || (props.mode === 'external-view' && col.visible === false)) { return null; }
                                                    if (row.hasOwnProperty(key)) return (keyList.includes(key) &&
                                                        <TableCell
                                                            align={col.rowAlignment || 'center'}
                                                            key={"cell-" + key + '-' + cindex}
                                                            style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}
                                                        >
                                                            <RenderCellValue
                                                                columns={props.colonnes}
                                                                defaultFontsize={_defaultFontsize}
                                                                id={key}
                                                                value={row[key]}
                                                                row={row}
                                                                fields={props.fields}
                                                            />
                                                        </TableCell>);
                                                    else return (
                                                        <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: 'none' }}>
                                                            <div className={classes.voidCell} >
                                                                <span className={classes.voidCell} />
                                                            </div>
                                                        </TableCell>
                                                    )
                                                })}
                                        </TableRow>)
                                }
                                else return null
                            })}

                        {
                            rows.length === 0 &&
                            <TableRow >
                                {
                                    showRowNumbers &&
                                    <TableCell
                                        align="center"
                                        key={props.field.label + '-cell-'}
                                        onClick={(e) => { handleClick(e) }}
                                        className={classes.noDataCell}
                                        style={{ width: 25, borderLeft: `1px solid ${colors.unselected}`, backgroundColor: 'white' }}
                                    >
                                        <div className={classes.voidCell}>
                                            <span className={classes.voidCell} />
                                        </div>
                                    </TableCell>
                                }
                                {
                                    props.colonnes.map((entry, cindex) => {
                                        const cellStyle = {
                                            fontSize: isDefined(entry.fontSize) ? entry.fontSize : 11,
                                            width: isDefined(entry.colWidth) ? entry.colWidth : 'auto',
                                            whiteSpace: "break-spaces",
                                        }
                                        if ((!props.mode.includes('external-create') || entry.encodable !== false) && (!props.mode.includes('external-view') || entry.visible !== false)) {
                                            return (
                                                <TableCell
                                                    align="center"
                                                    key={props.field.label + '-cell-' + cindex}
                                                    style={cindex === keyList.length - 1 ? { ...cellStyle, position: 'relative' } : cellStyle}
                                                    onClick={(e) => { handleClick(e) }}
                                                    className={classes.noDataCell}
                                                >
                                                    <div className={classes.voidCell}>
                                                        <span className={classes.voidCell} />
                                                    </div>
                                                </TableCell>)
                                        } else return null
                                    })
                                }
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </div>
        )
    } else if (props.toPrint === true) {
        let colonnes = []
        props.colonnes.forEach(entry => {
            let lastRow = rows[rows.length - 1]
            let hasTotal = false
            let totals = []
            let total = ""
            if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
                totals = Object.keys(lastRow)
                if (totals.includes(entry.id.toString())) {
                    hasTotal = true
                    if (lastRow[entry.id] !== null && lastRow[entry.id] !== undefined && lastRow[entry.id] !== "") {
                        if (lastRow[entry.id].toString().includes(':')) total = lastRow[entry.id];
                        else total = Math.round(lastRow[entry.id] * 1000) / 1000
                    }
                }
            }
            // Set the colWidth Value to send to PDF, preview or Modal if it is undefined or still not set
            if (entry.colWidth === undefined || entry.colWidth === null || entry.colWidth === '') {
                const label = entry.label;

                let maxLength = 0;
                let currentLength = 0;
                //check tilde and #
                for (let i = 0; i < label.length; i++) {
                    if (label[i] === "~") {
                        maxLength = Math.max(maxLength, currentLength);
                        currentLength = 0;
                    } else if (label.includes("#")) {
                        const indexOfHash = label.indexOf("#");
                        currentLength = indexOfHash;
                    } else {
                        currentLength++;
                    }
                }
                maxLength = Math.max(maxLength, currentLength);

                entry.colWidth = maxLength
            }

            // Set the fontSize Value to send to PDF, preview or Modal if it is undefined or still not set
            if (entry.fontSize === undefined || entry.fontSize === null || entry.fontSize === '') {
                entry.fontSize = 8
            }
            let col = { ...entry, value: entry.label }
            if (hasTotal) col['total'] = total
            colonnes.push(col);
        })

        let widths = []
        let labels = props.colonnes.slice(0, props.colonnes.length);

        labels.forEach((col, cindex) => {
            widths.push(col?.colWidth > 30 ? col.colWidth : 60)
        });

        rows
            .filter((row) => !row.hasOwnProperty('recap'))
            .forEach((row) => {
                let rLength = 0
                for (let i = 0; i < props.colonnes.length; i++) {
                    let col = props.colonnes[i];
                    if (row[col.id] !== undefined && row[col.id].length > 9) {
                        let lengthArr = (row[col.id].split()).map((el) => Number(el.length) + 2)
                        rLength = Math.max(...lengthArr)
                        if ((rLength * 9 + 4) > widths[i]) widths[i] = rLength * 10;
                    }
                }
            })

        const sum = widths.reduce((partialSum, a) => partialSum + a, 0);
        let flexWidths = []

        widths.forEach((val, index) => {
            flexWidths.push(parseFloat(val / sum).toPrecision(3))
            if (colonnes[index] !== undefined) {
                let lengthArr = (colonnes[index].value.split(/[\n,;]/)).map((el) => Number(el.length))
                let length = Math.max(...lengthArr) * 7
                if (val <= length) {
                    if (colonnes[index].value.includes("~")) {
                        let parts = colonnes[index].value.split("~");
                        // finds the maximum length of all parts between or after a Tilde
                        let maxLength = 0;
                        let currentLength = 0;
                        for (let i = 0; i < parts.length; i++) {
                            if (parts[i] === "~") {
                                maxLength = Math.max(maxLength, currentLength);
                                currentLength = 0;
                            } else {
                                currentLength++;
                            }
                        }
                        maxLength = Math.max(maxLength, currentLength);
                        //setLabelLength(maxLength);
                        parts = parts.map((part, i) => {
                            if (i !== parts.length - 1) {
                                return part + '\n';
                            }
                            return part;
                        });
                        colonnes[index].value = parts.join('');
                    }
                    if (colonnes[index].value.includes("#")) {
                        // Shows chars before #
                        colonnes[index].value = colonnes[index].value.split("#")[0];
                    }
                }
            }
        })
        return (
            <PDFTablemulti
                alignment={props.field.options.alignment}
                borderColor={colors.borderGrey}
                header={colonnes}
                mode={props.mode}
                rows={rows.filter((row) => !row.hasOwnProperty('recap'))}
                widths={flexWidths}
                fieldId={props.field.id}
                fieldLabel={props.field.title}
                hiddenOpt={props.hiddenOpt}
                setup={props.field.options.setup}
            />
        )
    }
    else return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            className={classes.modal}
            BackdropProps={{ style: { backgroundColor: colors.blackAlpha } }}
        >
            <div style={modalStyle} className={`${classes.popup} ${isLandscape ? classes.popupLandscape : undefined}`}>
                <div className={`${isLandscape ? classes.modalHeaderLandscape : undefined} ${classes.modalHeader}`}>
                    <div className={classes.searchInputCtn}>
                        {
                            props.hasDb === true && !props.disabled && authorizeDBSearch &&
                            <TextField
                                className={classes.textField}
                                disabled={props.disabled}
                                onChange={(event) => props.handleEditValue(event.target.value)}
                                id="standard-full-width"
                                style={{ margin: 0, color: !authorizeDBSearch ? colors.grey : 'inherit' }}
                                type="text"
                                multiline={false}
                                helperText=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                        <React.Fragment>
                                            {
                                                !props.disabled && authorizeDBSearch ?
                                                    <div className={classes.searchBtn} > {criteria}
                                                        <span id="icon-listForm" className={`material-icons md-25`} style={{ color: colors.main }} onClick={(event) => { if (!props.disabled && authorizeDBSearch) props.handleOpenSearchModel() }}>
                                                            search
                                                        </span>
                                                    </div>
                                                    :
                                                    <PlugTooltip placement="top" title={t('global.not-allowed')}>
                                                        <div className={`${classes.searchBtn} ${classes.colorDisable}`}> {criteria}
                                                            <span id="icon-listForm" className={`material-icons md-25 ${classes.colorDisable}`}>
                                                                search
                                                            </span>
                                                            <span id="icon-listForm" className={`material-icons red ${classes.notAllowedIcon}`}>
                                                                close
                                                            </span>
                                                        </div>
                                                    </PlugTooltip>
                                            }
                                        </React.Fragment>
                                    ),
                                }}
                                margin="normal"
                                onKeyDown={(e) => { if (e.key === 'Enter' && !props.disabled && authorizeDBSearch) props.handleOpenSearchModel() }}
                                placeholder={t('ext-table.search-table')}
                                value={value}
                            />
                        }
                        {/* <Input
                            autoComplete="new-password"
                            id="standard-search-field"
                            inputRef={searchInput}
                            placeholder={t('common:global.search')}
                            margin="none"
                            onKeyPress={(e) => {if (e.key === 'Enter') handleSearch()}}
                            disableUnderline={true}
                            startAdornment={
                                <InputAdornment position="start" style={{cursor : 'pointer', color: search === '' ? '#bababa': colors.mainButton}} onClick={handleSearch}>
                                    <Search/>
                                </InputAdornment>}
                            endAdornment={search !== "" &&
                                <InputAdornment
                                    onClick={handleResetSearch}
                                    position="end"
                                    style={{cursor : 'pointer', color: colors.mainButton}}>
                                    <span className={`material-icons md-25 `}>highlight_off</span>
                                </InputAdornment>
                            }
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onChange={handleChangeSearch}
                            value={search}
                        /> */}
                        {
                            !props.disabled && typeof props.field.options.importauto !== "undefined" && props.field.options.importauto === true && value !== undefined && value !== '' && authorizeDBSearch &&
                            <PlugTooltip title="Auto-import" placement="top">
                                <div className={classes.autoImportBtn} onClick={props.handleImportAuto}>
                                    <AutoImport className={classes.autoImportBtnImg} />
                                </div>
                            </PlugTooltip>
                        }
                    </div>
                    <div className={classes.headerTitle}>
                        <span className="titres align-items-center">
                            {props.title}
                        </span>
                        <br />

                        {/* <span className={classes.titleDetails}>{modalTitle} from {props.model !== null && props.model !== undefined ? props.model.label : props.title}</span> */}
                    </div>
                    <div className={classes.closeBtn}>

                        <span className={classes.exportIconCtn}>
                            {
                                props.disabled &&
                                <PlugTooltip title={t('tablemulti.export-csv')} placement='bottom-start'>
                                    <span>
                                        <FontAwesomeIcon icon={faFileCsv} size="lg" style={{ fontSize: 33, color: colors.mainButton }} className={`${classes.tablemultiExportButton} material-icons`} onClick={props.handleExportTableMulti} />
                                        {
                                            props.openDownload &&
                                            <CSVDownload data={props.datasToDownload} separator={";"} />
                                        }
                                    </span>
                                </PlugTooltip>
                            }

                            {
                                props.recap && props.rows.length > 0 && !props.rows[props.rows.length - 1].hasOwnProperty('recap') && !props.disabled &&
                                <PlugTooltip title="Calculate total(s)" placement="bottom">
                                    <span id="icon-add-playlist" className={`${classes.tablemultiExportButton} material-icons `} onClick={(e) => { props.handleAddRecap(e) }} style={{ fontSize: 32, color: colors.mainButton }}>
                                        title
                                    </span>
                                </PlugTooltip>
                            }
                            {
                                !props.disabled && props.field.options.setup?.rowLimit !== 1 &&
                                <PlugTooltip title={!isLimitReached() ? t('tablemulti.add-row') : t('tablemulti.row-limit-warn')} placement="bottom">
                                    <span id="icon-add-playlist"
                                        className={`${classes.tablemultiExportButton} material-icons ${isLimitReached() ? classes.disabledIcon : classes.icon} `}
                                        onClick={(e) => { if (!isLimitReached()) handleAddRow(e) }}
                                    >
                                        add_circle_outline
                                    </span>
                                </PlugTooltip>
                            }
                        </span>
                        <span id="icon-close"
                            className="material-icons md-50 lightgrey"
                            onClick={handleCloseModal}>
                            close
                        </span>
                    </div>
                </div>

                <div className={`${classes.modalBody} ${isLandscape ? classes.modalBodyLandscape : undefined}`}
                    style={isDefined(openRow?.row) && !isLandscape ? { marginBottom: 500 } : undefined}>
                    {
                        rowsToDelete.length > 0 &&
                        <span className={classes.headerDeleteBtnCtn}>
                            <PlugTooltip title={t('global.cancel')} placement="right-start">
                                <span className={`material-icons`}
                                    onClick={handleCancelDelete}
                                    style={{ color: colors.plugformBlue, fontSize: 36 }}
                                >cancel</span>
                            </PlugTooltip>
                            <PlugTooltip title={t('tablemulti.delete-rows')} placement="right-start">
                                <span className={` material-icons`}
                                    onClick={handleDeleteSelected}
                                    style={{ color: colors.redDark, fontSize: 36 }}
                                >
                                    delete
                                </span>
                            </PlugTooltip>
                        </span>
                    }
                    <div
                        className={`${classes.tableCtn} ${classes.scrollBar} ${setup?.tableOrientation === 'horizontal' ? classes.horizontalTable : classes.table}`}
                        style={props.disabled ? { paddingRight: 0 } : undefined}
                    >
                        {
                            setup?.tableOrientation === 'horizontal'
                                ? (
                                    TablemultiHorizontalView
                                )
                                : (

                                    <Table
                                        style={{ overflowX: 'auto', }}
                                    >
                                        <TableHead className={classes.tableHeader} sx={{ display: "table-header-group" }} aria-label="simple table">
                                            <TableRow className={classes.stickyHeader} >
                                                {
                                                    showRowNumbersInModal &&
                                                    <TableCell align="center" className={classes.rowNbColumnHeader} >
                                                        <PlugTooltip title={!props.disabled ? t('tablemulti.infos-edit') : ''} placement="right-start">
                                                            <div className={classes.columnHeader}>N°</div>
                                                        </PlugTooltip>
                                                        {
                                                            !props.disabled && rows.length > 0 &&
                                                            <PlugTooltip title={t('tablemulti.delete-all')} placement="right-start">
                                                                <span onClick={props.handleDeleteAll} className={`material-icons`}>delete</span>
                                                            </PlugTooltip>
                                                        }
                                                    </TableCell>
                                                }
                                                {
                                                    props.colonnes.map((entry, id) => {
                                                        if ((props.mode.includes('external-create') && entry.encodable === false) || (props.mode.includes('external-view') && entry.visible === false)) { return null; }
                                                        else {
                                                            let lastRow = rows[rows.length - 1]
                                                            let hasTotal = false
                                                            let totals = []
                                                            let total = ""
                                                            if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
                                                                totals = Object.keys(lastRow)
                                                                if (totals.includes(entry.id.toString())) {
                                                                    hasTotal = true
                                                                    if (lastRow[entry.id] !== null && lastRow[entry.id] !== undefined && lastRow[entry.id] !== "") {
                                                                        total = lastRow[entry.id]
                                                                        if (!total.toString().includes(':')) total = Math.round(total * 1000) / 1000
                                                                    }
                                                                    else total = "0"
                                                                }
                                                            }
                                                            let cellWidth = entry.colWidth || 'auto'
                                                            const cellStyle = {
                                                                whiteSpace: "break-spaces",
                                                                width: cellWidth,
                                                            }
                                                            const totalStyle = {
                                                                fontSize: isDefined(entry.fontSize) ? entry.fontSize : 11,
                                                                verticalAlign: 'top',
                                                            }
                                                            if (hasTotal) {
                                                                switch (entry.rowAlignment) {
                                                                    case 'left':
                                                                        totalStyle.marginRight = 'auto'
                                                                        break
                                                                    case 'right':
                                                                        totalStyle.marginLeft = 'auto'
                                                                        break
                                                                    case 'center':
                                                                    default:
                                                                        totalStyle.marginLeft = 'auto'
                                                                        totalStyle.marginRight = 'auto'
                                                                        break
                                                                }
                                                            }
                                                            return (
                                                                <TableCell
                                                                    align={entry.labelAlignment || 'center'}
                                                                    key={"header-" + id} style={cellStyle}>
                                                                    <span className={classes.columnHeader} style={cellStyle}>
                                                                        {getColumnLabel(entry, _defaultLabelSize)}
                                                                    </span>
                                                                    {
                                                                        hasTotal &&
                                                                        <div className={classes.totalCtn}>
                                                                            <NumberFormat value={total} thousandSeparator="." decimalSeparator="," displayType="text"
                                                                                renderText={
                                                                                    (value, props) => <span className={classes.totalValues} style={totalStyle}>
                                                                                        {value}
                                                                                    </span>}
                                                                            />
                                                                        </div>
                                                                    }
                                                                </TableCell>)
                                                        }
                                                    })}
                                                {
                                                    !props.disabled &&
                                                    <TableCell className={`${classes.thinColumn} ${classes.actionColumn} `}
                                                        style={{
                                                            backgroundColor: 'white',
                                                            borderBottomWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderTopWidth: 0,
                                                        }}
                                                    />
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={classes.tableBodyCtn}>
                                            {
                                                rows.length > 0 &&
                                                rows.map((row, rindex) => {
                                                    let rowId = rows.indexOf(row) + 1
                                                    if (row.recap !== true) return (
                                                        <React.Fragment key={rindex}>
                                                            <TableRow id={"row-index-" + rindex} style={row.recap === true ? { backgroundColor: colors.hoverBgnd, position: 'relative' } : { position: 'relative' }}>
                                                                {
                                                                    showRowNumbersInModal &&
                                                                    <TableCell
                                                                        align="center"
                                                                        className={classes.stickyColumn}
                                                                        key={"row-index-" + rindex}
                                                                    // style={{ width: '25px !important', position: 'relative' }}
                                                                    >
                                                                        {
                                                                            (props.disabled || !props.open) ?
                                                                                <span className={classes.rowNb}>{rindex + 1}</span>
                                                                                :
                                                                                <PlugTooltip title={t('multiple.edit-row')} placement="right-start">
                                                                                    <span
                                                                                        className={classes.rowNb}
                                                                                        onClick={() => handleClickRowNb(row)}
                                                                                    >
                                                                                        {rindex + 1}
                                                                                    </span>
                                                                                </PlugTooltip>
                                                                        }
                                                                    </TableCell>
                                                                }
                                                                {
                                                                    row.recap !== true && Object.keys(row)
                                                                        .sort((a, b) => {
                                                                            if (a === '1000' || a === 1000) return 1; else if (b === '1000' || b === 1000) return -1; else return a - b
                                                                        })
                                                                        .map((key, cindex) => {
                                                                            const col = props.colonnes.find(entry => entry.id.toString() === key.toString());
                                                                            if (!isDefined(col) || (props.mode.includes('external-create') && col.encodable === false) || (props.mode.includes('external-view') && col.visible === false)) { return null; }
                                                                            const isEditable = isEditableDefaultValue(props.field, col.id, rindex, props.mode, props.isFromPwCode)
                                                                            {/* devLog('isEditable ?', col, isEditable) */ }

                                                                            if (isEditableDirectly(col)) {
                                                                                return (
                                                                                    <TableCell
                                                                                        align={col.rowAlignment || 'center'}
                                                                                        key={"cell-" + key + '-' + cindex} style={{ position: 'relative', }}
                                                                                    // onClick={(e) => { if (isEditable) { handleCellClick(e, row, key) } }}
                                                                                    >
                                                                                        <RenderEditCellComponent
                                                                                            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                                            annexes={props.annexes}
                                                                                            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                            attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                            col={col}
                                                                                            columns={props.colonnes}
                                                                                            control={props.control}
                                                                                            controlCreate={props.controlCreate}
                                                                                            controlUpdate={props.controlUpdate}
                                                                                            disabled={props.disabled}
                                                                                            edit="cell"
                                                                                            field={props.field}
                                                                                            fields={props.fields}
                                                                                            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                            handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, col, rindex)}
                                                                                            handleValidate={handleEndEdit}
                                                                                            id={key}
                                                                                            isFromPwCode={props.isFromPwCode}
                                                                                            isPreview
                                                                                            key={'edit-cell-' + rindex + '-' + key}
                                                                                            mode={props.mode}
                                                                                            model={props.model}
                                                                                            open={true}
                                                                                            owner={props.owner}
                                                                                            ownerId={props.ownerId}
                                                                                            plugnote={props.plugnote}
                                                                                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                            rindex={rindex}
                                                                                            cindex={cindex}
                                                                                            row={row}
                                                                                            share={props.share}
                                                                                            title={props.title}
                                                                                            user={props.user}
                                                                                            value={row[key]}
                                                                                            dataHash={props?.dataHash}
                                                                                        />
                                                                                    </TableCell>
                                                                                )
                                                                            } else if (openRow !== null && openRow.row === row && openRow.id === key) return (
                                                                                <TableCell
                                                                                    align={col.rowAlignment || 'center'}
                                                                                    key={"cell-" + key + '-' + cindex} style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}>
                                                                                    <RenderEditCellComponent
                                                                                        annexes={props.annexes}
                                                                                        attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                        attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                        col={col}
                                                                                        columns={props.colonnes}
                                                                                        control={props.control}
                                                                                        controlCreate={props.controlCreate}
                                                                                        controlUpdate={props.controlUpdate}
                                                                                        disabled={props.disabled || !isEditable}
                                                                                        edit="cell"
                                                                                        field={props.field}
                                                                                        fields={props.fields}
                                                                                        handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                        handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, col, rindex)}
                                                                                        handleEndEdit={handleEndEdit}
                                                                                        id={openRow.id}
                                                                                        isFromPwCode={props.isFromPwCode}
                                                                                        key={'edit-cell-' + openRow.id}
                                                                                        mode={props.mode}
                                                                                        model={props.model}
                                                                                        open={true}
                                                                                        owner={props.owner}
                                                                                        ownerId={props.ownerId}
                                                                                        plugnote={props.plugnote}
                                                                                        renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                        rindex={props.rindex}
                                                                                        row={row}
                                                                                        share={props.share}
                                                                                        title={props.title}
                                                                                        user={props.user}
                                                                                        value={row[openRow.id]}
                                                                                    />
                                                                                </TableCell>
                                                                            )
                                                                            else if (row[key] === '' && keyList.includes(key)) {
                                                                                return (
                                                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ position: 'relative' }} onClick={(e) => { handleCellClick(e, row, key) }}>
                                                                                        <div className={classes.voidCell}>
                                                                                            <span className={classes.voidCell} />
                                                                                            {
                                                                                                !isEditable &&
                                                                                                <PlugTooltip title={t('tablemulti.locked-default')} placement="right">
                                                                                                    <div className={classes.disabledCellIcon} >
                                                                                                        <LockedIcon locked fontSize={12} />
                                                                                                    </div>
                                                                                                </PlugTooltip>
                                                                                            }
                                                                                        </div>
                                                                                    </TableCell>
                                                                                )
                                                                            }
                                                                            else return (keyList.includes(key) &&
                                                                                <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ position: 'relative' }} onClick={(e) => { handleCellClick(e, row, key) }}>
                                                                                    <RenderCellValue
                                                                                        columns={props.colonnes}
                                                                                        id={key}
                                                                                        value={row[key]}
                                                                                        row={row}
                                                                                    />
                                                                                    {
                                                                                        !isEditable &&
                                                                                        <PlugTooltip title={t('tablemulti.locked-default')} placement="right">
                                                                                            <div className={classes.disabledCellIcon} >
                                                                                                <LockedIcon locked fontSize={12} />
                                                                                            </div>
                                                                                        </PlugTooltip>
                                                                                    }
                                                                                </TableCell>
                                                                            )
                                                                        })
                                                                }
                                                                {
                                                                    row.recap === true && keyList
                                                                        .map((key, cindex) => {
                                                                            const col = props.colonnes.find(entry => entry.id.toString() === key.toString());
                                                                            if ((props.mode === 'plugcode-complete' && col.encodable === false) || (props.mode === 'plugcode-consult' && col.visible === false)) { return null; }
                                                                            const isEditable = isEditableDefaultValue(props.field, col.id, rindex, props.mode, props.isFromPwCode)

                                                                            if (openRow !== null && openRow.row === row && openRow.id === key) return (
                                                                                <TableCell
                                                                                    align={col.rowAlignment || 'center'}
                                                                                    key={"cell-" + key + '-' + cindex} style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}>
                                                                                    <RenderEditCellComponent
                                                                                        annexes={props.annexes}
                                                                                        attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                        attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                        col={col}
                                                                                        columns={props.colonnes}
                                                                                        control={props.control}
                                                                                        controlCreate={props.controlCreate}
                                                                                        controlUpdate={props.controlUpdate}
                                                                                        disabled={props.disabled}
                                                                                        edit="cell"
                                                                                        field={props.field}
                                                                                        fields={props.fields}
                                                                                        handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                        handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, col, rindex)}
                                                                                        handleEndEdit={handleEndEdit}
                                                                                        id={openRow.id}
                                                                                        isFromPwCode={props.isFromPwCode}
                                                                                        key={'edit-cell-' + openRow.id}
                                                                                        mode={props.mode}
                                                                                        model={props.model}
                                                                                        open={true}
                                                                                        owner={props.owner}
                                                                                        ownerId={props.ownerId}
                                                                                        plugnote={props.plugnote}
                                                                                        renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                        rindex={rindex}
                                                                                        row={row}
                                                                                        share={props.share}
                                                                                        title={props.title}
                                                                                        user={props.user}
                                                                                        value={row[openRow.id]}
                                                                                    />
                                                                                </TableCell>
                                                                            )
                                                                            else if (row.hasOwnProperty(key)) {
                                                                                return (
                                                                                    keyList.includes(key) &&
                                                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: '1px solid #DEDEDE', borderTop: 'none', position: 'relative' }}>
                                                                                        <RenderCellValue
                                                                                            columns={props.colonnes}
                                                                                            id={key}
                                                                                            value={row[key]}
                                                                                            row={row}
                                                                                        />
                                                                                        {
                                                                                            !isEditable &&
                                                                                            <PlugTooltip title={t('tablemulti.locked-default')} placement="right">
                                                                                                <div className={classes.disabledCellIcon} >
                                                                                                    <LockedIcon locked fontSize={12} />
                                                                                                </div>
                                                                                            </PlugTooltip>
                                                                                        }
                                                                                    </TableCell>
                                                                                )
                                                                            } else return (
                                                                                <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: 'none', position: 'relative' }}>
                                                                                    <div className={classes.voidCell} >
                                                                                        <span className={classes.voidCell} />
                                                                                        {
                                                                                            !isEditable &&
                                                                                            <PlugTooltip title={t('tablemulti.locked-default')} placement="right">
                                                                                                <div className={classes.disabledCellIcon} >
                                                                                                    <LockedIcon locked fontSize={12} />
                                                                                                </div>
                                                                                            </PlugTooltip>
                                                                                        }
                                                                                    </div>
                                                                                </TableCell>
                                                                            )
                                                                        })
                                                                }
                                                                {/* {
                                                        !props.disabled && !row.locked &&
                                                        (rowsToDelete.length > 0 ?
                                                            <td className={`${classes.deleteBtnCtn} material-icons`}
                                                                onClick={() => handleToggleToDelete(rowId)}
                                                                style={{ color: rowsToDelete?.includes(rowId) ? 'red' : colors.steel }}
                                                            >
                                                                delete
                                                            </td>
                                                            :
                                                            <PlugTooltip title={t('tablemulti.delete-row') + '\n' + t('tablemulti.delete-multiple')} placement="right-start">
                                                                <td className={`${classes.deleteBtnCtn} material-icons`} onClick={(e) => handleClickDelete(e, rowId)} >delete</td>
                                                            </PlugTooltip>
                                                        )
                                                    } */}
                                                                {
                                                                    !props.disabled &&
                                                                    <TableCell align="center" width={30} className={`${classes.actionColumn}`}
                                                                        style={{
                                                                            // display: 'none',
                                                                            backgroundColor: 'white',
                                                                            borderBottomWidth: 0,
                                                                            borderRightWidth: 0,
                                                                            borderTopWidth: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            !row.locked && rowsToDelete.length > 0 ?
                                                                                <span className={`${classes.deleteBtnCtn} material-icons`}
                                                                                    onClick={() => handleToggleToDelete(rowId)}
                                                                                    style={{ color: rowsToDelete?.includes(rowId) ? 'red' : colors.steel }}
                                                                                >
                                                                                    delete
                                                                                </span>
                                                                                :
                                                                                <RenderMenuPopup
                                                                                    handleToggleToDelete={handleToggleToDelete}
                                                                                    handleClickDelete={handleClickDelete}
                                                                                    handleClickDuplicate={handleClickDuplicate}
                                                                                    handleClose={() => setOpenPop(null)}
                                                                                    handleDeleteSingleRow={handleDeleteSingleRow}
                                                                                    handleOpen={() => setOpenPop(rowId)}
                                                                                    isDefaultRowsQtyReached={isDefaultRowsQtyReached}
                                                                                    isLimitReached={isLimitReached}
                                                                                    open={openPop === rowId}
                                                                                    openLastRow={openLastRow}
                                                                                    rindex={rindex}
                                                                                    row={row}
                                                                                    rowId={rowId}
                                                                                    rows={rows}
                                                                                    rowsToDelete={rowsToDelete}
                                                                                    rowToDelete={rowToDelete}
                                                                                    setRowToDelete={setRowToDelete}
                                                                                />
                                                                        }
                                                                    </TableCell>
                                                                }
                                                            </TableRow>
                                                            {
                                                                openRow !== null && openRow.row === row && openRow.id === undefined && (!isMobile || (isMobile && isLandscape)) &&
                                                                <TableRow key="edit-row" id="edit-row" className={classes.editRow}>
                                                                    <TableCell colSpan={1} className={classes.editRow}>
                                                                        <Collapse in={openRow !== null && openRow.row === row && openRow.id === undefined} timeout="auto" unmountOnExit>
                                                                            <Box margin={0}>
                                                                                <PlugTooltip title={t('global.validate')} placement="right-start">
                                                                                    <span className={`material-icons ${classes.validateIcon}`} onClick={() => handleEndEdit()}>check</span>
                                                                                </PlugTooltip>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className={isMobile ? `${classes.stickyEditor} ${classes.noBorder}` : classes.editRow}
                                                                        colSpan={keyList.length}
                                                                        ref={openRow !== null && openRow.row === row ? editedRow : null}
                                                                        style={isMobile ? { width: '90vw' } : undefined}
                                                                    >
                                                                        <Collapse in={openRow !== null && openRow.row === row && openRow.id === undefined} timeout="auto" style={{ position: "relative" }}>
                                                                            <Box margin={0} >
                                                                                <RenderEditRowComponent
                                                                                    annexes={props.annexes}
                                                                                    attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                    attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                    columns={props.colonnes}
                                                                                    control={props.control}
                                                                                    controlCreate={props.controlCreate}
                                                                                    controlUpdate={props.controlUpdate}
                                                                                    disabled={props.disabled}
                                                                                    edit="row"
                                                                                    field={props.field}
                                                                                    fields={props.fields}
                                                                                    handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                    handleChangeChildrenValue={(value, column) => props.handleChangeChildrenValue(value, column, rindex)}
                                                                                    handleEndEdit={handleEndEdit}
                                                                                    mode={props.mode}
                                                                                    model={props.model}
                                                                                    owner={props.owner}
                                                                                    ownerId={props.ownerId}
                                                                                    plugnote={props.plugnote}
                                                                                    renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                    rindex={rindex}
                                                                                    row={row}
                                                                                    share={props.share}
                                                                                    title={props.field.title}
                                                                                    user={props.user}
                                                                                />
                                                                                {
                                                                                    isMobile &&
                                                                                    <PlugTooltip title={t('global.validate')} placement="right-start">
                                                                                        <span className={`material-icons ${classes.validateIcon}`} onClick={() => handleEndEdit()}>check</span>
                                                                                    </PlugTooltip>
                                                                                }
                                                                            </Box>
                                                                        </Collapse>
                                                                    </TableCell>
                                                                </TableRow>

                                                            }
                                                        </React.Fragment>)
                                                    else return null
                                                })}
                                        </TableBody>
                                    </Table>
                                )
                        }
                    </div>
                    {
                        props.isAddRowPossible &&
                        <PlugTooltip title={!isLimitReached() ? t('tablemulti.add-row') : t('tablemulti.row-limit-warn')} placement="bottom">
                            <span id="icon-add-playlist" style={{ fontSize: '40px' }}
                                className={`${isLimitReached() ? classes.disabledIcon : classes.icon} ${classes.addNewLineBtn} material-icons`}
                                onClick={(e) => { if (!isLimitReached()) handleAddRow(e) }}
                            >
                                add_circle_outline
                            </span>
                        </PlugTooltip>
                    }
                </div>
                {
                    isDefined(openRow?.row) && !isLandscape &&
                    <PersistentBottomDrawer
                        drawerIcon="Validate"
                        handleDrawerClose={handleEndEdit}
                        open={isMobile && isDefined(openRow?.row)}
                        title={t('tablemulti.edit-row') + ' ' + (openRow.rindex + 1)}
                    >
                        <RenderEditRowComponent
                            annexes={props.annexes}
                            attemptSendMailSimple={props.attemptSendMailSimple}
                            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                            columns={props.colonnes}
                            control={props.control}
                            controlCreate={props.controlCreate}
                            controlUpdate={props.controlUpdate}
                            disabled={props.disabled}
                            edit="row"
                            field={props.field}
                            fields={props.fields}
                            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                            handleChangeChildrenValue={(value, column) => props.handleChangeChildrenValue(value, column, openRow.rindex)}
                            handleEndEdit={handleEndEdit}
                            mode={props.mode}
                            model={props.model}
                            owner={props.owner}
                            ownerId={props.ownerId}
                            plugnote={props.plugnote}
                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                            rindex={openRow.rindex}
                            row={openRow.row}
                            share={props.share}
                            title={props.field.title}
                            user={props.user}
                        />
                        <div style={{ padding: 8 }} />
                    </PersistentBottomDrawer>
                }
            </div>
        </Modal>
    )
}
