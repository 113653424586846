import { Tooltip } from '@material-ui/core';
import React from 'react';
import { isDefined } from '../../utils/utils';


export default function PlugTooltip(props) {
  if (props.debug) console.log('Plug TT props', props)

  const textStyle = {
    fontSize: isDefined(props.size) ? props.size : 15,
    whiteSpace: 'break-spaces',
    textAlign: isDefined(props.alignment) ? props.alignment : "left",
    display: 'flex',
  }

  return  <Tooltip
            {...props}
            open={props.debug} //? for tooltip inspection
            title={
              <span style={textStyle}>{props.title}</span>
            }
          />
}
