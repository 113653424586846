import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import common_en from "./en/common";
import common_fr from "./fr/common";
import {getLanguage} from "../utils/Common";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
    fallbackLng: ['en', 'fr'],
    lng: getLanguage() === null ? 'en' : getLanguage(),
        debug: true,
        resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        fr: {
            common: common_fr
        },
    },
    interpolation: {
        escapeValue: false
    },
    // react i18next special options (optional)
    react: {
        wait: true,
        useSuspense: false //   <---- this will do the magic
    }
});
export default i18n;
