import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import ModalListFicheComponent from "../modals/ModalListFicheComponent";
import ModalViewFicheComponent from "../modals/ModalViewFicheComponent";
import {getCurrentEnvironnement} from "../../utils/Common";
import {isJsonString} from "../../utils/utils";
import {getDataByClefUserIndexRequest, reduxGetDataByClefUserIndexReset} from "../../actions/data";
import {confirmAlert} from "react-confirm-alert";
import { InputAdornment, Tooltip } from "@material-ui/core";
import colors from "../../assets/themes/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldTop:{
        borderRadius: '6px',
        display: 'flex',
    },
    fieldBottomCtn:{
        border: '1px solid #E3E3E3',
        borderRadius: '6px',
        marginTop: '5px',
        padding: '3px 10px',
    },
    fieldBottom: {
        '& .MuiInputBase-inputMultiline': {
            alignSelf: 'flex-start',
        }
    },
    mainContainer:{
        minHeight: '40px',
        // width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    rightBtns: {
        cursor: 'pointer',
        padding: '3px 14px',
        borderRadius: "3px",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: colors.unselected,
    },
    topRightBtns: {
        alignSelf: 'flex-start',
        flexDirection: 'column',
        display: 'flex',
        maxHeight: 'none',
        height: 'auto',
    },
    addFormBtn: {
        fontSize: '17px',
        minHeight: '40px',
        paddingTop: '3px',
        paddingBottom: '3px',
        color: 'white!important',
        backgroundColor: colors.unselected,
        borderRadius: '6px',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        whiteSpace: 'break-spaces',
        width: '90%',
        marginRight: 5,
    },
    searchField: {
        minWidth: 150,
        transition: 'margin 0.5s'
    }
}));

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

const FormatPlugformComponent = (props) => {
    // console.log('F27', props);
    const classes = useStyles();
    const environment = getCurrentEnvironnement();
    //console.log('env F27 -', environment);

    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [owner, setOwner] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(false);
    const [clef, setClef] = useState(props.field.options.clef);
    const [field, setField] = useState(props.field.options.field);
    const [value, setValue] = useState(!!props.field.value.value ? props.field.value.value : "");
    const [index, setIndex] = useState(!!props.field.value.index ? props.field.value.index : "");
    const [name, setName] = useState(!!props.field.value.name ? props.field.value.name : "");
    const [envIndex, setEnvIndex] = useState(!!environment && !!environment.envIndex ? environment.envIndex + " - " : "");
    const [filter, setFilter] = useState('')
    const [openSearch, setOpenSearch] = useState(false)

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setValue(!!props.field.value.value ? props.field.value.value : "");
        setIndex(!!props.field.value.index ? props.field.value.index : "");
        setName(!!props.field.value.name ? props.field.value.name : "");
    },[props.field.value])

    const handleOpenFiche = () => {
        setView(true);
    }

    const handleOpenSearchModel = () => {
        setOpen(true);
    }

    const handleRefreshContentData = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.content-replace'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => {
                        if(owner !== null){
                            props.attemptGetPlugnoteByClefAndUserHashAndUserIndex(clef,owner,index, props.field.id);
                        }else{
                            props.attemptGetPlugnoteByClefAndUserHashAndUserIndex(clef,environment.userhash,index, props.field.id);
                        }
                    }
                },
                {
                    label: t('global.no'),
                },
            ]
        });

    }

    const handleSelectPlugnoteAndCopyDataFieldOld = (userindex, format, value, model) => {
        let tmp = "";
        switch (format){
            case 'select' :
                let tmpValueSelect = !Array.isArray(value) && value !== "[]" && value !== ""  ?  JSON.parse(value).join(',') : "";
                tmp = {"value" : tmpValueSelect , "index" : userindex, "name" : model.label};
                break;
            case 'table' :
                let tmpValueTable = !Array.isArray(value) && value !== "[]" && value !== "" ? JSON.parse(value).reduce((res, obj) => {
                    return res.concat(obj.text + ' : ' + obj.value || '');
                }, []).join(', ') : "";
                tmp = {"value" : tmpValueTable , "index" : userindex, "name" : model.label};
                break;
            case 'list' :
                let tmpValueList = !Array.isArray(value) && value !== "[]" && value !== "" ? JSON.parse(value).reduce((res, obj) => {
                    return res.concat(obj.text || '');
                }, []).join(', ') : "";
                tmp = {"value" : tmpValueList , "index" : userindex, "name" : model.label};
                break;
            case 'plugform' :
                let tmpValuePlugform = "";
                if(isJsonString(value)){
                    tmpValuePlugform = JSON.parse(value).value;
                }
                tmp = {"value" : tmpValuePlugform , "index" : userindex, "name" : model.label};
                break;
            default :
                tmp = {"value" : value , "index" : userindex, "name" : model.label};
                break;
        }

        props.handleChangePlugform(tmp, props.field, props.index);
        setOpen(false);
    }

    const handleSelectPlugnoteAndCopyDataFieldCached  = (userindex, object, model) => {
        let tmp = "";
        if(object.length > 0){
            let value = "";
            object.map((item, index) => {
                let temp = "";
                switch (item.format){
                    case 'select' :
                        let tmpValueSelect = !Array.isArray(item.value) && item.value !== "[]" && item.value !== ""  ?  JSON.parse(item.value).join(',') : "";
                        temp = tmpValueSelect;
                        break;
                    case 'table' :
                        let tmpValueTable = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text + ' : ' + obj.value || '');
                        }, []).join(', ') : "";
                        temp = tmpValueTable;
                        break;
                    case 'list' :
                        let tmpValueList = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text || '');
                        }, []).join(', ') : "";
                        temp = tmpValueList;
                        break;
                    case 'plugform' :
                        let tmpValuePlugform = "";
                        if(isJsonString(item.value)){
                            tmpValuePlugform = JSON.parse(item.value).value;
                        }
                        temp = tmpValuePlugform;
                        break;
                    case 'glocalis':
                        let plugValue = parseJson(item.value);
                        let tmpValue = "";
                        if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                            tmpValue = tmpValue.concat(plugValue.address);
                        }

                        if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                            if(tmpValue.length > 0){
                                tmpValue = tmpValue.concat(' ~ ');
                                tmpValue = tmpValue.concat(' \n ');
                            }
                            tmpValue = tmpValue.concat('lat : ' + plugValue.latitude + ' , lng : ' + plugValue.longitude);
                        }

                        temp = tmpValue;
                        break;
                    default :
                        temp = item.value;
                        break;
                }
                if(temp !== "" && typeof temp !== "undefined"){
                    value = value === "" ? value.concat(temp) : value.concat(' ~ \n\n'+ temp);
                }
            })

            tmp = {"value" : value , "index" : userindex, "name" : model.label};
            props.handleChangePlugform(tmp, props.field, props.index);
        }
        setOpen(false);
        props.attemptGetPlugnoteByClefAndUserHashAndUserIndexReset();
    }

    const handleSelectPlugnoteAndCopyDataField = (userindex, object, model) => {
        let tmp = "";
        if(object.length > 0){
            let value = "";
            object.map((item, index) => {
                let temp = "";
                switch (item.format){
                    case 'select' :
                        let tmpValueSelect = !Array.isArray(item.value) && item.value !== "[]" && item.value !== ""  ?  JSON.parse(item.value).join(',') : "";
                        temp = tmpValueSelect;
                        break;
                    case 'table' :
                        let tmpValueTable = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text + ' : ' + obj.value || '');
                        }, []).join(', ') : "";
                        temp = tmpValueTable;
                        break;
                    case 'list' :
                        let tmpValueList = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text || '');
                        }, []).join(', ') : "";
                        temp = tmpValueList;
                        break;
                    case 'plugform' :
                        let tmpValuePlugform = "";
                        if(isJsonString(item.value)){
                            tmpValuePlugform = JSON.parse(item.value).value;
                        }
                        temp = tmpValuePlugform;
                        break;

                    case 'glocalis':
                        let tmpValue = "";
                        try {
                            let plugValue = parseJson(item.value);

                            if (typeof plugValue.address !== "undefined" && plugValue.address.length > 0) {
                                tmpValue = tmpValue.concat(plugValue.address);
                            }

                            if (typeof plugValue.latitude !== "undefined" && typeof plugValue.longitude !== "undefined") {
                                if (tmpValue.length > 0) {
                                    tmpValue = tmpValue.concat(' ~ ');
                                    tmpValue = tmpValue.concat(' \n ');
                                }
                                tmpValue = tmpValue.concat('lat : ' + plugValue.latitude + ' , lng : ' + plugValue.longitude);
                            }
                        } catch (e) {
                            tmpValue = "";
                        }
                        temp = tmpValue;
                        break;
                    case 'password':
                        if (item.value.length > 0) temp = temp.concat('*******')
                        break;
                    default:
                        temp = item.value;
                        break;
                }
                if(temp !== "" && typeof temp !== "undefined"){
                    value = value === "" ? value.concat(temp) : value.concat(' ~ \n\n'+ temp);
                }
            })

            tmp = {"value" : value , "index" : userindex, "name" : model.label};
            props.handleChangePlugform(tmp, props.field, props.index);
        }
        setOpen(false);
        setOpenSearch(false)
    }

    const handleDeleteContentData = () => {
        // console.log('F27 - props', props);
        let emptyTmp = {"value" : "" , "index" : null, "name" : null}
        props.handleChangePlugform(emptyTmp, props.field, props.index);
    }

    function handleSearchValue (value) {
        setFilter(value)
    }
    
    function handleToggleSearch () {
        if (!disabled) setOpenSearch(!openSearch)
    }

    return (
        <div className={classes.mainContainer}>
            {
            props.mode.includes('create') && !disabled &&
            <div className={classes.fieldTop}>
                <Tooltip title={t('plugform.select-plugnote')} placement='top'>
                    <div className={classes.addFormBtn} onClick={(event) => handleOpenSearchModel()}>
                        { index !== "" ?
                            <span style={{fontSize : 14}}>{"Plugnote " + envIndex + index  + " - " + name + " [" + clef + "] (" + field + ")"}</span>
                            :
                            <span style={{fontSize : 14}}>{name + " [" + clef + "] " + field}</span>
                        }
                    </div>
                </Tooltip>
                {/* <div className="margin-auto-zero">
                    {typeof index == 'number' && !disabled &&
                    <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleDeleteContentData()}>
                        delete
                    </span>
                    }
                    {!disabled &&
                    <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleOpenSearchModel()}>
                        search
                    </span>
                    }
                </div> */}
                { typeof clef !== "undefined" && typeof name !== "undefined" &&
                <div className={classes.rightBtns}>
                    {
                        !disabled && !openSearch &&
                        <Tooltip title={t('plugform.filtered-search')} placement='top'>
                            <span id="icon-listForm" className={`material-icons md-25`} onClick={() => handleToggleSearch()}>
                                search
                            </span>
                        </Tooltip>
                    }
                    {
                        openSearch &&
                        <TextField
                            className={classes.searchField}
                            helperText=""
                            id="standard-full-width"
                            InputProps={{
                                endAdornment:
                                <span id="icon-listForm" className={`material-icons md-25`} onClick={() => handleOpenSearchModel()}>
                                    search
                                </span>
                            }}
                            margin="none"
                            multiline={true}
                            placeholder={t('plugform.filter-search')}
                            onChange={(event) => handleSearchValue(event.target.value)}
                            onKeyDown={(e) => {if (e.key === 'Enter') handleOpenSearchModel()}}
                            value={filter}
                        />
                    }
                    {/* {
                        typeof index == 'number' && !disabled &&
                        <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleOpenFiche()} style={{alignSelf: 'flex-end',}}>
                            info
                        </span>
                    } */}
                </div>
                }
            </div>
            }

            {value ===""? null :
            <div className={classes.fieldBottomCtn}>
                <TextField
                    className={classes.fieldBottom}
                    disabled={true}
                    id="standard-full-width"
                    style={{ margin: 0 }}
                    type="text"
                    multiline={true}
                    helperText=""
                    fullWidth
                    margin="normal"
                    InputProps={{
                        disableUnderline: true,
                        endAdornment:
                            <InputAdornment
                                classes={{root: classes.topRightBtns}}
                                position="end"
                            >
                                <div className={classes.topRightBtns}>
                                    {
                                        typeof index == 'number' && !disabled &&
                                        <Tooltip title={t('plugform.delete-content')} placement='top'>
                                            <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleDeleteContentData()}>
                                                delete
                                            </span>
                                        </Tooltip>
                                    }
                                    {
                                        typeof index == 'number' && !disabled &&
                                        <Tooltip title={t('plugform.refresh-content')} placement='top'>
                                            <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleRefreshContentData()}>
                                                cached
                                            </span>
                                        </Tooltip>
                                    }
                                    </div>
                            </InputAdornment>,
                            sx: {
                                "& .css-3fezr7-MuiInputBase-root-MuiOutlinedInput-root" : {
                                    padding: "4px 8px 4px 8px"
                                },
                                fontSize: ".875rem;",
                                alignItems:"flex-start"
                            }
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={value}
                />
            </div>}
            {
                open &&
                <ModalListFicheComponent
                    clef={clef}
                    close={(event) => setOpen(false)}
                    field={field}
                    filter={filter}
                    handleSelectPlugnoteAndCopyDataField={handleSelectPlugnoteAndCopyDataField}
                    label={name}
                    model={props.model}
                    open={open}
                    owner={owner}
                />
            }

            {/* {
                view && <ModalViewFicheComponent open={view} close={(event) => setView(false)} owner={owner} index={index}/>
            } */}
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetPlugnoteByClefAndUserHashAndUserIndex: (clef,userhash,userindex, fieldId) => dispatch(getDataByClefUserIndexRequest(clef,userhash,userindex, fieldId)),
    attemptGetPlugnoteByClefAndUserHashAndUserIndexReset: () => dispatch(reduxGetDataByClefUserIndexReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPlugformComponent);





