import logo from './logo.svg';
import './App.css';
import {ConnectedRouter} from "connected-react-router";
import {history} from "./hooks/history";
import {Container} from "rsuite";
import Navigations from "./navigations";
/* import {ToastContainer} from "react-toastify"; */
import Intercom from "./components/intercom/Intercom";
import React from "react";
import {withTranslation} from "react-i18next";

function App() {
  return (
      <ConnectedRouter history={history}>
        <Container>
          <Navigations/>
        {/* <ToastContainer autoClose={2000} /> */}
        </Container>
{/*
        <Intercom/>
*/}
      </ConnectedRouter>
  );
}

export default withTranslation()(App);
