import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useTranslation} from "react-i18next";
import {Add, Close, Delete, Search} from "@material-ui/icons";

import colors from "../../assets/themes/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
        border: '3px solid green',
    },
    input_add_item:{
        flexDirection: 'row',
        display: 'flex',
        border: "solid #E3E3E3 1px",
        borderRadius: "6px",
        width: "100%",
        padding: "2px 15px",
    },
    margin_left_5:{
        marginLeft : '5px',
        //border: '3px solid green',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        fontWeight: '500',
        borderRadius: "6px",
        padding: '0 10px',
        backgroundColor: colors.greyLighter,
        fontSize: '14px',
        flexGrow: 1,
        border: 'none',
        marginLeft: 10,
    },
    singleContainer: {
        width : '100%',
        border: '1px solid red',
    },
    singleAdd: {
        width : '30%',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        // marginTop: 10,
    },
    singleItemContainer: {
        flexDirection: 'row',
        display: 'flex',
        borderRadius: "6px",
        padding: "10px 15px",
        flexWrap: 'wrap',
        gap: 5,
    },
    selectedItemContainer: {
        flexDirection: 'row',
        height: '100%',
        // width: '100%',
        display: 'flex',
        borderRadius: "6px",
        //marginTop: 10,
        flexWrap: 'wrap',
        gap: 5,
        // alignItems: 'flex-start',
    },
    endButtonContainer:{
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "6px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'flex',
        color: 'white',
    },
    mainContainer:{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // backgroundColor: '#D4F61A' //à supprimer
        // alignItems: ''
    },
    itemContainer: {
        width: 'inherit',
        minHeight: '40px',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        "@media (max-width: 640px)": {
            minHeight: 30
        }
    },
    singleItem: {
        // cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedItem,
        color: colors.white,
        padding: "0 10px",
        gap: 5,
        fontWeight: 'normal',
    },
    unselectedItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        // border: '1px solid #b8f6f8',
        backgroundColor: colors.unselectedItem,
        color: colors.white,
        padding: "0 10px 1px",
        gap: 5,
    },
    singleInput: {
        textAlign: 'center',
        padding: 5,
        margin: 0,
    },
    singleDelButton: {
        width : 18,
        height : 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid',
        borderRadius: 50,
        fontSize: 16,
        fontWeight: 'bold'
    },
    singleItemSearchContainer: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        border: '1px solid #E3E3E3',
        borderRadius: "6px",
        padding: "10px 15px",
        marginTop: 10,
        gap: 5,
        alignItems: 'center',
    },
    singleItemSearchContainerOpen: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        border: '1px solid #E3E3E3',
        borderRadius: "6px",
        padding: "10px 15px",
        marginTop: 10,
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    singlePresetText: {
        color: colors.grey,
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    singleItemPresetOpen: {
        verticalAlign: 'middle',
        display: 'flex',
        gap: 5,
        alignItems: 'top-right',
        //border: '1px solid green',
        width: '20px',
    },
    singleSearchButton: {
        color: colors.main,
    },
    singleCleanButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderColor: colors.greyDark,
        color: colors.greyDark,
        borderRadius: 50,
    },
    singleCheckBoxGroup: {
        //color: colors.main,
        display: 'flex',
        alignItems: 'center',
        gap: 5,

    },
    singleCheckBox: {
        color: colors.main,
        width: 15,
        height: 15,
    },
    openFieldCtn: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    }
}));


const FormatSingleSelectComponent = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    const [selected, setSelected] = useState(props.field.value.length > 0 ? props.field.value[0] : "");
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [open, setOpen] = useState(props.visibleDefault ? props.field.visibleDefault : false);
    // const [open, setOpen] = useState(typeof props.expand === 'undefined' ? true: props.expand);
    const [value, setValue] = useState('');

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setSelected(props.field.value.length > 0 ? props.field.value[0] : "");
    },[props.field.value])


    const presels = [...props.field.options.presel]
    function isInPresel(value) {
        return props.field.originalPresel.includes(value)
    }
    const handleAddValue = (value) => {
        if (value.trim().length !== 0 && !presels.includes(value)) {
            props.field.options.presel.push(value);
            presels.push(value)
            props.handleChangeRadio(value, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
            setSelected(value);
        }
        setNewValue('');
        setOpen(false)
    }

    const handleRemoveValue = (value) => {
        let index = presels.indexOf(value);
        if (index !== -1) {
            presels.splice(index, 1);
            let indice = props.field.options.presel.indexOf(value);
            if (indice !== -1) {
                props.field.options.presel.splice(indice, 1);
                props.handleChangeRadio(value, indice, props.field,props.index, props.field.options.multiselect, props.field.options.mandatory);
            }
        }
    }
    const setItem = (item, indexation) => {
        if(selected.includes(item)){
            setSelected("");
            setOpen(true);

            if(!isInPresel(item)){
                handleRemoveValue(item)
            }else{
                props.handleChangeRadio(item, indexation,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
            }
        }
        else {
            setSelected(item)
            setOpen(false);
            props.handleChangeRadio(item, indexation,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }
    }

    return (
        <div className={classes.mainContainer}>
            <div className={classes.itemContainer} style={(disabled)? {cursor:"default"} : {cursor:"pointer"}} onClick={() => {if (!open && !disabled) setOpen(!open)}}>
                {!open ?
                    selected !== "" &&
                    <div className={classes.singleItem} style={(disabled)? {cursor:"default"} : {cursor:"pointer"}} onClick={() => {if (!disabled) setItem(selected, 0)} }>
                        <span className={ classes.singleInput}>{selected}</span>
                        <div onClick={() => {setOpen(true)}}>
                            {
                                !disabled &&
                                <span className={`${classes.singleDelButton} material-icons`}>
                                    {isInPresel(selected)? 'close':'delete'}
                                </span>
                            }
                        </div>
                    </div>
                    :
                    <div className={classes.openFieldCtn}>
                        {
                            props.field.options.presel.length > 10 && // filtre apparaît si plus de 10 items
                            <Input
                                id={`standard-full-width-search-${props.field.id}`}
                                label=""
                                fullWidth={true}
                                margin="none"
                                disableUnderline={true}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Search className={classes.singleSearchButton}/>
                                    </InputAdornment>}
                                endAdornment={ value.length > 0 &&
                                <InputAdornment position="end" onClick={(event) => setValue("")} style={{cursor : 'pointer'}}>
                                    <Close className={classes.singleCleanButton}/>
                                </InputAdornment>
                                }
                                inputlabelprops={{
                                    className: classes.input,
                                }}
                                className={classes.textField}
                                onChange={(event) => setValue(event.target.value)}
                                value={value}
                            />
                        }
                        <div className={classes.selectedItemContainer}>
                            {props.field.options.editable && !disabled &&
                            <div className={classes.singleAdd}>
                                <div className={classes.input_add_item}>
                                    <Input
                                        disabled={disabled}
                                        autoComplete='off'
                                        id="standard-full-width"
                                        label="Label"
                                        style={{ margin: 0 }}
                                        placeholder={t('common:multiple.add-item')}
                                        helpertext="Full width!"
                                        fullWidth={false}
                                        margin="none"
                                        disableUnderline={true}
                                        inputlabelprops={{
                                            shrink: true,
                                        }}
                                        value={newValue}
                                        onKeyPress={(e) => { if (e.key === 'Enter' && newValue != "") handleAddValue(newValue, props.field, props.index)}}
                                        onChange={(event) => setNewValue(event.target.value)}
                                    />
                                </div>
                            </div>}

                            {open && selected !== ""   ?
                                <div className={classes.singleItem} onClick={() => {if (!disabled) setItem(selected, 0)} }>
                                    <span className={classes.singleInput}>{selected}</span>
                                    <div onClick={() => {setOpen(true)}}>
                                        {
                                        !disabled &&
                                        <span className={`${classes.singleDelButton} material-icons`}>
                                            {isInPresel(selected)? 'close' :'delete'}
                                        </span>
                                        }
                                    </div>
                                </div>
                                : null}
                            {typeof props.field.options.presel !== 'undefined' && props.field.format.includes('select') && props.field.format.includes('single') && presels.filter(item => {
                                if (!value) return true
                                if (item.toLowerCase().includes(value.toLowerCase())) {
                                    return true
                                }
                            }).sort().map((item, indexage) => { if (item !== selected)
                                return(
                                    <div key= {'divsingleitem' + props.field.id + '-' + indexage} className={item===selected? classes.singleItem : classes.unselectedItem} onClick={() =>  {if (!disabled) setItem(item,indexage)}}>
                                        <span className={ classes.singleInput}>{item}</span>
                                        {
                                            !disabled && (item===selected)?
                                            <div>
                                            <span className={`${classes.singleDelButton} material-icons`}>
                                                {isInPresel(item)?  'close' : 'delete'}
                                            </span>
                                            </div>
                                         : null }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>

            <div className={classes.singleItemPresetOpen}>

            </div>
            { !disabled &&
                <div className={classes.endButtonContainer} onClick={(event) => setOpen(!open)}>
                    <span id="icon-delete-playlist" className={`${classes.singleItemPresetOpen} material-icons md-small`}>
                                    {open? 'expand_less' : props.field.options.editable? 'playlist_add':'playlist_add_check'}
                    </span>
                </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSingleSelectComponent);
