import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
       flexGrow: 1,
       overflow: 'auto',
       overflowX: 'hidden',
       whiteSpace: 'nowrap',
    },
    urlContainer:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 15,

    },
    urlButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
    color: "white",
    backgroundColor: "#58c3c1",
    padding: "3px 15px",
    borderRadius: "3px",
    cursor: "pointer",
    },
}));

const FormatUrlComponent = (props) => {

    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [text, setText] = useState(props.field.value);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        if(!!props.field.value){
            if (props.field.value.indexOf("http://") == 0 || props.field.value.indexOf("https://") == 0) {
                setText(props.field.value);
            }else{
                setText("https://"+props.field.value);
                props.handleEditValue("https://"+props.field.value, props.field, props.index)
            }
        }else {
            setText("https://");
        }

    },[props.field.value])

    const handleEditValue = (value, field, index) => {
        setText(value);
        props.handleEditValue(value, field, index)
    }

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const validUrl = (url) => {
        let newUrl = ""
        if(url.slice(0,5) !=="https") {
            newUrl = "https://" + url.trim()
        } else newUrl = url.trim()
        return newUrl
    }


    return (
        <div className={classes.urlContainer} >
                <TextField
                    onChange={(event) => handleEditValue(event.target.value, props.field, props.index)}
                    id="standard-full-width"
                    //label={field.title}
                    style={{ margin: 0 }}
                    autoComplete="url"
                    type="url"
                    placeholder={t('fields.format-choice-placeholder-url')}
                    onFocus={(e) => e.target.placeholder = ''}
                    onBlur={(e) => e.target.placeholder = t('fields.format-choice-placeholder-url')}
                    helperText=""
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className={classes.fieldRight}
                    value={text}
                    disabled={disabled}
                    InputProps={{ disableUnderline: true }}
                />
            { typeof text !== 'undefined' &&  text !== '' &&
                <div onClick={() => openInNewTab(validUrl(text.toString()))}>
                    <div className={classes.urlButton}><span className="material-icons md-small white">link</span></div>
                </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatUrlComponent);





