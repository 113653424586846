import React from "react";
import {isJsonStringObject} from "./utils";

/**
 * Service pour la gestion de compatibilité des formats pour le transfert entre Plugnote
 * Voir le mail de Vincent DeBruyn
 */

export const fieldTypes = {
    TEXT: 'text',
    NUMERIC: 'numeric',
    TIMEPLUS: 'timeplus',
    NOW: 'now',
    INDICE: 'indice',
    TEXTAREA: 'textarea',
    TIME: 'time',
    DATE: 'date',
    BIRTHDAY: 'birthday',
    AGENDA: 'agenda',
    PHONE: 'phone',
    SMS: 'sms',
    BOOLEAN: 'boolean',
    MAIL: 'mail',
    URL: 'url',
    PASSWORD: 'password',
    LIST: 'list',
    MULTI_SELECT: 'multi-select',
    FREE_MULTI_SELECT: 'free-multi-select',
    SINGLE_SELECT: 'single-select',
    FREE_SINGLE_SELECT: 'free-single-select',
    MULTI_TABLE: 'multi-table',
    FREE_MULTI_TABLE: 'free-multi-table',
    SINGLE_TABLE: 'single-table',
    FREE_SINGLE_TABLE: 'free-single-table',
    LOCALIS: 'localis',
    GLOCALIS: 'glocalis',
    KEYWORD: 'keyword',
    QRCODE: 'qrcode',
    QRCODEINT: 'qrcodeint',
    PLUGFORM: 'plugform',
    PLUGLINK: 'pluglink',
    FORMULA: 'formula',
    TABLEMULTI: 'tablemulti',
    TABLEMULTIN: 'tablemultin',
    EXTDATA: 'extdata',
    EXTDATAN: 'extdatan',
    SIGN: 'sign',
    SMAIL: 'smail',
    SMAILVALIDMULTI: 'smailvalidmulti',
    DOCUMENT: 'document',
}

export const formatTypes = {
    TEXT: 'F01',
    NUMERIC: 'F03',
    SMAIL: 'F21',
    NOW: 'F20',
    INDICE: 'F31',
    TEXTAREA: 'F02',
    TIME: 'F15',
    DATE: 'F04',
    BIRTHDAY: 'F18',
    AGENDA: 'F17',
    PHONE: 'F05',
    //SMS: 'sms',
    BOOLEAN: 'F16',
    MAIL: 'F06',
    URL: 'F07',
    PASSWORD: 'F08',
    LIST: 'F10',
    MULTI_SELECT: 'F13',
    FREE_MULTI_SELECT: 'F14',
    SINGLE_SELECT: 'F11',
    FREE_SINGLE_SELECT: 'F12',
    LOCALIS: 'F09',
    KEYWORD: 'F10',
    QRCODE: 'F19',
    QRCODEINT: 'F22',
    MULTI_TABLE: 'F25',
    FREE_MULTI_TABLE: 'F26',
    SINGLE_TABLE: 'F23',
    FREE_SINGLE_TABLE: 'F24',
    PLUGFORM: 'F27',
    PLUGLINK: 'F28',
    FORMULA: 'F29',
    GLOCALIS: 'F30',
    TABLEMULTI: 'F32',
    TABLEMULTIN: 'S32',
    EXTDATA: 'S01',
    EXTDATAN: 'S02',
    SIGN: 'M01',
    SMAILVALIDMULTI: 'S21',
    DOCUMENT: 'A32',
}

export const formatTextTypes = {
    "TEXT": 'F01',
    "NUMERIC": 'F03',
    "SMAIL": 'F21',
    "NOW": 'F20',
    "INDICE": 'F31',
    "TEXTAREA": 'F02',
    "TIME": 'F15',
    "DATE": 'F04',
    "BIRTHDAY": 'F18',
    "AGENDA": 'F17',
    "PHONE": 'F05',
    //SMS: 'sms',
    "BOOLEAN": 'F16',
    "MAIL": 'F06',
    "URL": 'F07',
    "PASSWORD": 'F08',
    "LIST": 'F10',
    "MULTI-SELECT": 'F13',
    "FREE-MULTI-SELECT": 'F14',
    "SINGLE-SELECT": 'F11',
    "FREE-SINGLE-SELECT": 'F12',
    "LOCALIS": 'F09',
    "KEYWORD": 'F10',
    "QRCODE": 'F19',
    "QRCODEINT": 'F22',
    "MULTI-TABLE": 'F25',
    "FREE-MULTI-TABLE": 'F26',
    "SINGLE-TABLE": 'F23',
    "FREE-SINGLE-TABLE": 'F24',
    "PLUGFORM": 'F27',
    "PLUGLINK": 'F28',
    "FORMULA": 'F29',
    "GLOCALIS": 'F30',
    "TABLEMULTI": 'F32',
    "TABLEMULTIN": 'S32',
    "EXTDATA": 'S01',
    "EXTDATAN": 'S02',
    "SMAILVALIDMULTI": 'S21',
    "DOCUMENT": 'A32',
    "SIGN" : "M01"
}

export const formatTable = {
    F01: {
        format: fieldTypes.TEXT,
        compatibleFormats: [fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_SINGLE_SELECT, fieldTypes.FREE_MULTI_SELECT, fieldTypes.INDICE, fieldTypes.EXTDATA],
    },
    F02: {
        format: fieldTypes.TEXTAREA,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.KEYWORD,fieldTypes.LOCALIS,fieldTypes.PHONE,fieldTypes.SMAIL,fieldTypes.SIGN, fieldTypes.INDICE]
    },
    F03: {
        format: fieldTypes.NUMERIC,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_SINGLE_SELECT, fieldTypes.FREE_MULTI_SELECT]
    },
    F04: {
        format: fieldTypes.DATE,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_SINGLE_SELECT, fieldTypes.FREE_MULTI_SELECT]
    },
    F05: {
        format: fieldTypes.PHONE,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.NUMERIC]
    },
    F06: {
        format: fieldTypes.MAIL,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT,fieldTypes.SMAIL]
    },
    F07: {
        format: fieldTypes.URL,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT]
    },
    F08: {
        format: fieldTypes.PASSWORD,
        compatibleFormats: [],
        compatibleItself: false
    },
    F09: {
        format: fieldTypes.LOCALIS,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT]
    },
    F10: {
        format: fieldTypes.KEYWORD,
        compatibleFormats: [fieldTypes.TEXTAREA, fieldTypes.FREE_SINGLE_SELECT, fieldTypes.FREE_MULTI_SELECT],
        compatibleFormatsOptions: { separator: ';' },
        otherFormat: fieldTypes.LIST
    },
    F11: {
        format: fieldTypes.SINGLE_SELECT,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT]
    },
    F12: {
        format: fieldTypes.FREE_SINGLE_SELECT,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.MULTI_SELECT, fieldTypes.SINGLE_SELECT]
    },
    F13: {
        format: fieldTypes.MULTI_SELECT,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT]
    },
    F14: {
        format: fieldTypes.FREE_MULTI_SELECT,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.MULTI_SELECT]
    },
    F15: {
        format: fieldTypes.TIME,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT]
    },
    F16: {
        format: fieldTypes.BOOLEAN,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT]
    },
    F17: {
        format: fieldTypes.AGENDA,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT]
    },
    F18: {
        format: fieldTypes.BIRTHDAY,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT]
    },
    F19: {
        format: fieldTypes.QRCODE,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA]
    },
    F20: {
        format: fieldTypes.NOW,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT]
    },
    F22: {
        format: fieldTypes.QRCODEINT,
        compatibleFormats: [fieldTypes.QRCODEINT],
    },
    F31: {
        format: fieldTypes.INDICE,
        compatibleFormats: [fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.KEYWORD, fieldTypes.FREE_MULTI_SELECT, fieldTypes.FREE_SINGLE_SELECT],
        compatibleItself: false
    },
    F23: {
        format: fieldTypes.SINGLE_TABLE,
        compatibleFormats: [fieldTypes.FREE_MULTI_TABLE, fieldTypes.MULTI_TABLE, fieldTypes.FREE_SINGLE_TABLE]
    },
    F24: {
        format: fieldTypes.FREE_SINGLE_TABLE,
        compatibleFormats: [fieldTypes.FREE_MULTI_TABLE, fieldTypes.MULTI_TABLE, fieldTypes.SINGLE_TABLE]
    },
    F25: {
        format: fieldTypes.MULTI_TABLE,
        compatibleFormats: [fieldTypes.FREE_MULTI_TABLE]
    },
    F26: {
        format: fieldTypes.FREE_MULTI_TABLE,
        compatibleFormats: [fieldTypes.MULTI_TABLE]
    },
    F27: {
        format: fieldTypes.PLUGFORM,
        compatibleFormats: [fieldTypes.PLUGFORM]
    },
    F28: {
        format: fieldTypes.PLUGLINK,
        compatibleFormats: [fieldTypes.PLUGLINK]
    },
    F29: {
        format: fieldTypes.FORMULA,
        compatibleFormats: [fieldTypes.FORMULA]
    },
    F30: {
        format: fieldTypes.GLOCALIS,
        compatibleFormats: [fieldTypes.GLOCALIS]
    },
    F32: {
        format: fieldTypes.TABLEMULTI,
        compatibleFormats: [fieldTypes.TABLEMULTI]
    },
    S32: {
        format: fieldTypes.TABLEMULTIN,
        compatibleFormats: [fieldTypes.TABLEMULTIN]
    },
    S01: {
        format: fieldTypes.EXTDATA,
        compatibleFormats: [fieldTypes.EXTDATA,fieldTypes.EXTDATAN, fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.TIME, fieldTypes.DATE,fieldTypes.AGENDA,fieldTypes.BIRTHDAY, fieldTypes.LOCALIS, fieldTypes.MAIL, fieldTypes.URL, fieldTypes.PHONE]
    },
    S02: {
        format: fieldTypes.EXTDATAN,
        compatibleFormats: [fieldTypes.EXTDATA,fieldTypes.EXTDATAN, fieldTypes.TEXT, fieldTypes.TEXTAREA, fieldTypes.TIME, fieldTypes.DATE,fieldTypes.AGENDA,fieldTypes.BIRTHDAY, fieldTypes.LOCALIS, fieldTypes.MAIL, fieldTypes.URL, fieldTypes.PHONE]
    },
    M01: {
        format: fieldTypes.SIGN,
        compatibleFormats: [fieldTypes.SIGN]
    },
    F21: {
        format: fieldTypes.SMAIL,
        compatibleFormats: [fieldTypes.SMAIL,fieldTypes.MAIL]
    },
    S21: {
        format: fieldTypes.SMAILVALIDMULTI,
        compatibleFormats: [fieldTypes.SMAILVALIDMULTI]
    },
    A32: {
        format: fieldTypes.DOCUMENT,
        compatibleFormats: [fieldTypes.DOCUMENT]
    }
}

export const getTemplateFormatText = (format, options) => {

    let fieldFormat = format;
    // Vérifier les préselections
    let template = '';

    if(isJsonStringObject(options)){
        options = JSON.parse(options)
    }

    if (options !== null && typeof options !== "undefined" && typeof options.presel !== 'undefined' && (fieldFormat === "select" || fieldFormat.includes("select"))) {

        if(options.multiselect && !options.editable){
            fieldFormat = "multi-select";
        }else if(!options.multiselect && !options.editable){
            fieldFormat = "single-select";

        }else if(options.multiselect && options.editable){
            fieldFormat = "free-multi-select";

        }else if(!options.multiselect && options.editable){
            fieldFormat = "free-single-select";
        }

    }

    if (options !== null && typeof options !== "undefined" && typeof options.presel !== 'undefined' && (fieldFormat === "table" || fieldFormat.includes("table"))) {

        if(options.multiselect && !options.editable){
            fieldFormat = "multi-table";
        }else if(!options.multiselect && !options.editable){
            fieldFormat = "single-table";

        }else if(options.multiselect && options.editable){
            fieldFormat = "free-multi-table";

        }else if(!options.multiselect && options.editable){
            fieldFormat = "free-single-table";
        }

    }

    switch (fieldFormat) {
        case fieldTypes.QRCODE:
            template =formatTypes.QRCODE; break;
        case fieldTypes.QRCODEINT:
            template =formatTypes.QRCODEINT; break;
        case fieldTypes.BIRTHDAY:
            template =formatTypes.BIRTHDAY; break;
        case fieldTypes.TEXT:
            template =formatTypes.TEXT; break;
        case fieldTypes.TEXTAREA:
            template =formatTypes.TEXTAREA; break;
        case fieldTypes.PHONE:
            template =formatTypes.PHONE; break;
        case fieldTypes.MAIL:
            template =formatTypes.MAIL; break;
        case fieldTypes.URL:
            template =formatTypes.URL; break;
        case fieldTypes.LOCALIS:
            template =formatTypes.LOCALIS; break;
        case fieldTypes.NUMERIC:
            template =formatTypes.NUMERIC; break;
        case fieldTypes.FREE_MULTI_SELECT:
            template =formatTypes.FREE_MULTI_SELECT; break;
        case fieldTypes.MULTI_SELECT:
            template =formatTypes.MULTI_SELECT; break;
        case fieldTypes.SINGLE_SELECT:
            template =formatTypes.SINGLE_SELECT; break;
        case fieldTypes.FREE_SINGLE_SELECT:
            template =formatTypes.FREE_SINGLE_SELECT; break;
        case fieldTypes.FREE_MULTI_TABLE:
            template =formatTypes.FREE_MULTI_TABLE; break;
        case fieldTypes.MULTI_TABLE:
            template =formatTypes.MULTI_TABLE; break;
        case fieldTypes.SINGLE_TABLE:
            template =formatTypes.SINGLE_TABLE; break;
        case fieldTypes.FREE_SINGLE_TABLE:
            template =formatTypes.FREE_SINGLE_TABLE; break;
        case fieldTypes.LIST:
            template =formatTypes.LIST; break;
        case fieldTypes.NOW:
            template =formatTypes.NOW; break;
        case fieldTypes.AGENDA:
            template =formatTypes.AGENDA; break;
        case fieldTypes.DATE:
            template =formatTypes.DATE; break;
        case fieldTypes.BOOLEAN:
            template =formatTypes.BOOLEAN; break;
        case fieldTypes.PASSWORD:
            template =formatTypes.PASSWORD; break;
        case fieldTypes.PLUGFORM:
            template =formatTypes.PLUGFORM; break;
        case fieldTypes.PLUGLINK:
            template =formatTypes.PLUGLINK; break;
        case fieldTypes.FORMULA:
            template =formatTypes.FORMULA; break;
        case fieldTypes.GLOCALIS:
            template =formatTypes.GLOCALIS; break;
        case fieldTypes.TABLEMULTI:
            template =formatTypes.TABLEMULTI; break;
        case fieldTypes.TABLEMULTIN:
            template =formatTypes.TABLEMULTIN; break;
        case fieldTypes.EXTDATA:
            template =formatTypes.EXTDATA; break;
        case fieldTypes.EXTDATAN:
            template =formatTypes.EXTDATAN; break;
        case fieldTypes.SIGN:
            template =formatTypes.SIGN; break;
        case fieldTypes.TIME:
            template =formatTypes.TIME; break;
        case fieldTypes.SMAIL:
            template =formatTypes.SMAIL; break;
        case fieldTypes.SMAILVALIDMULTI:
            template =formatTypes.SMAILVALIDMULTI; break;
        case fieldTypes.DOCUMENT:
            template =formatTypes.DOCUMENT; break;
    }

    return template;
}


/**
 * Permet de savoir si un champ est moyennement compatible, cf. Tableau des compatibilités de Vincent DeBruyn
 * @param field, champ que l'on cherche à savoir si il est compatible avec le champ à transférer
 * @param fieldToTransfer, champ que l'on souhaite transférer
 */
export const isAlmostCompatible = (field, fieldToTransfer) => {
    if(!!fieldToTransfer && !!field){
        // Récupération de la clé de format pour le champ que l'on souhaite transférer
        let formatKey = Object.keys(formatTable).find(key => formatTable[key].format === fieldToTransfer.format)
        // On teste si il y a un autre format lié à notre clé de format (ex: KEYWORD et LIST)
        if (!formatKey) {
            formatKey = Object.keys(formatTable).find(key => formatTable[key].otherFormat === fieldToTransfer.format)
        }

        if (formatKey != null) {
            // On va chercher dans la table du dessus, les formats compatibles avec le champs que l'on souhaite transférer
            const compatibleFormats = formatTable[formatKey].compatibleFormats
            // Et on vérifie si le champ recherché est bien un format compatible avec le champ que l'on souhaite transférer
            return compatibleFormats.find(format => format === field.format) != null
        } else {
            return false
        }
    }

    return false;

}

/**
 * Permet de savoir si un champ est moyennement compatible, cf. Tableau des compatibilités de Vincent DeBruyn
 * @param field, champ que l'on cherche à savoir si il est compatible avec le champ à transférer
 * @param fieldToTransfer, champ que l'on souhaite transférer
 */
export const isAlmostSpecialCompatible = (fieldFormat, fieldFormatToTransfer) => {
    if(!!fieldFormat && !!fieldFormatToTransfer){
        // Récupération de la clé de format pour le champ que l'on souhaite transférer
        let formatKey = Object.keys(formatTable).find(key => formatTable[key].format === fieldFormatToTransfer)
        // On teste si il y a un autre format lié à notre clé de format (ex: KEYWORD et LIST)
        if (!formatKey) {
            formatKey = Object.keys(formatTable).find(key => formatTable[key].otherFormat === fieldFormatToTransfer)
        }

        if (formatKey != null) {
            // On va chercher dans la table du dessus, les formats compatibles avec le champs que l'on souhaite transférer
            const compatibleFormats = formatTable[formatKey].compatibleFormats
            // Et on vérifie si le champ recherché est bien un format compatible avec le champ que l'on souhaite transférer
            return compatibleFormats.find(format => format === fieldFormat) != null && typeof compatibleFormats.find(format => format === fieldFormat) != "undefined"
        } else {
            return false
        }
    }

    return false;

}

/**
 * Permet de savoir si un champ est compatible avec son propre format, cf. Tableau des compatibilités de Vincent DeBruyn
 * @param field, champ que l'on cherche à savoir si il est compatible avec le champ à transférer
 * @param fieldToTransfer, champ que l'on souhaite transférer
 */
export const isCompatibleItself = (field, fieldToTransfer) => {

    if(!!fieldToTransfer && !!field){
        // Récupération de la clé de format pour le champ que l'on recherche
        let formatKey = Object.keys(formatTable).find(key => formatTable[key].format === field.format)
        // On teste si il y a un autre format lié à notre clé de format (ex: KEYWORD et LIST)
        if (!formatKey) {
            formatKey = Object.keys(formatTable).find(key => formatTable[key].otherFormat === field.format)
        }

        const format = field.format == fieldTypes.LIST ? fieldTypes.KEYWORD : field.format
        const formatToTransfer = fieldToTransfer.format == fieldTypes.LIST ? fieldTypes.KEYWORD : fieldToTransfer.format

        // On vérifie si le champ est compatible avec son propre format et si les formats des deux champs sont identiques
        return formatKey!= null && formatTable[formatKey].compatibleItself == null && format === formatToTransfer

    }

    return false;
}

/**
 * Permet de savoir si un champ est totalement compatible, cf. Tableau des compatibilités de Vincent DeBruyn
 * @param field, champ que l'on cherche à savoir si il est compatible avec le champ à transférer
 * @param fieldToTransfer, champ que l'on souhaite transférer
 */
export const isCompatible = (field, fieldToTransfer) => {

    if(!!fieldToTransfer && !!field){
        // On vérifie si le champ est compatible avec son propre format et si il est moyennement compatible
        return isAlmostCompatible(field, fieldToTransfer) || isCompatibleItself(field, fieldToTransfer)
    }

    return false;
}

/**
 * Permet de savoir si un champ est totalement compatible, cf. Tableau des compatibilités de Vincent DeBruyn
 * @param field, champ que l'on cherche à savoir si il est compatible avec le champ à transférer
 * @param fieldToTransfer, champ que l'on souhaite transférer
 */
export const isCompatibleSpecial = (field, fieldToTransfer) => {
    if(!!fieldToTransfer && !!field){
        // On vérifie si le champ est compatible avec son propre format et si il est moyennement compatible
        if(field === fieldToTransfer){
            return true;
        }else{
            return isAlmostSpecialCompatible(field, fieldToTransfer);
        }
    }

    return false;
}

export const isLabelCompatible = (field, fieldToTransfer) => {

    if(field.label === fieldToTransfer.label){
        return true;
    }

    return false;
}


export const isCompatibles = (fields, fieldToTransfer) => {

    return fields.filter((field) => {
        if(!!fieldToTransfer && !!field){
            // Récupération de la clé de format pour le champ que l'on recherche
            let formatKey = Object.keys(formatTable).find(key => formatTable[key].format === field.format)
            // On teste si il y a un autre format lié à notre clé de format (ex: KEYWORD et LIST)
            if (!formatKey) {
                formatKey = Object.keys(formatTable).find(key => formatTable[key].otherFormat === field.format)
            }

            const format = field.format == fieldTypes.LIST ? fieldTypes.KEYWORD : field.format
            const formatToTransfer = fieldToTransfer.format == fieldTypes.LIST ? fieldTypes.KEYWORD : fieldToTransfer.format

            // On vérifie si le champ est compatible avec son propre format et si les formats des deux champs sont identiques
            return formatKey!= null && formatTable[formatKey].compatibleItself == null && format === formatToTransfer

        }
        return false;

    });
}
export const isAlmostCompatibles = (fields, fieldToTransfer) => {
    return fields.filter((field) => {
        if(!!fieldToTransfer && !!field){
            // Récupération de la clé de format pour le champ que l'on souhaite transférer
            let formatKey = Object.keys(formatTable).find(key => formatTable[key].format === fieldToTransfer.format)
            // On teste si il y a un autre format lié à notre clé de format (ex: KEYWORD et LIST)
            if (!formatKey) {
                formatKey = Object.keys(formatTable).find(key => formatTable[key].otherFormat === fieldToTransfer.format)
            }

            if (formatKey != null) {
                // On va chercher dans la table du dessus, les formats compatibles avec le champs que l'on souhaite transférer
                const compatibleFormats = formatTable[formatKey].compatibleFormats

                // Et on vérifie si le champ recherché est bien un format compatible avec le champ que l'on souhaite transférer
                return compatibleFormats.find(format => format === field.format) != null
            } else {
                return false
            }
        }

        return false;
    })
}
