import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import {Content, Header} from "rsuite";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Colors from "../../assets/themes/Colors";
import colors from "../../assets/themes/Colors";
import Checkbox from "@material-ui/core/Checkbox";
import {confirmAlert} from "react-confirm-alert";
import {blobToBase64, urlify} from "../../utils/utils";
import {postSendMailV3Request} from "../../actions/utils";
import {getCurrentEnvironnement} from "../../utils/Common";
import {strReplaceContentField} from "../../utils/Fields";
import {Data9002PrintableGetURL} from "../printable/Data9002Printable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-regular-svg-icons";
import { ColorCheckbox } from "../custom/CustomComponents";
import { Tooltip } from "@material-ui/core";
import {Data2001PrintableGetURL} from "../printable/Data2001Printable";
import {isNull} from "lodash";
import ApplicationConfig from "../../config/ApplicationConfig";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
       flexGrow: 1,
    },
    mailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mailButton:{
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    inactiveBtn: {
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.darkSilver,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "not-allowed !important",
    },
    header: {
        flex: "0 0 100%",
    },
    sous_header_content: {
        padding: 15,
        display: "flex",
        justifyContent: "space-between",
        gap: 5,
        "@media (max-width: 1024px)": {
            flexDirection: "column",
            alignItems: "center",
            padding: 0,
            gap: 15,
        },
    },
    sous_header_content_title: {
        flexGrow: 1,
        overflow: "hidden",
        display: "flex",
        "@media (max-width: 1024px)": {
            width: "100%",
        },
    },
    sous_header_content_title_input: {
        color: "black",
        "@media (max-width: 1024px)": {
            textAlign: "center",
        },
    },
    sous_header_content_buttons: {
        display: "flex",
        gap: 5,
        "@media (max-width: 1024px)": {
            width: "100%",
            justifyContent: "space-between",
        },
    },
    sous_header_button: {
        width: "150px",
        padding: "4px 20px",
        borderRadius: "3px",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
            // width: "100%",
        },
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: '1px solid #ccc',
        WebkitBorderRadius: '5px',
    },
    faIconSize: {
        fontSize: 20,
        color: "#51C3C1",
    },
    textFieldLocked: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: '1px solid #ccc',
        WebkitBorderRadius: '5px',
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    mSeparatorWrapper:{
        height:3,
        backgroundColor: Colors.plugnotesBlueApp,
    },
    images: {
        paddingTop: 10,
        paddingBottom: 10,
        zIndex: 10
    },
    imageContainer: {
        paddingLeft: 15,
        paddingRight: 15,
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight : 100,
    },
    attachmentTitle: {
        color: "black",
        fontWeight: "600",
    },
    attachmentBox: {
        margin: "0 0 40px 0",
        border: "dashed 2px #51C3C1",
        borderRadius: "5px",
        backgroundColor: "#c5e4e3",
        height: 150,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    attItemsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        overflow: "hidden",
        marginLeft : '10px'
    },
    attItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0 0 5px 0",
    },
    attAnnexName: {
        overflow: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        height: "30px",
        flex: "none",
        width : "100%",
        fontSize: "14px",
        cursor: "pointer",
        color: colors.selectedMain
    },
    attIconDlInfo: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#D8D8D8",
    },
    attIconType: {
        flex: "0 0 10%",
        width: "30px",
        height: "30px",
        cursor: "pointer",
    },
    multipleCheckBox: {
        color: colors.main,
        width: 15,
        height: 15,
    },
    switchBase: {
        "&.Mui-checked": {
            color: colors.main
        }
    },
    modalHeader: {
        // position: 'absolute',
        top: 0,
        width: '100%',
    },
    modalFooter: {
        '@media (min-height:640px)': {
            display: 'none'
        },
        // position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}));

const SmailModal = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models']);

    let content = "";

    if(props.options?.body){
        content = strReplaceContentField(props.options?.body, props.fields, props?.model, props?.plugnote, props?.user);
    }

    const [body, setBody] = useState(content);
    const [sujet, setSujet] = useState(props.options?.title || "");

    const [expediteur, setExpediteur] = useState(props.options?.from || "");
    const [viewNotes, setViewNotes] = useState(props.options?.note ? props.options?.note === 'Y' : false);
    const [mail, setMail] = useState(props.value || "");
    const [mailCc, setMailCc] = useState(props.options?.cc || "");
    const [mailCci, setMailCci] = useState(props.options?.cci || "");
    const [attachments, setAttachments] = useState([]);
    const [attachment, setAttachment] = useState("");

    const environment = getCurrentEnvironnement();

    const handlePressValidate = () => {
        if(mail.length > 0){
            let message = t('global.send');
            confirmAlert({
                title: t('global.plugnotes'),
                message: message,
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            let bodyReplace = body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                            let bodyUrlReplace = urlify(bodyReplace);

                            const searchRegExp = /(PN|PF|PW)-\d{6}-[A-Z,0-9]{5}/g;


                            if(bodyUrlReplace.search(searchRegExp)){
                                const searchedValues = bodyUrlReplace.match(searchRegExp);


                                if(!isNull(searchedValues)){
                                    if(searchedValues.length > 0){
                                        searchedValues.map((value, index) => {
                                            bodyUrlReplace = bodyUrlReplace.replace(value, '<a href="' + ApplicationConfig.templateUrl + '?code='+ value + '">' + value + '</a>');
                                        })
                                    }
                                }
                            }


                            let payload = {};
                            payload.subject = sujet.length > 0 ?  sujet : null ;
                            payload.body = bodyUrlReplace.length > 0 ? bodyUrlReplace : null;
                            payload.from = expediteur.length > 0 ? expediteur : null;
                            payload.cc = mailCc.length > 0 ? mailCc : null;
                            payload.cci = mailCci.length > 0 ? mailCci : null;
                            payload.to = mail.length > 0 ? mail : null;
                            payload.attachments = attachments.length > 0 ? attachments : null;
                            payload.attachment = attachment !== "" ? attachment : null;

                            props.close();
                            props.attemptSendMailSimple(payload,environment?.userhash);
                        }
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.missing-email'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    const handleToggleAnnexe = (e, annexe) => {
        e.preventDefault();
        e.stopPropagation();
        if (!attachments.includes(annexe)){
            setAttachments([...attachments, annexe]);
        }
        else {
            let tmp = attachments.filter(item => item !== annexe);
            setAttachments(tmp);
        }
    }

    const handleToggleCustomAnnexe = (e, annexe) => {
        e.preventDefault();
        e.stopPropagation();
        if (attachment !== annexe) {
            setAttachment(annexe);
        }
        else {
            setAttachment("");
        }
    }

    return(
        <Dialog open={props.open} onClose={props.close} fullWidth={true} maxWidth="md">
            <div className={classes.modalHeader}>
                <div className="popup_wrapper_content justify_content_space">
                    <button
                        className="button-exit"
                        onClick={props.close}
                    >
                        {t("common:global.cancel")}
                    </button>
                    <button className="button float_right" onClick={handlePressValidate}>{ t('common:global.send') }</button>
                </div>
            </div>
            <Content>
                <div className="popup_wrapper_content">
                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel  style={{fontWeight : 'bold'}} shrink>{ t('common:mails.destinataire') }</InputLabel>
                        <span style={{height : '25px'}}/>
                    </div>
                    <Input
                        id="standard-full-width"
                        label=""
                        style={{ margin: 8 }}
                        //placeholder={props.placeholder}
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        inputlabelprops={{
                            className: classes.input,
                        }}
                        className={classes.textField}
                        value={mail}
                        disabled={false}
                        onChange={(event) => setMail(event.target.value)}
                    />
                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.destinatairecc') }</InputLabel>
                        <span style={{height : '25px'}}/>
                    </div>
                    <Input
                        id="standard-full-width"
                        label=""
                        style={{ margin: 8 }}
                        //placeholder={props.placeholder}
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        inputlabelprops={{
                            className: classes.input,
                        }}
                        className={classes.textField}
                        value={mailCc}
                        disabled={false}
                        onChange={(event) => setMailCc(event.target.value)}
                    />

                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.destinatairecci') }</InputLabel>
                        <span style={{height : '25px'}}/>
                    </div>
                    <Input
                        id="standard-full-width"
                        label=""
                        style={{ margin: 8 }}
                        //placeholder={props.placeholder}
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        inputlabelprops={{
                            className: classes.input,
                        }}
                        className={classes.textField}
                        value={mailCci}
                        disabled={false}
                        onChange={(event) => setMailCci(event.target.value)}
                    />

                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.sujet') }</InputLabel>
                        <span style={{height : '25px'}}/>
                    </div>

                    <Input
                        id="standard-full-width"
                        label=""
                        style={{ margin: 8 }}
                        //placeholder={props.placeholder}
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        inputlabelprops={{
                            className: classes.input,
                        }}
                        className={classes.textField}
                        onChange={(event) => setSujet(event.target.value)}
                        value={sujet}
                    />

                    <div className="flex_display_direction_row justify_content_space align-items-center">
                        <InputLabel style={{fontWeight : 'bold'}} shrink>{ t('common:mails.message') }</InputLabel>
                        <span style={{height : '25px', fontSize: 10, fontStyle: 'italic', fontWeight: 'bold', color: colors.red}}>
                            {body.includes('<sup>') && t('common:smail.info-tags')}
                        </span>
                    </div>

                    <Input
                        id="outlined-multiline-static"
                        //label="Commentaire d'annexe"
                        multiline={true}
                        style={{ margin: 8 }}
                        rows={10}
                        value={body}
                        margin="none"
                        disableUnderline={true}
                        fullWidth={true}
                        inputlabelprops={{
                            className: classes.input,
                        }}
                        className={classes.textFieldArea}
                        onChange={(event) => setBody(event.target.value)}
                    />

                </div>
                {
                    (props.annexes.length > 0 ||  props.customAnnexe !== null) &&
                    <div className="popup_wrapper_content">
                        <div className="flex_display_direction_row justify_content_space align-items-center">
                            <InputLabel  style={{fontWeight : 'bold'}} shrink>{ t('common:mails.attachments') }</InputLabel>
                            <span style={{height : '25px'}}/>
                        </div>
                        <div style={{flexDirection : 'column', display : 'flex'}}>
                            {
                                props.annexes.length > 0 && props.annexes.map((annexe, index) => {
                                    if((annexe.type === 'note' && viewNotes) ||  annexe.type !== 'note'){
                                        return (
                                            <div
                                                style={{flexDirection : 'row', display : 'flex', justifyContent : 'flex-start', alignItems : 'flex-start'}}
                                                key={'annexes-' + props.field.index + '-' + index}
                                                onClick={(e) => handleToggleAnnexe(e, annexe.hash)}
                                                >
                                                <ColorCheckbox
                                                    checked={attachments.includes(annexe.hash)}
                                                    />
                                                <div className={classes.attItemsContainer}>
                                                    <div className={classes.attItem}>
                                                        <div className={classes.attIconType}>
                                                            {annexe.type === 'note' && viewNotes && props.renderAnnexeIconPreview(annexe)}
                                                            {annexe.type !== 'note' && props.renderAnnexeIconPreview(annexe)}
                                                        </div>

                                                        <div className={classes.attAnnexName} style={{marginRight : '10px'}}>
                                                            { annexe.type === 'note' && viewNotes &&
                                                            <span>{annexe.name}</span>
                                                            }

                                                            { annexe.type !== 'note' &&
                                                            <span>{annexe.name}.{annexe.type}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else return null;
                                })
                            }

                            {
                                props.customAnnexe !== null &&
                                <div
                                    key={'annexes-' + props.field.index + '-' + (props.annexes.length + 1)}
                                    onClick={(e) => handleToggleCustomAnnexe(e, props.customAnnexe)}
                                    style={{flexDirection : 'row', display : 'flex', justifyContent : 'flex-start', alignItems : 'flex-start'}}
                                    >
                                    <ColorCheckbox
                                        checked={attachment === props.customAnnexe}
                                        color={colors.main}/>
                                    <div className={classes.attItemsContainer}>
                                        <div className={classes.attItem}>
                                            <div className={classes.attIconType}>
                                                <FontAwesomeIcon
                                                    icon={faFilePdf}
                                                    className={classes.faIconSize}
                                                />
                                            </div>

                                            <div className={classes.attAnnexName} style={{marginRight : '10px'}}>
                                                <span>{ t('common:smail.template-9002-content') }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                }
            </Content>
            <div className={classes.modalFooter}>
                <div className="popup_wrapper_content justify_content_space">
                    <button
                        className="button-exit"
                        onClick={props.close}
                    >
                        {t("common:global.cancel")}
                    </button>
                    <button className="button float_right" onClick={handlePressValidate}>{ t('common:global.send') }</button>
                </div>
            </div>
        </Dialog>
    );
}

const FormatSMailComponent = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [text, setText] = useState(props.field.value);
    const [open, setOpen] = useState(false);
    const [customAnnexe, setCustomAnnexe] = useState(null);
    const [templateOn, setTemplateOn] = useState(props.field?.options?.template || "");
    const [auto, setAuto] = useState(props.field?.options?.auto || "N");
    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setText(!!props.field.value ? props.field.value : "");
    },[props.field.value])


    const handleEditValue = (value, field, index) => {
        setText(value);
        props.handleEditValue(value, field, index)
    }

    const handleOpenModalSmail = () => {
        if(templateOn !== "" && templateOn == "9002"){
            generateCustomAnnexeFromTemplate9002().then((result) => {
                let reader = new FileReader();
                reader.readAsDataURL(result);
                reader.onloadend = function() {
                    let base64data = reader.result;
                    setCustomAnnexe(base64data);
                    setOpen(true);
                }
            });
        }
        else if(templateOn !== "" && templateOn == "2001"){
            generateCustomAnnexeFromTemplate2001().then((result) => {
                let reader = new FileReader();
                reader.readAsDataURL(result);
                reader.onloadend = function() {
                    let base64data = reader.result;
                    setCustomAnnexe(base64data);
                    setOpen(true);
                }
            });
        }
        else {
            setCustomAnnexe(null);
            setOpen(true);
        }
    }

    const generateCustomAnnexeFromTemplate9002 = async () => {
        return Data9002PrintableGetURL(props);
    }

    const generateCustomAnnexeFromTemplate2001 = async () => {
        return Data2001PrintableGetURL(props);
    }

    const handleCloseModalSmail = () => {
        setOpen(false);
    }

    return (
        <div className={classes.mailContainer}>
                <TextField
                    onChange={(event) => handleEditValue(event.target.value, props.field, props.index)}
                    id="standard-full-width"
                    type="text"
                    helperText=""
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className={`${classes.fieldRight} testeBorder`}
                    value={text}
                    disabled={disabled}
                    InputProps={{ disableUnderline: true }}
                />
            {
                typeof text !== 'undefined' &&  text !== '' &&
                <Tooltip placement="top" title={auto === 'N' ? t('fields.format-choice-smail') : t('mails.auto-mail')}>
                    <div className={auto === 'N' ? classes.mailButton : classes.inactiveBtn} onClick={() => {if (auto === 'N') handleOpenModalSmail()}}>
                        <span className="material-icons md-small white">forward_to_inbox</span>
                    </div>
                </Tooltip>
            }

            {
                open &&
                <SmailModal {...props} open={open} close={handleCloseModalSmail} options={props.field.options} value={text} customAnnexe={customAnnexe}/>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        main : state?.user || null,
        user : state?.user?.user || null,
        user2 : state?.user || null,
        profil : state?.user?.profil || null,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptSendMailSimple: (payload, userhash) => dispatch(postSendMailV3Request(payload,userhash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSMailComponent);





