import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import {confirmAlert} from "react-confirm-alert";
import colors from "../../assets/themes/Colors";
import useLongPress from "../../utils/useLongPress";
import {TextField} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input_add_item:{
        flexDirection: 'row',
        display: 'flex',
        border: "solid #E3E3E3 1px",
        borderRadius: "6px",
        padding: "5px 15px",
        width: '20%',
        minHeight: '35px',
        color: colors.unselected,
        "@media (max-width: 640px)": {
            minHeight: 30,
            padding: '0 10px',
            width: '35%'
        }
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        fontWeight: '500',
        borderRadius: '5px',
        padding: '0 10px',
        backgroundColor: colors.greyLighter,
        fontSize: '14px',
        flexGrow: 1,
        border: 'none',
        marginLeft: 10,
    },
    multTabMainContainer: {
        width : '100%',
        display: 'flex',
        minHeight: '42px',
        "@media (max-width: 640px)": {
            minHeight: 30
        }
    },
    multTableAdd: {
        width : '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    multTableItem: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedItem,
        color: colors.white,
        padding: "0 10px",
        gap: 5,
    },
    multTabSelected: {
        // cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        minHeight: '42px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        padding: "0 10px 1px",
        gap: 5,
        backgroundColor: colors.selectedItem,
        color: colors.white,
        "@media (max-width: 640px)": {
            minHeight: 30
        }
    },
    multTableItemText: {
        // color: colors.white,
        // fontWeight: 'bold',
    },
    multTableItemValue: {
        color: colors.white
    },
    multTableDelButton: {
        width : 18,
        height : 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid',
        // borderColor: colors.white,
        // color: colors.selectedMain,
        borderRadius: 50,
        fontSize: 16,
        fontWeight: 'bold',
    },
    multTableDelButtonSel: {
        width : 18,
        height : 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid',
        borderRadius: 50,
        fontSize: 16,
        fontWeight: 'bold',
    },
    multTableItemSearchContainer: {
        flexDirection: 'row',
        // width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        // padding: "5px 15px",
        gap: 5,
        alignItems: 'center',
    },
    singTableItemPresetOpen: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        width: '100%',
    },
    multTableItemSearchContainerOpen: {
        // width: '100%',
        display: 'flex',
        borderRadius: "6px",
        // marginTop: 10,
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    multTableUnselText:{
        // width: '30%',
        color: colors.white,
        //padding: '0 0 0 15px',
    },
    multTableSelText:{
        // width: '30%',
        color: colors.white,
        //padding: '0 0 0 15px',
    },
    unselectedTabItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        borderRadius: "6px",
        color: colors.white,
        backgroundColor: colors.unselectedItem,
        padding: "0 10px 1px 10px",
        gap: 5,
        minWidth: '70px',
        minHeight: '42px',
        justifyContent: 'space-between',
        "@media (max-width: 640px)": {
            minHeight: 30
        }
    },
    multTableText:{
        flexGrow: 1,
        textAlign: 'right',
        padding: '0 15px 0 0',
        border: 'none',
    },
    endButtonContainer:{
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "6px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'flex',
        color: 'white',
        marginLeft: 'auto'
    },
    inputField: {
        width: '80px',
        color: `${colors.white}!important`,
        backgroundColor: 'inherit',
        border: 'none',
    },
    addInputField: {
        // width: '80px',
        color: colors.unselected,
        border: 'none',
    },
    touchBox: {
        // border : '1px solid red',
        width: '20px',
        height: '30px',
        display: 'inline-block',
        flex: '1 1 auto'
    },
}));


const FormatMultipleSelectTableComponent = (props) => {

    //console.log(props);

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);
    const [selected, setSelected] = useState(props.field.value.length > 0 ? props.field.value : []);
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [open, setOpen] = useState(typeof props.expand === 'undefined' ? true: props.expand);
    const [preselOrignal , setPreselOriginal] = useState(typeof props.field.originalPresel !== 'undefined' ? props.field.originalPresel : []);
    const [value, setValue] = useState('');
    const [focus, setFocus] = useState('') // définit l'item cliqué

    //variables pour le longpress event
    const [editMode, setEditMode] = useState("")
    const [editedValue, setEditedValue] = useState("")

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[props.disabled]);

    const presels =  typeof props.field.originalPresel !== 'undefined' ? [...props.field.originalPresel] : [];
    let allItems = [...presels]

    const isInSelected = (value) => {
        let isThere = false
        if (selected.length > 0) selected.map(select => {
            if(select.text == value) isThere = true
        })
        return isThere
    }

    const isInPresels = (item) => {
        return presels.includes(item)
    }

    const handleAddValue = (value) => {
        if (!allItems.includes(value)) allItems.push(value)
        if(value !== ""){
            if(!isInSelected(value)){
                // setSelected([...selected, {"text" : value , "value" : ""}]);
                props.field.options.presel.push(value);
                // props.handleChangeInputTable({"text" : value , "value" : ""}, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
                setFocus(value)
            }
            else setFocus(value)
            setNewValue('');
        }
    }

    const handleDeletePresel = (value, indexation) => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.confirm-sur'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => {
                        let tmp = selected.filter(item => item.text !== value.text);
                        setSelected(tmp);
                        props.field.options.presel = props.field.options.presel.filter(item => item !== value.text);
                        props.handleUpdateInputTable(props.field.options.presel, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
                    }
                },
                {
                    label: t('global.no'),
                },
            ]
        });
    }

    const handleDeleteValue = (object) => {
        if (isInSelected(object.text)){
            let tempSel = [...selected];
            let tmp = tempSel.filter(item => item.text !== object.text);
            setSelected(tmp);
            props.handleDeleteInputTable(object.text, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }
    }

    const indexInSelected = (value) => {
        if(isInSelected(value)) {return selected.findIndex((item, index) => {
            return item.text.trim() === value.trim()
        })}
        else return -1
    }

    const handleSelect = (item) => {
        if (isInSelected(item)) {
            setFocus("")
            let tempSel = [...selected]
            setSelected(tempSel.filter(label => label.text !== item))
            if(!isInPresels(item)) props.field.options.presel = props.field.options.presel.filter(label => label !== item);
            props.handleUpdateInputTable(props.field.options.presel, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }
        else {
            // setSelected([...selected, {"text" : item , "value" : ""}]);
            // if(!isInPresels(item)) props.field.options.presel.push(item);
            // props.handleChangeInputTable({"text" : item , "value" : ""}, props.field.options.presel.length - 1, props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
            setFocus(item)
        }
    }
    const handleEditSelectValue = (event, item, indexation) => {
        if (event.target.value.trim !== ''){
            if(!isInSelected(focus)){
                setSelected([...selected, {"text" : focus, "value" : event.target.value}]);
            }
            else {
                selected[indexInSelected(focus)].value = event.target.value;
                setSelected(selected);
            }
            props.handleChangeInputTable({"text": focus, "value": event.target.value}, indexation, props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }
        if (event.target.value.trim == ''){
            handleUnselect()
        }
    }
    const handleUnselect = () => {
        if (isInSelected(focus)){
            let tempSel = [...selected];
            let tmp = tempSel.filter(select => select.text !== focus);
            setSelected(tmp);
            props.handleDeleteInputTable(focus, props.field.options.presel.length - 1, props.field,props.index, props.field.options.multiselect, props.field.options.mandatory);
        }
    }

    const handleEditValue = (label, old, value) => {
        let newPresel = {"text" : label , "value" : value}

        //delete old
        let tempSel = [...selected]
        let tmp = tempSel.filter(item => item.text !== old)
        console.log("edit", tmp)
        props.field.options.presel = props.field.options.presel.filter(item => item !== old);
        props.handleUpdateInputTable(props.field.options.presel, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        setSelected(tmp)

        //add new
        if(!isInPresels(label)) props.field.options.presel.push(label);
        props.handleChangeInputTable(newPresel, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        setFocus(label)
        setSelected([...selected, {"text" : label , "value" : value}])

        setEditMode("")
        setEditedValue("")
    }

    //long press events
    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const onLongPress = (item) => {
        // console.log("long press triggered", item.target);
        // setEditMode(item.target.innerText)
        // setEditedValue(item.target.innerText)
    };
    const onPress = () => {
        // console.log("click triggered")
    };

    const longPressEvent = useLongPress(onLongPress, onPress, defaultOptions)

    return (
        <div className={classes.multTabMainContainer} onClick={() => {
            if(!open && selected.length === 0 && !disabled) setOpen(true);
        }}>

            {/** liste des selects **/
                !open && selected.length > 0 &&
                <div className={classes.multTableItemSearchContainerOpen} onClick={() => {if(!open && !disabled) setOpen(true)}}>
                    {
                        selected.sort((a, b) => {
                            if(a.text !== undefined) return (a.text).localeCompare(b.text)
                            else return a.localeCompare(b)
                        })
                            .map((value, index) => {
                                return (
                                    <div key={index} className={classes.multTabSelected}>
                                        <span className={classes.multTableItemText} onClick={() => { if(!disabled) {setFocus(value.text); setOpen(true);}}}>{value.text} </span>
                                        {value.value !== ""? <span className={classes.multTableItemValue} onClick={(event) => {event.preventDefault(); event.stopPropagation(); if(!disabled) {setFocus(value.text); setOpen(true);}}}>: {value.value}</span> : null}
                                        { !disabled &&
                                        <span className="p3 cursor" onClick={(event) => {event.preventDefault(); event.stopPropagation(); !isInPresels(value.text)? handleDeletePresel(value) : handleDeleteValue(value)}}>
                                            <span className={`${classes.multTableDelButtonSel} material-icons`}>{!isInPresels(value.text)? 'delete' : 'close'}</span>
                                        </span>
                                        }
                                    </div>
                                )
                            })
                    }
                </div>
            }

            {
            open && typeof props.field.options.presel !== 'undefined' &&

            <div className={classes.multTableItemSearchContainer} >

                    {!disabled && props.field.options.editable &&
                    <div className={classes.input_add_item}>
                        <Input
                            disabled={disabled}
                            autoComplete='off'
                            id="standard-full-width"
                            //label="Label"
                            style={{ margin: 0 }}
                            placeholder={t('common:multiple.add-item')}
                            //helpertext="Full width!"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            disableUnderline={true}
                            className={classes.addInputField}
                            value={newValue}
                            onKeyPress={(e) => { if (e.key === 'Enter') {
                                // handleAddValue(newValue, props.field, props.index)}}}
                                handleAddValue(newValue)}}}
                            onChange={(event) => setNewValue(event.target.value)}
                        />
                    </div>}
                    {
                        props.field.format.includes('table') && props.field.format.includes('multi') &&
                        props.field.options.presel.filter(item => {
                            if (!value) return true
                            if (item.toLowerCase().includes(value.toLowerCase())) {
                                return true
                            }
                        })
                        .sort((a, b) => a.localeCompare(b))
                        // .sort((a, b) => {
                        //     console.log("compare",a);
                        //     console.log("compare B",b);
                        //     let aN = isInSelected(a)? 1 : 2;
                        //     let bN = isInSelected(b)? 1 : 2;
                        //     // aX.localeCompare(bX);
                        //     return (focus != "")? aN - bN : 0
                        // })
                        // .filter(item => {
                        //     if(isInSelected(item)) return true
                        // })
                        .map((item, indexation) => {
                            let find = indexInSelected(item);
                            return (
                                <div key={'div' + props.field.id + '-' +indexation}
                                    className={(isInSelected(item)) ? classes.multTabSelected :  classes.unselectedTabItem}
                                    onClick={(event) => {
                                    event.preventDefault(); event.stopPropagation();
                                    if(!isInSelected(item) && !disabled){
                                        handleSelect(item);
                                    }
                                    else setFocus(item)}}>
                                    {// longpress pour éditer l'ajout
                                    editMode === item ?
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id="standard-full-width"
                                            placeholder={t('common:multiple.add-item')}
                                            fullWidth={false}
                                            inputlabelprops={{
                                                shrink: true,
                                            }}
                                            inputProps={{color: `${colors.selectedMain}!important`}}
                                            // helperText="edit"
                                            value={editedValue}
                                            disableunderline={true}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { handleEditValue(editedValue, item, typeof selected[find] !== "undefined" ? selected[find].value : "")}}}
                                            onChange={(event) => setEditedValue(event.target.value)}
                                        />
                                          :

                                        // <Tooltip title={"Long press to edit"} placement="top" disableHoverListener={isInPresels(item)}>
                                            <span className={ [isInSelected(item)? classes.multTableSelText : classes.multTableUnselText, classes.multTableItemText]} {...longPressEvent}>{item}</span>
                                        // </Tooltip>
                                    }

                                    {(isInSelected(item) && editMode === "") || item === focus ?
                                        <span>:</span> &&
                                            <Input
                                                autoFocus={focus===item? true:false}
                                                disabled={disabled}
                                                autoComplete='off'
                                                name={'field-' + item + '-' + indexation}
                                                onWheel={ event => event.currentTarget.blur() }
                                                value={typeof selected[find] !== "undefined" ? selected[find].value : ""}
                                                className={classes.inputField}
                                                // InputProps={{color: 'white'}}
                                                style={{marginLeft: '5px', }}
                                                disableUnderline
                                                onKeyDown={(e)=> {if(e.key === "Backspace" || e.key === "Delete" || e.key === "Space"){
                                                    setTimeout(()=>{
                                                        if(e.target.value === '') handleUnselect()
                                                    }, 200)
                                                    // handleUnselect()
                                                    }
                                                    // if(e.target.value === ''){
                                                    //     if(e.key == "Backspace" || e.key === "Delete"){handleUnselect()}
                                                    // }
                                                    if(e.key === "Enter"){setFocus(''); setOpen(false)}
                                                }}
                                                onChange={(event, item, indexation) => handleEditSelectValue(event, item, indexation)}
                                            />
                                        :
                                        <div className={classes.touchBox} onClick={(event) => {event.preventDefault(); event.stopPropagation(); if (!disabled){handleSelect(item)}  }}/>}

                                        {editMode !== item ?
                                        !disabled && (preselOrignal.length > 0 || preselOrignal.length === 0) && (!isInPresels(item) || isInSelected(item)) &&
                                        <div className="p3 cursor" onClick={(event) => {event.preventDefault(); event.stopPropagation(); !isInPresels(item)? handleDeletePresel(selected[find]) : handleDeleteValue(selected[find])}}>
                                            <div className={`${classes.multTableDelButton} material-icons`}>{!isInPresels(item)? 'delete' : 'close'}</div>
                                        </div>
                                        :
                                        <Tooltip title={"Confirm change"} placement="top" >
                                            <span className={`${classes.singTableDelButton} material-icons`} onClick={() => handleEditValue(editedValue, item)}>done</span>
                                        </Tooltip>
                                    }
                                </div>
                        )})
                            }</div>
            }

            {
                typeof props.field.options.presel !== 'undefined' && !disabled &&
                <div className={classes.endButtonContainer} onClick={(event) => setOpen(!open)}>
                    <span id="icon-delete-playlist" className={`material-icons md-small`}>
                        {open? 'expand_less' : props.field.options.editable? 'playlist_add':'playlist_add_check'}
                    </span>
                </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatMultipleSelectTableComponent);
