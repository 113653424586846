import {all} from 'redux-saga/effects';
import UserSagas from './user';
import FieldSagas from "./field";
import AnnexeSagas from "./annexe";
import PlugcodeSagas from "./plugcode";
import ExternalSagas from "./external";
import TranslateSagas from "./translate";
import UtilsSagas from "./utils";
import ExtDataSagas from "./extdata";
import GallerySagas from "./gallery";

// combine all sagas
export default function* rootSaga() {
    yield all([
        ...UserSagas,
        ...FieldSagas,
        ...PlugcodeSagas,
        ...AnnexeSagas,
        ...ExternalSagas,
        ...TranslateSagas,
        ...UtilsSagas,
        ...ExtDataSagas,
        ...GallerySagas,
    ]);
}
