import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

import {Document, Image, Link, Page, pdf, PDFDownloadLink, StyleSheet, Text, View, BlobProvider} from '@react-pdf/renderer';
import Images from "../../assets/themes/Images";

import ApplicationConfig from "../../config/ApplicationConfig";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import axios from "axios";
import moment from "moment";
import {formatTextTypes} from "../../utils/Compatibility";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {blobToBase64, convertersNumberToAlpha} from "../../utils/utils";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const environment = getCurrentEnvironnement();


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        margin : 50
    },
    parent: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    child: {
        width: '20%',
        margin: '1%',
        aspectRatio: 1,
        maxHeight : "100px",
        alignItems : 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor : 'grey'
    },
    signature: {
        alignItems : 'center',
        justifyContent: 'center',
        maxHeight : '100px',
    },
    maxChild: {
        width: '69%',
        margin: '1%',
        borderWidth: 1,
        borderColor : 'grey'
    },
    minChild: {
        width: '5%',
        margin: '1%',
        alignItems : 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor : 'grey'
    },
    sectionHeader: {
        margin: 0,
    },
    section: {
        margin: 10,
        flexGrow: 0,
    },
    sectionAnnexes : {
        margin: 10,
        flexGrow: 0,
        paddingTop: 40,
        paddingBottom: 40,
    },
    viewer: {
        paddingTop: 10,
        paddingBottom: 0,
    },
    paragraph: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1' /* Let it fill the entire space horizontally */
    },
    paragraphText : {
        alignItems: 'center',
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1' /* Let it fill the entire space horizontally */,
        marginBottom: 5
    },
    paragraphMain: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        margin : 5
    },
    paragraphPng: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'flex-start',
        margin : 5
    },
    paragraphImage: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'center',
        margin : 5
    },
    paragraphTextAnnexes: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'flex-start',
        margin : 5
    },

    viewLeft: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */
    },
    viewRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */
    },
    viewFields: {
        width : '100%'
    },
    viewFieldsSub: {
        marginTop : 10,
        width : '100%',
        marginBottom : 10,
    },
    viewFieldsLeft: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        justifyContent: 'flex-start',
        flexDirection: "row",
    },
    viewFieldsLeftNoFlex: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'flex-start',
        flexDirection: "row",
    },
    viewFieldsCenter: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        justifyContent: 'center',
        flexDirection: "row",
    },
    viewFieldsRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '2' /* Let it fill the entire space horizontally */
    },
    viewTextRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        textAlign: 'right'
    },
    text: {
        width: "auto",
        fontWeight : 500,
        fontSize: 12,
    },
    textLabel:{
        width: "auto",
        fontWeight : 500,
        fontSize: 12,
    },
    textBlue: {
        width: "auto",
        fontWeight : 500,
        fontSize: 12,
        color : 'blue'
    },
    textInfos: {
        textAlign : 'right',
        width: "auto",
        fontWeight : 100,
        fontSize: 6,
    },
    textUnder: {
        width: "auto",
        fontWeight : 500,
        fontSize: 12,
        textDecoration: 'underline',
    },
    textThin: {
        width: "auto",
        fontWeight : 300,
        fontSize: 10,
        color : 'blue'
    },
    textThinBlue: {
        width: "auto",
        fontWeight : 300,
        fontSize: 10,
        color : 'blue'
    },
    textThinSmall:{
        width: "auto",
        fontWeight : 200,
        fontSize: 8,
    },
    textLink: {
        width: "auto",
        color : "green",
        fontWeight : 300,
        fontSize: 10,
    },
    image: {
        width: "50%",
    },
    icon: {
        width: "30",
        height: "30",
    },
    iconImg: {
        width: "200",
    },
    title:{
        marginBottom : 10
    },
    footer: {
        position: 'absolute',
        width : '100%',
        bottom: 10,
        left: 0,
        right: 0,
        justifyContent: 'center',
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    footerText: {
        width : '60%',
        justifyContent: 'center',
        color: 'grey',
        textAlign : 'center',
        fontSize: 10,
    },
    footerTextLeft: {
        width : '20%',
        left : 10,
        fontSize: 10,
        justifyContent: 'center',
        textAlign : 'left',
        color: 'grey',
    },
    footerTextRight: {
        right : 10,
        width : '20%',
        fontSize: 10,
        justifyContent: 'center',
        textAlign : 'right',
        color: 'grey',
    },
    header: {
        position: 'absolute',
        width : '100%',
        bottom: 0,
        top : 0,
        alignItems: 'center',
        left: 0,
        right: 0,
    },
    headerText: {
        textAlign: 'center',
        color: 'grey',
        fontSize: 10,
    },
    logo: {
        width: '186px'
    },
    logoCustom : {
        width : '100%'
    },
    textareaMemo : {
        border: 'none',
        resize: 'none',
        position: 'relative',
        margin: '5px',
    },
    divMemo : {
        padding: '5px',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        borderTop: '1px solid black',
    },
    tabText:{
        color: 'black',
        fontSize:10,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    tableCol: {
        width : "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    },
    tableCellBlue: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10,
        color : 'blue'
    }
});

const useStyles = makeStyles((theme) => ({
    pointer:{
        cursor: 'pointer'
    }
}));

//http://test.plugnotes.com/uploads/uswE1SjDMGLkg0820759001492675386/daeMEUjoGm8Go0402037001604567020/somename%20(1).pdf

//downloadUrl

const MyDoc = (props) => {

    let properties = props.props;
    let environnement = getCurrentEnvironnement();
    let profil = typeof properties.user.profil !== "undefined" ? properties.user.profil : null;
    let user = typeof properties.user.user !== "undefined" ? properties.user.user : null;
    let currentPlugnote = properties !== null && properties.plugnote !== null ? properties.plugnote : undefined;
    let userIndex = currentPlugnote !== null &&  typeof currentPlugnote !== "undefined" ?  currentPlugnote.userindex : null;
    let envIndex = currentPlugnote !== null &&  typeof currentPlugnote !== "undefined" ? environnement.envIndex : null;
    let lastUpdate = currentPlugnote !== null &&  typeof currentPlugnote !== "undefined" && typeof currentPlugnote.moddate !== "undefined"  ? moment(currentPlugnote.moddate).format('YY-MM-DD') : null;

    let model = properties.model;
    let modelName = model.label;
    let modelClef = model.clef;
    let creator = currentPlugnote !== null &&  typeof currentPlugnote !== "undefined" && currentPlugnote.externalowner !== null  ? currentPlugnote.username : null

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    let logo = user?.logo ? user?.logo : 'logoPng';
    let classLogo = logo === "logoPng" ? styles.logo : styles.logoCustom;
    let visible = logo !== "";
    let logoPath = Images[logo];

    return (
        <Document>
            <Page size="A4" style={styles.viewer} wrap>
                <View style={styles.section}>
                    {
                        properties.fields.map((field, index) => {
                            switch (field.format) {
                                case 'sign':
                                    if (typeof properties.signature !== "undefined" && typeof properties.index !== "undefined" && properties.index === field.id) {
                                    return (
                                        <View style={styles.signature}>
                                            <View>
                                                <Image src={properties.signature} style={styles.iconImg} />
                                            </View>
                                        </View>
                                    )
                                }
                                else{
                                    return <View/>;
                                }
                                default:
                                    return <View/>;
                            }
                        })
                    }
                </View>
            </Page>
        </Document>
    )
}


function Data9099Printable(props) {

    const { t, i18n } = useTranslation(['common', 'models']);
    const classes = useStyles();

    if(props.signature !== undefined){
        return (
            <BlobProvider document={<MyDoc props={props}/>}>
                {({blob, url, loading, error}) => {
                    //console.log(error);
                    if(blob !== null){
                        props.close();

                        let reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function() {
                            let file = new File([blob], props.filename+'.pdf');
                            file.base64data = reader.result;
                            props.onChangeHandlerDrop([file]);
                        }
                        // Do whatever you need with blob here
                    }
                    return '';
                }}
            </BlobProvider>
        )
    }


    return (
        <PDFDownloadLink document={<MyDoc props={props}/>} className={classes.pointer} fileName={props.filename+'.pdf'} style={{textDecoration : 'none', color : 'black'}}>
            {({ blob, url, loading, error }) =>
                loading ? <div style={{display : 'flex'}}> <span className="material-icons md-25 "> sync_alt </span><div className={classes.pointer}>{t('common:global.print-sync') }</div></div> :  <div style={{display : 'flex'}}> <span className="material-icons md-25 "> picture_as_pdf </span><div className={classes.pointer}>{t('common:global.print') }</div></div>
            }
        </PDFDownloadLink>
    );
}

export default Data9099Printable;

