import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel, getPlugnote, getUserMail, setUserMail} from "../../utils/Common";
import Input from "@material-ui/core/Input";
import { ColorCheckbox } from "../custom/CustomComponents";
import colors from "../../assets/themes/Colors";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        minWidth: '50%',
        maxWidth : '100%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    checkboxCtn: {
        cursor: 'pointer',
        padding: '10px 5px',
    }
}));

const ModalConfirmComponent = (props) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const { t } = useTranslation('common');
    const [email, setEmail] = useState(props?.email || "");
    const [copy, setCopy] = useState(props.copy);
    const [attachments, setAttachments] = useState(copy)
    const viewCopy = typeof props.external === 'undefined'

    const environment = getCurrentEnvironnement();

    const handleCloseModal = () => {
        props.close();
    };

    useEffect(() => {
        let mail = getUserMail()
        //console.log('mail', mail);
        if (mail !== null) {
            setEmail(mail)
            props.handleChangeEmail(mail)
        }
    }, [props.open])


    const isEmail = (value) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/g
        return emailRegex.test(value)
        // return /(.)+@(.)+/.test(value);
    };

    useEffect(() => {

    }, [props.open]);

    const handleChangeSendCopy = () => {
        setCopy(previous => !previous);
        setAttachments(previous => !previous)
        props.handleChangeSendCopy(!copy)
    }

    const handleChangeSendAttachments = () => {
        setAttachments(!attachments)
    }

    const handleSendFiche = () => {
        setUserMail(email)
        props.handleSaveCreatePlugnoteConfirm()
    }

    return (
        <Modal
            key="send-plugnote-modal"
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="">
                        <span>{t('common:external.insert-email-pf-code-complete')}</span>
                        <br/>
                        <br/>
                        <Input
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                                props.handleChangeEmail(event.target.value)
                            }}
                            id="standard-full-width-field"
                            disableUnderline={true}
                            placeholder={t('common:external.email')}
                            label="Label"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            className="input"
                        />
                        {
                            viewCopy &&
                            <div onClick={handleChangeSendCopy} className={classes.checkboxCtn}>
                                <ColorCheckbox color={colors.selectedMain} size={25} checked={copy}/>
                                { t('common:external.insert-attachments-copy') }
                            </div>
                        }


                        <button className="button" onClick={handleSendFiche} disabled={!isEmail(email)} style={isEmail(email)? null : {backgroundColor: colors.greyLighter, color: colors.darkSilver, cursor: "not-allowed"}}>{t('common:multiple.send')}</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmComponent)
