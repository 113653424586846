import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import {convertersAlphaToNumber, isJsonStringArray} from "../../utils/utils";
import ModalListExtDataComponent from "../modals/extdata/ModalListExtDataComponent";
import {postExtDataReset} from "../../actions/extdata";
import ModalCreatePlugnoteComponent from "../modals/ModalCreatePlugnoteComponent";

import colors from "../../assets/themes/Colors";
import * as moment from "moment";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import _, {isString} from "lodash";
import Immutable from "seamless-immutable";
import Plugforms from "../plugforms/Plugforms";
import {isPossiblePhoneNumber} from "react-phone-number-input";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        width : '100%',
        display: 'flex',
        alignItems: 'center',
    },
    searchBtn: {
        fontSize: 15,
        minWidth: 60,
        position: 'relative'
    },
    unavailable: {
        position: 'absolute',
        color: colors.mandatory,
        fontSize: 15,
        right: '4px',
        top: '2px'
    },
    helperText: {
        transform: 'scale(1) translateY(-20px)',
        position: 'absolute',
        left: '0',
        top: 0,
        backgroundColor: colors.white
    },
}));

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

const FormatExtDatanComponent = (props) => {

    //console.log("FormatExtDatanComponent",props);

    const classes = useStyles();
    const environment = getCurrentEnvironnement();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [hideSearch, setHideSearch] = React.useState(props?.model?.authoriseExternalDbInPf === undefined ? false  : !props?.model?.authoriseExternalDbInPf);
    const [owner, setOwner] = React.useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [open, setOpen] = React.useState(false);
    const [openModalCreatePlugnote, setOpenModalCreatePlugnote] = React.useState(false);
    const [view, setView] = React.useState(false);
    const [ref, setRef] = React.useState(props.field.options.ref);
    const [ownerId, setOwnerId] = React.useState(props.ownerId);
    const [column, setColumn] = React.useState(props.field.options.column);
    const [columns, setColumns] = React.useState(props.field.options.columns);
    const [plugform, setPlugform] = React.useState(props.field.options.plugform);
    const [dest, setDest] = React.useState(props.field.options.dest);
    const [options, setOptions] = React.useState(props.field.options);
    const [value, setValue] = React.useState(!!props.field.value ? props.field.value : "");
    const [valueToModal, setValueToModal] = React.useState(null);
    const [criteria, setCriteria] = useState("= xx")


    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setValue(!!props.field.value ? props.field.value : "");
    },[props.field.value])

    useEffect(()=>{
        switch (props.field.options.query) {
            case 'CONTAINS':
                setCriteria("..xx..")
                break;
            case 'START':
                setCriteria("xx..")
                break;
            case 'MULTIPLE':
                setCriteria("xxx")
                break;
            default: setCriteria("= xx")
                break;
        }
    }, [props.field.options.query])

    const handleOpenSearchModel = () => {
        props.attemptGetDataFromAnotherBaseByClientReset();
        setOpen(true);
    }

    const handleCloseModalCreatePlugnote = () => {
        setOpenModalCreatePlugnote(false);
    }

    const handleSelectData = (object) => {

        trackPromise(
            axios
                .get(
                    ApplicationConfig.apiUrl + 'modeluniquebyclefandowner?clef=' + plugform + '&id=' + ownerId + '&umh=' + null ,
                    {
                        crossdomain: true,
                    }
                )
                .then((response) => {
                    if (response.data.result) {
                        let model = response.data.data
                        const labels = Object.keys(model).filter((key) => {
                            const regex = /field[0-9]+label/gi;
                            return regex.test(key);
                        });

                        let labelChamps = labels.map((item) => {
                            return model[item];
                        });

                        let listFields = [];

                        labelChamps.forEach((field, index) => {

                            if (field) {

                                const fieldIndex = index + 1;
                                let value = "";
                                let format = {};
                                let visuelFormat = {};
                                let show = false;
                                let event = "";
                                let encodable = false;

                                let options = [];

                                let originalOptions = [];

                                let sharedPlugform = false;

                                if (model && model[`field${fieldIndex}formatoptions`]) {
                                    options = typeof model[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(model[`field${fieldIndex}formatoptions`])  : Object.create(JSON.parse(model[`field${fieldIndex}formatoptions`]));
                                    originalOptions =  typeof model[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(model[`field${fieldIndex}formatoptions`]) : Object.create(JSON.parse(model[`field${fieldIndex}formatoptions`]));
                                }

                                // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
                                if (!_.isEmpty(model)) {
                                    format = model[`field${fieldIndex}format`];
                                    encodable = model[`field${fieldIndex}encodable`] === 1;
                                    visuelFormat = model[`field${fieldIndex}format`];
                                    sharedPlugform = typeof model[`plugformModelIsSharedField${fieldIndex}`] !== "undefined" ? model[`plugformModelIsSharedField${fieldIndex}`] : false;
                                }

                                // Si c'est une préselection on initialise la valeur à un tableau vide
                                if ( options != null && (options.presel || format === "list")) {
                                    value = [];
                                }

                                // On vérifie si le champ est une préselection avec les options
                                if (options != null && options.presel && format === 'select') {

                                    if (Array.isArray(value) && Immutable.isImmutable(value)) {
                                        value = Immutable.asMutable(value);
                                    }

                                    // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                                    if (!Array.isArray(value)) {
                                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                            value = JSON.parse(value);
                                        } else {
                                            if (value && value != null) {
                                                value = [value];
                                            } else {
                                                value = [];
                                            }
                                        }
                                    }

                                    switch (options.multiselect) {
                                        case true :
                                            if (options.editable) {
                                                format = "free-multi-select";
                                            } else {
                                                format = "multi-select";
                                            }
                                            break;
                                        default:
                                            if (options.editable) {
                                                format = "free-single-select";
                                            } else {
                                                format = "single-select";
                                            }
                                    }

                                    options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                                }
                                else if (options != null && options.presel && format === 'table') {

                                    if (Array.isArray(value) && Immutable.isImmutable(value)) {
                                        value = Immutable.asMutable(value);
                                    }

                                    // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                                    if (!Array.isArray(value)) {
                                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                            value = JSON.parse(value);
                                        } else {
                                            if (value && value != null) {
                                                value = [value];
                                            } else {
                                                value = [];
                                            }
                                        }
                                    }

                                    switch (options.multiselect) {
                                        case true :
                                            if (options.editable) {
                                                format = "free-multi-table";
                                            } else {
                                                format = "multi-table";
                                            }
                                            break;
                                        default:
                                            if (options.editable) {
                                                format = "free-single-table";
                                            } else {
                                                format = "single-table";
                                            }
                                    }

                                    options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                                }
                                else {

                                    if (format === 'select') {
                                        format = 'multi-select'
                                        options = [];

                                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                            value = JSON.parse(value);
                                        } else {
                                            if (value && value != null) {
                                                value = [value];
                                            } else {
                                                value = [];
                                            }
                                        }
                                    }

                                    if (format === 'table') {
                                        format = 'multi-table'
                                        options = [];

                                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                            value = JSON.parse(value);
                                        } else {
                                            if (value && value != null) {
                                                value = [value];
                                            } else {
                                                value = [];
                                            }
                                        }
                                    }
                                }

                                if (format === "now" && !value) {
                                    value = moment().format("YYYY-MM-DD HH:mm:ss");
                                }

                                if (format === "tablemultin" && value.length === 0) {
                                    value = {"value" : "", "list" : []};
                                }

                                if (format === "smailvalidmulti" && value.length === 0) {
                                    value = {"value" : "", "list" : []};
                                }

                                let originalPresel = undefined;
                                if(typeof originalOptions.presel !== "undefined" && originalOptions.presel.length > 0){
                                    if(Array.isArray(originalOptions.presel)){
                                        originalPresel = originalOptions.presel
                                    }else{
                                        originalPresel = originalOptions.presel.split(';')
                                    }
                                }

                                if(isJsonStringArray(props.value)){
                                    let element = props.value.find((elm) => Number(elm.dest) === (fieldIndex));
                                    if(typeof element !== "undefined"){
                                        value = element.value;
                                    }

                                }

                                listFields.push({
                                    id: fieldIndex,
                                    title: model[`field${fieldIndex}label`],
                                    titleTranslate: model[`field${fieldIndex}label`],
                                    value: value,
                                    visuelValue : value,
                                    encodable,
                                    format,
                                    visuelFormat,
                                    options,
                                    sharedPlugform,
                                    originalPresel,
                                    mandatory: model[`field${fieldIndex}mandatory`],
                                    show,
                                    event : event
                                })
                            }
                        });

                        let jsonObjectToArray = Object.values(object)
                        let destinations = [];
                        let champs = dest.split('-');

                        champs.map((elm, index) => {
                            let field = elm.split(':');

                            if(field.length > 0 && field.length <= 3){

                                let dest = field[0];
                                let col = field[1];
                                let separator = typeof field[2] !== 'undefined' ?  field[2] : null;
                                let copyValue = col.split(';');
                                let value = ""

                                if(typeof listFields[(dest - 1)] !== "undefined"){
                                    if(listFields[(dest - 1)].format === "glocalis"){
                                        value = {
                                            latitude : "",
                                            longitude : "",
                                            address : ""
                                        }
                                    }else if(listFields[(dest - 1)].format === "tablemultin" || listFields[(dest - 1)].format === "smailvalidmulti"){
                                        value = {
                                            value : "",
                                            list : [],
                                        }
                                    }
                                    copyValue.map((item, index) => {
                                        let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                        let data = jsonObjectToArray[alphaNumIndexDest];
                                        if(typeof data !== "undefined"){
                                            let temp = data;
                                            if(temp !== "" && typeof temp !== "undefined"){
                                                if(temp === null){
                                                    temp = "";
                                                }

                                                if(temp != ""){

                                                    switch (listFields[(dest - 1)].format) {
                                                        case 'tablemultin' :
                                                        case 'smailvalidmulti' :
                                                            value.value = temp;
                                                            break;
                                                        case 'glocalis' :
                                                            value.address = value.address == "" ? value.address.concat(temp) : value.address.concat(' ' + temp) ;
                                                            break;
                                                        case 'localis' :
                                                            value = value == "" ? value.concat(temp) : value.concat(' ' + temp) ;
                                                            break;
                                                        default:
                                                            if (item == item.toUpperCase()) {
                                                                value = value == "" ? value.concat(temp) : value.concat(' ~ ' + '\n'+ temp);
                                                            }else{
                                                                value = value == "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                            }
                                                            break;

                                                    }

                                                    if(separator !== null){
                                                        switch (listFields[(dest - 1)].format) {
                                                            case 'phone':
                                                                if(isPossiblePhoneNumber(value) === false){
                                                                    const str = value;
                                                                    const char = str[0];
                                                                    if(char === "0"){
                                                                        value =  str.replace(char, separator);
                                                                    }else{
                                                                        value = separator + value ;
                                                                    }
                                                                }
                                                                break;
                                                            case 'numeric':
                                                                switch (separator) {
                                                                    case ",":
                                                                        value = value.replaceAll('.', '');
                                                                        value = value.replaceAll(separator, '.');
                                                                        break;
                                                                    case ".":
                                                                        value = value.replaceAll(',', '');
                                                                        value = value.replaceAll(separator, '.');
                                                                        break;
                                                                }
                                                                break;
                                                            case 'date':
                                                                value = moment(value, separator).format('YYYY-MM-DD');
                                                                break;
                                                            case 'glocalis':
                                                                let subSeparators = separator.split(',')
                                                                if(subSeparators.length){
                                                                    let sepLat = subSeparators[0];
                                                                    sepLat = sepLat.replaceAll('Lat=',"");
                                                                    let sepLng = subSeparators[1];
                                                                    sepLng = sepLng.replaceAll('Lnt=',"");
                                                                    let lat = jsonObjectToArray[convertersAlphaToNumber(sepLat) - 1];
                                                                    let lng = jsonObjectToArray[convertersAlphaToNumber(sepLng) - 1];
                                                                    value.latitude = lat;
                                                                    value.longitude = lng;
                                                                }
                                                                break;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    });


                                    if(listFields[(dest - 1)].format === "glocalis"){
                                        value = JSON.stringify(value);
                                    }

                                    destinations.push({
                                        "dest" : dest,
                                        "value" : value
                                    })
                                }
                            }
                        })

                        setValueToModal(destinations)
                        setOpenModalCreatePlugnote(true);
                        setOpen(false);
                    }
                })
                .catch((response) => {
                    console.error(
                        "Could not Download the Excel report from the backend.",
                        response
                    );
                })
        );
    }

    const titleFormat = `( ${ref} ) [ ${column} ] [ ${columns} ] [ ${plugform} ] [ ${dest} ]`
    return (
        <div className={classes.fieldRight}>

                <TextField
                    disabled={disabled}
                    onChange={(event) => props.handleEditValue(event.target.value, props.field, props.index)}
                    id="standard-full-width"
                    //label={field.title}
                    style={{ margin: 0 }}
                    type="text"
                    multiline={true}
                    //placeholder={t('fields.format-choice-placeholder-'+field.visuelFormat.toLowerCase())}
                    helperText={`in form ${props.field.options.plugform}` }
                    FormHelperTextProps={{className: classes.helperText}}
                    fullWidth
                    margin="normal"
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className={classes.fieldRight}
                    value={value}
                />
                { !hideSearch && !disabled &&
                <div className={classes.searchBtn}> {criteria}
                    <span id="icon-listForm" className={`material-icons md-25`} style={{color: colors.main}} onClick={(event) => handleOpenSearchModel()}>
                        search
                    </span>
                </div>
                }
                { (hideSearch || disabled) &&
                    <div className={classes.searchBtn} style={{color: colors.greyLight}} > {criteria}
                        <span id="icon-listForm" className={`material-icons md-25`} style={{color: colors.greyLight}} onClick={(event) => handleOpenSearchModel()}>
                            search
                        </span>
                        <span id="icon-listForm" className={`material-icons md-20 ${classes.unavailable}`} style={{color: colors.red}}>
                            close
                        </span>
                    </div>
                }

            {
                open &&  <ModalListExtDataComponent titlePerso={props.title} titleFormat={titleFormat} format="extdatan" open={open} close={(event) => setOpen(false)} options={options} owner={owner} value={value}  ownerId={ownerId} fields={props.fields} handleSelectData={handleSelectData} external={props.external}/>
            }
{/*
            {
                view && <ModalViewExtDataComponent open={view} close={(event) => setView(false)} owner={owner} options={options}  fields={props.fields}/>
            }*/}

            { openModalCreatePlugnote && <ModalCreatePlugnoteComponent open={openModalCreatePlugnote} close={handleCloseModalCreatePlugnote} plugform={plugform.toString()} value={valueToModal} owner={owner} ownerId={ownerId}  external={props.external}/> }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {

    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),

    //attemptGetPlugnoteByClefAndUserHashAndUserIndex: (clef,userhash,userindex, fieldId) => dispatch(getDataByClefUserIndexRequest(clef,userhash,userindex, fieldId)),
    //attemptGetPlugnoteByClefAndUserHashAndUserIndexReset: () => dispatch(reduxGetDataByClefUserIndexReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatExtDatanComponent);





