import React, {Suspense, useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import colors from "../../assets/themes/Colors";
import {TextField, Tooltip} from "../../../node_modules/@material-ui/core/index";
import {getPlugformViaCodeRequest, getPlugnoteViaCodeRequest} from "../../actions/plugcode";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {Translation, useTranslation} from "react-i18next";
import Modal from "@material-ui/core/Modal";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Close, Search} from "@material-ui/icons";
import {getTemplateFormatText} from "../../utils/Compatibility";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import ReactDataGrid from "react-data-grid";
import Button from "@material-ui/core/Button";
import {trackPromise} from "react-promise-tracker";
import {_getCodePnPpCodeTemplate} from "../../utils/Fields";
import ModalPlugnoteComponent from "../modals/ModalPlugnoteComponent";
import qs from "querystringify";
import {toast} from "react-toastify";
import i18n from "../../translations/i18n";
import {isJsonString} from "../../utils/utils";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    codeButton:{
        /* border: '1px red solid', */
        color : colors.main,
        display: 'inline-block',
        backgroundColor: "transparent",
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    input_error:{
        borderColor: colors.bloodOrange,
        borderBottom: '1px solid',
        borderRadius: '4px',
        textAlignLast: 'end',
        fontWeight: '900',
        marginRight: '5px'
    },
    editButton:{
        display: 'inline-block',
        paddingRight: "10px",
        borderRadius: "6px",
        fontSize: '22px!important',
        color: "#0000008a",
        cursor: "pointer",
        alignSelf: 'center'
    },
    rightButton: {
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        marginTop: 2,
        borderRadius: "6px",
        backgroundColor: colors.mainButton,
        alignItems: 'center',
        display: 'inline-block',
        color: 'white',
    },
    rightButtonMargin: {
        marginRight : '5px',
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "6px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'inline-block',
        color: 'white',
    },
    rightRedButtonMargin: {
        marginRight : '5px',
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "6px",
        backgroundColor: colors.red,
        alignItems: 'center',
        display: 'inline-block',
        color: 'white',
    },
    previewContainer: {
        width: '100%',
        // display: 'flex',
        // flexDirection: 'row',
        marginTop: '-32px',
    },
    commentContainer: {
        //transform: 'translateY(-6px)',
    },
    codeContainer: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        top: 0,
        right: 0,
        zIndex: 1,
    },
    mainContainer: {
        // width: '100%',
        // cursor: 'pointer',
        minHeight: 42,
        border: '1px solid #E3E3E3',
        borderRadius: 4,
        padding: '3px 10px'
    },
    collapsedView: {
        // width: '100%',
        justifyContent: 'space-between',
        marginRight: '15px'
    },
    commentPreview: {
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        cursor: 'pointer!important'
    },
    codeInput: {
        textAlignLast: 'center',
        fontWeight: '900',
        marginRight: '5px',
        borderColor: colors.selectedMain,
        borderBottom: '1px solid',
    },
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    smallBtnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const ViewPlugnotes = ({plugnotes, limitPage, startPage, model, handleSelectValuePlugnote, clef, field, attemptGetPlugnotesPaginateByClefAndUserHash, hash, actif, archived, activeSearch, search, owner}) => {

    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const gridRef = useRef(null);

    const [userhash, setUserHash] = React.useState(typeof owner !== "undefined" && owner !== null ? owner : '');

    const initialRows = [];
    let initColumns = [];

    const onRowClick = (plugnote) => {

        if(typeof plugnote !== "undefined"){
            let plugcode = _getCodePnPpCodeTemplate(plugnote);
            //console.log(plugcode);
            if(plugcode != ""){
                handleSelectValuePlugnote(plugcode,plugnote.modclef,plugnote.userindex);
            }else{
                handleGeneratePNCode(plugnote);
            }
        }

        //handleSelectValuePlugnote(value);
    }

    const handleGeneratePNCode = (plugnote) => {
        if (typeof (plugnote.pncode) === 'undefined' || plugnote.pncode === null || plugnote.pncode == "") {
            let data = {
                comment: "",
                dh: plugnote.hash
            };

            let typePnCodeNumber = "1";

            const payload = {
                ...data,
                uh: userhash,
                public: typePnCodeNumber
            }

            //postGeneratePlugcodePlugnote = (payload) =>  api.post('pncode/generatepns.json', payload);

            trackPromise(
                axios.post(ApplicationConfig.apiUrl + `pncode/generatepns.json`, payload
                , {
                    crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                }).then((response) => {
                    if(response.data.result === 'ok' || response.data.result === true){
                        handleSelectValuePlugnote(response.data.data.code,plugnote.modclef,plugnote.userindex);
                    }
                }).catch((response) => {
                    //console.log(response);
                }))
        }
    };

    const headerTitleView = (object) => {


        let item = object.column.item;


        let template = getTemplateFormatText(model['field'+item+'format'],typeof model['field'+item+'formatoptions'] !== "undefined" ? model['field'+item+'formatoptions'] : null);

        let span =  ' ( ' + item + ' - ' + template +' )';
        return (
            <div className="flex_display_direction_column">
                <span>{object.column.name}</span>
                <span style={{fontSize : '10px'}}>{span}</span>
            </div>
        );
    }


    const specialAction = (object) => {

        let column = object.column.key;
        let value = object.row[column];

        switch (object.column.format) {
            case 'phone':
                return (
                    <div>
                        <span>
                            <a href={`tel:${value}`}>{value}</a>
                        </span>
                    </div>
                );

            case 'mail':
                return (
                    <div>
                        <span>
                            <a href={`mailto:${value}`}>{value}</a>
                        </span>
                    </div>
                );

            case 'url':
                return (
                    <div>
                        <span>
                            <a href={value} target="_blank" rel="noreferrer">{value}</a>
                        </span>
                    </div>
                );
            default :
                return (
                    <div onClick={(event) => {onRowClick(object?.row?.plugnote)}} style={{cursor : 'pointer'}}>
                        <span>
                            {value}
                        </span>
                    </div>
                )
        }
    };

    const headerIdView = (object) => {
        return (
            <div
                style={{
                    color : '#58C3C1'
                }}
                className="flex_display_direction_column"
            >
                <span>
                        {object.column.name}
                    </span>
            </div>
        );
    }

    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
    }, [actif, archived,activeSearch,plugnotes]);

    if(plugnotes.length > 0 && model){

        // columns.push({ key: 'view', name:  '', resizable: true, sortable : false , width: 25, formatter : viewAction });

        initColumns.push({ key: 'userindex', name: "N°",
            resizable: true,
            sortable : true,
            item : 0,
            headerRenderer: headerIdView,
            formatter : specialAction,
            special : true
        });

        for (let item = 1; item <= 15 ; item++){

            if(model['field'+item+'label'] !== null && typeof model['field'+item+'label'] !== "undefined"){

                initColumns.push({ key: 'field' + item, name:  model['field'+item+'label'],
                    resizable: true,
                    sortable : true,
                    item : item,
                    headerRenderer: headerTitleView,
                    formatter : specialAction,
                    format : model['field'+item+'format'],
                    special : false
                });
            }
        }

        plugnotes.map((plugnote, index) => {

            if(actif === true && archived === true && (plugnote.isarchived === false || plugnote.isarchived === true)){
                let finalObject = {};
                let founded = false;
                finalObject['include'] = true;
                //finalObject.userindex = '[' + environment.envIndex + '-' + plugnote.userindex + ']';
                finalObject.userindex = '[' + plugnote.userindex + ']';

                for (let item = 1; item <= 15 ; item++){
                    if(model['field'+item+'label'] !== null){
                        let value = plugnote['field'+item];
                        if(plugnote['field'+item+'format'] === 'select'){
                            try {
                                let values = JSON.parse(plugnote['field'+item]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field'+item];
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'plugform'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            value = typeof plugValue !== "undefined" ? plugValue.value : "";
                        }
                        else if(plugnote['field'+item+'format'] === 'password'){
                            value = '*'.repeat(6);

                        }
                        else if(plugnote['field'+item+'format'] === 'glocalis'){
                            let plugValue = parseJson(value);
                            let tmp = "";
                            if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                                tmp = tmp.concat(plugValue.address);
                            }

                            if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                                if(tmp.length > 0){
                                    tmp = tmp.concat(' ~ ');
                                }
                                tmp = tmp.concat('lat : ' + plugValue.latitude + ',' + 'lng :' + plugValue.longitude);
                            }

                            value = tmp;
                        }
                        finalObject['field' + item] = value;

                        if(activeSearch && founded === false && typeof value !== "undefined"){
                            founded = value.includes(search)
                        }
                    }
                }

                finalObject['include'] = !activeSearch ? true : founded;


                finalObject['plugnote'] =  plugnote;
                finalObject['hash'] =  plugnote.hash;

                if(!activeSearch){
                    initialRows.push(finalObject);
                }else if(finalObject['include'] === true){
                    initialRows.push(finalObject);
                }


            }
            else if(actif === true && archived === false && plugnote.isarchived === false){
                let finalObject = {};
                let founded = false;
                finalObject['include'] = true;
                finalObject.userindex = '[' + plugnote.userindex + ']';

                for (let item = 1; item <= 15 ; item++){
                    if(model['field'+item+'label'] !== null){
                        let value = plugnote['field'+item];
                        if(plugnote['field'+item+'format'] === 'select'){
                            try {
                                let values = JSON.parse(plugnote['field'+item]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field'+item];
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }else if(plugnote['field'+item+'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        }else if(plugnote['field'+item+'format'] === 'plugform'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            value = typeof plugValue !== "undefined" ? plugValue.value : "";
                        }
                        else if(plugnote['field'+item+'format'] === 'password'){
                            value = '*'.repeat(6);

                        }
                        else if(plugnote['field'+item+'format'] === 'glocalis'){
                            let plugValue = parseJson(value);
                            let tmp = "";
                            if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                                tmp = tmp.concat(plugValue.address);
                            }

                            if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                                if(tmp.length > 0){
                                    tmp = tmp.concat(' ~ ');
                                }
                                tmp = tmp.concat('lat : ' + plugValue.latitude + ',' + 'lng :' + plugValue.longitude);
                            }

                            value = tmp;
                        }
                        finalObject['field' + item] = value;

                        if(activeSearch && founded === false && typeof value !== "undefined"){
                            founded = value.includes(search)
                        }

                    }

                }

                finalObject['include'] = !activeSearch ? true : founded;


                finalObject['plugnote'] =  plugnote;
                finalObject['hash'] =  plugnote.hash;

                if(!activeSearch){
                    initialRows.push(finalObject);
                }else if(finalObject['include'] === true){
                    initialRows.push(finalObject);
                }

            }
            else if(actif === false && archived === true && plugnote.isarchived === true){
                let finalObject = {};
                let founded = false;
                finalObject['include'] = true;
                //finalObject.userindex = '[' + environment.envIndex + '-' + plugnote.userindex + ']';
                finalObject.userindex = '[' + plugnote.userindex + ']';

                for (let item = 1; item <= 15 ; item++){
                    if(model['field'+item+'label'] !== null){
                        let value = plugnote['field'+item];
                        if(plugnote['field'+item+'format'] === 'select'){
                            try {
                                let values = JSON.parse(plugnote['field'+item]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field'+item];
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'plugform'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            value = typeof plugValue !== "undefined" ? plugValue.value : "";
                        }
                        else if(plugnote['field'+item+'format'] === 'password'){
                            value = '*'.repeat(6);

                        }
                        else if(plugnote['field'+item+'format'] === 'glocalis'){
                            let plugValue = parseJson(value);
                            let tmp = "";
                            if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                                tmp = tmp.concat(plugValue.address);
                            }

                            if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                                if(tmp.length > 0){
                                    tmp = tmp.concat(' ~ ');
                                }
                                tmp = tmp.concat('lat : ' + plugValue.latitude + ',' + 'lng :' + plugValue.longitude);
                            }

                            value = tmp;
                        }
                        finalObject['field' + item] = value;
                        finalObject['field' + item+ 'Original'] = plugnote['field'+item];
                        if(activeSearch && founded === false && typeof value !== "undefined"){
                            founded = value.includes(search)
                        }
                    }

                }


                finalObject['include'] = !activeSearch ? true : founded;

                finalObject['plugnote'] =  plugnote;
                finalObject['hash'] =  plugnote.hash;

                if(!activeSearch){
                    initialRows.push(finalObject);
                }else if(finalObject['include'] === true){
                    initialRows.push(finalObject);
                }

            }
        });
    }

    const [changeColumns, setChangeColumns] = useState(initColumns);
    const [rows, setRows] = useState([initialRows]);
    const [page, setPage] = useState(typeof startPage === 'undefined' ? 1 : startPage);
    const [end, setEnd] = useState(typeof limitPage === 'undefined' ? 1 : limitPage);
    const [hasMore, setHasMore] = useState(startPage < limitPage);
    const [sortDirection, setSortDirection] = useState("NONE");
    const [sortColumn, setSortColumn] = useState("userindex");
    const [rowCount, setRowCount] = useState(rows.length);


    const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
    }, [plugnotes]);

    useEffect(() => {
        setPage(startPage);
        setEnd(limitPage);
        if(startPage < limitPage){
            setHasMore(true);
        }
    }, [startPage, limitPage]);

    const fetchDatas = () => {

        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }else{
            setHasMore(false);
        }

        attemptGetPlugnotesPaginateByClefAndUserHash(nextPage);

    };

    return (
        <div key={-1}>
            <DndProvider backend={HTML5Backend}>
                <ReactDataGrid
                    rowKeyGetter={(row) => row.hash || ''}
                    defaultColumnOptions={{
                        sortable: true,
                        resizable: true
                    }}
                    style={{height : ((rowCount * 35) + 100)}}
                    columns={initColumns}
                    rowGetter={i => rows[i]}
                    rowsCount={rowCount}
                    sortDirection={sortDirection}
                    sortColumn={sortColumn}
                    onSort={(sortColumn, sortDirection) =>{
                        setSortDirection(sortDirection);
                        setSortColumn(sortColumn);
                        setRows(sortRows(initialRows, sortColumn, sortDirection));
                    }}
                    rows={rows || []}
                    headerRowHeight={70}
                    //selectedRows={selectedRows}
                    //onSelectedRowsChange={setSelectedRows}
                />
                {
                    hasMore &&
                    <div onClick={(event) => fetchDatas()} className={classes.pointer}>
                        <Button variant="contained" fullWidth={true}><span>LOAD MORE</span></Button>
                    </div>
                }
            </DndProvider>
        </div>
    );
};


const ModalListDatasComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [key, setKey] = React.useState(0);
    const [page, setPage] = useState( 1);
    const [end, setEnd] = useState(1);
    const [hasMore, setHasMore] = useState(props.start < props.end);
    const [hash, setHash] = React.useState(typeof props.owner !== "undefined" && props.owner !== null ? props.owner : '');

    const [archived, setArchived] = React.useState(false);
    const [actif, setActif] = React.useState(true);

    const [countArchived, setCountArchived] = React.useState(0);
    const [countActive, setCountActive] = React.useState(0);

    const [datas, setDatas] = useState([]) // champ pour ajouter un commentaire
    const [model, setModel] = useState(null) // champ pour ajouter un commentaire

    const [search, setSearch] = useState(props.filter);
    const [activeSearch, setActiveSearch] = useState(props.filter.length > 0);
    const [reload, setReload] = useState(false);

    const handleCloseModal = () => {
        props.close();
    };


    useEffect(() => {
        fetchLoadDatas();
    }, [props.open]);

    useEffect(() => {
        if(datas !== null && datas.length > 0){
            setCountActive(datas.filter((plug, index) => {
                return !plug.isarchived
            }).length)
            setCountArchived(datas.filter((plug, index) => {
                return plug.isarchived
            }).length)
        }
    }, [datas])


    const fetchLoadDatas = () => {

        let view = "unarchived";
        if(actif && archived){
            view = "all";
        }else if (!actif && archived){
            view = "archived";
        }

        // Fetch the dynamically generated excel document from the server.
        //`v2/datas/clefs.json?clef=${clef}&uh=${userhash}&internal=${userhash}&page=${page}`
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + `v3/datas/clefs.json?clef=${props.clef}&uh=${hash}&internal=${hash}&page=${page}&filter=${search}&view=${view}`, {crossdomain: true,
            'headers': {
                'X-AUTH-TOKEN': getToken(),
            }
        }).then((response) => {
            if(response.status === 200 && response.data.hasOwnProperty('model') && response.data.hasOwnProperty('datas')){
                setModel(response.data.model);
                setDatas(response.data.datas);
                setEnd(response.data.end);
            }else{
                setModel(null);
                setDatas([]);
                setPage(1);
                setEnd(1);
            }
        }).catch((response) => {
            setModel(null);
            setDatas([]);
            setPage(1);
            setEnd(1);
        }))

        //props.attemptGetPlugnotesPaginateByClefAndUserHash(props.clef, hash, 1);

    };

    const handleSeeMore = (page) => {
        setPage(page)
        fetchLoadDatas();
    }

    const handleSelectValuePlugnote = (value,model,data) => {
        props.handleSelectPlugnoteAndCopyDataField(value,model,data);
    }




    const handleViewInputSearch = () => {
        setActiveSearch(false);
        setSearch("");
        setReload(true);
    }

    const handleSearch = () => {
        setActiveSearch(true);
    };

    const handleChangeActif = () => {
        setActif(!actif);
        setReload(true);
    }

    const handleChangeArchived = () => {
        setArchived(!archived);
        setReload(true);
    }

    useEffect(() => {
        if(reload){
            fetchLoadDatas();
            setReload(false);
        }
    }, [reload])

    return (
        <Modal
            key={key}
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div className="text_align">
                                <span className="titres">
                                    {t('common:modal.select-plugnotes-from-folder')}
                                    <br/>
                                    { !!props.model &&
                                    <span>[{props.model.clef}] {props.model.label}</span>
                                    }
                                    <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">

                                <div className="grey flex_display_list_annexe">
                                    <div style={{width : '80%'}}>
                                        <Input
                                            id="standard-full-width"
                                            label="Label"
                                            style={{ margin: 8 }}
                                            //placeholder='titre formulaire'
                                            helpertext="Full width!"
                                            fullWidth={true}
                                            margin="none"
                                            inputlabelprops={{
                                                shrink: true,
                                            }}
                                            disableUnderline={true}
                                            className={classes.textField}
                                            value={search}
                                            onChange={(event) => {
                                                setSearch(event.target.value);
                                                if(activeSearch){
                                                    setActiveSearch(false)
                                                }
                                                setReload(true);
                                            }}
                                            endAdornment={ search.length > 0 && activeSearch ?
                                                <InputAdornment position="end" onClick={handleViewInputSearch} style={{cursor : 'pointer'}}>
                                                    <Close />
                                                </InputAdornment>
                                                :
                                                <InputAdornment position="end" onClick={handleSearch} style={{cursor : 'pointer'}}>
                                                    <Search />
                                                </InputAdornment>
                                            }
                                            onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch()}}}
                                        />
                                    </div>
                                    <div className="flex_display_direction_row_center" style={{width : '20%'}}>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={actif}
                                                   onChange={(event) => handleChangeActif()}/>
                                            {t('common:plugnote.plugnote-active')} ({countActive})
                                        </div>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={archived}
                                                   onChange={(event) => handleChangeArchived()}/>
                                            {t('common:plugnote.plugnote-archive')} ({countArchived})
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup_wrapper_content">
                            { datas.length > 0 && model !== null &&
                            <ViewPlugnotes
                                plugnotes={datas}
                                limitPage={end}
                                startPage={page}
                                model={model}
                                handleSelectValuePlugnote={handleSelectValuePlugnote}
                                clef={props.clef}
                                attemptGetPlugnotesPaginateByClefAndUserHash={handleSeeMore}
                                hash={hash}
                                actif={actif}
                                archived={archived}
                                activeSearch={activeSearch}
                                search={search}
                                owner={props.owner}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};


const FormatPlugcodeInternalComponent = (props) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [code, setCode] = useState(props?.field?.value?.code || "");
    const [codeModel, setCodeModel] = useState(props?.field?.value?.model || "");
    const [codeFiche, setCodeFiche] = useState(props?.field?.value?.data || "");
    const [comment, setComment] = useState(props?.field?.value?.comment && props?.field?.value?.comment != "" ? props?.field?.value?.comment : props.field?.options?.comment );
    const [fieldstoCopy, setFieldstoCopy] = useState(props.field?.options?.comment ? props.field?.options?.copyfieldstocomment : ""  );
    const [fieldstoFields, setFieldstoFields] = useState(props.field?.options?.copyfieldstofields ? props.field.options.copyfieldstofields : []  );
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [open, setOpen] = useState(false) // champ pour ajouter un commentaire
    const [openListFiche, setOpenListFiche] = useState(false) // champ pour ajouter un commentaire
    const [viewFiche, setViewFiche] = useState(false)
    const [owner, setOwner] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [index, setIndex] = useState(!!props.field.value.index ? props.field.value.index : "");

    const [internalSearch, setInternalSearch] = useState( typeof props.field?.options?.clef !== "undefined");
    const [clef, setClef] = useState( props.field?.options?.clef || null );
    const [response, setResponse] = useState(null);

    const [filter, setFilter] = useState('');
    const [openSearch, setOpenSearch] = useState(false);

    const ErrorToastMessage = ({ title, message }) => (
        <div>
            <Translation ns="common">
                {
                    (t, { i18n }) => <h6>{t(title)}</h6>
                }
            </Translation>
            <Translation ns="common">
                {
                    (t, { i18n }) => <p>{t(message)}</p>
                }
            </Translation>
        </div>

    )

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    const validateCode = (code) => {
        let isValid = false
        if(code.startsWith("PF-") || code.startsWith("PN-") || code.startsWith("PP-")) isValid = true
        if (code.trim().length === 15) return isValid;
        else return false
    }

    const handleSearchInternalCode = () => {
        setOpenListFiche(true);
    }

    const handleDeleteInternalCode = () => {
        setCode("");
        props.handleChangePlugcode({"code" : "", "model" : "", "data" : ""},"", props.field, props.index)
    }

    const handleSelectPlugnoteAndCopyDataField = (value, model, plugnote) => {

        let commentValue = comment;

        if(typeof fieldstoCopy !== "undefined" && fieldstoCopy.length > 0){
            let fieldsArray = fieldstoCopy.split(';');
            let value = "";
            fieldsArray.map((fieldInArray, index) => {
                if(plugnote['field'+ fieldInArray + 'format']){
                    let tmp = plugnote['field'+ fieldInArray] || "";
                    switch (plugnote['field'+ fieldInArray + 'format']) {
                        case 'tablemulti':

                            try {
                                let tmpValueTableMulti = "";
                                if(isJsonString(tmp)){
                                    tmpValueTableMulti = JSON.parse(tmp);

                                    let tmpValue = "";

                                    let objn = tmpValueTableMulti.find(o => typeof o.recap !== "undefined") || false;
                                    if(objn){
                                        delete objn.recap;
                                        let val = Object.values(objn);
                                        tmpValue += val.join(' ~ ')
                                    }

                                    if(tmpValue !== ""){
                                        tmpValue += ' ~ ';
                                    }

                                    tmpValue += '(' + tmpValueTableMulti.length + ')';

                                    if (index != (fieldsArray.length)) {
                                        value = value == "" ? value.concat(tmpValue) : value.concat(' ~ ' + '\n'+ tmpValue);
                                    }else{
                                        value = value == "" ? value.concat(tmpValue) : value.concat(' ~ ' + tmpValue);
                                    }
                                }
                            }catch (e) {
                                console.error(`❌  ${e}`)
                            }
                            break
                        case 'tablemultin':
                        case 'smailvalidmulti':
                        case 'document':

                            try {
                                let tmpValueTableMulti = "";
                                if(isJsonString(tmp)){
                                    tmpValueTableMulti = JSON.parse(tmp);

                                    let tmpValue = tmpValueTableMulti.value !== "" ?  tmpValueTableMulti.value + ' ~ ' : "";

                                    let objn = tmpValueTableMulti.list.find(o => typeof o.recap !== "undefined") || false;

                                    if(objn){
                                        delete objn.recap;
                                        let val = Object.values(objn);
                                        tmpValue += val.join(' ~ ')
                                    }

                                    if(tmpValue !== ""){
                                        tmpValue += ' ~ ';
                                    }

                                    tmpValue += '(' + tmpValueTableMulti.list.length + ')';

                                    if (index != (fieldsArray.length)) {
                                        value = value == "" ? value.concat(tmpValue) : value.concat(' ~ ' + '\n'+ tmpValue);
                                    }else{
                                        value = value == "" ? value.concat(tmpValue) : value.concat(' ~ ' + tmpValue);
                                    }
                                }
                            }catch (e) {
                                console.error(`❌  ${e}`)
                            }
                            break;
                        case 'select' :

                            try {
                                let tmpValueSelect = !Array.isArray(tmp) && tmp !== "[]" && tmp !== ""  ?  JSON.parse(tmp).join(',') : "";

                                if (index != (fieldsArray.length)) {
                                    value = value == "" ? value.concat(tmpValueSelect) : value.concat(' ~ ' + '\n'+ tmpValueSelect);
                                }else{
                                    value = value == "" ? value.concat(tmpValueSelect) : value.concat(' ~ ' + tmpValueSelect);
                                }
                            }catch (e) {
                                console.error(`❌  ${e}`)
                            }

                            break;
                        case 'table' :

                            try {
                                let tmpValueTable = !Array.isArray(tmp) && tmp !== "[]" && tmp !== "" ? JSON.parse(tmp).reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ') : "";

                                if (index != (fieldsArray.length)) {
                                    value = value == "" ? value.concat(tmpValueTable) : value.concat(' ~ ' + '\n'+ tmpValueTable);
                                }else{
                                    value = value == "" ? value.concat(tmpValueTable) : value.concat(' ~ ' + tmpValueTable);
                                }
                            }catch (e) {
                                console.error(`❌  ${e}`)
                            }
                            break;
                        case 'list' :
                            try {
                                let tmpValueList = !Array.isArray(tmp) && tmp !== "[]" && tmp !== "" ? JSON.parse(tmp).reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ') : "";

                                if (index != (fieldsArray.length)) {
                                    value = value == "" ? value.concat(tmpValueList) : value.concat(' ~ ' + '\n'+ tmpValueList);
                                }else{
                                    value = value == "" ? value.concat(tmpValueList) : value.concat(' ~ ' + tmpValueList);
                                }
                            }catch (e) {
                                console.error(`❌  ${e}`)
                            }
                            break;
                        case 'plugform' :

                            try {
                                let tmpValuePlugform = "";
                                if(isJsonString(tmp)){
                                    tmpValuePlugform = JSON.parse(tmp).value;
                                }

                                if (index != (fieldsArray.length)) {
                                    value = value == "" ? value.concat(tmpValuePlugform) : value.concat(' ~ ' + '\n'+ tmpValuePlugform);
                                }else{
                                    value = value == "" ? value.concat(tmpValuePlugform) : value.concat(' ~ ' + tmpValuePlugform);
                                }
                            }catch (e) {
                                console.error(`❌  ${e}`)
                            }
                            break;
                        case 'glocalis':

                            try {
                                let plugValue = parseJson(tmp);
                                let tmpValue = "";
                                if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                                    tmpValue = tmpValue.concat(plugValue.address);
                                }

                                if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                                    if(tmpValue.length > 0){
                                        tmpValue = tmpValue.concat(' \n ');
                                    }
                                    tmpValue = tmpValue.concat('lat : ' + plugValue.latitude + ' , ' + 'lng : ' + plugValue.longitude);
                                }

                                if (index != (fieldsArray.length)) {
                                    value = value == "" ? value.concat(tmpValue) : value.concat(' ~ ' + '\n'+ tmpValue);
                                }else{
                                    value = value == "" ? value.concat(tmpValue) : value.concat(' ~ ' + tmpValue);
                                }
                            }catch (e) {
                                console.error(`❌  ${e}`)
                            }
                            break
                        default:
                            if (index != (fieldsArray.length)) {
                                value = value == "" ? value.concat(tmp) : value.concat(' ~ ' + '\n'+ tmp);
                            }else{
                                value = value == "" ? value.concat(tmp) : value.concat(' ~ ' + tmp);
                            }
                            break;

                    }
                }
            })
            if(value !== ""){
                commentValue = value;
            }
        }

        let destinations = [];


        if(typeof fieldstoFields  !== "undefined" && fieldstoFields.length > 0){
            let champs = fieldstoFields.split('-');
            champs.map((elm, index) => {
                let field = elm.split(':');

                if(field.length > 0 && field.length <= 3){

                    let dest = field[0];
                    let col = field[1];
                    let separator = typeof field[2] !== 'undefined' ?  field[2] : null;
                    let copyValue = col?.split(';') || [col];
                    let value = "";

                    if(typeof props.fields[(dest - 1)] !== "undefined"){
                        if(props.fields[(dest - 1)].format === "glocalis"){
                            value = {
                                latitude : "",
                                longitude : "",
                                address : ""
                            }
                        }
                        else if(props.fields[(dest - 1)].format === "tablemultin" || props.fields[(dest - 1)].format === "smailvalidmulti" || props.fields[(dest - 1)].format === "document"){
                            value = {
                                value : "",
                                list : [],
                            }
                        }

                        copyValue.map((item, index) => {
                            let alphaNumIndexDest = 'field' + item.toUpperCase();
                            let data = plugnote[alphaNumIndexDest];
                            if(typeof data !== "undefined"){
                                let temp = data;
                                if(temp !== "" && typeof temp !== "undefined"){
                                    if(temp === null){
                                        temp = "";
                                    }

                                    if(temp != ""){

                                        switch (props.fields[(dest - 1)].format) {
                                            case 'glocalis' :
                                            case 'tablemulti':
                                            case 'document':
                                            case 'tablemultin' :
                                            case 'smailvalidmulti' :
                                                value = temp;
                                                break;
                                            case 'localis' :
                                                value = value == "" ? value.concat(temp) : value.concat(' ' + temp) ;
                                                break;
                                            default:
                                                if (item == item.toUpperCase()) {
                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + '\n'+ temp);
                                                }else{
                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                }
                                                break;

                                        }
                                    }
                                }
                            }
                        });


                        // if (props.fields[(dest - 1)].format === "glocalis") {
                        // }

                        destinations.push({
                            "dest" : dest,
                            "value" : value
                        })
                    }
                }
            })
        }

        setOpenListFiche(false);
        setCode(value);
        setCodeModel(plugnote.modclef);
        setCodeFiche(plugnote.userindex);
        setComment(commentValue);
        props.handleChangePlugcode({"code" : value, "model" : plugnote.modclef, "data" : plugnote.userindex, "comment" : commentValue},"", props.field, props.index, destinations)

    }

    const handleOpenComment = () => {
        {setOpen(true);
        if(open){
            setTimeout(() => {document.getElementById('comment-field').focus()}, 100);
        }
        }
    }
    // console.log("props+",props);

    const handleOpenFiche = () => {
        // console.log('formatplugcode', props);
        handleSearchPlugcode();
    }

    const handleSearchPlugcode = () => {
        if (code.length > 0) {
            if (code.startsWith("PF")) {
                //props.attemptGetPlugform(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pfcode/v2/modelcodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(code),
                    }, '?'))
                        .then((response) => {
                            if(response.data.result == true || response.data.result === 'ok'){
                                setResponse(response.data)
                                setViewFiche(true);
                            }else if(response.data.result == false || response.data.result === 'onk'){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${i18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }else if(response.data.result == 'nok'){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${i18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            } else {
                                setResponse(null)
                                toast.warn(`${i18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }
                        })
                        .catch((response) => {
                            //je lance une notification (toast error)
                            toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                        })
                )
            }
            else {
                //props.attemptGetPlugnote(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pncode/datacodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(code),
                    }, '?'))
                        .then((response) => {
                            if(response.data.result == true){
                                setResponse(response.data)
                                setViewFiche(true);
                            }else if(response.data.result == false){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${i18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }else if(response.data.result == 'nok'){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${i18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            } else {
                                setResponse(null)
                                toast.warn(`${i18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }
                        })
                        .catch((response) => {
                            //je lance une notification (toast error)
                            toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                        })
                )
            }
        }
    }

    function handleSearchValue (value) {
        setFilter(value)
    }

    return (
    <div className={classes.mainContainer}>
        <div className={classes.codeContainer} style={{backgroundColor: colors.white}}>
        {/* commentaire de plugform */}
            <Input
                autoComplete='off'
                autoFocus
                disabled={true}
                disableUnderline
                fullWidth={true}
                id='comment-field'
                inputProps={{maxLength: 512}}
                multiline={true}
                margin="none"
                placeholder="comment"
                style={{ margin: 0, }}
                value={comment}
            />

            {
                openSearch &&
                <TextField
                    className={classes.searchField}
                    helperText=""
                    id="standard-full-width"
                    margin="none"
                    multiline={true}
                    placeholder={t('plugform.filter-search')}
                    onChange={(event) => handleSearchValue(event.target.value)}
                    onKeyDown={(e) => {if (e.key === 'Enter') handleSearchInternalCode()}}
                    value={filter}
                />
            }


            {!open && code.length === 0 && !disabled &&
            <Tooltip title="Search code" placement="top">
                <span id="icon-listForm" className={`material-icons md-25 ${classes.rightButtonMargin}`}
                      style={{marginLeft: 'auto'}} onClick={(event) => handleSearchInternalCode()}>
                    search
                </span>
            </Tooltip>
            }

            {!open && code?.length === 0 && !disabled &&
                <Tooltip title="Search code" placement="top">
                <span
                    id="icon-listForm" className={`material-icons md-25 ${classes.rightButtonMargin}`}
                    style={{marginLeft: 'auto'}} onClick={(event) => setOpenSearch(!openSearch)}>
                    manage_search
                </span>
                </Tooltip>
            }

            {code.length > 0 && !disabled &&
            <Tooltip title="Search code" placement="top">
                <span id="icon-listForm" className={`material-icons md-25 ${classes.rightRedButtonMargin}`}
                      style={{marginLeft: 'auto'}} onClick={(event) => handleDeleteInternalCode()}>
                    clear
                </span>
            </Tooltip>
            }

            {validateCode(code) &&
            <Tooltip title="See Plugnote" placement="top">
                <span id="icon-listForm" className={`material-icons md-25  ${classes.rightButton}`} style={{marginLeft: 'auto'}} onClick={(event) => handleOpenFiche()}>
                    {code.startsWith("PF-")? 'edit' : 'visibility'}
                </span>
            </Tooltip>
            }

            <div className={classes.smallBtnContainer}>
                {!open && code.length > 0 && validateCode(code) &&
                <Tooltip title={code + ' - ' + codeModel + ' - ' + codeFiche} placement="top">
                    <span id="icon-listForm" className={`material-icons md-small ${classes.codeButton}`} style={{marginLeft: 'auto'}}>
                        info
                    </span>
                </Tooltip>
                }
                {!open && code.length > 0 && !disabled &&
                <Tooltip title="Delete code" placement="top">
                    <span id="icon-listForm" className={`material-icons md-small ${classes.codeButton}`}
                        style={{marginLeft: 'auto'}} onClick={(event) => handleDeleteInternalCode()}>
                        highlight_off
                    </span>
                </Tooltip>
                }
                {comment?.length > 450 ?
                <span className={classes.textLength}>{comment.length}/512</span>
                : null}
        </div>


        { viewFiche &&
                <Suspense fallback={null}>
                    <ModalPlugnoteComponent secondary={true}  value={response} code={code} codes={codeModel + ' - ' + codeFiche} open={viewFiche} close={(event) => setViewFiche(false)} useSuspense={false}/>
                </Suspense>
            }

            {/* { viewFiche && response !== null &&
                <Suspense fallback={null}>
                    <ModalPlugnoteComponent value={response} code={value} open={openModalPlugnote} close={(event) => setOpenModalPlugnote(false)} useSuspense={false}/>
                </Suspense>
            } */}

        {
            openListFiche &&  <ModalListDatasComponent open={openListFiche} close={(event) => setOpenListFiche(false)} owner={props.owner} filter={filter} clef={clef} handleSelectPlugnoteAndCopyDataField={handleSelectPlugnoteAndCopyDataField}/>
        }
        </div>
    </div>);
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    // console.log('global',state);
    return {
    }
}

const mapDispatchToProps = (dispatch) => {return {
    attemptGetPlugnoteViaCodeRequest: (plugcode, userhash, exist) => dispatch(getPlugnoteViaCodeRequest(plugcode, userhash, exist)),
    attemptGetPlugformViaCodeRequest: (plugcode, userhash, exist) => dispatch(getPlugformViaCodeRequest(plugcode, userhash, exist))
}};

export default connect(mapStateToProps, mapDispatchToProps)(FormatPlugcodeInternalComponent);





