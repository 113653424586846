import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getModel} from "../../utils/Common";
import ModalPluglinkComponent from "../modals/ModalPluglinkComponent";
import ModalListDatasPluglinkComponent from "../modals/ModalPluglinkComponent";
import colors from "../../assets/themes/Colors";
import Images from "../../assets/themes/Images";
// import { getPlugformWithCodeLogoff, getPlugnoteWithCodeLogoff } from "../../api/plugcodeApi";
import * as apiPlugcodes from '../../api/plugcodeApi'
import ModalConfirmComponent from "../modals/ModalConfirmComponent";
import { Modal } from "@material-ui/core";
import ModalPlugExternalCreateFromCodeComponent from "../modals/ModalPlugExternalCreateFromCodeComponent";
import { isDefined } from "../../utils/utils";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    fieldRight:{
        width : '100%',
        display: 'flex',
        minHeight: 42
    },
    addFormBtn: {
        textTransform: 'capitalize',
        fontSize: '17px',
        minHeight: '40px',
        paddingTop: '3px',
        paddingBottom: '3px',
        color: 'white!important',
        fontWeight: 'bold!important',
        backgroundColor: colors.unselected,
        borderRadius: '6px',
        justifyContent: 'center',
        textAlignLast: 'center',
        cursor: 'pointer',
        whiteSpace: 'break-spaces'
    },
    disabledBtn: {
        textTransform: 'capitalize',
        fontSize: '17px',
        minHeight: '40px',
        maxWidth: '150px',
        padding: '3px',
        color: 'white!important',
        backgroundColor: colors.plugnotesGris,
        borderRadius: '6px',
        textAlign: 'center',
        cursor: 'not-allowed',
        "@media (max-width: 640px)": {
            maxWidth: '100%'
        },
    },
    mainButtonText: {
        width: '80%',
        marginLeft: '-10px'
    },
    mainButtonIcon: {
        alignSelf: 'center',
        fontSize: 40,
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    mainButton: {
        display: 'flex',
        flexDirection: 'row',
    },
    rightButtons: {
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "3px",
        // backgroundColor: colors.main,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: colors.selectedMain,
    },
    popupContent: {
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column',
        margin: '5px',
        // textAlignLast: 'center',
    },
    popupHeader: {
        flex: '1 1',
        maxHeight: 80,
        display: 'flex',
        justifyContent: 'space-between'
    },
    popupInfos: {
        fontSize: '20px',
        paddingLeft: 30
    },
    fieldImage: {
        height: 20,
        width: 20,
    },
    fieldIcon: {
        width: 30,
        height: 30,
        padding: 5,
        marginLeft: 15,
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        marginRight: '8px'
    },
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        minWidth: '50%',
        maxWidth : '100%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
}));

const FormatPlugLinkComponent = (props) => {

    //console.log('FormatPlugLinkComponent', props);

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [consult, setConsult] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [code, setCode] = useState(props.field.options.pfcode || "");
    const [userindex, setUserIndex] = useState(props.field.value.userindex || "");
    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [pfData, setPfData] = useState(null)
    const [pfCode, setPfCode] = useState(props.field.options.pfcode || "")
    const [pfKey, setPfKey] = useState(props.field.options.code || "")
    const [pfOwner, setPfOwner] = useState("")
    const [pfTitle, setPfTitle] = useState("")
    const [pfShowInfo, setPfShowInfo] = useState(false)

    useEffect(() => {
        setPfCode(props.field.options.pfcode)
        setPfKey(props.field.options.code)
    }, [props.field.options])

    useEffect(() => {
        console.log("use effect pfData", pfData);
        if(pfData !== null) {
            setPfOwner(pfData.proprio || "")
            if (isDefined(pfData.data?.data)) setPfTitle(pfData.data.data.label || "");
        }
    }, [pfData])

    useEffect(() => {
        try {
            if(pfCode.startsWith("PF-")) {
                apiPlugcodes.getPlugformWithCodeLogoff(pfCode).then((e)=> {console.log("api",e); setPfData(e)})
                // console.log("found pf", pf);
            }
            else if(pfCode.startsWith("PN-")) {
                apiPlugcodes.getPlugnoteWithCodeLogoff(pfCode).then((e)=> {console.log("api",e); setPfData(e)})
                // console.log("found pf", pf);
            }
        }catch (e) {
            setPfCode("");
        }
    }, [pfCode])


    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseList = () => {
        setOpenList(false);
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const onClickUrl = (url) => {
        return () => openInNewTab(url)
    }

    useEffect(() => {

    }, [open])



    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);


    return (
        <div className={classes.fieldRight}>
            {
                pfData?.data?.result === "ok" ?
                    <div style={{ width: '90%', marginRight: '5px' }}>
                        {code ?
                            (!disabled && props.enabled) &&
                            <div
                                className={classes.addFormBtn} onClick={() => setOpen(true)}
                                id="standard-full-width"
                                type="text"
                            >
                                <div className={classes.mainButton}>

                                    <div className={classes.mainButtonText}>
                                        <span>{`add to form ` + pfKey}</span>
                                        {pfTitle !== "" ? <span>{`\n` + pfTitle}</span> : <span>{`\n` + pfCode}</span>}</div>

                                </div>
                            </div>
                            :
                            <div className={classes.disabledBtn}>
                                <span>Non disponible via PF code</span>
                            </div>
                        }

                        {(typeof code !== 'undefined' && code !== '' && userindex !== 'undefined' && userindex !== '' && consult === true) ?
                            <div
                                className={classes.addFormBtn}
                                onClick={() => setOpen(true)}
                            >
                                <span>{`add to form ` + pfKey}</span>
                                {pfTitle !== "" ? <span>{`\n` + pfTitle}</span> : <span>{`\n` + pfCode}</span>}
                            </div>
                            :
                            <div
                                className={classes.disabledBtn}>
                                <span>Non disponible via PF code</span>
                            </div>
                        }
                    </div>
                    :
                    <div style={{ width: '90%', marginRight: '5px' }}>
                        <div className={classes.disabledBtn}>
                            <span>PF code non disponible</span>
                        </div>
                    </div>
}
            { typeof code !== 'undefined' &&  code !== '' && userindex !== 'undefined' &&  userindex !== '' && consult === false && !disabled &&
                 <div className={classes.rightButtons}>
                    <span className="material-icons md-25" onClick={(event) => {if(pfData != null) setPfShowInfo(true)}}>
                        info
                    </span>
                    <span className="material-icons md-25" onClick={(event) => setOpenList(true)}>
                        visibility
                    </span>
                </div>
            }

            {(open && props.enabled) &&
            <ModalPlugExternalCreateFromCodeComponent dataHash={props?.dataHash} dataUserindex={props?.dataUserindex} open={open} close={() => setOpen(false)} params={props.field.value} owner={props?.ownerHash || null} />
            }
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPlugLinkComponent);





