import React from "react"
import { Divider, Drawer, IconButton, List, ListItem, ListItemText, styled, useTheme } from "@material-ui/core"
import { ChevronLeft, ChevronRight, Done, HighlightOff } from "@material-ui/icons"
import { devLog, isDefined } from "../../../utils/utils"

const drawerWidth = window.innerWidth - 12

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export const DrawerIconType = Object.freeze({
  OpenClose : "openClose",
  Validate : "validate",
})

const DrawerIcon = ({ handleDrawerClose, iconType }) => {
  const theme = useTheme()
  let template
  switch (iconType) {
    case 'openClose':
      template = theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />
      break
    case 'validate':
      template = <Done />
      break
    default:
      template = <HighlightOff />
      break
  }

  return (
    <IconButton onClick={handleDrawerClose}>
      {template}
    </IconButton>
  )
}

const PersistentDrawerLeft = (props) => {
  const { drawerIcon = "OpenClose", handleDrawerClose, open } = props
  const icon = DrawerIconType[drawerIcon]
  
  return (
    <Drawer
      sx={{
        height: window.innerHeight / 2,
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          height: window.innerHeight / 2,
          width: drawerWidth,
          boxSizing: 'border-box',
        },
        '& .MuiDrawer-paperAnchorBottom': {
          maxHeight: 500,
        },
      }}
      variant="persistent"
      anchor="bottom"
      open={open}
    >
      <DrawerHeader>
        {
          isDefined(props.title) &&
          <ListItemText primary={props.title} />
        }
        <DrawerIcon iconType={icon} handleDrawerClose={handleDrawerClose} />
      </DrawerHeader>
      <Divider />
      {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <IconButton>
              <ListItem>
                {index % 2 === 0 ? <Inbox /> : <Mail />}
              </ListItem>
              <ListItemText primary={text} />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <IconButton>
              <ListItem>
                {index % 2 === 0 ? <Inbox /> : <Mail />}
              </ListItem>
              <ListItemText primary={text} />
            </IconButton>
          </ListItem>
        ))}
      </List> */}
      {props.children}
    </Drawer>
  )
}
export default PersistentDrawerLeft