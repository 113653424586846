import {Types} from '../actions/external';

// create initial state for reducers
const INIT_STATE = {

};

// reducer function to transform state
export default function external(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        default: return state;
    }
}
