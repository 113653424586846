import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        width : '100%',
    },
    memoContainer:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: 5,
    },
    memoText:{
        flexGrow: 1,
        /* border: '1px solid blue', */
    },
    memoCount:{
        width: '50px',
        fontSize : '10px',
        textAlign: 'center',
        //lineHeight: '10px',
    },
}));

const FormatIndiceComponent = (props) => {

    const classes = useStyles();
    const [value, setValue] = useState(props.field.value);

    const { t, i18n } = useTranslation(['common', 'models']);


    let disabledInput = false;
    let editable  = props.field.options?.editable || false;

    if(editable){
        if(typeof props.disabled !== 'undefined' && props.disabled){
            disabledInput = props.disabled;
        }else{
            disabledInput = false;
        }
    }else{
        disabledInput = true;
    }

    const [disabled, setDisabled] = React.useState(disabledInput);


    const handleEditValue = (value, field, index) => {
        setValue(value);
        props.handleEditValue(value, field, index)
    }

    useEffect(() => {
        setValue(props.field.value);
    },[props.field.value, props.field.visuelValue])

    return (
        <div className={classes.memoContainer} key={"memo-"+props.index}>
            <TextField
                disabled={disabled}
                onChange={(event) => {
                    handleEditValue(event.target.value, props.field, props.index);
                }}
                id="standard-full-width"
                //label={field.title}
                style={{ margin: 0 }}
                multiline={false}
                placeholder={t('fields.format-choice-placeholder-'+props.field.visuelFormat.toLowerCase())}
                onFocus={(e) => e.target.placeholder = ''}
                onBlur={(e) => e.target.placeholder = t('fields.format-choice-placeholder-'+props.field.visuelFormat.toLowerCase())}
                helperText=""
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.memoText}
                value={value}
                InputProps={{ disableUnderline: true }}
            />
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatIndiceComponent);





