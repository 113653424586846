import {api} from "./index";


export let
    postCreateExternalPlugnotePFCode,
    postCreateInternalPlugnotePFCode,
    postCreateExternalPlugnotePFCodeBase64,
    postUpdateExistingPlugnoteFromPwBase64,
    postSendMails;

postCreateExternalPlugnotePFCode = (payload) => api.post('datas/createexternaldatafromqrcodes.json', payload, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

postCreateInternalPlugnotePFCode = (payload) => api.post('datas/createinternaldatafromqrcodes.json', payload, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

postSendMails = (payload) => api.post('utils/sendmails.json', payload, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

postCreateExternalPlugnotePFCodeBase64 = (payload) => api.post('datas/createexternaldatabase64fromqrcodes.json', payload);

postUpdateExistingPlugnoteFromPwBase64 = (payload) => api.post('datas/updateexternaldatabase64fromqrcodes.json', payload);

