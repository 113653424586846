import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/translate';
import * as api from '../api/translateApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, ...args) => trackPromise(fn(...args));
const myGenericTrackedWrapper2 = (fn, args1, args2) => trackPromise(fn(args1,args2));
const myGenericTrackedWrapper3 = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));
const myGenericTrackedWrapper4 = (fn, args1, args2, args3, args4) => trackPromise(fn(args1,args2, args3,args4));
const myGenericTrackedWrapper5 = (fn, args1, args2, args3, args4, args5) => trackPromise(fn(args1,args2, args3,args4, args5));

export function* fetchAllTranslate(action) {
    const response = yield call(myGenericTrackedWrapper2,api.getAllTranslationsByModel,action.modelhash, action.userhash);
    if (response.data.result){
        yield put(actions.getAllTranslationsByModelSuccess(response.data.data, response.data.data2));
    }else{
        yield put(actions.getAllTranslationsByModelFailure());
    }
}

export function* fetchOfflineAllTranslate(action) {
    const response = yield call(myGenericTrackedWrapper2,api.getAllTranslationsByModelOffline,action.modelhash);
    if (response.data.result){
        yield put(actions.getAllTranslationsByModelSuccess(response.data.data, response.data.data2));
    }else{
        yield put(actions.getAllTranslationsByModelFailure());
    }
}

export function* postCreateTranslation(action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.postCreateTranslationByModel,action.payload);
        if (response.data.result){
            yield put(actions.postCreateTranslationByModelSuccess());
            const subResponse = yield call(myGenericTrackedWrapper2,api.getAllTranslationsByModel,action.payload.modelhash, action.payload.userhash);
            if (subResponse.data.result){
                yield put(actions.getAllTranslationsByModelSuccess(subResponse.data.data, subResponse.data.data2));
            }else{
                yield put(actions.getAllTranslationsByModelFailure());
            }
        }else{
            yield put(actions.postCreateTranslationByModelFailure());
        }
    }catch(e) {
        yield put(actions.postCreateTranslationByModelError());
    }
}

export function* putUpdateTranslation(action) {
    //console.log(action.payload);
    try {
        const response = yield call(myGenericTrackedWrapper,api.putUpdateTranslationByModel,action.payload);
        if (response.data.result){
            yield put(actions.putUpdateTranslationByModelSuccess());
            const subResponse = yield call(myGenericTrackedWrapper2,api.getAllTranslationsByModel,action.payload.modelhash, action.payload.userhash);
            if (subResponse.data.result){
                yield put(actions.getAllTranslationsByModelSuccess(subResponse.data.data, subResponse.data.data2));
            }else{
                yield put(actions.getAllTranslationsByModelFailure());
            }
        }else{
            yield put(actions.putUpdateTranslationByModelFailure());
        }
    }catch(e) {
        yield put(actions.putUpdateTranslationByModelError());
    }
}


export function* fetchOneTranslate(action) {
    try {
        const response = yield call(myGenericTrackedWrapper3,api.getOneTranslationByModel,action.modelhash, action.userhash, action.translateid);
        if (response.data.result){
            yield put(actions.getOneTranslationByModelSuccess(response.data.data));
        }else{
            yield put(actions.getOneTranslationByModelFailure());
        }
    }catch(e) {
        yield put(actions.getOneTranslationByModelError());
    }
}

export function* fetchDeleteOneTranslate(action) {
    try {
        const response = yield call(myGenericTrackedWrapper3,api.deleteOneTranslationByModel,action.modelhash, action.userhash, action.id);
        if (response.data.result){
            yield put(actions.deleteDeleteTranslationByModelSuccess());
            const subResponse = yield call(myGenericTrackedWrapper2,api.getAllTranslationsByModel,action.modelhash, action.userhash);
            if (subResponse.data.result){
                yield put(actions.getAllTranslationsByModelSuccess(subResponse.data.data, subResponse.data.data2));
            }else{
                yield put(actions.getAllTranslationsByModelFailure());
            }
        }else{
            yield put(actions.deleteDeleteTranslationByModelFailure());
        }
    }catch(e) {
        yield put(actions.deleteDeleteTranslationByModelError());
    }
}

function* watchFetchField() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_ALL_TRANSLATIONS_BY_MODEL_REQUEST, fetchAllTranslate);
    yield takeEvery(actions.Types.GET_ALL_TRANSLATIONS_BY_MODEL_OFFLINE_REQUEST, fetchOfflineAllTranslate);
    yield takeEvery(actions.Types.GET_ONE_TRANSLATION_BY_MODEL_REQUEST, fetchOneTranslate);
    yield takeEvery(actions.Types.POST_CREATE_TRANSLATION_BY_MODEL_REQUEST, postCreateTranslation);
    yield takeEvery(actions.Types.PUT_UPDATE_TRANSLATION_BY_MODEL_REQUEST, putUpdateTranslation);
    yield takeEvery(actions.Types.DELETE_DELETE_TRANSLATION_BY_MODEL_REQUEST, fetchDeleteOneTranslate);
}

const FieldSagas = [
    fork(watchFetchField)
];

export default FieldSagas;
