// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {
    RESET_ALL_DATA: 'reset_all_data',

    GET_PLUGCODE_GALLERY_REQUEST : 'get_plugcode_gallery_request',
    GET_PLUGCODE_GALLERY_SUCCESS : 'get_plugcode_gallery_success',
    GET_PLUGCODE_GALLERY_FAILURE : 'get_plugcode_gallery_failure',
    GET_PLUGCODE_GALLERY_ERROR : 'get_plugcode_gallery_error',

    POST_PLUGCODE_GALLERY_REQUEST : 'post_plugcode_gallery_request',
    POST_PLUGCODE_GALLERY_SUCCESS : 'post_plugcode_gallery_success',
    POST_PLUGCODE_GALLERY_FAILURE : 'post_plugcode_gallery_failure',
    POST_PLUGCODE_GALLERY_ERROR : 'post_plugcode_gallery_error',

    PUT_PLUGCODE_GALLERY_REQUEST : 'put_plugcode_gallery_request',
    PUT_PLUGCODE_GALLERY_SUCCESS : 'put_plugcode_gallery_success',
    PUT_PLUGCODE_GALLERY_FAILURE : 'put_plugcode_gallery_failure',
    PUT_PLUGCODE_GALLERY_ERROR : 'put_plugcode_gallery_error',

    DEL_PLUGCODE_GALLERY_REQUEST : 'del_plugcode_gallery_request',
    DEL_PLUGCODE_GALLERY_SUCCESS : 'del_plugcode_gallery_success',
    DEL_PLUGCODE_GALLERY_FAILURE : 'del_plugcode_gallery_failure',
    DEL_PLUGCODE_GALLERY_ERROR : 'del_plugcode_gallery_error'
};

// function that returns an object literal
export const getPlugcodeGalleryRequest = (userhash) => ({
    type: Types.GET_PLUGCODE_GALLERY_REQUEST,
    userhash : userhash,
});

// function that returns an object literal
export const getPlugcodeGallerySuccess = (data) => ({
    type: Types.GET_PLUGCODE_GALLERY_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getPlugcodeGalleryFailure = () => ({
    type: Types.GET_PLUGCODE_GALLERY_FAILURE,
});

// function that returns an object literal
export const getPlugcodeGalleryError = () => ({
    type: Types.GET_PLUGCODE_GALLERY_ERROR,
});

// function that returns an object literal
export const postPlugcodeGalleryRequest = (gallery,userhash) => ({
    type: Types.POST_PLUGCODE_GALLERY_REQUEST,
    gallery : gallery,
    userhash : userhash,
});

// function that returns an object literal
export const postPlugcodeGallerySuccess = (data) => ({
    type: Types.POST_PLUGCODE_GALLERY_SUCCESS,
    payload: data
});

// function that returns an object literal
export const postPlugcodeGalleryFailure = () => ({
    type: Types.POST_PLUGCODE_GALLERY_FAILURE,
});

// function that returns an object literal
export const postPlugcodeGalleryError = () => ({
    type: Types.POST_PLUGCODE_GALLERY_ERROR,
});

// function that returns an object literal
export const putPlugcodeGalleryRequest = (gallery,userhash) => ({
    type: Types.PUT_PLUGCODE_GALLERY_REQUEST,
    gallery : gallery,
    userhash : userhash,
});

// function that returns an object literal
export const putPlugcodeGallerySuccess = (data) => ({
    type: Types.PUT_PLUGCODE_GALLERY_SUCCESS,
    payload: data
});

// function that returns an object literal
export const putPlugcodeGalleryFailure = () => ({
    type: Types.PUT_PLUGCODE_GALLERY_FAILURE,
});

// function that returns an object literal
export const putPlugcodeGalleryError = () => ({
    type: Types.PUT_PLUGCODE_GALLERY_ERROR,
});

// function that returns an object literal
export const delPlugcodeGalleryRequest = (galleryhash,userhash) => ({
    type: Types.DEL_PLUGCODE_GALLERY_REQUEST,
    galleryhash : galleryhash,
    userhash : userhash,
});

// function that returns an object literal
export const delPlugcodeGallerySuccess = (data) => ({
    type: Types.DEL_PLUGCODE_GALLERY_SUCCESS,
    payload: data
});

// function that returns an object literal
export const delPlugcodeGalleryFailure = () => ({
    type: Types.DEL_PLUGCODE_GALLERY_FAILURE,
});

// function that returns an object literal
export const delPlugcodeGalleryError = () => ({
    type: Types.DEL_PLUGCODE_GALLERY_ERROR,
});


export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

