import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {getCurrentEnvironnement} from "../../utils/Common";
import {convertersAlphaToNumber, isJsonString} from "../../utils/utils";
import {confirmAlert} from "react-confirm-alert";
import ModalListExtDataComponent from "../modals/extdata/ModalListExtDataComponent";
import {postExtDataReset} from "../../actions/extdata";
import * as moment from "moment";

import colors from "../../assets/themes/Colors";
import {isPossiblePhoneNumber} from "react-phone-number-input";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        width : '100%',
        display: 'flex',
        alignItems: 'center',
    },
    searchBtn: {
        fontSize: 15,
        minWidth: 60,
        position: 'relative'
    },
    unavailable: {
        position: 'absolute',
        color: colors.mandatory,
        fontSize: 15,
        right: '4px',
        top: '2px'
    }
}));

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

const FormatExtDataComponent = (props) => {

    const classes = useStyles();
    const environment = getCurrentEnvironnement();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [hideSearch, setHideSearch] = React.useState(props?.model?.authoriseExternalDbInPf === undefined ? false  : !props?.model?.authoriseExternalDbInPf);
    const [owner, setOwner] = React.useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [open, setOpen] = React.useState(false);
    const [view, setView] = React.useState(false);
    const [ref, setRef] = React.useState(props.field.options.ref);
    const [field, setField] = React.useState(props.field.options.field);
    const [column, setColumn] = React.useState(props.field.options.column);
    const [columns, setColumns] = React.useState(props.field.options.columns);
    const [options, setOptions] = React.useState(props.field.options);
    const [dest, setDest] = React.useState(props.field.options.dest);
    const [value, setValue] = React.useState(props.field.value.value ? props.field.value.value : "");
    const [ownerId, setOwnerId] = React.useState(props.ownerId);
    const [criteria, setCriteria] = useState("= xx")


    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setValue(props.field.value.value ? props.field.value.value : "");
    },[props.field.value])

    useEffect(()=>{
        switch (props.field.options.query) {
            case 'CONTAINS':
                setCriteria("..xx..")
                break;
            case 'START':
                setCriteria("xx..")
                break;
            case 'MULTIPLE':
                setCriteria("xxx")
                break;
            default: setCriteria("= xx")
                break;
        }
    }, [props.field.options.query])

    const handleOpenFiche = () => {
        setView(true);
    }

    const handleOpenSearchModel = () => {
        props.attemptGetDataFromAnotherBaseByClientReset();
        setOpen(true);
    }

    const handleRefreshContentData = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.content-replace'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => {
                        if(owner !== null){
                            //props.attemptGetPlugnoteByClefAndUserHashAndUserIndex(clef,owner,index, props.field.id);
                        }else{
                            //props.attemptGetPlugnoteByClefAndUserHashAndUserIndex(clef,environment.userhash,index, props.field.id);
                        }
                    }
                },
                {
                    label: t('global.no'),
                },
            ]
        });

    }

    const handleSelectPlugnoteAndCopyDataFieldCached  = (userindex, object, model) => {
        let tmp = "";
        if(object.length > 0){
            let value = "";
            object.map((item, index) => {
                let temp = "";
                switch (item.format){
                    case 'select': {
                        let tmpValueSelect = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).join(',') : "";
                        temp = tmpValueSelect;
                        break;
                    }
                    case 'table' :
                        let tmpValueTable = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text + ' : ' + obj.value || '');
                        }, []).join(', ') : "";
                        temp = tmpValueTable;
                        break;
                    case 'list' :
                        let tmpValueList = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text || '');
                        }, []).join(', ') : "";
                        temp = tmpValueList;
                        break;
                    case 'plugform' :
                        let tmpValuePlugform = "";
                        if(isJsonString(item.value)){
                            tmpValuePlugform = JSON.parse(item.value).value;
                        }
                        temp = tmpValuePlugform;
                        break;
                    case 'glocalis':
                        let plugValue = parseJson(item.value);
                        let tmpValue = "";
                        if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                            tmpValue = tmpValue.concat(plugValue.address);
                        }

                        if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                            if(tmpValue.length > 0){
                                tmpValue = tmpValue.concat(' ~ ');
                                tmpValue = tmpValue.concat(' \n ');
                            }
                            tmpValue = tmpValue.concat('lat : ' + plugValue.latitude + ' , lng : ' + plugValue.longitude);
                        }

                        temp = tmpValue;
                        break;
                    default :
                        temp = item.value;
                        break;
                }
                if(temp !== "" && typeof temp !== "undefined"){
                    value = value === "" ? value.concat(temp) : value.concat(' ~ \n\n'+ temp);
                }
            })

            tmp = {"value" : value , "index" : userindex, "name" : model.label};
            props.handleChangePlugform(tmp, props.field, props.index);
        }
        setOpen(false);
        props.attemptGetPlugnoteByClefAndUserHashAndUserIndexReset();
    }

    const handleSelectData = (object) => {
        let jsonObjectToArray = Object.values(object)
        let destinations = [];
        let champs = dest.split('-');

        champs.map((elm, index) => {
            let field = elm.split(':');

            if(field.length > 0 && field.length <= 3){

                let dest = field[0];
                let col = field[1];
                let separator = typeof field[2] !== 'undefined' ?  field[2] : null;
                let copyValue = col.split(';');
                let value = ""

                if(typeof props.fields[(dest - 1)] !== "undefined"){
                    if(props.fields[(dest - 1)].format === "glocalis"){
                        value = {
                            latitude : "",
                            longitude : "",
                            address : ""
                        }
                    }else if(props.fields[(dest - 1)].format === "tablemultin" || props.fields[(dest - 1)].format === "smailvalidmulti"){
                        value = {
                            value : "",
                            list : [],
                        }
                    }else if(props.fields[(dest - 1)].format === "intdatamaj"){
                        value = {
                            value : "",
                            updatedDate : "",
                        }
                    }

                    copyValue.map((item, index) => {
                        let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                        let data = jsonObjectToArray[alphaNumIndexDest];
                        if(typeof data !== "undefined"){
                            let temp = data;
                            if(temp !== "" && typeof temp !== "undefined"){
                                if(temp === null){
                                    temp = "";
                                }

                                if(temp !== ""){

                                    switch (props.fields[(dest - 1)].format) {
                                        case 'free-single-select':
                                        case 'single-select':
                                        case 'multi-select':
                                        case 'free-multi-select':
                                            try {
                                                value = [temp];
                                            }catch (e) {
                                                value = [];
                                            }
                                            break;
                                        case 'intdatamaj' :
                                            value.value = temp;
                                            break;
                                        case 'tablemultin' :
                                        case 'smailvalidmulti' :
                                            value.value = temp;
                                            break;
                                        case 'glocalis' :
                                                value.address = value.address === "" ? value.address.concat(temp) : value.address.concat(' ' + temp) ;
                                            break;
                                        case 'localis' :
                                            value = value === "" ? value.concat(temp) : value.concat(' ' + temp) ;
                                            break;
                                        default:
                                                if (item === item.toUpperCase()) {
                                                    value = value === "" ? value.concat(temp) : value.concat(' ~ \n'+ temp);
                                                }else{
                                                    value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                }
                                            break;

                                    }

                                    if(separator !== null){
                                        switch (props.fields[(dest - 1)].format) {
                                            case 'phone':
                                                if(isPossiblePhoneNumber(value) === false){
                                                    const str = value;
                                                    const char = str[0];
                                                    if(char === "0"){
                                                        value =  str.replace(char, separator);
                                                    }else{
                                                        value = separator + value ;
                                                    }
                                                }
                                                break;
                                            case 'numeric':
                                                switch (separator) {
                                                    case ",":
                                                        value = value.replaceAll('.', '');
                                                        value = value.replaceAll(separator, '.');
                                                        break;
                                                    case ".":
                                                        value = value.replaceAll(',', '');
                                                        value = value.replaceAll(separator, '.');
                                                        break;
                                                }
                                                break;
                                            case 'date':
                                                value = moment(value, separator).format('YYYY-MM-DD');
                                                break;
                                            case 'glocalis':
                                                let subSeparators = separator.split(',')
                                                if(subSeparators.length){
                                                    let sepLat = subSeparators[0];
                                                    sepLat = sepLat.replaceAll('Lat=',"");
                                                    let sepLng = subSeparators[1];
                                                    sepLng = sepLng.replaceAll('Lnt=',"");
                                                    let lat = jsonObjectToArray[convertersAlphaToNumber(sepLat) - 1];
                                                    let lng = jsonObjectToArray[convertersAlphaToNumber(sepLng) - 1];
                                                    value.latitude = lat;
                                                    value.longitude = lng;
                                                }
                                                break;
                                        }
                                    }
                                }
                            }
                        }
                    });


                    if(props.fields[(dest - 1)].format === "glocalis"){
                        value = JSON.stringify(value);
                    }

                    destinations.push({
                        "dest" : dest,
                        "value" : value
                    })
                }
            }
        })

        if(destinations.length > 0){
            props.handleChangeExtData(destinations);
        }

        setOpen(false);
    }

    useEffect(() => {
        setValue(props.field.value ? props.field.value : "");
    },[props.field.value])

    const titleFormat = `[ ${field} ] ( ${ref} ) [ ${columns} ] [ ${column} ]`

    return (
        <div className={classes.fieldRight}>
            <TextField
            disabled={disabled}
            onChange={(event) => props.handleEditValue(event.target.value, props.field, props.index)}
            id="standard-full-width"
            //label={field.title}
            style={{ margin: 0 }}
            type="text"
            multiline={true}
            //placeholder={t('fields.format-choice-placeholder-'+field.visuelFormat.toLowerCase())}
            helperText=""
            fullWidth
            margin="normal"
            InputProps={{ disableUnderline: true }}
            InputLabelProps={{
                shrink: true,
            }}
            //className={classes.fieldRight}
            value={value}

        />

            { !hideSearch && !disabled &&
                <div className={classes.searchBtn}> {criteria}
                <span id="icon-listForm" className={`material-icons md-25`} style={{color: colors.main}} onClick={(event) => handleOpenSearchModel()}>
                    search
                </span></div>
            }

            { (hideSearch || disabled) &&
                <div className={classes.searchBtn} style={{color: colors.greyLight}} > {criteria}
                    <span id="icon-listForm" className={`material-icons md-25`} style={{color: colors.greyLight}} onClick={(event) => handleOpenSearchModel()}>
                        search
                    </span>
                    <span id="icon-listForm" className={`material-icons md-20 ${classes.unavailable}`} style={{color: colors.red}}>
                        close
                    </span>
                </div>
            }

            {
                open  &&  <ModalListExtDataComponent titlePerso={props.title} titleFormat={titleFormat} format="extdata" open={open} close={(event) => setOpen(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} handleSelectData={handleSelectData} external={props.external}/>
            }
{/*
            {
                view && <ModalViewExtDataComponent open={view} close={(event) => setView(false)} owner={owner} options={options}  fields={props.fields}/>
            }*/}
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {

    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),

    //attemptGetPlugnoteByClefAndUserHashAndUserIndex: (clef,userhash,userindex, fieldId) => dispatch(getDataByClefUserIndexRequest(clef,userhash,userindex, fieldId)),
    //attemptGetPlugnoteByClefAndUserHashAndUserIndexReset: () => dispatch(reduxGetDataByClefUserIndexReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatExtDataComponent);





