//

// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {
    RESET_ALL_DATA: 'reset_all_data',

    POST_CREATE_PF_CODE_PLUGNOTE_REQUEST: 'post_create_pf_code_plugnote_request',
    POST_CREATE_PF_CODE_PLUGNOTE_PROGRESS: 'post_create_pf_code_plugnote_progress',
    POST_CREATE_PF_CODE_PLUGNOTE_SUCCESS: 'post_create_pf_code_plugnote_success',
    POST_CREATE_PF_CODE_PLUGNOTE_FAILURE: 'post_create_pf_code_plugnote_failure',
    POST_CREATE_PF_CODE_PLUGNOTE_ERROR: 'post_create_pf_code_plugnote_error',

    POST_UPDATE_PLUGNOTE_FROM_P_W_CODE_REQUEST: 'post_update_plugnote_from_p_w_code_request',
    POST_UPDATE_PLUGNOTE_FROM_P_W_CODE_SUCCESS: 'post_update_plugnote_from_p_w_code_success',
    POST_UPDATE_PLUGNOTE_FROM_P_W_CODE_FAILURE: 'post_update_plugnote_from_p_w_code_failure',
    POST_UPDATE_PLUGNOTE_FROM_P_W_CODE_ERROR: 'post_update_plugnote_from_p_w_code_error',

    POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_REQUEST: 'post_create_pf_code_plugnote_public_request',
    POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_PROGRESS: 'post_create_pf_code_plugnote_public_progress',
    POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_SUCCESS: 'post_create_pf_code_plugnote_public_success',
    POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_FAILURE: 'post_create_pf_code_plugnote_public_failure',
    POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_ERROR: 'post_create_pf_code_plugnote_public_error',


    POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_REQUEST: 'post_create_pf_code_plugnote_internal_request',
    POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_PROGRESS: 'post_create_pf_code_plugnote_internal_progress',
    POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_SUCCESS: 'post_create_pf_code_plugnote_internal_success',
    POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_FAILURE: 'post_create_pf_code_plugnote_internal_failure',
    POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_ERROR: 'post_create_pf_code_plugnote_internal_error',

    POST_CREATE_PLUGNOTE_INTERNAL_BASE64_REQUEST: 'post_create_plugnote_internal_base64_request',
    POST_CREATE_PLUGNOTE_INTERNAL_BASE64_PROGRESS: 'post_create_plugnote_internal_base64_progress',
    POST_CREATE_PLUGNOTE_INTERNAL_BASE64_SUCCESS: 'post_create_plugnote_internal_base64_success',
    POST_CREATE_PLUGNOTE_INTERNAL_BASE64_FAILURE: 'post_create_plugnote_internal_base64_failure',
    POST_CREATE_PLUGNOTE_INTERNAL_BASE64_ERROR: 'post_create_plugnote_internal_base64_error',
};




// function that returns an object literal
export const postCreatePFCodePlugnoteInternalRequest = (payload, files, model, userhash) => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_REQUEST,
    payload : payload,
    model : model,
    userhash : userhash,
    files : files,
});

// function that returns an object literal
export const postCreatePFCodePlugnoteInternalSuccess = (data) => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_SUCCESS,
    payload: { data }
});

export const postCreatePFCodePlugnoteInternalProgress = (file, progress) => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postCreatePFCodePlugnoteInternalFailure = () => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_FAILURE,
});

// function that returns an object literal
export const postCreatePFCodePlugnoteInternalError = () => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_INTERNAL_ERROR,
});

// function that returns an object literal
export const postCreatePFCodePlugnoteRequest = (payload, files, model, userhash) => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_REQUEST,
    payload : payload,
    model : model,
    userhash : userhash,
    files : files,
});

// function that returns an object literal
export const postCreatePFCodePlugnoteSuccess = (data) => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_SUCCESS,
    payload: { data }
});

export const postCreatePFCodePlugnoteProgress = (file, progress) => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postCreatePFCodePlugnoteFailure = () => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_FAILURE,
});

// function that returns an object literal
export const postCreatePFCodePlugnoteError = () => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_ERROR,
});


// function that returns an object literal
export const postUpdatePlugnoteFromPWCodeRequest = (payload) => ({
    type: Types.POST_UPDATE_PLUGNOTE_FROM_P_W_CODE_REQUEST,
    payload : payload,
});

// function that returns an object literal
export const postUpdatePlugnoteFromPWCodeSuccess = () => ({
    type: Types.POST_UPDATE_PLUGNOTE_FROM_P_W_CODE_SUCCESS,
});

// function that returns an object literal
export const postUpdatePlugnoteFromPWCodeFailure = () => ({
    type: Types.POST_UPDATE_PLUGNOTE_FROM_P_W_CODE_FAILURE,
});

// function that returns an object literal
export const postUpdatePlugnoteFromPWCodeError = () => ({
    type: Types.POST_UPDATE_PLUGNOTE_FROM_P_W_CODE_ERROR,
});

// function that returns an object literal
export const postCreatePFCodePlugnotePublicRequest = (payload) => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_REQUEST,
    payload : payload
});

// function that returns an object literal
export const postCreatePFCodePlugnotePublicSuccess = (data) => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_SUCCESS,
    payload: { data }
});

export const postCreatePFCodePlugnotePublicProgress = (file, progress) => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postCreatePFCodePlugnotePublicFailure = () => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_FAILURE,
});

// function that returns an object literal
export const postCreatePFCodePlugnotePublicError = () => ({
    type: Types.POST_CREATE_PF_CODE_PLUGNOTE_PUBLIC_ERROR,
});

// function that returns an object literal
export const postCreatePlugnoteInternalBase64Request = (payload) => ({
    type: Types.POST_CREATE_PLUGNOTE_INTERNAL_BASE64_REQUEST,
    payload : payload,
});

// function that returns an object literal
export const postCreatePlugnoteInternalBase64Success = (data) => ({
    type: Types.POST_CREATE_PLUGNOTE_INTERNAL_BASE64_SUCCESS,
    payload: { data }
});

export const postCreatePlugnoteInternalBase64Progress = (file, progress) => ({
    type: Types.POST_CREATE_PLUGNOTE_INTERNAL_BASE64_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postCreatePlugnoteInternalBase64Failure = () => ({
    type: Types.POST_CREATE_PLUGNOTE_INTERNAL_BASE64_FAILURE,
});

// function that returns an object literal
export const postCreatePlugnoteInternalBase64Error = () => ({
    type: Types.POST_CREATE_PLUGNOTE_INTERNAL_BASE64_ERROR,
});

export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});
