import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isDefined } from '../../../utils/utils'
import colors from '../../../assets/themes/Colors'
import { ErrorIcon } from '../../custom/CustomIcons'

const DialogErrorSavePlugnoteComponent = (props) => {
  const { t } = useTranslation('common')

  const missingValue = props.missingValues

  return (
    <Dialog
      open={props.openModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ position: 'absolute', right: 12, top: 12 }}>
        <ErrorIcon size="60" color={colors.iconGrey} />
      </div>
      <DialogTitle id="alert-dialog-title">{t('global.plugnotes')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            t('plugnote-create-update.plug-form-mandatory-fields-empty')
          }
        </DialogContentText>
        {
          missingValue !== false && missingValue !== true &&
          missingValue.map((missing, index) => {
            return (
              <div key={'missing-' + missing.field?.id + '-col-' + missing?.column?.id + '-row-' + missing?.rindex}>
                {
                  index > 0 && missing.field.titleTranslate !== missingValue[index - 1].field.titleTranslate &&
                  <br />
                }
                {
                  (index === 0 || (index > 0 && missing.field.titleTranslate !== missingValue[index - 1].field.titleTranslate)) &&
                  <div style={{ fontWeight: 'bold' }}>{t('helper.field') + ' ' + missing.field.id}: {missing.field.titleTranslate}</div>}
                {
                  isDefined(missing?.rindex) &&
                  <span style={{ paddingLeft: 16, color: (index === 0 || (index > 0 && missing.rindex !== missingValue[index - 1].rindex)) ? colors.unselected : 'white' }}>{t('tablemulti.row-number')}: {missing.rindex + 1}</span>
                }
                {
                  isDefined(missing?.column) &&
                  <span style={{}}> ● <span style={{ fontStyle: 'italic' }}>{missing.column.label}</span></span>
                }
              </div>
            )
          })
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClickOk} color="primary" autoFocus>
          {
            t('global.ok')
          }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogErrorSavePlugnoteComponent
