import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {DatePicker, KeyboardDatePicker} from "@material-ui/pickers";
import { Tooltip } from "@material-ui/core";
import colors from "../../assets/themes/Colors";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    dateComponent:{
        display: 'flex',
        justifyContent: 'space-between',
    },
    timeDelButton: {
        height:'22px',
        width: '12px',
        color: colors.main,
        alignItems: 'center',
        fontSize: 27
    },
    timeNowButton: {
        height:'16px',
        width: '12px',
        color: colors.main,
        alignItems: 'center',
        border: '2.5px solid',
        borderColor: colors.main,
        borderRadius: '22px',
        fontSize: "18px",
    },
    endButton: {    
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    timeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    }
}));


const FormatDateComponent = (props) => {
    const classes = useStyles();

    const [selectedDate, handleDateChange] = useState(typeof props.field.value !== 'undefined' && props.field.value !== null && props.field.value.length > 0 ? props.field.value : null);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [value, setValue] = useState(selectedDate)

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        handleDateChange(props.field.value ? props.field.value : null);
    },[props.field.value])

    const handleChangeDate = (date) => {
        if(!disabled) {
            if (date !== null) {
                let myDate = new Date(date)
                let visualDate = ""
                let year = myDate.getFullYear()
                let month = myDate.getMonth() + 1
                if(month < 10) month = '0' + month
                let day = myDate.getDate()
                if(day < 10) day = '0' + day
                if (!isNaN(year)) visualDate = year + '-' + month + '-' + day
                props.handleEditDateTime(selectedDate, visualDate, props.field, props.index)
            }
        }
    }

    return (
    <div className={classes.timeContainer}>
        {
            isMobile ?
            <DatePicker
                disabled={disabled}
                placeholder="YYYY-MM-DD"
                className={`${classes.datePickerIcon}`}
                variant="dialog"
                showTodayButton={true}
                format="yyyy-MM-dd"
                fullWidth={true}
                id={'date-picker-inline-'+props.field.id}
                value={selectedDate}
                onChange={(date, value) => {
                    handleDateChange(date);
                    setValue(value)
                    props.handleEditDateTime(date, value, props.field, props.index);
                }}
                InputProps={{ disableUnderline: true }}
                classes={{
                    root: classes.dateComponent, // class name, e.g. `classes-nesting-root-x`
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                }}
            />
            :
            <KeyboardDatePicker
                InputAdornmentProps={{ position: 'start' }}
                disabled={disabled}
                placeholder="YYYY-MM-DD"
                className={`${classes.datePickerIcon}`}
                variant="dialog"
                showTodayButton={true}
                format="yyyy-MM-dd"
                fullWidth={true}
                id={'date-picker-inline-' + props.field.id}
                //label={props.field.title}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                value={selectedDate}
                onChange={(date, value) => {
                    handleDateChange(date);
                    setValue(value)
                    props.handleEditDateTime(date, value, props.field, props.index);
                }}
                InputProps={{ disableUnderline: true }}
                classes={{
                    root: classes.dateComponent, // class name, e.g. `classes-nesting-root-x`
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                }}
            />
        }
        {!disabled &&
        <Tooltip title={(selectedDate === "" || selectedDate !== null) ? "Delete" : "Now time"} placement="top" className={classes.endButton} >
            {(selectedDate === "" || selectedDate !== null) ?
            <span className={`${classes.timeDelButton} material-icons`} onClick={() => handleDateChange(null)}>highlight_off</span>
            : 
            <span className={`${classes.timeNowButton} material-icons`}
                onClick={() => {if (selectedDate === null) {
                    handleDateChange(Date.now()); 
                    handleChangeDate(Date.now())
                    }
                }}
            >
                check
            </span>}
        </Tooltip> 
        }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatDateComponent);





