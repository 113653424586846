import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'

import UserReducer from './user';
import FieldReducer from './field';
import StartupReducer from './startup';
import AnnexeReducer from './annexe';
import PlugcodeReducer from './plugcode';
import ExternalReducer from './external';
import TranslateReducer from './translate';
import UtilsReducer from './utils';
import ExtDataReducer from './extdata';
import GalleryReducer from './gallery';

const appReducer  = (history) => combineReducers({
    router: connectRouter(history),
    user: UserReducer,
    field : FieldReducer,
    startup : StartupReducer,
    annexe : AnnexeReducer,
    plugcode : PlugcodeReducer,
    external: ExternalReducer,
    translate: TranslateReducer,
    utils: UtilsReducer,
    extdata: ExtDataReducer,
    gallery: GalleryReducer,
})



const rootReducer = (state, action) => {
    if(typeof action !== "undefined"){
        switch (action.type.toUpperCase()) {
            case "RESET_ALL_DATA":
                // Pour faire la purge de l'application
                state = undefined;
                return appReducer(state, action);
            default:
                return appReducer(state, action);
        }
    }
};

export default appReducer
