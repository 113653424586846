import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {KeyboardTimePicker} from "@material-ui/pickers";
import Icon from "@material-ui/core/Icon";
import colors from "../../assets/themes/Colors";
import PlugTooltip from "../custom/PlugTooltip";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    pointer: {
        cursor:'pointer'
    },
    timeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    timeDelButton: {
        fontSize: '28px',
        // height:'22px',
        width: '18px',
        // border: '2px solid',
        // borderRadius: "50px",
        color: colors.main,
    },
    timeNowButton: {
        height:'16px',
        width: '12px',
        color: colors.main,
        alignItems: 'center',
        border: '2.5px solid',
        borderColor: colors.main,
        borderRadius: '22px',
        fontSize: "18px",
    },
    endButton: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    }
}));


const FormatTimeComponent = (props) => {
    //"2018-01-01T11:12:00.000Z"
    const classes = useStyles();
    // console.log('props',props);
    let currentValue = null;
    if(props.field.value !== null && props.field.value.length > 0){
        // console.log(props.field.value);
        let split = props.field.value.split(':');
        // console.log(split);
        currentValue = new Date(null, null, null, split[0], split[1]);
    }

    const [selectedDate, changeDate] = useState(currentValue);
    const [visualValue, setVisualValue] = useState("")
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(()=> {
        if (selectedDate !== null && !disabled){
            // console.log("time", selectedDate);
            props.handleEditDateTime(selectedDate, visualValue, props.field, props.index)
        }
        else if(!disabled && selectedDate === null) {
            props.handleEditDateTime(selectedDate, '', props.field, props.index)}

        if(!disabled && visualValue !== null) {
            props.handleEditDateTime(selectedDate, visualValue, props.field, props.index)
        }
    }, [selectedDate, visualValue])

    const toVisual = (date) => {
        let hours = date.getHours()
        if(hours < 10) hours = '0' + hours
        let minutes = date.getMinutes()
        if(minutes < 10) minutes = '0' + minutes
        let visualDate = hours + ":" + minutes
        // console.log('visual', visualDate);
        setTimeout(()=> {setVisualValue(visualDate)}, 1000)
    }

    const setCurrentTime = () => {
        let date = new Date()
        changeDate(date)
        toVisual(date)
    }

    return (
        <div className={classes.timeContainer}>
            <KeyboardTimePicker
                InputAdornmentProps={{ position: 'start' }}
                disabled={disabled}
                autoComplete="off"
                fullWidth={true}
                className={`${classes.datePickerIcon} ${classes.fullWidth}`}
                format="HH:mm"
                style={selectedDate===""? classes.pointer: null}
                id="time-picker"
                ampm={false}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                keyboardIcon={<Icon>access_time</Icon>}
                value={selectedDate}
                onChange={(date, value) => {
                    changeDate(date);
                    setVisualValue(value)
                    props.handleEditDateTime(date, value, props.field, props.index);
                }}
                InputProps={{ disableUnderline: true }}
            />
            {
                !disabled &&
                <PlugTooltip title={(selectedDate === "" || selectedDate !== null) ? "Delete" : "Now time"} placement="top" className={classes.endButton} >
                    {(selectedDate === "" || selectedDate !== null) ?
                    <span className={`${classes.timeDelButton} material-icons`} onClick={() => {changeDate(null)}}>highlight_off</span>
                :
                    <span
                        className={`${classes.timeNowButton} material-icons`}
                        onClick={() => {if (selectedDate === null) setCurrentTime()}}>
                            check
                    </span>}

                </PlugTooltip>

            }
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTimeComponent);
