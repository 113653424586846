import {call, fork, put, select, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/plugcode';
import * as api from '../api/plugcodeApi';
import {history} from '../hooks/history'
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3, args4, args5) => trackPromise(fn(args1,args2, args3, args4, args5));

function forwardTo(location) {
    history.push(location);
}

/**
 * Récupération d'une Plugnote via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugnoteViaCode (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugnoteWithCodeLogin, action.plugcode, action.userhash);
        if (response.statusText == "OK" && typeof response.data.result === 'undefined') {
            yield put(actions.getPlugnoteViaCodeSuccess(response.data));
            if(!action.exist){
                yield call(forwardTo, '/plugcode-consult');
            }else{
                yield call(forwardTo, '/plugcode-consult-gallery');
            }
        } else {
            yield put(actions.getPlugnoteViaCodeFailure())
        }
    }catch(e) {
        yield put(actions.getPlugnoteViaCodeError());
    }
}

/**
 * Récupération d'un PlugForm via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugformViaCode (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugformWithCodeLogin, action.plugcode, action.userhash);
        // success?
        if (response.statusText == "OK" && response.data && response.data.data  && response.data.result === 'ok') {
            let payload = {
                data : response.data.data,
                proprio : !!response.data.proprio ? response.data.proprio : undefined
            }
            yield put(actions.getPlugformViaCodeSuccess(payload));
            if(!action.exist){
                yield call(forwardTo, '/plugcode-complete');
            }else{
                yield call(forwardTo, '/plugcode-complete-gallery');
            }
        } else {
            yield put(actions.getPlugformViaCodeFailure())
        }
    }catch(e) {
        yield put(actions.getPlugformViaCodeError());
    }

}

/**
 * Récupération d'une Plugnote via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugnoteViaCodeOffline (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugnoteWithCodeLogoff, action.plugcode);
        if (response.statusText == "OK" && typeof response.data.result === 'undefined') {
            yield put(actions.getPlugnoteViaCodeOfflineSuccess(response.data));
        } else {
            yield put(actions.getPlugnoteViaCodeOfflineFailure())
        }
    }catch(e) {
        yield put(actions.getPlugnoteViaCodeOfflineError());
    }
}

/**
 * Récupération d'un PlugForm via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugformViaCodeOffline (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugformWithCodeAndFolderLogoff, action.plugcode, action.folder, action.fiche);
        // success?
        if (response.statusText == "OK" && response.data && response.data.data  && response.data.result === 'ok') {
            let payload = {
                data : response.data.data,
                link : typeof response.data.link !== "undefined" ? response.data.link : null,
                plug : typeof response.data.plug !== "undefined" ? response.data.plug : null,
                proprio : !!response.data.proprio ? response.data.proprio : undefined
            }
            yield put(actions.getPlugformViaCodeOfflineSuccess(payload));

        } else {
            yield put(actions.getPlugformViaCodeOfflineFailure())
        }
    }catch(e) {
        yield put(actions.getPlugformViaCodeOfflineError());
    }

}


/**
 * Récupération d'une Plugnote via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugnoteViaDoubleCodeOffline (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugnoteWithPNCodeHashLogoff , action.payload.pnc, action.payload.dh);
        if (response.statusText == "OK" && response.data && response.data.data  && response.data.result === true) {
            yield put(actions.getPlugnoteViaDoubleCodeOfflineSuccess(response.data));
        } else {
            yield put(actions.getPlugnoteViaDoubleCodeOfflineFailure())
        }
    }catch(e) {
        yield put(actions.getPlugnoteViaDoubleCodeOfflineError());
    }
}

/**
 * Récupération d'un PlugForm via un PlugCode ou le scan d'un PlugCode
 * @param api
 * @param actions
 */
export function * getPlugformViaDoubleCodeOffline (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.getPlugformWithPFCodeHashLogoff, action.payload.pfc, action.payload.fh);
        // success?
        if (response.statusText == "OK" && response.data && response.data.data  && response.data.result === 'ok') {
            let payload = {
                data : response.data.data,
                link : typeof response.data.link !== "undefined" ? response.data.link : null,
                plug : typeof response.data.plug !== "undefined" ? response.data.plug : null,
                proprio : !!response.data.proprio ? response.data.proprio : undefined
            }
            yield put(actions.getPlugformViaDoubleCodeOfflineSuccess(payload));

        } else {
            yield put(actions.getPlugformViaDoubleCodeOfflineFailure())
        }
    }catch(e) {
        yield put(actions.getPlugformViaDoubleCodeOfflineError());
    }

}

/**
 * Vérification si le mail existe
 * @param api
 * @param actions
 */
export function * postCheckEmail (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postCheckEmail,{ email: action.email });
        if (response.data.result === 'ok') {
            yield put(actions.postCheckEmailSuccess(action.email, response.data.data))
        } else {
            yield put(actions.postCheckEmailFailure())
        }
    }catch(e) {
        yield put(actions.postCheckEmailError());
    }
}

function* watchFetchPlugcode() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_PLUGNOTE_VIA_CODE_REQUEST, getPlugnoteViaCode);
    yield takeEvery(actions.Types.GET_PLUGFORM_VIA_CODE_REQUEST, getPlugformViaCode);
    yield takeEvery(actions.Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_REQUEST, getPlugnoteViaCodeOffline);
    yield takeEvery(actions.Types.GET_PLUGFORM_VIA_CODE_OFFLINE_REQUEST, getPlugformViaCodeOffline);
    yield takeEvery(actions.Types.GET_PLUGNOTE_VIA_DOUBLE_CODE_OFFLINE_REQUEST, getPlugnoteViaDoubleCodeOffline);
    yield takeEvery(actions.Types.GET_PLUGFORM_VIA_DOUBLE_CODE_OFFLINE_REQUEST, getPlugformViaDoubleCodeOffline);
    yield takeEvery(actions.Types.POST_CHECK_EMAIL_REQUEST, postCheckEmail);
}

const PlugcodeSagas = [
    fork(watchFetchPlugcode)
];

export default PlugcodeSagas;
