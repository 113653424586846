import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useTranslation} from "react-i18next";
import {TextField} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import {confirmAlert} from "react-confirm-alert";
import {Close, Search} from "@material-ui/icons";
import colors from "../../assets/themes/Colors";
import useLongPress from "../../utils/useLongPress";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        color: 'white',
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '20%',
        display: 'flex',
        border: "solid #E3E3E3 1px",
        borderRadius: "6px",
        padding: "5px 15px",
        minHeight: '35px',
        color: colors.unselected,
        "@media (max-width: 640px)": {
            minHeight: 24,
            padding: '0px 10px',
            width: '35%'
        }
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        fontWeight: '500',
        borderRadius: '5px',
        padding: '0 10px',
        backgroundColor: colors.greyLighter,
        fontSize: '14px',
        flexGrow: 1,
        border: 'none',
        marginLeft: 10,
    },
    singTabMainContainer: {
        width : '100%',
        display: 'flex',
        minHeight: '42px',
        flexDirection: 'row',
        "@media (max-width: 640px)": {
            minHeight: 30
        }
    },
    singTableAddBox: {
        width : '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    singTabItemContainer: {
        flexDirection: 'row',
        width: 'inherit',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: "6px",
        padding: "10px 15px",
        flexWrap: 'wrap',
        cursor: 'pointer',
        backgroundColor: colors.main,
        // color: colors.black,
        gap: 5,
    },
    singTabItemText: {
        color: colors.grey,
        // fontWeight: 'bold',
    },
    singTabItemValue: {
        color: colors.white
    },
    singleTabItem: {
        // cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        minHeight: '42px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedItem,
        padding: "0 10px 1px",
        gap: 5,
        color: colors.white,
        "@media (max-width: 640px)": {
            minHeight: 30
        }
    },
    singleTabSelected: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        minHeight: '30px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        padding: "0 10px 1px",
        gap: 5,
        backgroundColor: colors.selectedItem,
        color: colors.white
    },
    unselectedTabItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        borderRadius: "6px",
        backgroundColor: colors.unselectedItem,
        color: colors.white,
        padding: "0 10px 1px 10px",
        gap: 5,
        minWidth: '70px',
        minHeight: '42px',
        "@media (max-width: 640px)": {
            minHeight: 30
        }
    },
    singleInput: {
        textAlign: 'center',
        color: colors.white,
        margin: 0,
    },
    selectedInput: {
        textAlign: 'center',
        margin: 0,
        // color: colors.selectedMain,
    },
    endButtonContainer:{
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "6px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'flex',
        color: 'white',
        marginLeft: 'auto'
    },
    singTableDelButton: {
        width : 18,
        height : 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid',
        // color: 'white',
        borderRadius: 50,
        fontSize: 16,
        fontWeight: 'bold',
    },
    singTableItemSearchContainer: {
        width: '100%',
        display: 'flex',
        borderRadius: "6px",
        padding: "10px 15px",
        marginTop: 10,
        gap: 5,
        alignItems: 'center',
    },
    singTableItemSearchContainerOpen: {
        width: '100%',
        display: 'flex',
        borderRadius: "6px",
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    singTablePresetText: {
        color: colors.grey,
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    singTableItemPresetOpen: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        width: '100%',
    },
    singTableSearchButton: {
        color: colors.main,
    },
    singTableText: {
        flexGrow: 1,
        textAlign: 'right',
        border: 'none',
        fontStyle: 'italic',
    },
    singTableItemText: {
        // fontWeight: 'bold',
    },
    inputField: {
        width: '80px',
        backgroundColor: 'inherit',
        border: 'none',
        color: colors.white
    },


}));

const FormatSingleSelectTableInputComponent = (props) => {
    const [newValue, setNewValue] = useState(typeof props.value !== 'undefined' ? props.value : "");
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    useEffect(() => {
        setNewValue(typeof props.value !== 'undefined' ? props.value : "")
    }, [props.value])

    return(
        <input
            disabled={disabled}
            autoFocus={true}
            autoComplete='off'
            style={{ margin: 0, marginLeft: '5px'}}
            // InputProps = {{color: "white"}}
            // inputProps = {{color: "white"}}
            onWheel={ event => event.currentTarget.blur() }
            id="standard-full-width"
            placeholder=""
            className={props.className}
            onChange={(event) => {
                setNewValue(event.target.value);
                props.onHandleChange(event.target.value);
            }}
            value={newValue}
            onKeyPress={(e) => {props.onKeyPress(e)}}
        />
    )

}

const FormatSingleSelectTableComponent = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    const includesInJson = () => {
        return props.field.options.presel.findIndex((item, index) => {
            if(typeof selected.text !== "undefined"){
                return item.trim() === selected.text.trim()
            }
            return false;
        })
    }
    const [selected, setSelected] = useState(props.field.value.length > 0 ? props.field.value[0] : "");
    const [newValue, setNewValue] = useState("");
    const [newValueDigit, setNewValueDigit] = useState(selected?.value || "");
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [open, setOpen] = useState(typeof props.expand === 'undefined' ? true: props.expand);
    const [choice, setChoice] = useState(selected?.text || null);
    const [preselOrignal , setPreselOriginal] = useState(typeof props.field.originalPresel !== 'undefined' ? props.field.originalPresel : []);
    const [value, setValue] = useState('') // filter text (bad name, I know...)
    const [editMode, setEditMode] = useState("")
    const [editedValue, setEditedValue] = useState("")

    const presels = [...props.field.options.presel]
    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        if(props.field.value.length > 0){
            let tmp = props.field.value[0];
            setNewValueDigit(tmp.value);
            setChoice(tmp.text);
        }else{
            setChoice("");
            setNewValueDigit("");
            setSelected("");
        }
    },[props.field.value]);

    const handleHitEnter = (e) => {
        if (e.key === 'Enter') setOpen(false)
    }

    const handleAddValue = (value) => {
        if (!presels.includes(value)) {
            // setSelected({"text" : value , "value" : ""});
            props.field.options.presel.push(value);
            presels.push(value)
        }
        // else setSelected()
        setChoice(props.field.options.presel.length - 1);
        setNewValueDigit("");
        // props.handleChangeInputTable({"text" : value , "value" : ""}, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        setNewValue('');
    }

    const handleOnChange = (value) => {
        if(selected) selected.value = value;
        else setSelected({"text": choice, "value": value})
        // setSelected(selected);
        if(value.trim != ""){
            props.handleChangeInputTable({"text" : props.field.options.presel[includesInJson()], "value" : value}, includesInJson(),props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }
        if(value == '') {
            props.handleDeleteInputTable(value, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
            setSelected('')
            console.log('removed', selected);
        }
    }

    const handleChangeSelect = (label) => {
        //récupère la valeur du selected
        setChoice(label)
        const oldValue = selected.value
        if (editMode !== "") {
            setEditMode("")
        }
        if (selected.text !== label && oldValue) {
            setSelected({"text" : label, "value" : oldValue});
            setNewValue("");
            setNewValueDigit(oldValue)
            props.handleChangeInputTable({"text" : label, "value" : oldValue}, label, props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }

    }

    const handleRemoveSelect = (item) => {
        setChoice("");
        setSelected("");
        setNewValue("");
        setNewValueDigit("");
        props.handleDeleteInputTable(item, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
    }

    const handleDeletePresel = (value) => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.confirm-sur'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => {
                        setSelected("");
                        props.field.options.presel = props.field.options.presel.filter(item => item !== value);
                        props.handleUpdateInputTable(props.field.options.presel, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
                    }
                },
                {
                    label: t('global.no'),
                },
            ]
        });
    }
    const handleEditValue = (newValue, oldValue, savedValue) => {
        console.log("new edit", newValue, oldValue, savedValue);
        const tmpVal = savedValue
        const tmpLabel = newValue
        if(newValue !== oldValue){
            props.field.options.presel = props.field.options.presel.filter(item => item !== oldValue);
            props.handleUpdateInputTable(props.field.options.presel, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);}
        if (!presels.includes(newValue)) {
            setSelected({"text" : tmpLabel , "value" : tmpVal});
            props.field.options.presel.push(newValue);
            presels.push(newValue)
        }
        setSelected({"text" : tmpLabel , "value" : tmpVal});
        setChoice(tmpLabel)
        console.log("selected", selected);
        console.log("choice", choice);
        setEditMode("")
    }

    const isInPresel = (value) => {
        return props.field.originalPresel.includes(value)
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const onLongPress = (item, oldValue) => {
        // console.log("long press triggered", item.target);
        // handleChangeSelect(item.target.innerText, selected.value)
        // if (!isInPresel(item.target.innerText)) {
        //     setEditMode(item.target.innerText);
        //     setEditedValue(item.target.innerText)
        // }
    };
    const onPress = () => {
        // console.log("click triggered")
    };

    const longPressEvent = useLongPress(onLongPress, onPress, defaultOptions)

    return (
        <div className={classes.singTabMainContainer} onClick={() => {if(!open && !disabled) setOpen(true)}}>
            {/** presel filter **/
                open && presels.length > 10 && <div className={classes.singTableItemPresetOpen}>
                    <Input
                        id={`standard-full-width-search-${props.field.id}`}
                        label=""
                        //style={{ margin: 8 }}
                        //placeholder={props.placeholder}
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        startAdornment={
                            <InputAdornment position="start">
                                <Search className={classes.singTableSearchButton}/>
                            </InputAdornment>}
                        endAdornment={ value.length > 0 &&
                        <InputAdornment position="end" onClick={(event) => setValue("")} style={{cursor : 'pointer'}}>
                            <Close />
                        </InputAdornment>
                        }
                        className={classes.textField}
                        onChange={(event) => setValue(event.target.value)}
                        value={value}
                    />
                </div>}


            {
                !open
                && typeof selected.text !== "undefined"  &&
                <div className={classes.singleTabItem} onClick={(event) => {event.preventDefault(); event.stopPropagation(); if(!disabled){setOpen(true);} }}>
                    <span className={classes.singTableItemText} >{selected.text}: </span>
                    <span>{selected.value}</span>
                    {/*<span className="cursor" onClick={() => {isInPresel(selected.text)? handleChangeSelect("") : handleDeletePresel(selected.text)}}>*/}
                    {
                        !disabled &&
                        <span className={{cursor: 'pointer', marginLeft: 'auto'}} onClick={(event) => { event.preventDefault(); event.stopPropagation(); !isInPresel(selected.text)?  handleDeletePresel(selected.text):  handleRemoveSelect(selected.text)}}>
                                <span className={`${classes.singTableDelButton} material-icons`}>{!isInPresel(selected.text)?'delete': 'close'}</span>
                            </span>
                    }
                </div>
            }

            { open && typeof props.field.options.presel !== 'undefined' &&
            /** box d'ajout **/
            <div className={classes.singTableItemSearchContainerOpen}>
                {
                    props.field.options.editable && !disabled &&
                    <div className={classes.input_add_item}>
                        <Input
                            disabled={disabled}
                            autoComplete='off'
                            id="standard-full-width"
                            label="Label"
                            style={{ margin: 0 }}
                            placeholder={t('common:multiple.add-item')}
                            helpertext="Full width!"
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            inputlabelprops={{
                                shrink: true,
                            }}
                            className={classes.fieldRight}
                            value={newValue}
                            onKeyPress={(e) => { if (e.key === 'Enter' && newValue.trim !== "") { handleAddValue(newValue, props.field, props.index); setChoice(newValue);}}}
                            onChange={(event) => setNewValue(event.target.value)}
                        />
                    </div>
                }
                {
                    props.field.format.includes('table') && props.field.format.includes('single') &&    /**** liste des items */
                    presels.filter(item => {
                        if (!value) return true
                        if (item.toLowerCase().includes(value.toLowerCase())) {
                            return true
                        }
                    })
                        .sort()
                        .map((item,indexation) => {
                            return (
                                <div key= {'divsingleitem' + props.field.id + '-' + indexation}
                                    className={(selected.text===item)? classes.singleTabItem : classes.unselectedTabItem}
                                    onClick={(event) =>{ event.preventDefault(); event.stopPropagation();
                                    if(choice !== item && !disabled) handleChangeSelect(item, selected.value)
                                    // setChoice(item)
                                    }}
                                >
                                    {editMode === item ? // longpress pour éditer l'ajout
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id="standard-full-width"
                                            placeholder={t('common:multiple.add-item')}
                                            fullWidth={false}
                                            inputlabelprops={{
                                                shrink: true,
                                            }}
                                            // helperText="edit"
                                            value={editedValue}
                                            disableUnderline={true}
                                            onKeyPress={(e) => { if (e.key === 'Enter') { handleEditValue(editedValue, item, selected.value)}}}
                                            onChange={(event) => setEditedValue(event.target.value)}
                                        />
                                    :
                                    // <Tooltip title={"Long press to edit"} placement="top" disableHoverListener={isInPresel(item)}>
                                        <span className={classes.singleInput} {...longPressEvent}>{item}</span>
                                    // </Tooltip>
                                    }
                                    {
                                        (editMode === "" && choice === item) || selected.text === item ?
                                        <span className={classes.singleInput}>:
                                            <FormatSingleSelectTableInputComponent
                                                className={classes.inputField}
                                                value={newValueDigit}
                                                disabled={disabled}
                                                onHandleChange={handleOnChange}
                                                onKeyPress={handleHitEnter}
                                            />
                                        </span>
                                            : null
                                    }
                                    {
                                        !disabled && selected.text===item && typeof selected.text !== "undefined" &&
                                        <span style={{cursor: 'pointer', marginLeft: 'auto'}}  onClick={() => editMode !== ""? handleEditValue(editedValue, item, selected.value) :(selected.text===item)?  handleRemoveSelect(item) : !preselOrignal.includes(selected.text) && handleDeletePresel(selected.text)}>
                                            <Tooltip title={editMode == "" ? "Unselect" : "Confirm change"} placement="top" >
                                            <span className={`${classes.singTableDelButton} material-icons`}>{editMode == "" ? 'close' : 'done'}</span></Tooltip>
                                        </span>
                                    }
                                    {
                                        !disabled && editMode === "" && item !== selected.text && !isInPresel(item) &&
                                        <span style={{cursor: 'pointer', marginLeft: 'auto'}}  onClick={() => handleDeletePresel(item)}>
                                        <Tooltip title={"Delete"} placement="top" >
                                            <span className={`${classes.singTableDelButton} material-icons`}>delete</span></Tooltip>
                                        </span>
                                    }
                                </div>
                            )
                        })
                }
            </div>
            }

            {
                typeof props.field.options.presel !== 'undefined' && !disabled &&
                <div className={classes.endButtonContainer}  onClick={(event) => setOpen(!open)}>
                    <span id="icon-delete-playlist" className={`${classes.singleItemPresetOpen} material-icons md-small`}>
                        {open? 'expand_less' : props.field.options.editable? 'playlist_add':'playlist_add_check'}
                    </span>
                </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSingleSelectTableComponent);
