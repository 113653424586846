import {fork} from 'redux-saga/effects';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3,args4, args5, args6) => trackPromise(fn(args1,args2, args3,args4, args5, args6));

// Watch for an upload request and then
// defer to another saga to perform the actual upload
function* uploadRequestWatcherSaga() {

}


const AnnexeSagas = [
    fork(uploadRequestWatcherSaga)
];

export default AnnexeSagas;
