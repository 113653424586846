import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import { ScatterPlot } from "../../../node_modules/@material-ui/icons/index";
import TextField from "@material-ui/core/TextField";
import {Checkbox, withStyles} from "@material-ui/core";
import colors from "../../assets/themes/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        //width : '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: '25px',
        display: 'flex'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        display: 'flex',
        alignItems: 'center',
        width : '50px',
    },
    checkbox: {
        color: colors.mainButton,
        margin: 0, 
        width: '20px', 
        height: '20px', 
        marginRight : '5px',
    },
    checkContainer: {
        cursor: 'pointer',
        bottom: 0,
        width: '120%',
        fontSize: 12,
        color: colors.darkSilver,
        display: 'flex',
        alignItems: 'center'
    },
}));


const DarkerDisabledTextField = withStyles({
    root: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0)" // (default alpha is 0.38)
        }
    }
})(TextField);
const PlugCheckbox = withStyles(theme => ({
  root: {
    color: colors.selectedItem,
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
      color: colors.unselectedItem
    }
  },
  checked: {
    color: colors.selectedItem,
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      color: colors.selectedItem,
    },
  }
}))(Checkbox);

const FormatBooleanComponent = (props) => {
    const classes = useStyles();
    const [selected, setSelected] = useState(props.field.value === '1');
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    const toggleCheck = () => {
        if(!disabled) {
            setSelected(!selected);
            props.handleChangeBoolean(selected === true ? '0' : '1', props.field,props.index)}
    }

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[props.disabled]);
    
    return (
        <div className={classes.checkContainer} onClick={(e) => {e.preventDefault(); e.stopPropagation(); toggleCheck()}}>
            <PlugCheckbox
                disabled={disabled}
                className={classes.checkbox}
                sx={{
                    color: colors.unselectedItem,
                    '&.Mui-checked': {
                        color: colors.selectedItem,
                    },
                }}
                type="checkbox"
                id={selected + '-' + props.index}
                name={props.value}
                checked={selected}
                value={selected === true ? '0' : '1'}
                onClick={() => { toggleCheck()}}
            />
            {disabled === true ? (
            <TextField
                id="standard-full-width"
                //label={field.title}
                style={{ margin: 0, marginLeft: '5px' }}
                type="text"
                //placeholder={t('fields.format-choice-placeholder-'+field.visuelFormat.toLowerCase())}
                helperText=""
                fullWidth={false}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.fieldRight}
                value={selected?'YES':'NO'}
                disabled={true}
                InputProps={{ disableUnderline: true }}
            />
            ) :
            (
            <DarkerDisabledTextField
                id="standard-full-width"
                //label={field.title}
                style={{ margin: 0, marginLeft: '5px' }}
                type="text"
                //placeholder={t('fields.format-choice-placeholder-'+field.visuelFormat.toLowerCase())}
                helperText=""
                fullWidth={false}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                className={classes.fieldRight}
                value={selected?'YES':'NO'}
                disabled={true}
                InputProps={{ disableUnderline: true }}
            />
            )}
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatBooleanComponent);





