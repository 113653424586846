import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {connect} from "react-redux";
import _, {isString} from "lodash";
import Immutable from "seamless-immutable";
import moment from "moment";
import {isJsonString, isJsonStringObject} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFile,
    faFileArchive,
    faFileAudio,
    faFileCode,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faStickyNote
} from "@fortawesome/free-regular-svg-icons";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import Plugforms from "../plugforms/Plugforms";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {confirmAlert} from "react-confirm-alert";
import colors from '../../assets/themes/Colors';
import ModalNoteComponent from "./ModalNoteComponent";
/*import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';*/
import parse from 'html-react-parser'

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '70px',
        display: 'visible',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        // position: 'absolute',
        width : '100%'
    },
    openCloseRelative: {
        marginTop : 5,
        bottom: 0,
        position: 'relative',
        width : '100%',
    },
    openCloseButton : {
        float : 'right',
        marginRight :'10px'
    },
    openedCloseButton : {
        float : 'right',
        marginRight :'10px'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    pointer:{
        cursor: 'pointer'
    },
    listHeight:{
        height: '100%'
    },
    faIconSize: {
        fontSize: 20,
        color: '#51C3C1',
    },
    faIconView: {
        fontSize: 200,
        color: '#51C3C1',
    },
    modalFile: {
        border: 'none',
        width: '75vw',
        '@media (min-width: 640px)': {
            minWidth: '400px'
        },
        '@media (max-width: 639px)': {
            width: '95vw',
            marginLeft: '-9%'
        },
        height: '100%',
    },
    docStyle: {
        '@media (min-width: 1025px)': {
            minWidth: 750
        },
    },
    confirmContainer: {
        border: "1px solid #F9F9F9",
        backgroundColor: 'white',
        borderRadius: 15,
        padding: '15px',
    },
    confirmTitle: {
        color: 'black',
        fontSize: '30px',
    },
    confirmText: {
        color: '#88949C',
    },
    confirmButtons: {
        marginTop: '30px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    confirmButtonStyle: {
        width: "100px",
        padding: "4px 20px",
        borderRadius: "3px",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
            /* width: "100%", */
        },
    },
    confirmAffirmative: {
        color: "white",
        backgroundColor: "#58c3c1",
        border: "none",
    },
    confirmNegative: {
        color: "#E3E3E3",
        backgroundColor: "transparent",
        border: "2px solid #E3E3E3",
        fontWeight: "600",
    },
    notRender: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 15,
    },
    notRenderText: {
        fontWeight: 'bold',
        color: '#88949C',
    },
    noteRenderText: {
        fontWeight: 'bold',
        color: colors.unselected,
        width: '59vw',
        paddingTop: 15,
        position: 'relative'
    },
    noteIcon: {
        position: 'absolute',
        right: '-5px',
        top: 15,
        color: colors.selectedMain
    },
    imgThumbnail: {
        cursor: 'pointer',
        minWidth: '30px',
        height: 'auto',
        boxShadow: '2px 2px 2px',
        borderRadius : '4px'
    },

}));


/*const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});*/
//TransitionComponent={Transition}

const specialFields = ['plugform', 'textarea', 'list', 'single-select', 'free-single-select', 'multi-select', 'free-multi-select', 'single-table', 'free-single-table', 'multi-table', 'free-multi-table', 'qrcode', 'localis', 'glocalis'];

function ModalPlugnoteComponent(props) {
    const classes = useStyles();
    const {t, i18n} = useTranslation(['common', 'models'])
    const {code, folder, fiche} = props;

    const [openModal, setOpenModal] = React.useState(false);
    const [openSave, setOpenSave] = React.useState(false);
    const [openNote, setOpenNote] = React.useState(false);
    const [isOpenFloatingMenu, setIsOpenFloatingMenu] = React.useState(false);

    const plugnote = props.plugnote;
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [selectedNote, setSelectedNote] = useState(null);
    const [openClassify, setOpenClassify] = useState(false);

    const [dataHasChanged, setDataHasChanged] = React.useState(false);
    const [createDateWithAnnexe, setCreateDateWithAnnexe] = React.useState(false);
    const [plugnoteAnnexes, setPlugnotesAnnexes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [internalFetching, setInternalFetching] = useState(false);
    const [loaded, setLoaded] = useState(0);

    const [isOpenModalAnnexe, setIsOpenModalAnnexe] = React.useState(false);
    const [selectedAnnexe, setSelectedAnnexe] = useState(null);
    const [isSecondary, setSecondary] = useState(props.secondary || false) //la modale est-elle la principale ou une modale secondaire?

    const [model, setModel] = useState(null);
    const [modelLinked, setModelLinked] = useState(null);
    const [modelForHash, setModelForHash] = useState(null);
    const [dataLinked, setDataLinked] = useState(null);
    const [dataForHash, setDataForHash] = useState(null);
    const [owner, setOwner] = useState("")
    const [plugNb, setPlugNb] = useState("")
    const [reset, setReset] = useState(true);

    const [copy, setCopy] = useState(true);
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [searchPlugcode, setSearchPlugcode] = useState(code.length > 0 ? code : "");

    const [fields, setFields] = useState([]);

    const [nameModel, setNameModel] = useState('');

    const [width, setWidth] = useState(window.innerWidth);

    const [isOpenModalListAnnexesVersion, setIsOpenModalListAnnexesVersion] =
        React.useState(false);

    const [isOpenModalListAnnexes, setIsOpenModalListAnnexes] =
        React.useState(false);

    const [openModalPreview, setOpenModalPreview] = React.useState(false);
    const [selectedPreviewAnnexe, setSelectedPreviewAnnexe] = useState(null);
    const [images, setImages] = useState([null]);

    let isMobile = (width <= 900);

    const inputFile = useRef(null)

    const environment = getCurrentEnvironnement();

    const handleModalAnnexeClose = () => {
        setSelectedAnnexe(null);
        setIsOpenModalAnnexe(false);
    };

    const handleModalAnnexeOpen = (annexe) => {
        setSelectedAnnexe(annexe);
        setIsOpenModalAnnexe(true);
    };

    const handleSaveFictifComment = (annexe, comment) => {
        let annexes = plugnoteAnnexes;
        annexes.map((item, index) => {
            if (item.id === annexe.id) {
                item.comment = comment
            }

            return item;
        });
        setPlugnotesAnnexes(annexes);
        setDataHasChanged(true);
    }

    const handleDeleteAnnexeExternal = (annexe) => {
        let annexes = plugnoteAnnexes;
        let filteredAnnexes = annexes.filter((item, index) => {
            return item.id !== annexe.id;
        });
        filteredAnnexes.filter(Boolean);
        setPlugnotesAnnexes(filteredAnnexes);
        setDataHasChanged(true);
    };

    const handleDeleteAnnexe = (event, annexe, previousFile) => {
        event.preventDefault();
        event.stopPropagation();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={classes.confirmContainer}>
                        <h1 className={classes.confirmTitle}>{t("global.confirm")}</h1>
                        <p className={classes.confirmText}>
                            {t("plugnote.confirm-delete-annexe")}
                        </p>
                        <div className={classes.confirmButtons}>
                            <button
                                className={`${classes.confirmButtonStyle} ${classes.confirmNegative}`}
                                onClick={onClose}
                            >
                                {t("global.cancel")}
                            </button>
                            <button
                                className={`${classes.confirmButtonStyle} ${classes.confirmAffirmative}`}
                                onClick={() => {
                                    //props.attemptDeleteAnnexe(annexe.hash, environment.userhash);
                                    handleDeleteAnnexeExternal(annexe);
                                    previousFile();
                                    onClose();
                                }}
                            >
                                {t("global.yes")}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    /**
     * Quand l'utilisateur veut éditer un champ
     * @param value
     * @param field
     * @param index
     * @private
     */
    const handleEditValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleSaveNote = (title, content) => {
        setSelectedNote(null);
        let annexes = plugnoteAnnexes;
        let newId = typeof annexes[annexes.length - 1] === "undefined" ? 1 : annexes[annexes.length - 1].id + 1;
        annexes.push({
            id: newId,
            hash: undefined,
            type: 'note',
            name: title,
            title: title,
            comment: '',
            Webdoc: {
                hash: undefined,
                content: content
            },
        });
        setPlugnotesAnnexes(annexes);
        setDataHasChanged(true);
    };

    const handleUpdateNote = (title, content, annexehash, notehash) => {
        setSelectedNote(null);
        let annexes = plugnoteAnnexes;
        annexes.map((annexe, index) => {
            if (annexe.id === selectedNote.id && annexe.type === 'note') {
                annexe.name = title;
                annexe.title = title;
                annexe.Webdoc.content = content;
            }
        });
        setPlugnotesAnnexes(annexes);
        setDataHasChanged(true);
    }

    const handleEditPhoneValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleEditDateTime = (date, value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;

        listFields[index].visuelValue = date;

        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleClickCancelModal = () => {
        props.history.push("/pluglist");
        setOpenModal(false);
    };

    const handleClickContinueModal = () => {
        setOpenModal(false);
    };

    const _handleOpenModalListAnnexes = () => {
        setIsOpenModalListAnnexes(true);
    };

    const _handleCloseModalListAnnexes = () => {
        setIsOpenModalListAnnexes(false);
    };

    const onClickHandler = () => {
        const data = plugnoteAnnexes;
        for (let x = 0; x < selectedFiles.length; x++) {
            let tmpFile = URL.createObjectURL(selectedFiles[x]);
            if (typeof tmpFile !== 'undefined') {
                let fullName = selectedFiles[x].name;
                let name = fullName.split('.').slice(0, -1).join('.');
                let type = fullName.substring(fullName.lastIndexOf(".") + 1);
                let newId = typeof data[data.length - 1] === "undefined" ? 1 : data[data.length - 1].id + 1;
                let tmpPayload = {
                    id: newId,
                    hash: undefined,
                    type: type,
                    path: tmpFile,
                    name: name,
                    comment: "",
                    creationdate: undefined,
                    expirationdate: undefined,
                    groupid: undefined,
                    size: selectedFiles[x].size,
                    data: selectedFiles[x]
                };
                if(selectedFiles[x].hasOwnProperty('base64data')){
                    tmpPayload.base64data = selectedFiles[x].base64data
                }

                data.push(tmpPayload)
                setDataHasChanged(true);
            }
        }
        data.filter(Boolean);
        setPlugnotesAnnexes(data);
    };

    const handleCreateNote = () => {
        handleNoteOpen();
    }

    const checkMimeType = (event) => {
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
        // const types = ['image/png', 'image/jpeg', 'image/gif']
        // // loop access array
        // for(var x = 0; x<files.length; x++) {
        //     // compare file type find doesn't matach
        //     if (types.every(type => files[x].type !== type)) {
        //         // create error message and assign to container
        //         err[x] = files[x].type+' is not a supported format\n';
        //     }
        // };
        for (var z = 0; z < err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    };

    const maxSelectFile = (event) => {
        let files = event.target.files
        if (files.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time'
            event.target.value = null
            toast.warn(msg)
            return false;
        }
        return true;
    };

    const checkFileSize = (event) => {
        let files = event.target.files
        let size = 1000000000
        let err = [];
        for (var x = 0; x < files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type + 'is too large, please pick a smaller file\n';
            }
        }
        
        for (var z = 0; z < err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    };

    const onChangeHandler = event => {
        const files = event.target.files;
        if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
            setLoaded(0);
            setSelectedFiles(files);
            setCreateDateWithAnnexe(true);
        }
    };

    const renderAnnexePreview = (annexeToPreview) => {
        switch (annexeToPreview.type.toLowerCase()) {
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'png':
            case 'PNG':
            case 'gif':
                return <FontAwesomeIcon icon={faFile} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'mp4':
            case 'mkv':
            case 'avi':
            case 'mov':
                return <FontAwesomeIcon icon={faFileVideo} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'pdf':
                return <FontAwesomeIcon icon={faFilePdf} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'zip':
            case 'rar':
                return <FontAwesomeIcon icon={faFileArchive} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'mp3':
            case 'aac':
                return <FontAwesomeIcon icon={faFileAudio} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'html':
                return <FontAwesomeIcon icon={faFileCode} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'txt':
            case 'sheet':
            case 'doc':
            case 'docx':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'xls':
            case 'xlsx':
            case 'csv':
                return <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'note':
                return <FontAwesomeIcon icon={faStickyNote} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'pages':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;

            case 'pptx':
            case 'ppt':
                return <FontAwesomeIcon icon={faFilePowerpoint} size="lg" style={{fontSize: '50', marginRight: '10'}}/>;
        }
    };


    const handleClickAlertWithCancel = () => {
        setOpenModal(true);
    }

    const handleClickAlertWithoutCancel = () => {
        setOpenModal(true);
    }

    const handleSaveCreatePlugnoteConfirm = () => {

        setOpenSave(false);

        if (dataHasChanged) {
            if (checkData(fields)) {
                if (selectedFiles !== null) {
                    handleClickAlertWithCancel();
                } else {
                    handleClickAlertWithoutCancel();
                }
            } else {

                let dataForHash = buildNewAnnexe(fields);

                let payload = {
                    dataForHash,
                    email: props.email,
                    copy: false,
                    plugname: props.plugform.label,
                    plugkey: props.plugform.clef,
                    userindex : props.fiche,
                };

                //props.close();

                //props.attemptCreatePFCodePlugnote(payload, plugnoteAnnexes, props.plugform, environment.userhash);

            }
        }
        else {
            if (checkData(fields)) {
                if (selectedFiles !== null) {
                    handleClickAlertWithCancel();
                } else {
                    handleClickAlertWithoutCancel();
                }
            } else {
                toast.warn("Aucune donnée ajoutée")
            }
        }

    }

    /**
     * Ajout d'une vérif pour le type 'email'
     */
    const isEmail = (value) => {
        return /(.)+@(.)+/.test(value)
    }


    const handleChangeRadio = (value, indexation, field, index, multiselect, mandatory) => {

        let listFields = fields;

        if (mandatory) {
            if (listFields[index].value.includes(value)) {
                listFields[index].value = listFields[index].value.filter(item => item !== value)
            } else {
                if (!multiselect) {
                    listFields[index].value = [];
                    listFields[index].value.push(value);
                } else {
                    listFields[index].value.push(value);
                }
            }
        } else {
            if (listFields[index].value.includes(value)) {
                listFields[index].value = listFields[index].value.filter(item => item !== value)
            } else {
                if (!multiselect) {
                    listFields[index].value = [];
                    listFields[index].value.push(value);
                } else {
                    listFields[index].value.push(value);
                }
            }
        }
        setFields(listFields);
        setDataHasChanged(true);
    }

    const includesInJson = (search, value) => {
        return search.findIndex((item, index) => {
            return item.text === value
        })
    }

    const handleChangeRadioTable = (value, indexation, field, index, multiselect, mandatory) => {

        let listFields = fields;

        if (mandatory) {
            if (!multiselect) {
                listFields[index].value = [];
                listFields[index].value.push({"text": value, "value": 1});
            } else {
                listFields[index].value.push({"text": value, "value": 1});
            }
        } else {
            if (includesInJson(listFields[index].value, value) !== -1) {
                listFields[index].value = listFields[index].value.filter(item => item.text !== value)
            } else {
                if (!multiselect) {
                    listFields[index].value = [];
                    listFields[index].value.push({"text": value, "value": 1});
                } else {
                    listFields[index].value.push({"text": value, "value": 1});
                }
            }
        }

        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeInputTable = (value, indexation, field, index, multiselect, mandatory) => {

        let listFields = fields;

        if (mandatory) {
            if (!multiselect) {
                listFields[index].value = [];
                listFields[index].value.push({"text": value.text, "value": value.value});
            } else {
                if (includesInJson(listFields[index].value, value.text) === -1) {
                    listFields[index].value.push({"text": value.text, "value": value.value});
                } else {
                    listFields[index].value[includesInJson(listFields[index].value, value.text)].value = value.value;
                }
            }
        } else {
            if (!multiselect) {
                listFields[index].value = [];
                listFields[index].value.push({"text": value.text, "value": value.value});
            } else {
                if (includesInJson(listFields[index].value, value.text) === -1) {
                    listFields[index].value.push({"text": value.text, "value": value.value});
                } else {
                    listFields[index].value[includesInJson(listFields[index].value, value.text)].value = value.value;
                }
            }
        }

        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleUpdateInputTable = (value, indexation, field, index, multiselect, mandatory) => {

        let listFields = fields;
        let tmpValue = listFields[index].value;

        listFields[index].value = tmpValue.filter(item => value.includes(item.text));

        setFields(listFields);
        setDataHasChanged(true);

    }

    const handleChangeKeyword = (value, indexation, field, index) => {

        let listFields = fields;
        if (listFields[index].value.includes(value)) {
            listFields[index].value = listFields[index].value.filter(item => item !== value)
        } else {
            listFields[index].value.push(value);
        }

        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeBoolean = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangePlugcode = (plugcode, comment, field, index) => {
        let listFields = fields;
        listFields[index].value = plugcode + ';' + comment;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangePlugcodeInternal = (plugcode, comment, field, index, destinations) => {
        let listFields = [...fields];

        listFields[index].value = plugcode;

        if (destinations?.length > 0) {
            let updatedListFields =  listFields.map((field, index) => {
                if(!!field.id){
                    if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
                        let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
                        switch (field.format) {
                            case 'numeric':
                                let tmpValue = destination.value.replaceAll(",", ".");
                                return {...field, value : tmpValue, visuelValue :  tmpValue}
                            default:
                                return {...field, value : destination.value, visuelValue :  destination.value}
                        }
                    }
                }
                return field;
            });

            setFields(updatedListFields);
            setDataHasChanged(true);
        }else{
            setFields(listFields);
            setDataHasChanged(true);
        }
    }

    const handleChangePassword = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangeLocalisation = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangeExtData = async (destinations) => {
        let listFields = [...fields];

        if (destinations.length > 0) {
            let updatedListFields =  listFields.map((field, index) => {
                if(!!field.id){
                    if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
                        let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
                        return {...field, value : destination.value, visuelValue :  destination.value}
                    }
                }
                return field;
            });

            setFields(updatedListFields);
            setDataHasChanged(true);
        }

    }

    const handleChangeTableValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleChangeIntDataMaj = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangeTableRowsValue = (value, rows, field, index) => {
        let listFields = fields;
        listFields[index].value.value = value;
        listFields[index].value.list = rows;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleUploadClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const maxSelectFileDrop = (files) => {
        if (files.length > 1) {
            const msg = "Only 1 images can be uploaded at a time";
            toast.warn(msg);
            return false;
        }
        return true;
    };

    const checkFileSizeDrop = (files) => {
        let size = 100000000;
        let err = [];
        for (var x = 0; x < files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type + "is too large, please pick a smaller file\n";
            }
        }
        for (var z = 0; z < err.length; z++) {
            // if message not same old that mean has error
            // discard selected file
            toast.error(err[z]);
        }
        return true;
    };

    const onChangeHandlerDrop = (acceptedFiles) => {
        const files = acceptedFiles;

        if (maxSelectFileDrop(files) && checkFileSizeDrop(files)) {
            setLoaded(0);
            setSelectedFiles(files);
            setCreateDateWithAnnexe(true);
        }
    };

    /**
     * Construction de la nouvelle fiche à partir des champs
     * @param fields
     */
    const buildNewAnnexe = (fields) => {
        let newData = {};

        const modelForHash = props.plugform;

        fields.map((field) => {
            if (field.format === "list" || field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select" || field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            }
            else {
                newData["field" + field.id] = field.value;
            }

            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                newData["field" + field.id + "format"] = "select";
            }
            else if (field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                newData["field" + field.id + "format"] = "table";
            } else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if(!editable || (editable && field.value === "")){
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if (field.format && field.format === 'agenda' || field.format && field.format === 'birthday') {
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;
        });
        newData.Annexes = [];
        newData.lastversion_model = modelForHash ? modelForHash.version : null;
        newData.modid = modelForHash.id;
        newData.offline = modelForHash.offline;

        return newData;
    };

    const downloadEmployeeData = (event,annexe) => {
        // Fetch the dynamically generated excel document from the server.
        trackPromise(
            axios
                .get(
                    ApplicationConfig.apiUrl + "offline/annexe.json?annexehash=" + annexe.hash,
                    {
                        crossdomain: true,
                    }
                )
                .then((response) => {
                    if (response.data.result) {
                        const fileNameHeader = "x-suggested-filename";
                        const suggestedFileName = response.headers[fileNameHeader];
                        const effectiveFileName =
                            suggestedFileName === undefined
                                ? annexe.name + "." + annexe.type
                                : suggestedFileName;
                        var a = document.createElement("a"); //Create <a>
                        a.href = response.data.base64; //Image Base64 Goes here
                        a.download = effectiveFileName; //File name Here
                        a.click(); //Downloaded file
                    }
                })
                .catch((response) => {
                    console.error(
                        "Could not Download the Excel report from the backend.",
                        response
                    );
                })
        );
    };


    const checkData = (fields) => {
        return fields.some((field) => {
            if (field.format !== null && typeof field.format !== "undefined") {
                if (!!field.mandatory && field.mandatory === 1) {
                    if (field.format.includes('select')) {
                        return field.value.length === 0;
                    } else if (field.format === 'list') {
                        return ((!field.value || field.value === '[]' || field.value.length === 0) && field.format != "indice");
                    } else if (field.format === 'boolean') {
                        return (field.value === "");
                    } else {
                        return (!field.value && field.format != "indice");
                    }
                }
            }
        })
    }

    const handleNoteOpen = (event,annexe) => {
        event.preventDefault();
        event.stopPropagation();
        if(typeof annexe !== "undefined"){
            setSelectedNote(annexe);
        }else{
            setSelectedNote(null);
        }

        setOpenNote(true);

    };

    const handleNoteClose = () => {
        setOpenNote(false);
    };

    const handleCloseClassify = () => {
        setOpenClassify(false);
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const handleExitCreatePlugnote = () => {
        props.close();
    }

    const handleExitViewPlugnote = () => {
        props.close();
    }

    const getUrlPreviewAnnexe = (annexe) => {
        return annexe.path;
    }


    const handleModalListAnnexesVersionOpen = (event, annexehash) => {
        event.preventDefault();
        event.stopPropagation();
        props.attemptGetAnnexesVersion(annexehash);
        setIsOpenModalListAnnexesVersion(true);
    };

    const handlePreviewAnnexe = (event, hash) => {
        event.preventDefault();
        event.stopPropagation();
        const images = [];
        plugnoteAnnexes.forEach((annexeToPreview, index) => {
            switch (annexeToPreview.type.toLowerCase()) {
                case "jpg":
                case "JPG":
                case "jpeg":
                case "png":
                case "PNG":
                case "gif":
                    let url = getUrlPreviewAnnexe(annexeToPreview);
                    images.push(url);
                    if (hash === annexeToPreview.hash) {
                        setSelectedPreviewAnnexe(images.length - 1);
                    }
                    break;
                default:
                    break;
            }
        });
        setImages(images);
        setOpenModalPreview(true);
    };

    const handlePreviewPdfAnnexe = (event, url) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(url, "_blank");
        //setOpenPdfPreview(true);
        //setSelectedPreviewPdfAnnexe(url);
    };

    const renderAnnexeIconPreview = (annexeToPreview, iconMode) => { // iconMode : booléen qui détermine si on renvoit une simple icône générique ou une miniature
        // console.log(annexeToPreview);
        let isIconMode
        if(iconMode === null) isIconMode = true;
        else isIconMode = iconMode
        if (annexeToPreview !== null) {
            switch (annexeToPreview.type.toLowerCase()) {
                case "jpg":
                case "jpeg":
                case "png":
                case "gif":
                    if(!isIconMode) return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name}
                        // onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)}
                        className={classes.imgThumbnail}/>;
                    else return (
                        <FontAwesomeIcon
                            icon={faFileImage}
                            className={classes.faIconSize}
                        />
                    );

                case "mp4":
                case "mkv":
                case "avi":
                case "mov":
                    return (
                        <FontAwesomeIcon
                            icon={faFileVideo}
                            className={classes.faIconSize}
                        />
                    );

                case "pdf":
                    return (
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            className={classes.faIconSize}
                            onClick={(event) =>
                                handlePreviewPdfAnnexe(
                                    event,
                                    getUrlPreviewAnnexe(annexeToPreview)
                                )
                            }
                        />
                    );

                case "zip":
                case "rar":
                    return (
                        <FontAwesomeIcon
                            icon={faFileArchive}
                            className={classes.faIconSize}
                        />
                    );

                case "mp3":
                case "aac":
                    return (
                        <FontAwesomeIcon
                            icon={faFileAudio}
                            className={classes.faIconSize}
                        />
                    );

                case "html":
                    return (
                        <FontAwesomeIcon icon={faFileCode} className={classes.faIconSize} />
                    );

                case "txt":
                case "sheet":
                case "doc":
                case "docx":
                    return (
                        <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
                    );

                case "xls":
                case "xlsx":
                case "csv":
                    return (
                        <FontAwesomeIcon
                            icon={faFileExcel}
                            className={classes.faIconSize}
                        />
                    );

                case "note":
                    return (
                        <FontAwesomeIcon
                            icon={faStickyNote}
                            className={classes.faIconSize}
                        />
                    );

                case "pages":
                    return (
                        <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
                    );

                case "pptx":
                case "ppt":
                    return (
                        <FontAwesomeIcon
                            icon={faFilePowerpoint}
                            className={classes.faIconSize}
                        />
                    );
                default:
                    return (
                        <FontAwesomeIcon icon={faFile} className={classes.faIconSize} />
                    );
            }
        }
    };

    const renderAnnexeImagePreview = (annexeToPreview) => {
        switch (annexeToPreview.type.toLowerCase()) {
            case "jpg":
            case "JPG":
            case "jpeg":
            case "png":
            case "PNG":
            case "gif":
                /* case 'jfif': */
                return (
                    <img
                        src={annexeToPreview.path}
                        alt={annexeToPreview.name}
                        onClick={(event) =>
                            handlePreviewAnnexe(event, annexeToPreview.hash)
                        } /* className={classes.modalFile} */
                    />
                );

            case "mp4":
            case "mkv":
            case "avi":
            case "mov":
                return (
                    <video className={classes.modalFile} controls>
                        <source
                            src={annexeToPreview.path}
                            type={`video/${annexeToPreview.type.toLowerCase()}`}
                        />
                    </video>
                );

            case "pdf":
                if(annexeToPreview.hasOwnProperty('base64data')){
                    return (
                        <iframe src={annexeToPreview.base64data} className={classes.modalFile} />
                    );
                }else{
                    return (
                        <iframe src={annexeToPreview.path} className={classes.modalFile} />
                    );
                }

            case "zip":
            case "rar":
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon
                            icon={faFileArchive}
                            className={classes.faIconView}
                        />
                        <p className={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );

            case "mp3":
            case "aac":
                return (
                    <audio className={classes.modalFile} controls>
                        <source
                            src={annexeToPreview.path}
                            type={`audio/${annexeToPreview.type.toLowerCase()}`}
                        />
                    </audio>
                );

            case "html":
                return (
                    <iframe src={annexeToPreview.path} className={classes.modalFile} />
                );

            case "txt":
                return (
                    <iframe
                        src={annexeToPreview.path}
                        className={classes.modalFile}
                        onClick={(event) =>
                            handlePreviewPdfAnnexe(
                                event,
                                getUrlPreviewAnnexe(annexeToPreview)
                            )
                        }
                    />
                );

            case "sheet":
            case "doc":
            case "docx":
                /* return <iframe src={`https://docs.google.com/gview?url=${getUrlPreviewAnnexe(annexeToPreview)}&embedded=true`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />; */
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                        className={`${classes.modalFile} ${classes.docStyle} `}
                    />
                );

            case "xls":
            case "xlsx":
            case "csv":
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                        className={`${classes.modalFile} ${classes.docStyle} `}
                    />
                );

            case "note":
                return <div className={classes.noteRenderText}>
                {/* {t("multiple.file-not-previewed")} */}
                    {parse(annexeToPreview.Webdoc.content)}
                    <span
                        id="icon-file_copy"
                        className={`material-icons md-25 ${classes.noteIcon}`}
                        onClick={(event) =>
                            handleNoteOpen(event, annexeToPreview)
                        }>
                    create
                    </span>
                </div>;

            case "pages":
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
                        <p className={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );

            case "pptx":
            case "ppt":
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                        className={classes.modalFile}
                    />
                );
            default:
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon icon={faFile} className={classes.faIconView} />
                        <p className={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );
        }
    };

    useEffect(() => {
        let payload = {
            data : props.value.data,
            model : props.value.model,
            link : typeof props.value.link !== "undefined" ? props.value.link : null,
            plug : typeof props.value.plug !== "undefined" ? props.value.plug : null,
            proprio : !!props.value.proprio ? props.value.proprio : undefined
        }
        // console.log("modale props", props);
        // console.log("modale payload", payload);
        setNameModel(payload.model.label);
        setModelLinked(payload.link);
        setModel(payload.model);
        setModelForHash(payload.model);
        setDataLinked(payload.plug);
        setDataForHash(payload.data);
        setPlugnotesAnnexes(payload.data.Annexes)
        setOwner(payload.proprio)
        setPlugNb(props.value.envindex+"-"+props.value.data.userindex)
    }, [props.value])

    useEffect(() => {
        if (typeof model !== "undefined" && model !== null && modelLinked === null) {
            const labels = Object.keys(model).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key);
            });

            let champs = labels.map((item) => {
                return model[item];
            });

            let listFields = [];

            champs.forEach((field, index) => {
                if (field) {
                    const fieldIndex = index + 1;
                    let value = "";
                    let format = {};
                    let visuelFormat = {};
                    let show = false;
                    let event = "";
                    let encodable = false;
                    let disabled = false;
                    let visible = false;
                    let options = [];
                    let originalOptions = [];

                    if (modelForHash && modelForHash[`field${fieldIndex}formatoptions`]) {
                        options = typeof modelForHash[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])  : Object.create(JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]));
                        originalOptions =  typeof modelForHash[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(modelForHash[`field${fieldIndex}formatoptions`]) : Object.create(JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]));
                    }

                    // console.log('modale plugnote', modelForHash, model, dataForHash);

                    // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
                    if (!_.isEmpty(modelForHash)) {
                        // console.log('modelforhash', modelForHash);
                        format = modelForHash[`field${fieldIndex}format`];
                        encodable = modelForHash[`field${fieldIndex}encodable`] === 1;
                        visible = modelForHash[`field${fieldIndex}visible`];
                        visuelFormat = modelForHash[`field${fieldIndex}format`];
                    }

                    // Si c'est une présélection on initialise la valeur à un tableau vide
                    if (options != null && (options.presel || format === "list")) {
                        value = [];
                    }

                    if (!_.isEmpty(dataForHash)) {
                        if(format === 'numeric' && typeof dataForHash[`field${fieldIndex}`] !== "undefined"){
                            value = Number.isInteger(dataForHash[`field${fieldIndex}`]) ?  parseInt(dataForHash[`field${fieldIndex}`]) : parseFloat(dataForHash[`field${fieldIndex}`].replace(',', '.'));
                        }
                        else if(format === 'list'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined'){
                                value = [];
                            }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                let keywordList = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                value = [];
                                keywordList.map((item, index) => {
                                    value.push(item.text);
                                })
                            }
                        }
                        else if(format === 'plugform'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "index" : ""};
                            }
                            else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'qrcodeint'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"code" : "", "model" : "", "data" : "", "comment" : ""};
                            }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'tablemulti'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : ""};
                        }
                        else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                        }
                        else if(format === 'tablemultin'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "list" : []};
                            }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'smailvalidmulti'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "list" : []};
                            }
                            else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'document'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "list" : []};
                            }
                            else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'intdatamaj'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "updatedDate" : ""};
                            }
                            else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'pluglink'){
                            value = {"code" : JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]).pfcode, "clef" : modelForHash[`clef`], "userindex" : dataForHash[`userindex`]};
                        }
                        else{
                            value = dataForHash[`field${fieldIndex}`];
                        }
                    }

                    // On vérifie si le champ est une présélection avec les options
                    if (options != null && options.presel && format === 'select') {
                        if (Array.isArray(value) && Immutable.isImmutable(value)) {
                            value = Immutable.asMutable(value);
                        }

                        // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                        if (!Array.isArray(value)) {
                            if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                value = JSON.parse(value);
                            } else {
                                if (value && value != null) {
                                    value = [value];
                                } else {
                                    value = [];
                                }
                            }
                        }

                        switch (options.multiselect) {
                            case true :
                                if (options.editable) {
                                    format = "free-multi-select";
                                    visuelFormat = "free-multi-select";
                                }
                                else {
                                    format = "multi-select";
                                    visuelFormat = "multi-select";
                                }
                                break;
                            default:
                                if (options.editable) {
                                    format = "free-single-select";
                                    visuelFormat = "free-single-select";
                                }
                                else {
                                    format = "single-select";
                                    visuelFormat = "single-select";
                                }
                        }
                        options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                    }
                    else if (options != null && options.presel && format === 'table') {
                        if (Array.isArray(value) && Immutable.isImmutable(value)) {
                            value = Immutable.asMutable(value);
                        }

                        // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                        if (!Array.isArray(value)) {
                            if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                value = JSON.parse(value);
                            } else {
                                if (value && value != null) {
                                    value = [value];
                                } else {
                                    value = [];
                                }
                            }
                        }

                        switch (options.multiselect) {
                            case true :
                                if (options.editable) {
                                    format = "free-multi-table";
                                    visuelFormat = "free-multi-table";
                                }
                                else {
                                    format = "multi-table";
                                    visuelFormat = "multi-table";
                                }
                                break;
                            default:
                                if (options.editable) {
                                    format = "free-single-table";
                                    visuelFormat = "free-single-table";
                                }
                                else {
                                    format = "single-table";
                                    visuelFormat = "single-table";
                                }
                        }
                        options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                    }
                    else {

                        if (format === 'select') {
                            format = 'multi-select'
                            options = [];

                            if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                value = JSON.parse(value);
                            } else {
                                if (value && value != null) {
                                    value = [value];
                                } else {
                                    value = [];
                                }
                            }
                        }
                        else if (format === 'table') {
                            format = 'multi-table'
                            options = [];

                            if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                value = JSON.parse(value);
                            } else {
                                if (value && value != null) {
                                    value = [value];
                                } else {
                                    value = [];
                                }
                            }
                        }
                    }

                    if (format === "now" && !value) {
                        value = moment().format("YYYY-MM-DD HH:mm:ss");
                        setDataHasChanged(true);
                    }

                    if (format === "tablemultin" && value.length === 0) {
                        value = {"value" : "", "list" : []};
                    }

                    if (format === "smailvalidmulti" && value.length === 0) {
                        value = {"value" : "", "list" : []};
                    }

                    if (format === "document" && value.length === 0) {
                        value = {"value" : "", "list" : []};
                    }

                    if( (format === "free-multi-select" || format === "free-single-select") && value.length > 0){
                        value.map((item, index) => {
                            if(!options.presel.includes(item)){
                                options.presel.push(item);
                            }
                        })
                    }

                    if( (format === "free-multi-table" || format === "free-single-table") && value.length > 0){
                        value.map((item, index) => {
                            if(!options.presel.includes(item.text)){
                                options.presel.push(item.text);
                            }
                        })
                    }

                    let originalPresel = undefined;
                    if (typeof originalOptions.presel !== "undefined" && originalOptions.presel.length > 0) {
                        if (Array.isArray(originalOptions.presel)) {
                            originalPresel = originalOptions.presel
                        } else {
                            originalPresel = originalOptions.presel.split(';')
                        }
                    }

                    listFields.push({
                        id: fieldIndex,
                        title: modelForHash[`field${fieldIndex}label`],
                        titleTranslate: modelForHash[`field${fieldIndex}label`],
                        value: typeof value === "undefined" ? "" : value,
                        visuelValue: value,
                        encodable,
                        visible,
                        format,
                        visuelFormat,
                        options,
                        originalPresel,
                        mandatory: modelForHash[`field${fieldIndex}mandatory`],
                        show,
                        event: event,
                        disabled
                    });

                }
            });
            // console.log('listFields-1', listFields);

            setFields(listFields);

        } else if (typeof model !== "undefined" && model !== null && modelLinked !== null && typeof modelLinked !== "undefined") {
            const labels = Object.keys(model).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key);
            });

            let champs = labels.map((item) => {
                return model[item];
            });

            let listFields = [modelLinked];

            champs.forEach((field, index) => {
                if (field) {

                    const fieldIndex = index + 1;

                    let fieldF28Format = Object.keys(modelLinked).find(function (key) {
                        if (key.includes('format') && modelLinked[key].includes('pluglink')) {
                            let optionsFormat = modelLinked[key.concat('options')];
                            let includesFormatOptions = optionsFormat.includes(searchPlugcode);
                            if (includesFormatOptions) {
                                return JSON.parse(optionsFormat);
                            }
                        }

                    });

                    let value = "";

                    let format = {};
                    let visuelFormat = {};
                    let show = false;
                    let event = "";
                    let encodable = false;
                    let disabled = false;
                    let visible = false;

                    let options = [];

                    let originalOptions = [];


                    if (modelForHash && modelForHash[`field${fieldIndex}formatoptions`]) {
                        options = typeof modelForHash[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])  : Object.create(JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]));
                        originalOptions =  typeof modelForHash[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(modelForHash[`field${fieldIndex}formatoptions`]) : Object.create(JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]));
                    }

                    // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
                    if (!_.isEmpty(modelForHash)) {
                        format = modelForHash[`field${fieldIndex}format`];
                        encodable = modelForHash[`field${fieldIndex}encodable`] === 1;
                        visuelFormat = modelForHash[`field${fieldIndex}format`];
                        visible = modelForHash[`field${fieldIndex}visible`];
                    }

                    // Si c'est une préselection on initialise la valeur à un tableau vide
                    if ( options != null && (options.presel || format === "list")) {
                        value = [];
                    }

                    if (!_.isEmpty(dataForHash)) {
                        if(format === 'numeric' && typeof dataForHash[`field${fieldIndex}`] !== "undefined"){
                            value = Number.isInteger(dataForHash[`field${fieldIndex}`]) ?  parseInt(dataForHash[`field${fieldIndex}`]) : parseFloat(dataForHash[`field${fieldIndex}`].replace(',', '.'));
                        }else if(format === 'list'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined'){
                                value = [];
                            }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                let keywordList = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                value = [];
                                keywordList.map((item, index) => {
                                    value.push(item.text);
                                })
                            }
                        }else if(format === 'plugform'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "index" : ""};
                            }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }

                        else if(format === 'qrcodeint'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"code" : "", "model" : "", "data" : "", "comment" : ""};
                            }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }

                        else if(format === 'tablemulti'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                        }
                        else if(format === 'tablemultin'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "list" : []};
                            }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'smailvalidmulti'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "list" : []};
                            }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'document'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "list" : []};
                            }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'intdatamaj'){
                            if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                value = {"value" : "", "updatedDate" : ""};
                            }
                            else if( dataForHash[`field${fieldIndex}`].length > 0){
                                value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            }
                        }
                        else if(format === 'pluglink'){
                            value = {"code" : JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]).pfcode, "clef" : modelForHash[`clef`], "userindex" : dataForHash[`userindex`]};
                        }else{
                            value = dataForHash[`field${fieldIndex}`];
                        }
                    }

                    // On vérifie si le champ est une préselection avec les options
                    if (options != null && options.presel && format === 'select') {
                        if (Array.isArray(value) && Immutable.isImmutable(value)) {
                            value = Immutable.asMutable(value);
                        }

                        // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                        if (!Array.isArray(value)) {
                            if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                value = JSON.parse(value);
                            } else {
                                if (value && value != null) {
                                    value = [value];
                                } else {
                                    value = [];
                                }
                            }
                        }

                        switch (options.multiselect) {
                            case true :
                                if (options.editable) {
                                    format = "free-multi-select";
                                    visuelFormat = "free-multi-select";
                                }
                                else {
                                    format = "multi-select";
                                    visuelFormat = "multi-select";
                                }
                                break;
                            default:
                                if (options.editable) {
                                    format = "free-single-select";
                                    visuelFormat = "free-single-select";
                                }
                                else {
                                    format = "single-select";
                                    visuelFormat = "single-select";
                                }
                        }
                        options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                    }
                    else if (options != null && options.presel && format === 'table') {
                        if (Array.isArray(value) && Immutable.isImmutable(value)) {
                            value = Immutable.asMutable(value);
                        }

                        // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                        if (!Array.isArray(value)) {
                            if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                value = JSON.parse(value);
                            } else {
                                if (value && value != null) {
                                    value = [value];
                                } else {
                                    value = [];
                                }
                            }
                        }

                        switch (options.multiselect) {
                            case true :
                                if (options.editable) {
                                    format = "free-multi-table";
                                    visuelFormat = "free-multi-table";
                                }
                                else {
                                    format = "multi-table";
                                    visuelFormat = "multi-table";
                                }
                                break;
                            default:
                                if (options.editable) {
                                    format = "free-single-table";
                                    visuelFormat = "free-single-table";
                                }
                                else {
                                    format = "single-table";
                                    visuelFormat = "single-table";
                                }
                        }
                        options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                    }
                    else {

                        if (format === 'select') {
                            format = 'multi-select'
                            options = [];

                            if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                value = JSON.parse(value);
                            } else {
                                if (value && value != null) {
                                    value = [value];
                                } else {
                                    value = [];
                                }
                            }
                        }
                        else if (format === 'table') {
                            format = 'multi-table'
                            options = [];

                            if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                value = JSON.parse(value);
                            } else {
                                if (value && value != null) {
                                    value = [value];
                                } else {
                                    value = [];
                                }
                            }
                        }
                    }

                    if (format === "now" && !value) {
                        value = moment().format("YYYY-MM-DD HH:mm:ss");
                        setDataHasChanged(true);
                    }

                    if (format === "tablemultin" && value.length === 0) {
                        value = {"value" : "", "list" : []};
                    }

                    if (format === "smailvalidmulti" && value.length === 0) {
                        value = {"value" : "", "list" : []};
                    }

                    if (format === "document" && value.length === 0) {
                        value = {"value" : "", "list" : []};
                    }

                    if( (format === "free-multi-select" || format === "free-single-select") && value.length > 0){
                        value.map((item, index) => {
                            if(!options.presel.includes(item)){
                                options.presel.push(item);
                            }
                        })
                    }

                    if( (format === "free-multi-table" || format === "free-single-table") && value.length > 0){
                        value.map((item, index) => {
                            if(!options.presel.includes(item.text)){
                                options.presel.push(item.text);
                            }
                        })
                    }

                    let fieldF28 = [];

                    if (typeof fieldF28Format !== "undefined") {
                        if (isJsonString(modelLinked[fieldF28Format + 'options'])) {
                            let fieldF28Extract = JSON.parse(modelLinked[fieldF28Format + 'options']);
                            if(typeof fieldF28Extract.fields !== "undefined"){
                                if (isJsonString(fieldF28Extract.fields)) {
                                    fieldF28 = JSON.parse(fieldF28Extract.fields);
                                } else {
                                    fieldF28 = fieldF28Extract.fields;
                                }
                            }
                        }
                    }

                    if (fieldF28.length > 0 && fieldF28.find(subItem => subItem.to.toString() === fieldIndex.toString())) {
                        fieldF28.map((item, index) => {
                            if (fieldIndex.toString() === item.to.toString()) {
                                if (format.includes("select")) {
                                    if (Array.isArray(value) && Immutable.isImmutable(value)) {
                                        value = Immutable.asMutable(value);
                                    }
                                    // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                                    if (!Array.isArray(dataLinked["field" + item.from.toString()])) {
                                        if (dataLinked["field" + item.from.toString()] && dataLinked["field" + item.from.toString()] != null && dataLinked["field" + item.from.toString()].length && /^[\],:{}\s]*$/.test(dataLinked["field" + item.from.toString()].replace(/\\["\\\/bfnrtu]/g, "@").replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, "]").replace(/(?:^|:|,)(?:\s*\[)+/g, ""))) {
                                            value = JSON.parse(
                                                dataLinked["field" + item.from.toString()]
                                            );
                                        } else {
                                            if (
                                                dataLinked["field" + item.from.toString()] &&
                                                dataLinked["field" + item.from.toString()] != null
                                            ) {
                                                value = [dataLinked["field" + item.from.toString()]];
                                            } else {
                                                value = [];
                                            }
                                        }
                                    }
                                    if(Array.isArray(value) && value.length > 0){
                                        if(typeof options.presel === 'undefined'){
                                            options.presel = value;
                                        }else{
                                            options.presel = options.presel.concat(value);
                                        }
                                    }
                                }
                                else if (format.includes("table") && !format.includes('tablemulti')) {
                                    if (Array.isArray(value) && Immutable.isImmutable(value)) {
                                        value = Immutable.asMutable(value);
                                    }
                                    // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                                    if (
                                        !Array.isArray(dataLinked["field" + item.from.toString()])
                                    ) {
                                        if (
                                            dataLinked["field" + item.from.toString()] &&
                                            dataLinked["field" + item.from.toString()] != null &&
                                            dataLinked["field" + item.from.toString()].length &&
                                            /^[\],:{}\s]*$/.test(
                                                dataLinked["field" + item.from.toString()]
                                                    .replace(/\\["\\\/bfnrtu]/g, "@")
                                                    .replace(
                                                        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                                                        "]"
                                                    )
                                                    .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
                                            )
                                        ) {
                                            value = JSON.parse(
                                                dataLinked["field" + item.from.toString()]
                                            );
                                        } else {
                                            if (
                                                dataLinked["field" + item.from.toString()] &&
                                                dataLinked["field" + item.from.toString()] != null
                                            ) {
                                                value = [dataLinked["field" + item.from.toString()]];
                                            } else {
                                                value = [];
                                            }
                                        }
                                    }

                                    if(Array.isArray(value) && value.length > 0){
                                        if(typeof options.presel === 'undefined'){
                                            options.presel = value.map(e => e.text);
                                        }else{
                                            options.presel = options.presel.concat(value.map(e => e.text));
                                        }
                                    }
                                }
                                else if (format.includes("glocalis")) {
                                    if (
                                        typeof dataLinked["field" + item.from.toString()] !==
                                        "undefined"
                                    ) {
                                        value = dataLinked["field" + item.from.toString()];
                                    }
                                }
                                else if (format.includes("localis")){
                                    if (typeof dataLinked['field' + item.from.toString()] !== "undefined") {
                                        value = dataLinked['field' + item.from.toString()];
                                    }
                                }
                                else if(format.includes("tablemulti")  && !format.includes('tablemultin')){
                                    let tmp = dataLinked['field' + item.from.toString()];
                                    if (isJsonStringObject(tmp)) {
                                        let tmpValue = JSON.parse(tmp);
                                        let data = [];
                                        if(tmpValue.length > 0){
                                            tmpValue.map((v,i) => {
                                                let tmpObjectValues = Object.values(v);
                                                let finalData = {}
                                                if(options.length > 0){
                                                    options.map((option,optionIndex) => {
                                                        if (tmpObjectValues[optionIndex] !== undefined) {
                                                            finalData[option.id] = tmpObjectValues[optionIndex];
                                                        }
                                                    })
                                                    data.push(finalData);
                                                }
                                            });
                                        }
                                        value = data;
                                    }
                                }
                                else if(format.includes("tablemultin")){
                                    let tmp = dataLinked['field' + item.from.toString()];
                                    if (isJsonStringObject(tmp)) {
                                        value = JSON.parse(tmp);
                                    }
                                }
                                else if(format.includes("smailvalidmulti")){
                                    let tmp = dataLinked['field' + item.from.toString()];
                                    if (isJsonStringObject(tmp)) {
                                        value = JSON.parse(tmp);
                                    }
                                }
                                else if(format.includes("document")){
                                    let tmp = dataLinked['field' + item.from.toString()];
                                    if (isJsonStringObject(tmp)) {
                                        value = JSON.parse(tmp);
                                    }
                                }
                                else if (format.includes("plugform")){
                                    let tmp = dataLinked['field' + item.from.toString()];
                                    if (isJsonStringObject(tmp)) {
                                        value = JSON.parse(tmp);
                                    }
                                }
                                else if (format.includes("qrcodeint")){
                                    let tmp = dataLinked['field' + item.from.toString()];
                                    if (isJsonStringObject(tmp)) {
                                        value = JSON.parse(tmp);
                                    }
                                }
                                else if(format.includes("numeric")){
                                    value = dataLinked["field" + item.from.toString()];
                                }
                                else {
                                    let tmp = dataLinked["field" + item.from.toString()];

                                    if (!Array.isArray(tmp)) {
                                        if (tmp && tmp != null && tmp.length && /^[\],:{}\s]*$/.test(tmp.replace(/\\["\\\/bfnrtu]/g, "@").replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, "]").replace(/(?:^|:|,)(?:\s*\[)+/g, ""))) {
                                            if (isJsonStringObject(tmp)) {
                                                let tmpParse = JSON.parse(tmp);
                                                tmp = tmpParse.join(",");
                                            }
                                        }
                                    }

                                    if (value !== "" && tmp !== "") {
                                        value = value + "\n " + tmp;
                                    } else if (value === "" && tmp !== "") {
                                        value = tmp;
                                    }
                                }
                            }
                        });

                        if (value !== "") {
                            setDataHasChanged(true);
                            disabled = true;
                        }
                    }

                    let originalPresel = undefined;
                    if (typeof originalOptions.presel !== "undefined" && originalOptions.presel.length > 0) {
                        if (Array.isArray(originalOptions.presel)) {
                            originalPresel = originalOptions.presel
                        } else {
                            originalPresel = originalOptions.presel.split(';')
                        }
                    }

                    listFields.push({
                        id: fieldIndex,
                        title: modelForHash[`field${fieldIndex}label`],
                        titleTranslate: modelForHash[`field${fieldIndex}label`],
                        value: value,
                        visuelValue: value,
                        encodable,
                        visible,
                        format,
                        visuelFormat,
                        options,
                        originalPresel,
                        mandatory: modelForHash[`field${fieldIndex}mandatory`],
                        show,
                        disabled,
                        event: event
                    });

                }
            });

            // console.log('listFields-2', listFields);

            setFields(listFields);
        }


    }, [model]);

    useEffect(() => {
        if (createDateWithAnnexe) {
            if (selectedFiles.length > 0) {
                onClickHandler();
                setCreateDateWithAnnexe(false);
            }
        }
    }, [createDateWithAnnexe]);


    // console.log('modelForHash',modelForHash);

    return (
        <Dialog
            fullScreen={width > 640? !isSecondary : true}
            fullWidth={width > 640? isSecondary : true}
            style={{ padding: '0px 0px 0px 0px' }}
            maxWidth={isSecondary? "md" : "xl"}
            disableEscapeKeyDown
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    props.close()
                }
            }}
            BackdropProps={{style: { backgroundColor: colors.blackAlpha}}}
            open={props.open}
            onClick={(e) => {e.preventDefault(); e.stopPropagation()}} >

            { modelForHash !== null ?
                <Plugforms
                    codes={props.codes}
                    download={false}
                    mode={isSecondary? "modal-external-view" : "external-view"}
                    hashOwnerModel={modelForHash?.mainOwner || null}
                    disabled={true}
                    logo={props.logo}
                    model={modelForHash}
                    shared={true}
                    fields={fields}
                    handleEditValue={handleEditValue}
                    handleEditDateTime={handleEditDateTime}
                    plugnoteAnnexes={plugnoteAnnexes}
                    nameModel={nameModel}
                    plugnote={dataForHash}
                    handleExitViewPlugnote={handleExitViewPlugnote}
                    openSave={openSave}
                    openClassify={openClassify}
                    handleCloseClassify={handleCloseClassify}
                    handleSaveCreatePlugnoteConfirm={handleSaveCreatePlugnoteConfirm}
                    copy={copy}
                    email={email}
                    owner={owner}
                    plugNb={plugNb}
                    emailValid={emailValid}
                    handleChangeLocalisation={handleChangeLocalisation}
                    handleModalAnnexeOpen={handleModalAnnexeOpen}
                    handleNoteOpen={handleNoteOpen}
                    handleDeleteAnnexe={handleDeleteAnnexe}
                    environment={environment}
                    isOpenModalAnnexe={isOpenModalAnnexe}
                    handleModalAnnexeClose={handleModalAnnexeClose}
                    handleEditPhoneValue={handleEditPhoneValue}
                    handleChangeBoolean={handleChangeBoolean}
                    handleChangePassword={handleChangePassword}
                    handleChangePlugcode={handleChangePlugcode}
                    handleChangePlugcodeInternal={handleChangePlugcodeInternal}
                    handleChangeRadioTable={handleChangeRadioTable}
                    handleChangeInputTable={handleChangeInputTable}
                    handleUpdateInputTable={handleUpdateInputTable}
                    handleChangeKeyword={handleChangeKeyword}
                    handleChangeTableValue={handleChangeTableValue}
                    handleChangeIntDataMaj={handleChangeIntDataMaj}
                    handleChangeTableRowsValue={handleChangeTableRowsValue}
                    handleChangeExtData={handleChangeExtData}
                    selectedAnnexe={selectedAnnexe}
                    handleSaveFictifComment={handleSaveFictifComment}
                    setFields={setFields}
                    handleChangeRadio={handleChangeRadio}
                    onChangeHandlerDrop={onChangeHandlerDrop}
                    handleUploadClick={handleUploadClick}
                    isOpenModalListAnnexes={isOpenModalListAnnexes}
                    _handleOpenModalListAnnexes={_handleOpenModalListAnnexes}
                    _handleCloseModalListAnnexes={_handleCloseModalListAnnexes}
                    downloadEmployeeData={downloadEmployeeData}
                    handleModalListAnnexesVersionOpen={handleModalListAnnexesVersionOpen}
                    handlePreviewAnnexe={handlePreviewAnnexe}
                    handlePreviewPdfAnnexe={handlePreviewPdfAnnexe}
                    renderAnnexeIconPreview={renderAnnexeIconPreview}
                    renderAnnexeImagePreview={renderAnnexeImagePreview}
                />
                :
                <div>SYSTEM ERROR</div>
            }

            {
                openNote &&
                <ModalNoteComponent disabled={true} selectedNote={selectedNote} open={openNote}  close={handleNoteClose} handleSaveNote={handleSaveNote} handleUpdateNote={handleUpdateNote}/>
            }

        </Dialog>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {

    return {
        plugformlink: state.plugcode.plugformlink || null,
        plugformplug: state.plugcode.plugformplug || null,
        plugform: state.plugcode.plugform || null,
        plugnote: state.plugcode.plugnote || null,
        plugformowner: state.plugcode.plugformowner || null,
        plugnoteowner: state.plugcode.plugnoteowner || null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPlugnoteComponent)
