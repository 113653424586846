import React, { useState, useEffect } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import i18n from "i18next";
import {
    BlobProvider,
    Document,
    Font,
    Image,
    Link,
    Page, pdf,
    PDFDownloadLink,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';
import Images from "../../assets/themes/Images";
import popLig from "../../fonts/Poppins-Regular.ttf"
import popLigIt from "../../fonts/Poppins-LightItalic.ttf"
import popBol from "../../fonts/Poppins-Bold.ttf"
import popReg from "../../fonts/Poppins-Medium.ttf"
import ApplicationConfig from "../../config/ApplicationConfig";
import {getCurrentEnvironnement, getToken } from "../../utils/Common";
import axios from "axios";
import moment from "moment";
import colors from '../../assets/themes/Colors';
import { ModalTablemultiTableViewComponent } from '../modals/tablemulti/ModalTablemultiTableViewComponent';
import { ColorCheckbox } from '../custom/CustomComponents';

// related Plugnotes
import {trackPromise} from "react-promise-tracker";
import qs from "querystringify";
import {toast} from "react-toastify";
import { Translation } from 'react-i18next';
import { isDefined, isJsonString, returnJSON, urlify } from '../../utils/utils';
import {isNull} from "lodash";

const environment = getCurrentEnvironnement();

// Create styles
const styles = StyleSheet.create({
    mainCtn: {
        position: 'relative',
        flexGrow: 1,
        height: '100%',
        width: '100%',
        paddingBottom: 50,
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        margin : 50
    },
    //BODY
    bodyCtn: {
        flexDirection: 'column',
        marginTop: -20,
    },
    parent: {
        width: '40%',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    attName: {
        fontFamily: 'Poppins',
        fontSize: 10,
        wordWrap: 'break-word',
    },
    attInfos: {
        fontFamily: 'Poppins',
        fontWeight: 'light',
        fontSize: 10,
        fontStyle: 'italic',
    },
    child: {
        width: '20%',
        margin: '1%',
        aspectRatio: '1/1',
        maxHeight : "160px",
        alignItems : 'center',
        justifyContent: 'center',
    },
    thumbnailCtn: {
        // aspectRatio: 1,
        marginTop: 5,
        maxHeight: "130px",
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    maxChild: {
        width: '69%',
        margin: '1%',
    },
    minChild: {
        width: '5%',
        margin: '1%',
        alignItems : 'center',
        justifyContent: 'center',
    },
    section: {
        margin: 10,
        flexGrow: 1,
    },
    sectionAnnexes : {
        margin: 10,
        paddingHorizontal: 40,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 15
    },
    icon: {
        width: "30",
        height: "30",
    },
    iconImg: {
        // maxWidth: "auto",
        maxHeight: "130",
    },
    viewer: {
        paddingTop: 40,
        paddingBottom: 60,
    },
    paragraph: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1' /* Let it fill the entire space horizontally */
    },
    paragraphText : {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1' /* Let it fill the entire space horizontally */,
        marginBottom: 5
    },
    paragraphMain: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        margin : 5
    },
    paragraphPng: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'flex-start',
        margin : 5
    },
    paragraphImage: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'center',
        margin : 5
    },
    paragraphTextAnnexes: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'flex-start',
        margin : 5
    },
    viewLeft: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */
    },
    viewRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */
    },
    viewFieldsLeft: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        justifyContent: 'flex-start',
        flexDirection: "row",
    },
    viewFieldsRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '2' /* Let it fill the entire space horizontally */
    },
    viewTextRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        textAlign: 'right'
    },
    text: {
        width: "auto",
        fontWeight : 500,
        fontSize: 14,
    },
    textInfos: {
        textAlign : 'right',
        width: "auto",
        fontWeight : 100,
        fontSize: 8,
    },
    textUnder: {
        width: "auto",
        fontWeight : 500,
        fontSize: 14,
        textDecoration: 'underline',
    },
    textThin: {
        width: "auto",
        fontWeight : 300,
        fontSize: 12,
    },
    textThinSmall:{
        width: "auto",
        fontWeight : 200,
        fontSize: 10,
    },
    textLink: {
        width: "auto",
        color : "green",
        fontWeight : 300,
        fontSize: 12,
    },
    title:{
        marginBottom : 10
    },
    //region FOOTER
    footer: {
        position: 'absolute',
        width : '100%',
        bottom: 10,
        left: 0,
        right: 0,
        paddingLeft: 30,
        justifyContent: 'flex-start',
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    footerText: {
        justifyContent: 'flex-start',
        color: 'grey',
        textAlign : 'center',
        fontSize: 10,
        fontFamily: 'Poppins',
        fontStyle: 'italic',
        fontWeight: 'light'
    },
    footerTextLeft: {
        width : '20%',
        left : 10,
        fontSize: 10,
        justifyContent: 'center',
        textAlign : 'left',
        color: 'grey',
    },
    footerLayout: {
        width: '30%',
        right: 30,
        bottom: 45,
        fontSize: 10,
        justifyContent: 'flex-end',
        textAlign : 'right',
        color: 'grey',
        position: 'absolute',
    },
    footerPageNb: {
        width: '20%',
        right: 30,
        // bottom: 25,
        fontSize: 10,
        justifyContent: 'flex-end',
        textAlign : 'right',
        color: 'grey',
        // position: 'absolute',
    },
    plugNbText: {
        letterSpacing: 1,
        color: colors.unselected,
        fontSize: 11,
        fontWeight: 'bold',
        fontFamily: 'Poppins'
    },
    //#endregion
    //#region HEADER
    headerCtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '40px 50px 0px 50px',
        position: 'relative',
        minHeight: 130,
    },
    headerTextCtn: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    headerLogoCtn: {
        flex: 1,
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        position: 'absolute',
        maxHeight: 100,
        top: -30,
    },
    plugTitle: {
        color: colors.unselected,
        fontSize: 17,
        fontWeight: 'bold',
        fontFamily: 'Poppins',
        maxWidth: 'calc(100% - 200px)',
        flexWrap: 'wrap',
    },
    headerText: {
        textAlign: 'center',
        color: 'grey',
        fontSize: 12,
    },
    headerLogo: {
        width: '514px',
        objectFit: 'contain',
        top: 0,
    },
    //#endregion
    contentCtn: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%'
    },
    pageCtn: {},
    //#region Annexes
    attFieldCtn: {
        flex: 1,
        paddingTop: 20,
        position: 'absolute',
        top: 40,
        width: '100%'
    },
    noAttFieldCtn: {
        paddingTop: 20,
        position: 'absolute',
        bottom: 40
    },
    fullPageCtn: {
        flex: 1,
        padding: 50,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    fullPageImgCtn: {
        maxWidth: "auto",
        aspectRatio: '1/1',
    },
    imgLegendCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 6
    },
    //#endregion
    //#region Related
    subheaderCtn: {
        flexDirection: 'column',
    },
    subheaderTextCtn: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 40,
    },
    //#endregion
    titleCtn: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignContent: 'center',
        marginBottom: 12
    },
    titleLine: {
        width: 100,
        height: 6,
        backgroundColor: colors.main,
        marginRight: 15
    },
    subtitle: {
        color: colors.unselected,
        fontWeight: 'bold',
        fontFamily: 'Poppins',
        fontSize: 11,
        letterSpacing: 1,
        marginTop: -5
    },
    fieldsCtn: {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        gap: 4,
    },
    mainFieldCtn: {
        flexDirection: 'column',
    },
    fieldCtn: {
        paddingLeft: 35,
        alignContent: 'flex-start',
        flexDirection: 'row',
        marginBottom: 8,
        minHeight: 25,
        height: 'auto',
    },
    fullWidthFieldCtn: {
        alignContent: 'center',
        flexDirection: 'column',
        marginBottom: 8,
        paddingLeft: 35,
        // backgroundColor: colors.cloud // pour test
    },
    fieldLabelCtn: {
        width: '25%',
    },
    fullWidthLabelCtn: {
        width: '100%',
        marginBottom: 10,
    },
    fieldLabel: {
        fontFamily: 'Poppins',
        color: colors.main,
        fontSize: 11,
    },
    fieldValue: {
        fontWeight: 'light',
        fontFamily: 'Poppins',
        fontSize: 11,
    },
    fieldValueCtn: {
        width: '75%',
        paddingLeft: 35,
        paddingRight: 20,
    },
    multilineValueCtn: {
        flexDirection: 'column',
    },
    signatureCtn: {
        alignItems : 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        maxHeight : '60px',
        width: '300px',
        marginBottom: 15,
        marginTop: -10,
        // borderBottomWidth: 1,
        // borderColor: "#c5c5c5",
    },
    signature: {
        width: "300px",
        objectFit: 'contain',
    },
    subfieldsCtn: {
        marginTop: -10,
        paddingHorizontal: 35,
        paddingVertical: 10,
    },
    subfieldsBorder: {
        paddingTop: 10,
        marginTop: -10,
        borderWidth: 1,
        borderColor: colors.main
    },
    fieldBtnCtn: {
        width: '75%',
        paddingLeft: 35,
        // display: 'inline-flex',
        // justifyContent: 'center',
        textAlign: 'center',
    },
    fullWidthValueCtn: {
        width: '100%',
        margin: '0px',
        padding: '5px 20px 5px 0px',
        // display: 'flex',
        // backgroundColor: colors.darkgrey // pour test
    },
    previewCtn: {
        border: 'none',
        width: '100%',
        minWidth: '400px',
        height: '100%',
        "@media (min-width:1025px)":{
            minWidth: '60vw',
        },
    },
    pdfCtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '50vh'
    },
    plugBtnCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
        paddingLeft: 35,
    },
    plugBtn: {
        borderRadius: '50%',
        color: 'white',
        backgroundColor: colors.mainButton,
        paddingHorizontal: 40,
        paddingVertical: 5,
        minWidth: 250,
        alignContent: 'center',
    },
    plugBtnText: {
        color: 'white',
        flexWrap: 'nowrap',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        alignSelf: 'center',
    }
});

Font.register({ family: 'Poppins', fonts: [
    { src: popBol, fontWeight: 'bold'},
    { src: popLig, fontWeight: 'light'},
    { src: popReg, fontWeight: 'normal'},
    { src: popLigIt, fontStyle: 'italic', fontWeight: 'light'},
]})

//http://test.plugnotes.com/uploads/uswE1SjDMGLkg0820759001492675386/daeMEUjoGm8Go0402037001604567020/somename%20(1).pdf

//downloadUrl

const downloadEmployeeData = (annexe) => {
    // Fetch the dynamically generated excel document from the server.
    return axios.get(ApplicationConfig.apiUrl + 'annexe.json?annexehash=' + annexe.hash, {crossdomain: true,
        'headers': {
            'X-AUTH-TOKEN': getToken(),
        }
    }).then((response) => {
        if (response.data.result){
            return response.data.base64; //Image Base64 Goes here
        }else {
            return false;
        }
    }).catch((response) => {
        return false;
    })
};


const getUrlPreviewAnnexe = (annexe, plugnote) => {
    return downloadEmployeeData(annexe);
    //return ApplicationConfig.downloadUrl + environment.userhash + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
}

const getUrlPreviewAnnexeUrl = (annexe, plugnote) => {
    return ApplicationConfig.downloadUrl + environment.userhash + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
}

const renderAnnexePreview = (annexeToPreview, plugnote, index) => {
    index++;
    switch (annexeToPreview.type.toLowerCase()) {
        case 'jpg':
        case 'JPG':
        case 'jpeg':
        case 'png':
        case 'PNG':
            return (
                <View style={[styles.parent]} key={index}>
                    <Text style={styles.attName}>{index}. {annexeToPreview.name}</Text>
                    <Text style={styles.attInfos}>{annexeToPreview.type.toUpperCase()} - {(annexeToPreview.size / 1000).toFixed(2) + ' kb'} - {annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('DD/MM/YYYY') : ''}</Text>
                    <View style={[styles.thumbnailCtn]}>
                        <Image src={getUrlPreviewAnnexe(annexeToPreview, plugnote)} style={styles.iconImg} />
                    </View>
                    <View style={styles.paragraphImage}>
                        {
                            typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                        }
                    </View>
                    {/* <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]}>
                        <Image src={getUrlPreviewAnnexe(annexeToPreview, plugnote)} style={styles.iconImg} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type + ' | Size: ' + ((annexeToPreview.size) / 1000).toFixed(2) + 'Kb' +  ' | Added: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('DD/MM/YYYY') : '') })</Text>
                        </View>
                    </View> */}
                </View>
            )
        case 'gif':
            return (
                <View style={[styles.parent]} key={index}>
                    <Text style={styles.attName}>{index}. {annexeToPreview.name}</Text>
                    <Text style={styles.attInfos}>{annexeToPreview.type.toUpperCase()} - {(annexeToPreview.size / 1000).toFixed(2) + ' kb'} - {annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('DD/MM/YYYY') : ''}</Text>
                    <View style={[styles.thumbnailCtn]}>
                        <Image src={Images.gifPng} style={styles.icon} />
                    </View>
                    <View style={styles.paragraphImage}>
                        {
                            typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                        }
                    </View>
                    {/* <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]}>
                        <Image src={Images.gifPng} style={styles.icon} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type + ' | Size: ' + ((annexeToPreview.size) / 1000).toFixed(2) + 'Kb' +  ' | Added: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('DD/MM/YYYY') : '') })</Text>
                        </View>
                    </View> */}
                </View>
            )
        case 'pdf':
            return (
                <View style={[styles.parent]} key={index}>
                    <Text style={styles.attName}>{index}. {annexeToPreview.name}</Text>
                    <Text style={styles.attInfos}>{annexeToPreview.type.toUpperCase()} - {(annexeToPreview.size / 1000).toFixed(2) + ' kb'} - {annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('DD/MM/YYYY') : ''}</Text>
                    <View style={[styles.thumbnailCtn]}>
                        <Image src={Images.pdfPng} style={styles.icon} />
                    </View>
                    <View style={styles.paragraphImage}>
                        {
                            typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                        }
                    </View>
                </View>
            )
        case 'txt':
        case 'sheet':
        case 'doc':
        case 'docx':
        case 'pages':
            return (
                <View style={[styles.parent]} key={index}>
                    <Text style={styles.attName}>{index}. {annexeToPreview.name}</Text>
                    <Text style={styles.attInfos}>{annexeToPreview.type.toUpperCase()} - {(annexeToPreview.size / 1000).toFixed(2) + ' kb'} - {annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('DD/MM/YYYY') : ''}</Text>
                    <View style={[styles.thumbnailCtn]}>
                        <Image src={Images.docPng} style={styles.icon} />
                    </View>
                    <View style={styles.paragraphImage}>
                        {
                            typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                        }
                    </View>
                </View>
            )
        /*        case 'xls':
                case 'xlsx':
                case 'csv':
                    return <Svg width={50}><FontAwesomeIcon icon={faFileExcel} size="lg" color='green' style={{fontSize : '50', marginRight : '10'}}  /></Svg>;
                    break;*/

        case 'note':
            return (
                <View style={[styles.parent]} key={index}>
                    <Text style={styles.attName}>{index}. {annexeToPreview.name}</Text>
                    <Text style={styles.attInfos}>{annexeToPreview.type} - {(annexeToPreview.size / 1000).toFixed(2) + ' kb'} - {annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('DD/MM/YYYY') : ''}</Text>
                    <View style={[styles.thumbnailCtn]}>
                        <Image src={Images.notePng} style={styles.icon} />
                    </View>
                    <View style={styles.paragraphImage}>
                        {
                            typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                        }
                    </View>
                </View>
            )
        case 'pptx':
        case 'ppt':
            return (
                <View style={[styles.parent]} key={index}>
                    <Text style={styles.attName}>{index}. {annexeToPreview.name}</Text>
                    <Text style={styles.attInfos}>{annexeToPreview.type.toUpperCase()} - {(annexeToPreview.size / 1000).toFixed(2) + ' kb'} - {annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('DD/MM/YYYY') : ''}</Text>
                    <View style={[styles.thumbnailCtn]}>
                        <Image src={Images.pptPng} style={styles.icon} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        {
                            typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                        }
                    </View>
                </View>
            )

        default:
            return (
                <View style={[styles.parent]} key={index}>
                    <Text style={styles.attName}>{index}. {annexeToPreview.name}</Text>
                    <Text style={styles.attInfos}>{annexeToPreview.type.toUpperCase()} - {(annexeToPreview.size / 1000).toFixed(2) + ' kb'} - {annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('DD/MM/YYYY') : ''}</Text>
                    <View style={[styles.thumbnailCtn]}>

                    </View>
                    <View style={styles.paragraphImage}>
                        {
                            typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                        }
                    </View>
                </View>
            )
    }
};

const renderFullPagePreview = (annexe, plugnote, index) => {
    index++;
    return (
        <View style={[styles.fullPageCtn]}>
            <View style={[styles.fullPageImgCtn]} key={index}>
                <Image src={getUrlPreviewAnnexe(annexe, plugnote)} style={styles.fullPageImgCtn} />
            </View>
            <View style={styles.imgLegendCtn}>
                <Text style={styles.attName}>{index}. {annexe.name} - </Text>
                <Text style={styles.attInfos}>{annexe.type.toUpperCase()} - {(annexe.size / 1000).toFixed(2) + ' kb'} - {annexe.creationdate ? moment(annexe.creationdate).format('DD/MM/YYYY') : ''}</Text>
            </View>
            {
                typeof annexe.comment !== "undefined" && annexe.comment.length > 0 &&
                <Text style={styles.textThin}>{annexe.comment}</Text>
            }
        </View>
    )
}

const renderSignature = (field, index, signature) => {
    let template = (
        <View style={[styles.fieldValueCtn, { width: '100%', alignSelf: 'center', paddingTop: 10 }]}>
            {/* <Text style={styles.fieldValue}>{field.value}</Text> */}
            <Text style={styles.fieldValue}>{i18n.t('common:fields.format-choice-sign')}</Text>
        </View>
    );

    return (
        <View
            wrap={false}
            style={styles.mainFieldCtn}
            key={'field-signature-' + index}
        >
            <View style={styles.fullWidthLabelCtn}>
                {template}
            </View>
            <View style={styles.signatureCtn}>
                <Image src={signature} style={styles.signature} />
            </View>
        </View>
    );
};

const renderFieldPreview = (value, format, options, field, index, pindex, showHidden, relPlugnote, model, mode) => {
    let isFullWidth = false;
    let template;
    let subfields = null;

    switch (format) {
        case 'qrcodeint' :
            let qrcodeintComment = typeof value.comment !==  "undefined" ? value.comment : "";
            let qrcodeintCode = typeof value.code !==  "undefined" ? value.code : "";
            if (qrcodeintComment !== "" && qrcodeintCode !== ""){
                let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                template = (
                    <View style={styles.fieldValueCtn}>
                        <Text style={styles.fieldValue}><Link src={url} target="_blank"><Text style={styles.fieldValue}>{qrcodeintCode}</Text></Link> - {qrcodeintComment}</Text>

                    </View>
                )
                subfields = (
                    <View style={styles.subfieldsCtn}>
                        <View style={styles.subfieldsBorder}>
                            {
                                relPlugnote !== undefined && relPlugnote !== null && relPlugnote.fields.map((field, subindex) => {
                                    return renderFieldPreview(field.value, field.format, field.options, field, subindex, undefined, showHidden, true, model, mode)
                                })
                            }
                        </View>
                    </View>
                )
            }
            else if (qrcodeintComment !== ""){
                template = (
                <View style={styles.fieldCtn}>
                    <View style={styles.fieldLabelCtn}>
                        <Text style={styles.fieldLabel}>{field.title}: </Text>
                    </View>
                    <View style={styles.fieldValueCtn}>
                        <Text style={styles.fieldValue}>{qrcodeintComment}</Text>
                    </View>
                </View>
                )
            }
            else if (qrcodeintCode !== ""){
                let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                template = (
                <View style={styles.fieldCtn}>
                    <View style={styles.fieldLabelCtn}>
                        <Text style={styles.fieldLabel}>{field.title}: </Text>
                    </View>
                    <View style={styles.fieldValueCtn}>
                        <Link src={url} target="_blank"><Text style={styles.fieldValue}>{qrcodeintCode}</Text></Link>
                    </View>
                </View>
                );
            }
            else {
                template = (
                    <View style={styles.fieldValueCtn}>
                        <Text style={styles.fieldValue}/>
                    </View>
                )
            }
            break;
        case 'document':
        case 'tablemultin':
            if (value !== '') {
                try {
                    if (value.hasOwnProperty('value') && value.hasOwnProperty('list')){
                        let formatOptions = typeof options !== "undefined" ?  JSON.parse(options) : field.options;
                        isFullWidth = true
                        template = (
                            <View style={styles.fullWidthValueCtn}>
                                <ModalTablemultiTableViewComponent
                                    colonnes={formatOptions.lines}
                                    field={field}
                                    isPreview={false}
                                    mode={mode}
                                    rows={value.list}
                                    toPrint={true}
                                    />
                            </View>
                        );
                    }
                    else {
                        template = (
                            <View style={styles.fieldValueCtn}>
                                <Text style={styles.fieldValue}/>
                            </View>
                        )
                    }
                }
                catch (e) {
                    template = (
                        <View style={styles.fieldValueCtn}>
                            <Text  style={styles.fieldValue}/>
                        </View>
                    )
                }
            }
            else {
                template = (
                    <View style={styles.fieldValueCtn}>
                        <Text  style={styles.fieldValue}/>
                    </View>
                )
            }
            break;
        case 'smailvalidmulti':
            if (value !== '') {
                isFullWidth = true
                try {
                    if (value.hasOwnProperty('value') && value.hasOwnProperty('list')){
                        let formatOptions = typeof options !== "undefined" ?  returnJSON(options) : field.options;

                        template = (
                        <View style={styles.fullWidthValueCtn}>
                            <ModalTablemultiTableViewComponent
                                colonnes={formatOptions.lines}
                                field={field}
                                isPreview={false}
                                mode={mode}
                                rows={value.list}
                                toPrint={true}
                            />
                        </View>
                        )
                    }
                    else {
                        template = (
                            <View style={styles.fieldValueCtn}>
                                <Text style={styles.fieldValue}/>
                            </View>
                        )
                    }
                }
                catch (e) {
                    template = (
                        <View style={styles.fieldValueCtn}>
                            <Text style={styles.fieldValue}>{e}</Text>
                        </View>
                    )
                }
            }
            else {
                template = (
                    <View style={styles.fieldValueCtn}>
                        <Text  style={styles.fieldValue}/>
                    </View>
                )
            }
            break;
        case 'tablemulti':
            let formatOptions = typeof options !== "undefined" ?  returnJSON(options) : field.options;
            let headers = formatOptions?.lines ? formatOptions.lines : formatOptions;
            isFullWidth = true

            template = (
                <View style={styles.fullWidthValueCtn}>
                    <ModalTablemultiTableViewComponent
                        colonnes={headers}
                        field={field}
                        isPreview={false}
                        mode={mode}
                        rows={Array.isArray(value) ? value : []}
                        toPrint={true}
                    />
                </View>
            )
            break;
        case 'boolean':
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text  style={styles.fieldValue}>{value === "1" ? 'oui/yes' : 'non/no'}</Text>
                </View>
            )
            break;
        case 'single-select':
        case 'free-single-select':
        case 'multi-select':
        case 'free-multi-select':
            let concatValue = value.join(', ')
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text  style={styles.fieldValue}>{concatValue}</Text>
                </View>
            )
            break;
        case 'single-table':
        case 'free-single-table':
        case 'multi-table':
        case 'free-multi-table':
            // console.log('select-table', field, value);
            let concatValueMultipleTable = '';
            value.forEach((item,index) => {
                if (typeof item.text !== "undefined"){
                    if (index < (value.length - 1 )){
                        concatValueMultipleTable += item.text + ' : ' + item.value + ' ~ '
                    }
                    else {
                        concatValueMultipleTable += item.text + ' : ' + item.value
                    }
                }
            })
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text  style={styles.fieldValue}>{concatValueMultipleTable}</Text>
                </View>
            )
            break;
        case 'numeric':
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text style={styles.fieldValue}>{isNaN(value) ? "" : value}</Text>
                </View>
            )
            break;
        case 'pluglink':
            if (typeof value.code !== 'undefined' &&  value.code !== '' && value.userindex !== 'undefined' &&  value.userindex !== ''){
                let source = ApplicationConfig.plugcodesUrl + value.code.toString() + '/' + value.clef.toString() +'/' + value.userindex.toString()
                if(typeof model !== "undefined" && !isNull(model)){
                    source = ApplicationConfig.plugcodesUrl + value.code.toString() + '/' + value.clef.toString() +'/' + value.userindex.toString() + '/' + model.hash.toString();
                }
                template = (
                    <View style={styles.fieldBtnCtn}>
                        <View style={styles.plugBtnCtn}>
                            <View style={styles.plugBtn}>
                                <Text maxLines={1} style={styles.textThin}>
                                    <Link rel="noopener noreferrer" src={source} style={styles.plugBtnText} target="_blank">{i18n.t('common:pluglink.fill-form-1') + ' [' + value.clef.toString() + ']'}</Link>
                                </Text>
                                <Text style={[styles.textThin]}>
                                    <Link rel="noopener noreferrer" src={source} style={styles.plugBtnText} target="_blank">{value.code.toString()}</Link>
                                </Text>
                            </View>
                        </View>
                    </View>
                )
            }
            else {
                template = (
                    <View style={styles.fieldValueCtn}>
                        <Text style={styles.textThin}/>
                    </View>
                )
            }
            break;
        case 'plugform' :
            let plugformValue = typeof value.value !==  "undefined" ? value.value : "";
            plugformValue = plugformValue.split('~')
            plugformValue = plugformValue.map(str => str.trim())
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text style={styles.fieldValue}>{plugformValue.join('\n')}</Text>
                </View>
            )
            break;
        case 'password':
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text  style={styles.fieldValue}>********</Text>
                </View>
            )
            break;
        case 'mail':
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text style={styles.fieldValue}><Link>{value}</Link></Text>
                </View>
            )
            break;
        case 'url':
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text style={styles.fieldValue}><Link src={value}>{value}</Link></Text>
                </View>
            )
            break;
        case 'glocalis':
            let jsonValue = value
            if (isJsonString(value)) {
                jsonValue = JSON.parse(value)
            }
            template= (
                <View style={styles.fieldValueCtn}>
                    <View style={styles.multilineValueCtn}>
                        <Text style={styles.fieldValue}>{jsonValue.address}</Text>
                        {jsonValue.latitude !== '' && jsonValue.longitude !== '' &&
                        <Text style={styles.fieldValue}>Latitude: {jsonValue.latitude} Longitude: {jsonValue.longitude}</Text>}
                        <Text style={styles.fieldValue}>(
                        <Link src={'http://maps.google.com/maps?daddr=' + encodeURI(jsonValue.address)}>
                            <Text>{i18n.t('common:print.open-maps')}</Text>
                        </Link>)
                    </Text>
                    </View>
                </View>
            )
            break;
        case 'localis':
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text style={styles.fieldValue}>{value}</Text>
                    <Text style={styles.fieldValue}>(
                        <Link src={'http://maps.google.com/maps?daddr=' + encodeURI(value)}>
                            <Text>{i18n.t('common:print.open-maps')}</Text>
                        </Link>)
                    </Text>

                </View>
            )
            break;
        default:
            template = (
                <View style={styles.fieldValueCtn}>
                    <Text style={styles.fieldValue}>{value}</Text>
                </View>
            );
            break;
    }

    if(typeof options !== "undefined" && options['hidden'] === true && !showHidden) return null;
    else return (
        <View
            // wrap={false}
            style={styles.mainFieldCtn}
            key={relPlugnote === true ? 'subfield-' + pindex + '-' + index : (pindex !== undefined ? 'plug-' + pindex + '-field-' + index : 'field-' + index) }
            >
            <View style={isFullWidth ? styles.fullWidthFieldCtn : styles.fieldCtn} >
                <View style={isFullWidth ? styles.fullWidthLabelCtn : styles.fieldLabelCtn}>
                    <Text style={[styles.fieldLabel, relPlugnote === true ? {paddingLeft: 20, color: colors.unselectedAlpha} : null]}>{field.title}: </Text>
                </View>
                <View>
                    {template}
                </View>
            </View>
            {
                subfields !== null &&
                <View
                >
                    {/* <Text>APERÇU</Text> */}
                    {subfields}
                </View>
            }
        </View>
    );
}
const imagesListType = ['jpg', 'jpeg', 'png'];
const formatsWithRelated = ['qrcode', 'qrcodeint', ] // F19 et F22

const PageFooter = () => {
    return (
        <View style={styles.footer} fixed>
            <Text style={styles.footerText}>{i18n.t('common:print.footer-msg')}Layout 2001</Text>
            <Text style={styles.footerPageNb} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} />
        </View>
    )
}

const MyDoc = (props) => {
    const prop = props.props;
    // console.log('MyDoc got props', prop);
    let user;
    if (props.user2 !== undefined) {
        user = prop.user.user;
    } else {
        user = prop.user;
    }
    const hasSignature = prop.fields.some(field =>(field.format === 'sign' && field.id === prop.index)) && isDefined(prop.signature);
    let profil = typeof prop.user.profil !== "undefined" ? prop.user.profil : null;
    // let plugnote = prop !== null ? prop.plugnote : undefined;
    let hasImgAnnexe = prop.annexes.some(attach => imagesListType.includes(attach.type))
    let model = prop.model;

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;

    //const logo = sessionStorage.getItem('logo');
    const logoSrc = isDefined(prop.logo) ? prop.logo : Images.logoPng;
    
    return (
        <Document>
            <Page size="A4" style={styles.viewer} wrap={true}>
                <View style={styles.headerCtn}>

                    <View style={styles.headerLogoCtn}>
                        <Image style={styles.headerLogo} src={logoSrc} resizeMode="center" />
                    </View>
                    <View style={styles.headerTextCtn}>
                        {prop.plugcode !== '' && <Text style={styles.plugNbText}>{prop.plugcode}</Text>}

                        <Text style={{ color: colors.unselected, fontSize: 13, fontFamily: 'Poppins' }}>{user.pseudo} {profil !== null && <Text style={{ fontSize: 13, fontFamily: 'Poppins', fontWeight: 'light' }}>{user.lastname} {user.firstname}</Text>}</Text>
                        <Text style={{ color: colors.unselected, fontSize: 9, fontFamily: 'Poppins' }}>{i18n.t('common:print.printed-on')}{today}</Text>
                    </View>
                </View>

                {/* Fields */}
                <View style={styles.bodyCtn} wrap>
                    <View style={styles.titleCtn}>
                        <View style={styles.titleLine} />
                        <Text style={styles.subtitle}>{prop.model.label.toUpperCase()} {isDefined(prop.filename) && prop.filename !== '' && '-' + prop.filename}</Text>
                    </View>
                    <View style={styles.section} wrap>
                        {
                            prop.fields.map((field, index) => {
                                let tmpValue = typeof field.value !== 'undefined' ? field.value.toString() : '';
                                let length = field.format === 'numeric' && tmpValue === 'NaN' ? 0 : tmpValue.length;
                                if (typeof tmpValue === 'string' && Object(tmpValue) && tmpValue !== null && field.format === 'plugform') {
                                    let tmp = field.value;
                                    if (tmp.hasOwnProperty('value')) {
                                        length = tmp.value.length;
                                    }
                                    else {
                                        length = 0;
                                    }
                                }
                                let relPlugnote = null
                                if (!!field && !!field.format && field.format.includes('qrcode')) {
                                    let code = field.value.code;
                                    if (typeof prop.related !== "undefined") {
                                        relPlugnote = prop.related.find((f) => f.filename === code)
                                    }
                                }

                                if (length !== 0) {
                                    return renderFieldPreview(field.value, field.format, model['field' + (index + 1) + 'formatoptions'], field, index, undefined, prop.showHidden, relPlugnote, model, prop.mode);
                                }
                                else {
                                    return null;
                                }
                            })
                        }
                        {
                            hasSignature &&
                            prop.fields.filter(field => field.id === prop.index)
                                .map((field, index) => renderSignature(field, index, prop.signature))
                        }
                    </View>
                </View>

                {
                    prop.showRelatedAttachments && prop.annexes.length === 0 &&
                    <View style={styles.noAttFieldCtn}>
                        <View style={styles.titleCtn}>
                            <View style={styles.titleLine} />
                            <Text style={styles.subtitle}>{i18n.t('common:print.att-empty')}</Text>
                        </View>
                    </View>
                }
                <PageFooter fixed />
            </Page>
            {
                prop.annexes.length > 0 &&
                <Page size="A4" style={styles.viewer} wrap>
                    <View style={styles.attFieldCtn}>
                        <View style={styles.titleCtn}>
                            <View style={styles.titleLine} />
                            <Text style={styles.subtitle}>{i18n.t('common:print.att-overview')}</Text>
                        </View>
                        <View style={styles.sectionAnnexes} break>
                            {
                                prop.annexes.map((annexe, index) => {
                                    return (renderAnnexePreview(annexe, prop.plugnote, index))
                                })
                            }
                        </View>
                    </View>
                    {/* <PageFooter /> */}
                </Page>
            }
            {
                prop.fullPageOption && hasImgAnnexe &&
                prop.annexes
                    .filter((attachment) => imagesListType.includes(attachment.type))
                    .map((annexe, pindex) => {
                        return (
                        <Page size="A4" style={styles.viewer} wrap key={"attachment-full-page-" + pindex}>
                            {
                                pindex < 1 &&
                                <View style={styles.titleCtn}>
                                    <View style={styles.titleLine} />
                                    <Text style={styles.subtitle}>{i18n.t('common:print.att-details')}</Text>
                                </View>
                            }
                            {
                                renderFullPagePreview(annexe, prop.plugnote, prop.annexes.indexOf(annexe))
                            }
                            {/* <PageFooter /> */}
                        </Page>
                        )
                    })
            }
            {
                prop.showRelated && prop.related?.length > 0 &&
                <Page size="A4" style={styles.viewer} wrap>
                    <View style={styles.attFieldCtn}>
                        <View style={styles.titleCtn}>
                            <View style={styles.titleLine} />
                            <Text style={styles.subtitle}>{i18n.t('common:print.related-plugnotes')}</Text>
                        </View>
                        {
                            prop.showRelated && prop.related.map((plugnote, pindex) => {
                                // console.log('Plugnote', plugnote);

                                return (
                                    <View key={'rel-plugnote-' + pindex} style={styles.subheaderCtn}>
                                        <View style={styles.subheaderTextCtn}>
                                            <Text style={styles.plugNbText}>Plugnote {pindex + 1 + ' - '} {plugnote.filename} - {i18n.t('common:print.from-field')} {plugnote.fieldId + ': ' + prop.fields[plugnote.fieldId - 1].title}</Text>

                                            <Text style={styles.plugTitle}>{plugnote.model.label.toUpperCase()}</Text>
                                            <Text>{'\n'}</Text>
                                            {/* <Text style={{ color: colors.unselectedMain, fontSize: 13, fontFamily: 'Poppins' }}>{prop.user.user.pseudo} {user !== null && <Text style={{ fontSize: 13, fontFamily: 'Poppins', fontWeight: 'light' }}>{user.lastname} {user.firstname}</Text>}</Text> */}
                                        </View>
                                        {
                                            prop.showRelated &&
                                            <View style={styles.bodyCtn} wrap>
                                                <View style={styles.titleCtn}>
                                                    <View style={styles.titleLine} />
                                                    <Text style={styles.subtitle}>{i18n.t('common:print.plugnote-content')}</Text>
                                                </View>
                                                {
                                                    plugnote.fields.map((field, index) => {
                                                        return renderFieldPreview(field.value, field.format, field.options, field, index, pindex, prop.showHidden,null, model, prop.mode)
                                                    })
                                                }


                                            </View>
                                        }
                                        {
                                            prop.showRelatedAttachments &&
                                            <View style={styles.titleCtn}>
                                                <View style={styles.titleLine} />
                                                <Text style={styles.subtitle}>{plugnote.data.Annexes.length > 0 ? i18n.t('common:print.related-att') : i18n.t('common:print.att-empty')}</Text>
                                            </View>
                                        }
                                        {
                                            prop.showRelatedAttachments &&
                                            <View style={styles.sectionAnnexes} break>
                                                {
                                                    plugnote.data.Annexes.length > 0 &&
                                                    plugnote.data.Annexes.map((annexe, index) => {
                                                        return (renderAnnexePreview(annexe, plugnote, index))
                                                    })
                                                }
                                            </View>
                                        }
                                    </View>
                                )
                            })
                        }
                    </View>
                    <PageFooter />
                </Page>
            }
        </Document>
    )
}

const ErrorToastMessage = ({ title, message }) => (
    <div>
        <Translation ns="common">
            {
                (t, { i18n }) => <h6>{t(title)}</h6>
            }
        </Translation>
        <Translation ns="common">
            {
                (t, { i18n }) => <p>{t(message)}</p>
            }
        </Translation>
    </div>
)

const useStyles = makeStyles((theme) => ({
    pointer:{
        cursor: 'pointer'
    },
    button: {
        display: 'flex',
        width: 150,
        alignItems: 'center',
        borderRadius: 6,
        fontSize: 30,
        height: 40,
        color: 'white',
        backgroundColor: colors.mainButton,
        margin: 50,
        cursor: 'pointer',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    optionCtn: {
        display: 'flex',
        gap: 5,
        flexDirection: 'column',
    },
    optionTxt: {
        cursor: 'pointer',
    },
    optionTitle: {
        fontSize: 18,
        alignSelf: 'center',
        margin: '8px 0px',
    },
}));

export function Data2001PrintableGetURL(props) {
    const blob = pdf(<MyDoc props={props}/>).toBlob();
    return blob;
}

function Data2001Printable(props) {
    const classes = useStyles();
    const [fullPageOption, setFullPageOption] = useState(false)
    const [relatedOption, setRelatedOption] = useState(false)
    const [relatedAttOption, setRelatedAttOption] = useState(false)
    const [hasHidden, setHasHidden] = useState(false)
    const [hiddenOption, setHiddenOption] = useState(false)
    const [loading, setLoading] = useState(true)
    const [related, setRelated] = useState([])
    const [validate, setValidate] = useState(null)
    const token = sessionStorage.getItem('token');

    // For dev purpose
    const [logoSrc, setLogoSrc] = useState('');
    const handleChangeLogoSrc = (e) => { setLogoSrc(e.target.value); console.log('logo?', e.target.value);}

    let hasRelatedPlug = props.fields.filter(field => field.format.includes('qrcode') && (field.value?.length > 0 || field.value.code !== ''))

    const toggleFullpageOption = () => {
        setFullPageOption(!fullPageOption)
    }

    const toggleRelatedOption = () => {
        setRelatedOption(!relatedOption)
    }

    const toggleRelatedAttOption = () => {
        setRelatedAttOption(!relatedAttOption)
    }

    const toggleHiddenOption = () => {
        setHiddenOption(!hiddenOption)
    }

    const handleValidate = () => {
        setValidate(true)
    }

    const searchCode = async (plugcode) => {
        let plugnote = null
        if (plugcode.startsWith("PF")) {
            await trackPromise(
                axios.get(ApplicationConfig.apiUrl + 'pfcode/modelcodelogin.json' + qs.stringify({
                    code: encodeURIComponent(plugcode),
                    uh: encodeURIComponent(props.userhash)
                }, '?'), { //token
                    headers: {
                        'X-AUTH-TOKEN': token
                    }
                })
                    .then((response) => {
                        // console.log('response from API :', response);
                        if (response.data.result === true || response.data.result === 'ok') {
                            plugnote = (response.data)
                        }
                        else if (response.data.result === false || response.data.result === 'onk') {
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.errorNote}`,
                            });
                            return (null)
                        }
                        else if (response.data.result === 'nok') {
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.errorNote}`,
                            });
                            return (null)
                        }
                        else {
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.errorNote}`,
                            });
                            return (null)
                        }
                    })
                    .catch((response) => {
                        //je lance une notification (toast error)
                        toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
            )
        }
        else {
            await trackPromise(
                axios.get(ApplicationConfig.apiUrl + 'pncode/datacodelogin.json' + qs.stringify({
                    code: encodeURIComponent(plugcode),
                    uh: encodeURIComponent(props.userhash)
                }, '?'),
                    { //token
                        headers: {
                            'X-AUTH-TOKEN': token
                        }
                    }
                )
                    .then((response) => {
                        if (response.statusText === "OK" && typeof response.data.result === 'undefined') {
                            plugnote = (response.data)
                        }
                        else if (response.data.result === false) {
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.errorNote}`,
                            });
                            return (null)
                        }
                        else if (response.data.result === 'nok') {
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.errorNote}`,
                            });
                            return (null)
                        }
                        else {
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.errorNote}`,
                            });
                            return (null)
                        }
                    })
                    .catch((response) => {
                        //je lance une notification (toast error)
                        toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
            )
        }
        return plugnote
    }

    useEffect(() => {
        Object.keys(props.model).forEach(key => {
            if (key.includes('visible') && props.model[key] === 0) setHasHidden(true)
        })
    }, [])

    useEffect(() => {
        if(related.length > 0) {
            related.forEach(plug => {
                Object.keys(plug.model).forEach(key => {
                    if (key.includes('visible') && plug.model[key] === 0) {
                        setHasHidden(true)
                    }
                })
            })
        }
    }, [related])

    useEffect(() => {
        if (loading) {
            if(hasRelatedPlug.length > 0) {
                hasRelatedPlug.forEach((plug) => {
                    let pCode = plug.value.code !== undefined ? plug.value.code : plug.value.split(';')[0]
                    let plugnote = null
                    searchCode(pCode)
                        .then((result) => {
                            plugnote = result
                            if (plugnote !== null) {
                                plugnote['filename'] = pCode
                                plugnote['fieldId'] = plug.id
                                let _related = [...related]
                                let fields = []
                                for (let index = 0; index < 15; index++) {
                                    let field = {
                                        format: returnJSON(plugnote.model['field' + (index + 1) + 'format']),
                                        options: returnJSON(plugnote.model['field' + (index + 1) + 'formatoptions']),
                                        id: index + 1,
                                        title: plugnote.model['field' + (index + 1) + 'label'],
                                        value: returnJSON(plugnote.data['field' + (index + 1)]),
                                    }
                                    if(plugnote.model['field' + (index + 1) + 'visible'] === 0) field.options['hidden'] = true

                                    let tmpValue = typeof field.value !== 'undefined' ? field.value.toString() : '';
                                    let length = field.format === 'numeric' && tmpValue === 'NaN' ? 0 : tmpValue.length;
                                    if (typeof tmpValue === 'string' && Object(tmpValue) && tmpValue !== null && field.format === 'plugform') {
                                        let tmp = field.value;
                                        if (tmp.hasOwnProperty('value')) {
                                            length = tmp.value.length;
                                        }
                                        else {
                                            length = 0;
                                        }
                                    }
                                    if (length !== 0) {
                                        fields.push(field)
                                    }
                                }
                                plugnote['fields'] = fields
                                _related.push(plugnote)
                                setRelated(_related)
                            }
                            setLoading(false)
                        })
                        .catch(e => console.log(e))
                })
            }
        }
    }, [relatedOption, relatedAttOption])

    if(isDefined(props.signature)){
        return (
            <BlobProvider document={<MyDoc props={{ ...props }} />}>
                {({blob, url, loading, error}) => {
                    if(blob !== null){
                        props.close();
                        let reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function() {
                            let file = new File([blob], (props.filename || props.fileName) + '.pdf');
                            file.base64data = reader.result;
                            props.onChangeHandlerDrop([file]);
                        }
                        // Do whatever you need with blob here
                    }
                    return '';
                }}
            </BlobProvider>
        )
    }else if (validate === null) return (
        <div className={classes.optionCtn}>
            <div className={classes.optionTitle}>{i18n.t('common:print.2001')}</div>
            <span>{i18n.t('common:print.print-option')}</span>
            <div onClick={toggleFullpageOption}>
                <ColorCheckbox
                    checked={fullPageOption}
                />
                <span className={classes.optionTxt}>{i18n.t('common:print.fullpage-att')}</span>
            </div>
            {
                hasRelatedPlug &&
                <div onClick={toggleRelatedOption}>
                    <ColorCheckbox
                        checked={relatedOption}
                    />
                    <span className={classes.optionTxt}>{i18n.t('common:print.include-related')}</span>
                </div>
            }
            {
                hasRelatedPlug &&
                <div onClick={toggleRelatedAttOption}>
                    <ColorCheckbox
                        checked={relatedAttOption}
                    />
                    <span className={classes.optionTxt}>{i18n.t('common:print.include-related-att')}</span>
                </div>
            }
            {
                hasHidden &&
                <div onClick={toggleHiddenOption}>
                    <ColorCheckbox
                        checked={hiddenOption}
                    />
                    <span className={classes.optionTxt}>{i18n.t('common:print.include-hidden')}</span>
                </div>
            }
            <div className={classes.button} onClick={handleValidate}>
                <span>{i18n.t('common:global.validate')}</span>
            </div>
        </div>
    )
    else return (
        <PDFDownloadLink
            document={
                <MyDoc props={{...props, fullPageOption: fullPageOption, related: related, showRelated: relatedOption, showRelatedAttachments: relatedAttOption, showHidden: hiddenOption}} />
            }
            fileName={(props.plugcode === '' ? props.fileName : props.plugcode) + '.pdf'}
            style={{ textDecoration: 'none', color: 'black', display: 'flex', height: '50vh', width: '100%'}}
        >
            {({ blob, url, loading, error }) =>
                loading ?
                    <div style={{ display: 'flex' }}>
                        <br />
                        <span className="material-icons md-25 "> hourglass_top </span>
                        <div className={classes.pointer}>{i18n.t('common:global.print-sync')}</div>
                    </div>
                    :
                    <div className={classes.pdfCtn}>
                        <iframe title='pdf-preview' src={url + "#toolbar=1&FitV"} className={classes.previewCtn} height="100%" style={{width: "calc(820px - 154px)", maxWidth: "calc(80vw - 154px)"}}/>
                        <br />
                        <div style={{ display: 'flex'}}>
                            <span className="material-icons md-25 "> picture_as_pdf </span>
                            <div className={classes.pointer}>{i18n.t('common:global.print')}</div>
                        </div>
                    </div>
            }
        </PDFDownloadLink>
    );
}

export default Data2001Printable;
