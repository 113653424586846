import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { connect } from "react-redux";
import { getCurrentEnvironnement } from "../../utils/Common";
import TextField from "@material-ui/core/TextField";
import {
  putAnnexeCommentModalRequest,
  putAnnexeCommentRequest,
} from "../../actions/annexe";

function getModalStyle() {
  const top = 5;

  return {
    top: `${top}%`,
    margin: "auto",
    overflow: "auto",
    //transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  popup: {
    position: "absolute",
    //width: '50%',
    //height : '50%',
    //backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    //boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
    minWidth: "50%",
    maxWidth: "90%",
    backgroundColor: "white",
    width: "820px",
    border: "1px solid #DEDEDE",
    boxSizing: "border-box",
    boxShadow:
      "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)",
    borderRadius: "20px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "90%",
    margin: "auto",
  },
  marginLeft: {
    marginLeft: "10px",
  },
  buttonSaveComment: {
    width: "91%",
    padding: '8px 20px',
    color: 'white',
    backgroundColor: '#58c3c1',
    border: 'none',
    borderRadius: '3px',
    margin: '5px auto',
    cursor: 'pointer',
  },
}));

const ModalAddAnnexeCommentComponent = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [t, i18n] = useTranslation("common");

  const [open, setOpen] = React.useState(true);
  const [fictif, setFicticf] = React.useState(
    typeof props.isFictif === "undefined" ? false : props.isFictif
  );
  const [comment, setComment] = React.useState("");
  const [modal, setModal] = React.useState(
    typeof props.modal !== "undefined" ? props.modal : false
  );

  const environment = getCurrentEnvironnement();

  const handleCloseModal = () => {
    props.close();
  };

  const handleSaveComment = () => {
    if (modal) {
      props.attemptUpdateCommentAnnexeModal(
        props.annexe.hash,
        comment,
        environment.userhash
      );
    } else {
      props.attemptUpdateCommentAnnexe(
        props.annexe.hash,
        comment,
        environment.userhash
      );
    }
    props.close();
  };

  const handleFictifSaveComment = () => {
    props.handleSaveFictifComment(props.annexe, comment);
    props.close();
  };

  return (
    <Modal
      keepMounted={false}
      open={props.open}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div key={props.countable} style={modalStyle} className={classes.popup}>
        <div className="scrollbar-hidden popup_wrapper flexSpacebetween">
          <div className="popup_wrapper_content">
            <div className="sous-header_modal_content">
              <div>
                <span className="titres">
                {t("search.plug-search-annexe-comment-type-title")} <br />
                </span>
              </div>
              <div onClick={handleCloseModal}>
                <span
                  id="icon-close"
                  className="material-icons md-50 lightgrey"
                >
                  close
                </span>
              </div>
            </div>
            <div className="sous-header_content lightgrey">
              <div className="line flex_style"></div>
            </div>
          </div>
          <div className="popup_wrapper_content">
            <TextField
              id="outlined-multiline-static"
              label={t("search.plug-search-annexe-comment-type-title")}
              multiline
              minRows={4}
              defaultValue={props.annexe.comment}
              variant="outlined"
              fullWidth={true}
              inputProps={{
                style: { padding: '10px' },
              }}
              onChange={(event) => setComment(event.target.value)}
            />
          </div>
          <button
            className={classes.buttonSaveComment}
            onClick={props.mode !== "external-shared-create" && props.mode !== "external-create" && props.mode !== 'external-shared-create' && props.mode !== 'modal-shared-create' && props.mode !== 'plugcode-complete-gallery' && props.mode !== 'plugcode-complete' ? handleSaveComment : handleFictifSaveComment }
            disabled={comment.length === 0}
          >
            {t("common:multiple.save")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    attemptUpdateCommentAnnexe: (annexehash, comment, userhash) =>
      dispatch(putAnnexeCommentRequest(annexehash, comment, userhash)),
    attemptUpdateCommentAnnexeModal: (annexehash, comment, userhash) =>
      dispatch(putAnnexeCommentModalRequest(annexehash, comment, userhash)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAddAnnexeCommentComponent);
