import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import colors from "../../assets/themes/Colors";
import ApplicationConfig from '../../config/ApplicationConfig';
import axios from "axios";
import {trackPromise} from "react-promise-tracker";
import {isNull} from "lodash";
import {getBuildDate} from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldTop:{
        width : '100%',
        // border: '1px solid #E3E3E3',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'baseline'
    },
    fieldValueCtn:{
        width : '100%',
        border: '1px solid #E3E3E3',
        borderRadius: '6px',
        marginTop: '5px',
        padding: '3px 10px',
        display: 'flex',
        flexDirection: 'row',
    },
    fieldBottom: {
        '& .MuiInputBase-inputMultiline': {
            alignSelf: 'flex-start',
        }
    },
    mainContainer:{
        minHeight: '40px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    rightBtns: {
        cursor: 'pointer',
        padding: '3px 6px',
        marginLeft: 5,
        borderRadius: "3px",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: colors.unselectedAlpha,
    },
    topRightBtns: {
        flexDirection: 'row',
        display: 'flex',
        maxHeight: 'none',
        height: 'auto',
        justifyContent: 'flex-end',
    },
    inputAdornmentCtn: {
        maxHeight: 'none',
        height: 'auto',
    },
    addFormBtn: {
        fontSize: '17px',
        minHeight: '40px',
        padding: '3px 6px',
        color: 'white!important',
        alignSelf: 'center',
        backgroundColor: colors.unselectedAlpha,
        borderRadius: '6px',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        whiteSpace: 'break-spaces',
    },
    folderText: {
        fontSize: 16,
        color: colors.unselected
    },
    formBtnText: {
        verticalAlign: '-webkit-baseline-middle',
        fontSize : 14,
        display: 'inline-flex',
        alignItems: 'center',
    },
    searchFieldCtn: {
        border: '1px solid',
        borderColor: colors.greyLight,
        borderRadius: 4,
        padding: '3px 10px',
    },
    searchField: {
        minWidth: 150,
        width: '50%',
        transition: 'margin 0.5s'
    },
    searchFieldfull: {
        minWidth: 150,
        width: '100%',
        transition: 'margin 0.5s'
    },
    searchCtn: {
        display: 'inline-flex',
        flexDirection: 'row',
        width: '-webkit-fill-available',
        gap: 8,
        justifyContent: 'space-between',
    },
    searchCtnTop: {
        marginTop :'10px',
        display: 'inline-flex',
        flexDirection: 'row',
        width: '-webkit-fill-available',
        gap: 8,
        justifyContent: 'space-between',
    },
    searchCtnWithout: {
        display: 'inline-flex',
        flexDirection: 'row',
        gap: 8,
        justifyContent: 'space-between',
    },
    searchBtnText: {
        '@media(max-width: 500px)': {
            display: 'none'
        }
    }
}));


const FormatIntDataMajComponent = (props) => {
    const classes = useStyles();
    const environment = getCurrentEnvironnement();
    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [owner, setOwner] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(false);
    const [clef, setClef] = useState(props.field.options.plugform);
    const [field, setField] = useState(props.field.options.field);

    let tmpUpdatedValue = "";

    if(!!props.field.value.updatedDate){
        if(props.field.value.updatedDate !== ""){
            tmpUpdatedValue = props.field.value.updatedDate;
        }
    }

    const [value, setValue] = useState(!!props.field.value.value ? props.field.value.value : "");
    const [updatedDate, setUpdatedDate] = useState(tmpUpdatedValue);
    const [model, setModel] = useState(null);
    const [filter, setFilter] = useState('')

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        console.log(props.field);
        setValue(!!props.field.value.value ? props.field.value.value : "");
        if(!!props.field.value.updatedDate){
            if(props.field.value.updatedDate !== ""){
                setUpdatedDate(props.field.value.updatedDate);
            }else {
                setUpdatedDate("");
            }
        }else{
            setUpdatedDate("");
        }
    },[props.field.value])

    const handleSelectPlugnote = (userindex) => {
        setValue(userindex);
        props.handleChangeIntDataMaj({"value": userindex, "updatedDate" : ""}, props.field, props.index);
        props.close();
    }

    const handleUpdateInternalPlugnote = () => {

        if(typeof props.dataHash !== "undefined"&& !isNull(props.dataHash)){
            let payload = {
                "plugnote_origin_hash" : props.dataHash,
                "plugnote_update_userindex" : value,
                "owner" : owner,
                "destination" : props.field.options.dest,
                "plugform" : props.field.options.plugform,

            }
            trackPromise(
                axios.post(ApplicationConfig.apiUrl + 'datas/updates.json?uh=' + environment.userhash , payload,
                    {
                        crossdomain: true,
                        'headers': {
                            'X-AUTH-TOKEN': getToken(),
                        }}
                ).then(async (response) => {
                    console.log(response);
                    if (response.data.result === true) {
                        let dateUpdated = await getBuildDate();
                        console.log('dateUpdated',dateUpdated);
                        setUpdatedDate(dateUpdated);
                        props.handleChangeIntDataMaj({
                            "value": value,
                            "updatedDate": dateUpdated
                        }, props.field, props.index);
                    }
                }).catch((response) => {

                })
            )
        }
    }

    return (
        <div className={classes.mainContainer}>
            <div className={classes.fieldTop}>
                {
                    typeof clef !== "undefined" ?
                        <div className={classes.folderCtn}>
                            <span className={classes.folderText}>From folder: </span>
                            <span className={classes.folderText}>
                                {
                                    "[" + clef + "]"
                                }
                            </span>
                        </div>
                        :
                        <span>not configured</span>
                }
            </div>
            <div className={classes.searchCtnWithout}>
                <TextField
                    InputProps={{
                        disableUnderline: false
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                    className={classes.searchFieldfull}
                    helperText=""
                    id="standard-full-width"
                    margin="none"
                    multiline={false}
                    onChange={(event) => handleSelectPlugnote(event.target.value)}
                    value={value}
                />
                {
                    updatedDate.length > 0 &&
                    <TextField
                        disabled={true}
                        className={classes.searchField}
                        helperText=""
                        id="standard-full-width"
                        margin="none"
                        multiline={false}
                        value={updatedDate}
                    />
                }
            </div>
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(FormatIntDataMajComponent);
