import * as moment from "moment";


// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') ||  null;
};

export const setToken = (token) => {
  sessionStorage.setItem('token',token);
};

export const removeToken = (token) => {
  sessionStorage.removeItem('token');
};


// return the token from the session storage
export const getDemoHash = () => {
  return localStorage.getItem('demo') ||  null;
};

export const setDemoHash = (hash) => {
  localStorage.setItem('demo',hash);
};

export const removeDemoHash = (hash) => {
  localStorage.removeItem('demo');
};

// return the token from the session storage
export const setCurrentEnvironnement = (currentEnvironnement) => {
  sessionStorage.setItem('currentEnvironnement', JSON.stringify(currentEnvironnement));
};

export const getCurrentEnvironnement = () => {
  const modelStr = sessionStorage.getItem('currentEnvironnement');
  if (modelStr) return JSON.parse(modelStr);
  else return null;
};

// return the token from the session storage
export const getModel = () => {
  const modelStr = sessionStorage.getItem('model');
  if (modelStr) return JSON.parse(modelStr);
  else return null;
};

// return the token from the session storage
export const setModel = (model) => {
  sessionStorage.setItem('model', JSON.stringify(model));
};

// return the token from the session storage
export const delModel = () => {
  sessionStorage.removeItem('model');
};

// return the token from the session storage
export const getModalModel = () => {
  const modelStr = sessionStorage.getItem('model-modal');
  if (modelStr) return JSON.parse(modelStr);
  else return null;
};

// return the token from the session storage
export const setModalModel = (model) => {
  sessionStorage.setItem('model-modal', JSON.stringify(model));
};

// return the token from the session storage
export const delModalModel = () => {
  sessionStorage.removeItem('model-modal');
};

// return the token from the session storage
export const getModelToUpdate = () => {
  const modelStr = sessionStorage.getItem('model-to-update');
  if (modelStr) return JSON.parse(modelStr);
  else return null;
};

// return the token from the session storage
export const setModelToUpdate = (model) => {
  sessionStorage.setItem('model-to-update', JSON.stringify(model));
};

// return the token from the session storage
export const removeModelToUpdate = () => {
  sessionStorage.removeItem('model-to-update');
};

// return the token from the session storage
export const getPlugnote = () => {
  const plugStr = sessionStorage.getItem('plugnote');
  if (plugStr) return JSON.parse(plugStr);
  else return null;
};

// return the token from the session storage
export const setPlugnote = (plugnote) => {
  sessionStorage.setItem('plugnote', JSON.stringify(plugnote));
};

// return the token from the session storage
export const delPlugnote = (plugnote) => {
  sessionStorage.removeItem('plugnote');
};

// return the token from the session storage
export const getModalPlugnote = () => {
  const plugStr = sessionStorage.getItem('plugnote-modal');
  if (plugStr) return JSON.parse(plugStr);
  else return null;
};

// return the token from the session storage
export const setModalPlugnote = (plugnote) => {
  sessionStorage.setItem('plugnote-modal', JSON.stringify(plugnote));
};

// return the token from the session storage
export const delModalPlugnote = (plugnote) => {
  sessionStorage.removeItem('plugnote-modal');
};

// return the token from the session storage
export const getAnnexes = () => {
  const annexesStr = sessionStorage.getItem('annexes');
  if (annexesStr) return JSON.parse(annexesStr);
  else return null;
};

// return the token from the session storage
export const setAnnexes = (annexes) => {
  sessionStorage.setItem('annexes', JSON.stringify(annexes));
};

// return the token from the session storage
export const delAnnexes = () => {
  sessionStorage.removeItem('annexes');
};

// return the token from the session storage
export const getModalAnnexes = () => {
  const annexesStr = sessionStorage.getItem('annexes-modal');
  if (annexesStr) return JSON.parse(annexesStr);
  else return null;
};

// return the token from the session storage
export const setModalAnnexes = (annexes) => {
  sessionStorage.setItem('annexes-modal', JSON.stringify(annexes));
};

// return the token from the session storage
export const delModalAnnexes = () => {
  sessionStorage.removeItem('annexes-modal');
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  //sessionStorage.removeItem('user');
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem('token', token);
  //sessionStorage.setItem('user', JSON.stringify(user));
};

export const getModelView = () => {
  return localStorage.getItem('movelView') ||  null;
};

export const setModelView = (value) => {
  localStorage.setItem('movelView',value);
};

export const getPlugnoteView = () => {
  return localStorage.getItem('plugnoteView') ||  null;
};

export const setPlugnoteView = (value) => {
  localStorage.setItem('plugnoteView',value);
};

export const getLoadModel = () => {
  return sessionStorage.getItem('loadModel') ||  null;
};

export const setLoadModel = (value) => {
  sessionStorage.setItem('loadModel',value);
};

export const getLoadSharedModel = () => {
  return sessionStorage.getItem('loadSharedModel') ||  null;
};

export const setLoadSharedModel = (value) => {
  sessionStorage.setItem('loadSharedModel',value);
};

export const logout = () => {
  sessionStorage.clear()
};

export const deleteHistoryAction = () => {
  delAnnexes();
  delModel();
  delPlugnote();
  removeModelToUpdate();
}

export const deleteHistoryPlugAction = () => {
  delAnnexes();
  delPlugnote();
  removeModelToUpdate();
}

// return the token from the session storage
export const getLanguage = () => {
  const languageStr = localStorage.getItem('language');
  if (languageStr) return languageStr;
  else return null;
};

// return the token from the session storage
export const setLanguage = (language) => {
  localStorage.setItem('language', language);
};


export const delFieldsSelected = () => {
  //sessionStorage.removeItem('fieldsSelected');
  let currentDate = moment(new Date());
  let canUpdate = false;

  let getCurrentFieldsStockage = JSON.parse(localStorage.getItem('fieldsSelected'));
  if(getCurrentFieldsStockage){
    let checkDate = getCurrentFieldsStockage.filter((item, index) => {
      let dateToCheck = moment(item.date,'YYYY/MM/DD');

      if(getModel() === null){
        canUpdate = true;
        if(currentDate.diff(dateToCheck, 'days') < 7) {
          return item;
        }
      }else{
        return item;
      }
    });



    let checkDateBoolean  = checkDate.filter(Boolean);

    if(canUpdate){
      localStorage.setItem('fieldsSelected', JSON.stringify(checkDateBoolean));
    }


  }
}

export const delSpecifiFieldsSelected = (hash) => {

  let getCurrentFieldsStockage = JSON.parse(localStorage.getItem('fieldsSelected'));
  if(getCurrentFieldsStockage){

    let list = getCurrentFieldsStockage.filter(item => item.hash !== hash)
    let listBoolean  = list.filter(Boolean);
    localStorage.setItem('fieldsSelected', JSON.stringify(listBoolean));
  }
};


// return the token from the session storage
export const getFieldsSelected = () => {
  const fieldSelectedStr = localStorage.getItem('fieldsSelected');
  if (fieldSelectedStr) return JSON.parse(fieldSelectedStr);
  else return null;
};

// return the token from the session storage
export const setFieldsSelected = (fields) => {
  let getCurrentFieldsStockage = JSON.parse(localStorage.getItem('fieldsSelected'));

  if(getCurrentFieldsStockage){

    let findIndex = getCurrentFieldsStockage.findIndex(item  => item.hash === fields.hash);
    if(findIndex !== -1){
      getCurrentFieldsStockage[findIndex] = fields;
    }
    else{
      getCurrentFieldsStockage.push(JSON.parse(JSON.stringify(fields)));
    }
    localStorage.setItem('fieldsSelected', JSON.stringify(getCurrentFieldsStockage));

  }
  else{
    var a = [];
    a.push(JSON.parse(JSON.stringify(fields)));
    localStorage.setItem('fieldsSelected', JSON.stringify(a));

  }
};

// return the token from the session storage
export const setFieldsSize = (fields) => {
  let getCurrentFieldsStockage = JSON.parse(localStorage.getItem('sizeFields'));

  if(getCurrentFieldsStockage){

    let findIndex = getCurrentFieldsStockage.findIndex(item  => item.hash === fields.hash);
    if(findIndex !== -1){
      getCurrentFieldsStockage[findIndex] = fields;
    }
    else{
      getCurrentFieldsStockage.push(JSON.parse(JSON.stringify(fields)));
    }
    localStorage.setItem('sizeFields', JSON.stringify(getCurrentFieldsStockage));

  }
  else{
    var a = [];
    a.push(JSON.parse(JSON.stringify(fields)));
    localStorage.setItem('sizeFields', JSON.stringify(a));

  }
};

export const delSpecifiFieldsSize = (hash) => {

  let getCurrentFieldsStockage = JSON.parse(localStorage.getItem('sizeFields'));
  if(getCurrentFieldsStockage){

    let list = getCurrentFieldsStockage.filter(item => item.hash !== hash)
    let listBoolean  = list.filter(Boolean);
    localStorage.setItem('fieldsSelected', JSON.stringify(listBoolean));
  }
};

export const setUserMail = (mail) => {
    localStorage.setItem('userMail', JSON.stringify(mail));
}
export const getUserMail = () => {
    return JSON.parse(localStorage.getItem('userMail'));
}