import React, { useEffect, useRef, useState } from 'react'
import { Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { isDefined } from '../../../utils/utils'
import NumberFormat from 'react-number-format'
import colors from '../../../assets/themes/Colors'
import { LockedIcon } from '../../custom/CustomIcons'
import { isEditableDefaultValue, isRowLimitReached } from '../../../utils/Fields'
import { RenderEditCellComponent } from './ModalTablemultiTableViewComponent'
import i18n from '../../../translations/i18n'
import { RiFileCopyLine } from "react-icons/all";
import PlugTooltip from '../../custom/PlugTooltip'


const useStyles = makeStyles(() => ({
  table: {
    borderColor: colors.unselectedAlpha,
    '& .MuiTableCell-root': {
      borderColor: colors.unselectedAlpha,
      borderRight: '1px solid ' + colors.unselectedAlpha,
    },
    borderLeft: '1px solid ' + colors.unselectedAlpha,
    borderTop: '1px solid ' + colors.unselectedAlpha,
  },
  tableHeader: {
    '& .MuiTableCell-root': {
      backgroundColor: colors.palePlugGreen,
    },
    '& tr:hover td:not(.TableSortingLabel__TableCell--head___yxhXj)': {
      backgroundColor: colors.palePlugGreen,
    },
  },
  dataCell: {
    '& .MuiTableCell-root': {
      backgroundColor: 'white !important',
      // borderColor: colors.unselectedAlpha,
    },
  },
  headerCell: {
    maxWidth: 200,
    '& .MuiTable-root .MuiTableCell-root': {
      width: 'min-content !important',
    },
  },
  headerIconCtn: {
    float: 'right',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  editedCellCtn: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
  },
  cellIconCtn: {
    // float: 'right',
    backgroundColor: colors.main,
    borderRadius: 6,
    cursor: 'pointer',
    padding: '4px 8px',
    order: 3,
  },
  disabledCellIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  actionsTableCellCtn: {
    display: 'flex',
    justifyContent: 'center'
  },
  duplicateBtnCtn: {
    cursor: 'pointer',
    color: colors.mainDark,
    top: 'calc(50% - 12px)',
  },
  deleteBtnCtn: {
    color: colors.mainDark,
    top: 'calc(50% - 12px)',
  },
  confirmDeleteCtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 4,
    maxWidth: 400,
    position: 'absolute',
    borderRadius: 8,
    right: -15,
    zIndex: 10,
    backgroundColor: 'white',
    padding: '4px 16px',
    boxShadow: '3px 0px 6px 4px rgba(136, 136, 136, 0.12)',
  }
}))

const TablemultiHorizontalViewComponent = (props) => {
  // devLog('HorizontalViewComponent got props', props)
  const classes = useStyles()
  const { t } = useTranslation('common')
  const colRefs = useRef([])

  // Variables
  const editedCell = props.editedCell
  const rows = props.rows
  const keyList = props.keyList
  const _defaultFontsize = props._defaultFontsize
  const _defaultLabelSize = props._defaultLabelSize
  const showRowNumbers = props.showRowNumbers

  // States
  const [rowToDelete, setRowToDelete] = useState(null)

  // const RenderEditCellComponent = (prop) => {
  //   return props.renderEditCellComponent(prop)
  // }

  const RenderCellValue = (prop) => {
    return props.renderCellValue(prop)
  }

  const handleClickTable = (e, rindex, id) => {
    e.preventDefault()
    e.stopPropagation()
    if (!props.openModal) { props.handleOpenModalView() } else {
      if (isDefined(rindex) && isDefined(id)) {
        if ((editedCell !== null && (editedCell.row !== rindex || editedCell.id !== id)) || editedCell === null) props.handleClickCell(e, rindex, id)
      }
    }
  }

  const handleClickDelete = (e, rindex) => {
    e.preventDefault()
    e.stopPropagation()
    setRowToDelete(rindex)
  }

  const handleDeleteRow = () => {
    props.handleDeleteRow(rowToDelete)
    setRowToDelete(null)
  }

  function handleClickDuplicate(row, rindex, e, id) {
    handleDuplicateRow(row, rindex, e, id);
  }

  function handleDuplicateRow(row, rindex, e, id) {
    if (row.hasOwnProperty('locked')) {
      let _newRowToDuplicate = { ...row }
      _newRowToDuplicate.locked = false;
      row = _newRowToDuplicate;
    }
    //props.setNewRow(null);
    props.handleDuplicateRow(row);
  }

  function isLimitReached() {
    return isRowLimitReached(props.field.options?.setup?.rowLimit, rows)
  }

  const isDefaultRowsQtyReached = () => {
    const defaultRows = props.field?.options?.setup?.defaultRows || [];
    let isRowsLengthBigger = rows.length >= defaultRows.length;
    return isRowsLengthBigger;
  }


  useEffect(() => {
    // devLog('edited cell props', editedCell)
  }, [editedCell])

  let decimalSeparator = ','
  let thousandSeparator = '.'
  if (i18n.language === 'en') {
    decimalSeparator = '.'
    thousandSeparator = ','
  }
  return (
    <Table
      className={classes.table}
      stickyHeader
      style={
        props.isModelPreview
          ? { maxWidth: '99%' }
          : undefined
      }
    >
      <TableBody sx={{ display: "table-header-group" }} aria-label="simple table" onClick={(e) => { handleClickTable(e) }}>
        {
          showRowNumbers &&
          <TableRow className={`${classes.tableHeader} ${classes.rowNbColumnHeader}`} >
            <TableCell align="center" className={classes.headerCell} width={30}>
              <span>N°</span>
            </TableCell>
            {
              rows.length > 0 &&
              rows
                .slice(0, props.isModelPreview ? 3 : 49)
                .filter(entry => entry?.recap !== true)
                .map((row, rindex) => {
                  let rowId = rindex + 1;
                  return (
                    <TableCell
                      align="center" key={"row-index-" + rindex}
                      style={{ backgroundColor: colors.palePlugGreen, position: 'relative' }}
                      className={`${classes.rowNbCell} ${classes.stickyColumn} `}
                      component="th"
                      scope="row"
                    >
                      <div className={classes.actionsTableCellCtn}>
                        <span className={classes.rowNb}>{rowId}</span>
                        {
                          props.openModal &&
                          <span className={classes.headerIconCtn} >
                            {
                              !row.locked &&
                              <PlugTooltip title={t('tablemulti.delete-row')}>
                                <span className={`${classes.deleteBtnCtn} material-icons`} onClick={(e) => handleClickDelete(e, rindex)}>
                                  delete
                                </span>
                              </PlugTooltip>
                            }
                          </span>
                        }
                        {
                          isDefaultRowsQtyReached() &&
                          <PlugTooltip title={'Dupliquer la ligne'} placement="top-end">
                            <span
                              className={classes.duplicateBtnCtn}
                              onClick={(e) => { if (!isLimitReached()) handleClickDuplicate(row, rindex, e, rowId) }}
                            >
                              <RiFileCopyLine
                                style={{ width: '1.4rem', height: '1.4rem', marginBottom: '-4px' }}
                              />
                            </span>
                          </PlugTooltip>
                        }
                        {
                          rowToDelete === rindex &&
                          <div className={classes.confirmDeleteCtn}>
                            <div>{t('tablemulti.confirm-delete')}</div>
                            <div>
                              <span className={classes.confirmBtn} onClick={() => setRowToDelete(null)}>
                                <span className={`${classes.confirmIcon} material-icons`} style={{ color: colors.plugformBlue }}>cancel</span>
                              </span>
                              <span className={classes.confirmBtn} onClick={handleDeleteRow}>
                                <span className={`${classes.confirmIcon} material-icons`} style={{ color: colors.bloodOrange }}>delete</span>
                              </span>
                            </div>
                          </div>
                        }
                      </div>
                    </TableCell>
                  )
                })
            }
            {
              rows.length === 0 &&
              <TableCell
                className={classes.dataCell}
                key={props.field.title + '-cell-empty-number'}
                style={{ position: 'relative' }}
                onClick={props.handleClickPreview}
              >
                <div className={classes.voidCell}>
                  <span className={classes.voidCell} />
                </div>
              </TableCell>
            }
          </TableRow>
        }
        {
          keyList.map((key, cindex) => {
            let col = props.columns.find(entry => entry.id.toString() === key)
            if ((props?.mode.includes('plugcode-complete') && col.encodable === false) || (props?.mode.includes('plugcode-consult') && col.visible === false)) { return null }
            if (props.mode === 'pdf' && col.showOnPdf === false) { return null }
            let lastRow = rows[rows.length - 1]
            let totals = []
            let total = ""
            if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
              totals = Object.keys(lastRow)
              if (totals.includes(col.id.toString())) {
                if (lastRow[col.id] !== null && lastRow[col.id] !== undefined && lastRow[col.id] !== "") {
                  if (lastRow[col.id].toString().includes(':')) total = lastRow[col.id];
                  else total = Math.round(lastRow[col.id] * 1000) / 1000
                }
              }
            }
            const headerStyle = {
              backgroundColor: colors.palePlugGreen,
              borderColor: colors.unselected,
              fontSize: isDefined(col.headerFontSize) ? col.headerFontSize : _defaultLabelSize,
              position: 'relative',
              minWidth: props.openModal ? 200 : 80,
              whiteSpace: "break-spaces",
            }

            if (props.isModelPreview) {
              {/* headerStyle.display = 'flex'
                                        headerStyle.flexDirection = 'column'*/}
            }

            const totalStyle = {
              fontSize: isDefined(col.fontSize) ? col.fontSize : _defaultLabelSize,
              verticalAlign: 'top',
              color: colors.bloodOrange,
            }
            switch (col.rowAlignment) {
              case 'left':
                totalStyle.marginRight = 'auto'
                break
              case 'right':
                totalStyle.marginLeft = 'auto'
                break
              case 'center':
              default:
                totalStyle.marginLeft = 'auto'
                totalStyle.marginRight = 'auto'
                break
            }

            return (
              <TableRow
                id={'table-row-' + props.field.title + '-' + key}
                key={'table-row-' + props.field.title + '-' + cindex}
              >
                <TableCell
                  align={col.labelAlignment || 'center'}
                  className={classes.headerCell}
                  style={headerStyle}
                  variant="head"
                >
                  <span >
                    {props.getColumnLabel(col, _defaultLabelSize, 'horizontal')}
                    {
                      total !== '' &&
                      <div className={classes.totalCtn} style={{ textAlign: col.rowAlignment || 'center' }}>
                        <NumberFormat value={total} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} displayType="text"
                          renderText={
                            (value, _) => <span className={classes.totalValues} style={totalStyle}>
                              {value}
                            </span>}
                        />
                      </div>
                    }
                  </span>
                  {
                    props.isModelPreview &&
                    <div
                      onMouseDown={() => props.onClickResizeColumn(cindex)}
                      ref={el => colRefs.current[cindex] = el}
                      className={classes.resizable}
                    />
                  }
                </TableCell>
                {
                  rows.length > 0 &&
                  rows.slice(0, props.isModelPreview ? 3 : 49)
                    .map((row, rindex) => {
                      if (row.recap) { return null }
                      const cellStyle = {
                        borderColor: colors.unselectedAlpha,
                        fontSize: isDefined(col.fontSize) && col.fontSize > -1 ? col.fontSize : _defaultFontsize,
                        // minWidth: col.colWidth > 0 ? col.colWidth : 160,
                        whiteSpace: "break-spaces",
                      }
                      const isEditable = isEditableDefaultValue(props.field, col.id, rindex, props.mode, props.isFromPwCode)

                      if (editedCell?.row === rindex && editedCell?.id.toString() === key && props.openModal) {
                        return (
                          <TableCell
                            align={col.rowAlignment || 'center'}
                            key={"cell-value-" + key + '-' + cindex + '-' + rindex}
                            style={{
                              ...cellStyle,
                              position: 'relative',
                            }}
                          // onClick={(e) => handleClickTable(e, rindex, key)}
                          >
                            <div className={classes.editedCellCtn} >
                              <RenderEditCellComponent
                                createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                annexes={props.annexes}
                                attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                attemptSendMailSimple={props.attemptSendMailSimple}
                                containerStyle={cellStyle}
                                col={col}
                                columns={props.columns}
                                control={props.control}
                                controlCreate={props.controlCreate}
                                controlUpdate={props.controlUpdate}
                                disabled={props.disabled}
                                edit="cell"
                                field={props.field}
                                fields={props.fields}
                                handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                handleChangeChildrenValue={props.handleChangeChildrenValue}
                                handleValidate={() => { }}
                                id={key}
                                key={'edited-cell-' + rindex + '-' + key}
                                mode={props.mode}
                                model={props.model}
                                open={true}
                                owner={props.owner}
                                ownerId={props.ownerId}
                                plugnote={props.plugnote}
                                renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                rindex={rindex}
                                cindex={cindex}
                                row={row}
                                share={props.share}
                                title={props.title}
                                user={props.user}
                                value={row[key]}
                                dataHash={props.dataHash}
                              />

                              <div className={classes.cellIconCtn} onClick={props.handleValidate} >
                                <span className="material-icons" style={{ color: 'white', fontWeight: 'bolder' }}>
                                  check
                                </span>
                              </div>
                            </div>
                            {
                              !isEditable && props.openModal &&
                              <PlugTooltip title={t('tablemulti.locked-default')} placement="right">
                                <div className={classes.disabledCellIcon} >
                                  <LockedIcon locked fontSize={12} />
                                </div>
                              </PlugTooltip>
                            }
                          </TableCell>
                        )
                      } else if (!isDefined(row[key]) || row[key] === '') {
                        return (
                          <TableCell
                            align={col.rowAlignment || 'center'}
                            className={classes.dataCell}
                            key={props.field.title + "-cell-" + key + '-' + cindex + '-' + rindex}
                            style={{ ...cellStyle, position: 'relative' }}
                            onClick={(e) => { if (isEditable) handleClickTable(e, rindex, key) }}
                          >
                            <div className={classes.voidCell}>
                              <span className={classes.voidCell} />
                            </div>
                            {
                              !isEditable && props.openModal &&
                              <PlugTooltip title={t('tablemulti.locked-default')} placement="right">
                                <div className={classes.disabledCellIcon} >
                                  <LockedIcon locked fontSize={12} />
                                </div>
                              </PlugTooltip>
                            }
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell
                            align={col.rowAlignment || 'center'}
                            key={"cell-value-" + key + '-' + cindex + '-' + rindex}
                            style={{ ...cellStyle, position: 'relative' }}
                            onClick={(e) => { if (isEditable) handleClickTable(e, rindex, key) }}
                          >
                            {
                              key === '1001' ?
                                <RenderEditCellComponent
                                  createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                  annexes={props.annexes}
                                  attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                  attemptSendMailSimple={props.attemptSendMailSimple}
                                  containerStyle={cellStyle}
                                  col={props.getCellColumn('1001', props.columns)}
                                  columns={props.columns}
                                  control={props.control}
                                  controlCreate={props.controlCreate}
                                  controlUpdate={props.controlUpdate}
                                  disabled={props.disabled}
                                  edit="preview"
                                  field={props.field}
                                  fields={props.fields}
                                  handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                  handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, props.getCellColumn('1001', props.columns), row)}
                                  handleValidate={() => { }}
                                  id="1001"
                                  isPreview
                                  key={'edit-cell-' + rindex + '-1001'}
                                  mode={props.mode}
                                  model={props.model}
                                  open={true}
                                  owner={props.owner}
                                  ownerId={props.ownerId}
                                  plugnote={props.plugnote}
                                  renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                  rindex={rindex}
                                  cindex={cindex}
                                  row={row}
                                  share={props.share}
                                  title={props.title}
                                  user={props.user}
                                  value={row['1001']}
                                  dataHash={props?.dataHash}
                                />
                                :
                                <RenderCellValue
                                  columns={props.columns}
                                  defaultFontsize={_defaultFontsize}
                                  id={key}
                                  value={row[key]}
                                  row={row}
                                  fields={props.fields}
                                />
                            }
                            {
                              !isEditable && props.openModal &&
                              <PlugTooltip title={t('tablemulti.locked-default')} placement="right">
                                <div className={classes.disabledCellIcon} >
                                  <LockedIcon locked fontSize={12} />
                                </div>
                              </PlugTooltip>
                            }
                          </TableCell>
                        )
                      }
                    })
                }
                {
                  rows.length === 0 &&
                  <TableCell
                    align={col.rowAlignment || 'center'}
                    className={classes.dataCell}
                    key={props.field.title + "-cell-" + key + '-' + cindex + '-empty'}
                    style={{ position: 'relative' }}
                    onClick={props.handleClickPreview}
                  >
                    <div className={classes.voidCell}>
                      <span className={classes.voidCell} />
                    </div>
                  </TableCell>
                }
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  )
}

export default TablemultiHorizontalViewComponent
