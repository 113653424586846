import React, {useState} from 'react';
import _ from "lodash";
import {connect} from "react-redux";
import {useLocation} from "react-router";
import ApplicationConfig from "../../config/ApplicationConfig";

/**
 * @return {null}
 */
function Intercom(props) {

    if(ApplicationConfig.intercom){
        window.Intercom('shutdown');
        window.Intercom('boot', {
            app_id: 'm63djv8b',
            hide_default_launcher : false,
        });
    }

    return null;
}

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Intercom)
