import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

import {Document, Image, Link, Page, pdf, PDFDownloadLink, StyleSheet, Text, View, BlobProvider} from '@react-pdf/renderer';
import Images from "../../assets/themes/Images";

import ApplicationConfig from "../../config/ApplicationConfig";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import axios from "axios";
import moment from "moment";
import {formatTextTypes} from "../../utils/Compatibility";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {blobToBase64, convertersNumberToAlpha} from "../../utils/utils";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {isNull} from "lodash";

const environment = getCurrentEnvironnement();


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        margin : 50
    },
    parent: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    child: {
        width: '20%',
        margin: '1%',
        aspectRatio: 1,
        maxHeight : "100px",
        alignItems : 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor : 'grey'
    },
    signature: {
        alignItems : 'center',
        justifyContent: 'center',
        maxHeight : '100px',
    },
    maxChild: {
        width: '69%',
        margin: '1%',
        borderWidth: 1,
        borderColor : 'grey'
    },
    minChild: {
        width: '5%',
        margin: '1%',
        alignItems : 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor : 'grey'
    },
    sectionHeader: {
        margin: 0,
    },
    section: {
        margin: 10,
        flexGrow: 0,
    },
    sectionAnnexes : {
        margin: 10,
        flexGrow: 0,
        paddingTop: 40,
        paddingBottom: 40,
    },
    viewer: {
        paddingTop: 10,
        paddingBottom: 0,
    },
    paragraph: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1' /* Let it fill the entire space horizontally */
    },
    paragraphText : {
        alignItems: 'center',
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1' /* Let it fill the entire space horizontally */,
        marginBottom: 5
    },
    paragraphMain: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        margin : 5
    },
    paragraphPng: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'flex-start',
        margin : 5
    },
    paragraphImage: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'center',
        margin : 5
    },
    paragraphTextAnnexes: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'flex-start',
        margin : 5
    },

    viewLeft: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */
    },
    viewRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */
    },
    viewFields: {
        width : '100%'
    },
    viewFieldsSub: {
        marginTop : 10,
        width : '100%',
        marginBottom : 10,
    },
    viewFieldsLeft: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        justifyContent: 'flex-start',
        flexDirection: "row",
    },
    viewFieldsLeftNoFlex: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: 'flex-start',
        flexDirection: "row",
    },
    viewFieldsCenter: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        justifyContent: 'center',
        flexDirection: "row",
    },
    viewFieldsRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '2' /* Let it fill the entire space horizontally */
    },
    viewTextRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        textAlign: 'right'
    },
    text: {
        width: "auto",
        fontWeight : 500,
        fontSize: 12,
    },
    textLabel:{
        width: "auto",
        fontWeight : 500,
        fontSize: 12,
    },
    textBlue: {
        width: "auto",
        fontWeight : 500,
        fontSize: 12,
        color : 'blue'
    },
    textInfos: {
        textAlign : 'right',
        width: "auto",
        fontWeight : 100,
        fontSize: 6,
    },
    textUnder: {
        width: "auto",
        fontWeight : 500,
        fontSize: 12,
        textDecoration: 'underline',
    },
    textThin: {
        width: "auto",
        fontWeight : 300,
        fontSize: 10,
        color : 'blue'
    },
    textThinBlue: {
        width: "auto",
        fontWeight : 300,
        fontSize: 10,
        color : 'blue'
    },
    textThinSmall:{
        width: "auto",
        fontWeight : 200,
        fontSize: 8,
    },
    textLink: {
        width: "auto",
        color : "green",
        fontWeight : 300,
        fontSize: 10,
    },
    image: {
        width: "50%",
    },
    icon: {
        width: "30",
        height: "30",
    },
    iconImg: {
        width: "200",
    },
    title:{
        marginBottom : 10
    },
    footer: {
        position: 'absolute',
        width : '100%',
        bottom: 10,
        left: 0,
        right: 0,
        justifyContent: 'center',
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    footerText: {
        width : '60%',
        justifyContent: 'center',
        color: 'grey',
        textAlign : 'center',
        fontSize: 10,
    },
    footerTextLeft: {
        width : '20%',
        left : 10,
        fontSize: 10,
        justifyContent: 'center',
        textAlign : 'left',
        color: 'grey',
    },
    footerTextRight: {
        right : 10,
        width : '20%',
        fontSize: 10,
        justifyContent: 'center',
        textAlign : 'right',
        color: 'grey',
    },
    header: {
        position: 'absolute',
        width : '100%',
        bottom: 0,
        top : 0,
        alignItems: 'center',
        left: 0,
        right: 0,
    },
    headerText: {
        textAlign: 'center',
        color: 'grey',
        fontSize: 10,
    },
    logo: {
        width: '186px'
    },
    logoCustom : {
        width : '100%'
    },
    textareaMemo : {
        border: 'none',
        resize: 'none',
        position: 'relative',
        margin: '5px',
    },
    divMemo : {
        padding: '5px',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
        borderTop: '1px solid black',
    },
    tabText:{
        color: 'black',
        fontSize:10,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    tableCol: {
        width : "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    },
    tableCellBlue: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10,
        color : 'blue'
    }
});

const useStyles = makeStyles((theme) => ({
    pointer:{
        cursor: 'pointer'
    }
}));

//http://test.plugnotes.com/uploads/uswE1SjDMGLkg0820759001492675386/daeMEUjoGm8Go0402037001604567020/somename%20(1).pdf

//downloadUrl

const renderFieldPreview = (value, format, options, field,ownerhash) => {

    switch (format) {
        case 'qrcodeint' :
            let qrcodeintComment = typeof value.comment !==  "undefined" ? value.comment : "";
            let qrcodeintCode = typeof value.code !==  "undefined" ? value.code : "";
            if(qrcodeintComment != "" && qrcodeintCode != ""){
                let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                return(
                    <View style={styles.divMemo}>
                        <View style={styles.textareaMemo}>
                            <Text style={styles.textThin}>{qrcodeintComment}</Text>
                            <Link src={url} target="_blank"><Text style={styles.textThin}>{url}</Text></Link>
                        </View>
                    </View>
                )
            }else if(qrcodeintComment !== ""){
                return(
                    <View style={styles.divMemo}>
                        <View style={styles.textareaMemo}>
                            <Text style={styles.textThin}>{qrcodeintComment}</Text>
                        </View>
                    </View>
                )
            }else if(qrcodeintCode !== ""){
                let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                return(
                    <View style={styles.divMemo}>
                        <View style={styles.textareaMemo}>
                            <Link src={url} target="_blank"><Text style={styles.textThin}>{url}</Text></Link>
                        </View>
                    </View>
                );
            }
            else{
                return null;
            }

        case 'sign':
            return (
            <Text  style={styles.textThin}>{value}</Text>
            )
        case 'tablemultin':
            try {
                let formatNOptions = typeof options !== "undefined" ? JSON.parse(options) : field.options;

                if (value !== ''){
                    let headers = formatNOptions.lines.map((elm, index) => elm.label);

                    let headersTemplate = headers.map((header, index) => {
                        return(
                            <View key={index} style={[styles.tableCol]}><Text style={styles.tableCell}>{header}</Text></View>
                        );
                    });

                    let valuesTemplate = ""

                    if (value !== '') {
                        /*                        valuesTemplate = value.list
                                                    .map((element, index) => {
                                                        return (
                                                            <View style={styles.tableRow} key={field.id + '-' + 'valuesTemplate-' + index}>
                                                                {
                                                                    Object.values(element).map((elm, elmIndex) =><View key={elmIndex} style={[styles.tableCol]}><Text style={styles.tableCellBlue}>{elm}</Text></View>)
                                                                }
                                                            </View>
                                                        )
                                                    });*/

                        valuesTemplate = value.list
                            .map((d, index) => {
                                return (
                                    <View style={styles.tableRow} key={field.id + '-valuesTemplate-' + index}>
                                        { formatNOptions.lines.map((element, index) => {
                                            let someText = typeof d[element.id] === 'string' ? d[element.id].replace(/\r?\n?/g, '').trim() : d[element.id] ;
                                            if(someText == undefined ){
                                                someText = "";
                                            }

                                            return <View key={index} style={[styles.tableCol]}><Text style={styles.tableCellBlue}>{someText}</Text></View>
                                        })}
                                    </View>
                                )
                            });
                    }

                    return (
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                {
                                    headersTemplate
                                }
                            </View>

                            {
                                valuesTemplate
                            }
                        </View>
                    );
                } else return null;
            }catch (e) {
                return null;
            }
        case 'smailvalidmulti':
            let formatNOptions = typeof options !== "undefined" ? JSON.parse(options) : field.options;

            if (value !== ''){
                let headers = formatNOptions.lines.map((elm, index) => elm.label);

                let headersTemplate = headers.map((header, index) => {
                    return(
                        <View key={index} style={[styles.tableCol]}><Text style={styles.tableCell}>{header}</Text></View>
                    );
                });

                let valuesTemplate = ""

                if (value !== '') {
                    valuesTemplate = value.list
                        .map((element, index) => {
                            return (
                                <View style={styles.tableRow} key={field.id + '-valuesTemplate-' + index}>
                                    {
                                        Object.values(element).map((elm, elmIndex) =><View key={elmIndex} style={[styles.tableCol]}><Text style={styles.tableCellBlue}>{elm}</Text></View>)
                                    }
                                </View>
                            )
                        });
                }

                return (
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            {
                                headersTemplate
                            }
                        </View>

                        {
                            valuesTemplate
                        }
                    </View>
                );
            }
            else return null;
        case 'tablemulti':
            try {


                let formatOptions = typeof options !== "undefined" ?  JSON.parse(options) : field.options;

                let optionsFormat = formatOptions?.lines ? formatOptions?.lines : formatOptions;


                if (value !== ''){
                    let headers = optionsFormat.map((elm, index) => elm.label);

                    let headersTemplate = headers.map((header, index) => {
                        return(
                            <View key={index} style={[styles.tableCol]}><Text style={styles.tableCell}>{header}</Text></View>
                        );
                    });

                    let valuesTemplate = "";

                    if (value !== '') {
                        valuesTemplate = value
                            .map((d, index) => {
                                return (
                                    <View style={styles.tableRow} key={field.id + '-valuesTemplate-' + index}>
                                        { optionsFormat.map((element, index) => {
                                            let someText = typeof d[element.id] === 'string' ? d[element.id].replace(/\r?\n?/g, '').trim() : d[element.id] ;
                                            if(someText == undefined ){
                                                someText = "";
                                            }

                                            return <View key={index} style={[styles.tableCol]}><Text style={styles.tableCellBlue}>{someText}</Text></View>
                                        })}
                                    </View>
                                )

                                // return (
                                //     <View style={styles.tableRow} key={field.id + '-valuesTemplate-' + index}>
                                //         {
                                //             Object.values(element).map((elm, elmIndex) =><View key={elmIndex} style={[styles.tableCol]}><Text style={styles.tableCellBlue}>{elm}</Text></View>)
                                //         }
                                //     </View>
                                // )
                            });
                    }
                    return (
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                {
                                    headersTemplate
                                }
                            </View>

                            {
                                valuesTemplate
                            }
                        </View>
                    );
                }
                else return null;
            }
            catch (e) {
                return null;
            }
        case 'boolean':
            return(
                <Text  style={styles.textThin}>{value === "1" ? 'oui/yes' : 'non/no'}</Text>
            )
        case 'single-table':
        case 'free-single-table':
            let concatValueSingleTable = value.map((item, index) => {
                if(typeof item.text !== "undefined"){
                    if(index < (value.length - 1 )){
                        return item.text + ' ~ ' + item.value + ' , '
                    }
                    else{
                        return item.text + ' ~ ' + item.value
                    }
                }
            })
            return(
                <Text  style={styles.textThin}>{concatValueSingleTable}</Text>
            )
        case 'multi-table':
        case 'free-multi-table':
            let concatValueMultipleTable = value.map((item,index) => {
                if(typeof item.text !== "undefined"){
                    if(index < (value.length - 1 )){
                        return item.text + ' ~ ' + item.value + ' , '
                    }
                    else{
                        return item.text + ' ~ ' + item.value
                    }
                }
            })
            return(
                <Text  style={styles.textThin}>{concatValueMultipleTable}</Text>
            )
        case 'pluglink':
            if(typeof value.code !== 'undefined' &&  value.code !== '' && value.userindex !== 'undefined' &&  value.userindex !== ''){

                if(typeof ownerhash !== "undefined" && !isNull(ownerhash)){
                    return(
                        <Link src={ApplicationConfig.plugcodesUrl + value.code.toString() + '/' + value.clef.toString() +'/' + value.userindex.toString() + '/' + ownerhash.toString()} target="_blank"><Text style={styles.textThin}>{value.code.toString() + '-' + value.clef.toString() +'-' + value.userindex.toString()}</Text></Link>
                    )
                }else{
                    return(
                        <Link src={ApplicationConfig.plugcodesUrl + value.code.toString() + '/' + value.clef.toString() +'/' + value.userindex.toString()} target="_blank"><Text style={styles.textThin}>{value.code.toString() + '-' + value.clef.toString() +'-' + value.userindex.toString()}</Text></Link>
                    )
                }
            }
            else{
                return(
                    <Text style={styles.textThin}/>
                )
            }
        case 'plugform' :
            let plugformValue = typeof value.value !==  "undefined" ? value.value : "";
            return(
                <Text  style={styles.textThin}>{plugformValue}</Text>
            )
        case 'password':
            return(
                <Text  style={styles.textThin}>********</Text>
            )
        case 'url':
            return(
                <Text  style={styles.textThin}>{value}</Text>
            )
        case 'textarea':
            return(
                <View style={styles.divMemo}>
                    <View style={styles.textareaMemo}>
                        <Text  style={styles.textThin}>{value}</Text>
                    </View>
                </View>
            )
        default:
            return(
                <Text  style={styles.textThin}>{value}</Text>
            )
    }


}

const MyDoc = (props) => {
    let properties = props.props;

    console.log('properties',properties);

    let environnement = getCurrentEnvironnement();

    let currentPlugnote = properties !== null && properties.plugnote !== null ? properties.plugnote : undefined;
    let userIndex = currentPlugnote !== null &&  typeof currentPlugnote !== "undefined" ?  currentPlugnote.userindex : null;
    let envIndex = currentPlugnote !== null &&  typeof currentPlugnote !== "undefined" ? environnement?.envIndex : null;

    let model = properties.model;
    let modelName = model.label;
    let modelClef = model.clef;

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    //today = mm + '/' + dd + '/' + yyyy;

    return (
        <Document>
            <Page size="A4" style={styles.viewer} wrap>
                <View style={styles.sectionHeader}>
                    <Image style={styles.logo} src={Images.logo}/>
                </View>

                <View style={styles.section}>
                    <View  style={styles.paragraph}>
                        <View style={styles.viewFieldsCenter}>
                            <Text  style={styles.text}>{modelName} - {modelClef} - <Text style={styles.textBlue}> PN {envIndex} - {userIndex}</Text></Text>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    {
                        properties.fields.map((field, index) => {
                            let tmpIndex = (index);
                            if(!properties.fields[0].hasOwnProperty('hash')){
                                tmpIndex = (index+1);
                            }
                            switch (field.format) {
                                case 'qrcodeint':
                                    if(field.value !== ""){
                                        return(
                                            <View  key={index}>
                                                <View style={styles.viewFields}>
                                                    <Text  style={styles.textLabel}>{field.title} : </Text>
                                                </View>
                                                <View style={styles.viewFieldsSub}>
                                                    {
                                                        renderFieldPreview(field.value, field.format, model['field'+tmpIndex+'formatoptions'], field, null)
                                                    }
                                                </View>
                                            </View>
                                        )
                                    } return null;
                                case 'sign':
                                    if (typeof properties.signature !== "undefined" && typeof properties.index !== "undefined" && properties.index === field.id) {
                                        return (
                                            <View>
                                                <View  style={styles.paragraphText} key={index}>
                                                    <View style={styles.viewFieldsLeftNoFlex}>
                                                        <Text  style={styles.textLabel}>{field.title} : </Text>
                                                    </View>
                                                    {
                                                        renderFieldPreview(field.value, field.format, model['field'+(index+1)+'formatoptions'], field,null)
                                                    }
                                                </View>
                                                <View style={styles.signature}>
                                                    <View>
                                                        <Image src={properties.signature} style={styles.iconImg} />
                                                    </View>
                                                </View>
                                            </View>
                                        )
                                    }else{
                                        return(
                                            <View  style={styles.paragraphText} key={index}>
                                                <View style={styles.viewFieldsLeftNoFlex}>
                                                    <Text  style={styles.textLabel}>{field.title} : </Text>
                                                </View>
                                                {
                                                    renderFieldPreview(field.value, field.format, model['field'+tmpIndex+'formatoptions'], field, null)
                                                }
                                            </View>

                                        )
                                    }
                                case 'textarea':
                                    if(field.value !== ""){
                                        return(
                                            <View  key={index}>
                                                <View style={styles.viewFields}>
                                                    <Text  style={styles.textLabel}>{field.title} : </Text>
                                                </View>
                                                <View style={styles.viewFieldsSub}>
                                                    {
                                                        renderFieldPreview(field.value, field.format, model['field'+tmpIndex+'formatoptions'], field, null)
                                                    }
                                                </View>
                                            </View>
                                        )
                                    } return null;
                                case 'tablemultin':
                                case 'smailvalidmulti':
                                    let valueTableMumtiN = typeof field.value !== "undefined" ? field.value : "";

                                    return(
                                        <View  key={index}>
                                            <View style={styles.viewFields}>
                                                <Text  style={styles.textLabel}>{field.title} : <Text style={styles.textThinBlue}>{valueTableMumtiN?.value || ""}</Text> </Text>
                                            </View>
                                            <View style={styles.viewFieldsSub}>
                                                {
                                                    renderFieldPreview(valueTableMumtiN, field.format, model['field'+tmpIndex+'formatoptions'], field, null)
                                                }
                                            </View>
                                        </View>
                                    )
                                case 'tablemulti':
                                    let value = typeof field.value !== "undefined" ? field.value : "";

                                    return(
                                        <View  key={index}>
                                            <View style={styles.viewFields}>
                                                <Text  style={styles.textLabel}>{field.title} : </Text>
                                            </View>
                                            <View style={styles.viewFieldsSub}>
                                                {
                                                    renderFieldPreview(value, field.format, model['field'+tmpIndex+'formatoptions'], field, null)
                                                }
                                            </View>
                                        </View>
                                    )
                                default:
                                    return(
                                        <View  style={styles.paragraphText} key={index}>
                                            <View style={styles.viewFieldsLeftNoFlex}>
                                                <Text  style={styles.textLabel}>{field.title} : </Text>
                                            </View>
                                            {
                                                renderFieldPreview(field.value, field.format, model['field'+tmpIndex+'formatoptions'], field,properties?.ownerHash || null)
                                            }
                                        </View>

                                    )
                            }
                        })
                    }
                </View>
            </Page>
        </Document>
    )
}

export function Data9002PrintableGetURL(props) {
    const blob = pdf(<MyDoc props={props}/>).toBlob();
    return blob;
}

export function Data9002PrintableGetURLCustom(props) {
    return pdf(<MyDoc props={props}/>).toBlob();
}

function Data9002Printable(props) {
    const { t, i18n } = useTranslation(['common', 'models']);
    const classes = useStyles();
    if(props.signature !== undefined){
        return (
            <BlobProvider document={<MyDoc props={props}/>}>
                {({blob, url, loading, error}) => {
                    if(blob !== null){
                        props.close();

                        let reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = function() {

                            var base64data = reader.result;
                            let file = new File([blob], props.filename+'.pdf');
                            file.base64data = reader.result;
                            props.onChangeHandlerDrop([file]);
                        }
                        // Do whatever you need with blob here
                    }
                    return '';
                }}
            </BlobProvider>
        )
    }

    return (
        <PDFDownloadLink document={<MyDoc props={props}/>} className={classes.pointer} fileName={props.filename+'.pdf'} style={{textDecoration : 'none', color : 'black'}}>
            {({ blob, url, loading, error }) =>
                loading ? <div style={{display : 'flex'}}> <span className="material-icons md-25 "> sync_alt </span><div className={classes.pointer}>{t('common:global.print-sync') }</div></div> :  <div style={{display : 'flex'}}> <span className="material-icons md-25 "> picture_as_pdf </span><div className={classes.pointer}>{t('common:global.print') }</div></div>
            }
        </PDFDownloadLink>
    );
}

export default Data9002Printable;
