import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/extdata';
import * as api from '../api/extdataApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


export function * postGetExtData (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postExtData, action.payload);
        // success?
        if (response.statusText == "OK" && response.data.result === true) {
            yield put(actions.postExtDataSuccess(response.data));
        } else {
            yield put(actions.postExtDataError())
        }
    }catch(e) {
        yield put(actions.postExtDataFailure());
    }
}

export function * postExtDataGenerateTable (action) {
    try {
        const response = yield call(myGenericTrackedWrapper,api.postExtDataGenerateTable, action.payload);
        // success?
        if (response.statusText == "OK" && response.data.result === true) {
            yield put(actions.postExtDataGenerateTableSuccess());
        } else {
            yield put(actions.postExtDataGenerateTableError())
        }
    }catch(e) {
        yield put(actions.postExtDataGenerateTableFailure());
    }
}


function* watchFetchExtSagas() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.POST_EXT_DATA_REQUEST, postGetExtData);
    yield takeEvery(actions.Types.POST_EXT_DATA_GENERATE_TABLE_REQUEST, postExtDataGenerateTable);
}

const ExtDataSagas = [
    fork(watchFetchExtSagas)
];

export default ExtDataSagas;
