import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import colors from "../../../assets/themes/Colors";
import { isDefined } from "../../../utils/utils";
import { isPrintable } from "../../../utils/Fields";

const styles = StyleSheet.create({
    row: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: "row",
        alignItems: "stretch",
        width: '100%',
        margin: 'auto',
        justifyContent: 'center',
        backgroundColor: colors.palePlugGreen,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderTopWidth: 1,
        borderBottomWidth: 1,
    },
    cell: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        alignContent: 'center',
        justifyContent: 'center',
        borderLeftWidth: 1,
        borderColor : "#e0e0e0",
        height: '100%',
        textAlign: 'center',
    },
    flexCell: {
        flex: 1,
        flexBasis: 'auto'
    },
    headerNb: {
        fontFamily: 'Poppins',
        //fontSize: 9,
        fontWeight: 'light',
    },
    total: {
        color: colors.bloodOrange,
        fontSize: 10,
        fontFamily: 'Poppins',
    },
    value: {
        fontSize: 10,
        fontFamily: 'Poppins',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
    },
    ellipsisCell: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
    }
});

// Handles special chars in column label
const getColumnLabel = (col) => {
    // const textStyle = { }  // pas de style particulier appliqué au header
    if (col.value.includes('~')) {
        const parts = col.value.split("~");
        return parts.map((part, i) => {
            let section;
            if (part.includes('#')) {
                section = part.split('#')[0] + '...';
            } else section = part;
            if (i !== parts.length - 1) {
                return (<><Text style={{ ...styles.value }}>{section}</Text><br /></>);
            }
            return (
                <Text
                    key={section}
                    style={{ ...styles.value }}>{section}</Text>
            )
        });
    } else if (col.value.includes('#')) {
        const index = col.value.indexOf("#");
        return (<Text style={{ ...styles.value }}>{col.value.slice(0, index) + '...'}</Text>);
    } else {
        return (<Text style={{ ...styles.value }}>{col.value}</Text>);
    }
}

const PDFHeaderRow = (props) => {
    const borderColor = props.borderColor !== undefined? props.borderColor : "#c5c5c5"
    const mode = props.mode
    return (
        <View style={[styles.row, { borderColor: borderColor, borderTopLeftRadius: 4, minWidth: 120 }]} fixed wrap={false}>
            <View style={[styles.cell, { fontSize: 10, borderColor: borderColor, borderTopLeftRadius: 4, width: 20 }]} key={"header-nb-cell"}>
                <Text style={styles.headerNb} key="header-number">N°</Text>
            </View>
            {
                props.items.map((item, id) => {
                    // Test if the Label and row should be shown
                    let printable = isPrintable(item, mode)
                    if (printable) {
                        let widthStyle = isDefined(item.colWidth) ? { width: item.colWidth * 9 + 4 } : { width: 'auto' };
                        let colHeader = getColumnLabel(item);
                        return (
                            <View style={[styles.cell, widthStyle, { borderColor: borderColor }, styles.flexCell]} key={'table-header' + id}>
                                <View style={{display: 'flex', alignSelf: 'center', justifyContent: 'center',}}>
                                    {colHeader}
                                </View>
                                <View>
                                    {
                                        typeof item.total !== "undefined" &&
                                        <Text style={styles.total}>{item.total}</Text>
                                    }
                                </View>
                            </View>
                        );
                    }
                    else { return null; }
                })}
        </View>
    )
};

export default PDFHeaderRow;