// create initial state for reducers
import {Types} from "../actions/utils";

const INIT_STATE = {
};


// reducer function to transform state
export default function utils(state = INIT_STATE, action) {


    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;

        default: return state;
    }

}