import { fieldsWithColumns, isEditableColumnMode, isEditableField } from "./Fields"
import { isDefined } from "./utils"

export const hasMissingMandatoryValue = (fields, mode) => {
  // devLog('checking missing mandatory values', fields, mode)
  let missing = []
  fields.forEach((field) => {
    if (isDefined(field.format) && isEditableField(field, mode)) {
      if (!!field.mandatory && field.mandatory === 1) {
        if (fieldsWithColumns.includes(field.format)) {
          if (isDefined(field?.value?.list)) {
            if (field.value.list?.length === 0 || field.value?.length === 0) {
              missing.push({ field: field })
            }}
        } else if (field.format.includes('select')) {
            if (field.value.length === 0) {
              missing.push({ field: field })
          }} else if (field.format === 'list') {
            if ((!field.value || field.value === '[]' || field.value.length === 0) && field.format !== "indice") {
            missing.push({ field: field })
          }} else if (field.format === 'boolean') {
            if (field.value === "") {
            missing.push({ field: field })
          }} else {
          if (!field.value && field.format !== "indice") {
            missing.push({ field: field })
          }
        }
      } else if (fieldsWithColumns.includes(field.format)) {
        // Looking for mandatory column
        let columns = isDefined(field?.options.lines) ? field.options.lines : field.options
        columns = columns.filter(entry => entry?.mandatory === true && isEditableColumnMode(field, entry, mode))
        let rows = isDefined(field?.value?.list) ? field.value.list : field.value
        if (rows.length > 0) {
          rows.forEach((row, rindex) => {
            if (row?.recap !== true) {
              columns.forEach(col => { if (row[col.id] === "" || !isDefined(row[col.id])) {
                missing.push({ field: field, rindex: rindex, column: col })
              } }) 
            }
          })
        }
      }
    }
  })
  // devLog('missing values', missing)
  return missing.length === 0 ? false : missing
}