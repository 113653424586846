import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/utils';
import * as api from '../api/utilsApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


export function * postSimpleSendMail (action) {

    try {
        action.payload.userhash = action.userhash;
        const response = yield call(myGenericTrackedWrapper,api.postSimpleSendMail, action.payload);
        // success?
        if (response.statusText == "OK" && response.data.result === true) {
            yield put(actions.postSendMailSuccess());
        } else {
            yield put(actions.postSendMailFailure())
        }
    }catch(e) {
        yield put(actions.postSendMailError());
    }
}

export function * postSimpleSendMailV3 (action) {

    try {
        action.payload.userhash = action.userhash;
        const response = yield call(myGenericTrackedWrapper,api.postSimpleSendMailV3, action.payload);
        // success?
        if (response.statusText == "OK" && response.data.result === true) {
            yield put(actions.postSendMailV3Success());
        } else {
            yield put(actions.postSendMailV3Failure())
        }
    }catch(e) {
        yield put(actions.postSendMailV3Error());
    }
}


function* watchFetchUtils() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.POST_SEND_MAIL_REQUEST, postSimpleSendMail);
    yield takeEvery(actions.Types.POST_SEND_MAIL_V3_REQUEST, postSimpleSendMailV3);
}

const UtilsSagas = [
    fork(watchFetchUtils)
];

export default UtilsSagas;
