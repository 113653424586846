import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {persistor, store} from "./store";
import {PersistGate} from 'redux-persist/integration/react';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import {usePromiseTracker} from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import './translations/i18n';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress &&
        <div
            style={{
                position : "fixed",
                backgroundColor : "rgba(192,192,192,0.2)",
                top : 0,
                bottom : 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex : '9999'
            }}>
            <div
                style={{
                    width: "100%",
                    height: "100",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Loader type="Bars" color="#58C3C1" height="150" width="150" />
            </div>
        </div>
    );
};


ReactDOM.render(
    <div>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Suspense fallback={ <div>Loading...</div> }>
                        <ToastContainer autoClose={2000} />
                        <App />
                      </Suspense>
                  </MuiPickersUtilsProvider>
          </PersistGate>
      </Provider>
      <LoadingIndicator/>
    </div>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
