// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {

    RESET_ALL_DATA: 'reset_all_data',

    POST_SEND_MAIL_REQUEST: 'post_send_mail_request',
    POST_SEND_MAIL_SUCCESS: 'post_send_mail_success',
    POST_SEND_MAIL_FAILURE: 'post_send_mail_failure',
    POST_SEND_MAIL_ERROR: 'post_send_mail_error',


    POST_SEND_MAIL_V3_REQUEST: 'post_send_mail_v3_request',
    POST_SEND_MAIL_V3_SUCCESS: 'post_send_mail_v3_success',
    POST_SEND_MAIL_V3_FAILURE: 'post_send_mail_v3_failure',
    POST_SEND_MAIL_V3_ERROR: 'post_send_mail_v3_error',

};

/*
Token
 */
// function that returns an object literal
export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});


/*
Token
 */
// function that returns an object literal
export const postSendMailRequest = (payload,userhash) => ({
    type: Types.POST_SEND_MAIL_REQUEST,
    userhash : userhash,
    payload : payload
});

// function that returns an object literal
export const postSendMailSuccess = () => ({
    type: Types.POST_SEND_MAIL_SUCCESS,
});

// function that returns an object literal
export const postSendMailFailure = () => ({
    type: Types.POST_SEND_MAIL_FAILURE,
});

// function that returns an object literal
export const postSendMailError = () => ({
    type: Types.POST_SEND_MAIL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postSendMailV3Request = (payload,userhash) => ({
    type: Types.POST_SEND_MAIL_V3_REQUEST,
    userhash : userhash,
    payload : payload
});

// function that returns an object literal
export const postSendMailV3Success = () => ({
    type: Types.POST_SEND_MAIL_V3_SUCCESS,
});

// function that returns an object literal
export const postSendMailV3Failure = () => ({
    type: Types.POST_SEND_MAIL_V3_FAILURE,
});

// function that returns an object literal
export const postSendMailV3Error = () => ({
    type: Types.POST_SEND_MAIL_V3_ERROR,
});

