import {Types} from '../actions/field';

// create initial state for reducers
const INIT_STATE = {
    list: [],
    fetching: false,
    error: null
};

// reducer function to transform state
export default function field(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.GET_FIELDS_REQUEST: {
            return {
                list : state.list,
                fetching: true,
            }
        }
        case Types.GET_FIELDS_SUCCESS: {
            return {
                list: action.payload.data.data,
                fetching : false
            }
        }
        default: return state;
    }
}