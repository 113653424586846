import React, { useEffect, useState } from "react";
import { Input, InputAdornment, makeStyles, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import colors from "../../assets/themes/Colors";
import { devLog } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    input_add_item: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        marginLeft: 5
    },
    margin_left_5: {
        marginLeft: '5px',
    },
    renderSingleSelMainContainer: {
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    renderSingleSelContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
    },
    renderSingleSelContainerColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        // "&: hover": {
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        // },
        marginBottom: 3,
        flexGrow: 1
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
        background: colors.greenLightest,
        borderRadius: 4,
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedItem,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedItem,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
}))

export const RenderItemSingleSelectComponent = (props) => {
    // devLog('single select props ', props)

    const classes = useStyles();
    const { t } = useTranslation(['common']);
    const [selected, setSelected] = useState(typeof props.value !== "undefined" ? props.value : '');
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false : props.disabled);
    const [open, setOpen] = useState(props.edit === "cell" ? !disabled : false);
    // const [value, ] = useState(''); use in filtering
    const [listMode, setListMode] = useState(false)

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false : props.disabled);
    }, [props.disabled]);

    const handleAddValueInput = (value) => {
        if (value.trim() !== "") {
            if (!props.presel.includes(value)) {
                props.presel.push(value);
            }
            setSelected(value);
            setOpen(false)
        }
        setNewValue('');
    }

    const handleAddValue = (value) => {
        if (value.trim() !== "") {
            if (selected !== value) {
                setSelected(value);
                setOpen(false)
            }
            else {
                setSelected("");
            }
        }
        setNewValue('');
    }

    const handleToggleOpen = () => {
        if (!props.disabled) { setOpen(previous => !previous) }
    }

    useEffect(() => {
        props.handleChangeChildrenValue(selected, props.col, props.row, props.rindex);
    }, [selected])

    let valuesOrdered = props.presel
        .filter((value) => { return value !== undefined })
        .sort((a, b) => { return a.toLowerCase().localeCompare(b.toLowerCase()) })

    if (open) {
        return (
            <div className={classes.renderSingleSelMainContainer}>
                <div className={classes.btnContainer}>
                    <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={(event) => setOpen(false)}>
                        arrow_drop_up
                    </span>
                    <Tooltip title={t('common:global.view-change')} placement="top">
                        <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setListMode(!listMode) }}>
                            {!listMode ? 'dashboard' : 'format_list_bulleted'}
                        </span>
                    </Tooltip>
                </div>
                <div className={classes.selectItemContainer} onClick={handleToggleOpen}>
                    <div className={classes.renderSingleSelContainerColumn}>
                        {props.editable && !disabled &&
                            <div className={classes.input_add_item} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                <Input
                                    disabled={disabled}
                                    autoComplete='off'
                                    id="standard-full-width"
                                    label="Label"
                                    style={{ margin: 0, fontSize: 12 }}
                                    placeholder={t('common:multiple.add-item')}
                                    helpertext="Full width!"
                                    fullWidth={true}
                                    margin="none"
                                    disableUnderline={true}
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    endAdornment={!disabled &&
                                        <InputAdornment position="end">
                                            <div onClick={() => handleAddValueInput(newValue, props.field, props.index)}>
                                                <span className="material-icons" style={{ fontSize: 20 }}>
                                                    add
                                                </span>
                                            </div>
                                        </InputAdornment>}
                                    value={newValue}
                                    onKeyPress={(e) => { if (e.key === 'Enter') { handleAddValueInput(newValue, props.field, props.index) } }}
                                    onChange={(event) => setNewValue(event.target.value)}
                                />
                            </div>}

                        {listMode ?
                            <div >
                                {valuesOrdered
                                    // .filter(item => {
                                    //     if (!value) return true
                                    //     if (item.toLowerCase().includes(value.toLowerCase())) {
                                    //         return true
                                    //     }
                                    // })
                                    .map((value, indexation) => {
                                        if (value !== "") {
                                            return (
                                                <div key={indexation} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        disabled={disabled}
                                                        type="checkbox"
                                                        id={value + '-' + indexation}
                                                        checked={selected === value}
                                                        onChange={(event) => { if (!disabled) handleAddValue(value, props.field, props.index) }}
                                                    /><label className={classes.margin_left_5} htmlFor={value + '-' + indexation} style={{ fontSize: 12 }}>{value}</label>
                                                </div>
                                            )
                                        } return null;
                                    })}
                            </div>
                            :
                            <div className={classes.selectItemContainer}>
                                {valuesOrdered
                                    // .filter(item => {
                                    //     if (!value) return true
                                    //     if (item.toLowerCase().includes(value.toLowerCase())) {
                                    //         return true
                                    //     }
                                    // })
                                    .map((value, indexation) => {
                                        if (value !== "") {
                                            return (
                                                <span key={indexation}
                                                    id={value + '-' + indexation}
                                                    className={selected === value ? classes.selectedItem : classes.unselectedItem}
                                                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); if (!disabled) handleAddValue(value, props.field, props.index) }}>
                                                    {value}
                                                </span>
                                            )
                                        }
                                        return null;
                                    })}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={classes.renderSingleSelMainContainer}>
                <span id="icon-delete-playlist" className={`${props.disabled ? classes.hiddenIcon : classes.renderSingleSelIcon} material-icons`} onClick={handleToggleOpen}>
                    arrow_drop_down
                </span>
                <div className={classes.renderSingleSelContainer} style={{ padding: '0 1px' }} onClick={handleToggleOpen}>
                    {typeof props.presel !== 'undefined' && selected !== '' ?
                        <span className={!listMode ? classes.selectedItem : classes.selectedItem}>{selected}</span>
                        :
                        <span className={classes.noSelectedItem}>
                            {/* {t('common:global.no-select')} */}

                        </span>
                    }
                </div>
            </div>
        )
    }
}

export const RenderItemMultipleSelectComponent = (props) => {
    const classes = useStyles();
    const { t, } = useTranslation(['common']);
    let tmpselected = [];

    if (props.value !== "") {
        if (Array.isArray(props.value)) {
            tmpselected = props.value;
        } else {
            tmpselected = [props.value];
        }
    }

    const [selected, setSelected] = useState(tmpselected);
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false : props.disabled);
    const [open, setOpen] = useState(props.edit === "cell" ? true : false);
    const [listMode, setListMode] = useState(false)

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false : props.disabled);
    }, [props.disabled]);

    useEffect(() => {
        props.handleChangeChildrenValue(selected, props.col, props.row, props.rindex);
    }, [selected]);

    const handleAddValueInput = (value) => {
        if (value.trim() !== "") {
            if (!selected.includes(value)) {
                setSelected([...selected, value]);
            }
            if (!props.presel.includes(value)) props.presel.push(value);
        }
        setNewValue('');
    }

    const handleAddValue = (value) => {
        if (value !== "") {
            if (!selected.includes(value)) {
                setSelected([...selected, value]);
            }
            else {
                let tmp = selected.filter(item => item !== value);
                setSelected(tmp);
            }
        }
    }

    const handleToggleOpen = () => {
        if (!props.disabled) { setOpen(previous => !previous) }
    }

    let valuesOrdered = props.presel
        .filter((value) => { if (value !== undefined) return true; else return false })
        .sort((a, b) => { return a.toLowerCase().localeCompare(b.toLowerCase()); });

    return (
        <div >
            {open &&
                <div className={classes.renderSingleSelMainContainer}>
                    <div className={classes.btnContainer}>
                        <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={(event) => setOpen(false)}>
                            arrow_drop_up
                        </span>
                        <Tooltip title={t('common:global.view-change')} placement="top">
                            <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setListMode(!listMode) }}>
                                {!listMode ? 'dashboard' : 'format_list_bulleted'}
                            </span>
                        </Tooltip>
                    </div>
                    <div className={classes.selectItemContainer} onClick={handleToggleOpen}>
                        <div className={classes.renderSingleSelContainerColumn} onClick={() => setOpen(!open)}>
                            {props.editable && !disabled &&
                                <div className={classes.input_add_item} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                    <Input
                                        disabled={disabled}
                                        autoComplete='off'
                                        id="standard-full-width"
                                        style={{ margin: 0, fontSize: 12, padding: '0 5px' }}
                                        placeholder={t('common:multiple.add-item')}
                                        fullWidth={true}
                                        margin="none"
                                        inputlabelprops={{
                                            shrink: true,
                                        }}
                                        disableUnderline={true}
                                        endAdornment={!disabled &&
                                            <InputAdornment position="end">
                                                <div onClick={handleAddValueInput.bind(this, newValue, props.field, props.index)}>
                                                    <span className="material-icons" style={{ fontSize: 20 }}>
                                                        add
                                                    </span>
                                                </div>
                                            </InputAdornment>}
                                        className={classes.fieldRight}
                                        value={newValue}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { handleAddValueInput(newValue, props.field, props.index) } }}
                                        onChange={(event) => setNewValue(event.target.value)}
                                    />
                                </div>
                            }
                            {listMode ?
                                valuesOrdered
                                    // .filter(item => {
                                    // if (!value) return true
                                    // if (item.toLowerCase().includes(value.toLowerCase())) {
                                    //     return true
                                    // }})
                                    .map((value, indexation) => {
                                        if (value !== "") {
                                            return (
                                                <div key={indexation} className={classes.selectItem}>
                                                    <input
                                                        disabled={disabled}
                                                        type="checkbox"
                                                        id={value + '-' + indexation}
                                                        checked={selected.includes(value)}
                                                        onChange={(event) => {
                                                            if (!props.disabled) handleAddValue(value, props.field, props.index);
                                                        }}
                                                    />
                                                    <label className={classes.margin_left_5} htmlFor={value + '-' + indexation}
                                                        style={{ fontSize: 12 }}
                                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); if (!props.disabled) handleAddValue(value, props.field, props.index) }}>
                                                        {value}
                                                    </label>
                                                </div>
                                            )
                                        }
                                        else return null;
                                    })
                                :
                                <div className={classes.selectItemContainer}>
                                    {valuesOrdered
                                        // .filter(item => {
                                        // if (!value) return true
                                        // if (item.toLowerCase().includes(value.toLowerCase())) {
                                        //     return true
                                        // }})
                                        .map((value, indexation) => {
                                            if (value !== "") {
                                                return (
                                                    <span key={indexation}
                                                        id={value + '-' + indexation}
                                                        className={selected.includes(value) ? classes.selectedItem : classes.unselectedItem}
                                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); if (!props.disabled) handleAddValue(value, props.field, props.index) }}>
                                                        {value}
                                                    </span>
                                                )
                                            }
                                            else return null;
                                        })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                !open &&
                <div className={classes.renderSingleSelMainContainer}>
                    <span id="icon-delete-playlist" className={`${props.disabled ? classes.hiddenIcon : classes.renderSingleSelIcon} material-icons`} onClick={(event) => setOpen(true)}>
                        arrow_drop_down
                    </span>
                    <div className={classes.renderSingleSelContainer} onClick={handleToggleOpen}>
                        {typeof props.presel !== 'undefined' && selected.length > 0 ?
                            selected
                                .map((item, index) => {
                                    return (
                                        <span className={!listMode ? classes.selectedItem : classes.selectedItem} key={item + '-' + index}>{item}</span>
                                    )
                                })
                            :
                            <span className={classes.noSelectedItem}>
                            </span>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export const restylizeNumber = (number) => {
    if ((typeof number === "number" || typeof number === 'string') && number !== '') {
        let nombre = 0
        let coma = false
        if (typeof number === "number") nombre = number;
        else if (number.charAt(number.length - 1) === ',') {
            nombre = parseFloat(number.slice(0, -1))
            coma = true
        }
        else nombre = parseFloat(number.replace(',', '.'))
        // console.log('nombre: ', number, nombre);
        let restyled = ''
        let ent = 0
        ent = Math.floor(nombre);
        // console.log('ent', ent);
        let stInt = ''
        let decim = number.toString().split('.')[1]
        if (!number.toString().includes('.')) decim = number.toString().split(',')[1]
        // console.log('decim', decim);
        if (nombre > 999) {
            ent += ''
            let rank = 1
            for (let index = ent.length - 1; index >= 0; index--) {
                const nb = ent.charAt(index);
                if (rank % 3 === 0 && index >= 1 && rank !== 0) stInt = '.' + nb + stInt;
                else stInt = nb + stInt.toString()
                rank++;
                // console.log(rank, '(',index, ') ',nombre,'- current char', nb, 'result =', stInt);
            }
        }
        else stInt = ent.toString()
        if (decim !== undefined) restyled = stInt + ',' + decim;
        else restyled = stInt
        // console.log('restyled', restyled);
        return restyled.concat(coma ? ',' : '')
    }
    return ''
}
