import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {KeyboardDatePicker} from "@material-ui/pickers";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
}));


const FormatBirthdayComponent = (props) => {
    const classes = useStyles();

    let currentValue = null;
    if(props.field.value !== null && props.field.value.length > 0){
        let currentTime = new Date();
        let split = props.field.value.split('-');
        currentValue = new Date(currentTime.getFullYear(), split[1] - 1, split[0], null, null);
    }

    const [selectedDate, handleDateChange] = useState(currentValue);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    return (
        <KeyboardDatePicker
            InputAdornmentProps={{ position: 'start' }}
            placeholder="DD-MM"
            disabled={disabled}
            className={`${classes.datePickerIcon}` + ` ` + classes.fullWidth}
            variant="inline"
            format="dd-MM"
            fullWidth={true}
            id={'date-picker-inline-'+props.field.id}
            //label={props.field.title}
            //value={selectedDate}
            //onChange={handleDateChange}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            value={selectedDate}
            onChange={(date, value) => {
                handleDateChange(date);
                props.handleEditDateTime(date, value, props.field, props.index);
            }}
            InputProps={{ disableUnderline: true }}
        />
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatBirthdayComponent);





