import moment from "moment";
import { useState, useEffect } from 'react';
import ApplicationConfig from "../config/ApplicationConfig";
import { isMobile } from "react-device-detect";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
    return moment(epoch).format("DD-MM-YYYY HH:mm");
};

export function  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isJsonStringObject(str) {
    try {
        let o = JSON.parse(str);
        return !!(o && typeof o === "object");
    } catch (e) {
        return false;
    }
}

export function isJsonStringArray(str) {
    try {
        return typeof str === "object";
    } catch (e) {
        return false;
    }
}

export function isJsonStringObjectAndCount(str) {
    try {
        let o = JSON.parse(str);
        if (o && typeof o === "object") {
            return o.length;
        } else return 0;
    } catch (e) {
        return 0;
    }
}


export function isEquivalent(a, b) {
    // Create arrays of property names
    let aProps = Object.getOwnPropertyNames(a);
    let bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export function sortValue(options){
    options.sort((str1, str2) => {
        let num1 = parseInt(str1);
        let num2 = parseInt(str2);

        if (isNaN(num1) && isNaN(num2)) {
            if (str1 > str2)
                return 1;
            else if (str1 < str2)
                return -1;
            else
                return 0;
        }

        else if (isNaN(num1))
            return 1;
        else if (isNaN(num2))
            return -1;
        else if (num1 > num2)
            return 1;
        else if (num1 < num2)
            return -1;
        else
            return 0;
    });

    return options;
}

export function isDefined(e) {
    return typeof e !== 'undefined' && e !== null;
}

/**
 * Extract file extension from URL.
 * @param {String} url
 * @returns {String} File extension or empty string if no extension is present.
 */
export function getFileExtension(url) {
    if (url === null) {
        return "";
    }
    let index = url.lastIndexOf("/");
    if (index !== -1) {
        url = url.substring(index + 1); // Keep path without its segments
    }
    index = url.indexOf("?");
    if (index !== -1) {
        url = url.substring(0, index); // Remove query
    }
    index = url.indexOf("#");
    if (index !== -1) {
        url = url.substring(0, index); // Remove fragment
    }
    index = url.lastIndexOf(".");
    return index !== -1
        ? url.substring(index + 1) // Only keep file extension
        : ""; // No extension found
}


export function urlify(text) {
    let urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

export const validateUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        console.error(e);
        return false;
    }
    return true;
};

export const  validateEmail  = (email)  => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const  validateText  = (text)  => {
    return text.length <= 40;
}

export const  validateTextarea  = (textarea)  => {
    return textarea.length <= 512;
}

export const  validateNumber = (number)  => {
    return !isNaN(number);
}


/**
 * If the element/node ('child') has the class 'className' => return true
 * Else: call the function again with parent until parent with class is found or no parent is left
 */

export const hasParentClass = (child, classname) => {
    if(child.className.split(' ').indexOf(classname) >= 0) return true;
    try{
        //Throws TypeError if child doesn't have parent any more
        return child.parentNode && hasParentClass(child.parentNode, classname);
    }catch(TypeError){
        return false;
    }
}

export const dateFormat = (date, fstr, utc) =>  {
    utc = utc ? 'getUTC' : 'get';
    return fstr.replace (/%[YmdHMS]/g, function (m) {
        switch (m) {
            case '%Y': return date[utc + 'FullYear'] (); // no leading zeros required
            case '%m': m = 1 + date[utc + 'Month'] (); break;
            case '%d': m = date[utc + 'Date'] (); break;
            case '%H': m = date[utc + 'Hours'] (); break;
            case '%M': m = date[utc + 'Minutes'] (); break;
            case '%S': m = date[utc + 'Seconds'] (); break;
            default: return m.slice (1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice (-2);
    });
}

export const currentDate = () => {
    let day = new Date().getDate()
    if (day <= 9) { day = "0".concat(day) }
    let month = new Date().getMonth() + 1
    if (month <= 9) { month = "0".concat(month) }
    let year = new Date().getFullYear()
    return day + '-' + month + '-' + year
}

export const getBool = (val) => {
    switch (val.toLowerCase ()) {
        case "true":
            return true;
        case "false":
            return false;
    }
}

export const isEven = (n) => !(n&1)

export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

export const blobToBase64Await = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export const  convertersAlphaToNumber = (val) => {
    let base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', i, j, result = 0;

    for (i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
        result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1);
    }

    return result;
};

export const  convertersNumberToAlpha = (val) => {
    let s = '', t;

    while (val > 0) {
        t = (val - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        val = (val - t)/26 | 0;
    }
    return s;
};

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function returnJSON (obj) {
    if (isJsonString(obj)) return JSON.parse(obj)
    else return obj
}

export function zeroPad (num, places) {
    return String(num).padStart(places, '0');
}

export function devLog (...text) {
    if (ApplicationConfig.devMode && ApplicationConfig.developer === 'tech2') {
        console.log(...text)
    }
}

export const windowHeight = window.innerHeight
export const windowWidth = window.innerWidth

export const isLandscape = isMobile && windowHeight < windowWidth

export function getElementCoords(elem) { // cross-browser version
    let box = elem.getBoundingClientRect()
    let body = document.body
    let docEl = document.documentElement
    let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    let clientTop = docEl.clientTop || body.clientTop || 0
    let clientLeft = docEl.clientLeft || body.clientLeft || 0
    
    let top  = box.top +  scrollTop - clientTop
    let left = box.left + scrollLeft - clientLeft

    return { top: Math.round(top), left: Math.round(left) }
}