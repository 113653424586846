import React, {useEffect, useRef, useState, Suspense} from 'react';
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Close, Search} from "@material-ui/icons";
import MenuComponent from "../components/menu/MenuComponent";
import qs from "querystringify";
import { useParams } from "react-router-dom";
import {Container, Content} from "rsuite";
import {connect} from "react-redux";
import {
    getPlugformViaCodeOfflineRequest, getPlugformViaCodeOfflineReset,
    getPlugformViaCodeRequest, getPlugformViaDoubleCodeOfflineRequest,
    getPlugnoteViaCodeOfflineRequest, getPlugnoteViaCodeOfflineReset, getPlugnoteViaDoubleCodeOfflineRequest
} from "../actions/plugcode";
import I18n from '../translations/i18n';

import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import {api} from "../api";
import ApplicationConfig from "../config/ApplicationConfig";
import {toast} from "react-toastify";
import {Translation, useTranslation} from "react-i18next";

import { Android, Apple } from '@material-ui/icons';

import { NavLink } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import colors from '../assets/themes/Colors';
import Images from "../assets/themes/Images";
import {getPlugformWithPFCodeHashLogoff, getPlugnoteWithPNCodeHashLogoff} from "../api/plugcodeApi";
import { TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import meta from "./../meta.json";
import {getBuildDate} from "../utils/utils";
import InputMask from 'react-input-mask';
import ReactInputMask from 'react-input-mask';
import ModalPlugnoteEditableComponent from "../components/modals/ModalPlugnoteEditableComponent";
import {confirmAlert} from "react-confirm-alert";
import i18n from "i18next";

const ModalPlugformComponent = React.lazy(() => import('../components/modals/ModalPlugformComponent'));
const ModalPlugnoteComponent = React.lazy(() => import('../components/modals/ModalPlugnoteComponent'));

const useStyles = makeStyles((theme) => ({
    homeContainer: {
        //border: '3px solid red',
        // height: 'calc(100vh - 50px)',
        // padding: '50px 10px',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    homeHeader: {
        //border: '1px solid blue',
        display: 'flex',
        gap: 15,
    },
    homeHeaderLink: {
        // fontSize: 20,
        color: colors.grey,
        marginBottom: 30,
    },
    homeVersionContainer: {
        fontSize: 10,
        color: colors.grey,
        // position: 'absolute',
        bottom: 5,
        textAlign: 'center',
        width: '96vw'
    },
    homeVersion: {
        fontSize: 10,
        color: colors.grey,
    },
    homeContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 20,
        height: 'max-content'
    },
    homeTitle: {
        //border: '3px solid pink',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        marginTop: 40,
        '@media (max-width: 640px)': {
            gap: 3,
            marginBottom: 5,
        }
    },
    homeTitlePlugcodes: {
        textTransform: 'uppercase',
        color: colors.mainMedium,
        fontWeight: 'bold',
        fontSize: 64,
        lineHeight: 1,

    },
    homeTitleBy: {
        textTransform: 'uppercase',
        color: colors.mainMedium,
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: 1,

    },
    homePlugcodeLogo: {
        width: 350,
        height: 'auto',
    },
    homeLogo: {
        width: 250,
        height: 'auto',
    },
    homeVideo: {
        width: 500,
        height: 'auto',
        borderRadius: 10,
        marginBottom: 15,
    },
    homeVideoVimeo: {
        borderRadius: 10,
        border : 0,
        width: 640,
        height: 360,
        '@media (max-width: 640px)': {
            // height: '53vh',
            width: '95vw',
            height: 180
        }
    },
    homeCodeContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        margin: '0 15px',
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
            padding: 0,
        },
    },
    homeButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        // width: '30vw',
    },
    homeCodeText: {
        color: colors.greyDark,
        fontWeight: 'bold',
        fontSize: 22,
        lineHeight: 1,
        textAlign: '-webkit-center'
    },
    homeCodeInput: {
        // width: '30%',
        fontSize: 25,
        lineHeight: '2.5em',
        padding: '0 6px',
        height: 70,
        boxSizing: 'border-box',
        textAlign: 'center',
        color: 'rgb(56, 76, 90)',
        border: 'solid 2px ',
        borderColor: colors.plugnotesLight,
        borderRadius: 10,
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center'
    },
    homeInput: {
        // width: '30%',
        fontSize: 25,
        lineHeight: '2.5em',
        padding: '0 6px',
        height: 65,
        boxSizing: 'border-box',
        textAlign: 'center',
        color: 'rgb(56, 76, 90)',
        border: 'none',
        borderRadius: 10,
        textTransform: 'uppercase',
        '&::placeholder': {
            color: colors.main,
            textTransform: 'none',
            fontSize: 22,
        },
    },
    homeCodeButton: {
        backgroundColor: colors.plugnotesLight,
        border: 'none',
        padding: '5px 0',
        minWidth: '350px',
        maxWidth: '90vw',
        color:'#fff',
        height: 70,
        borderRadius: 10,
        fontSize: '22px',
    },
    homeCodeButtonDisabled: {
        backgroundColor: colors.greyDark,
        border: 'none',
        lineHeight: 1,
        minHeight: '60px',
        width: '40%',
        color:'#fff',
        height: 50,
        borderRadius: 10,
    },
    homeGetMobileContainer: {
        display: 'flex',
        gap: 15,
    },
    homeGetMobileImgLink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 15,
        width: 200,
    },
    homeGetMobileLink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 15,
        color: colors.main,
        fontSize: 30,
        border: 'solid 2px #51c3c1',
        borderRadius: 10,
        padding: 10,
        width: 250,
    },
    homeGetMobileIcon: {
        height: 40,
        width: 'auto',
    },
    homeNote: {
        backgroundColor: colors.greyDark,
    },
    homeInputStartButton: {
        width : 30,
        height : 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // border: '2px solid',
        borderColor: colors.main,
        color: colors.main,
        borderRadius: 50,
        zIndex: 100,
    },
}));

function Home(props) {
    const { t } = useTranslation('common')
    const [value, setValue] = useState("");
    const [securityCode, setSecurityCode] = useState("");
    const [rules, setRules] = useState(null);
    const [response, setResponse] = useState(null);
    const [openModalPlugform, setOpenModalPlugform] = React.useState(false);
    const [openModalPlugnote, setOpenModalPlugnote] = React.useState(false);
    const [openModalPlugnoteEditable, setOpenModalPlugnoteEditable] = React.useState(false);
    const [viewCodeInput, setViewCodeInput] = React.useState(false);
    const [showMask, setShowMask] = useState(false)
    const [codeCheck, setCodeCheck] = useState('Pa-999999-*****')
    const [pfc, setPfc] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.pfc);
    const [pnc, setPnc] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.pnc);
    const [dh, setDh] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.dh);
    const [fh, setFh] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.fh);
    const [code, setCode] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.code);

    const [codeModelFiche, setCodeModelFiche] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.shared?.split('/')?.filter(Boolean));
    const [formLogo, setFormLogo] = useState(null);
    const codeInput = document.getElementById('home-code-input')
    const homeInput = useRef()

    const ErrorToastMessage = ({ title, message }) => (
        <div>
            <Translation ns="common">
                {
                    (t, { i18n }) => <h6>{t(title)}</h6>
                }
            </Translation>
            <Translation ns="common">
                {
                    (t, { i18n }) => <p>{t(message)}</p>
                }
            </Translation>
        </div>

    )

    const MainToastMessage = ({ title, message }) => (
        <div>
            <Translation ns="common">
                {
                    (t, { i18n }) => <p>{t(message)}</p>
                }
            </Translation>
        </div>

    )

    const validateCode = () => {
        return value.length == 15 && !value.includes('.') && (value.startsWith("PF") || value.startsWith("PN") || value.startsWith("PP"))
    }

    useEffect(() => {
        if(props.openModalPlugform){
            setOpenModalPlugform(true);
        }else{
            setOpenModalPlugform(false);
        }

    }, [props.openModalPlugform])

    useEffect(() => {
        if(props.openModalPlugnote){
            setOpenModalPlugnote(true);
        }else{
            setOpenModalPlugnote(false);
        }
    }, [props.openModalPlugnote])

    useEffect(() => {
        if(props.plugform !== null && props.plugnote === null && !openModalPlugform && !openModalPlugnote){
            props.attemptResetGetPlugform();
        }

    }, [openModalPlugform]);

    useEffect(() => {
        if(props.plugform !== null && props.plugnote !== null && !openModalPlugform && !openModalPlugnote){
            props.attemptResetGetPlugnote();
        }

    }, [openModalPlugform]);

    const _handleKeyDown = (e) => {
        //console.log(e)
        if (e.charCode === 13) {
            handleSearchPlugcode();
        }
    }

    const handleCheckSecurityCode = (value) => {
        if(securityCode === value && viewCodeInput && rules !== null){
            setSecurityCode("");
            setViewCodeInput("");
            let code = rules.readFiche.toString() + rules.editFiche.toString();
            switch (code.toString()) {
                case "10":
                    setOpenModalPlugnote(true);
                    break;
                case "11":
                    setOpenModalPlugnoteEditable(true);
                    break;
                default:
                    setRules(null);
                    setSecurityCode("");
                    setViewCodeInput("");
                    setResponse(null)
                    break;
            }
        }
    }

    const handleSearchPlugcode = () => {
        if (value.length > 0) {
            if (value.startsWith("PF")) {
                //props.attemptGetPlugform(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pfcode/v2/modelcodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(value),
                    }, '?'))
                        .then((response) => {
                        //console.log(response);
                        if(response.data.result == true || response.data.result === 'ok'){
                            //console.log('resposta da api', response)
                            setResponse(response.data)
                            setFormLogo(response.data.logo)
                            setOpenModalPlugform(true);
                        }else if(response.data.result == false || response.data.result === 'onk'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }else if(response.data.result == 'nok'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        } else {
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }
                    })
                        .catch((response) => {
                        //je lance une notification (toast error)
                        //console.log("error", response);
                        toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
                )
            }
            else if(value.startsWith("PW")){
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pncode/datapwcodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(value),
                    }, '?'))
                        .then((response) => {
                            //console.log(response);
                            if(response.data.result == true){
                                //console.log('resposta da api', response)
                                setViewCodeInput(true);
                                setResponse(response.data)
                                setSecurityCode(response.data.security);
                                setRules(response.data.share);
                                confirmAlert({
                                    title: i18n.t('common:global.plugnotes'),
                                    message: i18n.t('common:plugcode.send-code-sms'),
                                    buttons: [
                                        {
                                            label: i18n.t('common:global.ok'),
                                        }
                                    ]
                                });
                                //setOpenModalPlugnote(true);
                            }else if(response.data.result == false){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }else if(response.data.result == 'nok'){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            } else {
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }
                        })
                        .catch((response) => {
                            //je lance une notification (toast error)
                            //console.log("error", response);
                            toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                        })
                )
            }
            else {
                //props.attemptGetPlugnote(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pncode/datacodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(value),
                    }, '?'))
                        .then((response) => {
                        // console.log(response);
                        if(response.data.result == true){
                            // console.log('resposta da api', response)
                            setResponse(response.data)
                            setOpenModalPlugnote(true);
                        }else if(response.data.result == false){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }else if(response.data.result == 'nok'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        } else {
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }
                    })
                        .catch((response) => {
                        //je lance une notification (toast error)
                        //console.log("error", response);
                        toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
                )
            }
        }
    }

    const handleResetAllDatas = () => {
        setValue('');
        setViewCodeInput(false);
        setSecurityCode("");
        setResponse(null);
    }

    useEffect(() => {
        if(typeof fh !== "undefined" && typeof pfc !== "undefined" && fh !== undefined && pfc !== undefined){
            props.history.push({
                pathname: '/',
                search: '',
            });
            let params = {
                mh : fh,
                pfc : pfc
            }

            trackPromise(
                //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                axios.get(ApplicationConfig.apiUrl + 'pfcode/modellogoff.json' + qs.stringify(params, '?'))
                    .then((response) => {
                        //console.log(response);
                        if(response.data.result == true || response.data.result === 'ok'){
                            //console.log('resposta da api', response)
                            setResponse(response.data)
                            setOpenModalPlugform(true);
                        }else if(response.data.result == false || response.data.result === 'onk'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                            });
                        }else if(response.data.result == 'nok'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                            });
                        } else {
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                            });
                        }
                    })
                    .catch((response) => {
                        //je lance une notification (toast error)
                        //console.log("error", response);
                        toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
            )
        }

    }, [fh, pfc])


    useEffect(() => {
        if(typeof dh !== "undefined" && typeof pnc !== "undefined" && dh !== undefined && pnc !== undefined){
            props.history.push({
                pathname: '/',
                search: '',
            });
            let params = {
                dh : dh,
                pnc : pnc
            }
            trackPromise(
                //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                axios.get(ApplicationConfig.apiUrl + 'pncode/v2/datalogoff.json' + qs.stringify(params, '?'))
                    .then((response) => {
                        //console.log(response);
                        if(response.data.result == true){
                            console.log('resposta da api', response)
                            setResponse(response.data)
                            setOpenModalPlugnote(true);
                        }else if(response.data.result == false){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                            });
                        }else if(response.data.result == 'nok'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                            });
                        } else {
                            setResponse(null)
                            toast.warn(`${I18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                            });
                        }
                    })
                    .catch((response) => {
                        //je lance une notification (toast error)
                        //console.log("error", response);
                        toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
            )
        }

    }, [dh, pnc])


    useEffect(() => {
        if(typeof code !== "undefined"){
            props.history.push({
                pathname: '/',
                search: '',
            });

            setValue(code);

            if (code.startsWith("PF")) {
                //props.attemptGetPlugform(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pfcode/v2/modelcodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(code),
                    }, '?'))
                        .then((response) => {
                            //console.log(response);
                            if(response.data.result == true || response.data.result === 'ok'){
                                //console.log('resposta da api', response)
                                setResponse(response.data)
                                setFormLogo(response.data.logo)
                                setOpenModalPlugform(true);
                            }else if(response.data.result == false || response.data.result === 'onk'){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }else if(response.data.result == 'nok'){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            } else {
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }
                        })
                        .catch((response) => {
                            //je lance une notification (toast error)
                            //console.log("error", response);
                            toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                        })
                )
            }
            else if(code.startsWith("PW")){
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pncode/datapwcodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(code),
                    }, '?'))
                        .then((response) => {
                            if(response.data.result == true){
                                console.log('resposta da api', response)
                                setViewCodeInput(true);
                                setResponse(response.data)
                                setSecurityCode(response.data.security);
                                setRules(response.data.share);
                                confirmAlert({
                                    title: i18n.t('common:global.plugnotes'),
                                    message: i18n.t('common:plugcode.send-code-sms'),
                                    buttons: [
                                        {
                                            label: i18n.t('common:global.ok'),
                                        }
                                    ]
                                });
                                //setOpenModalPlugnote(true);
                            } else if (response.data.result == false) {
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            } else if (response.data.result == 'nok') {
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            } else {
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }
                        })
                        .catch((response) => {
                            //je lance une notification (toast error)
                            console.log("error", response);
                            toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                        })
                )
            } else {
                //props.attemptGetPlugnote(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pncode/datacodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(code),
                    }, '?'))
                        .then((response) => {
                            console.log(response);
                            if(response.data.result == true){
                                console.log('resposta da api', response)
                                setResponse(response.data)
                                setOpenModalPlugnote(true);
                            }else if(response.data.result == false){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }else if(response.data.result == 'nok'){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            } else {
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }
                        })
                        .catch((response) => {
                            //je lance une notification (toast error)
                            console.log("error", response);
                            toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                        })
                )
            }
        }

    }, [code])

    useEffect(() => {
        if(typeof codeModelFiche !== "undefined"){
            // props.history.push({
            //     pathname: '/',
            //     search: '',
            // });

            if (codeModelFiche[0].startsWith("PF")) {
                //props.attemptGetPlugform(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    /**
                     * api.get('pfcode/modelcodelogoff.json' + qs.stringify({
    code: encodeURIComponent(plugcode),
    folder : encodeURIComponent(folder),
    plug : encodeURIComponent(plug),
}, '?'));
                     */


                    axios.get(ApplicationConfig.apiUrl + 'pfcode/modelcodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(codeModelFiche[0]),
                        folder : encodeURIComponent(codeModelFiche[1]),
                        plug : encodeURIComponent(codeModelFiche[2]),
                        owner : encodeURIComponent(typeof codeModelFiche[3] !== "undefined" ? codeModelFiche[3] : null)
                    }, '?'))
                        .then((response) => {
                            console.log(response);
                            if(response.data.result == true || response.data.result === 'ok'){
                                console.log('resposta da api', response)
                                setResponse(response.data)
                                setFormLogo(response.data.logo)
                                setOpenModalPlugform(true);
                            }else if(response.data.result == false || response.data.result === 'onk'){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }else if(response.data.result == 'nok'){
                                //je lance une notification (toast warning)
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            } else {
                                setResponse(null)
                                toast.warn(`${I18n.t('common:template.code-error')}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 5000,
                                    className: `${classes.homeNote}`,
                                });
                            }
                        })
                        .catch((response) => {
                            //je lance une notification (toast error)
                            console.log("error", response);
                            toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                        })
                )
            }
        }

    }, [codeModelFiche])

    let buttonMsg;
    if(value.startsWith("PF")){
        buttonMsg = `${I18n.t('common:welcome.access-plugform')}`
    } else if (value.startsWith("PN")){
        buttonMsg = `${I18n.t('common:welcome.access-plugnote')}`
    } else {
        buttonMsg = `${I18n.t('common:welcome.access-pp')}`
    }

    const classes = useStyles();

    return (
       <div className={classes.homeContainer}>
           <div className={classes.homeContent}>
               <div className={classes.homeTitle}>
                    {/* <span className={classes.homeTitlePlugcodes}>plugcodes</span> */}
                    <img
                    className={classes.homePlugcodeLogo}
                    src={Images.plugcodes}
                    alt="logo"
                />
                    <span className={classes.homeTitleBy}>{I18n.t('common:global.by')}</span>
                    <img
                    className={classes.homeLogo}
                    src={Images.logoWithoutDefault}
                    alt="logo"
                />
               </div>
                <div className={classes.homeCodeContainer}>
                    <span className={classes.homeCodeText}>{t('welcome.plugcode-encodage-text')}</span>
                </div>

                <div className={classes.homeCodeContainer} style={{paddingTop: 10}}>
                    <span className={classes.homeCodeText} style={{fontSize:25}}>{t('plugcode.add-plugcode')}</span>
                    <div className={classes.homeButtonContainer}>
                        <div className={classes.homeCodeInput}>
                            <span >
                                <Search className={classes.homeInputStartButton} onClick={() => {if(validateCode()) {handleSearchPlugcode(); setCodeCheck('Pa-999999-*****')} else document.getElementById('home-code-input').focus()}} style={{cursor : 'pointer'}}/>
                            </span>
                            <InputMask
                                ref={homeInput} //équivalent à ref={} pour les input
                                inputRef={homeInput}
                                id='home-code-input'
                                className={classes.homeInput}
                                maskChar="."
                                mask={value.length === 0 ? '' : codeCheck}
                                value={value}
                                placeholder={I18n.t('common:welcome.plugcode-encode-placeholder')}
                                autoComplete='off'
                                // selection={{start:0, end:0}}
                                onChange={(event) => setValue(event.target.value.toUpperCase())} type="text"
                                onKeyPress={(event) => _handleKeyDown(event)}
                                onFocus={(e) => { e.target.placeholder = ''; setShowMask(false); }}
                                onBlur={(e) => {e.target.placeholder = I18n.t('common:welcome.plugcode-encode-placeholder')}}
                            >
                            </InputMask>
                            <span
                                className={classes.homeInputStartButton}
                                style={value.length === 15 || !value.includes('.')? null : {display: 'none'}}
                                onClick={() => {document.getElementById('home-code-input').setSelectionRange(0, 0); handleResetAllDatas(); document.getElementById('home-code-input').focus();}} >
                                <Close className={classes.homeInputStartButton} style={{cursor : 'pointer'}}/>
                            </span>
                        </div>
                        {
                            viewCodeInput && securityCode.length === 10 &&
                            <Input
                                onChange={(event) => handleCheckSecurityCode(event.target.value)}
                                id="standard-full-width-field"
                                disableUnderline={true}
                                placeholder="CODE SMS"
                                label=""
                                fullWidth={true}
                                margin="none"
                                inputlabelprops={{
                                    shrink: true,
                                }}
                                className="input"
                            />
                        }
                    </div>

                </div>
               {
                    validateCode() ?
                    <button onClick={(event) => handleSearchPlugcode()}
                        disabled ={value.startsWith("PP")?true:false}
                        className={value.startsWith("PP")?classes.homeCodeButtonDisabled:classes.homeCodeButton}>
                            {buttonMsg}
                    </button> : <div style={{height:70}}/>
                }

                {
                    !openModalPlugform && !openModalPlugnote &&
                    <iframe
                        title='embedded-video'
                        src="https://player.vimeo.com/video/446364822"
                        className={classes.homeVideoVimeo}
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        // onClick={(e) => { console.log('video clicked'); }}
                        allowFullScreen />
                }


               <NavLink className={classes.homeHeaderLink} to={{pathname:"https://www.plugnotes.com"}} target="_blank">
                    {'> '}{I18n.t('common:welcome.know-more-plugnotes')}
                    </NavLink>
               {/* <div className={classes.homeGetMobileContainer}>
                    <NavLink
                        className={classes.homeGetMobileImgLink}
                        to={{pathname:"https://play.google.com/store/apps/details?id=com.plugnotes.production"}} target="_blank"
                    >
                    <Android className={classes.homeGetMobileIcon} />
                    Google Play
                    <img
                    src={Images.googleplay}
                    alt="logo"
                />
                    </NavLink>
                    <NavLink
                        className={classes.homeGetMobileImgLink}
                        to={{pathname:"https://apps.apple.com/app/plugnotes/id1449856929"}} target="_blank"
                    >
                    <Apple className={classes.homeGetMobileIcon} />
                    App Store
                    <img
                    src={Images.appstore}
                    alt="logo"
                />
                    </NavLink>
               </div> */}


           </div>
           <div className={classes.homeVersionContainer}>
                <span className={classes.homeVersion}>V{getBuildDate(meta.buildDate)}</span>
            </div>
           { openModalPlugform && response !== null &&
                <Suspense fallback={null}>
                    <ModalPlugformComponent logo={response.logoWeb} value={response} code={value} open={openModalPlugform} close={(event) => setOpenModalPlugform(false)} useSuspense={false}/>
                </Suspense>
            }

            { openModalPlugnote && response !== null &&
                <Suspense fallback={null}>
                    <ModalPlugnoteComponent logo={response.logoWeb} value={response} code={value} open={openModalPlugnote} close={(event) => setOpenModalPlugnote(false)} useSuspense={false}/>
                </Suspense>
            }

           { openModalPlugnoteEditable && response !== null &&
               <Suspense fallback={null}>
                   <ModalPlugnoteEditableComponent logo={response.logoWeb} value={response} code={value} email={rules.email} open={openModalPlugnoteEditable} close={(event) => setOpenModalPlugnoteEditable(false)} useSuspense={false}/>
               </Suspense>
           }
       </div>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    console.log(state);
    return {
        plugformlink: state.plugcode.plugformlink || null,
        plugformplug: state.plugcode.plugformplug || null,
        plugform: state.plugcode.plugform || null,
        plugnote: state.plugcode.plugnote || null,
        plugformowner: state.plugcode.plugformowner || null,
        plugnoteowner: state.plugcode.plugnoteowner || null,
        openModalPlugform: state.plugcode.openModalPlugform,
        openModalPlugnote: state.plugcode.openModalPlugnote,
    }
}

//http://localhost:3001/?dh=da4r5zbBEHaE0794686001649752541&pnc=pnri2eQzI1Llg0039638001649775216
//http://localhost:3001/?dh=da4r5zbBEHaE0794686001649752541&pnc=pnri2eQzI1Llg0039638001649775216
//http://localhost:3000/?fh=6b8ac314-2622-4585-9d14-efb6640a1e51&pfc=pnYIcf7sJMLM0128640001646230373
//http://localhost:3001/?fh=6b8ac314-2622-4585-9d14-efb6640a1e51&pfc=pnYIcf7sJMLM0128640001646230373

const mapDispatchToProps = (dispatch) => ({
    attemptGetPlugform: (code) =>
        dispatch(getPlugformViaCodeOfflineRequest(code)),
    attemptGetPlugformPayload: (payload) =>
        dispatch(getPlugformViaDoubleCodeOfflineRequest(payload)),
    attemptGetPlugnote: (code) =>
        dispatch(getPlugnoteViaCodeOfflineRequest(code)),
    attemptGetPlugnotePayload: (payload) =>
        dispatch(getPlugnoteViaDoubleCodeOfflineRequest(payload)),
    attemptResetGetPlugform: () =>
        dispatch(getPlugformViaCodeOfflineReset()),
    attemptResetGetPlugnote: () =>
        dispatch(getPlugnoteViaCodeOfflineReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home)
