import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import colors from "../../assets/themes/Colors";
import { postExtDataReset } from "../../actions/extdata";
import { devLog, isDefined } from "../../utils/utils";
import { HyperFormula } from "hyperformula";
import { restylizeNumber } from "../custom/FormatTable";
import { Tooltip } from "@material-ui/core";
import { ModalTablemultiTableViewComponent } from "../modals/tablemulti/ModalTablemultiTableViewComponent";
import { confirmAlert } from "react-confirm-alert";
import { createEmptyRow, getAllDefaultRows, getLabelWithSpecialChar, isRowLimitReached, isVisibleInCSV, populateRowWithDefaultValues, purgeDefaultEmptyRow } from "../../utils/Fields";
import PlugTooltip from "../custom/PlugTooltip";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    table: {
        //maxWidth : 'fit-content',
        // width: "99%",
        userSelect: 'none',
        "&::-youbkit-user-select": {
            color: 'red',
            backgroundColor: 'yellow',
        },
    },
    mainCtn: {
		width: isMobile ? '98vw' : '100%',
        alignItems: isMobile ? 'center' : 'stretch',
        marginTop: -40,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        position: 'relative',
		overflowY: 'visible',
    },
    tableRoot: {
        // width: "99%", //it was 100%
        overflowX: "auto",
    },
    //Top buttons
	topBtnCtn: {
        zIndex: 200,
		right: 0,
		gap: 3,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	topBtn: {
		width: 50,
		height: 30,
		// padding: 5,
		borderRadius: 6,
		boxShadow: '0px 1px 2px 1px #ddd',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		backgroundColor: colors.main,
		color: 'white',
		'&:hover': {
			boxShadow: '0px 0px 10px 1px #aaa',
			fontWeight: 'bold',
		},
	},
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        // marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    scrollBar: {
        // padding: '5px',
        overflowX: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '10px',
            background: '#E3E3E3',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&::-webkit-scrollbar-button:single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */

    },
    addCellButton: {
        backgroundColor: colors.greyLighter,
        // display: 'flex',
        justifyContent: 'center',
        borderBottom: 'none'
    },
    totalCtn: {
        backgroundColor: colors.greyLighter,
    },
    rowDelCellButton: {
        backgroundColor: colors.greyLighter,
        display: 'flex',
        alignItems: 'center',
    },
    rowNum: {
        color: colors.grey,
        //fontWeight: 'bold',
    },
    tableHeader: {
        padding: 15,
    },
    tableMultAddButton: {
        // width : 20,
        // height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        fontSize: 28,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableMultExportButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.main,
        color: colors.main,
        fontSize: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headerSeparator: {
        borderColor: colors.greyLight,
        borderStyle: 'solid',
        borderWidth: 0,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        padding: 10,
    },
    buttonBar:{
        marginLeft: 'auto',
        backgroundColor: colors.greyLighter,
        display: 'flex',
        justifyContent:'space-between',
        height: 30,
        alignItems: 'center'
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        //border: '1px solid greenyellow',
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    width:{
        width : '99%'
    },
    paginationStyle: {
        border: '1px solid blue',
        position: "sticky",
        left: '-10px',
        background: colors.white,
        borderWidth: 0,
    },
    tableMultPageText: {
        color: colors.main,
        fontSize: 15,
        display: 'flex',
        gap: '8px'
    },
    tableMultButton: {
        fontSize: 13,
        alignSelf: 'center',
        // margin: '0 6px',
        color: colors.main,
        "@media (max-width: 640px)": {
            fontSize: 8,
        }
    },
    tableRowField: {
        fontSize: 10,
        width: 30,
        margin: '0 auto',
        color: colors.main,
        "@media (max-width: 640px)": {
            fontSize: 8,
        }
    },
    tableMultPageChoice: {
        color: colors.white,
        backgroundColor: colors.unselectedItem,
        height: '100%',
        fontSize: 15,
        fontWeight: 'bold',
        padding: '0 5px',
        borderRadius: '6px',
        cursor: 'pointer',
        "@media (max-width: 640px)": {
            padding: '0 3px',
            fontWeight: '100 !important',
            fontSize: 11
        }
    },
    paginationStyleRow: {
        //border: '3px solid blue',
    },
    paginationStyleFooter: {
        //border: '3px solid blue',
    },
    //Fonts
    fontChange: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    fontTotal: {
        fontSize: 16,
        paddingLeft: '5px!important',
    },
    fontChangeLabel: {
        fontSize: 12,
        color: colors.main,
        fontWeight: 'bold',
        marginLeft: '10px',
        "@media (max-width: 640px)": {
            marginLeft: 2
        }
    },
    fontChangeWidth: {
        fontSize: 12,
        minWidth : 120
    },
    fontFirst: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    textFont: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        paddingTop: 6,
    },
    textLength: {
        fontSize: 8,
        position: 'absolute',
        top: -6,
        right: 1,
    },
    fontFieldNumber: {
        fontSize: 8,
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        verticalAlign: 'middle',
        width: 16,
        height: 16,
    },
    //Render Item Single Select Component Styles
    renderSingleSelMainContainer: {
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    renderSingleSelContainer: {
        minHeight: 25,
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        // flexWrap: 'wrap',
    },
    renderSingleFormulaContainer: {
        minHeight: 25,
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
    },
    renderSingleSubTotalContainer: {
        padding : 0,
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
    },
    renderInputContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'no-wrap',
    },
    renderSingleSelSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    renderSingleSelPreselNum: {
        fontSize: 10,
        color: colors.greyLight,
        border: `1px solid ${colors.greyLight}`,
        borderRadius: 50,
        padding: '1px 2px',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    durationInputContainer: {
        display: 'flex',
        width: '100%',
        background: colors.greenLightest,
        marginBottom: 3,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottom: '1px solid black',
        position: 'relative'
    },
    renderFirstInputContainer: {
        display: 'flex',
        width: '40px'
    },
    renderSecondInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 'auto'
    },
    renderCentralInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 17
    },
    renderThirdInputContainer: {
        width: '70%'
    },
    tableLabelContainer: {
        marginRight: 'auto',
        order: 1,
        width : '30%',
        "@media (max-width: 640px)": {
            minWidth: '30%',
            maxWidth: '50%'
        }
    },btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedItem,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedItem,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 2,
    },
    selectField: {
        minHeight: 24
    },
    fieldContainer: {
        display : 'flex',
        alignItems : 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 3,
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width : 'calc(60% + 32px)',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
        color: colors.main
    },
    fieldValueContainer: {
        display: 'flex',
        marginLeft: 'auto',
        order: 2,
        width : '60%'
    },
    fieldValues: {
        color: colors.main,
        padding: 0,
        paddingLeft: 5,
        cursor: 'pointer',
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
    },
    fieldLabels: {
        fontWeight: 'bold',
        wordBreak: 'break-word',
        color: colors.main,
        textAlign: 'left',
        padding: '10px 15px',
    },
    pageRowCtn: {
        height: 30,
        verticalAlign: 'middle',
        backgroundColor: colors.greyLighter,
        borderBottom: 'none'
    },
    pageCell: {
        textAlign: 'center',
    },
    arrowRight: {
        textAlign: 'left',
        height: 28,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
    },
    arrowLeft: {
        textAlign: 'right',
        height: 30,
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
    },
    hidden: {
        color: colors.greyLighter
    },
    tripleCell: {
        '&.MuiTableCell-root': {
            borderBottom: 'none !important'
        },
        height: 30
    },
    rowBtn: {
        justifyContent: 'center',
        borderBottom: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.hoverBgnd,
        },
    },
	//New design
	totalValues: {
		color: colors.bloodOrange,
		padding: 0,
		paddingLeft: 5,
		wordBreak: 'break-word',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'left',
		fontSize: 12,
	},
	summaryCtn: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		gap: 5
	},
	summary: {
		paddingRight: 4
	},
	rowCtn: {
		justifyContent: 'flex-end',
		display: 'flex',
		flexDirection: 'row',
        minHeight: 30,
        width: '70%',
        alignSelf: 'flex-end',
	},
    //table preview
    tooltipCtn: {
		maxWidth: 'none',
		backgroundColor: colors.greyLighter,
		boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
		borderRadius: 8,
	},
	noTooltip: {
		backgroundColor: colors.transparent,
	},
	previewCtn: {
		backgroundColor: colors.transparent,
		margin: 10,
		maxWidth: '95vw',
	},
}));

const FormatTableMultipleComponent = (props) => {
    // devLog('Tablemulti got props', props)
    let tmpDisabled = false
    if (typeof props.disabled !== "undefined") {
        tmpDisabled = props.disabled;
    } else if(typeof props.field.disabled !== "undefined"){
        tmpDisabled = props.field.disabled;
    }
    const classes = useStyles()
    const { t } = useTranslation(['common'])

    let tmpValue = ""
    if (typeof props.value !== "undefined" && props.col.format !== 'numeric' && props.value.length > 0){
        tmpValue = props.value;
    } else if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric'){
        tmpValue = restylizeNumber(props.value);
    }

    let colonnesOptions = props.field.options.length > 0 ? props.field.options : []
    if (props.field.options?.lines) {
        colonnesOptions = props.field.options?.lines?.length > 0 ? props.field?.options?.lines : []
    }

    const setup = props.field.options?.setup
	const defaultRows = setup?.defaultRows || []
    const rowLimit = setup?.rowLimit || 0
    const colonnes = colonnesOptions
    const [rows, setRows] = useState(props.field.value.length > 0 ? props.field.value : [])
    const [recap, setRecap] = useState(false)
    const [tableLength, setTableLength] = useState(recap ? rows.length - 1 : rows.length)
    const [disabled , setDisabled] = useState(tmpDisabled)
    const [openDownload, setOpenDownload] = useState(false)
    const [datasToDownload, setDatasToDownload] = useState([])
    const [openTable, setOpenTable] = useState(false)
    const optionFormule = { licenseKey: 'gpl-v3' }
    const [newRow, setNewRow] = useState(null)
    const [openRow, setOpenRow] = useState(null)
	const isAddRowPossible = !disabled && tableLength < rowLimit

    //function deleteEmpty pour supprimer une rows[n] vide dans un useEffect ([])
    function deleteEmptyRows (rows) {
        let newRows = []
        if(rows.length > 0) {
            rows.forEach((row, index) => {
                let value = ''
                Object.values(row).forEach((valeur, id) => {
                    if(valeur !== '' && valeur !== undefined) value = valeur
                })
                if(value !== '') newRows.push(row)
            })
        }
        return newRows
    }

    useEffect(() => {
        let hasRecap = false
        if (props.field.options?.lines) {
            props.field.options.lines.forEach((col) => { if (col.recap === true) hasRecap = true })
        } else {
            props.field.options.forEach((col) => { if (col.recap === true) hasRecap = true })
        }
        setRecap(hasRecap)
        if (rows.length > 0) {
            let newRows = deleteEmptyRows(rows)
            setTableLength(newRows.length > 0 && newRows[newRows.length - 1].hasOwnProperty('recap')? newRows.length - 1 : newRows.length)
            setRows(newRows)
        } else if ((props.mode.includes('create') || props.mode.includes('complete')) && defaultRows?.length > 0) {
            if (setup?.defaultComplete === 'open') {
                let _rows = purgeDefaultEmptyRow(getAllDefaultRows(colonnes, setup?.defaultRows))
                // devLog('initial rows', _rows)
                setRows(hasRecap ? [..._rows, createRecapData()] : _rows)
            }
        }
    }, [])

    const createNewRowWithDefaultValues = () => {
        let row = createEmptyRow(colonnes)
        return populateRowWithDefaultValues(colonnes, setup?.defaultRows, tableLength, row)
    }


    const handleClickAddRow = (e) => {
        if (isDefined(e)) {
            e.preventDefault()
            e.stopPropagation()
        }
        let _reason = e
		if ((!isDefined(e) || typeof e === "object") && rows.length === 0) { _reason = setup?.defaultComplete }
		handleAddTableMulti(_reason)
		if(!openTable) setOpenTable(true)
    }

    const handleAddTableMulti = (reason) => {
        if (isDefined(reason) && reason === "once" && !disabled) {
            let newRows = purgeDefaultEmptyRow(getAllDefaultRows(colonnes, setup?.defaultRows))
            setRows(recap ? [...newRows, createRecapData()] : newRows)
        } else {
            let _newRow = createNewRowWithDefaultValues()
            if (rows.length > 0) {
                let isEmpty = true
                let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
                _rows = _rows.filter(Boolean);
                if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                    if (_rows.length > 0) {
                        let lastOne = _rows[_rows.length - 1];
                        Object.values(lastOne).forEach((k) => {
                            let control = typeof k !== "string" ? k.toString() : k;
                            if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                        })
                        if (!isEmpty) {
                            if (recap) setRows([..._rows, _newRow, createRecapData()])
                            else setRows([..._rows, _newRow]);
                            setNewRow(_newRow)
                        }
                        else {
                            setOpenRow(_rows.length - 1)
                        }
                    }
                    else {
                        if (recap) setRows([_newRow, createRecapData()])
                        else setRows([_newRow]);
                        setNewRow(_newRow)
                    }
                } else {
                    if (!toast.isActive()) {
                        toast.info(t('tablemulti.row-limit-warn'),
                            {
                                autoClose: 3000,
                                hideProgressBar: true,
                                bodyClassName: classes.warnToast,
                                className: classes.warnToast,
                                toastClassName: classes.warnToast,
                            })
                    }
                }
            } else {
                if (recap) { setRows([...rows, _newRow, createRecapData()]) }
                else { setRows([...rows, _newRow]) }
                setNewRow(_newRow)
            }
        }
    }

    const handleChangeTableValue =  () => {
        props.handleChangeTableValue(rows, props.field, props.index);
    }

    const handleDuplicateRow = async (row) => {
        let _newRowDuplicated = { ...row }
        if (rows.length > 0) {
            let isEmpty = true
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
            _rows = _rows.filter(Boolean);
            if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                if (_rows.length > 0) {
                    let lastOne = _rows[_rows.length - 1];
                    Object.values(lastOne).forEach((k) => {
                        let control = typeof k !== "string" ? k.toString() : k;
                        if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                    })
                    if (!isEmpty) {
                        if (recap) setRows([..._rows, _newRowDuplicated, createRecapData()]);
                        else setRows([..._rows, _newRowDuplicated]);
                        //setNewRow(_newRowDuplicated)
                    } else {
                        setOpenRow(_rows.length - 1)
                    }
                } else {
                    if (recap) setRows([_newRowDuplicated, createRecapData()])
                    else setRows([_newRowDuplicated]);
                }
            } else {
                if (!toast.isActive()) {
                    toast.info(t('tablemulti.row-limit-warn'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                }
            }
        } else {
            if (recap) { setRows([...rows, _newRowDuplicated, createRecapData()]) }
            else { setRows([...rows, _newRowDuplicated]) }
        }
    }

    const handleExportTableMulti = () => {
        let dataRows = [];
        if (colonnes.length > 0){
            let line1 = [];
            line1.push(t('export.export-date'))
            line1.push(t('export.export-key'))
            line1.push(t('export.export-number'))
            line1.push(t('export.export-field-number'))
            line1.push(t('export.export-field'))
            colonnes.forEach(col => {
                if (isVisibleInCSV(col, props.mode)) line1.push(getLabelWithSpecialChar(col.label))
            });

            dataRows.push(line1);
            rows.forEach((row, index) => {
                let dataRow = [];
                // date et heure de l'export
                // ajouter Clé du plugform
                // Numero de plugnote
                // Numero du champ
                // Libellé du champ
                let now = new Date()
                dataRow.push(now.toLocaleString())
                dataRow.push(props.plugKey)
                dataRow.push(props.plugNb)
                dataRow.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
                dataRow.push(props.title)

                colonnes.forEach(col => {
                    if (isVisibleInCSV(col, props.mode)) {
                        let someText = typeof row[col.id] === 'string'
                            ? row[col.id].replace(/\r?\n?/g, '').trim()
                            : row[col.id]
                        if (someText === undefined) {
                            someText = "";
                        }
                        dataRow.push(someText);
                }})
                dataRows.push(dataRow);
            });
        }

        if (dataRows.length > 0) {
            setDatasToDownload(dataRows);
            setOpenDownload(true);
        }
    }

    const handleAddRecap = () => {
        setRows([...rows, createRecapData()]);
    }

    useEffect(() => {
        if(openDownload){
            setDatasToDownload([]);
            setOpenDownload(false);
        }
    }, [openDownload]);

    const handleRemoveSpecificRow = (value) => {
        setOpenRow(null)
        let items
        if (Array.isArray(value)) {
            items = rows.filter((_, index) => !value.includes(index + 1))
        } else {
            items = rows.filter((row, index) => index !== value);
        }
        if (recap) {
            let row = items.find(row => row?.recap === true)
            if (row && items.length > 1) {
                let rindex = items.indexOf(row)
                colonnes.forEach( (col, index) => {
                    if (col?.recap){
                        row["recap"] = true;
                        row[col.id] = getAllValueFromRowsToSpecificIndex(items, col.id)
                    }
                })
                items[rindex] = row;
                setRows([...items]);
            }
            else setRows([])
        }
        else setRows(items);
    };

    function createRecapData() {
        let data = {};
        colonnes.forEach((col, index) => {
            if(col?.recap){
                data["recap"] = true;
                data[col.id] = getAllValueFromRowsToSpecificIndex(rows, col.id)
            }
        })
        return data;
    }

    function refreshRecapData () {
        let _rows = [...rows]
        let row = _rows.find(row => row?.recap === true)
        if (row) {
            let rindex = _rows.indexOf(row)
            colonnes.forEach( (col, index) => {
                if (col?.recap){
                    row["recap"] = true;
                    row[col.id] = getAllValueFromRowsToSpecificIndex(rows, col.id)
                }
            })
            rows[rindex] = row;
            setRows([...rows]);
        }
    }

    const getAllValueFromRowsToSpecificIndex = (rows, id) => {
        let total = 0;
        rows.map((row, index) => {
            if(row[id] && row?.recap !== true){
                total += Number(row[id]);
            }
        });
        return total;
    }

    const handleCalculateValueAuto = async (row, formula) => {
        let answer = null;
        let tmp = [];

        colonnes.map((elm, index) => {
            let tmpValue = row[elm.id];
            if(Object.prototype.toString.call(tmpValue) === '[object Array]' || Object.prototype.toString.call(tmpValue) === '[object Object]') {
                tmpValue = "";
            }
            if(elm.format === 'numeric' && row[elm.id] !== undefined && typeof row[elm.id] !== 'number'){
                tmpValue = row[elm.id].replace(', ', '.');
            }
            tmp.push([tmpValue]);
        });

        if (formula !== null) {
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };

            let sheetAData = data;

            let newFormula =  formula;

            const hfInstance = HyperFormula.buildEmpty(optionFormule);

            let formulasData = [[
                newFormula
            ]];

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);


            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );


            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if(!mySum.hasOwnProperty('type')){
                    answer =  mySum;
                }else{
                    answer =  "";
                }
            }catch (e){
                //setResult(e.message)
                answer =  "";
            }

            hfInstance.destroy();

        }
        return answer
    }

    const handleChangeChildrenValue =  async (value, col, rindex) => {
        if (!props.disabled && rindex >= 0) {
            const row = rows[rindex]
            let obj = colonnes.filter(o => o.format === 'formula').filter(Boolean)
            switch (col.format) {
                case 'presel': {
                    let multiselect = col.mask.multiselect;
                    let editable = col.mask.editable;
                    if (multiselect === false && editable === false) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === false && editable === true) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === true && editable === false) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === true && editable === true) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    break;
                }
                default:
                    row[col.id] = value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                             if (tmpValue !== null){
                                 row[list.id] = tmpValue
                             }
                        }
                    }
                    setRows(previous => {
                        let _rows = [...previous]
                        _rows[rindex] = row;
                        return _rows
                    });
                    break;
            }
            refreshRecapData();
        }
    }

    const handleChangeChildrenRowValue =  async (value, col, row, rindex) => {
        if (!props.disabled) {
            switch (col.format) {
                case 'extdata':
                    rows[rindex] = value;
                    setRows([...rows]);
                    break;
                default:
                    break;
            }
        }
    }

    const handleViewTable = () => {
        setOpenTable(true)
    }

    function handleCloseTable () {
        setOpenTable(false)
        setNewRow(null)
    }

    const handleClickPreview = (index, key) => {
        // if (!disabled) {
        //     if (isDefined(key)) setOpenRow({row: rows[index], id: key});
        //     else setOpenRow(index);
        // }
        if (!disabled && rows.length === 0) { handleClickAddRow() }
        handleViewTable()
    }

    const handleDeleteAll = () => {
        if(!disabled) {
            confirmAlert({
                title: t('actions.delete'),
                message: t('tablemulti.confirm-delete'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () => {
                            setRows([])
                        }
                    },
                    {
                        label: t('global.no'),
                    },
                ]
            });
        }
    }

    const isLimitReached = () => {
		return isRowLimitReached(props.field.options?.setup?.rowLimit, rows)
    }

    useEffect(() => {
        let tmpDisabled = false;
        if(typeof props.disabled !== "undefined"){
            tmpDisabled = props.disabled;
        }
        else if(typeof props.field.disabled !== "undefined"){
            tmpDisabled = props.field.disabled;
        }
        setDisabled(tmpDisabled);
    }, [props]);

    useEffect(() => {
        handleChangeTableValue();
        setTableLength(rows.length > 0 && rows[rows.length - 1].hasOwnProperty('recap')? rows.length - 1 : rows.length)
    }, [rows])

    // console.log("props F32", props.mode);

    return (
        colonnes.length > 0 &&
            <div className={classes.mainCtn}>
                <div className={classes.rowCtn}>
                    <div className={classes.topBtnCtn}>
                        {
                            !disabled && rowLimit > tableLength &&
                            <PlugTooltip title={isLimitReached() ? t('tablemulti.row-limit-warn') : t('tablemulti.add-row')} placement="top">
                                <div className={`${classes.topBtn} ${isLimitReached() ? classes.disabledBtn : undefined} `} colSpan={3} onClick={handleClickAddRow}>
                                    <span id="icon-add-playlist" className={` material-icons`} style={isLimitReached() ? { cursor: 'not-allowed' } : undefined}>
                                        add_circle_outline
                                    </span>
                                </div>
                            </PlugTooltip>
                        }
                        {
                            rows.length > 0 &&
                            <Tooltip title={t('tablemulti.table-view').concat(` ${tableLength} `).concat(t('tablemulti.rows'))} placement="top">
                                <div className={classes.topBtn} onClick={handleViewTable}>
                                    <span id="icon-add-playlist" className="material-icons">
                                        visibility
                                    </span>
                                    <span style={{ fontSize: 10, fontWeight: 'bolder', }}>
                                        {`(${tableLength})`}
                                    </span>
                                </div>
                            </Tooltip>
                        }
                    </div>
                </div>
                <ModalTablemultiTableViewComponent
                    {...props}
                    attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                    colonnes={colonnes}
                    datasToDownload={datasToDownload}
                    disabled={disabled}
                    field={props.field}
                    handleAddRecap={handleAddRecap}
                    handleAddTableMulti={handleAddTableMulti}
                    handleChangeChildrenRowValue={handleChangeChildrenRowValue}
                    handleChangeChildrenValue={handleChangeChildrenValue}
                    handleClose={handleCloseTable}
                    handleClickPreview={handleClickPreview}
                    handleDeleteAll={handleDeleteAll}
                    handleDeleteRow={handleRemoveSpecificRow}
                    handleDuplicateRow={handleDuplicateRow}
                    handleExportTableMulti={handleExportTableMulti}
                    hasDb={false}
                    isAddRowPossible={isAddRowPossible}
                    isFromPwCode={props.isFromPwCode}
                    isPreview={!openTable}
                    mode={props.mode}
                    model={props.model}
                    newRow={newRow}
                    open={openTable}
                    openDownload={openDownload}
                    openRow={openRow}
                    owner={props.owner}
                    ownerId={props.ownerId}
                    recap={recap}
                    rows={rows}
                    setNewRow={setNewRow}
                    setOpenRow={setOpenRow}
                    title={props.title}
                />
            </div>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
// console.log('F32 state', state);
    return {
        // "plugKey": state.plugcode.modelClef // clef fournie par plugforms.js
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTableMultipleComponent);
