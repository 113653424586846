import React, {useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement} from "../../utils/Common";
import '@contentful/forma-36-react-components/dist/styles.css';
import {getTemplateFormatText} from "../../utils/Compatibility";
import ReactDataGrid from "react-data-grid";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Close, Search} from "@material-ui/icons";
import {confirmAlert} from "react-confirm-alert";
import {trackPromise} from "react-promise-tracker";
import * as apiExtData from "../../api/extdataApi";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    }
}));

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};


const ViewPlugnotes = ({plugnotes,envIndex, limitPage, startPage, model, handleSelectPlugnote, clef, field, attemptGetPlugnotesPaginateByClefAndUserHash, hash, actif, archived, activeSearch, search}) => {
    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const gridRef = useRef(null);

    const environment = getCurrentEnvironnement();

    const initialRows = [];
    let initColumns = [];

    const onRowClick = (object) => {
        handleSelectPlugnote(object.row.plugnote);
    }

    const headerTitleView = (object) => {
        let item = object.column.item;
        let template = getTemplateFormatText(model['field'+item+'format'],typeof model['field'+item+'formatoptions'] !== "undefined" ? model['field'+item+'formatoptions'] : null);
        let span =  ' ( ' + item + ' - ' + template +' )';

        return (
            <div className="flex_display_direction_column">
                <span>{object.column.name}</span>
                <span style={{fontSize : '10px'}}>{span}</span>
            </div>
        );
    }

    const specialAction = (object) => {
        let column = object.column.key;
        let value = object.row[column];

        switch (object.column.format) {
            case 'phone':
                return (
                    <div>
                        <span>
                            <a href={`tel:${value}`}>{value}</a>
                        </span>
                    </div>
                );

            case 'mail':
                return (
                    <div>
                        <span>
                            <a href={`mailto:${value}`}>{value}</a>
                        </span>
                    </div>
                );

            case 'url':
                return (
                    <div>
                        <span>
                            <a href={value} target="_blank" rel="noreferrer">{value}</a>
                        </span>
                    </div>
                );


            default :
                return (
                    <div onClick={(event) => {onRowClick(object)}}>
                        <span>
                            {value}
                        </span>
                    </div>
                )
        }
    };

    const headerIdView = (object) => {
        return (
            <div
                style={{
                    color : '#58C3C1'
                }}
                className="flex_display_direction_column"
            >
                <span>
                        {object.column.name}
                    </span>
            </div>
        );
    }

    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
    }, [actif, archived,activeSearch,plugnotes]);


    if(plugnotes.length > 0 && model){
        // columns.push({ key: 'view', name:  '', resizable: true, sortable : false , width: 25, formatter : viewAction });
        initColumns.push({ key: 'userindex', name: "N°",
            resizable: true,
            sortable : true,
            item : 0,
            headerRenderer: headerIdView,
            formatter : specialAction,
            special : true
        });

        for (let item = 1; item <= 15 ; item++){
            if(model['field'+item+'label'] !== null && typeof model['field'+item+'label'] !== "undefined"){

                initColumns.push({ key: 'field' + item, name:  model['field'+item+'label'],
                    resizable: true,
                    sortable : true,
                    item : item,
                    headerRenderer: headerTitleView,
                    formatter : specialAction,
                    format : model['field'+item+'format'],
                    special : false
                });
            }
        }

        plugnotes.map((plugnote, index) => {

            if(actif === true && archived === true && (plugnote.isarchived === false || plugnote.isarchived === true)){
                let finalObject = {};
                let founded = false;
                finalObject['include'] = true;
                finalObject.userindex = '[' + envIndex + '-' + plugnote.userindex + ']';

                for (let item = 1; item <= 15 ; item++){
                    if(model['field'+item+'label'] !== null){
                        let value = plugnote['field'+item];
                        if(plugnote['field'+item+'format'] === 'select'){
                            try {
                                let values = JSON.parse(plugnote['field'+item]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field'+item];
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'plugform'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            value = typeof plugValue !== "undefined" ? plugValue.value : "";
                        }
                        else if(plugnote['field'+item+'format'] === 'qrcode'){
                            if(typeof value !== 'undefined' && value.length > 0){
                                let qrCode = "";
                                let comment = "";
                                if(value.startsWith(';')){
                                    let fieldValue  = value.substring(1);
                                    qrCode = '';
                                    comment = fieldValue;
                                }else{
                                    let fieldValue = value.split(/([^,]*);(.*)/);
                                    let fieldFilteredValue =  fieldValue.filter( a => a);
                                    qrCode = fieldFilteredValue[0];
                                    comment = fieldFilteredValue[1];
                                }

                                if(qrCode !== undefined && qrCode.length > 0){
                                    if (value.startsWith('PN') || value.startsWith('PP') || value.startsWith('PF') || value.startsWith('pn') || value.startsWith('pp') || value.startsWith('pf')){
                                        value = '(' + qrCode.toUpperCase() + ')';
                                    }
                                }

                                if(comment !== undefined && comment.length > 0){
                                    value = comment;
                                }

                                if(qrCode !== undefined && comment !== undefined && qrCode.length > 0 && comment.length > 0){
                                    value = '(' + qrCode.toUpperCase() + ')' + ' ' + comment;
                                }
                            }

                        }
                        else if(plugnote['field'+item+'format'] === 'password'){
                            value = '*'.repeat(6);

                        }
                        else if(plugnote['field'+item+'format'] === 'glocalis'){
                            let tmp = "";
                            try {
                                let plugValue = parseJson(value);
                                if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                                    tmp = tmp.concat(plugValue.address);
                                }
                                if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                                    if(tmp.length > 0){
                                        tmp = tmp.concat(' ~ ');
                                    }
                                    tmp = tmp.concat('lat : ' + plugValue.latitude + ', lng :' + plugValue.longitude);
                                }
                            }
                            catch {
                                tmp = ""
                            }
                            value = tmp;
                        }
                        finalObject['field' + item] = value;

                        if(activeSearch && founded === false && typeof value !== "undefined"){
                            founded = value.includes(search)
                        }
                    }
                }

                finalObject['include'] = !activeSearch ? true : founded;


                finalObject['plugnote'] =  plugnote;
                finalObject['hash'] =  plugnote.hash;

                if(!activeSearch){
                    initialRows.push(finalObject);
                }else if(finalObject['include'] === true){
                    initialRows.push(finalObject);
                }


            }
            else if(actif === true && archived === false && plugnote.isarchived === false){
                let finalObject = {};
                let founded = false;
                finalObject['include'] = true;
                finalObject.userindex = '[' + envIndex + '-' + plugnote.userindex + ']';

                for (let item = 1; item <= 15 ; item++){
                    if(model['field'+item+'label'] !== null){
                        let value = plugnote['field'+item];
                        if(plugnote['field'+item+'format'] === 'select'){
                            try {
                                let values = JSON.parse(plugnote['field'+item]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field'+item];
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }else if(plugnote['field'+item+'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        } else if(plugnote['field'+item+'format'] === 'plugform'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            value = typeof plugValue !== "undefined" ? plugValue.value : "";
                        }
                        else if(plugnote['field'+item+'format'] === 'qrcode'){
                            if(typeof value !== 'undefined' && value.length > 0){
                                let qrCode = "";
                                let comment = "";
                                if(value.startsWith(';')){
                                    let fieldValue  = value.substring(1);
                                    qrCode = '';
                                    comment = fieldValue;
                                }else{
                                    let fieldValue = value.split(/([^,]*);(.*)/);
                                    let fieldFilteredValue =  fieldValue.filter( a => a);
                                    qrCode = fieldFilteredValue[0];
                                    comment = fieldFilteredValue[1];
                                }

                                if(qrCode !== undefined && qrCode.length > 0){
                                    if (value.startsWith('PN') || value.startsWith('PP') || value.startsWith('PF') || value.startsWith('pn') || value.startsWith('pp') || value.startsWith('pf')){
                                        value = '(' + qrCode.toUpperCase() + ')';
                                    }
                                }

                                if(comment !== undefined && comment.length > 0){
                                    value = comment;
                                }

                                if(qrCode !== undefined && comment !== undefined && qrCode.length > 0 && comment.length > 0){
                                    value = '(' + qrCode.toUpperCase() + ')' + ' ' + comment;
                                }
                            }

                        }
                        else if(plugnote['field'+item+'format'] === 'password'){
                            value = '*'.repeat(6);

                        }
                        else if(plugnote['field'+item+'format'] === 'glocalis'){
                            let tmp = "";
                            try {
                                let plugValue = parseJson(value);
                                if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                                    tmp = tmp.concat(plugValue.address);
                                }
                                if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                                    if(tmp.length > 0){
                                        tmp = tmp.concat(' ~ ');
                                    }
                                    tmp = tmp.concat('lat : ' + plugValue.latitude + ', lng :' + plugValue.longitude);
                                }
                            }
                            catch {
                                tmp = ""
                            }

                            value = tmp;
                        }
                        finalObject['field' + item] = value;

                        if(activeSearch && founded === false && typeof value !== "undefined"){
                            founded = value.includes(search)
                        }

                    }

                }

                finalObject['include'] = !activeSearch ? true : founded;


                finalObject['plugnote'] =  plugnote;
                finalObject['hash'] =  plugnote.hash;

                if(!activeSearch){
                    initialRows.push(finalObject);
                }else if(finalObject['include'] === true){
                    initialRows.push(finalObject);
                }

            }
            else if(actif === false && archived === true && plugnote.isarchived === true){
                let finalObject = {};
                let founded = false;
                finalObject['include'] = true;
                finalObject.userindex = '[' + envIndex + '-' + plugnote.userindex + ']';

                for (let item = 1; item <= 15 ; item++){
                    if(model['field'+item+'label'] !== null){
                        let value = plugnote['field'+item];
                        if(plugnote['field'+item+'format'] === 'select'){
                            try {
                                let values = JSON.parse(plugnote['field'+item]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field'+item];
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }else if(plugnote['field'+item+'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue !== null) {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        }else if(plugnote['field'+item+'format'] === 'plugform'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            value = typeof plugValue !== "undefined" ? plugValue.value : "";
                        }
                        else if(plugnote['field'+item+'format'] === 'qrcode'){
                            if(typeof value !== 'undefined' && value.length > 0){
                                let qrCode = "";
                                let comment = "";
                                if(value.startsWith(';')){
                                    let fieldValue  = value.substring(1);
                                    qrCode = '';
                                    comment = fieldValue;
                                }else{
                                    let fieldValue = value.split(/([^,]*);(.*)/);
                                    let fieldFilteredValue =  fieldValue.filter( a => a);
                                    qrCode = fieldFilteredValue[0];
                                    comment = fieldFilteredValue[1];
                                }

                                if(qrCode !== undefined && qrCode.length > 0){
                                    if (value.startsWith('PN') || value.startsWith('PP') || value.startsWith('PF') || value.startsWith('pn') || value.startsWith('pp') || value.startsWith('pf')){
                                        value = '(' + qrCode.toUpperCase() + ')';
                                    }
                                }

                                if(comment !== undefined && comment.length > 0){
                                    value = comment;
                                }

                                if(qrCode !== undefined && comment !== undefined && qrCode.length > 0 && comment.length > 0){
                                    value = '(' + qrCode.toUpperCase() + ')' + ' ' + comment;
                                }
                            }

                        }
                        else if(plugnote['field'+item+'format'] === 'password'){
                            value = '*'.repeat(6);

                        }

                        else if(plugnote['field'+item+'format'] === 'glocalis'){
                            let plugValue = parseJson(value);
                            let tmp = "";
                            if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                                tmp = tmp.concat(plugValue.address);
                            }

                            if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                                if(tmp.length > 0){
                                    tmp = tmp.concat(' ~ ');
                                }
                                tmp = tmp.concat('lat : ' + plugValue.latitude + ',' + 'lng :' + plugValue.longitude);
                            }

                            value = tmp;
                        }
                        finalObject['field' + item] = value;
                        if(activeSearch && founded === false && typeof value !== "undefined"){
                            founded = value.includes(search)
                        }
                    }

                }


                finalObject['include'] = !activeSearch ? true : founded;

                finalObject['plugnote'] =  plugnote;
                finalObject['hash'] =  plugnote.hash;

                if(!activeSearch){
                    initialRows.push(finalObject);
                }else if(finalObject['include'] === true){
                    initialRows.push(finalObject);
                }

            }
        });
    }

    const [changeColumns, setChangeColumns] = useState(initColumns);
    const [rows, setRows] = useState([initialRows]);
    const [page, setPage] = useState(typeof startPage === 'undefined' ? 1 : startPage);
    const [end, setEnd] = useState(typeof limitPage === 'undefined' ? 1 : limitPage);
    const [hasMore, setHasMore] = useState(startPage < limitPage);
    const [sortDirection, setSortDirection] = useState("NONE");
    const [sortColumn, setSortColumn] = useState("userindex");
    const [rowCount, setRowCount] = useState(initialRows.length);


    const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
    }, [plugnotes]);

    useEffect(() => {
        setPage(startPage);
        setEnd(limitPage);
        if(startPage < limitPage){
            setHasMore(true);
        }
    }, [startPage, limitPage]);

    const fetchDatas = () => {
        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }else{
            setHasMore(false);
        }

        attemptGetPlugnotesPaginateByClefAndUserHash(nextPage);

    };

    return (
        <div key={-1}>
            <DndProvider backend={HTML5Backend}>
                <ReactDataGrid
                    rowKeyGetter={(row) => row.hash || ''}
                    defaultColumnOptions={{
                        sortable: true,
                        resizable: true
                    }}
                    style={{height : ((rowCount * 35) + 100)}}
                    columns={initColumns}
                    rowGetter={i => rows[i]}
                    rowsCount={rowCount}
                    sortDirection={sortDirection}
                    sortColumn={sortColumn}
                    onSort={(sortColumn, sortDirection) =>{
                        setSortDirection(sortDirection);
                        setSortColumn(sortColumn);
                        setRows(sortRows(initialRows, sortColumn, sortDirection));
                    }}
                    rows={rows || []}
                    headerRowHeight={70}
                    //selectedRows={selectedRows}
                    //onSelectedRowsChange={setSelectedRows}
                />
                {
                    hasMore &&
                    <div onClick={(event) => fetchDatas()} className={classes.pointer}>
                        <Button variant="contained" fullWidth={true}><span>LOAD MORE</span></Button>
                    </div>
                }
            </DndProvider>
        </div>
    );
};

const ModalListFicheComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [key, setKey] = React.useState(0);

    const [archived, setArchived] = React.useState(false);
    const [plugnotes, setPlugnotes] = React.useState([]);
    const [envIndex, setEnvIndex] = React.useState(1);
    const [start, setStart] = React.useState(0);
    const [end, setEnd] = React.useState(0);
    const [model, setModel] = React.useState(null);
    const [actif, setActif] = React.useState(true);

    const [countArchived, setCountArchived] = React.useState(0);
    const [countActive, setCountActive] = React.useState(0);

    const [search, setSearch] = useState(props.filter);
    const [activeSearch, setActiveSearch] = useState(props.filter.length > 0);
    const [reload, setReload] = useState(false);

    const handleCloseModal = () => {
        props.close();
    };


    useEffect(() => {
        fetchLoadDatas();
    }, [props.open]);

    useEffect(() => {
        if(plugnotes !== null && plugnotes.length > 0){
            setCountActive(plugnotes.filter((plug, index) => {
                return !plug.isarchived
            }).length)
            setCountArchived(plugnotes.filter((plug, index) => {
                return plug.isarchived
            }).length)
        }
    }, [plugnotes])


    const fetchLoadDatas = () => {

        let view = "unarchived";
        if(actif && archived){
            view = "all";
        }else if (!actif && archived){
            view = "archived";
        }

        trackPromise(
            apiExtData.getDataByClef(props.clef, props.model.hash, 1, search,view).then((response)=> {
                if(response.data.result){
                    setModel(response.data.model);
                    setStart(response.data.start);
                    setEnd(response.data.end);
                    setEnvIndex(response.data.envIndex);
                    setPlugnotes(response.data.datas);
                    setActiveSearch(true);
                }
            }));
    };

    const fetchLoadMoreDatas = (page) => {
        let view = "unarchived";
        if(actif && archived){
            view = "all";
        }else if (!actif && archived){
            view = "archived";
        }
        trackPromise(
            apiExtData.getDataByClef(props.clef, props.model.hash, page, search,view).then((response)=> {
                if(response.data.result){
                    setModel(response.data.model);
                    setStart(response.data.start);
                    setEnd(response.data.end);
                    setEnvIndex(response.data.envIndex);
                    setPlugnotes(response.data.datas);
                    setActiveSearch(true);
                }
            }));
    }

    const handleSelectPlugnote = (plugnote) => {

        let fields = props.field.toString().split(';');
        let infos = [];
        if(fields.length > 0){
            fields.map((value, index) => {
                infos.push(
                    {
                        "format" : plugnote["field"+value+"format"],
                        "value" : plugnote["field"+value]
                    }
                );
            })
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.content-replace'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () => {
                            props.handleSelectPlugnoteAndCopyDataField(plugnote.userindex,infos, props.model);
                        }
                    },
                    {
                        label: t('global.no'),
                    },
                ]
            });
        }
        //props.handleSelectPlugnoteAndCopyDataField(plugnote.userindex,plugnote["field"+props.field+"format"],plugnote["field"+props.field], props.model);
    }


    const handleViewInputSearch = () => {
        setActiveSearch(false);
        setSearch("");
        setReload(true);
    }

    const handleSearch = () => {
        setActiveSearch(true);
    };

    const handleChangeActif = () => {
        setActif(!actif);
        setReload(true);
    }

    const handleChangeArchived = () => {
        setArchived(!archived);
        setReload(true);
    }

    useEffect(() => {
        if(reload){
            fetchLoadDatas();
            setReload(false);
        }
    }, [reload])

    return (
        <Modal
            key={key}
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div className="text_align">
                                <span className="titres">
                                    {t('common:modal.select-plugnotes-from-folder')}
                                     <br/>
                                        { !!props.model &&
                                            <span>[{props.model.clef}] {props.model.label}</span>
                                        }
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">

                                <div className="grey flex_display_list_annexe">
                                    <div style={{width : '80%'}}>
                                        <Input
                                            id="standard-full-width"
                                            label="Label"
                                            style={{ margin: 8 }}
                                            //placeholder='titre formulaire'
                                            helpertext="Full width!"
                                            fullWidth={true}
                                            margin="none"
                                            inputlabelprops={{
                                                shrink: true,
                                            }}
                                            disableUnderline={true}
                                            className={classes.textField}
                                            value={search}
                                            onChange={(event) => {
                                                setSearch(event.target.value);
                                                if(activeSearch){
                                                    setActiveSearch(false)
                                                }
                                                setReload(true);
                                            }}
                                            endAdornment={ search.length > 0 && activeSearch ?
                                                <InputAdornment position="end" onClick={handleViewInputSearch} style={{cursor : 'pointer'}}>
                                                    <Close />
                                                </InputAdornment>
                                                :
                                                <InputAdornment position="end" onClick={handleSearch} style={{cursor : 'pointer'}}>
                                                    <Search />
                                                </InputAdornment>
                                            }
                                            onKeyPress={(e) => { if (e.key === 'Enter') { handleSearch()}}}
                                        />
                                    </div>
                                    <div className="flex_display_direction_row_center" style={{width : '20%'}}>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={actif}
                                                   onChange={(event) => setActif(!actif)}/>
                                            {t('common:plugnote.plugnote-active')} ({countActive})
                                        </div>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={archived}
                                                   onChange={(event) => setArchived(!archived)}/>
                                            {t('common:plugnote.plugnote-archive')} ({countArchived})
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup_wrapper_content">
                            { plugnotes.length > 0 &&
                                <ViewPlugnotes
                                    plugnotes={plugnotes}
                                    envIndex={envIndex}
                                    limitPage={end}
                                    startPage={start}
                                    model={model}
                                    handleSelectPlugnote={handleSelectPlugnote}
                                    field={props.field}
                                    clef={props.clef}
                                    attemptGetPlugnotesPaginateByClefAndUserHash={fetchLoadMoreDatas}
                                    actif={actif}
                                    archived={archived}
                                    //activeSearch={activeSearch}
                                    //search={search}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {


    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalListFicheComponent)
