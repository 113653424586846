import {getToken} from "../utils/Common";
import {api} from "./index";

const reqHeaders = {
    'headers': {
        'X-AUTH-TOKEN': getToken(),
    }
}

export let getFields;
getFields = () => api.get(`v3/fields.json`);
