import React, {useEffect, useState, Suspense} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import colors from "../../assets/themes/Colors";
import { Tooltip, TextField } from "../../../node_modules/@material-ui/core/index";
import ModalPlugnoteComponent from "../modals/ModalPlugnoteComponent";
import ModalPlugformComponent from "../modals/ModalPlugformComponent";
import qs from "querystringify";

import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {toast} from "react-toastify";
import {Translation, useTranslation} from "react-i18next";
import i18n from "../../translations/i18n";
import {trackPromise} from "react-promise-tracker";
import Images from "../../assets/themes/Images";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    editButton:{
        display: 'inline-block',
        paddingRight: "10px",
        borderRadius: "6px",
        fontSize: '22px!important',
        color: "#0000008a",
        cursor: "pointer",
        alignSelf: 'center'
    },
    rightButton: {
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "6px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'flex',
        color: 'white',
    },
    previewContainer: {
        flex: 1,
        // display: 'flex',
        // flexDirection: 'row',
    },
    commentContainer: {
        // transform: 'translateY(-18px)',
        flex: 1,
    },
    codeContainer: {
        // marginLeft: 'auto',
        width: 'fit-content',
        cursor: 'pointer',
        display: 'flex',
        position: 'relative',
        top: 0,
        right: 0,
        zIndex: 1,
    },
    mainContainerBorder: {
        minHeight: 42,
        border: '1px solid #E3E3E3',
        borderRadius: 4,
        padding: '3px 10px',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
    },
    mainCtnNoBorder: {
        minHeight: 42,
    },
    collapsedView: {
        // width: '100%',
        justifyContent: 'space-between',
        marginRight: '15px'
    },
    commentPreview: {
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        cursor: 'pointer!important'
    },
    editCommentCtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flex: 1,
    },
    codeInput: {
        textAlignLast: 'center',
        fontWeight: '900',
        width: 140,
        marginRight: '5px',
        borderColor: colors.selectedMain,
        borderBottom: '1px solid',
    },
    seeFormBtn: {
        fontSize: '17px',
        minHeight: '40px',
        paddingTop: '3px',
        paddingBottom: '3px',
        color: 'white!important',
        backgroundColor: colors.unselected,
        borderRadius: '6px',
        justifyContent: 'center',
        cursor: 'pointer',
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    seeFormTxt: {
        fontSize: '17px',
        color: 'white!important',
        textAlign: 'center',
        cursor: 'pointer',
        whiteSpace: 'break-spaces',
    },
	infobulle: {
		backgroundColor: colors.grey,
		color: colors.white,
		border: '1px solid',
		padding: '6px 10px',
		margin: 5,
		fontSize: '18px',
		borderRadius: 5,
		whiteSpace: 'pre-line',
	},
}));



const FormatPlugcodeComponent = (props) => {
    // qrcode - F19
    const classes = useStyles();
    const { t, i18n } = useTranslation('common');

    let plugcode = typeof props.field.value !== 'undefined' ? props.field.value.split(';') : [];
    let initCode = "";
    let initComment = "";
    let initBtn = "";
    const isLocked = props.field.options.setup?.editable === false || props.disabled

    if(plugcode.length === 2){
        initCode =  (plugcode[0] !== 'undefined' ? plugcode[0] : "");
        initComment =(typeof plugcode[1] !== 'undefined' ? plugcode[1] : "");
        initBtn = initCode.startsWith("PN") ? t('welcome.access-plugnote') : t('welcome.access-plugform');

    } else if(plugcode.length === 1){
        let constainsCode = plugcode[0].startsWith('PN') || plugcode[0].startsWith('PP') || plugcode[0].startsWith('PF') || plugcode[0].startsWith('PE');
        if(constainsCode){
            initCode= (plugcode[0]);
            initComment =("");
            initBtn = initCode.startsWith("PN") ? t('welcome.access-plugnote') : t('welcome.access-plugform');
        }else{
            initCode = ("");
            initComment = (plugcode[0]);
        }
    }

    const [code, setCode] = useState(initCode);
    const [comment, setComment] = useState(initComment);
    const [btnContent, ] = useState(initBtn);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [open, setOpen] = useState(false) // champ pour ajouter un commentaire
    const [viewFiche, setViewFiche] = useState(false)
    const [owner, setOwner] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [index, setIndex] = useState(!!props.field.value.index ? props.field.value.index : "");
    const [response, setResponse] = useState(null);
    const [formLogo, setFormLogo] = useState(null)

    const ErrorToastMessage = ({ title, message }) => (
        <div>
            <Translation ns="common">
                {
                    (t, { i18n }) => <h6>{t(title)}</h6>
                }
            </Translation>
            <Translation ns="common">
                {
                    (t, { i18n }) => <p>{t(message)}</p>
                }
            </Translation>
        </div>

    )

    const MainToastMessage = ({ title, message }) => (
        <div>
            <Translation ns="common">
                {
                    (t, { i18n }) => <p>{t(message)}</p>
                }
            </Translation>
        </div>

    )

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    const validateCode = (code) => {
        let isValid = false
        if(code.startsWith("PF-") || code.startsWith("PN-") || code.startsWith("PP-")) isValid = true
        if (code.trim().length === 15) return isValid;
        else return false
    }

    const handleOpenComment = () => {
        setOpen(true);
        if(open){
            setTimeout(() => {document.getElementById('comment-field').focus()}, 100);
        }
    }

    const handleSearchPlugcode = () => {
        if (code.length > 0) {
            if (code.startsWith("PF")) {
                //props.attemptGetPlugform(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pfcode/v2/modelcodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(code),
                    }, '?'))
                        .then((response) => {
                        //console.log(response);
                        if(response.data.result == true || response.data.result === 'ok'){
                            //console.log('resposta da api', response)
                            setResponse(response.data);
                            setFormLogo(response.data.logo !== 'logoPng' ?  response.data.logoWeb : Images['logoPng']);
                            setViewFiche(true);
                        }else if(response.data.result == false || response.data.result === 'onk'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }else if(response.data.result == 'nok'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        } else {
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }
                    })
                        .catch((response) => {
                        //je lance une notification (toast error)
                        toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
                )
            }
            else {
                //props.attemptGetPlugnote(value);
                trackPromise(
                    //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                    axios.get(ApplicationConfig.apiUrl + 'pncode/datacodelogoff.json' + qs.stringify({
                        code: encodeURIComponent(code),
                    }, '?'))
                        .then((response) => {
                        //console.log(response);
                        if(response.data.result == true){
                            //console.log('resposta da api', response)
                            setResponse(response.data)
                            setFormLogo(response.data.logo !== 'logoPng' ?  response.data.logoWeb : Images['logoPng']);
                            setViewFiche(true);
                        }else if(response.data.result == false){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }else if(response.data.result == 'nok'){
                            //je lance une notification (toast warning)
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        } else {
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.homeNote}`,
                              });
                        }
                    })
                        .catch((response) => {
                        //je lance une notification (toast error)
                        toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
                )
            }
        }
    }

    const handleOpenFiche = () => {
        //console.log("opening modal...");
        // console.log('formatplugcode', props);
        handleSearchPlugcode();
    }

    if (disabled) return (
        <div className={classes.mainCtnNoBorder}>
            <div
                className={classes.seeFormBtn}
                onClick={handleOpenFiche}
            >
                {
                    comment.trim() === "" ?
                        <span className={classes.seeFormTxt}>{btnContent}</span>
                        :
                        comment.length > 40 ?
                            <Tooltip title={comment} placement="top" classes={{ tooltip: classes.infobulle }}>
                                <span className={classes.seeFormTxt}>{comment.slice(0, 40)}...</span>
                            </Tooltip>
                            :
                            <span className={classes.seeFormTxt}>{comment}</span>
                }
            </div>
            {
                viewFiche && validateCode(code) !== null && code.startsWith("PF-") &&
                <Suspense fallback={null}>
                    <ModalPlugformComponent secondary={true} logo={formLogo} value={response} code={code} open={viewFiche} close={(event) => setViewFiche(false)} useSuspense={false} />
                </Suspense>
            }

            {
                viewFiche && validateCode(code) !== null && code.startsWith("PN-") &&
                <Suspense fallback={null}>
                    <ModalPlugnoteComponent logo={formLogo} secondary={true} value={response} code={code} open={viewFiche} close={(event) => setViewFiche(false)} useSuspense={false} />
                </Suspense>
            }
        </div>
    );
    else return (
        <div className={classes.mainContainerBorder}>
            <div className={classes.codeContainer} style={open? null : {backgroundColor: colors.white}}>
                {
                    !open && validateCode(code) && !disabled &&
                    <Tooltip title={code.startsWith("PN-") ? "See Plugnote" : code.startsWith("PF-") ? "See Plugform" : "Not allowed"} placement="top">
                        <span id="icon-listForm" className={`material-icons md-25  ${classes.rightButton}`} style={{ marginLeft: 'auto' }} onClick={(event) => handleOpenFiche()}>
                            {code.startsWith("PN-") ? 'visibility' : 'edit'}
                        </span>
                    </Tooltip>
                }
            </div>
            {
                !open &&
                <Input
                    disabled={disabled || isLocked}
                    autoComplete='off'
                    id="standard-full-width"
                    placeholder="code"
                    fullWidth={true}
                    margin="none"
                    className={classes.codeInput}
                    disableUnderline={true}
                    onChange={(event) => {
                        setCode(event.target.value);
                        props.handleChangePlugcode(event.target.value, comment, props.field, props.index)
                    }
                    }
                    defaultValue={code}
                />
            }

            {
                open &&
                    <div className={classes.editCommentCtn}>
                        <Input
                            id='comment-field'
                            autoFocus
                            disableUnderline
                            style={{ margin: 0 }}
                            disabled={disabled}
                            autoComplete='off'
                            placeholder="comment"
                            fullWidth={true}
                            multiline={true}
                            margin="none"
                            className={classes.commentContainer}
                            onChange={(event) => {
                                setComment(event.target.value);
                                props.handleChangePlugcode(code, event.target.value, props.field, props.index);
                            }}
                            defaultValue={comment}
                            onClick={(e) => { e.preventDefault(); e.stopPropagation() }}
                            onFocus={(e) =>
                                e.currentTarget.setSelectionRange(
                                    e.currentTarget.value.length,
                                    e.currentTarget.value.length
                                )}
                        />
                        <Tooltip title="Validate comment" placement="top">
                            <span id="icon-listForm" className={`material-icons md-25 ${classes.rightButton}`} style={{ marginLeft: 'auto' }} onClick={(event) => setOpen(false)}>
                                check
                            </span>
                        </Tooltip>
                    </div>
}
            {
                !open &&
                <div className={classes.previewContainer} onClick={()=> handleOpenComment() }>
                    {
                        comment !== ""?
                        <Tooltip title={disabled? "See full comment" : "Add comment"} placement="top">
                            <TextField
                                className={classes.commentPreview}
                                InputProps={{ disableUnderline: true, maxLength: 23}}
                                inputProps={{ maxLength: 12 }}
                                fullWidth={true}
                                onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleOpenComment()}}
                                value={comment.length > 20 ? comment.slice(0, 20).concat('...') : comment}
                            />
                        </Tooltip>
                            :
                        <Tooltip title={disabled? "See full comment" : "Add comment"} placement="top">
                            <span
                                onClick={()=> setOpen(true)}
                                className={`material-icons md-small ${classes.editButton}`}>message</span>
                        </Tooltip>
                    }
                </div>
            }
        {/* {
            viewFiche && <ModalViewFicheComponent open={viewFiche} close={(event) => setViewFiche(false)} owner={owner} index={index}/>
        } */}
        { viewFiche && validateCode(code) !== null && code.startsWith("PF-") &&
            <Suspense fallback={null}>
                <ModalPlugformComponent secondary={true} logo={formLogo} value={response} code={code} open={viewFiche} close={(event) => setViewFiche(false)} useSuspense={false}/>
            </Suspense>
        }

        { viewFiche && validateCode(code) !== null && code.startsWith("PN-") &&
            <Suspense fallback={null}>
                <ModalPlugnoteComponent logo={formLogo} secondary={true}  value={response} code={code} open={viewFiche} close={(event) => setViewFiche(false)} useSuspense={false}/>
            </Suspense>
        }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPlugcodeComponent);





