import {api} from "./index";

export let
    postExtData,
    postExtDataPreview,
    getDataByClef,
    postExtDataGenerateTable;


postExtData = (payload) => api.post('extdata/v3s/retrieves/datas.json', payload);
postExtDataPreview = (payload) => api.post('extdata/uniques/datas.json', payload);
postExtDataGenerateTable = (payload) => api.post('extdata/generates/datas.json', payload);
getDataByClef = (clef, modelHash,page,filter,view) => api.get(`extdata/v2/datas/by/clef.json?clef=${clef}&mh=${modelHash}&page=${page}&filter=${filter}&view=${view}`);


